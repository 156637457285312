export const AndroidPlaystoreConfiguration = {
    schema: {
        title: 'Playstore',
        type: 'object',
        category: 'Application',
        schemaId: 'playstoreConfiguration',
        properties: {
            playStoreMode: {
                title: 'Play Store Mode',
                description:
                    'This mode controls which apps are available to the user in the Play Store and the behavior on the device when apps are removed from the policy.',
                type: 'string',
                help: 'Blacklist is deprecated',
                setUrl: 'playstoremode',
                options: [
                    {
                        id: 'WHITELIST',
                        name: 'Whitelist apps',
                    },
                    {
                        id: 'BLACKLIST',
                        name: 'Blacklist apps',
                    },
                ],
            },
        },
    },
};
