import { PremiumFeature } from '../contact/contact.components';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export const UpdaterPermissionsCheck = ({ children, teaserProps = {} }) => {
    const { permissions } = useAuthContext();
    if (permissions.canManageSoftwarePatching) {
        return children;
    }
    return <UpdaterTeaser {...teaserProps} />;
};

const UpdaterTeaser = () => {
    return (
        <div className="tw-h-full tw-p-0.5">
            <WidgetPaper
                headerless
                className="tw-h-full"
            >
                <PremiumFeature
                    description="CapaOne Updater automates application updates, mitigating security issues in applications. The comprehensive application repository makes it hassle-free and blazing fast to update the most crucial applications."
                    icon="capaoneUpdater"
                    defaultSubject="CapaOne Updater"
                    productPage="https://www.capasystems.com/capaone-updater"
                />
            </WidgetPaper>
        </div>
    );
};
