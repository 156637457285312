export const AndroidCellularConfiguration = {
    schema: {
        title: 'Cellular',
        type: 'object',
        category: 'Networking',
        schemaId: 'cellularConfiguration',
        properties: {
            outgoingCallsDisabled: {
                title: 'Outgoing Call Disabled',
                description: 'Whether outgoing calls are disabled.',
                type: 'boolean',
            },
            smsDisabled: {
                title: 'SMS Disabled',
                description: 'Whether sending and receiving SMS messages is disabled.',
                type: 'boolean',
            },
            cellBroadcastsConfigDisabled: {
                title: 'Cell Broadcasts Config Disabled',
                description: 'Whether configuring cell broadcast is disabled.',
                type: 'boolean',
            },
        },
    },
};
