const AndroidWebAppConfiguration = {
    schema: {
        title: 'Set up your web application',
        description: 'A web app turns a web page into an Android app, making it easier to find and simpler to use on mobile devices.',
        type: 'object',
        category: 'Application',
        schemaId: 'webApp',
        properties: {
            title: {
                title: 'Title',
                description: 'Title as displayed to the user.',
                help: "The title must be fewer than 30 characters. Depending on the form factor of the device, a web app's title may be truncated in the managed Play store and in the device's launcher. We therefore recommend specifying short title.",
                type: 'string',
                required: true,
            },
            startUrl: {
                title: 'Start URL',
                description: 'The start URL defines the URL that should load when the user opens the application. The user can then navigate to other URLs.',
                help: 'The start URL must be a HTTPS or HTTP URL. The URL of the web app must have the HTTPS scheme when the display mode is full screen or standalone.',
                placeholder: 'https://www.example.com',
                type: 'string',
                required: true,
            },
            displayMode: {
                title: 'Display mode',
                description:
                    "<div class='tw-mb-1'>Display mode only applies to pages belonging to the URL. If your web app's URL redirects to a different website, the site will open in Chrome Browser.</div><div class='tw-grid tw-grid-cols-auto-1fr tw-leading-loose'><strong>&middot;&nbsp;&nbsp;Standalone</strong><span>The device's status bar and navigation bar are visible when the app is open.</span><strong class='tw-mr-2'>&middot;&nbsp;&nbsp;Full screen</strong><span>The app opens in full screen mode, hiding the device's status and navigation bars.</span><strong>&middot;&nbsp;&nbsp;Minimal UI</strong><span>The device's status bar, navigation bar, the app's URL, and a refresh button are visible when the app is open.</span></div>",
                type: 'string',
                options: [
                    {
                        id: 'STANDALONE',
                        name: 'Standalone',
                    },
                    {
                        id: 'FULL_SCREEN',
                        name: 'Full screen',
                    },
                    {
                        id: 'MINIMAL_UI',
                        name: 'Minimal UI',
                    },
                ],
                required: true,
            },
            icons: {
                title: 'Icon',
                description:
                    "The icon should be a maskable 512px square, png or jpeg.<br/>The maximum allowed file size is 1 MB.<br/>Your app image must follow the <strong><a href='https://play.google.com/about/developer-content-policy.html' target='google_play_store'>Google Play Developer Program Policies</a></strong>.",
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        imageData: {
                            title: 'Image Data',
                            type: 'string',
                        },
                    },
                },
                shouldRender: () => false,
            },
            /* name: {
                title: "Name",
                description: "The name of the web app, which is generated by the server during creation in the form enterprises/{enterpriseId}/webApps/{packageName}",
                type: "string",
                disabled: true,
            },
            versionCode: {
                title: "Version Code",
                description: "The current version of the app.",
                help: "Note: The version can automatically increase during the lifetime of the web app, while Google does internal housekeeping to keep the web app up-to-date.",
                type: "string",
                disabled: true,
            } */
        },
    },
};

export { AndroidWebAppConfiguration };
