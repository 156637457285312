export const AppleEndpointSettings = {
    schema: {
        schemaId: 'appleEndpointSettings',
        title: 'Endpoint Settings',
        category: 'Settings',
        description: '',
        type: 'object',
        properties: {
            HasRemovalPasscode: {
                title: 'Removable Passcode',
                type: 'boolean',
            },
            IsEncrypted: {
                title: 'Encrypted',
                type: 'boolean',
            },
            IsManaged: {
                title: 'Managed',
                type: 'boolean',
            },
            PayloadDisplayName: {
                title: 'Name',
                type: 'string',
            },
            PayloadIdentifier: {
                title: 'ID',
                type: 'string',
            },
            PayloadRemovalDisallowed: {
                title: 'Removable Disallowed',
                type: 'boolean',
            },
            PayloadUUID: {
                title: 'UUID',
                type: 'string',
            },
            PayloadVersion: {
                title: 'Version',
                type: 'number',
            },
            PayloadContent: {
                title: 'Content',
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        PayloadDisplayName: {
                            title: 'Content Name',
                            type: 'string',
                        },
                        PayloadIdentifier: {
                            title: 'Content ID',
                            type: 'string',
                        },
                        PayloadType: {
                            title: 'Type',
                            type: 'string',
                        },
                        PayloadVersion: {
                            title: 'Content Version',
                            type: 'number',
                        },
                    },
                },
            },
            SignerCertificates: {
                title: 'Signer Certificates',
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            _id: {
                title: 'ID',
                type: 'string',
            },
        },
    },
};
