import { Column, Dialog, DialogContent, DialogTitle, Ellipsis, VirtualizedTable } from '@capasystems/ui';
import { patchInstallOrUninstallFailed } from '@thirdparty/utils';
import { useMemo, useState } from 'react';
import { PieChart, eSize } from '../charts/pie-chart';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { CapaOneLink, WidgetPaper } from '../thirdparty-components/thirdparty-components';

export const UpdaterFailedInstall = ({ data, topCount = 5, className }) => {
    const navigate = useNavigate();
    const [showOther, setShowOther] = useState(false);

    const calculatedData = useMemo(() => {
        const tmp = {};

        data?.filter((d) => patchInstallOrUninstallFailed(d)).forEach((d) => {
            if (!tmp[d.appId]) {
                tmp[d.appId] = {
                    id: d.appId,
                    name: d.name,
                    y: 1,
                };
            } else {
                tmp[d.appId].y++;
            }
        });

        const values = Object.values(tmp);
        const sortedValues = values.sort((a, b) => {
            // Sort by y, then by name
            return b.y - a.y || a.name.localeCompare(b.name);
        });
        const top = sortedValues.slice(0, topCount);
        const other = sortedValues.slice(topCount);
        const otherReduced = other.reduce(
            (prev, curr) => {
                return {
                    name: 'Other',
                    y: prev.y + curr.y,
                };
            },
            { name: 'Other', y: 0 }
        );

        return {
            data: other.length > 0 ? [...top, otherReduced] : top,
            other,
        };
    }, [data]);

    return (
        <>
            <WidgetPaper
                className={className}
                title="Failed"
                description="This chart shows how many patches have failed to install"
            >
                <PieChart
                    data={calculatedData?.data}
                    size={eSize.large}
                    onClick={(e) => {
                        if (e.name === 'Other') {
                            setShowOther(true);
                        } else {
                            navigate.to(`windows/updater/application/${e.id}?updater.status.tab=5`);
                        }
                    }}
                />
            </WidgetPaper>
            <Dialog
                open={showOther}
                onClose={() => setShowOther(false)}
            >
                <DialogTitle className="tw-px-4">Failed - Other</DialogTitle>
                <DialogContent className="tw-px-0">
                    <div style={{ height: 500, maxHeight: '60vh' }}>
                        <VirtualizedTable items={calculatedData?.other}>
                            <Column
                                label="Application"
                                dataKey="name"
                                minWidth={160}
                                type="string"
                                cellRenderer={({ rowData }) => (
                                    <CapaOneLink to={'windows/updater/application/' + rowData.id + '?updater.status.tab=5'}>
                                        <Ellipsis>{rowData.name}</Ellipsis>
                                    </CapaOneLink>
                                )}
                            />
                            <Column
                                label="Amount"
                                dataKey="y"
                                minWidth={120}
                                maxWidth={120}
                                type="number"
                            />
                        </VirtualizedTable>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
