/* istanbul ignore file */
import classnames from 'classnames';
import React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { Link, LinkProps } from './link';

export type RouteLinkProps = LinkProps & {
    to: string | { pathname: string; search?: string } | ((location: { pathname: string; search?: string }) => void);
    className?: string;
    color?: 'primary' | 'inherit';
};

/** RouteLink component */
export const RouteLink = React.forwardRef<HTMLElement, RouteLinkProps>(({ className, color = 'primary', ...otherProps }, ref) => (
    <Link
        ref={ref}
        component={ReactRouterDomLink}
        className={classnames(
            className,
            {
                'tw-text-sky-600': color === 'primary',
                'tw-text-inherit': color === 'inherit',
            },
            'cs-route-link'
        )}
        {...otherProps}
    />
));

export default RouteLink;
