import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogTitle,
    Icon,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Tab,
    Tabs,
    Tooltip,
    useParams,
} from '@capasystems/ui';
import { ENDPOINT_TYPE } from '@thirdparty/constants';
import { TailwindBadge, useAppleEndpointWithIdSocket } from '@thirdparty/ui';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate as useCoreNavigate } from 'react-router-dom';
import { ActionsDialog, JsonViewer, PageTitle, UserManagementAssignUserToEndpoint, UserTypeIcon, useAppleApi, useNavigate } from '../../../index';
import { AppleEndpointCommands } from '../components/command/AppleEndpointCommands';
import { AppleEndpointApplicationsTab } from '../components/endpoint/AppleEndpointApplicationsTab';
import { AppleConfigurationsWrapper } from '../components/endpoint/AppleEndpointConfigurationsWrapper';
import { AppleEndpointDashboardTab } from '../components/endpoint/AppleEndpointDashboardTab';
import { AppleEndpointGroupsTab } from '../components/endpoint/AppleEndpointGroupsTab';
import { AppleEndpointInventoryTab } from '../components/endpoint/AppleEndpointInventoryTab';

const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

const manageUserAction = {
    id: 'manageUser',
    name: 'User management',
};

const resultingPolicyAction = {
    id: 'showResultingPolicy',
    name: 'Resulting policy',
    disabled: false,
};

// Available device fields: https://developers.google.com/apple/management/reference/rest/v1/enterprises.devices
const AppleEndpoint = (props) => {
    const { deviceId, '*': splat } = useParams();
    const selectedTab = splat === '' ? 'settings' : splat.split('/')[0];
    const [endpoint, setEndpoint] = useState({});
    const appleApi = useAppleApi();
    const [appliedPolicy, setAppliedPolicy] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const { appendBaseURL } = useNavigate();
    const coreNavigate = useCoreNavigate();

    const onTabChange = (e, selectedTabId) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(appendBaseURL(`apple/device/${deviceId}/${selectedTabId}`), '_blank');
        } else {
            coreNavigate(selectedTabId);
        }
    };

    useEffect(() => {
        appleApi
            .getEndpoint(deviceId)
            .then(async (endpoint) => {
                let model;
                if (endpoint.data.ModelName?.includes('Mac')) {
                    model = endpoint.data.ModelName;
                } else {
                    model = await FindAppleDeviceName(endpoint);
                }
                endpoint.modelName = model;
                setEndpoint(endpoint);
                setLoading(false);
            })
            .catch((deviceResponseError) => {
                setErrorMessage('Could not find device');
            });
    }, [deviceId]);

    useAppleEndpointWithIdSocket(
        deviceId,
        useCallback(
            ({ fullDocument }, { updateOperation, deleteOperation }) => {
                if (updateOperation) {
                    const { _id: id, ...rest } = fullDocument;
                    setEndpoint((c) => ({
                        id,
                        ...c,
                        ...rest,
                        user: c.user,
                    }));
                } else if (deleteOperation) {
                    coreNavigate('..');
                }
            },
            [deviceId]
        )
    );

    if (errorMessage) {
        return (
            <LayoutCentered>
                <b>{errorMessage}</b>
            </LayoutCentered>
        );
    }
    if (loading) {
        return (
            <LayoutCentered>
                <Loading></Loading>
            </LayoutCentered>
        );
    }
    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-auto-1fr tw-px-4">
            <AppleEndpointTopbar
                endpoint={endpoint}
                setEndpoint={setEndpoint}
                setLoading={setLoading}
            />
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                onRails
            >
                <Tab
                    label="Dashboard"
                    value="dashboard"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Inventory"
                    value="inventory"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Configurations"
                    value="configurations"
                    disableRipple
                    className={tabClassName}
                />
                {!endpoint.data.ModelName?.includes('Mac') && (
                    <Tab
                        label="Applications"
                        value="applications"
                        disableRipple
                        className={tabClassName}
                    />
                )}
                <Tab
                    label="Groups"
                    value="groups"
                    disableRipple
                    className={tabClassName}
                />
            </Tabs>
            <div className="tw-overflow-auto">
                <Routes>
                    <Route
                        path="dashboard"
                        element={
                            <AppleEndpointDashboardTab
                                endpoint={endpoint}
                                appliedPolicy={appliedPolicy}
                            />
                        }
                    />
                    <Route path="inventory">
                        <Route
                            path=":inventoryId"
                            element={<AppleEndpointInventoryTab endpoint={endpoint} />}
                        />
                        <Route
                            index
                            element={
                                <Navigate
                                    to="software"
                                    replace
                                />
                            }
                        />
                    </Route>

                    <Route
                        path="configurations"
                        element={<AppleConfigurationsWrapper endpoint={endpoint} />}
                    />
                    <Route
                        path="applications"
                        element={<AppleEndpointApplicationsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="groups"
                        element={<AppleEndpointGroupsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="*"
                        element={
                            <LayoutCentered>
                                <PageTitle>Page not found</PageTitle>
                            </LayoutCentered>
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

const FindAppleDeviceName = async (endpoint) => {
    const isAMac = endpoint.data.ModelName.includes('Mac');
    if (!isAMac) {
        return fetch('https://gist.githubusercontent.com/adamawolf/3048717/raw/07ad6645b25205ef2072a560e660c636c8330626/Apple_mobile_device_types.txt')
            .then((response) => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    throw new Error('Failed to fetch iPhone model lookup table');
                }
            })
            .then((iphoneModelTex) => {
                const isValidModel = iphoneModelTex.includes(endpoint.data.ProductName);
                if (!isValidModel) {
                    return endpoint.data.ProductName;
                }
                const ProductName = `${endpoint.data.ProductName} : (.*)`;
                const match = iphoneModelTex.match(ProductName);
                if (match && match[1]) {
                    return match[1];
                }
            });
    }
};

const AppleEndpointTopbar = ({ endpoint, setEndpoint, setLoading }) => {
    const [actionDialogProps, setActionDialogProps] = useState({
        open: false,
        anchorEl: null,
        category: '',
        title: '',
        actions: [],
    });
    const [showResultingPolicy, setShowResultingPolicy] = useState(false);
    const [isManagingUser, setIsManagingUser] = useState(false);
    const { to } = useNavigate();

    const closeMenu = () => {
        setActionDialogProps((c) => ({
            ...c,
            open: false,
        }));
    };

    const DeviceMode = () => {
        if (endpoint?.isKioskMode) {
            return (
                <Tooltip
                    content="This device is currently in Kiosk Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="cyan"
                        className="tw-gap-1"
                        dark
                    >
                        <Icon
                            type="kioskMode"
                            className="tw-text-lg"
                        />
                        <span>Kiosk Mode </span>
                    </TailwindBadge>
                </Tooltip>
            );
        } else if (endpoint.data?.IsSupervised) {
            return (
                <Tooltip
                    content="This device is currently in Supervised Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="indigo"
                    >
                        Supervised
                    </TailwindBadge>
                </Tooltip>
            );
        } else if (!endpoint.data?.IsSupervised) {
            return (
                <Tooltip
                    content="This device is currently in Unsupervised Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    <TailwindBadge
                        size="small"
                        color="purple"
                    >
                        Unsupervised
                    </TailwindBadge>
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case resultingPolicyAction.id:
                setShowResultingPolicy(true);
                break;
            case manageUserAction.id:
                setIsManagingUser(true);
                break;
        }
    };

    return (
        <Page title={endpoint.name}>
            <div className="tw-pb-3 tw-pt-4">
                <LayoutRow
                    verticalAlign="center"
                    className="tw-gap-x-4"
                >
                    <PageTitle>{endpoint.name}</PageTitle>
                    <EndpointUserInfo
                        endpoint={endpoint}
                        setIsManagingUser={setIsManagingUser}
                    />
                    <DeviceMode />
                    <AppleEndpointCommands endpoint={endpoint} />
                </LayoutRow>
                <ActionsDialog
                    {...actionDialogProps}
                    onClose={closeMenu}
                    onActionClick={onActionClick}
                />

                <UserManagementAssignUserToEndpoint
                    open={isManagingUser}
                    onSubmit={(user) => {
                        setIsManagingUser(false);
                        setEndpoint((c) => ({
                            ...c,
                            user,
                        }));
                    }}
                    endpointType={ENDPOINT_TYPE.APPLE}
                    endpoint={endpoint}
                    onClose={() => {
                        setIsManagingUser(false);
                    }}
                />

                <Dialog
                    open={showResultingPolicy}
                    onClose={() => setShowResultingPolicy(false)}
                    size="xl"
                >
                    <DialogTitle>Resulting policy</DialogTitle>
                    <div className="tw-grid tw-max-h-full tw-grid-cols-auto-1fr tw-overflow-auto">
                        <JsonViewer
                            json={endpoint.resultingPolicy}
                            className="tw-text-tiny"
                            square
                        />
                        <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white">
                            {endpoint.resultingConfigurationDescription}
                        </div>
                    </div>
                    <DialogActions>
                        <Button onClick={() => setShowResultingPolicy(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Page>
    );
};

const EndpointUserInfo = ({ endpoint, setIsManagingUser }) => {
    const navigate = useNavigate();

    const [mouseEntered, setMouseEntered] = useState(false);

    if (endpoint.user) {
        return (
            <Button
                variant={BUTTON.OUTLINED}
                className="tw-rounded-xl tw-border-slate-200 tw-bg-slate-100 tw-px-3 tw-py-2.5 hover:tw-bg-slate-200"
                onClick={() => navigate.to(`management/user/${endpoint.user.id}`)}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
                disableRipple
            >
                <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-x-3 tw-text-left">
                    <div>
                        <UserTypeIcon
                            type={endpoint.user.type}
                            className="tw-h-6 tw-w-6"
                            deleted={endpoint.user.deleted}
                        />
                    </div>
                    <Tooltip
                        content="View user"
                        disableHoverListener={endpoint.user.deleted}
                    >
                        <div>
                            <div className="tw-text-xs tw-font-semibold tw-leading-none">{endpoint.user.name}</div>
                            <div className="tw-mt-1 tw-block tw-text-xs tw-font-medium tw-leading-none">{endpoint.user.email}</div>
                        </div>
                    </Tooltip>
                    <div>
                        <Collapse
                            in={mouseEntered}
                            unmountOnExit
                            orientation="horizontal"
                        >
                            <Tooltip content={manageUserAction.name}>
                                <Icon
                                    type="edit"
                                    color="inherit"
                                    className="tw-text-blue-500 hover:tw-text-sky-700"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsManagingUser(true);
                                    }}
                                />
                            </Tooltip>
                        </Collapse>
                    </div>
                </div>
            </Button>
        );
    }
    return (
        <Button
            variant={BUTTON.OUTLINED}
            color={BUTTON.PRIMARY}
            className="tw-py-2"
            noMargin
            onClick={() => {
                setIsManagingUser(true);
            }}
        >
            <LayoutRow verticalAlign="center">
                <Icon
                    type="addUser"
                    className="tw-mr-2 tw-text-xl"
                />
                <div className="tw-text-xs tw-font-bold">Assign user</div>
            </LayoutRow>
        </Button>
    );
};

export { AppleEndpoint };
