import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import React from 'react';

const StyledLinearProgress = styled(LinearProgress)({
    flexGrow: 1,
    borderRadius: 2,
});

export type LoadingLinearProps = LinearProgressProps & {
    className?: string;
};

export const LoadingLinear: React.FC<LoadingLinearProps> = ({ className, variant = 'indeterminate', value = 10, color = 'primary', ...props }) => (
    <StyledLinearProgress
        className={classNames('cs-loading-linear', className)}
        variant={variant}
        value={value}
        color={color}
        {...props}
    />
);

export default LoadingLinear;
