import { Paper, Tab, Tabs } from '@capasystems/ui';
import { Url, isDefined } from '@capasystems/utils';
import { TAndroidDeviceWithId } from '@db/party';
import { Portal } from '@mui/base';
import {
    AndroidApplicationRulesConfiguration,
    AndroidBluetoothConfiguration,
    AndroidCellularConfiguration,
    AndroidConnectivityManagementConfiguration,
    AndroidCrossProfileConfiguration,
    AndroidKioskConfiguration,
    AndroidLocationConfiguration,
    AndroidNetworkConfiguration,
    AndroidPasswordConfiguration,
    AndroidPersistentPreferredActivitiesConfiguration,
    AndroidPersonalUsageConfiguration,
    AndroidPlaystoreConfiguration,
    AndroidPolicyEnforcementRulesConfiguration,
    AndroidReportingConfiguration,
    AndroidRestrictionsConfiguration,
    AndroidSecurityConfiguration,
    AndroidSystemConfiguration,
    AndroidVpnConfiguration,
    AndroidWifiConfiguration,
} from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { default as classNames } from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder, SchemaBuilder } from '../../../../../index';

const configurationTypes = [
    AndroidWifiConfiguration.schema,
    AndroidPasswordConfiguration.schema,
    AndroidVpnConfiguration.schema,
    AndroidApplicationRulesConfiguration.schema,
    AndroidBluetoothConfiguration.schema,
    AndroidCellularConfiguration.schema,
    AndroidKioskConfiguration.schema,
    AndroidLocationConfiguration.schema,
    AndroidNetworkConfiguration.schema,
    AndroidReportingConfiguration.schema,
    AndroidSecurityConfiguration.schema,
    AndroidSystemConfiguration.schema,
    AndroidPersistentPreferredActivitiesConfiguration.schema,
    AndroidPlaystoreConfiguration.schema,
    AndroidPersonalUsageConfiguration.schema,
    AndroidRestrictionsConfiguration.schema,
    AndroidCrossProfileConfiguration.schema,
    AndroidPolicyEnforcementRulesConfiguration.schema,
    AndroidConnectivityManagementConfiguration.schema,
].sort((a, b) => {
    const categoryCalc = a.category.toLocaleLowerCase().localeCompare(b.category.toLocaleLowerCase());
    if (categoryCalc === 0) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
        return categoryCalc;
    }
});

export type AndroidEndpointAppliedConfigurationsTabProps = {
    endpoint?: TAndroidDeviceWithId;
    portalContainer: React.MutableRefObject<any>;
};

export const AndroidEndpointAppliedConfigurationsTab: React.FC<AndroidEndpointAppliedConfigurationsTabProps> = ({ endpoint, portalContainer }) => {
    const [selectedTab, setSelectedTab] = useState<string | null | undefined>(Url.getString('schemaId', configurationTypes[0].schemaId));
    const [schemaBuilderSchema, setSchemaBuilderSchema] = useState<any>(
        configurationTypes.find((cc) => cc.schemaId === selectedTab) || { title: 'Endpoint not found' }
    );

    const changeTab = (_: any, tab: any) => {
        const configurationType = configurationTypes.find((cc) => cc.schemaId === tab);
        setSchemaBuilderSchema(configurationType);
        Url.set('schemaId', tab);
        setSelectedTab(tab);
    };
    const queryBuilderProfiles = getDefaultQueryBuilderConfiguration({ placeholder: 'Search policy' });
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const propertiesBuilderRef = useRef(endpoint?.resultingPolicy || {});
    const [resultingPolicy, setResultingPolicy] = useState(endpoint?.resultingPolicy || {});
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const queryBuilderRef = useRef({});

    const onSubmit = ([activeLeaf]: [any]) => {
        const search = activeLeaf ? activeLeaf.value.toLowerCase() : '';
        setSearchTerm(search);
    };

    useEffect(() => {
        if (isDefined(endpoint)) {
            if (endpoint?.resultingPolicy) {
                propertiesBuilderRef.current = endpoint.resultingPolicy;
                const keys = Object.keys(endpoint.resultingPolicy);
                if (keys.length !== 0) {
                    const tab = configurationTypes.find((ct) => {
                        return Object.keys(ct.properties).some((propertiesKey) => keys.includes(propertiesKey));
                    });
                    setSchemaBuilderSchema(tab);
                    Url.set('schemaId', tab?.schemaId);
                    setSelectedTab(tab?.schemaId);
                }

                setResultingPolicy(endpoint.resultingPolicy);
                setLoading(false);
            } else {
                setErrorMessage('Endpoint data is missing or invalid');
                setLoading(false);
            }
        }
    }, [endpoint]);

    const memorizedTabs = useMemo(() => {
        const tabs: any[] = [];
        const propertiesBuilderRefKeys = Object.keys(resultingPolicy);
        Object.entries(Object.groupBy(configurationTypes, (ct) => ct.category))
            .sort(([keyA], [keyB]) => keyA.toLocaleLowerCase().localeCompare(keyB.toLocaleLowerCase()))
            .forEach(([key, ctArray]) => {
                const categoryMatches = key.toLowerCase().includes(searchTerm.toLowerCase());

                const matchingCTs = ctArray?.filter(
                    (ct) =>
                        Object.keys(ct.properties).some((propertiesKey) => propertiesBuilderRefKeys.includes(propertiesKey)) &&
                        (ct.title.toLowerCase().includes(searchTerm.toLowerCase()) || categoryMatches)
                );

                if (matchingCTs && matchingCTs.length > 0) {
                    tabs.push(
                        <Tab
                            value={key}
                            disabled
                            className={classNames('tw-mb-1 tw-min-h-0 tw-py-0 tw-opacity-100', { 'tw-mt-2': tabs.length === 0, 'tw-mt-6': tabs.length !== 0 })}
                            label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{key}</div>}
                            key={'cat - ' + key}
                        />
                    );

                    matchingCTs.forEach((ct) => {
                        tabs.push(
                            <Tab
                                value={ct.schemaId}
                                classes={{ root: 'tw-pl-4' }}
                                label={
                                    <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                        <div className="tw-mr-2 tw-text-left">{ct.title}</div>
                                    </div>
                                }
                                key={'tab - ' + ct.title}
                                disableRipple
                            />
                        );
                    });
                }
            });
        return tabs;
    }, [resultingPolicy, searchTerm]);

    if (loading) {
        if (errorMessage) {
            return (
                <div>
                    <h2 className="tw-mb-4">{errorMessage}</h2>
                </div>
            );
        }
        return null;
    }
    if (!propertiesBuilderRef.current || Object.keys(propertiesBuilderRef.current).length === 0) {
        return (
            <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                <div className="tw-p-6 tw-font-semibold">No endpoint data found</div>
            </div>
        );
    }

    return (
        <>
            <Portal container={portalContainer.current}>
                <QueryBuilder
                    // @ts-expect-error - QueryBuilder does not support TS yet
                    defaultConfiguration={queryBuilderProfiles}
                    onInit={onSubmit}
                    onSubmit={onSubmit}
                    className="tw-mx-auto tw-w-full"
                    ref={queryBuilderRef}
                />
            </Portal>
            <div className="tw-col-span-1 tw-flex tw-h-full tw-overflow-hidden">
                <div
                    className="tw-h-full"
                    style={{ minWidth: 275 }}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={changeTab}
                        orientation="vertical"
                        pills
                        className="tw-h-full"
                        variant="scrollable"
                    >
                        {memorizedTabs}
                    </Tabs>
                </div>
                <Paper
                    className="tw-h-full tw-overflow-auto tw-shadow-sm"
                    style={{ flexGrow: 1, marginLeft: '20px' }}
                >
                    <SchemaBuilder
                        key={selectedTab}
                        readOnly
                        schema={schemaBuilderSchema}
                        currentState={propertiesBuilderRef.current}
                    />
                </Paper>
            </div>
        </>
    );
};
