import classNames from 'classnames';
import React from 'react';
import { PremiumFeature } from '../contact/contact.components';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export type DriversPermissionsCheckProps = {
    teaserProps?: DriversTeaserProps;
    children: React.ReactNode;
};

export const DriversPermissionsCheck: React.FC<DriversPermissionsCheckProps> = ({ children, teaserProps = {} }) => {
    const { permissions } = useAuthContext();
    if (permissions.canManageDrivers) {
        return children;
    }
    return <DriversTeaser {...teaserProps} />;
};

export type DriversTeaserProps = {
    className?: string;
};

const DriversTeaser: React.FC<DriversTeaserProps> = ({ className }) => {
    return (
        <div className={classNames('tw-h-full tw-p-0.5', className)}>
            <WidgetPaper
                headerless
                className="tw-h-full"
            >
                <PremiumFeature
                    description="CapaOne Drivers automates driver updates, keeping your endpoints secure and running smoothly. Outdated drivers are automatically identified and updated, saving you a lot of time and frustration."
                    icon="capaDrivers"
                    defaultSubject="CapaOne Drivers"
                    productPage="https://www.capasystems.com/capaone-driver-update-software"
                />
            </WidgetPaper>
        </div>
    );
};
