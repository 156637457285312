import classnames from 'classnames';
import React from 'react';
import './topbar.scss';

export type TopbarProps = {
    children: React.ReactNode;
    className?: string;
};

export const Topbar: React.FC<TopbarProps> = ({ className, ...rest }) => {
    return (
        <div
            className={classnames('cs-topbar-area tw-shadow', className)}
            {...rest}
        />
    );
};

export default Topbar;
