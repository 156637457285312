//Documentation
//AirPlay
//https://developer.apple.com/documentation/devicemanagement/airplay

export const AppleAirPlayConfiguration = {
    schema: {
        title: 'AirPlay',
        type: 'object',
        schemaId: 'appleAirPlayConfiguration',
        properties: {
            airPlay: {
                title: 'AirPlay',
                description: 'The configuring for AirPlay settings.',
                type: 'array',
                help: 'com.apple.airplay', //Specify com.apple.airplay as the payload type.
                items: {
                    type: 'object',
                    properties: {
                        allowList: {
                            title: 'Allow List',
                            description: 'List of allowed AirPlay destinations',
                            type: 'array',
                            items: {
                                type: 'object',
                                required: ['DeviceID'],
                                properties: {
                                    DeviceID: {
                                        type: 'string',
                                        help: 'The device ID of the AirPlay destination in the format xx:xx:xx:xx:xx:xx',
                                    },
                                },
                            },
                        },
                        passwords: {
                            title: 'Passwords',
                            description: 'Passwords for known AirPlay destinations',
                            type: 'array',
                            items: {
                                type: 'object',
                                required: ['DeviceID'],
                                properties: {
                                    deviceID: {
                                        type: 'string',
                                        description: 'The device ID of the AirPlay destination in the format xx:xx:xx:xx:xx:xx',
                                        format: 'mac-address',
                                    },
                                    deviceName: {
                                        title: 'Device name',
                                        description: 'The name of the AirPlay destination used in iOS',
                                        type: 'string',
                                        required: true,
                                    },
                                    password: {
                                        title: 'Password',
                                        description: 'The password for the AirPlay destination',
                                        help: 'Using multiple entries for the same destination, whether within the same payload or across multiple installed payloads, is an error and results in undefined behavior',
                                        type: 'string',
                                        required: true,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
