/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from '@capasystems/ui';
import { isUndefined, Url } from '@capasystems/utils';
import { LEAF_ID, LOCAL_STORAGE_ID } from '@thirdparty/constants';
import { useNavigate, useLocalStorageState, useAnalytics, PERIODS, useAuthContext } from './../../../index';
import dayjs from 'dayjs';

const ReliabilityContext = React.createContext();

const ReliabilityContextProvider = ({ setUrl = true, children }) => {
    const analytics = useAnalytics();
    const { permissions } = useAuthContext();

    const navigate = useNavigate();
    const { severityId, deviceId } = useParams();
    const [coreState, setCoreState] = useState({
        lockedPeriod: null,
        sampleInterval: 'hour',
        timezone: dayjs.tz.guess(),
    });
    const [selectedPeriodId, setSelectedPeriodIdPrivate] = useLocalStorageState(
        setUrl ? LOCAL_STORAGE_ID.RELIABILITY_DASHBOARD_PERIOD : LOCAL_STORAGE_ID.RELIABILITY_HOME_DASHBOARD_PERIOD,
        PERIODS[0].id,
        setUrl ? Url.getString : () => null,
        setUrl ? Url.set : () => null,
        LEAF_ID.PERIOD
    );
    const [inDetailedMode, setInDetailedMode] = useLocalStorageState(LOCAL_STORAGE_ID.RELIABILITY_IN_DETAILED_MODE, false, Url.getBoolean);

    const setSelectedPeriodId = (e, newPeriodId) => {
        setSelectedPeriodIdPrivate(newPeriodId);
        const foundPeriod = PERIODS.find((period) => period.id === newPeriodId);
        if (foundPeriod) {
            setCoreState({
                lockedPeriod: foundPeriod.toLockedPeriod(),
                sampleInterval: foundPeriod.sampleInterval,
                timezone: dayjs.tz.guess(),
            });
        } else {
            const lockedPeriodArray = selectedPeriodId.split('|');
            setCustomPeriod({
                start: lockedPeriodArray[0],
                end: lockedPeriodArray[1],
            });
        }
        if (coreState.lockedPeriod && isUndefined(deviceId)) {
            analytics.sendPageView();
        }
    };

    const setCustomPeriod = ({ start, end }) => {
        const newLockedPeriod = `${start}|${end}`;
        setSelectedPeriodIdPrivate(newLockedPeriod);
        setCoreState({
            lockedPeriod: newLockedPeriod,
            sampleInterval: 'hour',
            timezone: dayjs.tz.guess(),
        });
    };

    const setSeverityId = (selectedSeverityId, logType) => {
        if (deviceId) {
            if (logType) {
                if (window.location.search === '') {
                    navigate.to(`windows/device/${deviceId}/reliability/${selectedSeverityId}${window.location.search}?logType=${logType}`);
                } else {
                    navigate.to(`windows/device/${deviceId}/reliability/${selectedSeverityId}${window.location.search}&logType=${logType}`);
                }
            } else {
                navigate.to(`windows/device/${deviceId}/reliability/${selectedSeverityId}${window.location.search}`);
            }
        } else {
            if (logType) {
                if (window.location.search === '') {
                    navigate.to(`windows/reliability/dashboard/${selectedSeverityId}${window.location.search}?logType=${logType}`);
                } else {
                    navigate.to(`windows/reliability/dashboard/${selectedSeverityId}${window.location.search}&logType=${logType}`);
                }
                analytics.sendPageView();
            } else {
                navigate.to(`windows/reliability/dashboard/${selectedSeverityId}${window.location.search}`);
                analytics.sendPageView();
            }
        }
    };

    useEffect(() => {
        setSelectedPeriodId({}, selectedPeriodId);
    }, []);

    return (
        <ReliabilityContext.Provider
            value={{
                selectedPeriodId,
                setCustomPeriod,
                setSelectedPeriodId,
                severityId,
                setSeverityId,
                deviceId,
                inDetailedMode: severityId ? false : inDetailedMode,
                setInDetailedMode,
                retentionDays: permissions.retentionDays.reliability,
                ...coreState,
            }}
        >
            {coreState.lockedPeriod && children}
        </ReliabilityContext.Provider>
    );
};

export { ReliabilityContext, ReliabilityContextProvider };
