export const AppleAppLockConfiguration = {
    schema: {
        title: 'AppLock (Kiosk Mode)',
        type: 'object',
        schemaId: 'applockConfiguration',
        skipIndent: true,
        category: 'App Management',
        properties: {
            'com.apple.app.lock': {
                title: '',
                type: 'object',
                properties: {
                    App: {
                        title: '',
                        type: 'object',
                        skipIndent: true,
                        properties: {
                            Identifier: {
                                title: 'Identifier',
                                description: 'The app´s bundle identifier',
                                type: 'appleapplicationsearchdialog',
                                required: true,
                            },
                            Options: {
                                title: 'AppLockApp Options',
                                type: 'object',
                                description: 'The dictionary of options to set for the app',
                                properties: {
                                    DisableAutoLock: {
                                        title: 'Disable AutoLock',
                                        description: 'If true, the device doesn´t automatically go to sleep after an idle period',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    DisableDeviceRotation: {
                                        title: 'Disable Device Rotation',
                                        description: 'If true, disables device rotation sensing',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    DisableRingerSwitch: {
                                        title: 'Disable Ringer Switch',
                                        description:
                                            'If true, disables the ringer switch. When disabled, the ringer behavior depends on what position the switch was in when it was first disabled',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    DisableSleepWakeButton: {
                                        title: 'Disable Sleep/Wake Button',
                                        description: 'If true, the system disables the sleep/wake button.',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    DisableTouch: {
                                        title: 'Disable Touch',
                                        description: 'If true, disables the touch screen. In tvOS, it disables the touch surface on the Apple TV Remote',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    DisableVolumeButtons: {
                                        title: 'Disable Volume Buttons',
                                        description: 'If true, disables the volume buttons',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableAssistiveTouch: {
                                        title: 'Enable AssistiveTouch',
                                        description: 'If true, enables AssistiveTouch',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableInvertColors: {
                                        title: 'Enable Invert Colors',
                                        description: 'If true, enables inverted colors',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableMonoAudio: {
                                        title: 'Enable Mono Audio',
                                        description: 'If true, enables mono audio',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableSpeakSelection: {
                                        title: 'Enable Speak Selection',
                                        description: 'If true, enables speak selection',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableVoiceOver: {
                                        title: 'Enable VoiceOver',
                                        description: 'If true, enables VoiceOver',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableZoom: {
                                        title: 'Enable Zoom',
                                        description: 'If true, enables zoom',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    EnableVoiceControl: {
                                        title: 'Enable VoiceControl',
                                        description: 'If true, enables Voice Control',
                                        type: 'boolean',
                                        default: false,
                                    },
                                },
                            },
                            UserEnabledOptions: {
                                title: 'User Enabled Options',
                                description: 'The dictionary of user-editable options to set for the app',
                                type: 'object',
                                properties: {
                                    AssistiveTouch: {
                                        title: 'Assistive Touch',
                                        description: 'If true, the system allows the user to toggle AssistiveTouch.',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    InvertColors: {
                                        title: 'Invert Colors',
                                        description: 'If true, the system allows the user to toggle Invert Colors.',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    VoiceOver: {
                                        title: 'Voice Over',
                                        description: 'If true, the system allows the user to toggle VoiceOver.',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    Zoom: {
                                        title: 'Zoom',
                                        description: 'If true, the system allows the user to toggle Zoom.',
                                        type: 'boolean',
                                        default: false,
                                    },
                                    VoiceControl: {
                                        title: 'Voice Control',
                                        description: 'If true, the system allows the user to toggle Voice Control.',
                                        type: 'boolean',
                                        default: false,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
