/* eslint-disable linebreak-style */
import React from 'react';
import './table.scss';
import PropTypes from 'prop-types';
import TableMUI from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import classNames from 'classnames';
import Loading from '../loading/loading';
import Padding from '../padding/padding';

const enumSorting = {
    ascending: 'asc',
    descending: 'desc',
};

const TABLE_ROW_CLASSES = {
    root: 'cs-table-row',
    hover: 'cs-table-row-hover',
    selected: 'cs-table-row-selected',
};
const TABLE_SORT_LABEL_CLASSES = {
    root: 'cs-table-cell-sortable-label',
    active: 'cs-table-cell-sortable-label-active',
    icon: 'cs-table-cell-sortable-label-icon',
};
const RESIZE = 'resize';

export class Table extends React.Component {
    static propTypes = {
        tabelData: PropTypes.shape({
            header: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.node,
                        PropTypes.func,
                        PropTypes.object, // In case React.memo is used.
                    ]),
                    sortingLabel: PropTypes.string,
                    sortable: PropTypes.bool,
                    align: PropTypes.oneOf(['left', 'right', 'center']),
                    colControle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                })
            ),
            rows: PropTypes.arrayOf(
                PropTypes.shape({
                    cells: PropTypes.arrayOf(
                        PropTypes.shape({
                            label: PropTypes.oneOfType([
                                PropTypes.string,
                                PropTypes.number,
                                PropTypes.node,
                                PropTypes.func,
                                PropTypes.object, // In case React.memo is used.
                            ]).isRequired,
                            id: PropTypes.any.isRequired,
                            align: PropTypes.oneOf(['left', 'right', 'center']),
                            styles: PropTypes.style,
                        })
                    ),
                    selected: PropTypes.bool,
                    id: PropTypes.any.isRequired,
                }).isRequired
            ),
            maxRowCount: PropTypes.number,
        }).isRequired,
        sortingDirection: PropTypes.oneOf(['asc', 'desc']),
        sortingColumn: PropTypes.string,
        tableHeight: PropTypes.string,
        hoverRow: PropTypes.bool,
        showRowCount: PropTypes.bool,
        loading: PropTypes.bool,
        resetScroll: PropTypes.bool,
        applyStickyHeader: PropTypes.bool,
        noWrap: PropTypes.bool,
        sortingHandler: PropTypes.func,
        selectRowHandler: PropTypes.func,
        scrolledToBottomHandler: PropTypes.func,
    };

    static defaultProps = {
        sortingDirection: enumSorting.descending,
        sortingColumn: '',
        tableHeight: '50vh',
        hoverRow: false,
        showRowCount: false,
        loading: false,
        resetScroll: false,
        applyStickyHeader: true,
        noWrap: false,
        sortingHandler: () => null,
        selectRowHandler: () => null,
        scrolledToBottomHandler: () => null,
    };

    constructor(props) {
        super(props);
        const { tabelData, sortingDirection, sortingColumn, loading } = this.props;
        this.state = {
            data: tabelData,
            sortingDirection,
            sortingLabel: sortingColumn,
            loading,
            tableWidth: null,
            tableContainerHeight: null,
        };
        this.tableContainerRef = React.createRef();
        this.tableMUIDomRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const update = {};
        /* istanbul ignore next */
        if (nextProps.tabelData !== prevState.data) {
            update.data = nextProps.tabelData;
        }

        /* istanbul ignore next */
        if (nextProps.sortingDirection !== prevState.sortingDirection) {
            update.sortingDirection = nextProps.sortingDirection;
        }

        /* istanbul ignore next */
        if (nextProps.sortingColumn !== prevState.sortingLabel) {
            update.sortingLabel = nextProps.sortingColumn;
        }

        /* istanbul ignore next */
        if (nextProps.loading !== prevState.loading) {
            update.loading = nextProps.loading;
        }

        if (nextProps.resetScroll !== prevState.resetScroll) {
            update.resetScroll = nextProps.resetScroll;
        }
        return update;
    }

    componentDidMount() {
        this.onWindowResize();
        window.addEventListener(RESIZE, this.onWindowResize);
    }

    /* istanbul ignore next */
    componentDidUpdate() {
        const { resetScroll } = this.props;
        if (resetScroll) {
            try {
                this.tableContainerRef.current.scrollTo(0, 0);
            } catch (_) {
                this.tableContainerRef.current.scrollTop = 0;
            }
        }
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        window.removeEventListener(RESIZE, this.onWindowResize);
    }

    /* istanbul ignore next */
    onWindowResize = () => {
        this.setState({
            tableWidth: this.tableMUIDomRef.current && this.tableMUIDomRef.current.offsetWidth ? this.tableMUIDomRef.current.offsetWidth : null,
            tableContainerHeight:
                this.tableContainerRef.current && this.tableContainerRef.current.offsetHeight ? this.tableContainerRef.current.offsetHeight : null,
        });
    };

    /* istanbul ignore next */
    handleHeader = () => {
        const { sortingHandler } = this.props;
        const { data, sortingDirection, sortingLabel: stateSortingLabel } = this.state;
        const onTableCellClick = (sortable, sortingLabel) => () => {
            if (sortable && sortingLabel) {
                sortingHandler(sortingLabel);
            }
        };
        const headerCell = data.header.map(({ sortable, sortingLabel, label, align = 'left' }) => (
            <TableCell
                className={classNames({
                    'cs-table-cell': true,
                    'cs-table-cell-sortable': sortable,
                })}
                key={label}
                align={align}
                onClick={onTableCellClick(sortable, sortingLabel)}
            >
                {sortable && sortingLabel ? (
                    <TableSortLabel
                        classes={TABLE_SORT_LABEL_CLASSES}
                        active={sortingLabel === stateSortingLabel}
                        direction={sortingDirection}
                    >
                        {label}
                    </TableSortLabel>
                ) : (
                    /* istanbul ignore next */ label
                )}
            </TableCell>
        ));
        return headerCell;
    };

    handleRows = () => {
        const { hoverRow, selectRowHandler } = this.props;
        const { data } = this.state;
        /* istanbul ignore next */
        const onTableRowClick = (rowIndex) => () => selectRowHandler(rowIndex);
        const rows = [];
        data.rows.forEach((row, rowIndex) => {
            rows.push(
                <TableRow
                    classes={TABLE_ROW_CLASSES}
                    hover={hoverRow}
                    key={row.id}
                    selected={row.selected}
                    onClick={onTableRowClick(rowIndex)}
                >
                    {row.cells.map(({ id, styles, label, align = 'left' }) => (
                        <TableCell
                            className="cs-table-cell"
                            key={id}
                            align={align}
                            style={styles}
                        >
                            {label}
                        </TableCell>
                    ))}
                </TableRow>
            );
        });
        return rows;
    };

    /* istanbul ignore next */
    onScroll = (e) => {
        const { scrolledToBottomHandler } = this.props;
        const { loading } = this.state;
        if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight && !loading) {
            scrolledToBottomHandler();
        }
    };

    /* istanbul ignore next */
    defaultSortingHandler(givenSortingLabel) {
        const { sortingLabel, sortingDirection } = this.state;
        const isDescending = sortingDirection === enumSorting.descending;
        if (givenSortingLabel === sortingLabel) {
            if (isDescending) {
                this.setState({ sortingDirection: enumSorting.ascending });
            } else {
                this.setState({ sortingDirection: enumSorting.descending });
            }
        }
    }

    render() {
        const { data, loading, tableWidth, tableContainerHeight } = this.state;
        const { showRowCount, tableHeight, applyStickyHeader, noWrap } = this.props;
        /* istanbul ignore next */
        if (!data || Object.keys(data).length === 0) {
            return false;
        }
        const cols = data.header.length > 0 && (
            <colgroup>
                {data.header.map((head) => {
                    const colWidth = head.colControle ? /* istanbul ignore next */ head.colControle : '100%';
                    return (
                        <col
                            key={`col${head.label}`}
                            width={colWidth}
                        />
                    );
                })}
            </colgroup>
        );
        return (
            <React.Fragment>
                <div
                    className="cs-table-container"
                    style={{ height: tableHeight, overflowY: 'auto' }}
                    onScroll={this.onScroll}
                    ref={this.tableContainerRef}
                >
                    <TableMUI
                        ref={this.tableMUIDomRef}
                        className={classNames({
                            'cs-table': true,
                            'cs-table-no-wrap': noWrap,
                        })}
                    >
                        {cols}
                        <TableHead
                            className={classNames({
                                'cs-table-head': true,
                                'cs-table-head-sticky': applyStickyHeader,
                            })}
                        >
                            <TableRow classes={TABLE_ROW_CLASSES}>{this.handleHeader()}</TableRow>
                        </TableHead>
                        <TableBody className="cs-table-body">{data.rows && this.handleRows()}</TableBody>
                    </TableMUI>
                    {loading && (
                        /* istanbul ignore next */
                        <div
                            style={{
                                position: 'sticky',
                                width: tableWidth,
                                height: tableContainerHeight,
                                marginBottom: -tableContainerHeight,
                                backgroundColor: 'rgba(255,255,255,0.8)',
                                zIndex: '100',
                                bottom: 0,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: tableContainerHeight,
                                    width: '100%',
                                }}
                            >
                                <Loading />
                            </div>
                        </div>
                    )}
                </div>
                {showRowCount && /* istanbul ignore next */ data.rows && (
                    <Padding>
                        {'Showing '}
                        {data.rows.length}
                        {data.maxRowCount && ` of ${data.maxRowCount}`}
                    </Padding>
                )}
            </React.Fragment>
        );
    }
}

export default Table;
