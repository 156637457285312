//Documentation
//https://support.apple.com/en-eg/guide/deployment/dep00ab7903/1/web/1.0 
export const AppleContactsConfiguration = { //Specify com.apple.carddav.account as the payload type
    schema: {
        title: 'Contacts',
        type: 'object',
        schemaId: 'appleContactsConfiguration',
        properties: {
            accountDescription: {
            title: 'Account',
            description: 'The display name for the account.',
            type: 'string',
            },
            hostName: {
                title: 'Host Name',
                description: 'IP address or fully qualified domain name (FQDN).',
                help: 'Example: xxx.xxx.x.xxx or servername.com',
                type: 'string',
                required: true,
            },
            port: {
                title: 'Port',
                description: 'The port number of the CardDAV server.',
                type: 'number',
                help: 'Value from 0 to 65535',
                required: true,
            },
            principalURL: {
                title: 'Principal URL',
                description: 'The principal URL for the CardDAV account.',
                help: 'Example: "https://dav.example.com"',
                type: 'string',
            },
            accountUserName: {
                title: 'Account User Name',
                description: 'The user name for the CardDAV account',
                type: 'string',
                required: true,
            },
            accountPassword: {
                title: 'Account Password',
                description: 'The password of the CardDAV account.',
                help: 'If you leave this field empty, users must enter their password after the payload is installed on the device.',
                type: 'string',
            },
            useSSL: {
                title: 'SSL',
                description: 'When you enable "Use SSL" and the server´s SSL certificate isn´t recognized by your device, use the "Certificates" payload to add any required root or intermediate certificates to ensure your server´s SSL certificate is trusted and validated',
                type: 'boolean',
            },
            communicationServiceRules: { //You can select a default app to be used when calling contacts from this account.
                title: 'Communication Service Rules',

            },

        },
    },
};
