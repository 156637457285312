export const AndroidSecurityConfiguration = {
    schema: {
        title: 'Security',
        category: 'Security',
        type: 'object',
        schemaId: 'securityConfiguration',
        properties: {
            maximumTimeToLock: {
                title: 'Maximum Time To Lock',
                description: 'Maximum time in milliseconds for user activity until the device locks.',
                help: 'Note: A value of 0 means there is no restriction.',
                type: 'string',
            },
            accountTypesWithManagementDisabled: {
                title: 'Account Types With Management Disabled',
                description: "Account types that can't be managed by the user.",
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            stayOnPluggedModes: {
                title: 'Stay On Plugged Modes',
                description: 'The battery plugged in modes for which the device stays on.',
                help: "It is recommended to also clear 'Maximum Time To Lock' so that the device doesn't lock itself while on.",
                type: 'array',
                items: {
                    type: 'string',
                    options: [
                        {
                            id: 'AC',
                            name: 'Power source is AC charger',
                        },
                        {
                            id: 'USB',
                            name: 'Power source is USB port',
                        },
                        {
                            id: 'WIRELESS',
                            name: 'Power source is wireless',
                        },
                    ],
                },
            },
            encryptionPolicy: {
                title: 'Encryption Policy',
                description: 'Whether encryption is enabled',
                type: 'string',
                options: [
                    {
                        id: 'ENCRYPTION_POLICY_UNSPECIFIED',
                        name: 'No encryption required',
                    },
                    {
                        id: 'ENABLED_WITHOUT_PASSWORD',
                        name: 'Encryption required without password',
                    },
                    {
                        id: 'ENABLED_WITH_PASSWORD',
                        name: 'Encryption required with password',
                    },
                ],
            },
            personalAppsThatCanReadWorkNotifications: {
                title: 'Personal Apps That Can Read Work Notifications',
                description: 'Personal apps that can read work profile notifications using a NotificationListenerService.',
                help: 'Note: By default, no personal apps (aside from system apps) can read work notifications. Each value in the list must be a package name.',
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            advancedSecurityOverrides: {
                title: 'Advanced Security Overrides',
                description: 'Security policies set to secure values by default.',
                help: "Note: To maintain the security posture of a device, we don't recommend overriding any of the default values.",
                type: 'object',
                properties: {
                    untrustedAppsPolicy: {
                        title: 'Untrusted Apps Policy',
                        description: 'The policy for untrusted apps (apps from unknown sources) enforced on the device.',
                        type: 'string',
                        options: [
                            {
                                id: 'DISALLOW_INSTALL',
                                name: 'Disallow untrusted app installs on entire device',
                            },
                            {
                                id: 'ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY',
                                name: 'Allow untrusted app installs on personal profile only',
                            },
                            {
                                id: 'ALLOW_INSTALL_DEVICE_WIDE',
                                name: 'Allow untrusted app installs on entire device',
                            },
                        ],
                    },
                    googlePlayProtectVerifyApps: {
                        title: 'Google Play Protect Verify Apps',
                        description: 'Whether Google Play Protect verification is enforced.',
                        type: 'string',
                        options: [
                            {
                                id: 'VERIFY_APPS_ENFORCED',
                                name: 'Force-enable app verification',
                            },
                            {
                                id: 'VERIFY_APPS_USER_CHOICE',
                                name: 'User choice',
                            },
                        ],
                    },
                    developerSettings: {
                        title: 'Developer Settings',
                        description: 'Controls access to developer settings: developer options and safe boot.',
                        type: 'string',
                        options: [
                            {
                                id: 'DEVELOPER_SETTINGS_DISABLED',
                                name: 'Disable',
                            },
                            {
                                id: 'DEVELOPER_SETTINGS_ALLOWED',
                                name: 'Enable',
                            },
                        ],
                    },
                    commonCriteriaMode: {
                        title: 'Common Criteria Mode',
                        description:
                            "Controls 'Common Criteria Mode'—security standards defined in the 'Common Criteria for Information Technology Security Evaluation (CC)'. Enabling 'Common Criteria Mode' increases certain security components on a device, including AES-GCM encryption of Bluetooth Long Term Keys, and Wi-Fi configuration stores.",
                        help: "Warning: 'Common Criteria Mode' enforces a strict security model typically only required for IT products used in national security systems and other highly sensitive organizations. Standard device use may be affected. Only enabled if required.",
                        type: 'string',
                        options: [
                            {
                                id: 'COMMON_CRITERIA_MODE_DISABLED',
                                name: 'Disable',
                            },
                            {
                                id: 'COMMON_CRITERIA_MODE_ENABLED',
                                name: 'Enable',
                            },
                        ],
                    },
                    mtePolicy: {
                        title: 'Memory Tagging Extension',
                        description: 'Optional. Controls Memory Tagging Extension (MTE) on the device.',
                        help: 'The device needs to be rebooted to apply changes to the MTE policy. Supported on Android 14 and above.',
                        type: 'string',
                        options: [
                            {
                                id: 'MTE_USER_CHOICE',
                                name: 'The user can choose to enable or disable MTE on the device if the device supports this',
                            },
                            {
                                id: 'MTE_ENFORCED',
                                name: 'MTE is enabled on the device and the user is not allowed to change this setting',
                            },
                            {
                                id: 'MTE_DISABLED',
                                name: 'MTE is disabled on the device and the user is not allowed to change this setting',
                            },
                        ],
                    },
                },
            },
        },
    },
};
