import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Padding, Page, useParams } from '@capasystems/ui';
import { isUndefined } from '@capasystems/utils';
import { useLocalStorageState, useNavigate, useAnalytics, UpdaterApplicationList, UpdaterDashboard, UpdaterInProgressList, PageTitle } from '../../index';
import { LOCAL_STORAGE_ID } from '@thirdparty/constants';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';

const PAGE_TITLE_DASHBOARD = ['Updater', 'Dashboard'];
const PAGE_TITLE_LIST = ['Updater', 'List'];
const PAGE_TITLE_IN_PROGRESS = ['Updater', 'In progress'];

const TAB_ID = {
    DASHBOARD: 'dashboard',
    LIST: 'list',
    IN_PROGRESS: 'inProgress',
};

const UpdaterLandingPage = () => {
    const analytics = useAnalytics();
    const { tabId } = useParams();
    const navigate = useNavigate();
    const [latestTab, setSelectedTabId] = useLocalStorageState(LOCAL_STORAGE_ID.SELECTED_UPDATER_TAB, TAB_ID.LIST);

    const [portalContainer, setPortalContainer] = useState(null);

    const onRefChange = useCallback((node) => {
        setPortalContainer(node);
    }, []);

    const onTabChange = (e, newTabId) => {
        setSelectedTabId(newTabId);
        navigate.to(`windows/updater/${newTabId}`);
    };

    if (isUndefined(tabId)) {
        return (
            <Navigate
                to={latestTab}
                replace
            />
        );
    }

    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-1 tw-grid-rows-auto-1fr tw-gap-y-4 tw-p-4">
            <div className="tw-grid tw-grid-cols-1fr-auto">
                <Tabs
                    value={tabId}
                    onChange={onTabChange}
                    onRails
                >
                    <Tab
                        value={TAB_ID.DASHBOARD}
                        label="Dashboard"
                    />
                    <Tab
                        value={TAB_ID.LIST}
                        label="List"
                    />
                    <Tab
                        value={TAB_ID.IN_PROGRESS}
                        label="In progress"
                    />
                </Tabs>
                <div
                    className="tw-flex tw-w-full tw-items-end tw-pl-4"
                    ref={onRefChange}
                />
            </div>
            {tabId === TAB_ID.DASHBOARD && portalContainer !== null && (
                <div className="tw-overflow-auto">
                    <Page title={PAGE_TITLE_DASHBOARD}>
                        <UpdaterDashboard portalContainer={portalContainer} />
                    </Page>
                </div>
            )}

            {tabId === TAB_ID.LIST && portalContainer !== null && (
                <Page
                    title={PAGE_TITLE_LIST}
                    onInit={analytics.sendPageView}
                >
                    <UpdaterApplicationList portalContainer={portalContainer} />
                </Page>
            )}
            {tabId === TAB_ID.IN_PROGRESS && portalContainer !== null && (
                <Page
                    title={PAGE_TITLE_IN_PROGRESS}
                    onInit={analytics.sendPageView}
                >
                    <UpdaterInProgressList portalContainer={portalContainer} />
                </Page>
            )}
        </div>
    );
};
export { UpdaterLandingPage };
