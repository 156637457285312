/* eslint-disable linebreak-style */
import { getTheme } from '@capasystems/utils';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { DragDropContext } from './drag-and-drop-provider';

const { capasystems } = getTheme();

export const DropZone = React.memo(
    ({
        group,
        data,
        onDrop,
        children,
        style = {},
        acceptedTypes,
        acceptedColor = capasystems.palette.success.light,
        id,
        shouldBlowUp = false,
        blowUpFactor = '1.05',
        isSearch,
    }) => {
        /* istanbul ignore next */
        data.dropTypes = acceptedTypes;
        /* istanbul ignore next */
        data.group = group;
        /* istanbul ignore next */
        data.id = id;

        /* istanbul ignore next */
        let cancelCountDown = null;

        /* istanbul ignore next */
        const dragDropContext = useContext(DragDropContext);

        /* istanbul ignore next */
        const containsAccepted = dragDropContext.checkContainAcceptedItems(data);

        /* istanbul ignore next */
        const [isOver, setIsOver] = useState(false);

        /* istanbul ignore next */
        const shouldPreventDefault = (e, tempId) => {
            if (tempId === id) {
                clearTimeout(cancelCountDown);
            }
            e.stopPropagation();
            if (containsAccepted) {
                e.preventDefault();
                setIsOver(true);
            }
        };

        /* istanbul ignore next */
        return (
            <div
                className="cs-drop-zone"
                style={{
                    background: (dragDropContext.isDragging && containsAccepted) || isSearch ? acceptedColor : 'transparent',
                    transform: shouldBlowUp && isOver && `scale(${blowUpFactor})`,
                    ...style,
                }}
                onDrop={() => {
                    dragDropContext.resetSelectable();
                    if (onDrop) {
                        onDrop(data, dragDropContext.selected);
                    }
                    dragDropContext.setIsDragging(false);
                    dragDropContext.removeAllItems();
                    setIsOver(false);
                }}
                onDragOver={(e) => {
                    shouldPreventDefault(e, id);
                }}
                onDragLeave={() => {
                    clearTimeout(cancelCountDown);
                    cancelCountDown = setTimeout(() => {
                        setIsOver(false);
                    }, 25);
                }}
            >
                {children}
            </div>
        );
    }
);

DropZone.propTypes = {
    children: node.isRequired,
    style: shape(),
    acceptedTypes: arrayOf(string).isRequired,
    acceptedColor: string,
    group: string.isRequired,
    data: shape().isRequired,
    onDrop: func.isRequired,
    id: string.isRequired,
    shouldBlowUp: bool,
    blowUpFactor: oneOfType([string, number]),
};

export default DropZone;
