import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const Tree = ({ className, ...otherProps }) => (
    <ul
        className={classNames(className, 'cs-tree')}
        {...otherProps}
    />
);

Tree.propTypes = {
    className: PropTypes.string,
};

export default Tree;
