import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    CircularProgressWithLabel,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Grid,
    Icon,
    IconButton,
    Input,
    InputProps,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Select,
    Tab,
    Tabs,
    Tooltip,
} from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { AZURE, CRON_DAY_OF_MONTH, CRON_HOURS_WITH_MINUTES, CRON_WEEK_DAYS } from '@thirdparty/constants';
import { cronExtractor } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import { useManagementApi } from '../hooks/useApi/useApi';
import { useNavigate } from '../hooks/useNavigate/useNavigate';
import { useManagementIntegrationsSocket } from '../hooks/useSocket/useSocket';
import { PageTitle } from '../page/page-title';
import { ActionsDialog, WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { userPropertiesConstant } from './integration-management-components-constants';

const itemsSortingFunction = getSortingFunction({
    sortBy: 'name',
});

const monthSortingFunction = getSortingFunction(
    {
        sortBy: 'name',
    },
    true
);

const weekDaysSortingFunction = getSortingFunction(
    {
        sortBy: 'sortingIndex',
    },
    true
);

const CONFIRM_TEXT_LENGTH = 6;

const SYNC_FREQUENCY = {
    NEVER: 'NEVER',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export type IntegrationManagementInputFieldProps = Omit<InputProps, 'onChange'> & {
    label: string;
    className?: string;
    hasError?: boolean;
    name: string;
    onChange?: () => void;
    currentRef: any;
};

const IntegrationManagementInputField: React.FC<IntegrationManagementInputFieldProps> = ({
    label,
    className,
    hasError,
    name,
    onChange,
    currentRef,
    ...props
}) => {
    const [isValid, setIsValid] = useState(currentRef && currentRef[name] !== '');

    return (
        <div
            className={classNames(className, {
                'tw-mb-6': true,
            })}
        >
            <h2 className="tw-mb-2 tw-text-xs">{label}</h2>
            <Input
                callToAction
                light
                endAdornment={
                    isValid ? (
                        <Icon
                            type="checkmark"
                            size="small"
                            className="tw-mr-1 tw-text-emerald-500"
                        />
                    ) : (
                        <span className="tw-mr-1 tw-text-xs tw-text-neutral-300">Required</span>
                    )
                }
                onChange={(e) => {
                    currentRef[name] = e.target.value.trim();
                    setIsValid(currentRef[name] !== '');
                    onChange();
                }}
                name={name}
                {...props}
            />
        </div>
    );
};

export type IntegrationManagementAzureFormProps = {
    open: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    data?: any;
    isViewMode?: boolean;
};

const IntegrationManagementAzureForm: React.FC<IntegrationManagementAzureFormProps> = ({ open, onCancel, onSubmit, data = null, isViewMode = false }) => {
    const managementApi = useManagementApi();
    const [isProcessing, setIsProcessing] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [formIsInvalid, setFormIsInvalid] = useState(true);
    const [showContent, setShowContent] = useState(false);
    const [syncFrequency, setSyncFrequency] = useState(SYNC_FREQUENCY.NEVER);
    const [showAddNewSecret, setShowAddNewSecret] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedHour, setSelectedHour] = useState<any[]>([]);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<any[]>([]);
    const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState<any[]>([]);
    const [selectedUserProperties, setSelectedUserProperties] = useState<any[]>([]);

    const formRef = useRef<any>({});

    const validateForm = () => {
        if (!isViewMode) {
            setFormIsInvalid(Object.values(formRef.current).some((value: any) => value.trim() === ''));
        }
    };

    const onTabChange = (_: any, tabValue: string) => {
        setSyncFrequency(tabValue);
        validateForm();
    };

    const onCreate = () => {
        if (isViewMode) return;

        setIsProcessing(true);
        setErrorMessage(null);

        const errorHandler = (errorResponse: any) => {
            setErrorMessage(errorResponse.data.message || 'An unknown error occurred');
            console.log(errorResponse);
            setIsProcessing(false);
        };

        const syncConfiguration: {
            cronExpression: string | null;
            tz: string;
        } = {
            cronExpression: null,
            tz: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        if (syncFrequency !== SYNC_FREQUENCY.NEVER) {
            const hourPart =
                selectedHour
                    .map(({ id }) => id)
                    .sort((a, b) => a - b)
                    .join() || '0';
            if (syncFrequency === SYNC_FREQUENCY.MONTHLY && selectedDaysOfMonth.length !== CRON_DAY_OF_MONTH.length) {
                const monthPart =
                    selectedDaysOfMonth
                        .map(({ id }) => id)
                        .sort((a, b) => a - b)
                        .join() || '*';
                syncConfiguration.cronExpression = `0 ${hourPart} ${monthPart} * *`;
            } else if (syncFrequency === SYNC_FREQUENCY.WEEKLY && selectedDaysOfWeek.length !== CRON_WEEK_DAYS.length) {
                const weekPart =
                    selectedDaysOfWeek
                        .map(({ id }) => id)
                        .sort((a, b) => a - b)
                        .join() || '*';
                syncConfiguration.cronExpression = `0 ${hourPart} * * ${weekPart}`;
            } else {
                syncConfiguration.cronExpression = `0 ${hourPart} * * *`;
            }
        }

        const userProperties = selectedUserProperties.map(({ id }) => id);

        if (data) {
            managementApi
                .updateIntegrationService(data.id, {
                    name: formRef.current.name,
                    syncConfiguration,
                    userProperties,
                    clientSecret: formRef.current.newClientSecret, // Might be undefined which is fine.
                    clientSecretExpiration: formRef.current.clientSecretExpiration,
                })
                .then(() => {
                    onSubmit();
                })
                .catch(errorHandler);
        } else {
            managementApi
                .createIntegrationService({
                    ...formRef.current,
                    syncConfiguration,
                    userProperties,
                })
                .then(() => {
                    onSubmit();
                })
                .catch(errorHandler);
        }
    };

    const onEnter = () => {
        setFormIsInvalid(true);
        setIsProcessing(false);
        setHasError(false);
        formRef.current = {
            name: data?.name || '',
            clientId: data?.clientId || '',
            tenantId: data?.tenantId || '',
            clientSecret: data?.obfuscatedClientSecret || '',
            type: data?.type || 'AZURE',
            clientSecretExpiration: data?.clientSecretExpiration || '',
        };

        if (data?.userProperties) {
            const tempSelectedUserProperties = userPropertiesConstant.filter((up) => data.userProperties.indexOf(up.id) > -1);
            setSelectedUserProperties(tempSelectedUserProperties);
        } else {
            setSelectedUserProperties([]);
        }

        if (data?.syncConfiguration.cronExpression) {
            const [, hoursOfDay, dayOfMonth, , dayOfWeek] = data.syncConfiguration.cronExpression.split(' ').map((v: string) => v.split(','));
            const tempSelectedHour = CRON_HOURS_WITH_MINUTES.filter((h) => hoursOfDay.indexOf(h.id) > -1);
            const tempSelectedDaysOfWeek = CRON_WEEK_DAYS.filter((wd) => dayOfWeek.indexOf(wd.id) > -1);
            const tempSelectedDaysOfMonth = CRON_DAY_OF_MONTH.filter((md) => dayOfMonth.indexOf(md.id) > -1);
            setSelectedHour(tempSelectedHour);
            setSelectedDaysOfWeek(tempSelectedDaysOfWeek);
            setSelectedDaysOfMonth(tempSelectedDaysOfMonth);
            if (tempSelectedDaysOfMonth.length > 0) {
                setSyncFrequency(SYNC_FREQUENCY.MONTHLY);
            } else if (tempSelectedDaysOfWeek.length > 0) {
                setSyncFrequency(SYNC_FREQUENCY.WEEKLY);
            } else {
                setSyncFrequency(SYNC_FREQUENCY.DAILY);
            }
        } else {
            setSelectedHour([]);
            setSelectedDaysOfWeek([]);
            setSelectedDaysOfMonth([]);
            setSyncFrequency(SYNC_FREQUENCY.NEVER);
        }
        setShowContent(true);
    };

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onEnter={onEnter}
            onExited={() => {
                setShowAddNewSecret(false);
                setErrorMessage(null);
                setShowContent(false);
            }}
            unmountOnExit
            confirm
        >
            <DialogTitle>
                <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-4">
                    <Icon
                        type="azure"
                        className="tw-h-12 tw-w-12 tw-text-azure"
                    />
                    <PageTitle category={data ? (isViewMode ? 'View' : 'Edit') : 'New'}>Azure AD integration</PageTitle>
                    <Button
                        noMargin
                        href="https://capasystems.atlassian.net/wiki/spaces/CP/pages/19499810840/CapaOne+-+Azure+AD+intergration"
                        target="_BLANK"
                        rel="noreferrer"
                        size="small"
                    >
                        Need help?
                    </Button>
                </div>
                <Collapse
                    in={errorMessage !== null}
                    unmountOnExit
                >
                    <div className="tw-mt-4 tw-rounded tw-bg-red-50 tw-py-2 tw-pl-4 tw-pr-1 tw-text-red-600 tw-ring-1 tw-ring-red-100">
                        <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-4">
                            <b className="tw-text-sm">{errorMessage}</b>
                            <IconButton
                                noMargin
                                color="inherit"
                                onClick={() => setErrorMessage(null)}
                            >
                                <Icon
                                    type="remove"
                                    size="small"
                                />
                            </IconButton>
                        </div>
                    </div>
                </Collapse>
            </DialogTitle>

            {showContent && (
                <DialogContent>
                    {data !== null && (
                        <div className="tw-mb-4 tw-rounded tw-bg-slate-50 tw-px-4 tw-py-4 tw-pb-2 tw-pr-2 tw-text-slate-900 tw-ring-1 tw-ring-slate-100">
                            <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-2">
                                <div className="tw-mb-2 tw-text-xs tw-font-semibold">Application (client) ID</div>
                                <code className="tw-mb-2 tw-mr-2 tw-text-right tw-text-xs tw-font-semibold">{formRef.current.clientId}</code>
                                <div className="tw-text-xs tw-font-semibold">Directory (tenant) ID</div>
                                <code className="tw-mr-2 tw-text-right tw-text-xs tw-font-semibold">{formRef.current.tenantId}</code>
                                <div className="tw-text-xs tw-font-semibold">Client secret value</div>
                                <div className="tw-text-right">
                                    <code className="tw-font-semibold">{formRef.current.clientSecret}</code>
                                    {!isViewMode && (
                                        <IconButton
                                            noMargin
                                            className="tw-ml-2"
                                            color="inherit"
                                            onClick={() => {
                                                if (showAddNewSecret) {
                                                    delete formRef.current.newClientSecret;
                                                } else {
                                                    formRef.current.newClientSecret = '';
                                                }
                                                setShowAddNewSecret(!showAddNewSecret);
                                                validateForm();
                                            }}
                                        >
                                            <Tooltip
                                                content={showAddNewSecret ? 'Keep using current client secret' : 'Update your client secret...'}
                                                dark
                                                extraPadding
                                                bold
                                                position={TOOLTIP.POSITION.LEFT}
                                            >
                                                {showAddNewSecret ? (
                                                    <Icon
                                                        type="block"
                                                        size="small"
                                                    />
                                                ) : (
                                                    <Icon
                                                        type="edit"
                                                        size="small"
                                                    />
                                                )}
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <IntegrationManagementInputField
                        label="Name"
                        defaultValue={formRef.current.name as string}
                        disabled={isProcessing || isViewMode}
                        onChange={validateForm}
                        currentRef={formRef.current}
                        name="name"
                        hasError={hasError}
                        autoFocus
                    />
                    {data === null && (
                        <>
                            <IntegrationManagementInputField
                                label="Application (client) ID"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                defaultValue={formRef.current.clientId}
                                disabled={isProcessing || isViewMode}
                                onChange={validateForm}
                                currentRef={formRef.current}
                                name="clientId"
                                hasError={hasError}
                            />
                            <IntegrationManagementInputField
                                label="Directory (tenant) ID"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                defaultValue={formRef.current.tenantId}
                                disabled={isProcessing || isViewMode}
                                onChange={validateForm}
                                currentRef={formRef.current}
                                name="tenantId"
                                hasError={hasError}
                            />

                            <IntegrationManagementInputField
                                label="Client secret value"
                                placeholder="Client secret value"
                                defaultValue={formRef.current.clientSecret}
                                type="password"
                                disabled={isProcessing || isViewMode}
                                onChange={validateForm}
                                name="clientSecret"
                                currentRef={formRef.current}
                                hasError={hasError}
                                autoComplete="new-password"
                            />
                        </>
                    )}
                    {data !== null && !isViewMode && (
                        <Collapse
                            in={showAddNewSecret}
                            unmountOnExit
                        >
                            <IntegrationManagementInputField
                                label="New client secret value"
                                placeholder="New client secret value"
                                defaultValue={formRef.current.newClientSecret}
                                type="password"
                                disabled={isProcessing}
                                onChange={validateForm}
                                name="newClientSecret"
                                currentRef={formRef.current}
                                hasError={hasError}
                                autoComplete="new-password"
                            />
                        </Collapse>
                    )}
                    <IntegrationManagementInputField
                        label="Client secret expiration date"
                        defaultValue={dayjs(formRef.current.clientSecretExpiration).format('YYYY-MM-DD') || ''}
                        disabled={isProcessing || isViewMode}
                        onChange={validateForm}
                        currentRef={formRef.current}
                        name="clientSecretExpiration"
                        hasError={hasError}
                        autoFocus
                        type="date"
                    />

                    <div className="tw-mb-6">
                        <div className="tw-flex tw-space-x-1">
                            <h2 className="tw-mb-2 tw-text-xs">User Properties</h2>

                            <Tooltip
                                content="We keep the right to store the user properties ID and Email"
                                fullWidth
                                position={TOOLTIP.POSITION.RIGHT}
                            >
                                <Icon
                                    type="infoOutlined"
                                    size="small"
                                />
                            </Tooltip>
                        </div>
                        <Select
                            // @ts-ignore - this is not typed
                            selectedOptions={selectedUserProperties}
                            options={userPropertiesConstant}
                            multiple
                            onChange={(v: any) => {
                                setSelectedUserProperties(v);
                                validateForm();
                            }}
                            disabled={isProcessing || isViewMode}
                        />
                    </div>

                    <h2 className="tw-mb-2 tw-text-xs">Automatic synchronization</h2>
                    <Tabs
                        value={syncFrequency}
                        onChange={onTabChange}
                        pills
                        variant="fullWidth"
                        className="tw-mb-4"
                    >
                        <Tab
                            key={SYNC_FREQUENCY.NEVER}
                            disableRipple
                            label="Never"
                            value={SYNC_FREQUENCY.NEVER}
                            className="tw-text-xs"
                        />
                        <Tab
                            key={SYNC_FREQUENCY.DAILY}
                            disableRipple
                            label="Daily"
                            value={SYNC_FREQUENCY.DAILY}
                            className="tw-text-xs"
                        />
                        <Tab
                            key={SYNC_FREQUENCY.WEEKLY}
                            disableRipple
                            label="Weekly"
                            value={SYNC_FREQUENCY.WEEKLY}
                            className="tw-text-xs"
                        />
                        <Tab
                            key={SYNC_FREQUENCY.MONTHLY}
                            disableRipple
                            label="Monthly"
                            value={SYNC_FREQUENCY.MONTHLY}
                            className="tw-text-xs"
                        />
                    </Tabs>
                    <Collapse in={syncFrequency !== SYNC_FREQUENCY.NEVER}>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                            {syncFrequency === SYNC_FREQUENCY.MONTHLY && (
                                <div>
                                    <h2 className="tw-mb-2 tw-text-xs">At day(s) of month</h2>
                                    <Select
                                        // @ts-ignore - this is not typed
                                        selectedOptions={selectedDaysOfMonth}
                                        options={CRON_DAY_OF_MONTH}
                                        multiple
                                        onChange={(v: any) => {
                                            setSelectedDaysOfMonth(v.sort(monthSortingFunction));
                                            validateForm();
                                        }}
                                        placeholder="Every day of the month"
                                        callToAction
                                        disabled={isProcessing || isViewMode}
                                    />
                                </div>
                            )}
                            {syncFrequency === SYNC_FREQUENCY.WEEKLY && (
                                <div>
                                    <h2 className="tw-mb-2 tw-text-xs">At day(s) of the week</h2>
                                    <Select
                                        // @ts-ignore - this is not typed
                                        selectedOptions={selectedDaysOfWeek}
                                        options={CRON_WEEK_DAYS}
                                        multiple
                                        onChange={(v) => {
                                            setSelectedDaysOfWeek(v.sort(weekDaysSortingFunction));
                                            validateForm();
                                        }}
                                        placeholder="Every day of the week"
                                        callToAction
                                        disabled={isProcessing || isViewMode}
                                    />
                                </div>
                            )}
                            <div
                                className={classNames({
                                    'tw-mb-4': true,
                                    'tw-col-span-2': syncFrequency === SYNC_FREQUENCY.DAILY,
                                })}
                            >
                                <h2 className="tw-mb-2 tw-text-xs">At hour</h2>
                                <Select
                                    // @ts-ignore - this is not typed
                                    selectedOptions={selectedHour}
                                    options={CRON_HOURS_WITH_MINUTES}
                                    onChange={(v: any) => {
                                        setSelectedHour(v);
                                        validateForm();
                                    }}
                                    placeholder="00:00"
                                    callToAction
                                    required
                                    disabled={isProcessing || isViewMode}
                                />
                            </div>
                        </div>
                    </Collapse>
                </DialogContent>
            )}
            <DialogActions>
                {isProcessing ? (
                    <Loading
                        size={16}
                        className="tw-text- tw-mr-4"
                    />
                ) : (
                    !isViewMode && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onCreate}
                            disabled={formIsInvalid}
                        >
                            {data ? 'Save' : 'Create'}
                        </Button>
                    )
                )}
                <Button
                    onClick={onCancel}
                    disabled={isProcessing}
                >
                    {isViewMode ? 'Close' : 'Cancel'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const IntegrationManagementLandingPage = () => {
    const [showAzureDialog, setShowAzureDialog] = useState(false);

    const showIntegrationOptions = () => {
        setShowAzureDialog(true);
    };

    const closeAzureDialog = () => {
        setShowAzureDialog(false);
    };

    const onSubmit = () => {
        closeAzureDialog();
    };

    return (
        <Page title="Integrations">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div className="tw-flex tw-items-end tw-justify-end">
                    <Button
                        noMargin
                        color="primary"
                        variant="contained"
                        onClick={showIntegrationOptions}
                    >
                        New
                    </Button>
                    <IntegrationManagementAzureForm
                        open={showAzureDialog}
                        onCancel={closeAzureDialog}
                        onSubmit={onSubmit}
                    />
                </div>
                <IntegrationManagementAzureTable />
            </div>
        </Page>
    );
};

const syncAction = {
    id: 'sync',
    icon: 'cloudSync',
    name: 'Sync now',
};
const editAction = {
    id: 'edit',
    icon: 'editOutlined',
    color: BUTTON.DEFAULT,
    name: 'Edit',
    url: (integrationId: string) => `management/integration/${integrationId}/edit`,
};
const viewAction = {
    id: 'view',
    name: 'View integration',
};

const deleteAction = {
    id: 'delete',
    name: 'Delete',
};
const actions = [editAction, syncAction, viewAction, deleteAction];

type IntegrationManagementAzureTableProps = {
    isViewMode?: boolean;
};

const IntegrationManagementAzureTable: React.FC<IntegrationManagementAzureTableProps> = ({ isViewMode = false }) => {
    const [showLastSync, setShowLastSync] = useState(false);
    const managementApi = useManagementApi();
    const navigate = useNavigate();
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState<any>(null);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<any[]>([]);
    const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState<any[]>([]);
    const [syncFrequency, setSyncFrequency] = useState(SYNC_FREQUENCY.NEVER);
    const [selectedHour, setSelectedHour] = useState<any[]>([]);

    const formRef = useRef({});

    const validateForm = () => {
        if (!isViewMode) {
            // setFormIsInvalid(Object.values(formRef.current).some((value) => value.trim() === ''));
        }
    };

    const onTabChange = (_: any, tabValue: string) => {
        setSyncFrequency(tabValue);
        validateForm();
    };

    const [deleteState, setDeleteState] = useState<{
        open: boolean;
        isProcessing?: boolean;
        anErrorOccurred?: boolean;
        confirmText?: string;
        disabled?: boolean;
    }>({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });

    const openDrawer = (integration: any) => {
        setSelectedIntegration(integration);
        if (integration?.syncConfiguration?.cronExpression) {
            const [, hoursOfDay, dayOfMonth, , dayOfWeek] = integration.syncConfiguration.cronExpression.split(' ').map((v: any) => v.split(','));
            const tempSelectedHour = CRON_HOURS_WITH_MINUTES.filter((h) => hoursOfDay.indexOf(h.id) > -1);
            const tempSelectedDaysOfWeek = CRON_WEEK_DAYS.filter((wd) => dayOfWeek.indexOf(wd.id) > -1);
            const tempSelectedDaysOfMonth = CRON_DAY_OF_MONTH.filter((md) => dayOfMonth.indexOf(md.id) > -1);
            setSelectedHour(tempSelectedHour);
            setSelectedDaysOfWeek(tempSelectedDaysOfWeek);
            setSelectedDaysOfMonth(tempSelectedDaysOfMonth);
            if (tempSelectedDaysOfMonth.length > 0) {
                setSyncFrequency(SYNC_FREQUENCY.MONTHLY);
            } else if (tempSelectedDaysOfWeek.length > 0) {
                setSyncFrequency(SYNC_FREQUENCY.WEEKLY);
            } else {
                setSyncFrequency(SYNC_FREQUENCY.DAILY);
            }
        } else {
            setSelectedHour([]);
            setSelectedDaysOfWeek([]);
            setSelectedDaysOfMonth([]);
            setSyncFrequency(SYNC_FREQUENCY.NEVER);
        }
        setShowDrawer(true);
    };

    const closeDrawer = () => {
        setShowDrawer(false);
        setSelectedIntegration(null);
    };

    const [items, setItems] = useState<any[]>([]);
    const [showIntegrationManagementAzureForm, setShowIntegrationManagementAzureForm] = useState(false);

    const showLastSyncDialog = () => {
        setShowLastSync(true);
    };

    const closeLastSyncDialog = () => {
        setShowLastSync(false);
    };

    useManagementIntegrationsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setItems((currentItems) => {
                    return currentItems
                        .map((item) => {
                            if (item.id === documentId) {
                                return {
                                    ...fullDocument,
                                    id: documentId,
                                };
                            }
                            return item;
                        })
                        .sort(itemsSortingFunction);
                });
            } else if (insertOperation) {
                setItems((currentItems) => {
                    return [
                        ...currentItems,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ].sort(itemsSortingFunction);
                });
            } else if (deleteOperation) {
                setItems((currentItems) => {
                    return currentItems.filter((item) => item.id !== documentId);
                });
            }
        }, [])
    );

    useEffect(() => {
        managementApi
            .getIntegrationServices()
            .then((response) => {
                setItems(response.sort(itemsSortingFunction));
            })
            .catch(() => {
                //ignore
            });
    }, [managementApi]);

    const [menuState, setMenuState] = useState<{
        open: boolean;
        rowData: any;
        anchorEl?: any;
        isViewMode?: boolean;
    }>({
        open: false,
        rowData: {
            name: '',
        },
    });

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action: any) => {
        closeMenu();
        switch (action.id) {
            case editAction.id:
                if (menuState.rowData?.id) {
                    navigate.to(`management/integration/${menuState.rowData?.id}/edit`);
                }
                break;
            case viewAction.id:
                openDrawer(menuState.rowData);
                break;
            case syncAction.id:
                if (menuState.rowData.syncStatus !== AZURE.SYNC_STATUS.SYNC_IN_PROGRESS) {
                    managementApi
                        .performIntegrationServiceSync(menuState.rowData.id)
                        .then((response) => {
                            // TODO
                        })
                        .catch((e) => {
                            // TODO
                        });
                } else {
                    alert('Sync is already in progress');
                }
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });
            managementApi
                .deleteIntegrationService(menuState.rowData.id)
                .then(() => {
                    closeDeleteDialog();
                    setItems((currentItems) => {
                        return currentItems.filter((item) => item.id !== menuState.rowData.id);
                    });
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                        confirmText: menuState.rowData.id.substr(0, CONFIRM_TEXT_LENGTH).toUpperCase(),
                    });
                });
        } else {
            closeMenu();
            setDeleteState({
                open: true,
                disabled: true,
                confirmText: menuState.rowData.id.substr(0, CONFIRM_TEXT_LENGTH).toUpperCase(),
            });
        }
    };

    const syncStatusCellRenderer = ({ rowData }: { rowData: any }) => {
        if (rowData.syncStatus === AZURE.SYNC_STATUS.SYNC_IN_PROGRESS) {
            return (
                <LayoutRow
                    verticalAlign="center"
                    align="end"
                >
                    <div className="tw-w-24">
                        <CircularProgressWithLabel
                            size={45}
                            value={Math.trunc(rowData?.syncProgress) || 0}
                        />
                    </div>
                </LayoutRow>
            );
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.SUCCESS) {
            return (
                <>
                    <div
                        onClick={showLastSyncDialog}
                        className="tw-cursor-pointer"
                    >
                        <LayoutRow
                            verticalAlign="center"
                            align="end"
                            className="tw-text-emerald-500"
                        >
                            <Tooltip
                                content="Synchronization completed"
                                dark
                                bold
                                extraPadding
                            >
                                <Icon
                                    type="badgeCheckmark"
                                    className="tw-ml-1 tw-h-5 tw-w-5 tw-text-teal-400"
                                />
                            </Tooltip>
                        </LayoutRow>
                    </div>
                    <LastSyncDialog rowData={rowData} />

                    <Drawer
                        open={showDrawer}
                        anchor="right"
                        onClose={closeDrawer}
                        PaperProps={{
                            style: {
                                minWidth: '20vw',
                                maxWidth: '100vw',
                                width: 860,
                            },
                        }}
                    >
                        <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr">
                            <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4 tw-bg-white tw-px-4 tw-pb-2 tw-pt-4">
                                <div className="tw-flex tw-items-center">
                                    <Icon
                                        type="azure"
                                        className="tw-mr-2 tw-h-12 tw-w-12 tw-text-azure"
                                    />
                                    <PageTitle
                                        category="Integration"
                                        description={selectedIntegration?.description}
                                    >
                                        {selectedIntegration?.name}
                                    </PageTitle>
                                </div>
                                <div className="tw-flex tw-items-center">
                                    <Button
                                        noMargin
                                        href="https://capasystems.atlassian.net/wiki/spaces/CP/pages/19499810840/CapaOne+-+Azure+AD+intergration"
                                        target="_BLANK"
                                        rel="noreferrer"
                                        size="small"
                                        className="tw-mr-2 tw-text-gray-400"
                                    >
                                        Need help?
                                    </Button>
                                    <Button
                                        noMargin
                                        variant="text"
                                        onClick={() => navigate.to(`management/integration/${selectedIntegration?.id}/edit`)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        noMargin
                                        color="danger"
                                        variant="text"
                                        onClick={() => onActionClick(deleteAction)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>

                            <div className="tw-overflow-y-auto">
                                {selectedIntegration !== null && (
                                    <div className="tw-mb-4 tw-ml-6 tw-mr-5 tw-rounded tw-bg-slate-50 tw-px-6 tw-py-4 tw-pb-2 tw-pr-4 tw-text-slate-900 tw-ring-1 tw-ring-slate-100">
                                        <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-2">
                                            <div className="tw-mb-2 tw-text-xs tw-font-semibold">Application (client) ID</div>
                                            <code className="tw-mb-2 tw-mr-2 tw-text-right tw-text-xs tw-font-semibold">{selectedIntegration?.clientId}</code>
                                            <div className="tw-mb-2 tw-text-xs tw-font-semibold">Directory (tenant) ID</div>
                                            <code className="tw-mr-2 tw-text-right tw-text-xs tw-font-semibold">{selectedIntegration?.tenantId}</code>
                                            <div className="tw-text-xs tw-font-semibold">Client secret value</div>
                                            <div className="tw-mr-2 tw-text-right">
                                                <code className="tw-font-semibold">{selectedIntegration?.obfuscatedClientSecret}</code>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="tw-mb-4 tw-ml-2 tw-rounded tw-bg-white tw-px-4 tw-py-4 tw-pb-2 tw-pr-5 tw-text-slate-900 tw-ring-slate-100">
                                    <IntegrationManagementInputField
                                        label="Name"
                                        placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                        defaultValue={selectedIntegration?.name || ''}
                                        disabled={true}
                                        currentRef={formRef.current}
                                        name="clientId"
                                        // hasError={hasError}
                                    />

                                    <IntegrationManagementInputField
                                        label="Client Secret Expiration Date"
                                        placeholder="dd-mm-yyyy"
                                        type="date"
                                        defaultValue={dayjs(selectedIntegration?.clientSecretExpiration).format('YYYY-MM-DD') || ''}
                                        disabled={true}
                                        currentRef={formRef.current}
                                        name="tenantId"
                                        // hasError={hasError}
                                    />

                                    {selectedIntegration?.userProperties && selectedIntegration.userProperties.length > 0 ? (
                                        <div>
                                            <div className="tw-flex tw-items-center">
                                                <div className="tw-text-xs tw-font-semibold">User Properties</div>
                                                <Tooltip
                                                    content="We keep the right to store the user properties ID and Email"
                                                    fullWidth
                                                    position={TOOLTIP.POSITION.RIGHT}
                                                    dark
                                                    extraPadding
                                                    bold
                                                >
                                                    <Icon
                                                        type="infoOutlined"
                                                        size="small"
                                                        className="tw-ml-2"
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-2">
                                                {selectedIntegration.userProperties.map((userProperty: any, index: number) => (
                                                    <div
                                                        key={index}
                                                        className="tw-block tw-rounded tw-border tw-border-slate-200 tw-bg-slate-50 tw-p-2"
                                                    >
                                                        {userProperty.charAt(0).toUpperCase() + userProperty.slice(1)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <IntegrationManagementInputField
                                            label="User Properties"
                                            placeholder="No User Properties"
                                            defaultValue=""
                                            disabled={true}
                                            name="clientSecret"
                                            currentRef={formRef.current}
                                            // hasError={hasError}
                                            autoComplete="new-password"
                                        />
                                    )}

                                    <div className="tw-mt-6 ">
                                        <h2 className="tw-mb-2 tw-text-xs">Automatic synchronization</h2>
                                        <Tabs
                                            value={syncFrequency}
                                            onChange={onTabChange}
                                            pills
                                            variant="fullWidth"
                                            className="tw-mb-4"
                                            // disabled
                                        >
                                            <Tab
                                                key={SYNC_FREQUENCY.NEVER}
                                                disableRipple
                                                label="Never"
                                                value={SYNC_FREQUENCY.NEVER}
                                                className="tw-text-xs"
                                                disabled
                                            />
                                            <Tab
                                                key={SYNC_FREQUENCY.DAILY}
                                                disableRipple
                                                label="Daily"
                                                value={SYNC_FREQUENCY.DAILY}
                                                className="tw-text-xs"
                                                disabled
                                            />
                                            <Tab
                                                key={SYNC_FREQUENCY.WEEKLY}
                                                disableRipple
                                                label="Weekly"
                                                value={SYNC_FREQUENCY.WEEKLY}
                                                className="tw-text-xs"
                                                disabled
                                            />
                                            <Tab
                                                key={SYNC_FREQUENCY.MONTHLY}
                                                disableRipple
                                                label="Monthly"
                                                value={SYNC_FREQUENCY.MONTHLY}
                                                className="tw-text-xs"
                                                disabled
                                            />
                                        </Tabs>
                                        <Collapse in={syncFrequency !== SYNC_FREQUENCY.NEVER}>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                                                {syncFrequency === SYNC_FREQUENCY.MONTHLY && (
                                                    <div>
                                                        <h2 className="tw-mb-2 tw-text-xs">At day(s) of month</h2>
                                                        <Select
                                                            // @ts-ignore - this is not typed
                                                            selectedOptions={selectedDaysOfMonth}
                                                            options={CRON_DAY_OF_MONTH}
                                                            multiple
                                                            onChange={(v) => {
                                                                setSelectedDaysOfMonth(v.sort(monthSortingFunction));
                                                                validateForm();
                                                            }}
                                                            placeholder="Every day of the month"
                                                            callToAction
                                                            disabled
                                                        />
                                                    </div>
                                                )}
                                                {syncFrequency === SYNC_FREQUENCY.WEEKLY && (
                                                    <div>
                                                        <h2 className="tw-mb-2 tw-text-xs">At day(s) of the week</h2>
                                                        <Select
                                                            // @ts-ignore - this is not typed
                                                            selectedOptions={selectedDaysOfWeek}
                                                            options={CRON_WEEK_DAYS}
                                                            multiple
                                                            onChange={(v: any) => {
                                                                setSelectedDaysOfWeek(v.sort(weekDaysSortingFunction));
                                                                validateForm();
                                                            }}
                                                            placeholder="Every day of the week"
                                                            callToAction
                                                            disabled
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={classNames({
                                                        'tw-mb-4': true,
                                                        'tw-col-span-2': syncFrequency === SYNC_FREQUENCY.DAILY,
                                                    })}
                                                >
                                                    <h2 className="tw-mb-2 tw-text-xs">At hour</h2>
                                                    <Select
                                                        // @ts-ignore - this is not typed
                                                        selectedOptions={selectedHour}
                                                        options={CRON_HOURS_WITH_MINUTES}
                                                        onChange={(v: any) => {
                                                            setSelectedHour(v);
                                                            validateForm();
                                                        }}
                                                        placeholder="00:00"
                                                        callToAction
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </>
            );
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.FAILED) {
            return (
                <>
                    <div
                        onClick={showLastSyncDialog}
                        className="tw-cursor-pointer"
                    >
                        <LayoutRow
                            verticalAlign="center"
                            align="end"
                            className="tw-text-red-500"
                        >
                            <Tooltip
                                content="Synchronization failed"
                                dark
                                bold
                                extraPadding
                            >
                                <Icon
                                    className="tw-ml-1 tw-h-5 tw-w-5 tw-text-red-500"
                                    type="syncDisabled"
                                    color="error"
                                />
                            </Tooltip>
                        </LayoutRow>
                    </div>
                    <LastSyncDialog rowData={rowData} />
                </>
            );
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.NEVER_SYNCED) {
            return (
                <LayoutRow
                    verticalAlign="center"
                    align="end"
                    className="tw-text-tiny tw-text-neutral-300"
                >
                    Never synchronized
                </LayoutRow>
            );
        }

        return rowData.syncStatus;
    };
    const LastSyncDialog = ({ rowData }: { rowData: any }) => {
        return (
            <Dialog
                open={showLastSync}
                onClose={() => closeLastSyncDialog()}
                size={'md'}
            >
                <DialogTitle>Latest Synchronization Log</DialogTitle>
                <div className="tw-grid tw-max-h-full tw-overflow-auto">
                    <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white">
                        {rowData.lastSyncLog}
                    </div>
                </div>
                <DialogActions>
                    <Button onClick={() => closeLastSyncDialog()}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const lastSyncCellRenderer = ({ rowData }: { rowData: any }) => {
        if (rowData.lastSyncStop) {
            return <b className="tw-text-xs">{dayjs(rowData.lastSyncStop).fromNow()}</b>;
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.NEVER_SYNCED) {
            // return <b className="tw-text-xs tw-text-red-300">Awaiting initial sync.</b>;
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.SYNC_IN_PROGRESS) {
            // return "First sync. in progress";
        }
        return null;
    };

    const clientSecretExpirationCellRenderer = ({ rowData }: { rowData: any }) => {
        const expireDate = rowData.clientSecretExpiration ? dayjs(rowData.clientSecretExpiration) : null;
        const currentDate = dayjs();
        dayjs.extend(isBetween);
        const oneMonthFromNow = currentDate.add(1, 'month');
        let backgroundColor = 'tw-bg-white';
        let tooltipContent = 'No expiration date set';
        if (expireDate && expireDate.isValid()) {
            const hasExpired = currentDate.isAfter(expireDate, 'day');
            const expiresSoon = expireDate.isBetween(currentDate, oneMonthFromNow, 'day', '[]');
            if (hasExpired) {
                backgroundColor = 'tw-bg-red-100';
                tooltipContent = 'Expired';
            } else if (expiresSoon) {
                const daysLeft = expireDate.diff(currentDate, 'day');
                backgroundColor = 'tw-bg-amber-100';
                tooltipContent = `Expires in ${daysLeft} day${daysLeft > 1 ? 's' : ''}`;
            } else {
                backgroundColor = 'tw-bg-emerald-100';
                tooltipContent = 'Up-to-date';
            }
        }

        return {
            backgroundColor,
            content: (
                <div className={`flex items-center ${backgroundColor}`}>
                    <Tooltip
                        content={tooltipContent}
                        dark
                        bold
                        extraPadding
                    >
                        <b className="ml-2 tw-text-xs">{expireDate ? expireDate.format('MM-DD-YYYY') : 'No expiration date set'}</b>
                    </Tooltip>
                </div>
            ),
        };
    };

    const closeAzureDialog = () => {
        setShowIntegrationManagementAzureForm(false);
    };

    return (
        <>
            {/* @ts-ignore - this is not typed */}
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Azure AD integration"
                title={menuState.rowData.name}
                actions={actions}
                // @ts-ignore - this is not typed
                onActionClick={onActionClick}
            />
            <IntegrationManagementAzureForm
                open={showIntegrationManagementAzureForm}
                onCancel={closeAzureDialog}
                onSubmit={closeAzureDialog}
                data={menuState.rowData}
                isViewMode={menuState.isViewMode}
            />
            <ConfirmDialog
                onConfirm={onDelete}
                disabled={deleteState.disabled}
                onCancel={closeDeleteDialog}
                open={deleteState.open}
                isProcessing={deleteState.isProcessing}
                title={
                    <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-4">
                        <Icon
                            type="azure"
                            className="tw-h-12 tw-w-12 tw-text-azure"
                        />
                        <PageTitle category="Delete">Azure AD integration</PageTitle>
                    </div>
                }
            >
                <div className="tw-text-center">
                    <div className="tw-mt-12 tw-text-sm tw-font-medium tw-text-slate-700">Enter the following text to confirm</div>
                    <div className="tw-mb-6 tw-mt-3 tw-text-lg tw-font-semibold tw-leading-none tw-tracking-widest">{deleteState.confirmText}</div>
                    <LayoutCentered>
                        <div className="tw-mb-8 tw-w-96">
                            <Input
                                callToAction
                                naked
                                inputClassName="tw-text-center tw-text-lg tw-h-12 tw-tracking-widest"
                                autoFocus
                                maxLength={CONFIRM_TEXT_LENGTH}
                                disabled={deleteState.isProcessing}
                                onChange={(e) => {
                                    if (deleteState.disabled) {
                                        if (e.target.value === deleteState.confirmText) {
                                            setDeleteState((c) => ({
                                                ...c,
                                                disabled: false,
                                            }));
                                        }
                                    } else {
                                        setDeleteState((c) => ({
                                            ...c,
                                            disabled: true,
                                        }));
                                    }
                                }}
                            />
                        </div>
                    </LayoutCentered>
                </div>
                {deleteState.anErrorOccurred && (
                    <b className="tw-mb-4 tw-mt-8 tw-block tw-text-red-700">Could not delete "{menuState.rowData.name}". Please try again.</b>
                )}
            </ConfirmDialog>
            <Page title="Integrations">
                <div className="tw-overflow-auto">
                    <Grid
                        container
                        spacing={2}
                    >
                        {items.map((item) => (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={4}
                                key={item.id}
                            >
                                <WidgetPaper
                                    className="tw-h-full tw-overflow-auto"
                                    // @ts-ignore - this is not typed
                                    title={
                                        <div className="tw-flex tw-items-center">
                                            <Tooltip
                                                content="Azure"
                                                dark
                                                bold
                                                extraPadding
                                            >
                                                <Icon
                                                    type="azure"
                                                    className="tw-mr-2 tw-h-12 tw-w-12 tw-text-azure"
                                                />
                                            </Tooltip>

                                            <div className="tw-mr-4">{item.name}</div>

                                            <div className="tw-text-2xl tw-font-semibold">{syncStatusCellRenderer({ rowData: item })}</div>
                                        </div>
                                    }
                                    truncateHeaderElements
                                    // @ts-ignore - this is not typed
                                    actions={
                                        <IconButton
                                            onClick={(e) => {
                                                setMenuState({
                                                    open: true,
                                                    anchorEl: e.currentTarget,
                                                    rowData: item,
                                                });
                                            }}
                                            noMargin
                                        >
                                            <Icon type="moreVert" />
                                        </IconButton>
                                    }
                                >
                                    <div>
                                        <Divider light />
                                        <div
                                            className={classNames({
                                                'tw-grid tw-bg-slate-50/25 tw-text-slate-900': true,
                                                'tw-grid-cols-1fr-auto-1fr-auto-1fr': true,
                                            })}
                                        >
                                            <div className="tw-p-4">
                                                <LayoutCentered direction="column">
                                                    <div className="tw-text-2xl tw-font-semibold">
                                                        {lastSyncCellRenderer({ rowData: item }) || 'No sync information'}
                                                    </div>
                                                    <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">Last sync status</div>
                                                </LayoutCentered>
                                            </div>
                                            <Divider
                                                orientation="vertical"
                                                light
                                            />
                                            <div className="tw-p-4">
                                                <LayoutCentered direction="column">
                                                    <div className="tw-mt-1 tw-text-2xl tw-text-tiny tw-font-semibold tw-text-slate-500">
                                                        {item.syncConfiguration.cronExpression ? (
                                                            <Tooltip
                                                                content={cronExtractor(item.syncConfiguration.cronExpression).text}
                                                                dark
                                                                bold
                                                                extraPadding
                                                            >
                                                                <Icon
                                                                    type="sync"
                                                                    color="primary"
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                content="Disabled"
                                                                dark
                                                                bold
                                                                extraPadding
                                                            >
                                                                <Icon
                                                                    type="syncDisabled"
                                                                    className="tw-ml-2 tw-text-gray-400"
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                    <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">Automatic synchronization</div>
                                                </LayoutCentered>
                                            </div>

                                            <Divider
                                                orientation="vertical"
                                                light
                                            />
                                            <div className={`tw-p-4 ${clientSecretExpirationCellRenderer({ rowData: item }).backgroundColor}`}>
                                                <LayoutCentered direction="column">
                                                    <div className="tw-text-2xl tw-font-semibold">
                                                        {clientSecretExpirationCellRenderer({ rowData: item }).content || 'No expiration'}
                                                    </div>
                                                    <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">Secret expiration date</div>
                                                </LayoutCentered>
                                            </div>
                                        </div>
                                        <Divider light />
                                        <Button
                                            fullWidth
                                            noMargin
                                            size="large"
                                            color="primary"
                                            className="tw-rounded-none tw-py-4 tw-text-xs"
                                            onClick={() => openDrawer(item)}
                                        >
                                            View
                                            <Icon
                                                type="arrowRight"
                                                size="small"
                                                className="tw-ml-2"
                                            />
                                        </Button>
                                    </div>
                                </WidgetPaper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Page>
        </>
    );
};

export { IntegrationManagementLandingPage };

