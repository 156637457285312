import classNames from 'classnames';
import React from 'react';

export type DashboardGridItemProps = {
    className?: string;
    'data-grid': {
        x: number;
        y: number;
        w: number;
        h: number;
        isResizable?: boolean;
        isDraggable?: boolean;
        static?: boolean;
    };
};

export const DashboardGridItem = React.forwardRef<HTMLDivElement, DashboardGridItemProps>(({ className, ...otherProps }, ref) => (
    <div
        ref={ref}
        className={classNames(className, 'cs-dashboard-grid-item')}
        {...otherProps}
    />
));

export default DashboardGridItem;
