import { now } from '@capasystems/utils';
import { LEAF_ID } from '@thirdparty/constants';
import { highchartsTooltipHeadingAndText } from '@thirdparty/utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { default as TWC, default as colors } from 'tailwindcss/colors';
import { RELIABILITY, ReliabilityContext, SyncedHighcharts, WidgetPaper, useApi } from '../../index';

const ReliabilityEventBarChartWidget = ({ className, activeFilters, ...rest }) => {
    const api = useApi();
    const { deviceId, lockedPeriod, timezone, sampleInterval, inDetailedMode, severityId, setCustomPeriod } = useContext(ReliabilityContext);
    const chartRef = useRef(null);
    const chartData = useRef({
        series: [],
        categories: [],
    });
    const [widgetPaperProps, setWidgetPaperProps] = useState({ loading: true });
    const [, refreshChart] = useState(0);

    const columnClick = (periodObject) => {
        if (periodObject && periodObject.start && periodObject.end) {
            setCustomPeriod({
                start: periodObject.start,
                end: periodObject.end,
            });
        }
    };

    const updateChartOptions = () => {
        if (chartRef.current !== null) {
            modifyChartSeries();
            refreshChart(now());
        }
    };

    const onRequestError = (error) => {
        console.log(error);
        setWidgetPaperProps({
            errorMessage: 'Could not load Event timeline.',
        });
    };

    const responseHandler = (response) => {
        chartData.current.series = [];
        chartData.current.categories = response.categories;
        response.series
            .filter((s) => s.name !== 'Informational')
            .forEach((series) => {
                if (series.name === 'Affected devices') {
                    chartData.current.series.push({ name: series.name, unit: series.unit, data: series.data.Application, eventLog: 'Application' });
                    chartData.current.series.push({ name: series.name, unit: series.unit, data: series.data.System, eventLog: 'System' });
                    chartData.current.series.push({ name: series.name, unit: series.unit, data: series.data.Total, eventLog: 'Total' });
                } else if (series.data.Application.length > 0) {
                    const applicationSeries = { name: series.name, unit: series.unit, data: series.data.Application, eventLog: 'Application' };
                    const systemSeries =
                        series.data.System.length > 0
                            ? { name: series.name, unit: series.unit, data: series.data.System, eventLog: 'System' }
                            : { name: series.name, unit: series.unit, data: series.data.Application.map((point) => ({ ...point, y: 0 })), eventLog: 'System' };
                    const combinedSeriesData = [];
                    for (let i = 0; i < series.data.Application.length; i++) {
                        combinedSeriesData.push({
                            y: series.data.Application[i].y + (series.data.System.length > 0 ? series.data.System[i].y : 0),
                            period: series.data.Application[i].period,
                        });
                    }
                    const combinedSeries = { name: series.name, unit: series.unit, data: combinedSeriesData, eventLog: 'Total' };
                    chartData.current.series.push(combinedSeries);
                    chartData.current.series.push(applicationSeries);
                    if (Object.keys(systemSeries).length > 0) {
                        chartData.current.series.push(systemSeries);
                    }
                } else if (series.data.System.length > 0) {
                    const applicationSeries =
                        series.data.Application.length > 0
                            ? { name: series.name, unit: series.unit, data: series.data.Application, eventLog: 'Application' }
                            : { name: series.name, unit: series.unit, data: series.data.System.map((point) => ({ ...point, y: 0 })), eventLog: 'Application' };
                    const systemSeries = { name: series.name, unit: series.unit, data: series.data.System, eventLog: 'System' };
                    const combinedSeriesData = [];
                    for (let i = 0; i < series.data.System.length; i++) {
                        combinedSeriesData.push({
                            y: series.data.System[i].y + (series.data.Application.length > 0 ? series.data.Application[i].y : 0),
                            period: series.data.System[i].period,
                        });
                    }
                    const combinedSeries = { name: series.name, unit: series.unit, data: combinedSeriesData, eventLog: 'Total' };
                    chartData.current.series.push(combinedSeries);
                    if (Object.keys(applicationSeries).length > 0) {
                        chartData.current.series.push(applicationSeries);
                    }
                    chartData.current.series.push(systemSeries);
                }
            });
        modifyChartSeries();
        setWidgetPaperProps({});
    };

    const modifyChartSeries = useCallback(() => {
        if (!deviceId) {
            chartData.current.series.forEach((series) => {
                if (series.name === 'Affected devices' && series.unit === 'spline') {
                    series.visible = false;
                    series.showInLegend = false;
                }
            });
        }

        const setColorsAndName = (series, { colorRepresentation, name }) => {
            if (series.eventLog === 'System') {
                series.color = colors[colorRepresentation][400];
            } else if (series.eventLog === 'Application') {
                series.data.forEach((point) => {
                    point.color = colors[colorRepresentation][300];
                });
                series.color = colors[colorRepresentation][400];
            } else {
                series.color = colors[colorRepresentation][400];
            }
            series.name = name;
        };

        chartData.current.series.forEach((series) => {
            switch (series.name) {
                case 'Critical':
                    setColorsAndName(series, RELIABILITY.SEVERITY.CRITICAL);
                    break;
                case 'Error':
                    setColorsAndName(series, RELIABILITY.SEVERITY.ERROR);
                    break;
                case 'Warning':
                    setColorsAndName(series, RELIABILITY.SEVERITY.WARNING);
                    break;
                default:
                    break;
            }
            if (inDetailedMode) {
                series.stack = series.eventLog;
                const visible = series.eventLog !== 'Total' && series.unit !== 'devices' && series.unit !== 'spline';
                series.visible = visible;
                series.showInLegend = visible;
                if (series.eventLog === 'System') {
                    series.linkedTo = ':previous';
                    series.showInLegend = false;
                }
            } else {
                const visible = series.eventLog === 'Total' && series.unit !== 'devices' && series.unit !== 'spline';
                series.visible = visible;
                series.showInLegend = visible;
            }
        });
    }, [deviceId, inDetailedMode]);

    useEffect(updateChartOptions, [inDetailedMode, deviceId, modifyChartSeries]);

    useEffect(() => {
        api.cancel();
        if (activeFilters === null) {
            return;
        }
        chartRef.current = null;
        setWidgetPaperProps({
            loading: true,
        });
        const logTypeFilter = activeFilters.find((filter) => filter.id === LEAF_ID.LOG_TYPE);
        const sourceFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SOURCE);
        const evendIdFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.EVENT_ID);
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);
        if (severityId) {
            if (deviceId) {
                api.getEventChartSeverityDevice(
                    deviceId,
                    lockedPeriod,
                    timezone,
                    sampleInterval,
                    severityId,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value
                )
                    .then(responseHandler)
                    .catch(onRequestError);
            } else {
                api.getEventChartSeverity(
                    lockedPeriod,
                    timezone,
                    sampleInterval,
                    severityId,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value
                )
                    .then(responseHandler)
                    .catch(onRequestError);
            }
        } else {
            if (deviceId) {
                api.getEventChartDevice(
                    deviceId,
                    lockedPeriod,
                    timezone,
                    sampleInterval,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value
                )
                    .then(responseHandler)
                    .catch(onRequestError);
            } else {
                api.getEventChartOverview(
                    lockedPeriod,
                    timezone,
                    sampleInterval,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value
                )
                    .then(responseHandler)
                    .catch(onRequestError);
            }
        }
    }, [lockedPeriod, sampleInterval, activeFilters, severityId]);

    const onInit = (chart) => {
        chartRef.current = chart;
    };

    return (
        <WidgetPaper
            title="Event timeline"
            description="Shows events on a timeline for the selected time period. Events are grouped in smaller periods that you can drill into."
            className={className}
            truncateHeaderElements
            {...rest}
            {...widgetPaperProps}
        >
            <SyncedHighcharts
                onInit={onInit}
                options={{
                    colors: [TWC.red[800], TWC.red[500], TWC.orange[500], TWC.blue[500]],
                    chart: {
                        type: 'column',
                        spacingTop: 8,
                        spacingRight: 16,
                        animation: false,
                        events: {
                            click: function (event) {
                                if (chartData.current.series.length > 0) {
                                    chartData.current.series[0].data.forEach((point, index) => {
                                        if (index === Math.round(event.xAxis[0]?.value)) {
                                            columnClick(point.period);
                                        }
                                    });
                                }
                            },
                        },
                    },
                    title: {
                        text: null,
                    },
                    subtitle: {
                        text: null,
                    },
                    xAxis: {
                        categories: chartData.current.categories,
                        crosshair: true,
                        labels: {
                            style: {
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textOverflow: 'none',
                            },
                            align: 'center',
                            rotation: 0,
                            formatter: function () {
                                if (chartData.current.categories.length > 15 && this.pos % 3 !== 0) {
                                    return '';
                                }
                                return this.axis.userOptions.categories[this.pos];
                            },
                        },
                    },
                    yAxis: {
                        gridLineWidth: 1,
                        min: 0,
                        labels: {
                            overflow: 'justify',
                            style: {
                                color: colors.slate[900],
                                fontWeight: 600,
                            },
                        },
                        lineWidth: 0,
                        minTickInterval: 1,
                        showLastLabel: true,
                        endOnTick: true,
                        showFirstLabel: false,
                        minorGridLineWidth: 1,
                        tickWidth: 1,
                        tickLength: 0,
                        title: {
                            text: 'Number of events',
                            style: {
                                color: colors.slate[900],
                                fontWeight: 600,
                            },
                        },
                    },
                    tooltip: {
                        outside: true,
                        padding: 16,
                        borderRadius: 8,
                        formatter: function () {
                            const logTypeFilter = activeFilters?.find((filter) => filter.id === 'logType');
                            let tooltip = '';
                            try {
                                if (inDetailedMode) {
                                    let showApplicationInfo = true;
                                    let showSystemInfo = true;
                                    if (logTypeFilter) {
                                        showApplicationInfo = logTypeFilter.value.join() === 'Application';
                                        showSystemInfo = logTypeFilter.value.join() === 'System';
                                    }
                                    tooltip += highchartsTooltipHeadingAndText('Time period', this.x, false);
                                    tooltip += '<table class="tw-mt-2"><tr>';
                                    const categoryIndex = chartData.current.categories.indexOf(this.x);
                                    if (showApplicationInfo) {
                                        tooltip += '<td class="tw-text-left">';
                                        tooltip += '<b class="tw-text-xs">Application</b>';
                                        tooltip += highchartsTooltipHeadingAndText(
                                            RELIABILITY.SEVERITY.CRITICAL.name,
                                            chartData.current.series[1].data[categoryIndex].y,
                                            true
                                        );
                                        tooltip += highchartsTooltipHeadingAndText(
                                            RELIABILITY.SEVERITY.ERROR.name,
                                            chartData.current.series[4].data[categoryIndex].y,
                                            true
                                        );
                                        tooltip += highchartsTooltipHeadingAndText(
                                            RELIABILITY.SEVERITY.WARNING.name,
                                            chartData.current.series[7].data[categoryIndex]?.y || 0,
                                            true
                                        );
                                        tooltip += highchartsTooltipHeadingAndText(
                                            'Total',
                                            chartData.current.series[1].data[categoryIndex].y +
                                                chartData.current.series[4].data[categoryIndex].y +
                                                chartData.current.series[7].data[categoryIndex]?.y,
                                            true
                                        );
                                        tooltip += '</td>';
                                        if (showSystemInfo) {
                                            tooltip += '<td class="tw-px-6"></td>';
                                        }
                                    }
                                    if (showSystemInfo) {
                                        if (showApplicationInfo) {
                                            tooltip += '<td class="tw-text-right">';
                                        } else {
                                            tooltip += '<td class="tw-text-left">';
                                        }
                                        tooltip += '<b class="tw-text-xs">System</b>';
                                        tooltip += highchartsTooltipHeadingAndText(
                                            RELIABILITY.SEVERITY.CRITICAL.name,
                                            chartData.current.series[2].data[categoryIndex].y,
                                            true
                                        );
                                        tooltip += highchartsTooltipHeadingAndText(
                                            RELIABILITY.SEVERITY.ERROR.name,
                                            chartData.current.series[5].data[categoryIndex].y,
                                            true
                                        );
                                        tooltip += highchartsTooltipHeadingAndText(
                                            RELIABILITY.SEVERITY.WARNING.name,
                                            chartData.current.series[8].data[categoryIndex]?.y || 0,
                                            true
                                        );
                                        tooltip += highchartsTooltipHeadingAndText(
                                            'Total',
                                            chartData.current.series[2].data[categoryIndex].y +
                                                chartData.current.series[5].data[categoryIndex].y +
                                                chartData.current.series[8].data[categoryIndex]?.y,
                                            true
                                        );
                                        tooltip += '</td>';
                                    }
                                    tooltip += '</tr></table>';
                                } else {
                                    tooltip += highchartsTooltipHeadingAndText('Time period', this.points[0].key, false);
                                    this.points.forEach((point) => {
                                        tooltip += highchartsTooltipHeadingAndText(point.series.name, point.point.y?.toLocaleString(), true);
                                    });
                                    if (!deviceId) {
                                        const affectedDevicesList = [];
                                        chartData.current.series.forEach((serie) => {
                                            if (serie.name === 'Affected devices') {
                                                affectedDevicesList.push(serie.data);
                                            }
                                        });
                                        let affectedDevicesListIndex = 2;
                                        if (logTypeFilter) {
                                            affectedDevicesListIndex = logTypeFilter.value.join() === 'Application' ? 0 : 1;
                                        }
                                        tooltip += highchartsTooltipHeadingAndText(
                                            'Affected endpoints',
                                            affectedDevicesList[affectedDevicesListIndex][this.points[0].point.index].y?.toLocaleString(),
                                            true
                                        );
                                    }
                                }
                                return tooltip;
                            } catch {
                                return false;
                            }
                        },
                        shared: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.1,
                            borderWidth: 0,
                            stacking: 'normal',
                            events: {
                                legendItemClick: (e) => {
                                    // e.preventDefault();
                                },
                            },
                        },
                        series: {
                            cursor: 'pointer',
                            events: {
                                click: function (event) {
                                    columnClick(event.point.period);
                                },
                            },
                        },
                    },
                    legend: {
                        zIndex: 1,
                    },
                    series: chartData.current.series,
                }}
            />
        </WidgetPaper>
    );
};

ReliabilityEventBarChartWidget.propTypes = {};

export { ReliabilityEventBarChartWidget };
export default ReliabilityEventBarChartWidget;
