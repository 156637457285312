import { Button, Dialog, DialogActions, DialogContent } from '@capasystems/ui';
import React from 'react';
import { Heading } from '../heading/heading';

export type ComingSoonDialogProps = {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

export const ComingSoonDialog: React.FC<ComingSoonDialogProps> = ({ children, ...props }) => {
    return (
        <Dialog {...props}>
            <DialogContent>
                <Heading>Coming Soon...</Heading>
            </DialogContent>
            <DialogContent>
                <Heading subheading>{children}</Heading>
            </DialogContent>
            <DialogActions>
                <Button
                    noMargin
                    color="primary"
                    onClick={props.onClose}
                >
                    Gotcha
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ComingSoonDialog;
