import { getDefaultGaugeConfiguration, getUniqueId, isFunction, isUndefined } from '@capasystems/utils';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React from 'react';

export class Gauge extends React.Component {
    static defaultProps = { min: 0, style: { width: '100%', height: '100%' }, title: '', unit: 'Index' };

    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.chartId = props.htmlId ? props.htmlId : getUniqueId('gauge-');
        this.chartConfiguration = getDefaultGaugeConfiguration();
    }

    /* istanbul ignore next */
    componentDidMount() {
        const { plotBands, max, min, title, unit, tooltipFormatter, configure, series } = this.props;
        plotBands.map((plotBand) => (plotBand.thickness = this.chartConfiguration.yAxis.plotBands[0].thickness));
        this.chartConfiguration.yAxis.plotBands = plotBands;
        this.chartConfiguration.yAxis.max = max;
        if (min) this.chartConfiguration.yAxis.min = min;
        if (title) this.chartConfiguration.title.text = title;
        if (unit) this.chartConfiguration.yAxis.title.text = unit;
        if (unit) this.chartConfiguration.yAxis.unit = unit;
        if (tooltipFormatter) this.chartConfiguration.tooltip.formatter = tooltipFormatter;
        if (isFunction(configure)) {
            configure(this.chartConfiguration, series);
        }
        this.chart = Highcharts.chart(this.chartId, this.chartConfiguration);
        this.addSeries(series);
    }

    /* istanbul ignore next */
    componentDidUpdate(prevProps) {
        const { series, plotBands, max, title, unit } = this.props;
        for (let i = 0; i < series.length; i += 1) {
            const hcs = this.chart.get(series[i].id);
            //  Add new series or update series with new data.
            if (isUndefined(hcs)) {
                this.addSeries(series[i]);
            } else if (prevProps.series.some((e) => e.id === series[i].id && e.data !== series[i].data)) {
                const serie = this.chart.get(series[i].id);
                serie.points[0].update(series[i].data);
            }
        }

        //  Remove series.
        for (let i = 0; i < prevProps.series.length; i += 1) {
            if (!series.some((e) => e.id === prevProps.series[i].id)) {
                const hcs = this.chart.get(prevProps.series[i].id);
                hcs.remove();
            }
        }

        //  Check for changes to plotBands/colorRanges
        if (plotBands.some((e, i) => JSON.stringify(e) !== JSON.stringify(prevProps.plotBands[i]))) {
            this.chart.yAxis[0].update(
                {
                    plotBands,
                },
                true
            );
        }

        //  Check for change in max value
        if (prevProps.max !== max) {
            this.chart.yAxis[0].update({
                max,
            });
        }

        //  Check for changes to title
        if (prevProps.title !== title) {
            this.chart.title.update({
                text: title,
            });
        }

        //  Update unit
        if (prevProps.unit !== unit) {
            this.chart.yAxis[0].update({
                unit,
                title: {
                    text: unit,
                },
            });
        }
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        this.chart.destroy();
    }

    /* istanbul ignore next */
    addSeries = (seriesFunction) => {
        const { series } = this.props;
        const hcs = this.chart.get(series.id);
        hcs.chart.addSeries({
            dial: {
                radius: seriesFunction.small ? '50%' : '80%',
            },
            ...seriesFunction,
        });
    };

    /* istanbul ignore next */
    render() {
        const { style } = this.props;
        return (
            <div
                id={this.chartId}
                style={style}
            />
        );
    }
}

Gauge.propTypes = {
    series: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.number).isRequired,
            small: PropTypes.bool,
            dial: PropTypes.object,
            overshoot: PropTypes.number,
        })
    ).isRequired,
    max: PropTypes.number.isRequired,
    plotBands: PropTypes.arrayOf(
        PropTypes.shape({
            from: PropTypes.number,
            to: PropTypes.number,
            color: PropTypes.string,
        })
    ).isRequired,
    min: PropTypes.number,
    // eslint-disable-next-line react/require-default-props
    tooltipFormatter: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    configure: PropTypes.func,
    style: PropTypes.shape(),
    title: PropTypes.string,
    unit: PropTypes.string,
};

export default Gauge;
