import { Page, Snackbar, SnackbarContent } from '@capasystems/ui';
import { useState } from 'react';
import { useCoreContext } from '../../hooks/useCoreContext/useCoreContext';
import { useAppleClusterEvent } from '../../hooks/useSocket/useSocket';
import { AppleSettingsDEP } from '../components/setting/AppleSettingsDEP';
import { AppleSettingsPushCertificate } from '../components/setting/AppleSettingsPushCertificate';
import { AppleSettingsVPP } from '../components/setting/AppleSettingsVPPToken';

const AppleCertificates = () => {
    const [errorMessage, setErrorMessage] = useState(null);

    const { setAppleEnterpriseAccount } = useCoreContext();
    useAppleClusterEvent((event) => {
        if (event.fullDocument) {
            setAppleEnterpriseAccount(event.fullDocument);
        }
    });

    return (
        <Page title="Certificates">
            <div className="tw-mx-auto tw-grid tw-max-w-screen-2xl tw-grid-cols-1 tw-gap-6 tw-p-4 tw-pt-8 lg:tw-grid-cols-2 2xl:tw-grid-cols-3">
                <AppleSettingsPushCertificate />
                <AppleSettingsDEP />
                <AppleSettingsVPP />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={errorMessage !== null}
                    onClose={() => setErrorMessage(null)}
                    autoHideDuration={3000}
                >
                    <SnackbarContent
                        severity="error"
                        message={errorMessage}
                    />
                </Snackbar>
            </div>
        </Page>
    );
};

export { AppleCertificates };
