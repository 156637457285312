export const AppleWallpaperConfiguration = {
    schema: {
        schemaId: 'appleWallpaperConfiguration',
        category: 'User Experience',
        title: 'Wallpaper',
        type: 'object',
        properties: {
            Settings: {
                skipIndent: true,
                title: '',
                description: '',
                type: 'object',
                properties: {
                    Wallpaper: {
                        type: 'object',
                        title: '',
                        description: 'The wallpaper to use for the device.',
                        properties: {
                            Image: {
                                title: 'Image',
                                description: 'The image to use for the wallpaper.',
                                tooltipContent: ``,
                                type: 'uploadbutton',
                                accept: '.jpeg,.png',
                                required: true,
                            },
                        },
                    },
                },
            },
        },
    },
};
