import { SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import {
    Button,
    Column,
    Ellipsis,
    EmptyState,
    Icon,
    IconButton,
    LayoutCentered,
    LayoutRow,
    Page,
    Tooltip,
    VirtualizedTable
} from '@capasystems/ui';
import { getSortingFunction, noop } from '@capasystems/utils';
import { TAndroidConfigurationWithId } from '@db/party';
import { NONE } from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionsDialog,
    CapaOneLink,
    ConfirmDialogWarning,
    Heading,
    PriorityController,
    PriorityControllerButton,
    PriorityVisualization,
    QueryBuilder,
    RenamingDialog,
    TwoLineCellRenderer,
    WidgetPaper,
    useAndroidApi,
    useAndroidConfigurationsSocket,
    useColumnPicker,
    useManagementApi,
    useNavigate,
} from '../../../index';

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Configuration name' });

const renameAction = {
    id: 'rename',
    name: 'Rename',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};
const configurationActions = [renameAction, deleteAction];

const AndroidConfigurationList = () => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const columnPicker = useColumnPicker({
        id: 'android-configuration-list',
        lockedColumns: ['Name'],
    });
    const [configurationList, setConfigurationList] = useState<TAndroidConfigurationWithId[]>([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [menuState, setMenuState] = useState<{ open: boolean; anchorEl: any; rowData?: Partial<TAndroidConfigurationWithId>; pages: any[] }>({
        open: false,
        anchorEl: null,
        rowData: {
            name: '',
        },
        pages: [],
    });
    const [deleteState, setDeleteState] = useState<{ open?: boolean; isProcessing?: boolean; anErrorOccurred?: boolean }>({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });
    const [isRenaming, setIsRenaming] = useState(false);
    const [sortingState, setSortingState] = useState({
        sortBy: 'name',
        sortDirection: SORT_DIRECTION.ASC,
    });
    const [priorityControllerIsOpen, setPriorityControllerIsOpen] = useState(false);
    const queryBuilderRef = useRef<any>({});

    const refreshConfigurationsList = useCallback(() => {
        setLoading(true);
        Promise.all([androidApi.getAndroidConfigurations(), managementApi.getGroups()])
            .then(([configurationsResponse, groupsResponse]) => {
                setConfigurationList(
                    configurationsResponse
                        .sort((a: any, b: any) => (a.createdAt || a.createdDate).localeCompare(b.createdAt || b.createdDate))
                        .map((configuration: any, index: number) => ({
                            priority: index + 1, // Only if priority is not set.
                            ...configuration,
                            groupMemberCount: groupsResponse.filter((group: any) =>
                                group.configurationRefs.some(({ refId }: { refId: any }) => refId === configuration.id)
                            ).length,
                            endpointMemberCount: configuration.endpointRefIds.length,
                        }))
                );
                setErrorMessage(null);
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Could not get Android configurations');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [androidApi]);

    useAndroidConfigurationsSocket(
        useCallback<TSocketMethod<TAndroidConfigurationWithId>>(
            ({ updateDescription, documentId }, { insertOperation, updateOperation, deleteOperation }) => {
                if (updateOperation) {
                    setConfigurationList((currentConfigurations) => {
                        return currentConfigurations.map((configuration) => {
                            if (configuration.id === documentId) {
                                return {
                                    ...configuration,
                                    ...updateDescription.updatedFields,
                                };
                            }
                            return configuration;
                        });
                    });
                } else if (insertOperation) {
                    refreshConfigurationsList();
                } else if (deleteOperation) {
                    setConfigurationList((currentList) => {
                        return currentList.filter((configuration) => configuration.id !== documentId);
                    });
                }
            },
            [refreshConfigurationsList]
        )
    );

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });
            androidApi
                .deleteAndroidConfiguration(menuState.rowData?.id as string, true)
                .then(() => {
                    closeDeleteDialog();
                    setConfigurationList((currentList) => {
                        return currentList.filter((configuration) => configuration.id !== menuState.rowData?.id);
                    });
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                    });
                });
        } else {
            setDeleteState({
                open: true,
            });
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        androidApi
            .updateAndroidConfiguration(menuState.rowData?.id as string, { name: newName })
            .then(noop)
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const onSubmit = ([activeLeaf]: [any]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const onActionClick = (action: any) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                onRename();
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    useEffect(refreshConfigurationsList, [refreshConfigurationsList]);

    const memoizedConfigurationList = useMemo(() => {
        const isNumeric = ['priority', 'endpointMemberCount', 'groupMemberCount'].includes(sortingState.sortBy);
        const sortingFunction = getSortingFunction(sortingState, isNumeric);
        if (searchTerm) {
            return configurationList.filter((c) => c.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())).sort(sortingFunction);
        }
        return configurationList.sort(sortingFunction);
    }, [searchTerm, configurationList, sortingState]);

    const onPriorityControllerSubmit = (updatedItems: any) => {
        closePriorityController();
        androidApi
            .updateAndroidConfigurations(updatedItems.map(({ id, priority }: any) => ({ id, priority })))
            .then(noop)
            .catch(() => {
                openPriorityController();
            });
    };

    const openPriorityController = () => {
        setPriorityControllerIsOpen(true);
    };

    const closePriorityController = () => {
        setPriorityControllerIsOpen(false);
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <div>
                    <Heading>{errorMessage}</Heading>
                    <Button
                        onClick={refreshConfigurationsList}
                        className="tw-mt-4"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                    >
                        Retry
                    </Button>
                </div>
            </LayoutCentered>
        );
    }

    return (
        <Page title="Configurations">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div>
                    <LayoutRow align="space-between">
                        <div className="tw-ml-auto tw-flex tw-items-center tw-gap-4">
                            {configurationList.length > 0 && (
                                // @ts-ignore - QueryBuilder is not typed
                                <QueryBuilder
                                    // @ts-ignore - QueryBuilder is not typed
                                    defaultConfiguration={queryBuilderConfiguration}
                                    onInit={onSubmit}
                                    onSubmit={onSubmit}
                                    className="tw-w-64"
                                    ref={queryBuilderRef}
                                />
                            )}
                            <div>
                                <Button
                                    noMargin
                                    color="primary"
                                    variant="contained"
                                    onClick={() => navigate.to('android/configuration/create')}
                                >
                                    New
                                </Button>
                            </div>
                        </div>
                    </LayoutRow>
                    <ActionsDialog
                        open={menuState.open}
                        anchorEl={menuState.anchorEl}
                        onClose={closeMenu}
                        category="Configuration"
                        title={menuState.rowData?.name as string}
                        pages={menuState.pages}
                        actions={configurationActions}
                        onActionClick={onActionClick}
                    />
                    <RenamingDialog
                        onCancel={onRename}
                        onSubmit={onRenameSubmit}
                        currentName={menuState.rowData?.name as string}
                        open={isRenaming}
                    />
                    <ConfirmDialogWarning
                        onConfirm={onDelete}
                        onCancel={closeDeleteDialog}
                        open={deleteState.open}
                        name={menuState.rowData?.name}
                        isProcessing={deleteState.isProcessing}
                        error={deleteState.anErrorOccurred ? `Could not delete "${menuState.rowData?.name}". Please try again.` : undefined}
                    />
                </div>
                <WidgetPaper
                    className="tw-h-full"
                    headerless
                >
                    {/* @ts-ignore - VirtualizedTable is not typed */}
                    <VirtualizedTable
                        items={memoizedConfigurationList}
                        totalRowCount={memoizedConfigurationList.length}
                        isLoading={loading}
                        showRowCount
                        entity="configuration"
                        disableHeader={configurationList.length === 0}
                        noRowsRenderer={() => (
                            <EmptyState
                                searchTerm={searchTerm}
                                entity="configuration"
                                isSearching={searchTerm !== ''}
                                onClearSearch={queryBuilderRef.current?.clearFiltersAndApplyChanges}
                                onClick={() => navigate.to('android/configuration/create')}
                            />
                        )}
                        actions={
                            <>
                                <PriorityControllerButton onClick={openPriorityController} />
                                <PriorityController
                                    open={priorityControllerIsOpen}
                                    onCancel={closePriorityController}
                                    onSubmit={onPriorityControllerSubmit}
                                    items={configurationList}
                                    rowRenderer={(item) => (
                                        <div>
                                            <div className="tw-font-bold">{item.name}</div>
                                            <div className="tw-text-xs tw-text-neutral-700">{item.updatedBy}</div>
                                        </div>
                                    )}
                                />
                            </>
                        }
                        sort={setSortingState}
                        // @ts-ignore - VirtualizedTable is not typed
                        sortBy={sortingState.sortBy}
                        sortDirection={sortingState.sortDirection}
                        columnPicker={columnPicker}
                    >
                        <Column
                            minWidth={32}
                            maxWidth={32}
                            dataKey="id"
                            disableSort
                            label=""
                            type="icon"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <IconButton
                                        onClick={(e) => {
                                            setMenuState({
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                rowData,
                                                pages: [
                                                    {
                                                        name: 'Endpoints',
                                                        icon: 'androidEndpoint',
                                                        url: `android/configuration/${rowData.id}/membership?tab=endpoints`,
                                                    },
                                                    {
                                                        name: 'Groups',
                                                        icon: 'androidGroup',
                                                        url: `android/configuration/${rowData.id}/membership?tab=groups`,
                                                    },
                                                    {
                                                        name: 'Edit',
                                                        icon: 'editOutlined',
                                                        url: `android/configuration/${rowData.id}/edit`,
                                                    },
                                                ],
                                            });
                                        }}
                                        noMargin
                                    >
                                        <Icon type="moreVert" />
                                    </IconButton>
                                );
                            }}
                        />
                        <Column
                            minWidth={160}
                            dataKey="name"
                            label="Name"
                            type="string"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <Tooltip
                                        content="Configuration management"
                                        position={TOOLTIP.POSITION.TOP_START}
                                    >
                                        <CapaOneLink to={`android/configuration/${rowData.id}/membership`}>
                                            <Ellipsis tooltipProps={{ position: TOOLTIP.POSITION.RIGHT }}>{rowData.name}</Ellipsis>
                                        </CapaOneLink>
                                    </Tooltip>
                                );
                            }}
                        />
                        <Column
                            minWidth={120}
                            dataKey="updatedBy"
                            label="Updated by"
                            type="multiLine"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <TwoLineCellRenderer
                                        main={rowData.updatedBy}
                                        secondary={rowData.updatedAt ? dayjs(rowData.updatedAt).fromNow() : null}
                                    />
                                );
                            }}
                        />
                        <Column
                            minWidth={120}
                            maxWidth={120}
                            dataKey="priority"
                            label="Priority"
                            type="multiLine"
                            cellRenderer={({ rowData }) => {
                                const blockSize = 100 / Math.max(configurationList.length - 1, 1);
                                const percentage = rowData.priority === 1 ? 100 : blockSize * (configurationList.length - rowData.priority);
                                return <PriorityVisualization percentage={percentage} />;
                            }}
                        />
                        <Column
                            minWidth={96}
                            maxWidth={96}
                            dataKey="endpointMemberCount"
                            label="Endpoints"
                            defaultSortDirection={SORT_DIRECTION.DESC}
                            type="number"
                            columnData={{
                                tabName: 'endpoints',
                                appendBaseURL: navigate.appendBaseURL,
                            }}
                            cellRenderer={countRenderer}
                        />
                        <Column
                            minWidth={96}
                            maxWidth={96}
                            dataKey="groupMemberCount"
                            label="Groups"
                            defaultSortDirection={SORT_DIRECTION.DESC}
                            type="number"
                            columnData={{
                                tabName: 'groups',
                                appendBaseURL: navigate.appendBaseURL,
                            }}
                            cellRenderer={countRenderer}
                        />
                    </VirtualizedTable>
                </WidgetPaper>
            </div>
        </Page>
    );
};

type CountRendererProps = {
    cellData: number;
    rowData: TAndroidConfigurationWithId;
    columnData: { tabName: string };
};

const countRenderer = ({ cellData, rowData, columnData }: CountRendererProps) => {
    return (
        <CapaOneLink to={`android/configuration/${rowData.id}/membership?tab=${columnData.tabName}`}>
            {cellData > 0 ? cellData : <span className="tw-text-xs tw-italic tw-text-gray-300">{NONE}</span>}
        </CapaOneLink>
    );
};

export { AndroidConfigurationList };

