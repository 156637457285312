/* eslint-disable react/jsx-one-expression-per-line,
    linebreak-style,
    jsx-a11y/click-events-have-key-events */
import { node, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { DragDropContext } from './drag-and-drop-provider';
import './drag-and-drop-provider.scss';

import { getTheme } from '@capasystems/utils';

const { capasystems } = getTheme();

export const Draggable = React.memo(
    ({ group, itemType, data, selectedColor = capasystems.palette.success.light, children = null, style, id }) => {
        /* istanbul ignore next */
        const dragAndDropContext = useContext(DragDropContext);

        /* istanbul ignore next */
        data.itemType = itemType;
        /* istanbul ignore next */
        data.group = group;
        /* istanbul ignore next */
        data.id = id;

        /* istanbul ignore next */
        dragAndDropContext.setAsSelectedable(data);

        /* istanbul ignore next */
        const [isTheDragger, setIsTheDragger] = useState(false);

        /* istanbul ignore next */
        const onDragStart = (dragId) => () => {
            if (!dragAndDropContext.isSelected(data)) {
                dragAndDropContext.updateDragItems(data);
            }
            if (dragId === id) {
                setIsTheDragger(true);
            }
            dragAndDropContext.setIsDragging(true);
        };

        /* istanbul ignore next */
        return (
            <span
                role="presentation"
                draggable
                onClick={() => {
                    dragAndDropContext.updateDragItems(data, dragAndDropContext.isSelected(data));
                }}
                onDragStart={onDragStart(id)}
                onDragEnd={() => {
                    dragAndDropContext.setIsDragging(false);
                    if (isTheDragger) {
                        setIsTheDragger(false);
                    }
                }}
                style={{
                    display: 'block',
                    padding: 0,
                    margin: 0,
                    background: dragAndDropContext.isSelected(data) ? selectedColor : 'transparent',
                    opacity: dragAndDropContext.isDragging && dragAndDropContext.isSelected(data) ? '0.5' : '1',
                    ...style,
                }}
            >
                {children}{' '}
                {dragAndDropContext.showSelectedCount && dragAndDropContext.isDragging && isTheDragger && (
                    <span className="cs-draggable-selected-count">{dragAndDropContext.selected.length}</span>
                )}
            </span>
        );
    },
    /* istanbul ignore next */ (prevProps, nextProps) =>
        prevProps.id === nextProps.id || prevProps.group === nextProps.group || prevProps.itemType === nextProps.itemType
);

Draggable.propTypes = {
    group: string.isRequired,
    itemType: string.isRequired,
    data: shape().isRequired,
    selectedColor: string,
    children: node,
    id: string.isRequired,
};

export default Draggable;
