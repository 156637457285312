/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

export const useDialog = () => {
    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    return {
        open,
        setOpen,
        onClose,
    };
};

export default useDialog;
