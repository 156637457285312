import React, { useState } from 'react';
import Button, { ButtonProps } from '../button/button';
import './upload-button.scss';

export type TFilesWithKey = (File & { key: number })[];

export type UploadButtonProps = ButtonProps & {
    htmlId: string;
    accept?: string;
    onChange: (fileObject: { files: TFilesWithKey; filename: string }) => void;
    multiple?: boolean;
    hideSelectedFiles?: boolean;
};

type onChangeFunctionType = (event: React.ChangeEvent<HTMLInputElement>) => void;

/** CapaSystems upload button. */
export const UploadButton: React.FC<UploadButtonProps> = ({
    disabled,
    size,
    color,
    variant,
    accept = '*',
    htmlId,
    multiple = false,
    hideSelectedFiles = false,
    className,
    children,
    onChange,
    fullWidth = false,
}) => {
    const [files, setFiles] = useState<TFilesWithKey>([]);
    const [filename, setFilename] = useState<string | null>(null);

    /* istanbul ignore next */
    const onChangeFunction: onChangeFunctionType = (event) => {
        const target = event.target;
        /*
		Note that target.files is a FileList, ie. not an Array, but it does conform to its contract (has length and numeric indices).
		We simply transform it to a proper Array.
		*/
        const filesArray = Array.from(target.files || []) as TFilesWithKey;
        const filenameTarget = target.value;
        filesArray.map((file, index) => {
            file.key = index;
            return file;
        });
        if (!hideSelectedFiles) {
            setFiles(filesArray);
            setFilename(filenameTarget);
        }
        const fileObject = {
            files: filesArray,
            filename: filenameTarget,
        };
        onChange(fileObject);
    };

    /* istanbul ignore next */
    const onClick = () => {
        /** Ignore */
    };

    return (
        <React.Fragment>
            <input
                className="tw-hidden"
                accept={accept}
                onChange={onChangeFunction}
                id={htmlId}
                multiple={multiple}
                type="file"
                disabled={disabled}
            />
            <label htmlFor={htmlId}>
                <Button
                    component="span"
                    disabled={disabled}
                    size={size}
                    color={color}
                    variant={variant}
                    onClick={onClick}
                    className={className}
                    fullWidth={fullWidth}
                >
                    {children}
                </Button>
            </label>
            {filename}
            {files.length > 1 && (
                <span>
                    <b> (+ {files.length - 1} more) </b>selected{' '}
                </span>
            )}
        </React.Fragment>
    );
};

export default UploadButton;
