import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Paper } from '../paper/paper';

const StyledBackdrop = styled(Backdrop)(({ theme }) => {
    return {
        zIndex: theme.zIndex.modal,
    };
});
const StyledPopper = styled(Popper)(({ theme }) => {
    return {
        zIndex: theme.zIndex.modal,
    };
});
const StyledPaper = styled(Paper)({
    maxWidth: 1000,
    maxHeight: '90vh',
    overflow: 'auto',
});

const styledPopperModifiers = [
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            rootBoundary: 'window',
            padding: 0,
        },
    },
];

export type RichTooltipProps = {
    position?:
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | 'right'
        | 'right-start'
        | 'right-end';
    open: boolean;
    onClose: () => void;
    onEntering?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
    visibleBackdrop?: boolean;
    anchorEl: null | HTMLElement;
    paperProps?: Record<string, unknown>;
    transitionDuration?: number;
    children: React.ReactNode;
};

export const RichTooltip: React.FC<RichTooltipProps> = ({
    position = 'top',
    open,
    onClose,
    onEntering,
    onExiting,
    onExited,
    visibleBackdrop = false,
    anchorEl,
    paperProps = {},
    transitionDuration = 350,
    children,
}) => {
    return (
        <>
            <StyledBackdrop
                open={open}
                onExited={onExited}
                invisible={!visibleBackdrop}
                transitionDuration={transitionDuration}
                onClick={onClose}
                onEntering={onEntering}
                onExiting={onExiting}
            />
            <StyledPopper
                open={open}
                anchorEl={anchorEl}
                placement={position}
                transition
                modifiers={styledPopperModifiers}
            >
                {({ TransitionProps }) => (
                    <Fade
                        {...TransitionProps}
                        timeout={transitionDuration}
                    >
                        <StyledPaper {...paperProps}>{children}</StyledPaper>
                    </Fade>
                )}
            </StyledPopper>
        </>
    );
};

export default RichTooltip;
