export const AndroidApplicationRulesConfiguration = {
    schema: {
        title: 'Application Rules',
        type: 'object',
        category: 'Application',
        schemaId: 'applicationRulesConfiguration',
        properties: {
            defaultPermissionPolicy: {
                title: 'Default Permission Policy',
                description: 'The default policy for all permissions requested by the app.',
                help: "Note: If specified, this overrides the policy-level 'Default Permission Policy' which applies to all apps. It does not override the 'Permission Grants' which applies to all apps.",
                type: 'string',
                options: [
                    {
                        id: 'PROMPT',
                        name: 'Prompt the user to grant a permission',
                    },
                    {
                        id: 'GRANT',
                        name: 'Automatically grant a permission',
                    },
                    {
                        id: 'DENY',
                        name: 'Automatically deny a permission',
                    },
                ],
            },
            permissionGrants: {
                title: 'Permission Grants',
                description: 'Explicit permission or group grants or denials for all apps.',
                help: 'Note: These values override the defaultPermissionPolicy.',
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        policy: {
                            title: 'Policy',
                            description: 'The policy for granting the permission.',
                            type: 'string',
                            options: [
                                {
                                    id: 'PROMPT',
                                    name: 'Prompt the user to grant a permission',
                                },
                                {
                                    id: 'GRANT',
                                    name: 'Automatically grant a permission',
                                },
                                {
                                    id: 'DENY',
                                    name: 'Automatically deny a permission',
                                },
                            ],
                        },
                        permission: {
                            title: 'Permission',
                            description: 'The Android permission or group, e.g. android.permission.READ_CALENDAR or android.permission_group.CALENDAR.',
                            type: 'string',
                            required: (siblings: any) => {
                                if (siblings.policy) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                            shouldRender: (siblings: any) => {
                                if (siblings.policy) {
                                    return true;
                                }
                                delete siblings.permission;
                                return false;
                            },
                        },
                    },
                },
            },
            choosePrivateKeyRules: {
                title: 'Choose Private Key Rules',
                description: "Rules for determining apps' access to private keys.",
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        urlPattern: {
                            title: 'Url Pattern',
                            description: 'The URL pattern to match against the URL of the request.',
                            help: 'Note: If not set or empty, it matches all URLs. This uses the regular expression syntax of java.util.regex.Pattern.',
                            type: 'string',
                        },
                        packageNames: {
                            title: 'Package Names',
                            description:
                                'The package names to which this rule applies. The hash of the signing certificate for each app is verified against the hash provided by Play.',
                            help: 'Note: If no package names are specified, then the alias is provided to all apps that call KeyChain.choosePrivateKeyAlias or any overloads (but not without calling KeyChain.choosePrivateKeyAlias, even on Android 11 and above). Any app with the same Android UID as a package specified here will have access when they call KeyChain.choosePrivateKeyAlias.',
                            type: 'array',
                            items: {
                                type: 'string',
                            },
                        },
                        privateKeyAlias: {
                            title: 'Private Key Alias',
                            description: 'The alias of the private key to be used.',
                            type: 'string',
                        },
                    },
                },
            },
            privateKeySelectionEnabled: {
                title: 'Private Key Selection Enabled',
                description:
                    'Allows showing UI on a device for a user to choose a private key alias if there are no matching rules in Choose Private Key Rules.',
                help: 'Note: For devices below Android P, setting this may leave enterprise keys vulnerable.',
                type: 'boolean',
            },
            permittedInputMethods: {
                title: 'Permitted Input Methods',
                description: 'If present, only the input methods provided by packages in this list are permitted.',
                help: 'Note: If this field is present, but the list is empty, then only system input methods are permitted.',
                type: 'object',
                properties: {
                    packageNames: {
                        title: 'Package Names',
                        description: 'A list of package names.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                },
            },
            permittedAccessibilityServices: {
                title: 'Permitted Accessibility Services',
                description:
                    "Specifies permitted accessibility services. If the field is not set, any accessibility service can be used. If the field is set, only the accessibility services in this list and the system's built-in accessibility service can be used. In particular, if the field is set to empty, only the system's built-in accessibility services can be used.",
                help: 'Note: This can only be set on fully managed devices and on work profiles.',
                type: 'object',
                properties: {
                    packageNames: {
                        title: 'Package Names',
                        description: 'A list of package names.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                },
            },
        },
    },
};
