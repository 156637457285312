import classNames from 'classnames';
import React from 'react';

export type PageTitleProps = {
    category?: string;
    description?: string | React.ReactNode;
    descriptionClassName?: string;
    categoryClassName?: string;
    color?: 'slate' | 'inherit';
    subtitle?: boolean;
    children: React.ReactNode;
    className?: string;
};

const PageTitle: React.FC<PageTitleProps> = ({
    category,
    description,
    descriptionClassName,
    categoryClassName,
    children,
    color = 'slate',
    subtitle = false,
    ...rest
}) => (
    <div {...rest}>
        {category && (
            <div
                className={classNames(
                    {
                        'tw-text-xs': true,
                        'tw-mb-1 tw-mt-0 tw-font-medium': true,
                        'tw-text-slate-800': color === 'slate',
                    },
                    categoryClassName
                )}
            >
                {category}
            </div>
        )}
        <div
            className={classNames({
                'tw-text-sm': subtitle,
                'tw-text-base': !subtitle,
                'tw-m-0 tw-font-semibold': true,
                'tw-text-slate-900': color === 'slate',
            })}
        >
            {children}
        </div>
        {description && (
            <div
                className={classNames(
                    {
                        'tw-text-xs': true,
                        'tw-mb-0 tw-mt-1 tw-font-medium': true,
                        'tw-text-slate-800': color === 'slate',
                    },
                    descriptionClassName
                )}
            >
                {description}
            </div>
        )}
    </div>
);

export { PageTitle };
