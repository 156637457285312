export type TCapaOneContractInfo = {
    [key: string]: TInnerInfo[];
};

type TInnerInfo = {
    name: string;
    link?: string;
    betaLink?: string;
    helpLink?: string;
    info: string;
    image: string;
    color: string;
};

export const capaOneContractInfo: TCapaOneContractInfo = {
    CapaPacks: [
        {
            name: 'CapaPacks',
            helpLink: 'https://capasystems.atlassian.net/wiki/spaces/CI62DOC/pages/7870916876/CapaPacks+in+Console',
            info: 'Read more about CapaInstaller Packages',
            image: 'capaPacks',
            color: 'Packs',
        },
    ],
    // CapaImages: [
    //     {
    //         name: 'CapaImages',
    //         link: 'https://api.capaone.com/redirect/capapacks/images',
    //         betaLink: 'https://apibeta.capaone.com/redirect/capapacks/images',
    //         info: 'Download the latest CapaInstaller Images',
    //         image: 'capaImage',
    //         color: 'Packs',
    //     },
    // ],
    CapaDrivers: [
        {
            name: 'CapaDrivers',
            helpLink: 'https://capasystems.atlassian.net/wiki/spaces/CF/pages/8537276417/CapaDrivers',
            info: 'Read more about CapaDrivers',
            image: 'capaDrivers',
            color: 'Packs',
        },
    ],
    'Service Desk': [
        {
            name: 'Service Desk',
            link: 'https://rattlesnake.capasystems.com/servicedesk/customer/portal/2',
            betaLink: 'https://rattlesnake.capasystems.com/servicedesk/customer/portal/2',
            info: 'Have any bug reports or questions about a CapaSystems program? Use Service Desk',
            image: 'service',
            color: 'Help',
        },
        {
            name: 'Technical Blog',
            helpLink: 'https://www.capasystems.dk/capasystems-technical-blog/',
            info: 'Read our Technical Blog to get a better understanding of our products',
            image: 'techBlog',
            color: 'Help',
        },
    ],
    PerformanceGuard: [
        {
            name: 'PerformanceGuard',
            helpLink: 'https://capasystems.atlassian.net/wiki/spaces/PD/overview',
            info: 'Need help with your PerformanceGuard installation?',
            image: 'performanceGuard',
            color: 'PerformanceGuard',
        },
    ],
    CapaInstaller: [
        {
            name: 'CapaInstaller',
            helpLink: 'https://capasystems.atlassian.net/wiki/spaces/CI/overview',
            info: 'Need help with your CapaInstaller installation?',
            image: 'capaInstaller',
            color: 'CapaInstaller',
        },
        {
            name: 'CapaOne for CapaInstaller',
            link: 'https://mdm.capaone.com',
            betaLink: 'https://mdmbeta.capaone.com',
            info: 'Manage Android Enterprise, Zero-touch, Certificates, and OS Deployment Templates in CapaOne.',
            image: 'capaInstaller',
            color: 'CapaInstaller',
        },
    ],
    ZeroTouch: [
        {
            name: 'ZeroTouch',
            link: 'https://mdm.capaone.com/configurations',
            betaLink: 'https://mdmbeta.capaone.com/configurations',
            info: 'Create a ZeroTouch Configuration',
            image: 'zeroTouch',
            color: 'CapaInstaller',
        },
    ],
    CapaForum: [
        {
            name: 'CapaForum',
            link: 'https://forum.capaone.com/',
            betaLink: 'https://forum.capaone.com/',
            info: 'Ask questions or help fellow Capa Users',
            image: 'capaForum',
            color: 'Help',
        },
    ],
    'Knowledge Base': [
        {
            name: 'Knowledge Base',
            helpLink: 'https://capasystems.atlassian.net/wiki/spaces/SKB/overview',
            info: 'How-To and Troubleshooting Articles',
            image: 'knowledgeBase',
            color: 'Help',
        },
    ],
    'PerformanceGuard Office 365': [
        {
            name: 'PerformanceGuard Office 365',
            link: 'https://capasystems.atlassian.net/wiki/spaces/PD/pages/8509920594/Office+365',
            betaLink: 'https://capasystems.atlassian.net/wiki/spaces/PD/pages/8509920594/Office+365',
            info: 'Get insights into Office 365',
            image: 'office365',
            color: 'PerformanceGuard',
        },
    ],
};

export default capaOneContractInfo;
