import { BUTTON } from '@capasystems/constants';
import { Button, Checkbox, Dialog, UploadButton } from '@capasystems/ui';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../confirm-dialog/confirm-dialog';
import { DrawerStepRow } from '../../../drawer-step-row/drawer-step-row';
import { useAppleApi } from '../../../hooks/useApi/useApi';
import { useCoreContext } from '../../../hooks/useCoreContext/useCoreContext';
import useMessageContext from '../../../hooks/useMessageContext/useMessageContext';
import { AppleCertificatePaper } from '../AppleCertificatePaper';

const AppleSettingsVPP = () => {
    const [confirm, setConfirm] = useState(false);
    const theme = useTheme();
    const api = useAppleApi();
    const appleApi = useAppleApi();
    const [vppTokenFile, setVppTokenFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const { appleEnterpriseAccount } = useCoreContext();
    const [force, setForce] = useState(false);
    const [uploadDialog, setUploadDialog] = useState(false);
    const { showErrorMessage } = useMessageContext(true);

    const hasVPPTokenExpired = appleEnterpriseAccount?.VPP?.expireDate ? appleEnterpriseAccount.VPP?.expireDate < new Date() : false;

    const handleCheckboxChange = (event) => {
        setCheckboxChecked(event.target.checked);
    };

    const onVPPTokenFileChange = (fileDetails) => {
        if (fileDetails.files.length !== 0) {
            if (fileDetails.filename.endsWith('.vpptoken')) {
                setVppTokenFile(fileDetails.files[0]);
            } else {
                showErrorMessage('The file name must be .vpptoken', { autoHideDuration: 600 });
            }
        }
    };

    const uploadButtonState = () => {
        if (isUploading) {
            return 'Uploading...';
        } else if (force) {
            return 'Force Update';
        } else {
            return 'Upload';
        }
    };

    return (
        <AppleCertificatePaper
            title="VPP Token"
            description="Manage licenses for your apps and software"
            expireDate={appleEnterpriseAccount?.VPP?.expireDate}
            onUpdate={() => setConfirm(true)}
        >
            These tokens allow you to assign and manage licenses for your purchases. When you upload a VPP token, it enables you to efficiently manage licenses
            for your apps and software within your organization.
            <br />
            <br />
            Volume Purchase Program tokens are licenses obtained through Apple's Business Manager, better known as VPP Token.
            <ConfirmDialog
                disabled={!checkboxChecked}
                open={confirm}
                onCancel={() => {
                    setConfirm(false);
                    setCheckboxChecked(false);
                }}
                onConfirm={() => {
                    setConfirm(false);
                    setUploadDialog(true);
                }}
                isProcessing={confirmLoading}
            >
                <p>Are you sure you want to update the VPP Token?</p>
                <p>Read these steps carefully to create your VPP Token</p>
                <DrawerStepRow badgeContent={1}>
                    <p>
                        Make sure that you can log in to your{' '}
                        <a
                            href="https://business.apple.com/#/main/preferences/myprofile"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: theme.palette.primary.main }}
                        >
                            <br />
                            <b>Apple Business Account</b>
                        </a>
                        <Checkbox
                            checked={checkboxChecked}
                            onChange={handleCheckboxChange}
                        />
                    </p>
                </DrawerStepRow>
                <DrawerStepRow badgeContent={2}>
                    <p>
                        Click <b>Confirm</b>. Then just follow the steps on screen.
                    </p>
                </DrawerStepRow>
            </ConfirmDialog>
            <Dialog
                open={uploadDialog}
                onClose={() => {
                    setVppTokenFile(null);
                    setCheckboxChecked(false);
                    setUploadDialog(false);
                }}
            >
                <div className="tw-grid tw-w-full tw-max-w-3xl tw-grid-rows-1fr-auto tw-p-4">
                    <div className="tw-flex tw-flex-col tw-items-center">
                        <h1 className="tw-mb-2">VPP Token</h1>
                        {force && (
                            <p className="tw-rounded-md tw-bg-red-200 tw-p-4 tw-text-red-900">
                                The location and token of the uploaded VPP does not match the previous one. If you want to force an update, click the force
                                update button below.
                            </p>
                        )}
                    </div>
                    <DrawerStepRow badgeContent={1}>
                        <p>
                            Log in to your{' '}
                            <a
                                href="https://business.apple.com/#/main/preferences/paymentsandbilling/appsandbooks"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: theme.palette.primary.main }}
                            >
                                <b>Apple Business Account</b>
                            </a>
                        </p>
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={2}>
                        Navigate to <b>'Preferences'</b> under your profile in the bottom-left corner click <b>Payments and Billing</b>.
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={3}>
                        Select the <b>Apps and Books</b> tab, and go down to <b>Content Tokens</b> and click download on the token name where the MDM server is
                        assigned.
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={4}>
                        Click the <b>Select VPP Token</b> button and confirm update
                    </DrawerStepRow>
                    <div className="tw-mt-4 tw-grid tw-w-full tw-grid-cols-1 tw-gap-4">
                        <UploadButton
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                            htmlId="upload-vppToken-button"
                            accept=".vpptoken"
                            onChange={onVPPTokenFileChange}
                            hideSelectedFiles
                            size="large"
                            disabled={isUploading}
                            fullWidth
                        >
                            {vppTokenFile?.name ?? 'Select VPP Token'}
                        </UploadButton>

                        <Button
                            className="tw-col-span-1"
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                            fullWidth
                            noMargin
                            disabled={isUploading || !vppTokenFile}
                            onClick={async () => {
                                setIsUploading(true);
                                const formData = new FormData();
                                formData.append('vpp', new Blob([vppTokenFile], { type: 'application/octet-stream' }));
                                formData.append('force', force);
                                try {
                                    await appleApi.uploadVppToken(formData);
                                    setConfirm(false);
                                    setCheckboxChecked(false);
                                    setVppTokenFile(null);
                                    setUploadDialog(false);
                                } catch (error) {
                                    if (error.status.code === 412) {
                                        setForce(true);
                                    } else {
                                        showErrorMessage(error.data?.message ?? error.message, { autoHideDuration: 600 });
                                        setIsUploading(false);
                                    }
                                } finally {
                                    setIsUploading(false);
                                }
                            }}
                        >
                            {uploadButtonState()}
                        </Button>
                    </div>
                </div>
            </Dialog>
        </AppleCertificatePaper>
    );
};
export { AppleSettingsVPP };
