import classNames from 'classnames';
import { isFunction } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import Button from '../button/button';
import Icon, { TIconType } from '../icon/icon';
import { LayoutCentered } from '../layout-center/layout-center';

export type EmptyStateProps = Omit<CoreEmptyStateProps, 'title' | 'iconType'> & {
    searchTerm?: string;
    isSearching?: boolean;
    entity?: string;
    onClearSearch?: () => void;
    onClick?: () => void;
    buttonText?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    title?: string;
    iconType?: TIconType;
};

const EmptyState: React.FC<EmptyStateProps> = ({
    searchTerm = '',
    isSearching = false,
    entity = 'item',
    onClearSearch,
    onClick,
    buttonText = 'New',
    color = 'primary',
    children,
    ...rest
}) => {
    if (isSearching) {
        return (
            <CoreEmptyState
                iconType="search"
                title={`No ${pluralize(entity)} found`}
                description={
                    <>
                        Your search {searchTerm && <b className="tw-font-semibold">"{searchTerm}"</b>} did not match any {pluralize(entity)}.
                    </>
                }
            >
                <div className="tw-flex tw-gap-2">
                    {isFunction(onClearSearch) && (
                        <Button
                            color="primary"
                            onClick={onClearSearch}
                            noMargin
                        >
                            Clear search
                        </Button>
                    )}
                    {isFunction(onClick) && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onClick}
                            noMargin
                        >
                            {buttonText}
                        </Button>
                    )}
                    {children}
                </div>
            </CoreEmptyState>
        );
    }
    return (
        <CoreEmptyState
            iconType="add"
            title={`No ${pluralize(entity)}`}
            description={`Create a new ${entity} to get started`} // Why I should create this item, how it will benefit me, or how to actually do it.
            color={color}
            {...rest}
        >
            <div className="tw-flex tw-gap-2">
                {isFunction(onClick) && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onClick}
                        noMargin
                        disabled={rest.disabled}
                    >
                        {buttonText}
                    </Button>
                )}
                {children}
            </div>
        </CoreEmptyState>
    );
};

export type CoreEmptyStateProps = {
    iconType: TIconType;
    title: any;
    description?: any;
    color?: 'primary' | 'error' | 'success';
    disableFixedDescriptionWidth?: boolean;
    children?: React.ReactNode;
};

const CoreEmptyState: React.FC<CoreEmptyStateProps> = ({
    children,
    iconType,
    title,
    description = null,
    color = 'primary',
    disableFixedDescriptionWidth = false,
}) => {
    return (
        <LayoutCentered
            direction="column"
            className="tw-overflow-hidden tw-p-4"
        >
            <div className="tw-mb-4">
                <div
                    className={classNames('tw-block tw-rounded-full tw-p-4', {
                        'tw-bg-sky-500/10': color === 'primary',
                        'tw-bg-red-500/10': color === 'error',
                        'tw-bg-emerald-500/10': color === 'success',
                    })}
                >
                    <Icon
                        type={iconType}
                        className={classNames('tw-h-8 tw-w-8', {
                            'tw-text-sky-400': color === 'primary',
                            'tw-text-red-400': color === 'error',
                            'tw-text-emerald-400': color === 'success',
                        })}
                    />
                </div>
            </div>
            <div className={classNames('tw-mb-0 tw-mt-1 tw-text-sm tw-font-semibold')}>{title}</div>
            <div
                className={classNames('tw-mb-0 tw-mt-1.5 tw-max-w-full tw-text-center tw-text-xs tw-font-medium tw-leading-relaxed', {
                    'tw-w-88': !disableFixedDescriptionWidth,
                })}
            >
                {description}
            </div>
            <div className="tw-mt-4">{children}</div>
        </LayoutCentered>
    );
};

export { EmptyState };
