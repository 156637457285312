import classnames from 'classnames';
import React from 'react';
import './sidebar.scss';

export type SidebarProps = {
    children: React.ReactNode;
    className?: string;
};

/**
 * Place content in the sidebar area of the grid
 */
export const Sidebar: React.FC<SidebarProps> = ({ className, ...rest }) => (
    <div
        className={classnames('cs-sidebar-area tw-shadow', className)}
        {...rest}
    />
);

export default Sidebar;
