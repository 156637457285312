import { Highcharts } from '@capasystems/ui';
import { colorHash, Url } from '@capasystems/utils';
import { LEAF_ID } from '@thirdparty/constants';
import React, { useContext, useEffect, useState } from 'react';
import { chartDefaults, chartFormatters } from '../constants-with-dependencies/constants-with-dependencies';
import { ReliabilityContext } from '../contexts/reliability-context/reliability.context';
import { useApi } from '../hooks/useApi/useApi';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

const ReliabilityEventSourcesWidget = ({ activeFilters, setUrlIsDirty }) => {
    const { deviceId, lockedPeriod, timezone, severityId, sampleInterval } = useContext(ReliabilityContext);
    const api = useApi();
    const [chartData, setChartData] = useState([]);
    const [widgetPaperProps, setWidgetPaperProps] = useState({
        loading: true,
    });

    const updateSourceFilter = (selectedSourceId) => {
        Url.set(LEAF_ID.SOURCE, selectedSourceId);
        setTimeout(() => {
            setUrlIsDirty(true);
        }, 100);
    };

    const responseHandler = (response) => {
        if (response.data.length > 0) {
            let pieDataTemp = [];
            response.data.forEach((source) => {
                const { hex: color } = colorHash(source.name);
                pieDataTemp.push({
                    ...source,
                    color: color,
                    events: {
                        legendItemClick(e) {
                            response.data.length > 1 ? updateSourceFilter(source.name) : e.preventDefault();
                        },
                        click(e) {
                            response.data.length > 1 ? updateSourceFilter(source.name) : e.preventDefault();
                        },
                    },
                });
            });
            setChartData(pieDataTemp.sort((a, b) => b.y - a.y));
        } else {
            setChartData([]);
        }
        setWidgetPaperProps({});
    };

    const onRequestError = (error) => {
        setWidgetPaperProps({
            errorMessage: 'Could not load event sources',
        });
        console.log(error);
    };

    useEffect(() => {
        api.cancel();
        if (activeFilters === null) {
            return;
        }
        setWidgetPaperProps({
            loading: true,
        });
        const logTypeFilter = activeFilters.find((filter) => filter.id === LEAF_ID.LOG_TYPE);
        const sourceFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SOURCE);
        const evendIdFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.EVENT_ID);
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);
        if (deviceId) {
            api.getEventPieChartDevice(
                deviceId,
                lockedPeriod,
                sampleInterval,
                timezone,
                severityId,
                logTypeFilter?.value.join(),
                sourceFilter?.value.join(),
                evendIdFilter?.value.join(),
                searchFilter?.value
            )
                .then(responseHandler)
                .catch(onRequestError);
        } else {
            api.getEventPieChart(
                lockedPeriod,
                sampleInterval,
                timezone,
                severityId,
                logTypeFilter?.value.join(),
                sourceFilter?.value.join(),
                evendIdFilter?.value.join(),
                searchFilter?.value
            )
                .then(responseHandler)
                .catch(onRequestError);
        }
    }, [lockedPeriod, activeFilters, deviceId, severityId]);

    return (
        <WidgetPaper
            title="Event sources"
            description="Shows the frequency of the event sources"
            className="tw-h-96"
            headerClassName="tw-pb-0"
            {...widgetPaperProps}
        >
            <Highcharts
                allowChartUpdate={false}
                options={{
                    chart: chartDefaults.pie.chart,
                    title: {
                        text: null,
                    },
                    tooltip: {
                        formatter: chartFormatters.pie.tooltip.formatter('event'),
                    },
                    plotOptions: {
                        pie: chartDefaults.pie.plotOptions,
                    },
                    legend: {
                        ...chartDefaults.pie.legend,
                        labelFormatter: chartFormatters.pie.legend.labelFormatter('event'),
                    },
                    series: [
                        {
                            cursor: 'pointer',
                            minPointSize: 10,
                            data: chartData,
                        },
                    ],
                }}
            />
        </WidgetPaper>
    );
};

ReliabilityEventSourcesWidget.propTypes = {};

export { ReliabilityEventSourcesWidget };
export default ReliabilityEventSourcesWidget;
