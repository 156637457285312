//Documentation
// https://developer.apple.com/documentation/devicemanagement/profile

import { SkipKeysElement } from './payloads/PayloadElements/SkipKeysElement';

export const AppleDEPEnrollmentProfile = {
    schema: {
        title: 'DEP Enrollment Profile',
        description: '',
        type: 'object',
        schemaId: 'appleDEPEnrollmentProfile',
        properties: {
            allow_pairing: {
                title: 'Allow Pairing',
                description: 'Allows users to connect supervised devices to a computer host.',
                help: 'In iOS 13, this property was deprecated.',
                type: 'boolean',
            },
            anchor_certs: {
                title: 'Anchor Certificates',
                description: 'A list of certificates that are DER-encoded (Distinguished Encoding Rules) and converted to Base64 encoding.',
                type: 'string', //TODO: each string should be a DER-encoded certificate
            },
            auto_advance_setup: {
                title: 'Auto Advance Setup',
                description: 'If enabled, the device will tell Setup Assistant to automatically advance though its screens.',
                help: 'This key is valid in X-Server-Protocol-Version 2 and later.',
                default: false,
                type: 'boolean',
            },
            await_device_configured: {
                title: 'Await Device Configured',
                description:
                    'If enabled, the device will not continue in Setup Assistant intil the MDM server sends a coomand that states the device is configured.',
                help: 'Ignored on iOS devices if is_supervised is false. This key is valid in X-Server-Protocol-Version 2 and later.',
                default: false,
                type: 'boolean',
            },
            configuration_web_url: {
                title: 'Configuration Web Url',
                description:
                    'The web address that customers visit when setting up a device. This website helps you log in securely, and once you’re ready, it starts the process of downloading a special profile that helps manage your device.',
                help: 'To provide the MDM enrollment profile, the web view looks for a page with MIME type application/x-apple-aspen-config.While the user is allowed to navigate to any site or host during authentication, the MDM enrollment profile must originate from the same host as specified in this field.',
                type: 'string',
            },
            department: {
                title: 'Department',
                description: 'The user-defined department or location name.',
                type: 'string',
            },
            devices: {
                title: 'Devices',
                description: 'A list of device serial numbers.',
                type: 'string',
            },
            is_mandatory: {
                title: 'Mandatory',
                description: 'If enabled, the user may not skip applying the profile returned by the MDM server.',
                help: 'In iOS 13 and later, all DEP enrollments are mandatory.',
                default: false,
                type: 'boolean',
            },
            is_mdm_removable: {
                title: 'MDM Removable',
                description:
                    'If disabled, the MDM payload delivered by the configuration URL cannot be removed by the user via the user interface on the device; that is, the MDM payload is locked onto the device.',
                help: 'This key can be set to false only if is_supervised is set to true.',
                default: true,
                type: 'boolean',
            },
            is_multi_user: {
                title: 'Multi User',
                description: 'If enabled, tells the device to configure for Shared iPad.',
                help: 'This key is valid only for Apple School Manager or Apple Business Manager organizations using X-Server-Protocol-Version 2 and later. Devices that do not meet the Shared iPad minimum requirements do not honor this command. With iOS devices, com.apple.mdm.per-user-connections must be added to the MDM enrollment profile’s Server Capabilities.',
                default: false,
                type: 'boolean',
            },
            is_supervised: {
                title: 'Supervised',
                description: 'If enabled, the device must be supervised.',
                help: 'In iOS 11, DEP devices that are not supervised have been deprecated. In iOS 11, all DEP devices will be supervised and the OS will ignore the is_supervised flag completely.',
                default: false,
                type: 'boolean',
            },
            language: {
                title: 'Language',
                description: 'Choose which language that the profile should be on.',
                type: 'string',
            },
            org_magic: {
                title: 'Managed Organization Services',
                description: 'Uniquely identifies various services that are managed by a single organization.',
                type: 'string',
            },
            profile_name: {
                title: 'Name',
                description: 'Name for the profile.',
                type: 'string',
            },
            region: {
                title: 'Region',
                description: 'Choose which country you are in.',
                type: 'string',
            },
            skip_setup_items: {
                title: 'Skip items',
                description: 'A list of setup panes to skip.',
                type: 'array',
                items: {
                    type: 'string',
                    options: SkipKeysElement,
                },
            },
            supervising_host_certs: {
                title: 'Supervising host certificates',
                description: 'A list that contains DER-encoded certificates converted to Base concoding.',
                help: 'If provided, the device will continue to pair with a host that possesses one of these certificates even when allow_pairing is set to false. If is_supervised is false, this list is unused.',
                type: 'string',
            },
            support_email_address: {
                title: 'Support Email Address',
                description: 'A support email address for the organization.',
                help: 'This key is valid in X-Server-Protocol-Version 2 and later.',
                type: 'string',
            },
            support_phone_number: {
                title: 'Support Phone Number',
                description: 'A support phone number for the organization.',
                type: 'string',
            },
            url: {
                title: 'URL',
                description: 'The URL of the MDM server.',
                type: 'string',
            },
        },
    },
};
