/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useNavigate } from './../../../index';
import { SnackbarContent, Snackbar, IconButton, Icon, LayoutRow } from '@capasystems/ui';

import { getUniqueId } from '@capasystems/utils';
import { coreMessage } from '@capasystems/constants';

const MessageContext = React.createContext();

const messages = [];

// eslint-disable-next-line react/prop-types
const MessageContextProvider = ({ children }) => {
    const [state, setState] = useState({ messages: [] });
    const navigate = useNavigate();

    const showErrorMessage = (message, configuration = {}) => {
        messages.push({
            severity: 'error',
            message,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            autoHideDuration: null,
            iconType: 'alert',
            ...configuration,
            key: getUniqueId('MessageContextProviderErrorMessage'),
        });
        setState({
            messages,
        });
    };

    const handleApiError = (response) => {
        showErrorMessage(response?.data?.message || response?.status?.message || coreMessage.anUnknownErrorOccurred);
    };

    const handleAccessDenied = (response) => {
        const message = response?.data?.message || response?.status?.message || coreMessage.anUnknownErrorOccurred;
        navigate.to(`access-denied/${message}`);
    };

    const clearMessages = () => {
        messages.length = 0;
        setState({
            messages,
        });
    };

    const showSuccessMessage = (message, configuration = {}) => {
        messages.push({
            severity: 'success',
            message,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },
            autoHideDuration: 6000,
            iconType: 'checkmark',
            ...configuration,
            key: getUniqueId('MessageContextProviderSuccessMessage'),
        });
        setState({
            messages,
        });
    };

    const onClose = (index) => (event, reason) => {
        if (reason === 'clickaway' && messages[index] === null) {
            return;
        }
        messages.splice(index, 1);
        setState({
            messages,
        });
    };

    return (
        <MessageContext.Provider
            value={{
                handleApiError,
                handleAccessDenied,
                showErrorMessage,
                showSuccessMessage,
                clearMessages,
                ...state,
            }}
        >
            {children}
            {state.messages.map((m, index) => (
                <Snackbar
                    key={m.key}
                    open
                    onClose={onClose(index)}
                    anchorOrigin={m.anchorOrigin}
                    autoHideDuration={m.autoHideDuration}
                >
                    <SnackbarContent
                        message={
                            <LayoutRow verticalAlign="center">
                                <Icon
                                    type={m.iconType}
                                    size="small"
                                />
                                &nbsp;&nbsp;<b>{m.message}</b>
                            </LayoutRow>
                        }
                        severity={m.severity}
                        action={[
                            <IconButton
                                key="remove"
                                onClick={onClose(index)}
                                color="inherit"
                                noMargin
                            >
                                <Icon
                                    type="remove"
                                    size="small"
                                />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            ))}
        </MessageContext.Provider>
    );
};

export { MessageContext, MessageContextProvider };
