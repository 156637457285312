export const AndroidBluetoothConfiguration = {
    schema: {
        title: 'Bluetooth',
        category: 'Networking',
        type: 'object',
        schemaId: 'bluetoothConfiguration',
        properties: {
            bluetoothDisabled: {
                title: 'Bluetooth Disabled',
                description: 'Whether bluetooth is disabled.',
                help: "Note: Prefer this setting over 'Bluetooth Config Disabled' because 'Bluetooth Config Disabled' can be bypassed by the user.",
                type: 'boolean',
            },
            bluetoothConfigDisabled: {
                title: 'Bluetooth Config Disabled',
                description: 'Whether configuring bluetooth is disabled.',
                help: "Note: Prefer 'Bluetooth Disabled' over this setting because 'Bluetooth Config Disabled' can be bypassed by the user.",
                type: 'boolean',
                shouldRender: (siblings: any) => {
                    if (siblings.bluetoothDisabled) {
                        delete siblings.bluetoothConfigDisabled;
                        return false;
                    }
                    return true;
                },
            },
            bluetoothContactSharingDisabled: {
                title: 'Bluetooth Contact Sharing Disabled',
                description: 'Whether bluetooth contact sharing is disabled.',
                type: 'boolean',
                shouldRender: (siblings: any) => {
                    if (siblings.bluetoothDisabled) {
                        delete siblings.bluetoothContactSharingDisabled;
                        return false;
                    }
                    return true;
                },
            },
        },
    },
};
