import { Highcharts } from '@capasystems/ui';
import { useMemo } from 'react';
import TWC from 'tailwindcss/colors';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export const UpdaterApplicationCompliance = ({ data, topCount = 5, className }) => {
    const navigate = useNavigate();

    const calculatedData = useMemo(() => {
        const tmp = {};

        data?.softwareData?.forEach((d) => {
            const upToDate = d.status.code >= 200 && d.status.code < 300;
            const notUpToDate = d.status.code === 302;

            if (upToDate || notUpToDate) {
                if (!tmp[d.appId]) {
                    tmp[d.appId] = {
                        id: d.appId,
                        name: d.name,
                        notUpToDate: 0,
                        upToDate: 0,
                        notInstalled: data?.totalDevices,
                    };
                }

                if (upToDate) {
                    tmp[d.appId].upToDate++;
                    tmp[d.appId].notInstalled--;
                } else if (notUpToDate) {
                    tmp[d.appId].notUpToDate++;
                    tmp[d.appId].notInstalled--;
                }
            }
        });

        const values = Object.values(tmp)
            .sort((a, b) => {
                if (b.notUpToDate === a.notUpToDate) {
                    return a.name.localeCompare(b.name);
                }

                return b.notUpToDate - a.notUpToDate;
            })
            .slice(0, topCount);

        let upToDate = [],
            notUpToDate = [],
            notInstalled = [],
            categories = [];
        values.forEach((d) => {
            categories.push(d.name);

            upToDate.push({
                y: d.upToDate,
                events: {
                    click: (e) => {
                        e.preventDefault();
                        navigate.to(`windows/updater/application/${d.id}?updater.status.tab=4`);
                    },
                },
            });
            notUpToDate.push({
                y: d.notUpToDate,
                events: {
                    click: (e) => {
                        e.preventDefault();
                        navigate.to(`windows/updater/application/${d.id}?updater.status.tab=0`);
                    },
                },
            });

            notInstalled.push({
                y: d.notInstalled,
                events: {
                    click: (e) => {
                        e.preventDefault();
                        navigate.to(`windows/updater/application/${d.id}?updater.status.tab=1`);
                    },
                },
            });
        });

        return {
            categories,
            upToDate,
            notUpToDate,
            notInstalled,
        };
    }, [data]);

    return (
        <WidgetPaper
            className={className}
            title="Software Compliance"
            description="This chart shows the percentage of devices that have the latest version of the software installed in top 5"
        >
            <Highcharts
                options={{
                    title: {
                        text: null,
                    },
                    colors: [TWC.red[400], TWC.emerald[400], TWC.gray[400]],
                    spacing: [0, 16, 12, 16],
                    chart: {
                        type: 'bar',
                    },
                    xAxis: {
                        categories: calculatedData.categories,
                        tickInterval: 1,
                        lineWidth: 0,
                        labels: {
                            align: 'right',
                            useHTML: true,
                            allowOverlap: true,
                            step: 1,
                            style: {
                                fontSize: '10px',
                                fontWeight: 'bold',
                            },
                            formatter() {
                                return `<span style="color: black">${this.value}</span>`;
                            },
                        },
                        showEmpty: false,
                    },
                    yAxis: {
                        title: {
                            enabled: false,
                        },
                        labels: {
                            overflow: 'justify',
                            formatter() {
                                return Math.abs(this.value) + '%';
                            },
                            style: {
                                color: TWC.slate[900],
                                fontWeight: 600,
                            },
                        },
                        lineWidth: 0,
                        minTickInterval: 1,
                        showLastLabel: true,
                        reversedStacks: false,
                        endOnTick: true,
                        showFirstLabel: false,
                        minorGridLineWidth: 1,
                        tickWidth: 1,
                        tickLength: 0,
                    },
                    plotOptions: {
                        series: {
                            showInLegend: true,
                            pointPadding: 0,
                            cursor: 'pointer',
                            borderRadiusTopLeft: 3,
                            borderRadiusTopRight: 3,
                            stacking: 'percent',
                            events: {},
                        },
                    },
                    series: [
                        {
                            name: 'Not Up-to-date',
                            data: calculatedData.notUpToDate,
                        },
                        {
                            name: 'Up-to-date',
                            data: calculatedData.upToDate,
                        },
                        {
                            name: 'Not Installed',
                            visible: false,
                            data: calculatedData.notInstalled,
                        },
                    ],
                    tooltip: {
                        backgroundColor: TWC.white,
                        outside: true,
                    },
                }}
            />
        </WidgetPaper>
    );
};
