export const AppleAppLayerVPNConfiguration = {
    schema: {
        schemaId: 'appleAppLayerVPNConfiguration',
        category: 'VPN',
        title: 'App Layer VPN',
        description: 'The payload you use to configure add-on VPN software.',
        type: 'object',
        properties: {
            'com.apple.vpn.managed.applayer': {
                title: '',
                description: '',
                type: 'object',
                properties: {
                    VPNUUID: {
                        title: 'VPN UUID',
                        description: ' A globally unique identifier for this VPN configuration.',
                        type: 'string',
                        required: true,
                    },
                    AssociatedDomains: {
                        title: 'Associated Domains',
                        description: 'An array with entries that must each specify a domain that triggers this VPN.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    CalendarDomains: {
                        title: 'Calendar Domains',
                        description: 'An array with entries that must each specify a domain that triggers this VPN connection in Calendar.',
                        help: 'Each entry is in the format www.apple.com. This property is deprecated in iOS 13.4 and later; use the VPNUUID property of the CalDAV payload instead.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    ContactsDomains: {
                        title: 'Contacts Domains',
                        description: 'An array with entries that must each specify a domain that triggers this VPN connection in Contacts.',
                        help: 'Each entry is in the format www.apple.com. This property is deprecated in iOS 13.4 and later; use the VPNUUID property of the CardDAV payload instead.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    ExcludedDomains: {
                        title: 'Excluded Domains',
                        description: 'An array with entries that each specify a domain that doesn’t trigger this VPN for connections to the domain.',
                        help: 'Available in iOS 14 and later, and macOS 11 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    MailDomains: {
                        title: 'Mail Domains',
                        description: 'An array with entries that must each specify a domain that triggers this VPN connection in Mail.',
                        help: 'Each entry is in the format www.apple.com.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    SafariDomains: {
                        title: 'Safari Domains',
                        description: 'An array with entries that must each specify a domain that triggers the VPN connection in Safari.',
                        help: 'Each entry is in the format www.apple.com.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    SMBDomains: {
                        title: 'SMB Domains',
                        description: 'An array of SMB domains that’s accessible through this VPN connection.',
                        help: 'Available in iOS 13 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    OnDemandMatchAppEnabled: {
                        title: 'OnDemand Match App Enabled',
                        description:
                            'If enabled, the VPN connects automatically when certain apps try to go online. If disabled, you need to start the VPN yourself for those apps. If not set, another setting called OnDemandEnabled decides.',
                        type: 'boolean',
                    },
                },
            },
        },
    },
};
