/* istanbul ignore file */
import classnames from 'classnames';
import React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import Button from '../button/button';

export type RouteButtonProps = {
    to: string | { pathname: string; search?: string } | ((location: { pathname: string; search?: string }) => void);
    className?: string;
};

/** RouteButton component */
export const RouteButton = React.forwardRef<any, RouteButtonProps>(({ className, ...otherProps }, ref) => (
    <Button
        ref={ref}
        component={ReactRouterDomLink}
        className={classnames(className, 'cs-route-button')}
        {...otherProps}
    />
));

export default RouteButton;
