import { COMMAND_IDS } from '@apple';
import { BUTTON, SORT_DIRECTION } from '@capasystems/constants';
import {
    Button,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    EmptyState,
    Icon,
    IconButton,
    Page,
    Tooltip,
    VirtualizedTable,
} from '@capasystems/ui';
import { Url, formatDate } from '@capasystems/utils';
import { NONE } from '@thirdparty/constants';
import { ActionsDialog, PageTitle, useApi, useAppleApi, useAppleDEPEndpointsSocket, useColumnPicker, useNavigate } from '@thirdparty/ui';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { useCallback, useEffect, useRef, useState } from 'react';

const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDir';

const AppleEndpointListDEP = ({ searchTerm }) => {
    const columnPicker = useColumnPicker({
        id: 'apple-endpoint-list-dep',
        lockedColumns: ['Serial Number'],
    });
    const navigate = useNavigate();
    const appleApi = useAppleApi();
    const api = useApi();
    const [enrollmentLink, setEnrollmentLink] = useState({ open: false });
    const [dirtyCount, setDirtyCount] = useState(0);
    const { to } = navigate;
    const [dimensions, setDimensions] = useState({});

    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });
    const queryBuilderRef = useRef({});
    const [loading, setLoading] = useState(true);
    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: { name: '' },
    });
    const enrollmentLinkbutton = {
        id: 'enrollmentLink',
        name: 'Link Enrollment',
    };
    /*const deleteFromDep = {
        id: 'deleteFromDep',
        name: 'Delete from DEP list',
    };*/
    const groupActions = [enrollmentLinkbutton /*, deleteFromDep*/];
    const [enrollmentList, setEnrollmentList] = useState([]);
    const [depEndpointList, setDepEndpointList] = useState([]);
    const [sortingState, setSortingState] = useState({
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        pageNumber: 0,
        pageSize: 50,
    });
    const [lastSync, setLastSync] = useState(null);

    useEffect(() => {
        fetchData();
    }, [searchTerm, sortingState]);

    const fetchData = () => {
        appleApi.cancel();
        setLoading(true);
        appleApi
            .getDepEndpoints({
                search: searchTerm,
                sortBy: sortingState.sortBy,
                sortDirection: sortingState.sortDirection,
                pageNumber: sortingState.pageNumber,
                pageSize: sortingState.pageSize,
            })
            .then((response) => {
                pagingRef.current.totalRowCount = response.count;
                if (sortingState.pageNumber === 0) {
                    setDepEndpointList(response.content);
                } else {
                    setDepEndpointList((currentList) => [...currentList, ...response.content]);
                }
            })

            .catch((_) => {
                // ignore
            })
            .finally(() => {
                setLoading(false);
            });
        setDirtyCount(0);
    };

    const fetchEnrollmenData = async () => {
        await appleApi.getEnrollmentConfigurations().then((response) => {
            setEnrollmentList(response);
        });
    };

    const fetchClusterAndEnrollmentData = async () => {
        await api.getAppleCluster().then((response) => {
            if (response?.lastDepSync) {
                setLastSync(response?.lastDepSync);
            }
        });
        await fetchEnrollmenData();
    };

    useEffect(() => {
        fetchClusterAndEnrollmentData();
    }, []);

    useAppleDEPEndpointsSocket(
        useCallback(
            ({ documentId, fullDocument, updateDescription }, { updateOperation, insertOperation, deleteOperation }) => {
                if (updateOperation) {
                    setDepEndpointList((currentList) =>
                        currentList.map((device) => {
                            if (device._id === documentId) {
                                return {
                                    ...fullDocument,
                                    _id: documentId,
                                };
                            }
                            return device;
                        })
                    );
                } else if (insertOperation) {
                    setDirtyCount((c) => c + 1);
                } else if (deleteOperation) {
                    setDepEndpointList((currentList) => {
                        return currentList.filter((device) => device.id !== documentId);
                    });
                }
                setLastSync(new Date());
            },
            [sortingState]
        )
    );

    const handleLinking = (respond) => {
        appleApi
            .updateAppleDepEnrollmentlinking(respond.id, { serial_number: enrollmentLink.serial_number })
            .then(onCloseEnrollmentDialog)
            .catch((_) => {
                // ignore
            });
    };

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case enrollmentLinkbutton.id:
                setEnrollmentLink({ open: true, serial_number: menuState.rowData.serial_number });
                break;
            /*case deleteFromDep.id:
                // probably should be moved to a separate function and use a dialog to confirm
                appleApi.sendCommand(menuState.rowData.udid, { commandId: COMMAND_IDS.DELETE_VIA_DEP }).catch((_) => {
                    // ignore
                });*/
        }
    };
    const onScrollToBottom = () => {
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: currentPagingState.pageNumber + 1,
        }));
    };

    const onOrderByChange = ({ sortBy, sortDirection }) => {
        Url.set(SORT_BY, sortBy);
        Url.set(SORT_DIR, sortDirection);
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: 0,
            sortBy,
            sortDirection,
        }));
    };
    const onCloseEnrollmentDialog = () => {
        setEnrollmentLink({ open: false });
    };

    const forceRefresh = () => {
        setSortingState((currentPagingState) => ({ ...currentPagingState }));
    };

    const syncAppleDepDevices = () => {
        appleApi.sendCommand({
            commandId: COMMAND_IDS.SYNC_DEP_DEVICES,
        });
        setLastSync(new Date());
    };

    return (
        <Page title="DEP Endpoints">
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="DEP Endpoint"
                title={menuState.rowData.serial_number}
                pages={menuState.pages}
                actions={groupActions} //I wanted to have just the schema action here but it just makes it throw a error
                onActionClick={onActionClick}
            />
            <VirtualizedTable
                entity="endpoint"
                items={depEndpointList}
                onResize={setDimensions}
                isLoading={loading}
                onScrollToBottom={onScrollToBottom}
                totalRowCount={pagingRef.current.totalRowCount}
                showRowCount
                sort={onOrderByChange}
                sortBy={sortingState.sortBy}
                sortDirection={sortingState.sortDirection}
                disableHeader={depEndpointList.length === 0 && searchTerm === ''}
                noRowsRenderer={() => {
                    const isSearching = searchTerm !== '';
                    return (
                        <EmptyState
                            searchTerm={searchTerm}
                            isSearching={isSearching}
                            entity="endpoint"
                            description="Get started by setting up an enrollment configuration."
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            onClick={isSearching ? undefined : () => to('apple/enrollment')}
                            buttonText="Enrollment"
                        />
                    );
                }}
                actions={
                    <>
                        {dirtyCount > 0 && (
                            <Tooltip
                                content={`${pluralize('new endpoint', dirtyCount, true)} detected`}
                                open
                                arrow
                                theme="rose"
                            >
                                <Button
                                    size={BUTTON.SMALL}
                                    onClick={forceRefresh}
                                >
                                    Refresh
                                </Button>
                            </Tooltip>
                        )}
                        <Tooltip
                            content={
                                <div>
                                    Sync DEP devices
                                    {lastSync && <div className="tw-mt-1 tw-text-tiny tw-font-medium">Last sync: {formatDate(lastSync)}</div>}
                                </div>
                            }
                        >
                            <IconButton
                                onClick={syncAppleDepDevices}
                                size={BUTTON.SMALL}
                                color={BUTTON.PRIMARY}
                                noMargin
                            >
                                <Icon type="sync" />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                columnPicker={columnPicker}
            >
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="id"
                    label=""
                    disableSort
                    type="icon"
                    cellRenderer={({ rowData }) => {
                        return (
                            <IconButton
                                onClick={(e) => {
                                    setMenuState({
                                        open: true,
                                        anchorEl: e.currentTarget,
                                        rowData,
                                    });
                                }}
                                noMargin
                            >
                                <Icon type="moreVert" />
                            </IconButton>
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="serial_number"
                    label="Serial Number"
                    type="string"
                />
                <Column
                    minWidth={160}
                    maxWidth={320}
                    dataKey="last_seen"
                    label="Last Seen"
                    type="timestamp"
                    cellRenderer={({ rowData }) => {
                        if (rowData?.last_seen && dayjs(rowData.last_seen).isAfter(dayjs().subtract(20, 'years'))) {
                            return dayjs(rowData.last_seen).fromNow();
                        }
                        return <span className="tw-text-gray-300">{NONE}</span>;
                    }}
                />
                <Column
                    minWidth={160}
                    maxWidth={320}
                    dataKey="dep_profile_status"
                    label="Profile Status"
                    type="string"
                    cellRenderer={({ rowData }) => {
                        const { dep_profile_status } = rowData;
                        if (dep_profile_status) {
                            return dep_profile_status.charAt(0).toUpperCase() + dep_profile_status.slice(1);
                        }
                        return null;
                    }}
                />
                <Column
                    minWidth={136}
                    maxWidth={136}
                    dataKey="enrollment_status"
                    label="Enrollment Status"
                    type="icon"
                    cellRenderer={({ rowData }) => {
                        return <Icon type={rowData.enrollment_status === 'true' ? 'checkmarkOutlined' : 'dashOutlined'} />;
                    }}
                />
            </VirtualizedTable>
            <Dialog
                open={enrollmentLink.open}
                onClose={onCloseEnrollmentDialog}
                size="md"
            >
                <DialogTitle className="tw-px-4">
                    <PageTitle>Enrollment Linking</PageTitle>
                </DialogTitle>
                <DialogContent
                    className="tw-border-y tw-p-0"
                    style={{ maxHeight: '80vh', height: '80vh' }}
                >
                    <VirtualizedTable
                        items={enrollmentList}
                        rowClassName="hover:tw-bg-slate-50"
                        isLoading={loading}
                        disableHeader
                        onRowClick={({ rowData }) => handleLinking(rowData)}
                        noRowsRenderer={() => {
                            return (
                                <EmptyState
                                    entity="endpoint"
                                    description="Get started by setting up an enrollment configuration."
                                    buttonText="Enrollment"
                                />
                            );
                        }}
                    >
                        <Column
                            minWidth={100}
                            dataKey="name"
                        />
                    </VirtualizedTable>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseEnrollmentDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Page>
    );
};

export { AppleEndpointListDEP };
