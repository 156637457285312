import MUISnackbarContent, { SnackbarContentProps as MUISnackbarContentProps } from '@mui/material/SnackbarContent';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

export type SnackbarContentProps = MUISnackbarContentProps & {
    severity: 'error' | 'warning' | 'info' | 'success';
};

/** SnackbarContent component */
export const SnackbarContent = forwardRef<HTMLDivElement, SnackbarContentProps>(({ severity = 'info', className, ...otherProps }, ref) => {
    return (
        <MUISnackbarContent
            ref={ref}
            className={classNames(
                {
                    'tw-font-semibold tw-text-white': true,
                    'tw-bg-red-600': severity === 'error',
                    'tw-bg-orange-600': severity === 'warning',
                    'tw-bg-sky-600': severity === 'info',
                    'tw-bg-emerald-600': severity === 'success',
                },
                className
            )}
            {...otherProps}
        />
    );
});

export default SnackbarContent;
