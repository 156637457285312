import { SkipKeysElement } from '../PayloadElements/SkipKeysElement';

export const AppleSetupAssistantConfiguration = {
    schema: {
        schemaId: 'appleSetupAssistantConfiguration',
        category: 'User Experience',
        title: 'Setup Assistant',
        type: 'object',
        properties: {
            'com.apple.SetupAssistant.managed': {
                title: '',
                type: 'object',
                properties: {
                    SkipAccessibility: {
                        title: 'Skip Accessibility',
                        description: 'If true, the system skips the Accessibility pane.',
                        type: 'boolean',
                    },
                    SkipAppearance: {
                        title: 'Skip Appearance',
                        description: 'If true, the system skips the Choose Your Look pane.',
                        type: 'boolean',
                    },
                    SkipCloudSetup: {
                        title: 'Skip Cloud Setup',
                        description: 'If true, the system skips the iCloud pane.',
                        type: 'boolean',
                    },
                    SkipiCloudStorageSetup: {
                        title: 'Skip iCloud Storage Setup',
                        description: 'If true, the system skips the iCloud Storage pane. ',
                        type: 'boolean',
                    },
                    SkipPrivacySetup: {
                        title: 'Skip Privacy Setup',
                        description: 'If true, the system skips the Privacy consent pane. ',
                        type: 'boolean',
                    },
                    SkipScreenTime: {
                        title: 'Skip Screen Time',
                        description: 'If true, the system skips the Screen Time pane.',
                        type: 'boolean',
                    },
                    SkipSiriSetup: {
                        title: 'Skip Siri Setup',
                        description: 'If true, the system skips the Siri setup pane.',
                        type: 'boolean',
                    },
                    SkipTouchIDSetup: {
                        title: 'Skip Touch ID Setup',
                        description: 'If true, the system skips the Touch ID setup pane.',
                        type: 'boolean',
                    },
                    SkipTrueTone: {
                        title: 'Skip True Tone',
                        description: 'If true, the system skips the True Tone Display pane. ',
                        type: 'boolean',
                    },
                    SkipUnlockWithWatch: {
                        title: 'Skip Unlock With Watch',
                        description: 'If true, the system skips the Unlock With Apple Watch pane.',
                        type: 'boolean',
                    },
                    SkipWallpaper: {
                        title: 'Skip Wallpaper',
                        description: 'If true, the system skips the Wallpaper pane.',
                        type: 'boolean',
                    },
                    SkipSetupItems: {
                        title: 'Skip Setup Items',
                        description: 'An array strings that describe the setup items to skip.',
                        type: 'array',
                        items: {
                            type: 'string',
                            options: SkipKeysElement, //TODO: Should there be link to documentation?
                        },
                    },
                },
            },
        },
    },
};
