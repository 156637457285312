import { LAYOUT } from '@capasystems/constants';
import { cloneDeep } from 'lodash';
import React from 'react';
import './layout-fill.scss';

type TGetStyles = (width?: number, height?: number) => React.CSSProperties;

const getStyles: TGetStyles = (width, height) => {
    if (width || height) {
        const styles = cloneDeep(LAYOUT.WIDTH);
        if (width) {
            styles.maxWidth = `${width}%`;
        }
        if (height) {
            styles.maxHeight = `${height}%`;
        }
        return styles;
    }
    return LAYOUT.FLEX;
};

export type LayoutFillProps = {
    width?: number;
    height?: number;
    style?: React.CSSProperties;
    children?: React.ReactNode;
};

export const LayoutFill: React.FC<LayoutFillProps> = ({ style = {}, width, height, ...otherProps }) => (
    <div
        style={{ ...getStyles(width, height), ...style }}
        {...otherProps}
    />
);

export default LayoutFill;
