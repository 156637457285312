const IconItem = {
    Type: {
        title: 'Type',
        type: 'string',
        enum: ['Application', 'Folder', 'WebClip'],
        required: true,
    },
    BundleID: {
        title: 'Bundle ID',
        type: 'string',
        required: (siblings: any) => siblings.Type === 'Application',
    },
    URL: {
        title: 'URL',
        type: 'string',
        required: (siblings: any) => siblings.Type === 'WebClip',
    },
    DisplayName: {
        title: 'Display Name',
        type: 'string',
        required: (siblings: any) => siblings.Type === 'Folder',
    },
    Pages: {
        title: 'Pages',
        type: 'array',
        items: {
            title: '',
            description: '',
            type: 'array',
            items: {
                title: '',
                description: '',
                type: 'object',
                properties: {
                    Type: {
                        title: 'Type',
                        type: 'string',
                        enum: ['Application', 'Folder', 'WebClip'],
                        required: true,
                    },
                    BundleId: {
                        title: 'Bundle ID',
                        type: 'string',
                        required: (siblings: any) => siblings.Type === 'Application',
                    },
                    URL: {
                        title: 'URL',
                        type: 'string',
                        required: (siblings: any) => siblings.Type === 'WebClip',
                    },
                    DisplayName: {
                        title: 'Display Name',
                        type: 'string',
                        required: (siblings: any) => siblings.Type === 'Folder',
                    },
                },
            },
        },
    },
};

export const AppleHomeScreenLayoutConfiguration = {
    schema: {
        schemaId: 'appleHomeScreenLayoutConfiguration',
        category: 'User Experience',
        title: 'Home Screen Layout',
        type: 'object',
        properties: {
            'com.apple.homescreenlayout': {
                Pages: {
                    title: 'Pages',
                    description: 'The layout of the home screen pages.',
                    required: true,
                    type: 'array',
                    items: {
                        title: '',
                        description: '',
                        type: 'array',
                        items: {
                            title: '',
                            description: '',
                            type: 'object',
                            properties: IconItem,
                        },
                    },
                },
                Dock: {
                    title: 'Dock',
                    description: '',
                    help: `If this key is not present, the user's dock is empty.`,
                    type: 'array',
                    items: {
                        //* Max 4 items
                        type: 'object',
                        properties: IconItem,
                    },
                },
            },
        },
    },
};
