import { noop } from '@capasystems/utils';
import React, { useEffect } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '../../index';

export type TrialUpgradeDialogProps = {
    onClose: () => void;
};

const TrialUpgradeDialog: React.FC<TrialUpgradeDialogProps> = ({ onClose }) => {
    useEffect(() => {
        fetch('/api/organization/upgrade-request', {
            method: 'GET',
            credentials: 'include',
        }).catch(noop);
    }, []);
    return (
        <>
            <DialogTitle>Thank you for trying CapaSystems Cloud Solution - CapaOne.</DialogTitle>
            <DialogContent>
                <p>
                    At CapaSystems, we think it's much better to communicate directly with our customers, to make sure we find the right combination of products
                    that fulfill your needs.
                </p>
                <br />
                <p>We will contact you shortly by phone and email.</p>
                <br />
                <p>Have a great day!</p>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export { TrialUpgradeDialog };
