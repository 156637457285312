import { useTheme } from '@capasystems/utils';
import classNames from 'classnames';
import React from 'react';
import './layout-core.scss';

type StyleLayoutProps = {
    direction?: 'row' | 'column';
    flex?: string;
    wrap?: 'wrap' | 'wrap-reverse' | 'nowrap';
    align?: 'flex-start' | 'start' | 'flex-end' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    verticalAlign?: 'flex-start' | 'start' | 'flex-end' | 'end' | 'center' | 'baseline' | 'stretch';
};

const styleLayout = (props: StyleLayoutProps) => ({
    display: 'flex',
    flexDirection: props.direction ? props.direction : /* istanbul ignore next */ 'row',
    flex: props.flex ? props.flex : /* istanbul ignore next */ '0 1 auto',
    flexWrap: props.wrap ? props.wrap : /* istanbul ignore next */ 'nowrap',
    justifyContent: props.align
        ? props.align === 'end'
            ? /* istanbul ignore next */ 'flex-end'
            : props.align === 'start'
            ? 'flex-start'
            : props.align
        : /* istanbul ignore next */ 'flex-start',
    alignItems: props.verticalAlign
        ? props.verticalAlign === 'end'
            ? /* istanbul ignore next */ 'flex-end'
            : props.verticalAlign === 'start'
            ? /* istanbul ignore next */ 'flex-start'
            : props.verticalAlign
        : /* istanbul ignore next */ 'stretch',
});

type ClassesProps = {
    className?: string;
    fill?: boolean;
    scrollContent?: boolean;
    direction?: 'row' | 'column';
    theme?: any;
};

const classes = (props: ClassesProps) => {
    let classNamesVal = classNames('flex', props.className);

    classNamesVal = props.direction === 'row' ? classNames('layout-row', classNamesVal) : classNames('layout-column', classNamesVal);
    if (props.fill) classNamesVal = classNames('layoutFill', classNamesVal);
    if (props.scrollContent) classNamesVal = classNames('scrollContent', classNamesVal);
    return classNamesVal;
};

export type LayoutCoreProps = {
    /** Add row | column */
    direction?: 'row' | 'column';
    /** Shorthand for flex-grow, flex-shrink and flex-basis. */
    flex?: string;
    /** Values: wrap | wrap-reverse | nowrap */
    wrap?: 'wrap' | 'wrap-reverse' | 'nowrap';
    /** Values: flex-start || start | flex-end || end | center | space-between | space-around | space-evenly */
    align?: 'flex-start' | 'start' | 'flex-end' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    /** Values: flex-start | flex-end | center | baseline | stretch */
    verticalAlign?: 'flex-start' | 'start' | 'flex-end' | 'end' | 'center' | 'baseline' | 'stretch';
    /** Fill out layout. */
    fill?: boolean;
    /** Scroll content. */
    scrollContent?: boolean;
    className?: string;
    style?: React.CSSProperties;
};

export const LayoutCore: React.FC<LayoutCoreProps> = ({
    className,
    fill = false,
    scrollContent = false,
    direction = 'row',
    flex = '0 1 auto',
    wrap = 'nowrap',
    align = 'flex-start',
    verticalAlign = 'stretch',
    style,
    ...otherProps
}) => {
    // const  = props;

    const theme = useTheme();

    return (
        <div
            {...otherProps}
            className={classes({
                className,
                fill,
                scrollContent,
                direction,
                theme,
            })}
            style={{
                ...styleLayout({
                    direction,
                    flex,
                    wrap,
                    align,
                    verticalAlign,
                }),
                ...style,
            }}
        />
    );
};
