export enum COMMAND_IDS {
    DEVICEINFO = 'DeviceInformation',
    UPDATECERTIFICATE = 'UpdateCertificate',
    UPDATEDEPCERTIFICATE = 'UpdateDEPCertificate',
    INSTALLEDAPPLICATIONLIST = 'InstalledApplicationList',
    SECURITYINFO = 'SecurityInfo',
    ENROLLDEVICE = 'EnrollDevice',
    PROFILELIST = 'ProfileList',
    RESTRICTIONS = 'Restrictions',
    AVAILABLEOSUPDATES = 'AvailableOSUpdates',
    OS_UPDATE_STATUS = 'OSUpdateStatus',
    CREATEENROLLMENT = 'CreateEnrollment',
    DELETEENROLLMENT = 'DeleteEnrollment',
    CREATE_APPLE_PUSH_REQUEST_CERTIFICATE = 'CreateApplePushRequestCertificate',
    CREATE_APPLE_DEP_REQUEST_CERTIFICATE = 'CreateAppleDEPRequestCertificate',
    RECONFIGURE_DEVICE = 'ReconfigureDevice',
    INSTALL_APPLICATION = 'InstallApplication',
    REMOVE_APPLICATION = 'RemoveApplication',
    RESTART_DEVICE = 'RestartDevice',
    DEVICE_LOCK = 'DeviceLock',
    SHUTDOWN_DEVICE = 'ShutDownDevice',
    ERASE_DEVICE = 'EraseDevice',
    SYNC_DEP_DEVICES = 'SyncDEPDevices',
    SCHEDULE_OS_UPDATE = 'ScheduleOSUpdate',
    CLEAR_PASSCODE = 'ClearPasscode',
    SET_DEFAULT_DEP_PROFILE = 'SetDefaultDEPProfile',
    SYNC_VPP = 'SyncVPP',
    MANAGED_INSTALLED_APPLICATION_LIST = 'ManagedApplicationList',
    INSTALL_PROFILE = 'InstallProfile',
    REMOVE_PROFILE = 'RemoveProfile',
    CLEAR_COMMAND_QUEUE = 'ClearCommandQueue',
    SETTINGS = 'Settings',
    DELETE_ENDPOINT_DATA = 'DeleteEndpointData',
    ENABLE_LOST_MODE = 'EnableLostMode',
    DISABLE_LOST_MODE = 'DisableLostMode',
    ACTIVATION_LOCK_BYPASS_CODE = 'ActivationLockBypassCode',
    DELETE_VIA_DEP = 'DeleteViaDEP',
}
