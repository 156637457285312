import { BUTTON } from '@capasystems/constants';
import { isDefined } from '@capasystems/utils';
import Collapse from '@mui/material/Collapse';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../checkbox/checkbox';
import IconButton from '../icon-button/icon-button';
import Icon from '../icon/icon';
import Tree from './tree';

export default class TreeNode extends React.PureComponent {
    static defaultProps = {
        expanded: false,
        isLeaf: false,
        onSelect: () => null,
        selectable: false,
        selected: false,
        description: undefined,
        className: '',
        nameProps: {},
        children: undefined,
    };

    /* istanbul ignore next */
    onExpand = () => {
        const { onExpand } = this.props;
        onExpand(this);
    };

    /* istanbul ignore next */
    onSelect = () => {
        const { onSelect } = this.props;
        onSelect(this);
    };

    render() {
        const {
            isLeaf,
            expanded,
            selectable,
            selected,
            name,
            description,
            className,
            nameProps: { className: namePropsClassName, ...restNameProps },
            children,
        } = this.props;
        const expandedIconType = expanded ? /* istanbul ignore next */ 'chevronDown' : 'chevronRight';
        return (
            <li
                className={classnames({
                    'cs-tree-node': true,
                    [className]: true,
                })}
            >
                <IconButton
                    disabled={isLeaf}
                    onClick={this.onExpand}
                    color={BUTTON.INHERIT}
                    className="cs-tree-node-expand-button"
                    noMargin
                >
                    <Icon
                        type={expandedIconType}
                        size="small"
                    />
                </IconButton>
                {selectable && (
                    /* istanbul ignore next */
                    <Checkbox
                        className="cs-tree-node-checkbox"
                        onChange={this.onSelect}
                        checked={selected}
                    />
                )}
                <b
                    className={classnames({
                        'cs-tree-node-name': true,
                        [namePropsClassName]: isDefined(namePropsClassName),
                    })}
                    {...restNameProps}
                >
                    {name}
                </b>
                {description && (
                    /* istanbul ignore next */
                    <span className="cs-tree-node-description">{description}</span>
                )}
                {children && (
                    <Collapse in={expanded}>
                        <Tree>{children}</Tree>
                    </Collapse>
                )}
            </li>
        );
    }
}

TreeNode.propTypes = {
    /** The entire treeNode */
    // eslint-disable-next-line react/no-unused-prop-types
    dataRef: PropTypes.shape().isRequired,
    name: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    isLeaf: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onExpand: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    description: PropTypes.string,
    className: PropTypes.string,
    nameProps: PropTypes.shape(),
    children: PropTypes.node,
};

export { TreeNode };
