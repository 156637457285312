import { LayoutCore, Toolbar } from '@capasystems/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const WidgetTitle = ({
    children,
    className = '',
    subheading = false,
    bold = false,
    regular = false,
    style,
    center = false,
    position = 'static',
    toolbarClassName,
    align = 'flex-start',
}) => (
    <div>
        <Toolbar
            position={position}
            className={toolbarClassName}
            style={style}
        >
            <LayoutCore
                align={align}
                fill
            >
                <h2 className={classNames('tw-text-sm tw-font-semibold', className)}>{children}</h2>
            </LayoutCore>
        </Toolbar>
    </div>
);

WidgetTitle.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    subheading: PropTypes.bool,
    bold: PropTypes.bool,
    regular: PropTypes.bool,
    center: PropTypes.bool,
    position: PropTypes.string,
    align: PropTypes.string,
};

export default WidgetTitle;
