import { App as AppDependencies } from '@capasystems/ui';
import { AuthContextProvider, CoreContextProvider, MessageContextProvider } from '@thirdparty/ui';
import React from 'react';
// import App from './app';
import { BASE_ORGANIZATION_ROUTE } from '@thirdparty/constants';
import { Route, Routes } from 'react-router-dom';
import { ApplicationLayoutAndRoutes } from './app';
import { UploadProvider } from './components/contenxt/UploadContext';
import './styles/main.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const Index = () => (
    <AppDependencies>
        <Routes>
            <Route
                path={BASE_ORGANIZATION_ROUTE + '/*'}
                element={<RouteRender />}
            />
            <Route
                path="*"
                element={<RouteRender />}
            />
        </Routes>
    </AppDependencies>
);

const RouteRender = () => (
    <AuthContextProvider>
        <CoreContextProvider>
            <MessageContextProvider>
                <UploadProvider>
                    <DndProvider backend={HTML5Backend}>
                        <ApplicationLayoutAndRoutes />
                    </DndProvider>
                </UploadProvider>
            </MessageContextProvider>
        </CoreContextProvider>
    </AuthContextProvider>
);

export default Index;
