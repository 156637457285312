import { Highcharts, Icon, LayoutCenter, LayoutColumn, LayoutRow } from '@capasystems/ui';
import { isUndefined } from '@capasystems/utils';
import { LEAF_ID } from '@thirdparty/constants';
import classNames from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import colors from 'tailwindcss/colors';
import { RELIABILITY, ReliabilityContext, useApi } from '../../index';
import { chartDefaults, chartFormatters } from '../constants-with-dependencies/constants-with-dependencies';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

const validSeverities = Object.values(RELIABILITY.SEVERITY).map((s) => s.id);

const ReliabilityEventCountWidget = ({ severity, activeFilters }) => {
    const { deviceId, lockedPeriod, timezone, inDetailedMode, setSeverityId, sampleInterval } = useContext(ReliabilityContext);
    const api = useApi();
    const [animating, setAnimating] = useState(false);
    const [widgetData, setWidgetData] = useState({
        affectedDevices: 0,
        application: 0,
        system: 0,
    });
    const [widgetPaperProps, setWidgetPaperProps] = useState({ loading: true });

    const navigateToSeverity = () => {
        setSeverityId(severity.id);
    };

    const navigateToLogType = (event, logType) => {
        event.preventDefault();
        setTimeout(() => {
            setSeverityId(severity.id, logType);
        }, 10);
    };

    useEffect(() => {
        api.cancel();
        if (activeFilters === null) {
            return;
        }
        setWidgetPaperProps({
            loading: true,
        });
        const logTypeFilter = activeFilters.find((filter) => filter.id === LEAF_ID.LOG_TYPE);
        const sourceFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SOURCE);
        const evendIdFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.EVENT_ID);
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);

        const onSuccess = (response) => {
            setWidgetData(response);
            setWidgetPaperProps({});
        };

        const onError = (error) => {
            console.log(error);
            setWidgetPaperProps({
                errorMessage: 'An error occurred while fetching data',
            });
        };

        if (deviceId) {
            api.getEventTypeOverviewDevice(
                deviceId,
                lockedPeriod,
                sampleInterval,
                timezone,
                severity.id,
                logTypeFilter?.value.join(),
                sourceFilter?.value.join(),
                evendIdFilter?.value.join(),
                searchFilter?.value
            )
                .then(onSuccess)
                .catch(onError);
        } else {
            api.getEventTypeOverview(
                lockedPeriod,
                sampleInterval,
                timezone,
                severity.id,
                logTypeFilter?.value.join(),
                sourceFilter?.value.join(),
                evendIdFilter?.value.join(),
                searchFilter?.value
            )
                .then(onSuccess)
                .catch(onError);
        }
    }, [lockedPeriod, activeFilters]);

    useEffect(() => {
        setAnimating(true);
        const to = setTimeout(() => {
            setAnimating(false);
        }, 250);
        return () => {
            clearTimeout(to);
        };
    }, [inDetailedMode]);

    return (
        <WidgetPaper
            title={pluralize(severity.name)}
            className={classNames({
                'tw-cursor-pointer tw-overflow-hidden tw-transition-all': true,
                'tw-h-112': inDetailedMode,
                'tw-h-32': !inDetailedMode,
            })}
            color={severity.colorRepresentation}
            light
            onClick={navigateToSeverity}
            {...widgetPaperProps}
        >
            <LayoutColumn fill>
                <div className="tw-px-4">
                    <LayoutRow
                        align="space-between"
                        className="tw-text-2xl tw-font-semibold"
                    >
                        <div>{(widgetData.application + widgetData.system).toLocaleString()}</div>
                        {isUndefined(deviceId) && <div>{widgetData.affectedDevices}</div>}
                    </LayoutRow>
                    <LayoutRow align="space-between">
                        <h2 className="tw-text-md tw-font-regular tw-m-0 tw-text-current">{pluralize(`event`, widgetData.application + widgetData.system)}</h2>
                        {isUndefined(deviceId) && (
                            <h2 className="tw-text-md tw-font-regular tw-m-0 tw-text-current">{pluralize(`endpoint`, widgetData.affectedDevices)} affected</h2>
                        )}
                    </LayoutRow>
                </div>

                {inDetailedMode && (
                    <div className="tw-h-full tw-overflow-hidden">
                        {!animating &&
                            (widgetData.application + widgetData.system > 0 ? (
                                <span
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ cursor: 'default' }}
                                >
                                    <Highcharts
                                        allowChartUpdate={false}
                                        options={{
                                            chart: { ...chartDefaults.pie.chart, spacingRight: 8, spacingLeft: 8, marginBottom: 8 },
                                            title: {
                                                text: null,
                                            },
                                            tooltip: {
                                                ...chartDefaults.pie.tooltip,
                                                formatter: chartFormatters.pie.tooltip.formatter('event'),
                                            },
                                            plotOptions: {
                                                pie: {
                                                    ...chartDefaults.pie.plotOptions,
                                                    point: {
                                                        events: {
                                                            click(event) {
                                                                navigateToLogType(event, this.name);
                                                            },
                                                            legendItemClick(event) {
                                                                navigateToLogType(event, event.target.name);
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                            legend: {
                                                ...chartDefaults.pie.legend,
                                                align: 'center',
                                                itemStyle: {
                                                    fontSize: 12,
                                                    lineHeight: 16,
                                                },
                                                labelFormatter: chartFormatters.pie.legend.labelFormatterCentered('event'),
                                            },
                                            series: [
                                                {
                                                    minPointSize: 10,
                                                    data: [
                                                        {
                                                            name: 'Application',
                                                            y: widgetData.application,
                                                            color: colors[severity.colorRepresentation][300],
                                                        },
                                                        {
                                                            name: 'System',
                                                            color: colors[severity.colorRepresentation][400],
                                                            y: widgetData.system,
                                                        },
                                                    ],
                                                },
                                            ],
                                        }}
                                    />
                                </span>
                            ) : (
                                <LayoutCenter
                                    direction="column"
                                    className="tw-text-sm"
                                >
                                    <Icon
                                        type="checkmark"
                                        color="inherit"
                                        className="tw-text-8xl"
                                    ></Icon>
                                    <b>No {pluralize(severity.name.toLocaleLowerCase())} in this period</b>
                                </LayoutCenter>
                            ))}
                    </div>
                )}
            </LayoutColumn>
        </WidgetPaper>
    );
};

ReliabilityEventCountWidget.propTypes = {
    severity: PropTypes.shape({
        id: PropTypes.oneOf(validSeverities).isRequired,
        name: PropTypes.string.isRequired,
        colorRepresentation: PropTypes.string.isRequired,
    }).isRequired,
};

export { ReliabilityEventCountWidget };
export default ReliabilityEventCountWidget;
