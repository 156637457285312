import Popover, { PopoverProps } from '@mui/material/Popover';
import classNames from 'classnames';
import React from 'react';
import './context-dialog.scss';

const contextDialogClasses = {
    root: 'cs-context-dialog',
    paper: 'cs-context-dialog-content',
};

type CustomTransitionProps = {
    onEnter?: () => void;
    onEntering?: () => void;
    onEntered?: () => void;
    onExit?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
};

export type ContextDialogProps = PopoverProps & {
    /** If true backdrop click and escape key down will be disabled */
    confirm?: boolean;
    disableBackdropClick?: boolean;
    visibleBackdrop?: boolean;
    anchorOrigin?: PopoverProps['anchorOrigin'];
    paperProps?: PopoverProps['PaperProps'];
    className?: string;
    onEnter?: CustomTransitionProps['onEnter'];
    onEntering?: CustomTransitionProps['onEntering'];
    onEntered?: CustomTransitionProps['onEntered'];
    onClose?: PopoverProps['onClose'];
    onExit?: CustomTransitionProps['onExit'];
    onExiting?: CustomTransitionProps['onExiting'];
    onExited?: CustomTransitionProps['onExited'];
};

export const ContextDialog: React.FC<ContextDialogProps> = ({
    visibleBackdrop = false,
    confirm = false,
    open,
    onEnter,
    onEntering,
    onEntered,
    onClose,
    onExit,
    onExiting,
    onExited,
    paperProps,
    className,
    disableBackdropClick = false,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
    ...props
}) => {
    const handleOnClose: PopoverProps['onClose'] = (event, reason) => {
        if (disableBackdropClick || (confirm && reason === 'backdropClick')) {
            // ignore
        } else if (onClose) {
            onClose(event, reason);
        }
    };

    return (
        <Popover
            classes={contextDialogClasses}
            slotProps={{
                root: {
                    slotProps: {
                        backdrop: {
                            invisible: !visibleBackdrop && !confirm,
                            classes: {
                                root: classNames('cs-backdrop', {
                                    'tw-backdrop-blur-md': confirm || disableBackdropClick,
                                }),
                                invisible: 'cs-backdrop-invisible',
                            },
                        },
                    },
                },
                paper: paperProps,
            }}
            disableEscapeKeyDown={confirm}
            open={open}
            className={classNames(className, confirm ? 'cs-confirm-dialog' : null)}
            onClose={handleOnClose}
            TransitionProps={{
                onEnter: onEnter,
                onEntering,
                onEntered: onEntered,
                onExit: onExit,
                onExiting,
                onExited: onExited,
            }}
            anchorOrigin={anchorOrigin}
            {...props}
        />
    );
};

export default ContextDialog;
