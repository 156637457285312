import { useContext, useEffect } from 'react';
import { MessageContext } from './../../../index';

export const useMessageContext = (clearMessagesOnUnmount = true) => {
    const context = useContext(MessageContext);
    useEffect(
        () => () => {
            if (clearMessagesOnUnmount) {
                context.clearMessages();
            }
        },
        []
    );
    return context;
};

export default useMessageContext;
