export const LOCAL_STORAGE_ID = {
    // Device IDs
    SHOW_ACTIVITY_LOG: 'device.showActivityLog',
    SELECTED_ACTIVITY_LOG_TAB: 'device.selectedActivityLogTab',
    DEVICE_ACTIVITY_LOG_DEBUG_MODE_TIMESTAMP: 'device.activityLog.timestamp',
    DEVICE_ACTIVITY_LOG_DEBUG_MODE_DEVICE_ID: 'device.activityLog.deviceId',
    // Devices IDs
    SELECTED_DEVICES_LANDING_PAGE_TAB: 'devices.tabId',
    RECENTLY_USED_DEVICE_FILTERS: (orgId: number | string) => `devices.recentlyUsedFiltersByOrg.${orgId}`,
    // AdminOnDemand IDs
    SELECTED_ADMIN_ON_DEMAND_TAB: 'adminOnDemand.tab',
    // Updater IDs
    SELECTED_UPDATER_TAB: 'updater.tab',
    ACTIVE_PATCH_STATUS_TAB: 'updater.status.tab',
    ACTIVE_DRIVER_PATCH_STATUS_TAB: 'driver.status.tab',
    SELECTED_DRIVER_TAB: 'driver.tab',
    // Reliability IDs
    RELIABILITY_IN_DETAILED_MODE: 'reliability.inDetailedMode',
    RELIABILITY_DASHBOARD_PERIOD: 'reliability.dashboardPeriod',
    RELIABILITY_HOME_DASHBOARD_PERIOD: 'reliability.homeDashboardPeriod',
    // Settings Ids
    ACTIVE_WINDOWS_SETTINGS_PAGE_TAB: (orgId: number) => `windows.settings.tab.${orgId}`,
    // Core IDs
    SIDE_NAV_COLLAPSE: 'app.collapseSideNav',
    // User IDs
    LATEST_PAGE_VIEW: (userId: number) => `user.latestPageView.${userId}`,
    // Private IDs
    TENANT_VIEW_MODE: 'private.tenant.viewMode',
    LATEST_SELECTED_WINDOWS_DASHBOARD_TAB: 'windows.dashboards.tab',
    LATEST_SELECTED_WINDOWS_SECURITY_TAB: 'windows.security.tab',
    LATEST_SELECTED_WINDOWS_SECURITY_VULNERABILITIES_TAB: 'windows.security.vulnerability.tab',
    LATEST_SELECTED_WINDOWS_SECURITY_VULNERABILITIES_TAB_FOR_ENDPOINT: 'windows.endpoint.security.vulnerability.tab',
    WINDOWS_ENDPOINT_SECURITY_SUMMARY_WINDOWS_UPDATE_DETAILED_VIEWMODE: 'windows.endpoint.security.summary.windowsupdate.detailed.viewmode',
    // Security IDs
    EXPERIENCE_DASHBOARD_PERIOD: 'experience.dashboard.period',
    // Reporting IDs
    SELECTED_REPORTING_TAB: 'reporting.tab',
};
