import React from 'react';
import HighlightWords, { HighlighterProps as HighlightWordsProps } from 'react-highlight-words';

export type HighlighterProps = HighlightWordsProps & {
    highlightClassName?: string;
    searchWords?: string[];
    autoEscape?: boolean;
    textToHighlight?: string;
};

export const Highlighter: React.FC<HighlightWordsProps> = ({
    highlightClassName = 'cs-highlighted-text',
    searchWords = [],
    autoEscape = true,
    textToHighlight = '',
    ...rest
}) => {
    return (
        <HighlightWords
            highlightClassName={highlightClassName}
            searchWords={searchWords}
            autoEscape={autoEscape}
            textToHighlight={textToHighlight}
            {...rest}
        />
    );
};

export default Highlighter;
