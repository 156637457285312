export const PRODUCT = {
    ID: {
        SOFTWARE_PATCHING: 11, // CapaOne Third-Party Update
        RELIABILITY: 12, // CapaOne Event Logs,
        ADMIN_ON_DEMAND: 13, // Admin on Demand
        TRIAL_VERSION: 14, // Trial contract
        ANDROID_ENTERPRISE: 15, // Android Management
        DRIVERS: 16, // Drivers Management
        CUSTOM_APPS: 17, // Custom Package management
        EXPERIENCE: 18, // CapaOne Experience
        APPLE: 19, // Apple Management
        SECURITY: 20, // Security (CVEs and NIS2 reporting)
    },
    STATUS: {
        ACTIVATED: 'Activated',
    },
};
