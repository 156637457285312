import React from 'react';
import {
    DriverCompliance,
    DriverFailedByModel,
    DriverInProgressByModel,
    DriverUpdateCategory,
    DriverUpdatesByModel,
    DriverUpToDateByModel,
    WidgetsContainer,
} from '../../index';
import { DriverSupportedDevices } from './driver-supported-devices';

export const DriverDashboard = () => {
    return (
        // @ts-ignore - this is not typed
        <WidgetsContainer>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <DriverCompliance className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
                <DriverSupportedDevices className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
                <DriverUpdatesByModel className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
                <DriverUpToDateByModel className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
                <DriverInProgressByModel className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
                <DriverFailedByModel className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
                <DriverUpdateCategory className="tw-col-span-2 tw-h-96 lg:tw-col-span-1" />
            </div>
        </WidgetsContainer>
    );
};

DriverDashboard.propTypes = {};
