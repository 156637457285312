/* eslint-disable react-hooks/exhaustive-deps */
import { PRODUCT } from '@capasystems/constants';
import { Authentication, LayoutCenter, Loading, MainContent, useLocation, useParams } from '@capasystems/ui';
import { Url, api as baseApi, isDefined, isUndefined, noop } from '@capasystems/utils';
import { BASE_ORGANIZATION_ROUTE, LOCAL_STORAGE_ID, PRODUCT_NAME } from '@thirdparty/constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useNavigate as useCoreNavigate } from 'react-router-dom';
import { useApi, useSocket } from './../../../index';

const AuthContext = React.createContext();

const generatePermissions = ({ contracts = [], orgId = 0 }) => {
    const canViewExperimentalFeatures = orgId === 1;
    const isTrialVersion = contracts.some(({ productId, status }) => productId === PRODUCT.ID.TRIAL_VERSION);
    const reliabilityContract = contracts.find(({ productId, status }) => productId === PRODUCT.ID.RELIABILITY && status === PRODUCT.STATUS.ACTIVATED);
    const canViewReliability = isDefined(reliabilityContract);
    const canManageSoftwarePatching = contracts.some(
        ({ productId, status }) => productId === PRODUCT.ID.SOFTWARE_PATCHING && status === PRODUCT.STATUS.ACTIVATED
    );
    const canViewAdminOnDemand = contracts.some(({ productId, status }) => productId === PRODUCT.ID.ADMIN_ON_DEMAND && status === PRODUCT.STATUS.ACTIVATED);
    const canViewAndroidManagement = contracts.some(
        ({ productId, status }) => productId === PRODUCT.ID.ANDROID_ENTERPRISE && status === PRODUCT.STATUS.ACTIVATED
    );
    const canViewAppleManagement = contracts.some(({ productId, status }) => productId === PRODUCT.ID.APPLE && status === PRODUCT.STATUS.ACTIVATED);
    const canManageDrivers = contracts.some(({ productId, status }) => productId === PRODUCT.ID.DRIVERS && status === PRODUCT.STATUS.ACTIVATED);
    const canViewExperience = contracts.some(({ productId, status }) => productId === PRODUCT.ID.EXPERIENCE && status === PRODUCT.STATUS.ACTIVATED);
    const canManageCustomApps = contracts.some(({ productId, status }) => productId === PRODUCT.ID.CUSTOM_APPS && status === PRODUCT.STATUS.ACTIVATED);
    const canViewSecurity = contracts.some(({ productId, status }) => productId === PRODUCT.ID.SECURITY && status === PRODUCT.STATUS.ACTIVATED);
    const canViewWindowsManagement =
        canViewReliability ||
        canManageSoftwarePatching ||
        canViewAdminOnDemand ||
        canManageDrivers ||
        canManageCustomApps ||
        canViewExperience ||
        canViewSecurity;
    const permissions = {
        canViewReliability,
        canManageSoftwarePatching,
        canViewAdminOnDemand,
        canViewAndroidManagement,
        canViewAppleManagement,
        canViewWindowsManagement,
        canViewExperimentalFeatures,
        isTrialVersion,
        canManageDrivers,
        canManageCustomApps,
        canViewExperience,
        canViewSecurity,
        retentionDays: {
            reliability: canViewReliability ? reliabilityContract.retentionDays : 0,
        },
    };
    return permissions;
};

const initialState = {
    userId: null,
    firstName: null,
    lastName: null,
    email: '',
    authToken: null,
    orgId: null,
    parentId: null,
    orgName: null,
    contracts: [],
    organizations: [],
    permissions: generatePermissions({}),
};

const AUTH_TOKEN_IDENTIFIER = 'authtoken';

// eslint-disable-next-line react/prop-types
const AuthContextProvider = ({ children }) => {
    const api = useApi();
    const socket = useSocket();
    const navigate = useCoreNavigate();
    const location = useLocation();
    const [state, setState] = useState(initialState);
    const [switchingOrganizations, setSwitchingOrganizations] = useState(false);
    const { organizationId } = useParams();
    const timeoutRef = useRef(null);
    const [initialized, setInitialized] = useState(false);

    const logout = () => {
        baseApi.defaults.baseURL = 'api';
        socket.disconnect();
        api.logout()
            .then(() => {
                setState(initialState);
                navigate('/');
            })
            .catch(() => null)
            .finally(() => {
                // window.localStorage.clear();
            });
    };

    const switchOrganization = (selectedOrganizationId) => {
        if (selectedOrganizationId !== Number(organizationId)) {
            setSwitchingOrganizations(true);
            const currentRoute = BASE_ORGANIZATION_ROUTE.replace(':organizationId', organizationId);
            const nextRoute = BASE_ORGANIZATION_ROUTE.replace(':organizationId', selectedOrganizationId);
            navigate(location.pathname.replace(currentRoute, nextRoute) + location.search);
        }
    };

    const setProfile = (profile) => {
        let contractsCheckNeeded = false;
        if (organizationId) {
            if (clarity.hasStarted()) clarity.identify('USER_ID', { userProperty: profile.userId });

            const isValidOrg = profile.organizations.some((organization) => organization.id === Number(organizationId));
            if (isValidOrg) {
                baseApi.defaults.baseURL = `api/organizations/${organizationId}`;
                contractsCheckNeeded = profile.orgId !== Number(organizationId);
            } else {
                navigate(`${BASE_ORGANIZATION_ROUTE.replace(':organizationId', profile.orgId)}/access-denied/Invalid organization selected`);
                baseApi.defaults.baseURL = `api/organizations/${profile.orgId}`;
            }
        } else {
            navigate(BASE_ORGANIZATION_ROUTE.replace(':organizationId', `${profile.orgId}/windows`) + location.search, { replace: true });
            baseApi.defaults.baseURL = `api/organizations/${profile.orgId}`;
        }
        socket.connect();
        if (contractsCheckNeeded) {
            api.getOrganizationContracts()
                .then((response) => {
                    setState({
                        ...profile,
                        permissions: generatePermissions({
                            contracts: response.contracts,
                            orgId: profile.orgId,
                        }),
                    });
                })
                .catch(noop);
        } else {
            setState({
                ...profile,
                permissions: generatePermissions(profile),
            });
        }
    };

    useEffect(
        () => () => {
            clearTimeout(timeoutRef.current);
        },
        []
    );

    const [appIsReady, userIsValidated, providerValue] = useMemo(() => {
        const theUserIsValidated = state.userId !== null;
        const theAppIsReady = theUserIsValidated && switchingOrganizations === false && organizationId;
        const dataAttr = 'data-sidebar-control';
        if (theAppIsReady) {
            document.getElementById('root').removeAttribute(dataAttr);
        } else {
            document.getElementById('root').setAttribute(dataAttr, true); // Used to control sidebar width.
        }
        return [
            theAppIsReady,
            theUserIsValidated,
            {
                user: {
                    id: state.userId,
                    email: state.email,
                    firstName: state.firstName,
                    lastName: state.lastName,
                    authToken: state.authToken,
                    organization: {
                        id: state.orgId,
                        name: state.orgName,
                        parentOrganizationId: state.parentId,
                        isParentOrganization: state.orgId === state.parentId,
                    },
                },
                organizations: state.organizations,
                selectedOrganization: state.organizations.find((organization) => organization.id === Number(organizationId)),
                permissions: state.permissions,
                contracts: state.contracts,
                switchOrganization,
                logout,
                accessToken: state.accessToken,
            },
        ];
    }, [state, switchingOrganizations, organizationId]);

    useEffect(() => {
        if (userIsValidated) {
            setSwitchingOrganizations(true);
            baseApi.defaults.baseURL = `api/organizations/${organizationId}`;
            if (state.orgId !== Number(organizationId)) {
                api.getOrganizationContracts()
                    .then((response) => {
                        setState((currentState) => ({
                            ...currentState,
                            permissions: generatePermissions({
                                contracts: response.contracts,
                                orgId: currentState.orgId,
                            }),
                        }));
                    })
                    .catch(noop);
            } else {
                setState((currentState) => ({
                    ...currentState,
                    permissions: generatePermissions(currentState),
                }));
            }
            timeoutRef.current = setTimeout(() => setSwitchingOrganizations(false), 1000);
        }
    }, [organizationId]);

    if (appIsReady) {
        return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
    }

    if (userIsValidated) {
        return (
            <MainContent key="switching-organization">
                <LayoutCenter direction="column">
                    <Loading
                        color="inherit"
                        className="tw-text-neutral-500"
                        thickness={2}
                    />
                    <b className="tw-mt-8 tw-text-slate-600">Switching organization. Please wait.</b>
                </LayoutCenter>
            </MainContent>
        );
    }

    return (
        <UserAuthentication
            onSuccess={setProfile}
            initialized={initialized}
            setInitialized={setInitialized}
            navigate={navigate}
            organizationId={organizationId}
        />
    );
};

const UserAuthentication = ({ onSuccess, initialized, setInitialized, navigate, organizationId }) => {
    const api = useApi();
    const location = useLocation();
    const [tempProfile, setTempProfile] = useState(null);

    const profileChecker = (profile) => {
        if (isUndefined(organizationId)) {
            const latestPage = window.localStorage.getItem(LOCAL_STORAGE_ID.LATEST_PAGE_VIEW(profile.userId));
            const ROOT_PATHS = ['', '/'];
            if (ROOT_PATHS.includes(location.pathname) && latestPage) {
                navigate(latestPage, { replace: true });
            } else {
                navigate(BASE_ORGANIZATION_ROUTE.replace(':organizationId', `${profile.orgId}${location.pathname}`) + location.search, { replace: true });
            }
        }
        setTempProfile(profile); // Set state to wait for potential URL organizationId update.
    };

    const onSubmit = (user) => {
        return new Promise((resolve, reject) => {
            api.login(user)
                .then((profile) => {
                    resolve(() => {
                        profileChecker(profile);
                    });
                })
                .catch(({ data, status, details }) => {
                    if (status.code === 302) {
                        window.location.href = details.url.replace('http', 'https');
                        return;
                    }
                    reject(data.message ?? 'Incorrect username, password or login method not allowed');
                });
        });
    };

    useEffect(() => {
        if (tempProfile) {
            onSuccess(tempProfile);
        }
    }, [tempProfile]);

    useEffect(() => {
        if (!initialized) {
            const token = Url.getString(AUTH_TOKEN_IDENTIFIER, null);
            if (token) {
                api.login({ token })
                    .then(profileChecker)
                    .catch(() => {
                        setInitialized(true);
                    });
                Url.set(AUTH_TOKEN_IDENTIFIER, null);
            } else if (!initialized) {
                api.getProfile()
                    .then(profileChecker)
                    .catch(() => {
                        setInitialized(true);
                    });
            }
        }
    }, []);

    if (initialized) {
        return (
            <MainContent
                key="main-authentication"
                className="tw-bg-beams tw-bg-cover tw-bg-center tw-bg-no-repeat"
            >
                <Authentication
                    onSubmit={onSubmit}
                    productName={PRODUCT_NAME}
                    withMicrosoft
                    isCapaOne
                    onForgotPassword={api.forgotPassword}
                />
            </MainContent>
        );
    }
    return (
        <MainContent key="loading-screen">
            <LayoutCenter>
                <Loading />
            </LayoutCenter>
        </MainContent>
    );
};

export { AuthContext, AuthContextProvider };
