import MuiSkeleton, { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton';
import classnames from 'classnames';
import React from 'react';

const constuctClasses = ({ root, ...classes }: MuiSkeletonProps['classes'] = {}) => ({
    root: classnames('cs-skeleton', root),
    ...classes,
});

export type SkeletonProps = MuiSkeletonProps;

export const Skeleton: React.FC<SkeletonProps> = ({ classes = {}, ...rest }) => (
    <MuiSkeleton
        classes={constuctClasses(classes)}
        animation="pulse"
        variant="text"
        {...rest}
    />
);

export default Skeleton;
