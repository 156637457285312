import { LayoutRow, Page, Tab, Tabs, useParams } from '@capasystems/ui';
import { useEffect, useRef, useState } from 'react';
import { useNavigate as useCoreNavigate } from 'react-router-dom';
import { CollectedOnTimestamp, useNavigate } from '../../../../index';
import { AppleEndpointHardwareTab } from './AppleEndpointHardwareTab';
import { AppleEndpointSoftwareTab } from './AppleEndpointSoftwareTab';

const AppleEndpointInventoryTab = ({ endpoint }) => {
    const { deviceId, inventoryId } = useParams();
    const navigate = useNavigate();
    const queryBuilderContainerRef = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const coreNavigate = useCoreNavigate();
    const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

    const onTabChange = (e, selectedInventoryId) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(navigate.appendBaseURL(`apple/device/${deviceId}/inventory/${selectedInventoryId}`), '_blank');
        } else {
            coreNavigate(`../${selectedInventoryId}`);
        }
    };

    useEffect(() => {
        setInitialized(true); // queryBuilderContainerRef is set now.
    }, []);

    return (
        <Page title="Endpoints · Inventory">
            <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-4 tw-overflow-hidden">
                <div className="tw-pt-4">
                    <LayoutRow
                        verticalAlign="center"
                        align="space-between"
                    >
                        <div className="tw-flex tw-items-center tw-gap-6">
                            <Tabs
                                value={inventoryId}
                                onChange={onTabChange}
                                pills
                            >
                                <Tab
                                    label="Software"
                                    value="software"
                                    disableRipple
                                    className={tabClassName}
                                />
                                <Tab
                                    label="Hardware"
                                    value="hardware"
                                    disableRipple
                                    className={tabClassName}
                                />
                            </Tabs>
                        </div>
                        <div className="tw-flex">
                            <CollectedOnTimestamp timestamp={endpoint.lastCheckin} />
                            <div
                                ref={queryBuilderContainerRef}
                                className={inventoryId === 'software' && 'tw-ml-4 tw-w-72'}
                            />
                        </div>
                    </LayoutRow>
                </div>
                {initialized && (
                    <div>
                        {inventoryId === 'software' && (
                            <AppleEndpointSoftwareTab
                                appleData={endpoint}
                                queryBuilderContainerRef={queryBuilderContainerRef}
                            />
                        )}
                        {inventoryId === 'hardware' && <AppleEndpointHardwareTab appleData={endpoint} />}
                    </div>
                )}
            </div>
        </Page>
    );
};

export { AppleEndpointInventoryTab };
