import React, { useEffect, useState } from 'react';
import { Icon, IconButton, Menu, MenuItem } from '../../index';

import './capaone-application-switcher.scss';

import { capaOneContractInfo as contractInfo, capaOneIcons as icons, isBeta } from '@capasystems/constants';

export type TContract = {
    productId: number;
    productLine: string;
    endDate: string;
    status: string;
    trial: boolean | null;
    onlinePurchase: boolean | null;
    numberOfLicenses: number;
    retentionDays: number;
    storageLimit: number;
};

export type CapaoneApplicationSwitcherProps = {
    contracts: TContract[];
    exclude?: (number | string)[];
};

export const CapaoneApplicationSwitcher: React.FC<CapaoneApplicationSwitcherProps> = ({ contracts, exclude = [] }) => {
    const [menu, setMenu] = useState<{ isOpen: boolean; anchorEl?: HTMLButtonElement }>({ isOpen: false });

    const capaoneContractIDs = [13, 15, 19, 17, 16, 18, 12, 11];

    const hasCapone = contracts.some((contract) => new Date(contract.endDate) >= new Date() && capaoneContractIDs.includes(contract.productId));

    const [currentPage, setCurrentPage] = useState('dash');

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMenu({
            ...menu,
            isOpen: true,
            anchorEl: e.currentTarget,
        });
    };

    const closeMenu = () => {
        setMenu({ ...menu, isOpen: false });
    };

    useEffect(() => {
        const checkPage = () => {
            if (window.location.href.includes('dashbeta.capaone.com') || window.location.href.includes('dash.capaone.com')) {
                setCurrentPage('dash');
            } else if (window.location.href.includes('portalbeta.capaone.com') || window.location.href.includes('portal.capaone.com')) {
                setCurrentPage('portal');
            }
        };

        checkPage();

        const onPopState = () => {
            checkPage();
        };

        window.addEventListener('popstate', onPopState);

        return () => {
            window.removeEventListener('popstate', onPopState);
        };
    }, []);

    const handleNavigation = () => {
        let newUrl;
        if (currentPage === 'dash') {
            newUrl = isBeta ? 'https://portalbeta.capaone.com/' : 'https://portal.capaone.com/';
        } else if (currentPage === 'portal') {
            newUrl = isBeta ? 'https://dashbeta.capaone.com/' : 'https://dash.capaone.com/';
        }
        const win = window.open(newUrl, '_blank');
        win && win.focus();
    };

    const links: React.ReactElement[] = [];

    contracts
        .filter((contract) => contractInfo[contract.productLine] && !exclude.includes(contract.productId))
        // .sort((a, b) => a.productLine.localeCompare(b.productLine))
        .forEach((contract) => {
            const contractLinks = contractInfo[contract.productLine];
            if (contractLinks) {
                contractLinks.forEach((link) => {
                    if (link.betaLink && link.link) {
                        links.push(
                            <MenuItem
                                key={contract.productLine}
                                onClick={() => {
                                    const win = window.open(isBeta ? link.betaLink : link.link, '_blank');
                                    if (win) {
                                        win.focus();
                                    }
                                }}
                            >
                                <img
                                    className="application-link-image"
                                    src={icons[link.image]}
                                    alt=""
                                />
                                {link.name}
                            </MenuItem>
                        );
                    }
                });
            }
        });

    if (hasCapone) {
        links.push(
            <MenuItem
                key="CapaOne"
                onClick={handleNavigation}
            >
                <img
                    className="application-link-image"
                    src={icons.capaAdminOnDemand}
                    alt=""
                />
                {currentPage === 'portal' ? 'CapaOne' : 'CapaOne Portal'}
            </MenuItem>
        );
    } else if (!exclude.includes('portal')) {
        const portalName = 'CapaOne Portal';
        links.push(
            <MenuItem
                key={portalName}
                onClick={() => {
                    const win = window.open(`https://portal${isBeta ? 'beta' : ''}.capaone.com`, '_blank');
                    if (win) {
                        win.focus();
                    }
                }}
            >
                <img
                    className="application-link-image"
                    style={{ filter: 'unset' }}
                    src={icons.portal}
                    alt=""
                />
                {portalName}
            </MenuItem>
        );
    }

    links.sort((a, b) => (a.key ?? '').localeCompare(b.key ?? ''));

    return (
        <>
            <IconButton
                onClick={openMenu}
                variant="text"
                noMargin
            >
                <Icon
                    type="apps"
                    size="small"
                />
            </IconButton>
            <Menu
                open={menu.isOpen}
                anchorEl={menu.anchorEl}
                onClose={closeMenu}
                onClick={closeMenu}
                disableAutoFocusItem
            >
                {links.length > 0 ? links : <MenuItem key="noContracts">You have no contracts</MenuItem>}
            </Menu>
        </>
    );
};

export default CapaoneApplicationSwitcher;
