import classnames from 'classnames';
import React from 'react';

export type FormProps = Omit<HTMLFormElement, 'children'> & {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
};

const Form: React.FC<FormProps> = ({ onSubmit, children, className, disabled = false, ...rest }) => {
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        onSubmit(e);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={classnames('cs-form', className)}
            {...rest}
        >
            {children}
            <input
                className="tw-hidden"
                type="submit"
                disabled={disabled}
            />
        </form>
    );
};

export { Form };
export default Form;
