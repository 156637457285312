export const DeviceStateEnums: Record<string, string> = {
    ACTIVE: 'Device is active',
    DISABLED: 'Device is disabled',
    PROVISIONING: 'Device is being provisioned. No policy applied yet.', //  Newly enrolled devices are in this state until they have a policy applied.,
    DELETED: 'Device was deleted',
    LOST: 'Device is in Lost Mode',
};

export const ManagementModeEnums: Record<string, string> = {
    DEVICE_OWNER: 'Fully managed device', // Android Device Policy has full control over the device.
    PROFILE_OWNER: 'Managed work profile', // Android Device Policy has control over a managed profile on the device
};

export const OwnershipEnums: Record<string, string> = {
    COMPANY_OWNED: 'Company-owned device',
    PERSONALLY_OWNED: 'Personally-owned device',
};

export const UpdateStatusEnums: Record<string, string> = {
    UPDATE_STATUS_UNKNOWN: 'Unknown update status', // It is unknown whether there is a pending system update. This happens when, for example, the device API level is less than 26, or if the version of Android Device Policy is outdated.
    UP_TO_DATE: 'System is up-to-date', // "There is no pending system update available on the device.",
    UNKNOWN_UPDATE_AVAILABLE: 'System update available', // "There is a pending system update available, but its type is not known.",
    SECURITY_UPDATE_AVAILABLE: 'Security update available', // "There is a pending security update available.",
    OS_UPDATE_AVAILABLE: 'OS update available.',
};

export const ApplicationStateEnums: Record<string, string> = {
    REMOVED: 'Removed from device',
    INSTALLED: 'Installed on device',
};

export const ApplicationInstallTypeEnum: Record<string, string> = {
    PREINSTALLED: 'Preinstalled. Can be removed',
    FORCE_INSTALLED: "Force installed. Can't be removed",
    BLOCKED: 'Blocked from installation',
    AVAILABLE: 'Available in store',
    REQUIRED_FOR_SETUP:
        "Required for Setup. Automatically installed and can't be removed by the user. Will prevent setup from completion until installation is complete",
    KIOSK: 'Automatically installed in kiosk mode',
};

export const ApplicationSourceEnum: Record<string, string> = {
    APPLICATION_SOURCE_UNSPECIFIED: 'App was sideloaded from an unspecified source.',
    SYSTEM_APP_FACTORY_VERSION: "System app from the device's factory image.",
    SYSTEM_APP_UPDATED_VERSION: 'Updated system app.',
    INSTALLED_FROM_PLAY_STORE: 'Installed from the Google Play Store.',
};

export const UserFacingTypeEnum: Record<string, string> = {
    NOT_USER_FACING: 'App is not user-facing',
    USER_FACING: 'App is user-facing',
};

export const CameraAccessEnum: Record<string, string> = {
    CAMERA_ACCESS_USER_CHOICE: 'User choice',
    CAMERA_ACCESS_DISABLED: 'Disabled',
    CAMERA_ACCESS_ENFORCED: 'Enforce access enabled',
};

export const MicrophoneAccessEnum: Record<string, string> = {
    MICROPHONE_ACCESS_USER_CHOICE: 'User choice',
    MICROPHONE_ACCESS_DISABLED: 'Disabled',
    MICROPHONE_ACCESS_ENFORCED: 'Enforce access enabled',
};

export const PrintingPolicyEnum: Record<string, string> = {
    PRINTING_DISALLOWED: 'Printing is disallowed',
    PRINTING_ALLOWED: 'Printing is allowed',
};

export const PasswordQualityEnum: Record<string, string> = {
    PASSWORD_QUALITY_UNSPECIFIED: 'No requirements',
    BIOMETRIC_WEAK: 'Weak biometric',
    SOMETHING: 'Password required. No restrictions',
    NUMERIC: 'Numeric. Must contain numbers',
    NUMERIC_COMPLEX: 'Numeric Complex. Must contain non-repeating or ordered numbers',
    ALPHABETIC: 'Alphabetic. Must contain alphabetic or symbol characters',
    ALPHANUMERIC: 'Alphanumeric. Must contain numeric and alphabetic characters',
    COMPLEX: 'Complex. Must meet requirements',
};

export const SystemUpdateEnum: Record<string, string> = {
    AUTOMATIC: 'Install automatically when available',
    WINDOWED: 'Install automatically within daily maintenance window (Strongly recommended for kiosk devices)',
    POSTPONE: 'Postpone automatic install up to 30 days',
};

export const PolicyEnforcementRulesEnum: Record<string, string> = {
    BluetoothDisabled: 'Not working as expected', // Not working on purpose
    policyEnforcementRules: 'Policy Enforcement Rules',
    bluetoothDisabled: 'Bluetooth Disabled',
    bluetoothConfigDisabled: 'Bluetooth Config Disabled',
    bluetoothContactSharingDisabled: 'Bluetooth Contact Sharing Disabled',
    adjustVolumeDisabled: 'Adjust Volume Disabled',
    usbFileTransferDisabled: 'USB File Transfer Disabled',
    advancedSecurityOverrides: 'Advanced Security Overrides',
};
