import { coreMessage } from '@capasystems/constants';
import React, { useEffect, useState } from 'react';
import Button, { ButtonProps } from '../button/button';
import SavingChanges from '../saving-changes/saving-changes';
import './copy-text-button.scss';

type CopyTextButtonProps = ButtonProps & {
    text: string;
    hideConfirm?: boolean;
};

export const CopyTextButton: React.FC<CopyTextButtonProps> = ({ text, onClick, hideConfirm = false, ...otherProps }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            setLoading(false);
        }
    }, [loading]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        e.currentTarget.appendChild(el);
        el.select();
        document.execCommand('copy');
        e.currentTarget.removeChild(el);
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <React.Fragment>
            <Button
                onClick={handleClick}
                {...otherProps}
            />
            {!hideConfirm && (
                <SavingChanges
                    successMessage={coreMessage.copiedToClipboard}
                    loading={loading}
                    minLoadingTime={0}
                    minSuccessTime={1500}
                />
            )}
        </React.Fragment>
    );
};

export default CopyTextButton;
