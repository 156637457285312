import { LayoutColumn, Padding } from '@capasystems/ui';
import { isDefined, isUndefined } from '@capasystems/utils';
import classNames from 'classnames';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export const SingleCountWidget = ({ count, description, className, ...rest }) => {
    return (
        <WidgetPaper
            className={classNames(
                {
                    'tw-h-32': isDefined(description),
                    'tw-h-28': isUndefined(description),
                },
                className
            )}
            headerClassName="tw-pb-0"
            loadingPosition="left"
            description={description}
            {...rest}
        >
            <div className="tw-h-full tw-p-4">
                <LayoutColumn
                    fill
                    align="end"
                >
                    <div className="tw-w-full tw-text-2xl tw-font-semibold tw-leading-none">{count}</div>
                </LayoutColumn>
            </div>
        </WidgetPaper>
    );
};
