export const userPropertiesConstant = [
    {
        id: 'city',
        name: 'City',
    },
    {
        id: 'country',
        name: 'Country',
    },
    {
        id: 'preferredLanguage',
        name: 'Preferred Language',
    },
    {
        id: 'streetAddress',
        name: 'Street Address',
    },
    {
        id: 'businessPhone',
        name: 'Business Phone',
    },
    {
        id: 'mobilePhone',
        name: 'Mobile Phone',
    },
    {
        id: 'mailNickname',
        name: 'Mail Nickname',
    },
    {
        id: 'department',
        name: 'Department',
    },
    {
        id: 'jobTitle',
        name: 'Job Title',
    },
    {
        id: 'companyName',
        name: 'Company Name',
    },
    {
        id: 'officeLocation',
        name: 'Office Location',
    },
    {
        id: 'onPremisesSecurityIdentifier',
        name: 'On Premises Security Identifier',
    },
];
