import { defaultTheme } from '@capasystems/constants';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
// Import required fonts
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/300.css'; // Weight 300.
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/400.css'; // Weight 400.
import '@fontsource/open-sans/500-italic.css';
import '@fontsource/open-sans/500.css'; // Weight 500.
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/600.css'; // Weight 600.
import '@fontsource/open-sans/700-italic.css';
import '@fontsource/open-sans/700.css'; // Weight 700.
// End fonts import
import { BrowserRouter } from 'react-router-dom';
import '../styles/main.scss';
import '../styles/tailwind.css';
/* import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css'; */

/**
 * Wrap your entire application in the App component in order to apply the
 * CapaSystems theme.
 * */

type AppProps = {
    children: React.ReactNode;
};

export const App: React.FC<AppProps> = (props) => (
    <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline {...props} />
        </ThemeProvider>
    </BrowserRouter>
);

export default App;
