export const AndroidPersonalUsageConfiguration = {
    schema: {
        title: 'Personal Usage',
        type: 'object',
        category: 'Restrictions',
        schemaId: 'personalUsageConfiguration',
        properties: {
            personalUsagePolicies: {
                title: 'Personal Usage Policies',
                description: 'Policies managing personal usage on a company-owned device.',
                type: 'object',
                properties: {
                    screenCaptureDisabled: {
                        title: 'Screen Capture Disabled',
                        description: 'Whether screen capture is disabled.',
                        type: 'boolean',
                    },
                    accountTypesWithManagementDisabled: {
                        title: 'Account Types With Management Disabled',
                        description: "Account types that can't be managed by the user.",
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    maxDaysWithWorkOff: {
                        title: 'Max Days With Work Off',
                        description: 'Controls how long the work profile can stay off.',
                        help: 'Note: The duration must be at least 3 days.',
                        type: 'number',
                    },
                    personalPlayStoreMode: {
                        title: 'Personal Play Store Mode',
                        description: "Used together with 'Personal Applications' to control how apps in the personal profile are allowed or blocked.",
                        type: 'string',
                        options: [
                            {
                                id: 'BLOCKLIST',
                                name: "Blocklist. Block apps with 'Install Type' 'Blocked' in 'Personal Applications'",
                            },
                            {
                                id: 'ALLOWLIST',
                                name: "Allowlist. Allow apps with 'Install Type' 'Available' in 'Personal Applications'",
                            },
                        ],
                    },
                    personalApplications: {
                        title: 'Personal Applications',
                        description: 'Policy applied to applications in the personal profile.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                packageName: {
                                    title: 'Package Name',
                                    description: 'The package name of the application.',
                                    type: 'string',
                                    required: true,
                                },
                                installType: {
                                    title: 'Install Type',
                                    description: 'Types of installation behaviors a personal profile application can have.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'BLOCKED',
                                            name: 'Block app from installation on personal profile',
                                        },
                                        {
                                            id: 'AVAILABLE',
                                            name: 'Make app available for installation on personal profile',
                                        },
                                    ],
                                    required: true,
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
