import { highchartsTooltipHeadingAndText } from '@thirdparty/utils';
import React, { useContext, useEffect, useState } from 'react';
import { RELIABILITY, ReliabilityContext, SyncedHighcharts, WidgetPaper, useApi } from './../../index';

const ZONES = [
    {
        value: 0,
    },
    {
        value: RELIABILITY.SCORE.UNACCEPTABLE.RANGE.TO,
        color: RELIABILITY.SCORE.UNACCEPTABLE.COLOR[500],
    },
    {
        value: RELIABILITY.SCORE.POOR.RANGE.TO,
        color: RELIABILITY.SCORE.POOR.COLOR[500],
    },
    {
        value: RELIABILITY.SCORE.FAIR.RANGE.TO,
        color: RELIABILITY.SCORE.FAIR.COLOR[500],
    },
    {
        value: RELIABILITY.SCORE.GOOD.RANGE.TO,
        color: RELIABILITY.SCORE.GOOD.COLOR[500],
    },
];

const modifyReliabilityChartSeries = (chartData) => {
    const tempChartData = chartData;
    tempChartData.series.forEach((serie) => {
        if (serie.name === 'Average Reliability Score') {
            serie.lineWidth = 4;
            serie.zIndex = 1;
            serie.type = 'spline';
            serie.showInLegend = false;
            const yCheck = [];
            serie.data.forEach((data) => {
                if ('y' in data) {
                    yCheck.push(data);
                }
            });
            if (yCheck.length === 1) {
                serie.marker = { enabled: true, radius: 4 };
            } else {
                serie.marker = { fillColor: 'white', enabled: false };
            }
        } else {
            serie.type = 'areasplinerange';
            serie.lineWidth = 0;
            serie.linkedTo = ':previous';
            serie.opacity = 0.64;
            serie.zIndex = 0;
            if (serie.data.length === 1) {
                serie.marker = { enabled: true, radius: 4 };
                serie.zones = ZONES;
            } else {
                serie.marker = { enabled: false };
                serie.zones = ZONES;
            }
        }
    });
    return tempChartData;
};

const PLOTBANDS = [
    {
        from: RELIABILITY.SCORE.UNACCEPTABLE.RANGE.FROM,
        to: RELIABILITY.SCORE.UNACCEPTABLE.RANGE.TO,
        color: RELIABILITY.SCORE.UNACCEPTABLE.COLOR[50],
        label: {
            useHTML: true,
            text: RELIABILITY.SCORE.UNACCEPTABLE.NAME,
            style: {
                color: RELIABILITY.SCORE.UNACCEPTABLE.COLOR[900],
                fontWeight: 700,
            },
        },
    },
    {
        from: RELIABILITY.SCORE.POOR.RANGE.FROM,
        to: RELIABILITY.SCORE.POOR.RANGE.TO,
        color: RELIABILITY.SCORE.POOR.COLOR[50],
        label: {
            useHTML: true,
            text: RELIABILITY.SCORE.POOR.NAME,
            style: {
                color: RELIABILITY.SCORE.POOR.COLOR[900],
                fontWeight: 700,
            },
        },
    },
    {
        from: RELIABILITY.SCORE.FAIR.RANGE.FROM,
        to: RELIABILITY.SCORE.FAIR.RANGE.TO,
        color: RELIABILITY.SCORE.FAIR.COLOR[50],
        label: {
            useHTML: true,
            text: RELIABILITY.SCORE.FAIR.NAME,
            style: {
                color: RELIABILITY.SCORE.FAIR.COLOR[900],
                fontWeight: 700,
            },
        },
    },
    {
        from: RELIABILITY.SCORE.GOOD.RANGE.FROM,
        to: RELIABILITY.SCORE.GOOD.RANGE.TO,
        color: RELIABILITY.SCORE.GOOD.COLOR[50],
        label: {
            useHTML: true,
            text: RELIABILITY.SCORE.GOOD.NAME,
            style: {
                color: RELIABILITY.SCORE.GOOD.COLOR[900],
                fontWeight: 700,
            },
        },
    },
];

const ReliabilityAverageScoreWidget = () => {
    const { deviceId, lockedPeriod, timezone, sampleInterval, setCustomPeriod } = useContext(ReliabilityContext);
    const api = useApi();
    const [chartData, setChartData] = useState({
        series: [],
        categories: [],
    });
    const [widgetPaperProps, setWidgetPaperProps] = useState({
        loading: true,
    });

    const columnClick = (e) => {
        if (e !== null && e.period) {
            setCustomPeriod({
                start: e.period.start,
                end: e.period.end,
            });
        }
    };

    const onRequestError = (error) => {
        console.log(error);
        setWidgetPaperProps({
            errorMessage: 'Could not load Average Reliability Score.',
        });
    };

    const onRequestSuccess = (reliabilityData) => {
        setChartData(modifyReliabilityChartSeries(reliabilityData));
        setWidgetPaperProps({});
    };

    useEffect(() => {
        api.cancel();
        setWidgetPaperProps({
            loading: true,
        });
        if (deviceId) {
            api.getEventReliabilityChartDevice(deviceId, lockedPeriod, timezone, sampleInterval).then(onRequestSuccess).catch(onRequestError);
        } else {
            api.getEventOverviewReliabilityChart(lockedPeriod, timezone, sampleInterval).then(onRequestSuccess).catch(onRequestError);
        }
    }, [lockedPeriod, sampleInterval, api, deviceId, timezone]);

    return (
        <WidgetPaper
            className="tw-h-80 tw-min-h-full"
            title="Average Reliability Score"
            description="The reliability score is a Windows score (from 1 to 10) of an endpoint's overall stability. As the number and severity of errors increases, it lowers the reliability score"
            truncateHeaderElements
            {...widgetPaperProps}
        >
            <SyncedHighcharts
                options={{
                    chart: {
                        spacing: [0, 0, 16, 0],
                        zIndex: 1,
                        events: {
                            click: function (event) {
                                this.series[0].points.forEach((point) => {
                                    if (point.index === Math.round(event.xAxis[0].value)) {
                                        columnClick(point);
                                    }
                                });
                            },
                        },
                    },
                    title: {
                        text: null,
                    },
                    xAxis: {
                        crosshair: true,
                        lineWidth: 0,
                        categories: chartData.categories,
                        labels: {
                            style: {
                                fontSize: '10px',
                                fontWeight: 'bold',
                                textOverflow: 'none',
                            },
                            rotation: 0,
                            align: 'center',
                            formatter: function () {
                                if (chartData.categories.length > 15 && this.pos % 3 !== 0) {
                                    return '';
                                }
                                return this.axis.userOptions.categories[this.pos];
                            },
                        },
                    },
                    yAxis: {
                        title: {
                            text: null,
                        },
                        labels: {
                            enabled: false,
                        },
                        startOnTick: false,
                        endOnTick: false,
                        min: 1,
                        max: 10.1,
                        minorGridLineWidth: 0,
                        gridLineWidth: 0,
                        alternateGridColor: null,
                        plotBands: PLOTBANDS,
                    },
                    plotOptions: {
                        series: {
                            cursor: chartData.series[0]?.data?.length > 1 ? 'pointer' : 'default',
                            connectNulls: true,
                            events: {
                                click: function (event) {
                                    columnClick(event.point);
                                },
                            },
                            states: {
                                hover: {
                                    halo: null,
                                },
                            },
                        },
                    },
                    tooltip: {
                        padding: 16,
                        borderRadius: 8,
                        outside: true,
                        formatter: function () {
                            let tooltip = '';
                            tooltip += highchartsTooltipHeadingAndText('Time period', this.points[0].key, false);

                            if (deviceId) {
                                tooltip += highchartsTooltipHeadingAndText('Reliability Score', this.points[0].point.y, true);
                                tooltip += highchartsTooltipHeadingAndText('Range', this.points[1].point.low + ' - ' + this.points[1].point.high, true);
                            } else {
                                tooltip += highchartsTooltipHeadingAndText('Reliability Score &middot; Average', this.points[0].point.y, true);
                                tooltip += highchartsTooltipHeadingAndText(
                                    'Reliability Score &middot; Range',
                                    this.points[1].point.low + ' - ' + this.points[1].point.high,
                                    true
                                );
                                tooltip += highchartsTooltipHeadingAndText('Endpoint count', this.points[0].point.counts.deviceCount, true);
                            }
                            return tooltip;
                        },
                        shared: true,
                    },
                    series: chartData.series,
                }}
            />
        </WidgetPaper>
    );
};

ReliabilityAverageScoreWidget.propTypes = {};

export { ReliabilityAverageScoreWidget };
export default ReliabilityAverageScoreWidget;
