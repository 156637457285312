import { SORT_DIRECTION } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import {
    Button,
    Checkbox,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    EmptyState,
    Highlighter,
    VirtualizedTable,
} from '@capasystems/ui';
import { formatDate, noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { NOT_AVAILABLE } from '@thirdparty/constants';
import {
    CapaOneLink,
    DeviceAgentState,
    PatchStatusTabs,
    QueryBuilder,
    WidgetPaper,
    useApi,
    useDriverPatchStatusTabs,
    useOrgDriverPatchingSocket,
} from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const defaultQueryBuilderConfiguration = getDefaultQueryBuilderConfiguration({
    placeholder: 'Name or model',
});

export type DriverUpdateManagementProps = {
    portalContainer: any;
};

export const DriverUpdateManagement: React.FC<DriverUpdateManagementProps> = ({ portalContainer }) => {
    const api = useApi();
    const [drivers, setDrivers] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedDriver, setSelectedDriver] = useState<any[]>([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [sort, setSort] = useState({
        sortDirection: SORT_DIRECTION.ASC,
        sortBy: 'deviceName',
    });
    const queryBuilderRef = useRef<any>({});

    const { selectedTab, onTabChange, patchStatusFilter, searchString, setSearchString, showRequestedDate } = useDriverPatchStatusTabs();

    useEffect(() => {
        api.getDriverPatchQueue(true)
            .then((data) => {
                setDrivers(
                    data.map((driver: any) => {
                        if (driver.status === null) {
                            driver.status = {
                                code: 200,
                                message: 'Driver Pack Installed',
                            };
                        }
                        return driver;
                    })
                );
            })
            .catch(noop)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (selectedDriver.length) {
            setSelectedDriver([]);
        }
    }, [selectedTab]);

    const searchFilter = useCallback(
        (d) => {
            if (d.deviceName?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                return true;
            }

            if (d.model?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                return true;
            }

            return false;
        },
        [searchString]
    );

    const onSocketChange = useCallback<TSocketMethod>(
        ({ fullDocument }) => {
            const exists = drivers.some((d: any) => d.deviceId === fullDocument.deviceId);
            if (exists) {
                const newDrivers = drivers.map((d: any) => {
                    if (d.deviceId === fullDocument.deviceId) {
                        d.status = fullDocument.status;
                    }
                    return d;
                });
                setDrivers(newDrivers);
            } else {
                api.getDevice(fullDocument.deviceId).then((device) => {
                    fullDocument.deviceName = device.name;
                    fullDocument.online = device.online;
                    setDrivers([...drivers, fullDocument]);
                });
            }
        },
        [drivers]
    );

    useOrgDriverPatchingSocket(onSocketChange);

    const calculatedData = useMemo(() => {
        return drivers.filter(patchStatusFilter).filter(searchFilter);
    }, [drivers, patchStatusFilter, searchFilter]);

    const onSubmit = ([activeLeaf]: [any]) => {
        setSearchString(activeLeaf ? activeLeaf.value : '');
    };

    const selectedItemsCount = selectedDriver.length;

    const toggleAllDevicesSelect = () => {
        if (selectedItemsCount === calculatedData.length) {
            setSelectedDriver([]);
        } else {
            setSelectedDriver(calculatedData.map((d: any) => d.deviceId));
        }
    };

    const toggleDeviceSelect =
        ({ deviceId }: { deviceId: any }) =>
        () => {
            if (selectedDriver.some((d) => d === deviceId)) {
                setSelectedDriver(selectedDriver.filter((d) => d !== deviceId));
            } else {
                setSelectedDriver([...selectedDriver, deviceId]);
            }
        };

    const onConfirmDialogClose = () => setConfirmDialog(false);

    const openConfirmDialog = () => setConfirmDialog(true);

    const sorting = useCallback(
        (a: any, b: any) => {
            if (sort.sortBy === 'status') {
                if (sort.sortDirection === SORT_DIRECTION.DESC) {
                    return b.status.code - a.status.code;
                }
                return a.status.code - b.status.code;
            }

            if (sort.sortDirection === SORT_DIRECTION.DESC) {
                return b[sort.sortBy]?.localeCompare(a[sort.sortBy]);
            }
            return a[sort.sortBy]?.localeCompare(b[sort.sortBy]);
        },
        [sort]
    );

    return (
        <>
            <Portal container={portalContainer}>
                <QueryBuilder
                    // @ts-ignore - this is not typed yet
                    defaultConfiguration={defaultQueryBuilderConfiguration}
                    onInit={onSubmit}
                    onSubmit={onSubmit}
                    ref={queryBuilderRef}
                />
            </Portal>
            <div className="tw-grid-rows-auto tw-grid tw-h-full tw-grid-cols-auto-1fr tw-py-4">
                <PatchStatusTabs
                    selectedTab={selectedTab}
                    onTabChange={onTabChange}
                    items={drivers}
                    searchFilter={searchFilter}
                    includeNotInstalled={false}
                />
                <WidgetPaper
                    className="tw-ml-4"
                    // @ts-ignore - this is not typed yet
                    title="Endpoints"
                    headerless={selectedTab !== 0}
                    // @ts-ignore - this is not typed yet
                    description={selectedItemsCount === 0 ? 'No endpoints selected' : <>{pluralize('endpoint', selectedItemsCount, true)} selected</>}
                    // @ts-ignore - this is not typed yet
                    actions={
                        selectedItemsCount > 0 && (
                            <Button
                                onClick={openConfirmDialog}
                                variant="contained"
                                color="primary"
                            >
                                Install
                            </Button>
                        )
                    }
                >
                    {/* @ts-ignore - this is not typed yet */}
                    <VirtualizedTable
                        items={calculatedData.sort(sorting)}
                        totalRowCount={calculatedData.length}
                        showRowCount={calculatedData.length > 0}
                        disableHeader={calculatedData.length === 0}
                        entity="endpoint"
                        isLoading={loading}
                        // @ts-ignore - this is not typed yet
                        sortBy={sort.sortBy}
                        sortDirection={sort.sortDirection}
                        sort={(a: any) => setSort(a)}
                        noRowsRenderer={() => (
                            <EmptyState
                                searchTerm={searchString}
                                isSearching={searchString !== ''}
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                                entity="endpoint"
                                iconType="lightbulbOutlined"
                                description="No endpoints with the selected status"
                            />
                        )}
                    >
                        {selectedTab === 0 && (
                            <Column
                                // @ts-ignore - this is not typed yet
                                headerRenderer={() => (
                                    <Checkbox
                                        label=""
                                        checked={calculatedData.length === selectedDriver.length}
                                        onChange={toggleAllDevicesSelect}
                                        className="tw-mx-2"
                                    />
                                )}
                                dataKey="deviceId"
                                minWidth={32}
                                maxWidth={32}
                                type="checkbox"
                                cellRenderer={({ cellData, rowData }) => (
                                    <Checkbox
                                        checked={selectedDriver.some((sd) => sd === rowData.deviceId)}
                                        onChange={toggleDeviceSelect(rowData)}
                                    />
                                )}
                                disableSort
                            />
                        )}
                        <Column
                            key="deviceName"
                            dataKey="deviceName"
                            label="Name"
                            minWidth={160}
                            type="string"
                            cellRenderer={({ cellData, rowData }) => (
                                // @ts-ignore - this is not typed yet
                                <CapaOneLink to={'windows/device/' + rowData.deviceId + '/updates/software'}>
                                    <Ellipsis>
                                        <Highlighter
                                            searchWords={[searchString]}
                                            textToHighlight={cellData}
                                        />
                                    </Ellipsis>
                                </CapaOneLink>
                            )}
                        />
                        <Column
                            key="online"
                            dataKey="online"
                            label="Agent Status"
                            minWidth={96}
                            maxWidth={96}
                            type="badge"
                            // @ts-ignore - this is not typed yet
                            cellRenderer={({ rowData }) => <DeviceAgentState device={{ ...rowData, id: rowData.deviceId }} />}
                            disableSort
                        />
                        <Column
                            key="model"
                            dataKey="model"
                            label="Model"
                            minWidth={120}
                            type="string"
                            cellRenderer={({ cellData }) => (
                                <Ellipsis>
                                    <Highlighter
                                        searchWords={[searchString]}
                                        textToHighlight={cellData}
                                    />
                                </Ellipsis>
                            )}
                        />
                        {showRequestedDate && (
                            <Column
                                key="requestedInstallDate"
                                dataKey="requestedInstallDate"
                                label="Requested Date"
                                minWidth={120}
                                maxWidth={120}
                                // @ts-ignore - this is not typed yet
                                shouldRender={({ tableWidth }) => tableWidth >= 900}
                                defaultSortDirection={SORT_DIRECTION.DESC}
                                type="date"
                                cellRenderer={({ cellData }) => (cellData ? formatDate(cellData) : NOT_AVAILABLE)}
                            />
                        )}
                        <Column
                            key="status"
                            dataKey="status"
                            label="Status"
                            minWidth={120}
                            type="string"
                            cellRenderer={({ cellData }) => <Ellipsis>{cellData?.message ?? 'Up-to-date'}</Ellipsis>}
                        />
                        {selectedTab === 1 && (
                            <Column
                                key="workflowId"
                                dataKey="workflowId"
                                label="Workflow"
                                minWidth={240}
                                maxWidth={320}
                                // @ts-ignore - this is not typed yet
                                headerClassName="tw-text-xs"
                                className="tw-font-medium"
                                type="string"
                                cellRenderer={({ cellData, rowData }) =>
                                    cellData ? (
                                        //  @ts-ignore - this is not typed yet
                                        <CapaOneLink to={'windows/workflow/' + cellData}>
                                            <Ellipsis>{rowData?.workflowName || cellData}</Ellipsis>
                                        </CapaOneLink>
                                    ) : (
                                        <Ellipsis>Not part of a Workflow</Ellipsis>
                                    )
                                }
                            />
                        )}
                    </VirtualizedTable>
                </WidgetPaper>
            </div>
            <Dialog
                open={confirmDialog}
                onClose={onConfirmDialogClose}
                confirm
            >
                <DialogTitle>Update Drivers</DialogTitle>
                <DialogContent>Are you sure you want to update driver on {pluralize('endpoint', selectedDriver.length, true)}?</DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmDialogClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            api.installDriversOnEndpoints(selectedDriver)
                                .then(() => {
                                    setSelectedDriver([]);
                                    onConfirmDialogClose();
                                })
                                .catch(noop);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
