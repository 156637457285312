import { CameraAccessEnum, MicrophoneAccessEnum, PrintingPolicyEnum } from '../android-management-enums';

export const AndroidRestrictionsConfiguration = {
    schema: {
        title: 'Restrictions',
        type: 'object',
        category: 'Restrictions',
        schemaId: 'restrictionsConfiguration',
        properties: {
            usbFileTransferDisabled: {
                title: 'USB File Transfer Disabled',
                description: 'Whether transferring files over USB is disabled.',
                type: 'boolean',
                isDeprecated: true,
                help: 'See documentation.',
                setUrl: 'Policy.FIELDS.usb_file_transfer_disabled',
            },
            adjustVolumeDisabled: {
                title: 'Adjust Volume Disabled',
                description: 'Whether adjusting the master volume is disabled.',
                help: 'Note: Also mutes the device.',
                type: 'boolean',
            },
            screenCaptureDisabled: {
                title: 'Screen Capture Disabled',
                description: 'Whether screen capture is disabled.',
                type: 'boolean',
            },
            createWindowsDisabled: {
                title: 'Create Windows Disabled',
                description: 'Whether creating windows besides app windows is disabled.',
                type: 'boolean',
            },
            skipFirstUseHintsEnabled: {
                title: 'Skip First Use Hints Enabled',
                description:
                    'Flag to skip hints on the first use. Enterprise admin can enable the system recommendation for apps to skip their user tutorial and other introductory hints on first start-up.',
                type: 'boolean',
            },
            setWallpaperDisabled: {
                title: 'Set Wall Paper Disabled',
                description: 'Whether changing the wallpaper is disabled.',
                type: 'boolean',
            },
            mountPhysicalMediaDisabled: {
                title: 'Mount Physical Media Disabled',
                description: 'Whether the user mounting physical external media is disabled.',
                type: 'boolean',
            },
            funDisabled: {
                title: 'Fun Disabled',
                description: 'Whether the user is allowed to have fun. Controls whether the Easter egg game in Settings is disabled.',
                type: 'boolean',
            },
            keyguardDisabled: {
                title: 'Keyguard Disabled',
                description: 'Whether the keyguard is disabled.',
                type: 'boolean',
            },
            keyguardDisabledFeatures: {
                title: 'Keyguard Disabled Features',
                description: 'Disabled keyguard customizations, such as widgets.',
                type: 'array',
                items: {
                    type: 'string',
                    options: [
                        {
                            id: 'CAMERA',
                            name: 'Disable camera',
                        },
                        {
                            id: 'NOTIFICATIONS',
                            name: 'Disable notifications',
                        },
                        {
                            id: 'UNREDACTED_NOTIFICATIONS',
                            name: 'Disable unredacted notifications',
                        },
                        {
                            id: 'TRUST_AGENTS',
                            name: 'Ignore trust agent state',
                        },
                        {
                            id: 'DISABLE_FINGERPRINT',
                            name: 'Disable fingerprint sensor',
                        },
                        {
                            id: 'FACE',
                            name: 'Disable face authentication',
                        },
                        {
                            id: 'IRIS',
                            name: 'Disable iris authentication',
                        },
                        {
                            id: 'BIOMETRICS',
                            name: 'Disable all biometric authentication',
                        },
                        {
                            id: 'ALL_FEATURES',
                            name: 'Disable all current and future keyguard customizations',
                        },
                    ],
                },
                shouldRender: (siblings: any) => {
                    if (siblings.keyguardDisabled) {
                        delete siblings.keyguardDisabledFeatures;
                        return false;
                    }
                    return true;
                },
            },
            cameraAccess: {
                title: 'Camera Access',
                description: 'Controls the use of the camera and whether the user has access to the camera access toggle.',
                type: 'string',
                options: Object.entries(CameraAccessEnum).map(([id, name]) => ({ id, name })),
            },
            microphoneAccess: {
                title: 'Microphone Access',
                description: 'Controls the use of the microphone and whether the user has access to the microphone access toggle.',
                help: 'Note: This applies only on fully managed devices.',
                type: 'string',
                options: Object.entries(MicrophoneAccessEnum).map(([id, name]) => ({ id, name })),
            },
            printingPolicy: {
                title: 'Printing',
                description: 'Controls whether printing is allowed.',
                type: 'string',
                help: 'This is supported on devices running Android 9 and above.',
                setUrl: 'printingpolicy',
                options: Object.entries(PrintingPolicyEnum).map(([id, name]) => ({ id, name })),
            },
            factoryResetDisabled: {
                title: 'Factory Reset Disabled',
                description: 'Whether factory resetting from settings is disabled.',
                type: 'boolean',
            },
            addUserDisabled: {
                title: 'Add User Disabled',
                description: 'Whether adding new users and profiles is disabled.',
                type: 'boolean',
            },
            modifyAccountsDisabled: {
                title: 'Modify Accounts Disabled',
                description: 'Whether adding or removing accounts is disabled.',
                type: 'boolean',
            },
            removeUserDisabled: {
                title: 'Remove User Disabled',
                description: 'Whether removing other users is disabled.',
                type: 'boolean',
            },
            setUserIconDisabled: {
                title: 'Set User Icon Disabled',
                description: 'Whether changing the user icon is disabled.',
                type: 'boolean',
            },
            credentialsConfigDisabled: {
                title: 'Credentials Config Disabled',
                description: 'Whether configuring user credentials is disabled.',
                type: 'boolean',
            },
            installAppsDisabled: {
                title: 'Install Apps Disabled',
                description: 'Whether user installation of apps is disabled.',
                type: 'boolean',
            },
            uninstallAppsDisabled: {
                title: 'Uninstall Apps Disabled',
                description: 'Whether user uninstallation of applications is disabled.',
                type: 'boolean',
            },
        },
    },
};
