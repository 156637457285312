import { dispatchResizeEvent, isFunction } from '@capasystems/utils';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import './dashboard-grid.scss';

// ResponsiveGrid can use compactType='horizontal' to enable swapping of widgets.
const ResponsiveGrid = WidthProvider(Responsive);

export type DashboardGridProps = {
    /** Specify which DOM elements, containers or classes that should be non-draggable */
    draggableCancel?: string;
    onLayoutChange?: (layout: ReactGridLayout.Layout[], layouts: ReactGridLayout.Layouts) => void;
    /** Allow grid items to be dragged. */
    isDraggable?: boolean;
    /** Allow resize of grid items. */
    isResizable?: boolean;
    rowHeight?: number;
    margin?: number;
    breakPoints?: {
        lg: number;
        md: number;
        sm: number;
        xs: number;
    };
    cols?: {
        lg: number;
        md: number;
        sm: number;
        xs: number;
    };
    className?: string;
    children: ReactElement<DashboardGridProps>;
};

export const DashboardGrid: React.FC<DashboardGridProps> = ({
    draggableCancel = '.cs-non-draggable-area, input, textarea, button, select, .cs-dialog, .cs-context-dialog, .highcharts-container, .cs-tooltip-container',
    onLayoutChange,
    isDraggable = true,
    cols = { lg: 12, md: 10, sm: 2, xs: 1 },
    className,
    isResizable = true,
    children,
    rowHeight = 50,
    margin = 16,
    breakPoints = {
        lg: 1200,
        md: 996,
        sm: 768,
        xs: 0,
    },
    ...otherProps
}) => {
    /* istanbul ignore next */
    const onLayoutChangeHandler = (layout: ReactGridLayout.Layout[], layouts: ReactGridLayout.Layouts) => {
        if (isFunction(onLayoutChange)) {
            onLayoutChange(layout, layouts);
        }
        dispatchResizeEvent();
    };

    return (
        <ResponsiveGrid
            draggableCancel={draggableCancel}
            onLayoutChange={onLayoutChangeHandler}
            breakpoints={breakPoints}
            cols={cols}
            margin={[margin, margin]} // Margin between items [x, y] in px.
            containerPadding={[0, 0]} // Padding inside the container [x, y] in px
            isDraggable={isDraggable}
            isResizable={isResizable}
            rowHeight={rowHeight}
            className={classNames(className, 'cs-dashboard-grid')}
            useCSSTransforms={false}
            {...otherProps}
        >
            {children}
        </ResponsiveGrid>
    );
};

export default DashboardGrid;
