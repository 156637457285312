import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PieChart, eSize } from '../charts/pie-chart';
import { useApi } from '../hooks/useApi/useApi';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export type DriverUpdateCategoryProps = {
    className?: string;
};

export const DriverUpdateCategory: React.FC<DriverUpdateCategoryProps> = ({ className }) => {
    const api = useApi();

    const textRef = useRef<any>();

    const [chartData, setChartData] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getDevicesDriverCompliance()
            .then(setChartData)
            .catch(() => setChartData(undefined))
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const { patches } = chartData;

        const details: Record<string, number> = {};

        for (const patch of patches) {
            const { updates } = patch;
            [
                ...new Set(
                    updates.reduce((p: string[], c: any) => {
                        return [...p, c.category];
                    }, [])
                ),
            ].forEach((category: any) => {
                if (!details[category]) {
                    details[category] = 1;
                } else {
                    details[category]++;
                }
            });
        }

        return Object.entries(details)
            .map(([key, value]) => ({
                name: key,
                y: value,
            }))
            .sort((a, b) => b.y - a.y);
    }, [chartData]);

    textRef.current = calculatedData?.reduce((prev, d) => prev + 1, 0);

    return (
        <WidgetPaper
            // @ts-ignore - this is not typed yet
            title="Driver Update Categories"
            // @ts-ignore - this is not typed yet
            description="Percentage of out of date drivers by category"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
            />
        </WidgetPaper>
    );
};
