import classnames from 'classnames';
import React from 'react';

export type HeadingProps = {
    children: React.ReactNode;
    className?: string;
    subheading?: boolean;
    bold?: boolean;
    regular?: boolean;
    style?: React.CSSProperties;
};

export const Heading: React.FC<HeadingProps> = ({ children, className = '', subheading = false, bold = false, regular = false, style, ...rest }) => (
    <h2
        className={classnames({
            'tw-font-thin': !bold && !regular,
            'tw-font-semibold': bold,
            'tw-font-normal': regular,
            'tw-text-2xl': !subheading,
            'tw-text-lg': subheading,
            [className]: true,
        })}
        style={style}
        {...rest}
    >
        {children}
    </h2>
);

export default Heading;
