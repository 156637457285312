export const WindowsDriversConfigurationWorkingHours = {
    schema: {
        title: 'Working hours',
        type: 'object',
        schemaId: 'workingHours',
        properties: {
            workingHoursTimespan: {
                title: 'Working hours timespan',
                description: 'Specify timespan in hours where the driver installer wont ask or force driver installations.',
                type: 'range',
                required: true,
                defaultValue: [8, 16],
                numberOfSteps: 24,
                marks: [
                    { value: 0, label: '0' },
                    { value: 4, label: '4' },
                    { value: 8, label: '8' },
                    { value: 12, label: '12' },
                    { value: 16, label: '16' },
                    { value: 20, label: '20' },
                    { value: 24, label: '24' },
                ],
            },
        },
    },
};
