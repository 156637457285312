import { BUTTON } from '@capasystems/constants';
import { Button, Checkbox } from '@capasystems/ui';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../confirm-dialog/confirm-dialog';
import { DrawerStepRow } from '../../../drawer-step-row/drawer-step-row';
import { useAppleApi } from '../../../hooks/useApi/useApi';
import useAuthContext from '../../../hooks/useAuthContext/useAuthContext';
import { useCoreContext } from '../../../hooks/useCoreContext/useCoreContext';
import useMessageContext from '../../../hooks/useMessageContext/useMessageContext';
import { WithExperimentalFeatures } from '../../../thirdparty-components/thirdparty-components';
import { AppleCertificatePaper } from '../AppleCertificatePaper';

const AppleSettingsPushCertificate = () => {
    const { permissions } = useAuthContext();
    const { appleEnterpriseAccount } = useCoreContext();
    const [confirm, setConfirm] = useState(false);
    const [confirmedRemoveKubeStack, setConfirmedRemoveKubeStack] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { setAppleEnterpriseAccount } = useCoreContext();
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const api = useAppleApi();

    const { showErrorMessage } = useMessageContext();

    const handleCheckboxChange = (event) => {
        setCheckboxChecked(event.target.checked);
    };

    const hasPushCertificateExpired = appleEnterpriseAccount?.pushCertificateExpireDate ? appleEnterpriseAccount.pushCertificateExpireDate < new Date() : false;

    const theme = useTheme();

    return (
        <AppleCertificatePaper
            title="Push Certificate"
            description={'Enables secure and encrypted communication'}
            expireDate={appleEnterpriseAccount?.pushCertificateExpireDate}
            onUpdate={() => setConfirm(true)}
            secondaryAction={
                <WithExperimentalFeatures disableHoverListener={confirmedRemoveKubeStack}>
                    <div>
                        <Button
                            variant={BUTTON.RAISED}
                            color={BUTTON.DANGER}
                            noMargin
                            onClick={() => {
                                setConfirmedRemoveKubeStack(true);
                            }}
                        >
                            Remove Kube Stack
                        </Button>
                        <ConfirmDialog
                            open={confirmedRemoveKubeStack}
                            onCancel={() => setConfirmedRemoveKubeStack(false)}
                            onConfirm={() => {
                                setConfirmedRemoveKubeStack(false);
                                api.deleteMdmStack()
                                    .then(() => {
                                        setAppleEnterpriseAccount(null);
                                    })
                                    .catch((error) => {
                                        showErrorMessage(error.message, { autoHideDuration: 600 });
                                    });
                            }}
                        />
                    </div>
                </WithExperimentalFeatures>
            }
        >
            An <span className="tw-font-semibold">Apple Push Certificate</span> is a digital credential issued by Apple to enable{' '}
            <span className="tw-font-semibold">secure and encrypted</span> communication between your server and Push Notification service.
            <br />
            <br /> It serves as a means of authentication, ensuring that only authorized servers can send push notifications to devices registered to receive
            them.
            <br />
            <br /> You need this certificate to enable push notifications for your iOS and macOS, allowing you to deliver timely updates, alerts, and messages
            to your users. Upload your Apple Push Certificate here to configure push notifications for your app.
            <ConfirmDialog
                disabled={!checkboxChecked}
                open={confirm}
                onCancel={() => {
                    setConfirm(false);
                    if (checkboxChecked) {
                        setCheckboxChecked(false);
                    }
                }}
                onConfirm={() => {
                    api.updatePushRequest()
                        .then(() => {
                            setConfirmLoading(true);
                            setTimeout(() => {
                                setAppleEnterpriseAccount((prev) => {
                                    prev.hasCertificate = false;
                                    prev.isReady = false;
                                    return { ...prev };
                                });
                            }, 1000);
                        })
                        .catch((error) => {
                            showErrorMessage(error.message, { autoHideDuration: 600 });
                            setConfirmLoading(false);
                        });
                }}
                isProcessing={confirmLoading}
            >
                <p>Are you sure you want to update the push certificate?</p>
                <p>Read these steps carefully to create your push certificate</p>
                <DrawerStepRow badgeContent={1}>
                    <p>
                        Make sure that you can log in to{' '}
                        <a
                            href="https://identity.apple.com/pushcert/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: theme.palette.primary.main }}
                        >
                            <b>Apple Push Certificates</b>
                        </a>{' '}
                        with the account you used to create the previous push certificate:
                    </p>
                    <p className="tw-mt-2">
                        <b>{appleEnterpriseAccount?.appleAccount}</b>
                        <Checkbox
                            checked={checkboxChecked}
                            onChange={handleCheckboxChange}
                        />
                    </p>
                </DrawerStepRow>
                <DrawerStepRow badgeContent={2}>
                    Click <b>Confirm</b>. This will create a new push request certificate. Then just follow the steps on screen.
                </DrawerStepRow>
            </ConfirmDialog>
        </AppleCertificatePaper>
    );
};

export { AppleSettingsPushCertificate };
