export const OnDemandRulesElement = {
    title: 'OnDemand Rules',
    description: 'A list of rules that determine when and how to use an OnDemand VPN.',
    type: 'array',
    items: {
        type: 'object',
        properties: {
            Action: {
                title: 'Action',
                description: 'The action to take if this dictionary matches the current network.',
                help: 'Only the Disconnect action is available on watchOS 10 and later.',
                type: 'string',
                options: [
                    {
                        id: 'Allow',
                        name: 'Allow: Allow VPN On Demand to connect if triggered.',
                    },
                    {
                        id: 'Connect',
                        name: 'Connect: Unconditionally initiate a VPN connection on the next network attempt.',
                    },
                    {
                        id: 'Disconnect',
                        name: 'Disconnect: Tear down the VPN connection and don’t reconnect on demand as long as this dictionary matches.',
                    },
                    {
                        id: 'EvaluateConnection',
                        name: 'EvaluateConnection: Evaluate the ActionParameters array for each connection attempt.',
                    },
                    {
                        id: 'Ignore',
                        name: 'Ignore: Leave any existing VPN connection up, but don’t reconnect on demand as long as this dictionary matches.',
                    },
                ],
            },
            ActionParameters: {
                title: ' Action Parameters',
                description:
                    'A dictionary that provides rules similar to the OnDemandRules dictionary, but evaluated on each connection instead of when the network changes. This value is only for use with dictionaries in which the Action value is EvaluateConnection. The system evaluates these dictionaries in order and the first dictionary that matches determines the behavior.',
                type: 'array',
                shouldRender: (siblings: any) => {
                    if (siblings.Action === 'EvaluateConnection') {
                        return true;
                    } else {
                        delete siblings.ActionParameters;
                        return false;
                    }
                },
                items: {
                    type: 'object',
                    properties: {
                        Domains: {
                            title: 'Domains',
                            description: 'The domains to apply this evaluation.',
                            type: 'array',
                            required: true,
                            items: {
                                type: 'string',
                            },
                        },
                        DomainAction: {
                            title: 'Domain Action',
                            description: 'Defines the VPN behavior for the specified domains.',
                            type: 'string',
                            options: [
                                {
                                    id: 'ConnectIfNeeded',
                                    name: 'Connect IF Needed',
                                },
                                {
                                    id: 'NeverConnect',
                                    name: 'Never Connect',
                                },
                            ],
                        },
                    },
                },
                DNSDomainMatch: {
                    title: 'DNS Domain Match',
                    description:
                        'A list of domain names. This rule matches if any of the domain names in the specified list matches any domain in the device’s search domains list.',
                    help: 'The system supports a wildcard (*) prefix. For example, *.example.com matches against either mydomain.example.com or yourdomain.example.com.',
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                DNSServerAddressMatch: {
                    title: 'DNS Server Address Match',
                    description: 'A list of IP addresses. This rule matches if any of the network’s specified DNS servers match any entry in the array.',
                    help: 'The system supports matching with a single wildcard. For example, 17.* matches any DNS server in the 17.0.0.0/8 subnet.',
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                InterfaceTypeMatch: {
                    title: 'InterfaceTypeMatch',
                    description:
                        'An interface type. If specified, this rule matches only if the primary network interface hardware matches the specified type.',
                    type: 'string',
                    options: [
                        {
                            id: 'Ethernet',
                            name: 'Ethernet',
                        },
                        {
                            id: 'WiFi',
                            name: 'WiFi',
                        },
                        {
                            id: 'Cellular',
                            name: 'Cellular',
                        },
                    ],
                },
                SSIDMatch: {
                    title: 'SSID Match',
                    description:
                        'A list of SSIDs to match against the current network. If the network isn’t a Wi-Fi network or if the SSID doesn’t appear in this array, the match fails.',
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                URLStringProbe: {
                    title: 'URL String Probe',
                    description:
                        'A URL to probe. This rule matches when this URL is successfully fetched (returns a 200 HTTP status code) without redirection.',
                    type: 'string',
                },
            },
        },
    },
};
