import MUIBadge from '@mui/material/Badge';
import classnames from 'classnames';
import React from 'react';
import './badge.scss';

export type TBadgeClasses = {
    root: string;
    badge: string;
    dot: string;
    invisible: string;
    colorPrimary: string;
    colorSecondary: string;
    colorError: string;
    [key: string]: string;
};

const badgeClasses = ({ root = '', badge = '', dot = '', ...rest }: TBadgeClasses | any, small: boolean) => ({
    root: classnames('cs-badge-container', root),
    badge: classnames(
        {
            'cs-badge': true,
            'cs-badge-small': small,
        },
        badge
    ),
    dot: classnames('cs-badge-dot', dot),
    invisible: 'cs-badge-invisible',
    colorPrimary: 'cs-badge-primary',
    colorSecondary: 'cs-badge-secondary',
    colorError: 'cs-badge-error',
    ...rest,
});

export type BadgeProps = {
    badgeContent: React.ReactNode;
    /** One of primary, secondary, error. Use default and a className for custom color. */
    color?: 'primary' | 'secondary' | 'error' | 'default';
    variant?: 'standard' | 'dot';
    /** Wrapped shape the badge should overlap. */
    overlap?: 'circular' | 'rectangular';
    invisible?: boolean;
    showZero?: boolean;
    small?: boolean; // standard variant only
    /** Max count to show. */
    max?: number;
    /** Badge position */
    anchorOrigin?: {
        vertical: 'top' | 'bottom';
        horizontal: 'left' | 'right';
    };
    classes?: TBadgeClasses | any;
    children?: React.ReactNode;
};

/** Badge component */
export const Badge: React.FC<BadgeProps> = ({
    classes = {},
    small = false,
    showZero = false,
    color = 'primary',
    variant = 'standard',
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
    overlap = 'rectangular',
    max = 99,
    ...props
}) => {
    return (
        <MUIBadge
            classes={badgeClasses(classes, small)}
            showZero={showZero}
            color={color}
            variant={variant}
            anchorOrigin={anchorOrigin}
            overlap={overlap}
            max={max}
            {...props}
        />
    );
};

export const RippleBadge: React.FC<BadgeProps> = ({ classes, variant = 'dot', ...rest }) => {
    const { badge, dot, ...remainingClasses } = classes;
    return (
        <Badge
            classes={{
                badge: classnames(
                    'tw-ring-2 tw-ring-current tw-shadow-white after:tw-animate-ripple after:tw-border after:tw-border-current after:tw-w-full after:tw-h-full after:tw-absolute after:tw-top-0 after:tw-left-0 after:tw-rounded-full',
                    badge
                ),
                dot: classnames('tw-rounded-full', dot),
                ...remainingClasses,
            }}
            variant={variant}
            {...rest}
        />
    );
};

export default Badge;
