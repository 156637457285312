// eslint-disable-next-line import/prefer-default-export
export const SCROLLED_TO_BOTTOM_THRESHOLD = 500;

export const KEY_CODE = {
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ENTER: 13,
};

export const SORT_DIRECTION = {
    /**
     * Sort items in ascending order.
     * This means arranging from the lowest value to the highest (e.g. a-z, 0-9).
     * Dates will be sorted from oldest to newest.
     */
    ASC: 'ASC',

    /**
     * Sort items in descending order.
     * This means arranging from the highest value to the lowest (e.g. z-a, 9-0).
     * Dates will be sorted from newest to oldest.
     */
    DESC: 'DESC',
};
