export const DEVICE_EVENT = 'deviceChange';
export const DEVICE_LOG_EVENT = 'deviceLogChange';
export const DEVICE_PATCHING_EVENT = 'devicePatchingChange';
export const ORG_PATCHING_EVENT = 'orgPatchingChange';
export const ORG_DRIVER_PATCHING_EVENT = 'orgDriverPatchingChange';
export const APPLICATION_PATCHING_EVENT = 'applicationPatchingChange';
export const APPLICATION_PATCH_UPDATE_AVAILABLE_EVENT = 'APPLICATION_PATCH_UPDATE_AVAILABLE_EVENT';
export const DEVICES_EVENT = 'devicesChange';
export const JOIN_ROOM = 'join';
export const LEAVE_ROOM = 'leave';
export const OPERATION_TYPE_INSERT = 'insert';
export const OPERATION_TYPE_UPDATE = 'update';
export const OPERATION_TYPE_REPLACE = 'replace';
export const OPERATION_TYPE_DELETE = 'delete';
export const SOFTWARE_INVENTORY_EVENT = 'softwareChange';
export const HARDWARE_INVENTORY_EVENT = 'hardwareChange';
export const SECURITY_INVENTORY_EVENT = 'securityInventoryChange';
export const DRIVER_INVENTORY_EVENT = 'driverChange';
export const WORKFLOW_EVENT = 'workflowChange';
export const WORKFLOW_EVENT_WITH_ID = 'workflowWithIdChange';
export const CONFIGURATION_EVENT = 'configurationChange';
export const CONFIGURATION_EVENT_WITH_ID = 'configurationWithIdChange';
export const PACKAGE_EVENT = 'packageChange';
export const ANDROID_ENDPOINTS_EVENT = 'androidEndpointsChange';
export const ANDROID_ENDPOINT_EVENT = 'androidEndpointChange';
export const ANDROID_CONFIGURATIONS_EVENT = 'androidConfigurationsChange';
export const ANDROID_CONFIGURATION_EVENT = 'androidConfigurationChange';
export const ANDROID_APPLICATIONS_EVENT = 'androidApplicationsChange';
export const ANDROID_APPLICATION_EVENT = 'androidApplicationChange';
export const MANAGEMENT_INTEGRATIONS_EVENT = 'managementIntegrationsChange';
export const DEVICE_DRIVER_PATCHING_EVENT = 'deviceDriverPatchingChange';
export const GROUPS_EVENT = 'groupsChange';
export const GROUP_EVENT = 'groupChange';
export const APPLICATION_EVENT = 'applicationChange';
export const APPLICATION_EVENT_WITH_ID = 'applicationWithIdChange';
export const APPLE_CONFIGURATIONS_EVENT = 'appleConfigurationsChange';
export const APPLE_CONFIGURATION_EVENT = 'appleConfigurationChange';
export const APPLE_APPLICATIONS_EVENT = 'appleApplicationsChange';
export const APPLE_VPP_LICENSES_EVENT = 'appleVppLicensesChange';
export const APPLE_APPLICATION_EVENT = 'appleApplicationChange';
export const APPLE_ENDPOINTS_EVENT = 'appleEndpointsChange';
export const APPLE_DEP_ENDPOINTS_EVENT = 'appleDEPEndpointsChange';
export const APPLE_ENDPOINT_EVENT = 'appleEndpointChange';
export const APPLE_CLUSTER_EVENT = 'appleClusterChange';
export const DEVICE_CUSTOM_APP_PATCH_QUEUE_EVENT = 'deviceCustomAppPatchQueueChange';
export const APPLE_ENROLLMENT_CONFIGURATIONS_EVENT = 'appleEnrollmentConfigurationsChange';
export const APPLE_COMMANDS_EVENT = 'appleCommandsChange';
export const REPORT_EXPORT_HISTORY_EVENT = 'reportExportHistoryChange';
export const ORG_CONFIG_EVENT = 'orgConfigChange';
export const APPLICATION_CUSTOM_APP_PATCH_QUEUE_EVENT = 'applicationCustomAppPatchQueueChange';

export type RoomParams = {
    orgId?: string;
    deviceId?: string;
    applicationId?: string;
    workflowId?: string;
    configurationId?: string;
    endpointId?: string;
    id?: string;
};

export type RoomIdFunction = (params: RoomParams) => string | null;

const roomIds: { [key: string]: RoomIdFunction } = {
    [DEVICES_EVENT]: ({ orgId }) => (orgId ? `${DEVICES_EVENT}_${orgId}` : null),
    [DEVICE_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${DEVICE_EVENT}_${deviceId}_${orgId}` : null),
    [SOFTWARE_INVENTORY_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${SOFTWARE_INVENTORY_EVENT}_${deviceId}_${orgId}` : null),
    [HARDWARE_INVENTORY_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${HARDWARE_INVENTORY_EVENT}_${deviceId}_${orgId}` : null),
    [DRIVER_INVENTORY_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${DRIVER_INVENTORY_EVENT}_${deviceId}_${orgId}` : null),
    [SECURITY_INVENTORY_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${SECURITY_INVENTORY_EVENT}_${deviceId}_${orgId}` : null),
    [DEVICE_LOG_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${DEVICE_LOG_EVENT}_${deviceId}_${orgId}` : null),
    [DEVICE_PATCHING_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${DEVICE_PATCHING_EVENT}_${deviceId}_${orgId}` : null),
    [APPLICATION_PATCHING_EVENT]: ({ applicationId, orgId }) => (applicationId && orgId ? `${APPLICATION_PATCHING_EVENT}_${applicationId}_${orgId}` : null),
    [APPLICATION_PATCH_UPDATE_AVAILABLE_EVENT]: ({ applicationId, orgId }) =>
        applicationId && orgId ? `${APPLICATION_PATCH_UPDATE_AVAILABLE_EVENT}_${applicationId}_${orgId}` : null,
    [ORG_PATCHING_EVENT]: ({ orgId }) => (orgId ? `${ORG_PATCHING_EVENT}_${orgId}` : null),
    [ORG_DRIVER_PATCHING_EVENT]: ({ orgId }) => (orgId ? `${ORG_DRIVER_PATCHING_EVENT}_${orgId}` : null),
    [WORKFLOW_EVENT]: ({ orgId }) => (orgId ? `${WORKFLOW_EVENT}_${orgId}` : null),
    [WORKFLOW_EVENT_WITH_ID]: ({ orgId, workflowId }) => (orgId ? `${WORKFLOW_EVENT}_${orgId}_${workflowId}` : null),
    [CONFIGURATION_EVENT]: ({ orgId }) => (orgId ? `${CONFIGURATION_EVENT}_${orgId}` : null),
    [CONFIGURATION_EVENT_WITH_ID]: ({ orgId, configurationId }) => (orgId ? `${CONFIGURATION_EVENT}_${orgId}_${configurationId}` : null),
    [PACKAGE_EVENT]: ({ orgId }) => (orgId ? `${PACKAGE_EVENT}_${orgId}` : null),
    [ANDROID_ENDPOINTS_EVENT]: ({ orgId }) => (orgId ? `${ANDROID_ENDPOINTS_EVENT}_${orgId}` : null),
    [ANDROID_ENDPOINT_EVENT]: ({ endpointId, orgId }) => (endpointId ? `${ANDROID_ENDPOINT_EVENT}_${endpointId}_${orgId}` : null),
    [ANDROID_CONFIGURATIONS_EVENT]: ({ orgId }) => (orgId ? `${ANDROID_CONFIGURATIONS_EVENT}_${orgId}` : null),
    [ANDROID_CONFIGURATION_EVENT]: ({ id, orgId }) => (id ? `${ANDROID_CONFIGURATION_EVENT}_${id}_${orgId}` : null),
    [ANDROID_APPLICATIONS_EVENT]: ({ orgId }) => (orgId ? `${ANDROID_APPLICATIONS_EVENT}_${orgId}` : null),
    [ANDROID_APPLICATION_EVENT]: ({ id, orgId }) => (id ? `${ANDROID_APPLICATION_EVENT}_${id}_${orgId}` : null),
    [MANAGEMENT_INTEGRATIONS_EVENT]: ({ orgId }) => (orgId ? `${MANAGEMENT_INTEGRATIONS_EVENT}_${orgId}` : null),
    [DEVICE_DRIVER_PATCHING_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${DEVICE_DRIVER_PATCHING_EVENT}_${deviceId}_${orgId}` : null),
    [GROUPS_EVENT]: ({ orgId }) => (orgId ? `${GROUPS_EVENT}_${orgId}` : null),
    [GROUP_EVENT]: ({ id, orgId }) => (id ? `${GROUP_EVENT}_${id}_${orgId}` : null),
    [APPLICATION_EVENT]: ({ orgId }) => (orgId ? `${APPLICATION_EVENT}_${orgId}` : null),
    [APPLICATION_EVENT_WITH_ID]: ({ orgId, applicationId }) => (orgId ? `${APPLICATION_EVENT}_${orgId}_${applicationId}` : null),
    [APPLE_CONFIGURATIONS_EVENT]: ({ orgId }) => (orgId ? `${APPLE_CONFIGURATIONS_EVENT}_${orgId}` : null),
    [APPLE_CONFIGURATION_EVENT]: ({ id, orgId }) => (id ? `${APPLE_CONFIGURATION_EVENT}_${id}_${orgId}` : null),
    [APPLE_APPLICATIONS_EVENT]: ({ orgId }) => (orgId ? `${APPLE_APPLICATIONS_EVENT}_${orgId}` : null),
    [APPLE_APPLICATION_EVENT]: ({ id, orgId }) => (id ? `${APPLE_APPLICATION_EVENT}_${id}_${orgId}` : null),
    [APPLE_VPP_LICENSES_EVENT]: ({ orgId }) => (orgId ? `${APPLE_VPP_LICENSES_EVENT}_${orgId}` : null),
    [APPLE_ENDPOINTS_EVENT]: ({ orgId }) => (orgId ? `${APPLE_ENDPOINTS_EVENT}_${orgId}` : null),
    [APPLE_DEP_ENDPOINTS_EVENT]: ({ orgId }) => (orgId ? `${APPLE_DEP_ENDPOINTS_EVENT}_${orgId}` : null),
    [APPLE_ENDPOINT_EVENT]: ({ endpointId, orgId }) => (endpointId ? `${APPLE_ENDPOINT_EVENT}_${endpointId}_${orgId}` : null),
    [APPLE_CLUSTER_EVENT]: ({ orgId }) => `${APPLE_CLUSTER_EVENT}_${orgId}`,
    [DEVICE_CUSTOM_APP_PATCH_QUEUE_EVENT]: ({ deviceId, orgId }) => (deviceId ? `${DEVICE_CUSTOM_APP_PATCH_QUEUE_EVENT}_${deviceId}_${orgId}` : null),
    [APPLE_ENROLLMENT_CONFIGURATIONS_EVENT]: ({ orgId }) => (orgId ? `${APPLE_ENROLLMENT_CONFIGURATIONS_EVENT}_${orgId}` : null),
    [APPLE_COMMANDS_EVENT]: ({ orgId, deviceId }) => (deviceId ? `${APPLE_COMMANDS_EVENT}_${deviceId}_${orgId}` : null),
    [REPORT_EXPORT_HISTORY_EVENT]: ({ orgId }) => (orgId ? `${REPORT_EXPORT_HISTORY_EVENT}_${orgId}` : null),
    [ORG_CONFIG_EVENT]: ({ orgId }) => (orgId ? `${ORG_CONFIG_EVENT}_${orgId}` : null),
    [APPLICATION_CUSTOM_APP_PATCH_QUEUE_EVENT]: ({ orgId, applicationId }) =>
        applicationId ? `${APPLICATION_CUSTOM_APP_PATCH_QUEUE_EVENT}_${applicationId}_${orgId}` : null,
};

export const getSubscriptionId = (roomId: keyof typeof roomIds, roomParams: RoomParams): string | null => {
    if (typeof roomIds[roomId] === 'function') {
        return roomIds[roomId](roomParams);
    }
    console.log('Client used unsupported roomId', roomId, 'with roomParams', JSON.stringify(roomParams));
    return null;
};
