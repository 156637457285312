export const AppleWebContentFilterConfiguration = {
    schema: {
        schemaId: 'appleWebContentFilterConfiguration',
        category: 'Web',
        title: 'Web Content Filter',
        type: 'object',
        properties: {
            'com.apple.webcontent-filter': {
                title: '',
                type: 'object',
                properties: {
                    FilterType: {
                        title: 'Filter Type',
                        description: 'The type of filter, built-in or plug-in.',
                        help: 'In macOS, the system only supports the plug-in value.',
                        type: 'string',
                        options: [
                            {
                                id: 'BuiltIn',
                                name: 'BuiltIn',
                            },
                            {
                                id: 'Plugin',
                                name: 'Plugin',
                            },
                        ],
                    },
                    //*FILTERTYPE === BUILTIN
                    AutoFilterEnabled: {
                        title: 'Auto Filter Enabled',
                        description: 'If true, the system enables automatic filtering.',
                        type: 'boolean',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'BuiltIn') {
                                return true;
                            } else {
                                delete siblings.AutoFilterEnabled;
                                return false;
                            }
                        },
                    },
                    PermittedURLs: {
                        title: 'Permitted URLs',
                        description: 'An array or URLs that are accessible whether or not the automatic filter allows access.',
                        type: 'array',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'BuiltIn' && siblings.AutoFilterEnabled === true) {
                                return true;
                            } else {
                                delete siblings.PermittedURLs;
                                return false;
                            }
                        },
                        items: {
                            type: 'string',
                        },
                    },
                    DenyListURLs: {
                        title: 'Deny List URLs',
                        description: 'An array of strings that define the URLs that the user cannot visit.',
                        type: 'array',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'BuiltIn') {
                                return true;
                            } else {
                                delete siblings.DenyListURLs;
                                return false;
                            }
                        },
                        items: {
                            type: 'string',
                        },
                    },
                    AllowListBookmarks: {
                        title: 'Allow List Bookmarks',
                        description: 'An array of dictionaries that define the pages that the user can bookmark or visit.',
                        type: 'array',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'BuiltIn') {
                                return true;
                            } else {
                                delete siblings.AllowListBookmarks;
                                return false;
                            }
                        },
                        items: {
                            type: 'object',
                            properties: {
                                Title: {
                                    title: 'Title',
                                    description: 'The title of the bookmark.',
                                    type: 'string',
                                    required: true,
                                },
                                URL: {
                                    title: 'URL',
                                    description: 'The URL of the bookmark in the allow list.',
                                    type: 'string',
                                    required: true,
                                },
                            },
                        },
                    },
                    //*FILTERTYPE === PLUGIN
                    PluginBundleID: {
                        title: 'Plugin Bundle ID',
                        description: 'The bundle ID of the plug-in that provides filtering service.',
                        type: 'string',
                        required: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.PluginBundleID;
                                return false;
                            }
                        },
                    },
                    ContentFilterUUID: {
                        title: 'Content Filter UUID',
                        description: 'A globally unique identifier for this content filter configuration.',
                        help: 'The content filter processes network traffic for managed apps with the same ContentFilterUUID in their app attributes.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.ContentFilterUUID;
                                return false;
                            }
                        },
                    },
                    ServerAddress: {
                        title: 'Server Address',
                        description: 'The server address, which may be the IP address, hostname, or URL.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.ServerAddress;
                                return false;
                            }
                        },
                    },
                    UserName: {
                        title: 'User Name',
                        description: 'The user name for the service.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.UserName;
                                return false;
                            }
                        },
                    },
                    UserDefinedName: {
                        title: 'User Defined Name',
                        description: 'The display name for this filtering configuration.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.UserDefinedName;
                                return false;
                            }
                        },
                    },
                    Password: {
                        title: 'Password',
                        description: 'The password for the service.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.Password;
                                return false;
                            }
                        },
                    },
                    Organization: {
                        title: 'Organization',
                        description: 'The organization string to pass to the third-party plug-in.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.Organization;
                                return false;
                            }
                        },
                    },
                    FilterSockets: {
                        title: 'Filter Sockets',
                        description: 'If true, enables the filtering of socket traffic.',
                        help: 'At least one of FilterBrowsers or FilterSockets needs to be true.',
                        type: 'boolean',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.FilterSockets;
                                return false;
                            }
                        },
                    },
                    FilterDataProviderBundleIdentifier: {
                        title: 'Filter Data Provider Bundle Identifier',
                        description:
                            'The bundle identifier string of the filter data provider system extension. This string identifies the filter data provider when the filter starts running.',
                        type: 'string',
                        required: (siblings) => {
                            if (siblings.FilterSockets === true) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        shouldRender: (siblings) => {
                            if (siblings.FilterSockets === true) {
                                return true;
                            } else {
                                delete siblings.FilterDataProviderBundleIdentifier;
                                return false;
                            }
                        },
                    },
                    FilterDataProviderDesignatedRequirement: {
                        title: 'Filter Data Provider Designated Requirement',
                        description:
                            'The designated requirement string that the system embeds in the code signature of the filter data provider system extension. This string identifies the filter data provider when the filter starts running.',
                        type: 'string',
                        required: (siblings) => {
                            if (siblings.FilterSockets === true) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        shouldRender: (siblings) => {
                            if (siblings.FilterSockets === true) {
                                return true;
                            } else {
                                delete siblings.FilterDataProviderDesignatedRequirement;
                                return false;
                            }
                        },
                    },
                    FilterPackets: {
                        title: 'Filter Packets',
                        description: 'If true and FilterType is Plugin, the system enables filtering network packets.',
                        help: 'At least one of FilterPackets or FilterSockets needs to be true.',
                        type: 'boolean',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.FilterPackets;
                                return false;
                            }
                        },
                    },
                    FilterPacketProviderBundleIdentifier: {
                        title: 'Filter Packet Provider Bundle Identifier',
                        description:
                            'The bundle identifier string of the filter packet provider system extension. This string identifies the filter packet provider when the filter starts running.',
                        type: 'string',
                        required: (siblings) => {
                            if (siblings.FilterPackets === true) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        shouldRender: (siblings) => {
                            if (siblings.FilterPackets === true) {
                                return true;
                            } else {
                                delete siblings.FilterPacketProviderBundleIdentifier;
                                return false;
                            }
                        },
                    },
                    FilterPacketProviderDesignatedRequirement: {
                        title: 'Filter Packet Provider Designated Requirement',
                        description:
                            'The designated requirement string that the system embeds in the code signature of the filter packet provider system extension. This string identifies the filter packet provider when the filter starts running.',
                        type: 'string',
                        required: (siblings) => {
                            if (siblings.FilterPackets === true) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        shouldRender: (siblings) => {
                            if (siblings.FilterPackets === true) {
                                return true;
                            } else {
                                delete siblings.FilterPacketProviderDesignatedRequirement;
                                return false;
                            }
                        },
                    },
                    FilterBrowsers: {
                        title: 'Filter Browsers',
                        description: 'If true, the system enables filtering WebKit traffic.',
                        help: 'At least one of FilterBrowsers or FilterSockets needs to be true.',
                        type: 'boolean',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.FilterBrowsers;
                                return false;
                            }
                        },
                    },
                    PayloadCertificateUUID: {
                        title: 'Payload Certificate UUID',
                        description: 'The UUID of the certificate payload within the same profile that the system uses to authenticate the user.',
                        type: 'string',
                        shouldRender: (siblings) => {
                            if (siblings.FilterType === 'Plugin') {
                                return true;
                            } else {
                                delete siblings.PayloadCertificateUUID;
                                return false;
                            }
                        },
                    },
                    //TODO: Handle VendorConfig
                    // VendorConfig: {
                    //     title: 'Vendor Config',
                    //     description: 'A dictionary of vendor-specific keys and values.',
                    //     type: 'object',
                    //     properties: {},
                    // },
                    FilterGrade: {
                        title: 'Filter Grade',
                        description:
                            'The system uses this value to derive the relative order of content filters. Filters with a grade of firewall see network traffic before filters with a grade of inspector. However, the system doesn’t define the order of filters within a grade.',
                        type: 'string',
                        options: [
                            {
                                id: 'firewall',
                                name: 'Firewall',
                            },
                            {
                                id: 'inspector',
                                name: 'Inspector',
                            },
                        ],
                        shouldRender: (siblings) => {
                            if (siblings.FilterType) {
                                return true;
                            } else {
                                delete siblings.FilterGrade;
                                return false;
                            }
                        },
                    },
                },
            },
        },
    },
};
