import AppBar, { AppBarProps } from '@mui/material/AppBar';
import MUIToolbar, { ToolbarProps as MUIToolbarProps } from '@mui/material/Toolbar';
import classNames from 'classnames';
import React from 'react';

const toolbarClasses = {
    root: 'cs-toolbar',
    dense: 'cs-toolbar-compact',
};

export type ToolbarProps = MUIToolbarProps &
    AppBarProps & {
        compact?: boolean;
        children?: React.ReactNode;
    };

/** Toolbar */
export const Toolbar: React.FC<ToolbarProps> = ({
    position = 'static',
    elevation = 0,
    compact = false,
    disableGutters = true,
    className,
    children,
    ...otherProps
}) => (
    <AppBar
        position={position}
        elevation={elevation}
        className={classNames(className, 'cs-toolbar-container')}
        color="transparent"
        {...otherProps}
    >
        <MUIToolbar
            disableGutters={disableGutters}
            classes={toolbarClasses}
            variant={compact ? 'dense' : 'regular'}
        >
            {children}
        </MUIToolbar>
    </AppBar>
);

export default Toolbar;
