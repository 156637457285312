import { BUTTON, SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    EmptyState,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Paper,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
    useParams,
} from '@capasystems/ui';
import { Url, getSortingFunction, noop } from '@capasystems/utils';
import { ENDPOINT_TYPE, USER } from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionsDialog,
    BooleanBadge,
    CapaOneLink,
    CollectedOnTimestamp,
    ConfirmDialog,
    Heading,
    PageTitle,
    QueryBuilder,
    TailwindBadge,
    TwoLineCellRenderer,
    WidgetPaper,
    useAndroidApi,
    useAppleApi,
    useManagementApi,
    useNavigate,
} from './../../index';

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Search users' });

const editAction = {
    id: 'edit',
    name: 'Edit',
};
/* const endpointManagementAction = {
    id: "endpointManagement",
    name: "Endpoint management", 
}; */
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};

const userActionsByType = {
    [USER.TYPE.AZURE]: [
        // endpointManagementAction
    ],
    [USER.TYPE.LOCAL]: [
        editAction,
        // endpointManagementAction,
        deleteAction,
    ],
};

const sortingFunction = getSortingFunction({
    sortDirection: SORT_DIRECTION.ASC,
    sortBy: 'name',
});

export const UserManagementUserID = () => {
    const { userId } = useParams();
    const managementApi = useManagementApi();
    const [selectedTab, setSelectedTab] = useState(() => Url.getString('tab', 'information'));
    const [user, setUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const [assignedEndpointsState, setAssignedEndpointsState] = useState({
        items: [],
        loading: true,
    });
    const [isEditing, setIsEditing] = useState(false);
    /* const [actionsDialogState, setActionsDialogState] = useState({
        open: false,
        anchorEl: null
    }); */
    const [deleteState, setDeleteState] = useState({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });

    const onDeleteConfirmed = () => {
        setDeleteState({
            open: true,
            isProcessing: true,
        });
        managementApi
            .deleteUser(user.id)
            .then(() => {
                navigate.to('management/user', true);
            })
            .catch(() => {
                setDeleteState({
                    open: true,
                    anErrorOccurred: true,
                });
            });
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    /* 
    const onActionClick = (action) => {
        setActionsDialogState({
            ...actionsDialogState,
            open: false,
        });
        switch(action.id) {
            case editAction.id:
                setIsEditing(true);
                break;
            case deleteAction.id:
                setDeleteState({
                    open: true,
                });
                break;
        }
    };
    */

    const onTabChange = (_, tabValue) => {
        Url.set('tab', tabValue);
        setSelectedTab(tabValue);
    };

    useEffect(() => {
        managementApi
            .getUser(userId)
            .then((userResponse) => {
                managementApi
                    .getEndpointsAssignedToUser(userId)
                    .then((assignedEndpointsResponse) => {
                        setAssignedEndpointsState({ items: assignedEndpointsResponse, loading: false });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
                setUser(userResponse);
            })
            .catch(() => {
                setErrorMessage('Could not find user');
            });
    }, [managementApi, userId]);

    if (user === null) {
        if (errorMessage) {
            return (
                <LayoutCentered>
                    <div>
                        <PageTitle category="Error">{errorMessage}</PageTitle>
                        <div className="tw-mt-4">
                            <CapaOneLink to="management/user">
                                <Button
                                    variant={BUTTON.RAISED}
                                    color={BUTTON.PRIMARY}
                                >
                                    Back
                                </Button>
                            </CapaOneLink>
                        </div>
                    </div>
                </LayoutCentered>
            );
        }
        return (
            <LayoutCentered>
                <Loading />
            </LayoutCentered>
        );
    }
    return (
        <div className="tw-grid tw-h-full tw-grid-rows-auto-auto-1fr tw-gap-1">
            <div className="tw-px-4 tw-pt-4">
                <div className="tw-grid tw-grid-cols-auto-auto-1fr-auto tw-items-center tw-gap-4">
                    <div>
                        <UserTypeIcon
                            type={user.type}
                            className="tw-h-16 tw-w-16"
                        />
                    </div>
                    <div>
                        <PageTitle
                            category="User"
                            description={
                                <LayoutRow
                                    verticalAlign="center"
                                    className="tw-mt-1"
                                >
                                    {user.email}
                                </LayoutRow>
                            }
                        >
                            {user.name}
                        </PageTitle>
                    </div>
                    <div>
                        {/* 
                        <IconButton 
                            onClick={(e) => {
                                setActionsDialogState({
                                    open: true,
                                    anchorEl: e.currentTarget,
                                });
                            }}
                            noMargin
                        >
                            <Icon type="moreVert" />
                        </IconButton>
                        <ActionsDialog
                            open={actionsDialogState.open}
                            anchorEl={actionsDialogState.anchorEl}   
                            onClose={() => {
                                setActionsDialogState({
                                    ...actionsDialogState,
                                    open: false,
                                });
                            }}
                            category="User"
                            title={user.name}
                            actions={userActionsByType[user.type]}
                            onActionClick={onActionClick}
                        />  
                        */}
                        <UserManagementCreateOrEditLocalUser
                            open={isEditing}
                            onCancel={() => {
                                setIsEditing(false);
                            }}
                            onSubmit={(editedUser) => {
                                setUser(editedUser);
                                setIsEditing(false);
                            }}
                            user={user}
                        />
                        {/* <UserManagementAssignEndpointToUser 
                            open={isAssigningEndpointToUser}
                            user={user}
                            onClose={() => setIsAssigningEndpointToUser(false)}
                            onSubmit={() => {
                                console.log("Call management/user/<user.id>/device to get the new details for assignedEndpoints");
                            }}
                        /> */}
                        <ConfirmDialog
                            onConfirm={onDeleteConfirmed}
                            onCancel={closeDeleteDialog}
                            open={deleteState.open}
                            title={`Delete ${user.name}?`}
                            isProcessing={deleteState.isProcessing}
                        >
                            {deleteState.anErrorOccurred && (
                                <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{user.name}". Please try again.</b>
                            )}
                        </ConfirmDialog>
                    </div>
                    <div>
                        {user.deleted && (
                            <TailwindBadge
                                color="red"
                                size="small"
                            >
                                Marked as deleted
                            </TailwindBadge>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    onRails
                >
                    <Tab
                        key="information"
                        disableRipple
                        label="Information"
                        value="information"
                        className="tw-px-8"
                    />
                    <Tab
                        key="endpoints"
                        disableRipple
                        label="Endpoints"
                        value="endpoints"
                        className="tw-px-8"
                    />
                </Tabs>
            </div>
            <div className="tw-overflow-auto tw-p-4">
                <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl">
                    {selectedTab === 'information' && <InformationTabContent user={user} />}
                    {selectedTab === 'endpoints' && (
                        <WidgetPaper
                            headerless
                            className="tw-h-full"
                        >
                            <VirtualizedTable
                                items={assignedEndpointsState.items}
                                isLoading={assignedEndpointsState.loading}
                                sortDirection="DESC"
                                totalRowCount={assignedEndpointsState.items.length}
                                disableHeader={assignedEndpointsState.items.length === 0}
                                onRowClick={({ rowData }) => {
                                    if (rowData.type === 'ANDROID') {
                                        navigate.to(`android/device/${rowData.id}/dashboard`);
                                    }
                                    if (rowData.type === 'APPLE') {
                                        navigate.to(`apple/endpoint/${rowData.id}/dashboard`);
                                    }
                                }}
                                noRowsRenderer={() => (
                                    <EmptyState
                                        entity="endpoint"
                                        description={`${user.name} is not assigned to any endpoints`}
                                    />
                                )}
                            >
                                <Column
                                    minWidth={40}
                                    maxWidth={40}
                                    dataKey="type"
                                    label=""
                                    type="icon"
                                    cellRenderer={({ cellData }) => {
                                        if (cellData === 'WINDOWS') {
                                            return (
                                                <Icon
                                                    type="windows"
                                                    className="tw-text-windows"
                                                    size="large"
                                                />
                                            );
                                        }
                                        if (cellData === 'ANDROID') {
                                            return (
                                                <Icon
                                                    type="android"
                                                    className="tw-h-8 tw-w-8 tw-text-android"
                                                />
                                            );
                                        }
                                        if (cellData === 'APPLE') {
                                            return (
                                                <Icon
                                                    type="apple"
                                                    className="tw-h-8 tw-w-8 tw-text-appleDark"
                                                />
                                            );
                                        }
                                        return (
                                            <Icon
                                                type="badgeExclamation"
                                                className="tw-h-8 tw-w-8 tw-text-amber-400"
                                            />
                                        );
                                    }}
                                />
                                <Column
                                    minWidth={160}
                                    dataKey="name"
                                    label="Name"
                                    type="multiLine"
                                    cellRenderer={({ rowData }) => {
                                        return (
                                            <TwoLineCellRenderer
                                                main={rowData.name}
                                                secondary={rowData.email}
                                                callToAction
                                            />
                                        );
                                    }}
                                />
                            </VirtualizedTable>
                        </WidgetPaper>
                    )}
                </div>
            </div>
        </div>
    );
};

const InformationTabContent = ({ user }) => {
    if (user.type === USER.TYPE.AZURE) {
        return (
            <div>
                <LayoutRow
                    align="end"
                    className="tw-mb-3"
                >
                    <CollectedOnTimestamp timestamp={user.updatedAt} />
                </LayoutRow>
                <UserInformationContainer title="Identity">
                    <UserInformationLabel>Display name</UserInformationLabel>
                    <UserInformationValue>{user.azure.displayName} </UserInformationValue>
                    <UserInformationLabel>User principal name</UserInformationLabel>
                    <UserInformationValue>{user.azure.userPrincipalName}</UserInformationValue>
                    <UserInformationLabel>User type (Azure)</UserInformationLabel>
                    <UserInformationValue>{user.azure.userType}</UserInformationValue>
                    <UserInformationLabel>Preferred language</UserInformationLabel>
                    <UserInformationValue>{user.azure.preferredLanguage}</UserInformationValue>
                </UserInformationContainer>
                <UserInformationContainer title="Contact">
                    <UserInformationLabel>Street address</UserInformationLabel>
                    <UserInformationValue>{user.azure.streetAddress}</UserInformationValue>
                    <UserInformationLabel>City</UserInformationLabel>
                    <UserInformationValue>{user.azure.city}</UserInformationValue>
                    <UserInformationLabel>Country</UserInformationLabel>
                    <UserInformationValue>{user.azure.country}</UserInformationValue>
                    <UserInformationLabel>Email</UserInformationLabel>
                    <UserInformationValue>{user.azure.mail}</UserInformationValue>
                    <UserInformationLabel>{pluralize('Business phone', user.azure.businessPhones?.length || 1, false)}</UserInformationLabel>
                    <UserInformationValue>{user.azure.businessPhones?.join(', ')}</UserInformationValue>
                    <UserInformationLabel>Mobile phone</UserInformationLabel>
                    <UserInformationValue>{user.azure.mobilePhone}</UserInformationValue>
                    <UserInformationLabel>Mail nickname</UserInformationLabel>
                    <UserInformationValue>{user.azure.mailNickname}</UserInformationValue>
                </UserInformationContainer>
                <UserInformationContainer title="Job">
                    <UserInformationLabel>Job title</UserInformationLabel>
                    <UserInformationValue>{user.azure.jobTitle}</UserInformationValue>
                    <UserInformationLabel>Company name</UserInformationLabel>
                    <UserInformationValue>{user.azure.companyName}</UserInformationValue>
                    <UserInformationLabel>Department</UserInformationLabel>
                    <UserInformationValue>{user.azure.department}</UserInformationValue>
                    <UserInformationLabel>Office location</UserInformationLabel>
                    <UserInformationValue>{user.azure.officeLocation}</UserInformationValue>
                </UserInformationContainer>
                <UserInformationContainer title="On-premises">
                    <UserInformationLabel>Security identifier</UserInformationLabel>
                    <UserInformationValue>{user.azure.onPremisesSecurityIdentifier}</UserInformationValue>
                </UserInformationContainer>
                <UserInformationContainer title="Settings">
                    <UserInformationLabel>Account enabled</UserInformationLabel>
                    <UserInformationValue>
                        <BooleanBadge value={user.azure.accountEnabled} />
                    </UserInformationValue>
                </UserInformationContainer>
            </div>
        );
    }
    if (user.type === USER.TYPE.LOCAL) {
        return (
            <div>
                <UserInformationContainer title="Contact">
                    <UserInformationLabel>Name</UserInformationLabel>
                    <UserInformationValue>{user.name} </UserInformationValue>
                    <UserInformationLabel>Email</UserInformationLabel>
                    <UserInformationValue>{user.email}</UserInformationValue>
                    <UserInformationLabel>Phone</UserInformationLabel>
                    <UserInformationValue>{user.phoneNumber}</UserInformationValue>
                </UserInformationContainer>
            </div>
        );
    }
    return null;
};

/* 
export const UserManagementAssignEndpointToUser = ({ onSubmit, open = false, user, onClose }) => {
    const { permissions } = useAuthContext();
    const androidApi = useAndroidApi();
    const [endpointList, setEndpointList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("android");

    const handleAssignEndpointToUser = (endpoint) => {
        console.log(endpoint, user);
    };

    useEffect(() => {
        if ( open ){
            if ( permissions.canViewAndroidManagement ){
                setLoading(true);
                setSearchTerm("");
                androidApi
                    .getDevices().then(response => {
                        setEndpointList(response);
                    })
                    .catch(noop).finally(() => {
                        setLoading(false);
                    });
            } else {
                setLoading(false);
                // TODO: Handle Windows etc.
            }
        } else {
            setLoading(false);
        }
    }, [androidApi, open, permissions]);

    const memoizedEndpointList = useMemo(() => {
        if ( selectedTab === "android"){
            if ( searchTerm ){
                return endpointList.filter(endpoint => endpoint.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            }
            return endpointList;
        }
        return [];
    }, [searchTerm, endpointList, selectedTab]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <LayoutRow verticalAlign="top" align="space-between">
                    <PageTitle
                        category="Endpoint management for user"
                    >
                        {user.name}
                    </PageTitle>
                    <div>
                       
                    </div>
                </LayoutRow>
               
                <div className='tw-w-96 tw-mx-auto'>
                    {endpointList.length > 0 && (
                        <Input 
                            callToAction
                            placeholder='Search endpoint name'
                            className="tw-mx-auto tw-w-96 tw-mt-4"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            delay={350}
                            autoFocus
                        />
                    )}
                </div>
            </DialogTitle>

            <Tabs
                value={selectedTab}
                variant="fullWidth"
                onChange={(_, v) => setSelectedTab(v)}
            >
                <Tab
                    label="Android"
                    value="android"
                    disableRipple
                />
                <Tab
                    label="Windows"
                    value="windows"
                    disableRipple
                />
            </Tabs>

            <DialogContent className="tw-p-0 tw-px-0 tw-border-y" style={{ maxHeight: "80vh", minHeight: "30vh", height: 62 * (memoizedEndpointList.length)}}>
                <VirtualizedTable
                    items={memoizedEndpointList}
                    sortDirection="DESC"
                    totalRowCount={memoizedEndpointList.length}
                    disableHeader={memoizedEndpointList.length === 0}
                    headerClassName="tw-text-xs tw-text-neutral-500"
                    onRowClick={({ rowData }) => handleAssignEndpointToUser(rowData)}
                    rowClassName="hover:tw-bg-slate-50"
                    isLoading={loading}
                >
                    <Column
                        minWidth={40}
                        maxWidth={40}
                        dataKey="type"
                        label=""
                        cellRenderer={({ cellData }) => {
                            return (
                                <Icon 
                                    type="android" 
                                    className="tw-ml-2 tw-text-android"
                                />
                            );
                        }}

                    />
                    <Column
                        minWidth={300}
                        dataKey="name"
                        label="Name"
                        cellRenderer={({ rowData }) => {
                            return (
                                <TwoLineCellRenderer
                                    main={rowData.name}
                                    secondary={rowData.androidData.hardwareInfo?.model}
                                    callToAction
                                />
                               
                            );
                        }}
                    />
                    <Column
                        minWidth={300}
                        dataKey="user"
                        label="Current user"
                        cellRenderer={({ rowData }) => {
                            if ( rowData.user ){
                                return (
                                    <TwoLineCellRenderer
                                        main={rowData.user.name}
                                        secondary={rowData.user.email}
                                        // callToAction
                                        secondaryProps={{ className: "tw-truncate "}}
                                    />
                                );
                            }
                            return <i className="tw-text-neutral-300">None</i>;
                        }}
                    />
                </VirtualizedTable>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
*/

export const UserManagementAssignUserToEndpoint = ({ onSubmit, open = false, endpointType, endpoint, onClose }) => {
    const managementApi = useManagementApi();
    const androidApi = useAndroidApi();
    const appleApi = useAppleApi();
    const [userList, setUserList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    const handleAssignUserToEndpoint = (user, isDeleteOperation = false) => {
        if (endpointType === ENDPOINT_TYPE.ANDROID) {
            androidApi
                .updateDevice(endpoint.id, {
                    user: isDeleteOperation ? null : user.id,
                })
                .then(() => {
                    onSubmit(isDeleteOperation ? null : user);
                })
                .catch(noop);
        } else if (endpointType === ENDPOINT_TYPE.APPLE) {
            appleApi
                .updateOrDeleteEndpointUser(endpoint.id, isDeleteOperation ? null : user.id)
                .then(() => {
                    onSubmit(isDeleteOperation ? null : user);
                })
                .catch(noop);
        }
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            setSearchTerm('');
            managementApi
                .getUsers()
                .then(setUserList)
                .catch(noop)
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [managementApi, open]);

    const memoizedUserList = useMemo(() => {
        if (searchTerm) {
            return userList.filter(
                (user) =>
                    user.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                    user.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            );
        }
        return userList;
    }, [searchTerm, userList]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <LayoutRow
                    verticalAlign="top"
                    align="space-between"
                >
                    <PageTitle description={endpoint.name}>User management</PageTitle>
                    <div></div>
                </LayoutRow>

                <div className="tw-mx-auto tw-w-96">
                    {userList.length > 0 && (
                        <Input
                            callToAction
                            placeholder="Search user name"
                            className="tw-mx-auto tw-mt-4 tw-w-96"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            delay={350}
                            autoFocus
                        />
                    )}
                </div>
            </DialogTitle>
            <DialogContent
                className="tw-border-y tw-p-0 tw-px-0"
                style={{ maxHeight: '80vh', minHeight: '30vh', height: 62 * memoizedUserList.length }}
            >
                <VirtualizedTable
                    items={memoizedUserList}
                    sortDirection="DESC"
                    totalRowCount={memoizedUserList.length}
                    disableHeader
                    onRowClick={({ rowData }) => handleAssignUserToEndpoint(rowData)}
                    rowClassName="hover:tw-bg-slate-50"
                    isLoading={loading}
                >
                    <Column
                        minWidth={32}
                        maxWidth={32}
                        dataKey="type"
                        label="Type"
                        type="icon"
                        cellRenderer={({ cellData }) => {
                            return <UserTypeIcon type={cellData} />;
                        }}
                    />
                    <Column
                        minWidth={160}
                        dataKey="name"
                        label="Name"
                        type="multiLine"
                        cellRenderer={({ rowData }) => {
                            return (
                                <TwoLineCellRenderer
                                    main={rowData.name}
                                    secondary={rowData.email}
                                    callToAction
                                />
                            );
                        }}
                    />
                </VirtualizedTable>
            </DialogContent>
            <DialogActions>
                {endpoint?.user && (
                    <Button
                        onClick={() => handleAssignUserToEndpoint(endpoint.user, true)}
                        variant={BUTTON.RAISED}
                    >
                        Remove current user ({endpoint?.user?.name})
                    </Button>
                )}
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export const UserCellRenderer = ({ user, onAddUserClick, viewOnly = false }) => {
    if (user && !viewOnly) {
        return (
            <CapaOneLink to={`management/user/${user._id}`}>
                <Button
                    size={BUTTON.SMALL}
                    startIcon={
                        <UserTypeIcon
                            type={user.type}
                            deleted={user.deleted}
                        />
                    }
                    fullWidth
                    noMargin
                >
                    <Ellipsis>{user.name}</Ellipsis>
                </Button>
            </CapaOneLink>
        );
    }
    if (user && viewOnly) {
        return (
            <LayoutRow>
                <UserTypeIcon type={user.type} />
                <Ellipsis className="tw-m-1 tw-text-xs tw-font-semibold">{user.name}</Ellipsis>
            </LayoutRow>
        );
    }
    if (!user && viewOnly === true) {
        if (!user) {
            return (
                <LayoutRow>
                    <UserTypeIcon
                        className="tw-opacity-20"
                        type={USER.TYPE.NO_USER}
                    />
                    <p className="tw-m-1 tw-text-xs tw-font-semibold tw-text-gray-300">No user assigned</p>
                </LayoutRow>
            );
        }
    }

    return (
        <div>
            <Button
                size={BUTTON.SMALL}
                noMargin
                onClick={onAddUserClick}
                fullWidth
                className="tw-text-gray-300 hover:tw-text-inherit"
                startIcon={<Icon type="addUser" />}
            >
                Add user
            </Button>
        </div>
    );
};

const UserInformationContainer = ({ title, children }) => (
    <WidgetPaper
        title={title}
        className="tw-mb-4"
    >
        <div className="tw-grid tw-grid-cols-auto-1fr tw-items-center tw-gap-x-8 tw-gap-y-3 tw-px-4 tw-pb-4">{children}</div>
    </WidgetPaper>
);

const UserInformationLabel = ({ children }) => (
    <div
        className="tw-text-tiny tw-font-semibold tw-text-slate-900"
        style={{ minWidth: 120 }}
    >
        {children}
    </div>
);
const UserInformationValue = ({ children }) => (
    <div className="tw-font-semibold tw-text-slate-900">{children || <i className="tw-font-medium tw-opacity-20">None</i>}</div>
);

const UserManagementCreateOrEditLocalUser = ({ open, onCancel, onSubmit, user }) => {
    const [validated, setValidated] = useState(false);
    const [busy, setBusy] = useState(true);
    const managementApi = useManagementApi();

    const userRef = useRef({
        name: '',
        email: '',
        phoneNumber: '',
        type: USER.TYPE.LOCAL,
    });

    const [invalidFormData, setInvalidFormData] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (userRef.current.name.trim() === '' || userRef.current.email.trim() === '') {
            setInvalidFormData(true);
        } else if (user) {
            setBusy(true);
            managementApi
                .updateUser(user.id, userRef.current)
                .then((response) => {
                    onSubmit(response);
                })
                .catch(() => {
                    setBusy(false);
                });
            setInvalidFormData(false);
        } else {
            setBusy(true);
            managementApi
                .createUser(userRef.current)
                .then((response) => {
                    onSubmit(response);
                })
                .catch(() => {
                    setBusy(false);
                });
            setInvalidFormData(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onEnter={() => {
                setBusy(false);
                setInvalidFormData(false);
                if (user) {
                    userRef.current.name = user.name;
                    userRef.current.email = user.email;
                    userRef.current.phoneNumber = user.phoneNumber;
                } else {
                    userRef.current.name = '';
                    userRef.current.email = '';
                    userRef.current.phoneNumber = '';
                }
                setValidated(true);
            }}
            onExited={() => {
                setValidated(false);
            }}
            unmountOnExit
        >
            <form onSubmit={handleSubmit}>
                {validated && (
                    <DialogContent>
                        <PageTitle
                            category={user ? 'Editing' : 'New'}
                            className="tw-mb-4"
                        >
                            {user ? user.name : 'User'}
                        </PageTitle>
                        <div className="tw-grid tw-grid-cols-auto-1fr tw-items-center tw-gap-x-8 tw-gap-y-6">
                            <div>Name</div>
                            <Input
                                autoFocus
                                callToAction
                                fullWidth
                                defaultValue={userRef.current.name}
                                onChange={(e) => {
                                    setInvalidFormData(false);
                                    userRef.current.name = e.target.value;
                                }}
                                minLength={1}
                                error={invalidFormData && userRef.current.name.trim() === ''}
                            />
                            <div>Email</div>
                            <Input
                                type="email"
                                callToAction
                                fullWidth
                                defaultValue={userRef.current.email}
                                onChange={(e) => {
                                    setInvalidFormData(false);
                                    userRef.current.email = e.target.value;
                                }}
                                error={invalidFormData && userRef.current.email.trim() === ''}
                            />
                            <div>Phone</div>
                            <Input
                                callToAction
                                fullWidth
                                defaultValue={userRef.current.phoneNumber}
                                onChange={(e) => {
                                    userRef.current.phoneNumber = e.target.value;
                                }}
                            />
                        </div>
                    </DialogContent>
                )}
                <DialogActions className="tw-mt-2 tw-pr-4">
                    <Button
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        type="submit"
                        disabled={busy}
                    >
                        {user ? 'Save changes' : 'Create'}
                    </Button>
                    <Button
                        onClick={onCancel}
                        variant={BUTTON.RAISED}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export const UserManagementUserList = () => {
    const managementApi = useManagementApi();
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [creatingUser, setCreatingUser] = useState(false);
    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: {
            name: '',
        },
    });
    const [isEditing, setIsEditing] = useState(false);
    const [deleteState, setDeleteState] = useState({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });
    const queryBuilderRef = useRef({});

    const onScrollToBottom = () => null;

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const openCreateUserDialog = () => {
        setCreatingUser(true);
    };

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onEdit = () => {
        setIsEditing(true);
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });
            managementApi
                .deleteUser(menuState.rowData.id)
                .then(() => {
                    setUserList((currentUserList) => {
                        return currentUserList.filter((user) => user.id !== menuState.rowData.id);
                    });
                    closeDeleteDialog();
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                    });
                });
        } else {
            setDeleteState({
                open: true,
            });
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case editAction.id:
                onEdit();
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    useEffect(() => {
        managementApi
            .getUsers()
            .then((usersResponse) => {
                setUserList(usersResponse);
            })
            .catch((usersResponse) => {
                setErrorMessage('Could not get users');
                console.log(usersResponse);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [managementApi]);

    const memoizedUserList = useMemo(() => {
        if (searchTerm) {
            return userList.filter(
                (user) =>
                    user.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                    user.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            );
        }
        return userList;
    }, [searchTerm, userList]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <div>
                    <Heading>{errorMessage}</Heading>
                </div>
            </LayoutCentered>
        );
    }

    return (
        <>
            <UserManagementCreateOrEditLocalUser
                open={creatingUser}
                onCancel={() => {
                    setCreatingUser(false);
                }}
                onSubmit={(newUser) => {
                    setUserList((currentUserList) => {
                        return [...currentUserList, newUser].sort(sortingFunction);
                    });
                    setCreatingUser(false);
                }}
            />
            <UserManagementCreateOrEditLocalUser
                open={isEditing}
                onCancel={() => {
                    setIsEditing(false);
                }}
                onSubmit={(editedUser) => {
                    setUserList((currentUserList) => {
                        return currentUserList.map((user) =>
                            user.id === menuState.rowData.id
                                ? { ...user, name: editedUser.name, email: editedUser.email, phoneNumber: editedUser.phoneNumber }
                                : user
                        );
                    });
                    setIsEditing(false);
                }}
                user={menuState.rowData}
            />
            {/* <UserManagementAssignEndpointToUser 
                    open={isAssigningEndpointToUser}
                    user={menuState.rowData}
                    onClose={() => setIsAssigningEndpointToUser(false)}
                    onSubmit={() => {
                        console.log("Call management/user/<user.id>/device to get the new count for list");
                    }}
                /> */}
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="User"
                title={menuState.rowData.name}
                pages={menuState.pages}
                actions={userActionsByType[menuState.rowData.type]}
                onActionClick={onActionClick}
            />
            <ConfirmDialog
                onConfirm={onDelete}
                onCancel={closeDeleteDialog}
                open={deleteState.open}
                title={`Delete ${menuState.rowData.name}?`}
                isProcessing={deleteState.isProcessing}
            >
                {deleteState.anErrorOccurred && (
                    <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{menuState.rowData.name}". Please try again.</b>
                )}
            </ConfirmDialog>
            <Page title="Users">
                <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-p-4">
                    <div className="tw-mb-4 tw-flex tw-justify-end">
                        <QueryBuilder
                            defaultConfiguration={queryBuilderConfiguration}
                            onInit={onSubmit}
                            onSubmit={onSubmit}
                            className="tw-mr-4"
                            ref={queryBuilderRef}
                        />
                        <Button
                            noMargin
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                            onClick={openCreateUserDialog}
                        >
                            New
                        </Button>
                    </div>
                    <Paper className="tw-h-full tw-shadow-sm">
                        <VirtualizedTable
                            items={memoizedUserList}
                            sortDirection="DESC"
                            totalRowCount={memoizedUserList.length}
                            showRowCount={memoizedUserList.length > 0}
                            disableHeader={memoizedUserList.length === 0}
                            isLoading={loading}
                            noRowsRenderer={() => (
                                <EmptyState
                                    entity="user"
                                    searchTerm={searchTerm}
                                    isSearching={searchTerm !== ''}
                                    onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                                    description="Create a new user or integrate with Azure to get started"
                                    onClick={openCreateUserDialog}
                                    buttonText="New user"
                                >
                                    <Button
                                        onClick={() => {
                                            navigate.to('management/integration');
                                        }}
                                        color={BUTTON.PRIMARY}
                                        variant={BUTTON.RAISED}
                                    >
                                        Integrate with Azure
                                    </Button>
                                </EmptyState>
                            )}
                            onScrollToBottom={onScrollToBottom}
                            entity="user"
                        >
                            <Column
                                minWidth={32}
                                maxWidth={32}
                                dataKey="name"
                                label=""
                                type="icon"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <IconButton
                                            onClick={(e) => {
                                                setMenuState({
                                                    open: true,
                                                    anchorEl: e.currentTarget,
                                                    rowData,
                                                    pages: [
                                                        {
                                                            name: 'Information',
                                                            icon: 'infoOutlined',
                                                            url: `management/user/${rowData.id}?tab=information`,
                                                        },
                                                        {
                                                            name: 'Endpoints',
                                                            icon: 'windowsEndpoints',
                                                            url: `management/user/${rowData.id}?tab=endpoints`,
                                                        },
                                                    ],
                                                });
                                            }}
                                            noMargin
                                        >
                                            <Icon type="moreVert" />
                                        </IconButton>
                                    );
                                }}
                            />
                            <Column
                                minWidth={32}
                                maxWidth={32}
                                dataKey="type"
                                label=""
                                type="icon"
                                cellRenderer={({ cellData }) => {
                                    return (
                                        <UserTypeIcon
                                            type={cellData}
                                            className="tw-h-8 tw-w-8"
                                        />
                                    );
                                }}
                            />
                            <Column
                                minWidth={240}
                                dataKey="name"
                                label="Name"
                                type="multiLine"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <CapaOneLink to={`management/user/${rowData.id}`}>
                                            <TwoLineCellRenderer
                                                main={rowData.name}
                                                secondary={rowData.email}
                                                callToAction
                                            />
                                        </CapaOneLink>
                                    );
                                }}
                            />
                            <Column
                                minWidth={120}
                                dataKey="phoneNumber"
                                label="Phone"
                                type="string"
                            />
                            <Column
                                minWidth={120}
                                maxWidth={120}
                                dataKey="endpointMemberCount"
                                label="Endpoints"
                                type="number"
                                columnData={{
                                    tabName: 'endpoints',
                                }}
                                cellRenderer={managementSpaceUserListCountRenderer}
                            />
                        </VirtualizedTable>
                    </Paper>
                </div>
            </Page>
        </>
    );
};

const managementSpaceUserListCountRenderer = ({ cellData, rowData, columnData }) => {
    return (
        <CapaOneLink to={`management/user/${rowData.id}?tab=${columnData.tabName}`}>
            {cellData > 0 ? cellData : <span className="tw-text-xs tw-italic">None</span>}
        </CapaOneLink>
    );
};

export const UserTypeIcon = ({ type, className, colorControlled = false, deleted = false, ...rest }) => {
    if (deleted) {
        return (
            <Tooltip
                content="User is marked as deleted"
                position={TOOLTIP.POSITION.TOP_START}
            >
                <Icon
                    type="noAccounts"
                    className={classNames(
                        {
                            'tw-text-red-500': !colorControlled,
                        },
                        className
                    )}
                    {...rest}
                />
            </Tooltip>
        );
    }
    if (type === USER.TYPE.AZURE) {
        return (
            <Tooltip content="Azure user">
                <Icon
                    type="azure"
                    className={classNames(
                        {
                            'tw-text-azure': !colorControlled,
                        },
                        className
                    )}
                    {...rest}
                />
            </Tooltip>
        );
    }
    if (type === USER.TYPE.LOCAL) {
        return (
            <Tooltip content="Local user">
                <Icon
                    type="accountCircle"
                    className={classNames(
                        {
                            'tw-text-slate-500': !colorControlled,
                        },
                        className
                    )}
                    {...rest}
                />
            </Tooltip>
        );
    }
    if (type === USER.TYPE.NO_USER) {
        return (
            <Icon
                type="accountCircle"
                className={classNames(
                    {
                        'tw-text-slate-500': !colorControlled,
                    },
                    className
                )}
                {...rest}
            />
        );
    }

    return null;
};
