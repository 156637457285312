const AppleApplication = {
    schema: {
        title: 'Set up your application',
        type: 'object',
        schemaId: 'apple-application',
        properties: {
            // description: {
            //     title: 'Description',
            //     type: 'string',
            // },
        },
    },
};

export { AppleApplication };
