//Documentation: Passcode
//https://developer.apple.com/documentation/devicemanagement/passcode
export const ApplePasscodeConfiguration = {
    schema: {
        schemaId: 'applePasscodeConfiguration',
        category: 'Security',
        title: 'Passcode',
        type: 'object',
        properties: {
            'com.apple.mobiledevice.passwordpolicy': {
                title: '',
                type: 'object',
                properties: {
                    forcePIN: {
                        title: 'Force PIN',
                        description: 'If true, the system forces the user to enter a PIN.',
                        type: 'boolean',
                    },
                    allowSimple: {
                        title: 'Allow Simple',
                        description: 'If disabled, the system prevents use of a simple passcode.',
                        help: 'A simple passcode contains repeated characters, or increasing or decreasing characters, such as 123 or CBA.',
                        type: 'boolean',
                    },
                    maxFailedAttempts: {
                        title: 'Max Failed Attempts',
                        description:
                            'The number of allowed failed attempts to enter the passcode at the device’s lock screen. After six failed attempts, the system imposes a time delay before a passcode can be entered again. The delay increases with each attempt. In macOS, set minutesUntilFailedLoginReset to define a delay before the next passcode can be entered. When this number is exceeded in macOS, the system locks the device; in iOS, the system wipes the device.',
                        type: 'string',
                        options: [
                            {
                                id: 2,
                                name: '2',
                            },
                            {
                                id: 3,
                                name: '3',
                            },
                            {
                                id: 4,
                                name: '4',
                            },
                            {
                                id: 5,
                                name: '5',
                            },
                            {
                                id: 6,
                                name: '6',
                            },
                            {
                                id: 7,
                                name: '7',
                            },
                            {
                                id: 8,
                                name: '8',
                            },
                            {
                                id: 9,
                                name: '9',
                            },
                            {
                                id: 10,
                                name: '10',
                            },
                            {
                                id: 11,
                                name: '11',
                            },
                        ],
                    },
                    minutesUntilFailedLoginReset: {
                        title: 'Minutes Until Failed Login Reset',
                        description:
                            'The number of minutes before the system resets the login after the maximum number of unsuccessful login attempts is reached.',
                        help: 'This key requires setting maxFailedAttempts.',
                        type: 'number',
                        shouldRender: (siblings: any) => {
                            if (siblings.maxFailedAttempts) {
                                return true;
                            } else {
                                delete siblings.minutesUntilFailedLoginReset;
                                return false;
                            }
                        },
                    },
                    maxGracePeriod: {
                        title: 'Max Grace Period',
                        description:
                            'The maximum grace period, in minutes, to unlock the phone without entering a passcode. The default is 0, which is no grace period and requires a passcode immediately. In macOS, the system translates this grace period value to screen-saver settings.',
                        type: 'number',
                    },
                    maxInactivity: {
                        title: 'Max Inactivity',
                        description:
                            'The maximum number of minutes for which the device can be idle without the user unlocking it, before the system locks it. When this limit is reached, the system locks the device and the passcode is required to unlock it. The user can edit this setting, but the value can’t exceed the maxInactivity value.',
                        help: 'Possible values from 0 to 15. Note: In macOS, the system translates this inactivity value to screen-saver settings. The maximum value for macOS is 60.',
                        type: 'number',
                    },
                    maxPINAgeInDays: {
                        title: 'Max PIN Age In Days',
                        description: 'The number of days for which the passcode can remain unchanged.',
                        help: 'After this number of days, the system forces the user to change the passcode before it unlocks the device. Possible values from 0 to 730',
                        type: 'number',
                    },
                    minComplexChars: {
                        title: 'Min Complex Characters',
                        description: 'The minimum number of complex characters that a passcode needs to contain.',
                        help: 'A complex character is a character other than a number or a letter, such as &, %, $, and #. Possible values from 0 to 4.',
                        type: 'number',
                    },
                    minLength: {
                        title: 'Minimum Length',
                        description: 'The minimum overall length of the passcode',
                        help: 'Possible values from 0 to 16',
                        type: 'number',
                    },
                    pinHistory: {
                        title: 'Pin History',
                        description: 'This value defines N, where the new passcode must be unique within the last N entries in the passcode history.',
                        help: 'Possible values from 0 to 50',
                        type: 'number',
                    },
                    requireAlphanumeric: {
                        title: 'Require Alphanumeric',
                        description: 'If enabled, the system requires alphabetic characters instead of only numeric characters.',
                        type: 'boolean',
                    },
                    changeAtNextAuth: {
                        title: 'Change At Next Auth',
                        description: 'If enabled, the system causes a password reset to occur the next time the user tries to authenticate.',
                        help: 'If this key is set in a device profile, the setting takes effect for all users, and admin authentications may fail until the admin user password is also reset.',
                        type: 'boolean',
                    },
                    customRegex: {
                        title: 'Custom Regex',
                        description:
                            'Specifies a regular expression, and its description, used to enforce password compliance. Use the simpler passcode restrictions whenever possible, and rely on regular expression matching only when necessary. Mistakes in regular expressions can lead to frustrating user experiences, such as unsatisfiable passcode policies, or policy descriptions that don’t match the enforced policy. Available in macOS 14 and later.',
                        type: 'object',
                        properties: {
                            passwordContentRegex: {
                                title: 'Password Content Regex',
                                description:
                                    ' A regular expression string that the system matches against the password to determine whether it complies with a policy. The regular expression uses the ICU syntax (https://unicode-org.github.io/icu/userguide/strings/regexp.html)',
                                help: 'Must not exceed 2048 characters in length.',
                                type: 'string',
                                multiline: true,
                            },
                            passwordContentDescription: {
                                title: 'Password Content Description',
                                description:
                                    'Contains a dictionary of keys for supported OS language IDs (for example, “en-US”), and whose values represent a localized description of the policy enforced by the regular expression.',
                                help: 'Use the special default key can for languages that aren’t contained in the dictionary.',
                                type: 'string',
                                multiline: true,
                            },
                        },
                    },
                },
            },
        },
    },
};
