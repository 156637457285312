/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Highcharts from 'highcharts';
import Highmaps from 'highcharts/highmaps';
import { decimalSeparator, getTheme, isDefined, isNumber, isUndefined, merge, numberFormat, thousandsSeparator } from './core';
import { formatTimestamp } from './date-time';

const TIMELINE_LINE_WIDTH = 2;

const { capasystems } = getTheme();

// Shared configurations for Highmaps and Highcharts
export const sharedHighchartsSettings = {
    global: {
        useUTC: true,
        timezoneOffset: new Date().getTimezoneOffset(),
    },
    chart: {
        backgroundColor: undefined,
        style: {
            fontFamily: capasystems.fontFamily,
        },
    },
    lang: {
        thousandsSep: thousandsSeparator,
        decimalPoint: decimalSeparator,
        numericSymbols: undefined,
    },
    credits: {
        enabled: false,
    },
    exporting: {
        scale: 1,
    },
    noData: {
        useHTML: true,
    },
};

const fontSize = {
    small: '10px',
    medium: '13px',
    large: '14px',
};

declare module 'highcharts' {
    interface Options {
        capasystems?: typeof capasystems;
        fontSize?: typeof fontSize;
    }
}

/**  Set default theme like shadows, colors and sizes for any chart. */
/* istanbul ignore next */
function setTheme() {
    if (isUndefined(Highcharts.theme)) {
        /* istanbul ignore next */
        Highmaps.theme = {
            ...sharedHighchartsSettings,
            legend: {
                enabled: false,
            },
        };

        /* istanbul ignore next */
        Highcharts.theme = {
            ...sharedHighchartsSettings,
            capasystems,
            fontSize,
            navigation: {
                buttonOptions: {
                    enabled: false,
                },
            },
            title: {
                style: {
                    color: capasystems.palette.typography.light,
                    fontSize: fontSize.medium,
                    fontWeight: 'bold',
                },
            },
            subtitle: {
                style: {
                    color: capasystems.palette.typography.light,
                    fontSize: fontSize.small,
                    fontWeight: 'bold',
                },
            },
            plotOptions: {
                arearange: {
                    fillOpacity: 0.25,
                    lineWidth: 0,
                    connectNulls: false,
                    states: {
                        hover: {
                            lineWidth: 0,
                            lineWidthPlus: 0,
                        },
                    },
                    marker: {
                        enabled: true,
                        fillColor: undefined,
                        lineWidth: 1,
                        lineColor: undefined, // inherit from series
                        radius: 1,
                        symbol: 'circle',
                    },
                },
                line: {
                    lineWidth: 1,
                    connectNulls: false,
                    states: {
                        hover: {
                            lineWidth: 3,
                            halo: {
                                size: 1,
                            },
                        },
                    },
                    marker: {
                        fillColor: undefined,
                        lineWidth: 1,
                        lineColor: undefined, // inherit from series
                        enabled: true,
                        radius: 1,
                        symbol: 'circle',
                    },
                },

                series: {
                    shadow: false,
                    marker: {
                        symbol: 'circle',
                    },
                },
                bubble: {
                    states: {
                        hover: {
                            halo: {
                                size: 5,
                            },
                        },
                    },
                    cursor: 'pointer',
                    point: {
                        events: {},
                    },
                    zones: [], // Let widgets decide the zone colors and values.
                    marker: {
                        lineColor: capasystems.borderColor,
                        fillOpacity: 1,
                    },
                    dataLabels: {
                        style: {
                            textOutline: '0px',
                        },
                        enabled: true, // Show values inside the bubbles.
                    },
                },
                pie: {
                    states: {
                        hover: {
                            lineWidth: 1,
                            halo: {
                                size: 5,
                            },
                        },
                    },
                },
            },
            yAxis: {
                gridLineWidth: 1,
                gridLineColor: capasystems.borderColor,
                labels: {
                    style: {
                        fontSize: fontSize.small,
                    },
                },
                title: {
                    style: {
                        fontSize: fontSize.small,
                    },
                },
            },
            xAxis: {
                tickColor: capasystems.borderColor,
                lineColor: capasystems.borderColor,
                labels: {
                    align: 'right',
                    style: {
                        color: capasystems.palette.typography.light,
                        fontSize: fontSize.small,
                    },
                },
            },
            legend: {
                itemStyle: {
                    fontSize: fontSize.small,
                },
            },
            tooltip: {
                useHTML: true,
                borderWidth: 1,
                borderColor: capasystems.borderColor,
                backgroundColor: capasystems.background.paper,
                shadow: false,
            },
        };
        /* istanbul ignore next */
        if (Highcharts.theme.plotOptions?.spline) {
            Highcharts.theme.plotOptions.spline = Highcharts.theme.plotOptions.line;
        }
        /* istanbul ignore next */
        if (Highcharts.theme.plotOptions?.areasplinerange) {
            Highcharts.theme.plotOptions.areasplinerange = Highcharts.theme.plotOptions.arearange;
        }
        /* istanbul ignore next */
        Highcharts.setOptions(Highcharts.theme);
        /* istanbul ignore next */
        Highmaps.setOptions(Highmaps.theme);
    }
}

/* istanbul ignore next */
export function tickPositioner(this: any) {
    const positions = [];
    const numberOfTicks = Math.ceil(this.height) > 400 ? 10 : 5;
    /** Note that 'this' refers to the current HighCharts instance */
    if (this.hasVisibleSeries) {
        if (isNumber(this.max) && isNumber(this.min)) {
            let numberOfDecimals = 0;
            if (this.userOptions.max === null) {
                // The user did not adjust the yAxis manually. Find the best fit.
                const roundings = [1, 10, 100, 1000, 10000, 100000, 1000000];
                let dataMax = Math.max(this.dataMax, isDefined(this.minRange) ? this.minRange : 0);
                const currentMinMaxDiff = dataMax - this.min;
                const currentTickDistance = currentMinMaxDiff <= 0 ? 1 / numberOfTicks : currentMinMaxDiff / numberOfTicks;
                roundings.forEach((value) => {
                    if (currentTickDistance > value) {
                        dataMax = Math.ceil(dataMax / value) * value;
                    }
                });
                if (dataMax > this.dataMax) {
                    this.max = dataMax;
                }
            }
            let minMaxDiff = this.max - this.min;
            if (minMaxDiff < 100) {
                minMaxDiff = Number(minMaxDiff.toPrecision(3));
                if (minMaxDiff % 1 !== 0) {
                    numberOfDecimals = minMaxDiff.toString().split('.')[1].length || 0;
                } else {
                    numberOfDecimals = minMaxDiff.toString().length || 0;
                }
            }
            const tickDistance = minMaxDiff <= 0 ? 1 / numberOfTicks : minMaxDiff / numberOfTicks;
            let showDecimals = false;
            if (tickDistance !== Math.floor(tickDistance)) {
                showDecimals = true;
            }
            if (showDecimals && this.min !== 0) {
                positions.push(parseFloat(this.min.toFixed(numberOfDecimals)));
            } else {
                positions.push(this.min);
            }
            let i = 1;
            while (i <= numberOfTicks) {
                const label = this.min + tickDistance * i;
                if (showDecimals) {
                    positions.push(parseFloat(label.toFixed(numberOfDecimals)));
                } else {
                    positions.push(label);
                }
                // eslint-disable-next-line no-plusplus
                i += 1;
            }
        } else {
            // There is no data attached this axis. Just increment ticks by 1.
            let i = 0;
            while (i <= numberOfTicks) {
                positions.push(i);
                // eslint-disable-next-line no-plusplus
                i += 1;
            }
        }
    }
    return positions;
}

/* istanbul ignore next */
function timelineChartTooltipFormatter(this: any, event: any) {
    const _width = event.chart.chartWidth;
    let _showTooltip = false;
    const _timestamp = this.x;
    let _activeIndex = -1;

    let _tooltip = '<div class="cs-timeline-chart-tooltip-container">';
    _tooltip += `<h3>${formatTimestamp(this.x)}</h3>`;
    _tooltip += '<table cellspacing="8" cellpadding="0">';
    event.chart.series.forEach((series: any) => {
        if (series.visible && series.points && series.points.length > 0) {
            let _point: any;
            series._thresholdExceededWith = 0;
            series.points.forEach((point: any, index: number) => {
                if (point.x === _timestamp) {
                    _point = point;
                    _activeIndex = index;
                }
            });
            if (isUndefined(series.linkedParent) && isDefined(_point) && _point.y !== null) {
                _showTooltip = true;
                _tooltip += `<tr style='color: ${series.color};'>`;
                if (_width > 600) {
                    if (event.chart.hoverPoint.series.name === series.name) {
                        // Add icon
                        _tooltip += '<td class="cs-timeline-chart-tooltip-active-row">&rarr;</td>';
                    } else {
                        _tooltip += '<td></td>';
                    }
                    _tooltip += `<td>${series.name}&nbsp;</td>`;
                }
                if (_width > 400) {
                    series.yAxis.plotLinesAndBands.forEach((plotLine: any) => {
                        if (plotLine.options.linkedTo === series.userOptions.id) {
                            const increase = _point.y - plotLine.options.value;
                            const percentIncrease = (increase / plotLine.options.value) * 100;
                            // eslint-disable-next-line no-param-reassign
                            series._thresholdExceededWith = percentIncrease;
                        }
                    });

                    series.linkedSeries.forEach((linkedSeries: any) => {
                        if (linkedSeries.userOptions.isRange && isDefined(linkedSeries.points)) {
                            const _area = linkedSeries.points[_activeIndex];
                            if (isDefined(_area) && _area.high > _area.low) {
                                _tooltip += `<td class='text-right'>( ${numberFormat(_area.low)}</td>`;
                                _tooltip += '<td> - </td>';
                                _tooltip += `<td>${numberFormat(_area.high)} )&nbsp;</td>`;
                            } else {
                                _tooltip += "<td colspan='3'></td>";
                            }
                        } else if (linkedSeries.userOptions.isDynamicThreshold && isDefined(linkedSeries.xData)) {
                            const thresholdValue = linkedSeries.yData[linkedSeries.xData.indexOf(_timestamp)];
                            if (thresholdValue) {
                                const increase = _point.y - thresholdValue;
                                const percentIncrease = (increase / thresholdValue) * 100;
                                series._thresholdExceededWith = percentIncrease;
                            }
                        }
                    });
                }

                if (isDefined(_point)) {
                    if (isDefined(_point.low) && isDefined(_point.high)) {
                        _tooltip += `<td style='text-align:right;'>${numberFormat(_point.low)}&nbsp; - &nbsp;${numberFormat(_point.high)}</td>`;
                    } else {
                        _tooltip += `<td style='text-align:right;'>${numberFormat(_point.y)}&nbsp;</td>`;
                    }
                }
                if (isDefined(series.yAxis.axisTitle)) {
                    _tooltip += `<td>${series.yAxis.axisTitle.textStr}</td>`;
                }

                if (series._thresholdExceededWith > 0.5) {
                    _tooltip += `<td><span class='cs-timeline-chart-tooltip-badge cs-timeline-chart-tooltip-badge-danger'>&uarr;&nbsp;${numberFormat(
                        series._thresholdExceededWith,
                        0
                    )} %</span></td>`;
                } else if (series._thresholdExceededWith < -0.5) {
                    _tooltip += `<td><span class='cs-timeline-chart-tooltip-badge cs-timeline-chart-tooltip-badge-success'>&darr;&nbsp;${numberFormat(
                        series._thresholdExceededWith,
                        0
                    )} %</span></td>`;
                }
                _tooltip += '</tr>';
            } else {
                // We've got this series covered. Continue.
            }
        }
    });
    _tooltip += '</table></div>';
    if (_showTooltip) {
        return _tooltip;
    }
    return false;
}

export const getTimelineChartLineWidth = (connectPoints: boolean) => (connectPoints ? TIMELINE_LINE_WIDTH : 0);

export const getTimelineChartAreasplinerangeFillOpacity = (connectPoints: boolean) => (connectPoints ? 0.25 : 0);

export const getTimelineChartAreasplinerangeLineWidthPlus = (connectPoints: boolean) => (connectPoints ? 0 : TIMELINE_LINE_WIDTH);

export const getTimelineChartAreasplinerangeOpacity = (connectPoints: boolean) => (connectPoints ? 0.25 : 0);

export const getTimelineChartMarkerRadius = (connectPoints: boolean) => (connectPoints ? TIMELINE_LINE_WIDTH / 2 : TIMELINE_LINE_WIDTH);

export const getTimelineChartLineMarkerEnabled = (connectPoints: boolean, connectNulls: boolean) => connectPoints === false || connectNulls === false;

/* istanbul ignore next */
export function getDefaultTimelineChartConfiguration(hasCategories: boolean, connectPoints: boolean, connectNulls: boolean) {
    setTheme();
    const configuration: Highcharts.Options = {
        chart: {
            animation: false,
            alignTicks: hasCategories,
            resetZoomButton: {
                theme: {
                    display: 'none',
                },
            },
            events: {},
        },
        tooltip: {
            formatter: timelineChartTooltipFormatter,
            padding: 0,
            shared: true,
            hideDelay: 0,
            outside: true,
            distance: 50,
        },
        xAxis: {
            type: 'datetime',
            minRange: 60000, // Zoom in to 1 minute max - see https://api.highcharts.com/highcharts/xAxis.minRange
            startOnTick: true,
            endOnTick: false,
            showLastLabel: true,
            showFirstLabel: false,
            minTickInterval: 60000,
        },
        title: {
            text: undefined,
        },
        plotOptions: {
            series: {
                animation: false,
                states: {
                    hover: {
                        animation: false,
                    },
                },
            },
            spline: {
                lineWidth: getTimelineChartLineWidth(connectPoints),
                pointPlacement: 'on',
                connectNulls: connectPoints && connectNulls,
                states: {
                    hover: {
                        lineWidth: 0,
                        lineWidthPlus: TIMELINE_LINE_WIDTH,
                        halo: {
                            size: 6,
                        },
                    },
                },
                marker: {
                    enabled: getTimelineChartLineMarkerEnabled(connectPoints, connectNulls),
                    symbol: 'circle',
                    lineWidth: 0,
                    radius: getTimelineChartMarkerRadius(connectPoints),
                },
            },
            areasplinerange: {
                fillOpacity: getTimelineChartAreasplinerangeFillOpacity(connectPoints),
                connectNulls: connectPoints && connectNulls,
                states: {
                    hover: {
                        lineWidth: 0,
                        lineWidthPlus: getTimelineChartAreasplinerangeLineWidthPlus(connectPoints),
                        halo: {
                            size: 6,
                        },
                    },
                },
                marker: {
                    enabled: getTimelineChartLineMarkerEnabled(connectPoints, connectNulls),
                    lineWidth: 0,
                    radius: getTimelineChartMarkerRadius(connectPoints),
                },
            },
        },
        yAxis: [],
        series: [],
    };
    if (hasCategories === false) {
        const gridlinesId = 'gridlines';
        /** This will align gridlines inside the plotArea no matter how many yAxes we have. */

        // check if yAxis is array
        if (Array.isArray(configuration.yAxis)) {
            configuration.yAxis?.push({
                id: gridlinesId,
                tickmarkPlacement: 'on',
                showEmpty: true,
                title: {
                    text: null,
                },
                min: 0,
                max: 100,
                labels: {
                    enabled: false,
                },
                gridLineWidth: 1,
                opposite: false,
                tickPositioner,
            });
        }

        if (Array.isArray(configuration.series)) {
            configuration.series.push({
                showInLegend: false,
                yAxis: gridlinesId,
                data: [],
                type: 'spline',
            });
        }
    }
    return configuration;
}

/* istanbul ignore next */
export function getDefaultGaugeConfiguration() {
    setTheme();
    const configuration = {
        chart: {
            renderTo: 'container',
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            spacingLeft: 10,
            spacingRight: 0,
            spacingBottom: 0,
            marginBottom: 0,
        },
        plotOptions: {
            series: {
                stickyTracking: false,
                overshoot: 10,
                dataLabels: {
                    y: 80,
                    formatter(this: any) {
                        const { unit } = this.series.yAxis.userOptions;
                        const seriesData: any[] = [];
                        this.series.yAxis.series.map((_value: any, index: number) => {
                            if (this.series.yAxis.series[index].yData.length === 1) {
                                seriesData.push(`${this.series.yAxis.series[index].yData[0]} ${unit}`);
                            }
                            return seriesData;
                        });
                        return `<span>${seriesData.join('<br>')}</span>`;
                    },
                },
            },
        },
        tooltip: {
            valueSuffix: '',
            shared: false,
            valueDecimals: 0,
        },

        title: {
            text: '',
        },

        pane: {
            size: '100%',
            startAngle: -130,
            endAngle: 130,
            borderWidth: 1,
            background: [
                {
                    backgroundColor: capasystems.background.paper,
                    borderWidth: 0,
                    outerRadius: '100%',
                    innerRadius: '88%',
                },
            ],
        },
        yAxis: {
            gridLineColor: 'transparent',
            min: 0,
            max: 100,
            plotBands: [
                {
                    thickness: 18,
                },
            ],
            minorTickLength: 18,
            minorTickColor: '#666',
            tickWidth: 3,
            tickLength: 18,
            tickColor: '#666',
            labels: {
                distance: -35,
                step: 2,
                rotation: 'auto',
            },
            title: {
                text: '',
            },
        },

        series: [],
    };
    return configuration;
}

/* istanbul ignore next */
export function getDefaultHeatmapConfiguration() {
    setTheme();
    const configuration = {
        chart: {
            type: 'heatmap',
            animation: false,
            events: {
                drillup: () => null,
                drilldown: () => null,
            },
        },
        plotOptions: {
            heatmap: {
                shadow: false,
                borderColor: capasystems.borderColor,
                borderWidth: 1,
            },
        },
        title: {
            text: null,
        },
        xAxis: {
            tickLength: 0,
            opposite: true,
            title: {
                enabled: true,
                text: 'X',
                useHTML: true,
                margin: 20,
                style: {
                    fontSize: Highcharts.theme.fontSize?.large,
                },
            },
            categories: [],
            labels: {
                useHTML: false,
                style: {
                    textAlign: 'right',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: Highcharts.theme.capasystems?.palette.primary.dark,
                },
            },
        },
        yAxis: {
            title: {
                enabled: true,
                text: 'Y',
                useHTML: true,
                margin: 20,
                style: {
                    fontSize: Highcharts.theme.fontSize?.large,
                },
            },
            categories: [],
            labels: {
                useHTML: true,
                style: {
                    textAlign: 'right',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: Highcharts.theme.capasystems?.palette.primary.dark,
                },
                step: 1,
            },
        },
        colorAxis: {
            min: 0,
            dataClasses: [
                {
                    to: 0,
                    color: capasystems.severity.critical,
                },
                {
                    from: 1,
                    to: 1,
                    color: capasystems.severity.success,
                },
                {
                    from: 2,
                    to: 2,
                    color: capasystems.severity.minor,
                },
                {
                    from: 3,
                    to: 3,
                    color: capasystems.severity.neutral,
                },
            ],
            labels: {
                enabled: false,
            },
        },
        tooltip: {
            useHTML: true,
            style: {
                padding: 0,
            },
            borderWidth: 1,
            backgroundColor: capasystems.background.paper,
            shadow: false,
            formatter(this: any) {
                const { timestamp } = this.point;
                if (isDefined(timestamp) && timestamp !== 0) {
                    return `<div class='cs-chart-tooltip-container'>${Highcharts.dateFormat('%H:%M:%S, %b %e %Y', timestamp)}</div>`;
                }
                return false;
            },
        },
        drilldown: {
            series: [],
            activeAxisLabelStyle: {
                textDecoration: 'none',
            },
        },
    };
    return configuration;
}

/* istanbul ignore next */
export function getDefaultSunburstConfiguration(rootId: any, data: any, onClick: () => void) {
    setTheme();
    const configuration = {
        chart: {
            type: 'sunburst',
        },
        title: {
            text: null,
        },
        subtitle: {
            text: null,
        },
        tooltip: {
            headerFormat: '{point.key} ',
            pointFormat: '{point.value}',
        },
        series: [
            {
                turboThreshold: 0,
                allowTraversingTree: true,
                cursor: 'pointer',
                data,
                events: {},
                rootId,
            },
        ],
        plotOptions: {
            sunburst: {
                events: {
                    click: onClick,
                },
                dataLabels: {
                    style: {
                        color: '#000000',
                        textOutline: undefined,
                        fontSize: 14,
                        fontWeight: 700,
                    },
                },
            },
        },
    };
    return configuration;
}

const luminanace = (r: number, g: number, b: number) => {
    const a = [r, g, b].map((v) => {
        // eslint-disable-next-line no-param-reassign
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const getContrastRatio = (rgb1: number[], rgb2: number[]) => {
    const lum1 = luminanace(rgb1[0], rgb1[1], rgb1[2]);
    const lum2 = luminanace(rgb2[0], rgb2[1], rgb2[2]);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
};

/**
 * Generates a pseudo-random color from an input string.
 * For any given string, the generated color will always be the same, like a hash function.
 */
export const colorHash = (inputString: any, configurationOverrides = {}): any => {
    const { minContrastRatio, alphaChannel, backgroundRGB, maxTryCount, fallback } = merge(
        {},
        {
            alphaChannel: 1,
            backgroundRGB: [255, 255, 255],
            minContrastRatio: 3,
            maxTryCount: 5,
            fallback: {
                bestContrastRatio: 0,
                basedOn: '',
            },
        },
        configurationOverrides
    ); // Treats arrays like objects.

    let sum = 0;
    for (let i = 0; i < inputString.length; i += 1) {
        sum += inputString.charCodeAt(i);
    }
    // eslint-disable-next-line no-bitwise
    const r = ~~(
        Number(
            `0.${Math.sin(sum + 1)
                .toString()
                .substr(6)}`
        ) * 256
    );
    // eslint-disable-next-line no-bitwise
    const g = ~~(
        Number(
            `0.${Math.sin(sum + 2)
                .toString()
                .substr(6)}`
        ) * 256
    );
    // eslint-disable-next-line no-bitwise
    const b = ~~(
        Number(
            `0.${Math.sin(sum + 3)
                .toString()
                .substr(6)}`
        ) * 256
    );
    const currentContrastRatio = getContrastRatio(backgroundRGB, [r, g, b]);
    if (minContrastRatio > currentContrastRatio) {
        const { bestContrastRatio, basedOn } = fallback;
        if (maxTryCount > 0) {
            const SUFFIX = '*';
            return colorHash(`${inputString}${SUFFIX}`, {
                minContrastRatio,
                alphaChannel,
                backgroundRGB,
                maxTryCount: maxTryCount - 1,
                fallback: {
                    bestContrastRatio: bestContrastRatio > currentContrastRatio ? bestContrastRatio : currentContrastRatio,
                    basedOn: bestContrastRatio > currentContrastRatio ? basedOn : inputString,
                },
            });
        }
        if (bestContrastRatio > currentContrastRatio) {
            return colorHash(basedOn, {
                minContrastRatio: 1,
                alphaChannel,
                backgroundRGB,
                maxTryCount,
                fallback,
            });
        }
    }
    const rgb = `rgb(${r}, ${g}, ${b})`;
    const rgba = `rgba(${r}, ${g}, ${b}, ${alphaChannel})`;

    let hex = '#';
    hex += `00${r.toString(16)}`.substr(-2, 2).toUpperCase();
    hex += `00${g.toString(16)}`.substr(-2, 2).toUpperCase();
    hex += `00${b.toString(16)}`.substr(-2, 2).toUpperCase();
    return {
        r,
        g,
        b,
        rgb,
        rgba,
        hex,
    };
};

/* export function stringToHex(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += (`00${value.toString(16)}`).substr(-2);
    }
    return color;
} */
