import { Highcharts } from '@capasystems/ui';
import React, { useEffect, useState } from 'react';
import colors from 'tailwindcss/colors';
import { chartDefaults, chartFormatters } from '../constants-with-dependencies/constants-with-dependencies';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { useApi, useNavigate } from './../../index';

export type DevicesSoftwareComplianceProps = {
    className?: string;
};

export const DevicesSoftwareCompliance: React.FC<DevicesSoftwareComplianceProps> = ({ className = 'tw-h-96', ...rest }) => {
    const api = useApi();
    const navigate = useNavigate();
    const [devices, setDevices] = useState<any[]>([]);
    const [widgetPaperProps, setWidgetPaperProps] = useState<{ loading?: boolean; errorMessage?: string }>({
        loading: true,
    });

    const showCompliantDevices = () => navigate.to(`windows/device/list?software.compliant=true`);

    const showNonCompliantDevices = () => navigate.to(`windows/device/list?software.compliant=false`);

    useEffect(() => {
        api.getDevicesSoftwareCompliance()
            .then((response) => {
                setDevices(response);
                setWidgetPaperProps({});
            })
            .catch(() => {
                setWidgetPaperProps({
                    errorMessage: 'Could not load Software status table',
                });
            });
    }, [api]);

    const createSeverityData = () => {
        const tmpSeverityArray: any[] = [];
        const tmpSeverityData: any = {};
        devices
            .filter(({ severity }) => severity !== undefined)
            .forEach((device) => {
                if (!tmpSeverityData[device.severity]) {
                    tmpSeverityData[device.severity] = 1;
                } else {
                    tmpSeverityData[device.severity] += 1;
                }
            });
        Object.keys(tmpSeverityData).forEach((key) => {
            tmpSeverityArray.push({
                severity: key,
                count: tmpSeverityData[key],
            });
        });
        return tmpSeverityArray;
    };

    const deviceCount = devices.length;
    const compliantCount = devices.filter(({ softwareCompliant }) => softwareCompliant).length;
    const nonCompliantCount = deviceCount - compliantCount;
    const severityData = createSeverityData();

    return (
        <WidgetPaper
            // @ts-ignore - ts is not typed
            title="Software status"
            // @ts-ignore - ts is not typed
            description="Overview of endpoint software status"
            className={className}
            {...rest}
            {...widgetPaperProps}
        >
            <ChartArea
                deviceCount={deviceCount}
                compliantCount={compliantCount}
                nonCompliantCount={nonCompliantCount}
                showCompliantDevices={showCompliantDevices}
                showNonCompliantDevices={showNonCompliantDevices}
                severityData={severityData}
            />
        </WidgetPaper>
    );
};

type ChartAreaProps = {
    deviceCount: number;
    compliantCount: number;
    nonCompliantCount: number;
    showNonCompliantDevices: () => void;
    showCompliantDevices: () => void;
    severityData: any[];
};

const ChartArea: React.FC<ChartAreaProps> = ({
    deviceCount,
    compliantCount,
    nonCompliantCount,
    showNonCompliantDevices,
    showCompliantDevices,
    severityData,
}) => {
    let subtitleText = null;
    let compliancePercentage = 100;
    if (deviceCount > 0) {
        compliancePercentage = Math.round((compliantCount / deviceCount) * 100);
        subtitleText = `${compliancePercentage}%<br/><span style="visibility: hidden; font-size: 8px;">.</span><br/><span style="font-size: .65em; font-weight: 600;">up-to-date</span>`;
    }

    const showCompliantData = [
        {
            name: 'Up-to-date on software',
            id: 'Up-to-date',
            y: compliantCount,
            color: colors.emerald[400],
            events: {
                legendItemClick(e: any) {
                    e.preventDefault();
                    setTimeout(showCompliantDevices);
                },
                click: showCompliantDevices,
            },
        },
        {
            name: 'Not up-to-date on software',
            id: 'Not Up-to-date',
            visible: compliancePercentage < 100,
            y: nonCompliantCount,
            color: colors.red[400],
            borderWidth: 0,
            events: {
                legendItemClick(e: any) {
                    e.preventDefault();
                    setTimeout(showNonCompliantDevices);
                },
                click: showNonCompliantDevices,
            },
        },
    ];

    return (
        <div className="tw-h-full tw-w-full tw-overflow-hidden">
            <Highcharts
                // @ts-ignore - ts is not typed
                options={{
                    chart: chartDefaults.pie.chart,
                    plotOptions: {
                        pie: chartDefaults.pie.plotOptions,
                    },
                    title: {
                        text: null,
                    },
                    subtitle: {
                        text: subtitleText,
                        align: 'center',
                        verticalAlign: 'middle',
                        style: {
                            fontSize: '1.2rem',
                            fontWeight: 600,
                        },
                        y: 24,
                        x: -122,
                    },
                    credits: { enabled: false },
                    legend: {
                        ...chartDefaults.pie.legend,
                        labelFormatter: chartFormatters.pie.legend.labelFormatter('endpoint'),
                        itemMarginTop: 16,
                        itemMarginBottom: 16,
                        width: 220,
                    },
                    responsive: {
                        rules: [
                            {
                                condition: {
                                    maxWidth: 450,
                                },
                                chartOptions: {
                                    legend: {
                                        enabled: false,
                                    },
                                    subtitle: {
                                        x: 0,
                                    },
                                },
                            },
                        ],
                    },
                    tooltip: {
                        ...chartDefaults.pie.tooltip,
                        formatter: chartFormatters.pie.tooltip.formatter('endpoint'),
                    },
                    series: [
                        {
                            name: 'Endpoints',
                            sliced: true,
                            cursor: 'pointer',
                            data: showCompliantData,
                        },
                    ],
                }}
            />
        </div>
    );
};

export default DevicesSoftwareCompliance;
