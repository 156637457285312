import FormControlLabel from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import React from 'react';

const radioButtonClasses = {
    root: 'cs-radio-button',
    checked: 'cs-radio-checked',
    disabled: 'cs-radio-disabled',
    colorPrimary: 'cs-radio-color-primary',
    colorSecondary: 'cs-radio-color-secondary',
};

const formControlLabelClasses = {
    root: 'cs-radio-button-label-container',
    disabled: 'cs-radio-button-label-disabled',
    label: 'cs-radio-button-label',
};

export type RadioButtonProps = RadioProps & {
    /** The value of the radio button */
    value: any;
    /** The label of the radio button */
    label?: React.ReactNode;
    /** The color of the radio button */
    color?: 'primary' | 'secondary';
    /** If true, the radio button will be disabled */
    disabled?: boolean;
    /** The placement of the label */
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
};

/** RadioButton component - Props for RadioGroup can be seen at the RadioGroup example */
export const RadioButton: React.FC<RadioButtonProps> = ({ value, label, labelPlacement = 'end', disabled = false, color = 'primary', ...otherProps }) => (
    <FormControlLabel
        value={value}
        label={label}
        classes={formControlLabelClasses}
        control={
            <Radio
                classes={radioButtonClasses}
                color={color}
                disabled={disabled}
                {...otherProps}
            />
        }
        labelPlacement={labelPlacement}
    />
);

export default RadioButton;
