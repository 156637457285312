import { LayoutCentered, Loading, Page, Paper, Tab, Tabs, useParams } from '@capasystems/ui';
import { Url, isDefined } from '@capasystems/utils';
import { TAndroidConfigurationWithId } from '@db/party';
import {
    AndroidApplicationRulesConfiguration,
    AndroidBluetoothConfiguration,
    AndroidCellularConfiguration,
    AndroidConnectivityManagementConfiguration,
    AndroidCrossProfileConfiguration,
    AndroidKioskConfiguration,
    AndroidLocationConfiguration,
    AndroidNetworkConfiguration,
    AndroidPasswordConfiguration,
    AndroidPersistentPreferredActivitiesConfiguration,
    AndroidPersonalUsageConfiguration,
    AndroidPlaystoreConfiguration,
    AndroidReportingConfiguration,
    AndroidRestrictionsConfiguration,
    AndroidSecurityConfiguration,
    AndroidSystemConfiguration,
    AndroidVpnConfiguration,
    AndroidWifiConfiguration,
} from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { default as classNames } from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder, SchemaBuilder } from '../../../index';
import { useAndroidApi } from '../../hooks/useApi/useApi';

type TConfigurationType = {
    title: string;
    type: string;
    category: string;
    schemaId: string;
    properties: any;
};

const configurationTypes: TConfigurationType[] = [
    AndroidApplicationRulesConfiguration.schema,
    AndroidBluetoothConfiguration.schema,
    AndroidCellularConfiguration.schema,
    AndroidCrossProfileConfiguration.schema,
    AndroidKioskConfiguration.schema,
    AndroidLocationConfiguration.schema,
    AndroidNetworkConfiguration.schema,
    AndroidPasswordConfiguration.schema,
    AndroidPersistentPreferredActivitiesConfiguration.schema,
    AndroidPersonalUsageConfiguration.schema,
    AndroidPlaystoreConfiguration.schema,
    AndroidReportingConfiguration.schema,
    AndroidRestrictionsConfiguration.schema,
    AndroidSecurityConfiguration.schema,
    AndroidSystemConfiguration.schema,
    AndroidVpnConfiguration.schema,
    AndroidWifiConfiguration.schema,
    AndroidConnectivityManagementConfiguration.schema,
].sort((a, b) => {
    const categoryCalc = a.category.toLocaleLowerCase().localeCompare(b.category.toLocaleLowerCase());
    if (categoryCalc === 0) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
        return categoryCalc;
    }
});

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Search Configuration' });

export type AndroidConfigurationSummaryProps = {
    configuration: TAndroidConfigurationWithId;
};

const AndroidConfigurationSummary: React.FC<AndroidConfigurationSummaryProps> = ({ configuration }) => {
    const androidApi = useAndroidApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const { configurationId } = useParams();
    const [searchTerm, setSearchTerm] = useState();
    const [selectedTab, setSelectedTab] = useState(() => {
        return configurationTypes[0].schemaId;
    });
    const [loading, setLoading] = useState(true);
    const [schemaBuilderSchema, setSchemaBuilderSchema] = useState<TConfigurationType | undefined>(
        configurationTypes.find((cc) => cc.schemaId === selectedTab) || {
            title: 'Not found',
            category: 'unkown',
            schemaId: 'none',
            type: 'none',
            properties: {},
        }
    );
    const queryBuilderRef = useRef({});

    const changeTab = (_: any, tab: string) => {
        const configurationType = configurationTypes.find((cc) => cc.schemaId === tab);
        setSchemaBuilderSchema(configurationType);
        Url.set('schemaId', tab);
        setSelectedTab(tab);
    };

    const propertiesBuilderRef = useRef({});

    const onSubmitSearch = ([activeLeaf]: [activeLeaf: any]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    useEffect(() => {
        if (isDefined(configurationId)) {
            propertiesBuilderRef.current = configuration.data || {};

            const keys = Object.keys(propertiesBuilderRef.current);
            const matchingConfigurationType = configurationTypes.find((ct) => {
                return keys.some((key) => Object.keys(ct.properties).includes(key));
            });

            if (matchingConfigurationType) {
                setSchemaBuilderSchema(matchingConfigurationType);
                Url.set('schemaId', matchingConfigurationType.schemaId);
                setSelectedTab(matchingConfigurationType.schemaId);
            }
        }
        
        const checkPropertiesBuilderRef = () => {
            if (propertiesBuilderRef.current) {
                setLoading(false);
            }
        };

        checkPropertiesBuilderRef();
    }, [androidApi, configurationId]);


    const propertiesBuilderRefKeys = Object.keys(propertiesBuilderRef.current);

    const memorizedTabs = useMemo(() => {
        const tabs: any[] = [];
        Object.entries(Object.groupBy(configurationTypes, (ct) => ct.category))
            .sort(([keyA], [keyB]) => keyA.toLocaleLowerCase().localeCompare(keyB.toLocaleLowerCase()))
            .forEach(([key, ctArray]) => {
                const categoryMatches = (key || '').toLowerCase().includes((searchTerm || '').toLowerCase());

                const sortedCtArray = ctArray?.filter(
                    (ct) =>
                        Object.keys(ct.properties).some((propertiesKey) => propertiesBuilderRefKeys.includes(propertiesKey)) &&
                        ((ct.title || '').toLowerCase().includes((searchTerm || '').toLowerCase()) || categoryMatches)
                );

                if (sortedCtArray && sortedCtArray.length > 0) {
                    tabs.push(
                        <Tab
                            value={key}
                            disabled
                            className={classNames('tw-mb-1 tw-min-h-0 tw-py-0 tw-opacity-100', { 'tw-mt-2': tabs.length === 0, 'tw-mt-6': tabs.length !== 0 })}
                            label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{key}</div>}
                            key={'cat - ' + key}
                        />
                    );

                    sortedCtArray.forEach((ct) => {
                        tabs.push(
                            <Tab
                                value={ct.schemaId}
                                classes={{ root: 'tw-pl-4' }}
                                label={
                                    <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                        <div className="tw-mr-2 tw-text-left">{ct.title}</div>
                                    </div>
                                }
                                key={'tab - ' + ct.title}
                                disableRipple
                            />
                        );
                    });
                }
            });
        return tabs;
    }, [propertiesBuilderRefKeys, searchTerm]);
    
    return (
        <Page title={'Configuration · Summary'}>
            {loading ? (
                <LayoutCentered>
                    <Loading />
                </LayoutCentered>
            ) : !propertiesBuilderRef.current || Object.keys(propertiesBuilderRef.current).length === 0 ? (
                <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                    <div className="tw-p-6 tw-font-semibold">No configuration data found</div>
                </div>
            ) : (
                <div className="tw-col-span-1 tw-flex tw-h-full">
                    <div className="tw-col-span-3"></div>
                    <div>
                        {errorMessage !== null && (
                            <div className="tw-col-span-3 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                        )}
                    </div>
                    <div
                        className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-y-4"
                        style={{ minWidth: 275 }}
                        >
                        {/* @ts-ignore - QueryBuilder is not typed */}
                        <QueryBuilder
                            // @ts-ignore - QueryBuilder is not typed
                            defaultConfiguration={queryBuilderConfiguration}
                            onInit={onSubmitSearch}
                            onSubmit={onSubmitSearch}
                            className="tw-mx-auto tw-w-full"
                            ref={queryBuilderRef}
                            />
                        <div className="tw-w-[275px] tw-overflow-auto">
                            <Tabs
                                value={selectedTab}
                                onChange={changeTab}
                                orientation="vertical"
                                pills
                                className="tw-h-full"
                                variant="scrollable"
                                >
                                {memorizedTabs}
                            </Tabs>
                        </div>
                    </div>
                    <Paper
                        className="tw-h-full tw-overflow-auto tw-shadow-sm"
                        style={{ flexGrow: 1, marginLeft: '20px' }}
                        >
                        <SchemaBuilder
                            key={selectedTab}
                            schema={schemaBuilderSchema}
                            currentState={propertiesBuilderRef.current}
                            readOnly
                            />
                    </Paper>
                </div>
            )}
        </Page>
    );
};

export { AndroidConfigurationSummary };

