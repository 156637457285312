import { isBeta, isLocalHost, SORT_DIRECTION } from '@capasystems/constants';
import { api, getUniqueId, now } from '@capasystems/utils';
import { LEAF_ID, windowsApplication } from '@thirdparty/constants';
import { stripRequiredEdges, stripRequiredNodes } from '@thirdparty/utils';
import cronstrue from 'cronstrue/i18n';
import dayjs from 'dayjs';

const CAPAONE_API_ROOT = isBeta ? 'https://apibeta.capaone.com' : 'https://api.capaone.com';

const CAPI_ROOT = isLocalHost ? '' : isBeta ? 'https://capibeta.capaone.com' : 'https://capi.capaone.com';

const MOCK_BASE_URL = 'https://dashbeta.capaone.com/api/mock';

const expirationTimestamps: any = {};
class Connection {
    abortController: AbortController;
    connectionID: string;

    constructor() {
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('CapaOneConnectionID');
    }

    /** GET */
    getProfile = () => this.get('login');

    getPlaylistItems = () => this.get(`${CAPAONE_API_ROOT}/youtube/playlist`, {}, true, false);

    getDevices = (filters: any = {}, cache = false) => this.get(`device/list`, filters, cache);

    getWindowsDeviceCountBasedOnFilter = (filters: any = {}) =>
        this.get(`device/list`, { pageSize: 1, filter: JSON.stringify(filters) }).then((response: any) => response.totalElements);

    getDevicesStatus = () => this.get('device/dashboard/status');

    getDevicesOs = (detail: any) =>
        this.get('device/dashboard/os', {
            detail,
        });

    getDevice = (id: string) => this.get(`device/${id}`);

    getPolicies = () => this.get(`${MOCK_BASE_URL}/policy`);

    getPolicy = (id: string) => this.get(`${MOCK_BASE_URL}/policy/${id}`);

    getOrganizations = () => this.get('org');

    getApplications = (filters: any = {}) => this.get('application', filters);

    getApplicationInstallationsInProgress = (filters: any = {}) => this.get('application/progress', filters);

    getApplication = (applicationId: string) => this.get(`application/${applicationId}`);

    getApplicationAndDeviceMatches = (applicationId: string) => this.get(`application/match/${applicationId}`);

    getDeviceSoftwareInventory = (deviceId: string) => this.get(`device/${deviceId}/inventory/software`);

    getDeviceHardwareInventory = (deviceId: string) => this.get(`device/${deviceId}/inventory/hardware`);

    getDeviceSecuritySummary = (deviceId: string) => this.get(`device/${deviceId}/inventory/security`);

    getDeviceDashbordSecurityInformation = (deviceId: string) => this.get(`/device/${deviceId}/dashboard/security`);

    getDeviceDriverInventory = (deviceId: string) => this.get(`device/${deviceId}/inventory/driver`);

    getDevicePatchingInventory = (deviceId: string, filters: any) => this.get(`device/${deviceId}/patch`, filters);

    getDeviceDriverPatchingInventory = (deviceId: string) => this.get(`device/${deviceId}/driver/patch`);

    getDriverInstallationsInProgress = (filters: any = {}) => this.get('driver/progress', filters);

    getRealWorkflows = () => this.get('workflow');

    getRealWorkflowWithId = (id: any) =>
        this.get(`workflow/${id}`).then(async (wf) => {
            const required = [];
            if (wf.type === 'schedule') {
                required.push({
                    id: 'required_schedule',
                    position: {
                        x: wf.nodes[0] ? wf.nodes[0].position.x : 484,
                        y: wf.nodes[0] ? wf.nodes[0].position.y - 250 : 0,
                    },
                    category: 'trigger',
                    type: 'schedule',
                    configuration: wf.configuration,
                    name: 'Schedule',
                    description: cronstrue.toString(wf.configuration.cronExpression, {
                        use24HourTimeFormat: true,
                        locale: 'en',
                        verbose: true,
                    }),
                });
                if (!wf.edges.find((e: any) => e.source === 'required_schedule')) {
                    wf.edges.push({ source: 'required_schedule', target: 'required_filter', label: 'required' });
                }
            }

            const filters = await this.getDeviceFilters();

            if (wf.filterId) {
                const exists = filters.find((fi: any) => fi.id === wf.filterId);
                if (exists) {
                    required.push({
                        id: 'required_filter',
                        position: {
                            x: wf.nodes[0] ? wf.nodes[0].position.x : 484,
                            y: wf.nodes[0] ? wf.nodes[0].position.y - 125 : 150,
                        },
                        category: 'filter',
                        type: 'filter_device',
                        configuration: {
                            filterId: wf.filterId,
                        },
                        name: 'Filter',
                        description: exists.name,
                    });
                }
            } else {
                required.push({
                    id: 'required_filter',
                    position: {
                        x: wf.nodes[0] ? wf.nodes[0].position.x : 484,
                        y: wf.nodes[0] ? wf.nodes[0].position.y - 125 : 150,
                    },
                    category: 'filter',
                    type: 'filter_device',
                    configuration: {
                        filterId: wf.filterId,
                    },
                    name: 'Filter',
                    description: 'All',
                });
            }

            if (wf.nodes.length > 0 && !wf.edges.find((e: any) => e.source === 'required_filter')) {
                wf.edges.push({ source: 'required_filter', target: wf.nodes[0]?.id });
            }

            wf.nodes = [...required, ...wf.nodes];

            return wf;
        });

    getRealWorkflowItems = (id: string) => this.get('workflowItem');

    getWorkflowWithStage = (id: string) => this.get(`${MOCK_BASE_URL}/workflowWithStages/${id}`);

    getWorkflowStages = () => this.get(`${MOCK_BASE_URL}/workflowStage`);

    getWorkflowItems = () => this.get(`${MOCK_BASE_URL}/workflowItem`);

    getWorkflowItem = (workflowItemId: string) => this.get(`${MOCK_BASE_URL}/workflowItem/${workflowItemId}`);

    getSystemInfo = () => this.get('system/info');

    getDevicePatchingLogFiles = (deviceId: string, cmdId: string) => this.get(`file/logs/${deviceId}/${cmdId}/ls`);

    getDevicePatchingLogFile = (deviceId: string, cmdId: string, filename: string) => this.get(`file/logs/${deviceId}/${cmdId}/download/${filename}`);

    getDeviceLog = (deviceId: string) =>
        this.get(`device/${deviceId}/log`, {
            // size: null,
            // page: null,
            orderBy: `date,${SORT_DIRECTION.DESC}`,
        });

    getTags = () => this.get('tag');

    getTagsByCategory = () =>
        this.getTags().then(({ content }) => {
            const uniqueCategories = [...new Set(content.map((tag: any) => tag.category))]
                .filter((p) => p !== '')
                .sort()
                .map((p: any) => ({
                    name: p.toString(),
                    id: p.replace(/\s/g, '').toLocaleLowerCase(),
                    options: content.filter((tag: any) => tag.category === p),
                    selectedOptions: [],
                    searchTerm: '',
                }));
            return uniqueCategories;
        });

    getSoftwareCompliance = () => this.get('dashboard/software/compliance');

    getSoftwareDashboard = (filter: any) => this.get('dashboard/software', { filter });

    getDevicesSoftwareCompliance = () => this.get('dashboard/device/compliance');

    getDevicesDriverCompliance = () => this.get('dashboard/driver/compliance');

    getDevicesDriverSupported = () => this.get('dashboard/driver/models/supported');

    getDevicesDriverList = (input: any) => this.get('dashboard/driver/models', input);

    getMockEventBubbleChartOverview = (type: string) => this.get(`${MOCK_BASE_URL}/eventBubbleChartSingle/${type}`);

    getMockEventBubbleChart = () => this.get(`${MOCK_BASE_URL}/eventBubblechart`);

    getMockEventTable = () => this.get(`${MOCK_BASE_URL}/eventTable`);

    getMockEventTableDevice = () => this.get(`${MOCK_BASE_URL}/eventTableDevice`);

    getApplicationInProgress = () => this.get('/dashboard/software/progress');

    getEventChartOverview = (
        timestampInterval: number,
        timezone: string,
        sampleInterval: number,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get('event/overview/barchart', {
            timestampInterval,
            timezone,
            sampleInterval,
            logType,
            source,
            eventId,
            search,
        });

    getEventChartDevice = (
        deviceId: string,
        timestampInterval: number,
        timezone: string,
        sampleInterval: number,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/overview/agent/barchart/${deviceId}`, {
            timestampInterval,
            timezone,
            sampleInterval,
            logType,
            source,
            eventId,
            search,
        });

    getEventChartSeverity = (
        timestampInterval: number,
        timezone: string,
        sampleInterval: number,
        severityId: number,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/severity/barchart/${severityId}`, {
            timestampInterval,
            timezone,
            sampleInterval,
            logType,
            source,
            eventId,
            search,
        });

    getEventChartSeverityDevice = (
        deviceId: string,
        timestampInterval: number,
        timezone: string,
        sampleInterval: number,
        severityId: string,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/severity/agent/barchart/${severityId}/${deviceId}`, {
            timestampInterval,
            timezone,
            sampleInterval,
            logType,
            source,
            eventId,
            search,
        });

    getEventDetailedTable = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        pageNumber: number,
        pageSize: number,
        logType: string,
        source: string,
        eventId: string,
        search: string,
        orderBy: string,
    ) =>
        this.get(`event/severity/eventtable/${severityId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            pageNumber,
            pageSize,
            logType,
            source,
            eventId,
            search,
            orderBy,
        });

    getEventDetailedTableDevice = (
        deviceId: string,
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        pageNumber: number,
        pageSize: number,
        logType: string,
        source: string,
        eventId: string,
        search: string,
        orderBy: string,
    ) =>
        this.get(`event/overview/agent/eventtable/${deviceId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            pageNumber,
            pageSize,
            logType,
            source,
            eventId,
            search,
            orderBy,
        });

    getEventDetailedTableSeverityDevice = (
        deviceId: string,
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        pageNumber: number,
        pageSize: number,
        logType: string,
        source: string,
        eventId: string,
        search: string,
        orderBy: string,
    ) =>
        this.get(`event/severity/agent/eventtable/${severityId}/${deviceId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            pageNumber,
            pageSize,
            logType,
            source,
            eventId,
            search,
            orderBy,
        });

    getEventPieChart = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/severity/piechart/${severityId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            logType,
            source,
            eventId,
            search,
        });

    getEventPieChartDevice = (
        deviceId: string,
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/severity/agent/piechart/${severityId}/${deviceId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            logType,
            source,
            eventId,
            search,
        });

    getEventTopAffected = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/overview/severity/table/${severityId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            logType,
            source,
            eventId,
            search,
        });

    getEventTypeOverview = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/overview/severity/count/${severityId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            logType,
            source,
            eventId,
            search,
        });

    getEventTypeOverviewDevice = (
        deviceId: string,
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        severityId: string,
        logType: string,
        source: string,
        eventId: string,
        search: string,
    ) =>
        this.get(`event/overview/agent/severity/count/${severityId}/${deviceId}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            logType,
            source,
            eventId,
            search,
        });

    getEventOverviewReliabilityPieChart = () => this.get('event/overview/reliability/piechart');

    getEventOverviewReliabilityDeviceList = (
        timestampInterval: number,
        timezone: string,
        sampleInterval: number,
        rangeStart: number,
        rangeEnd: number,
        pageNumber: number,
        pageSize: number,
    ) =>
        this.get('event/overview/reliability/table', {
            timestampInterval,
            timezone,
            sampleInterval,
            rangeStart,
            rangeEnd,
            pageNumber,
            pageSize,
        });

    getEventOverviewReliabilityChart = (timestampInterval: number, timezone: string, sampleInterval: number) =>
        this.get('event/overview/reliability/chart', {
            timestampInterval,
            timezone,
            sampleInterval,
        });

    getEventReliabilityScoreDevice = (deviceId: string, timezone: string) =>
        this.get(`event/overview/agent/reliability/piechart/${deviceId}`, {
            timezone,
        });

    getEventReliabilityChartDevice = (deviceId: string, timestampInterval: number, timezone: string, sampleInterval: number) =>
        this.get(`event/overview/agent/reliability/chart/${deviceId}`, {
            timestampInterval,
            timezone,
            sampleInterval,
        });

    getEventFilters = (type: string, timestampInterval: number, timezone: string) =>
        this.get(`filter/${type}`, {
            timestampInterval,
            timezone,
        });

    getDeviceFilterSuggestions = ({ key, search }: { key: string; search: string }) =>
        this.get(
            'filter/suggestion',
            {
                key,
                search,
            },
            true,
        );

    getDeviceFilters = () => this.get('device/filter');

    getDeviceFilter = (id: string) => this.get(`device/filter/${id}`);

    getConfigurations = (search: string) =>
        this.get('configuration', {
            search,
        });

    getConfiguration = (id: string) => this.get(`configuration/${id}`);

    getDeviceLogList = (id: string) => this.get(`file/logs/${id}/list`);

    getAodApplicationsChart = (timestampInterval: number, sampleInterval: number, timezone: string, type: string, activeFilters: any) =>
        this.get('adminondemand/charts/application', {
            timestampInterval,
            sampleInterval,
            type,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodUsersChart = (timestampInterval: number, sampleInterval: number, timezone: string, activeFilters: any) =>
        this.get('adminondemand/charts/user', {
            timestampInterval,
            sampleInterval,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodSessionUsersChart = (timestampInterval: number, sampleInterval: number, timezone: string, activeFilters: any) =>
        this.get('adminondemand/charts/session/user', {
            timestampInterval,
            sampleInterval,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodUsersCombinedChart = (timestampInterval: number, sampleInterval: number, timezone: string, activeFilters: any) =>
        this.get('adminondemand/charts/overview/user', {
            timestampInterval,
            sampleInterval,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodApplicationsTable = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        type: string,
        applicationName: string,
        pageNumber: number,
        pageSize: number,
        others: any,
        activeFilters: any,
    ) =>
        this.get(`adminondemand/charts/application/table/mostinstalled/${applicationName}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            type,
            pageNumber,
            pageSize,
            others,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodMostActiveTable = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        userName: string,
        pageNumber: number,
        pageSize: number,
        others: any,
        activeFilters: any,
    ) =>
        this.get(`adminondemand/charts/application/table/mostactive/${userName}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            userName,
            pageNumber,
            pageSize,
            others,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
        });

    getAodUserTable = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        userName: string,
        pageNumber: number,
        pageSize: number,
        activeFilters: any,
    ) =>
        this.get(`adminondemand/charts/user/table/${userName}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            pageNumber,
            pageSize,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodSessionUserTable = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        userName: string,
        pageNumber: number,
        pageSize: number,
        activeFilters: any,
    ) =>
        this.get(`adminondemand/charts/session/user/table/${userName}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            pageNumber,
            pageSize,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodUsersCombinedTable = (
        timestampInterval: number,
        sampleInterval: number,
        timezone: string,
        userName: string,
        pageNumber: number,
        pageSize: number,
        activeFilters: any,
    ) =>
        this.get(`adminondemand/charts/overview/user/table/${userName}`, {
            timestampInterval,
            sampleInterval,
            timezone,
            pageNumber,
            pageSize,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodDashboardOverview = (type: string, elevatedUser: string, timestampInterval: number, sampleInterval: number, timezone: string, activeFilters: any) =>
        this.get('adminondemand/dashboard/overview', {
            type,
            elevatedUser,
            timestampInterval,
            sampleInterval,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodDashboardPieChartMostInstalled = (timestampInterval: number, sampleInterval: number, timezone: string, activeFilters: any) =>
        this.get('adminondemand/dashboard/piechart/mostinstalled', {
            timestampInterval,
            sampleInterval,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodDashboardPieChartMostActive = (timestampInterval: number, sampleInterval: number, timezone: string, activeFilters: any) =>
        this.get('adminondemand/dashboard/piechart/mostactive', {
            timestampInterval,
            sampleInterval,
            timezone,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodDashboardChart = (timestampInterval: number, timezone: string, sampleInterval: number, activeFilters: any) =>
        this.get('adminondemand/dashboard/chart', {
            timestampInterval,
            timezone,
            sampleInterval,
            [LEAF_ID.USER_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.USER_NAME)?.value,
            [LEAF_ID.APPLICATION_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.APPLICATION_NAME)?.value,
            [LEAF_ID.DEVICE_NAME]: activeFilters?.find((filter: any) => filter.id === LEAF_ID.DEVICE_NAME)?.value,
        });

    getAodDeviceTable = (deviceId: string, pageNumber: number, pageSize: number, timezone: string) =>
        this.get(`adminondemand/device/${deviceId}`, {
            pageNumber,
            pageSize,
            timezone,
        });

    getPackageTestResults = () => this.get('application/packageTest');

    getSinglePackageTestResults = (packageId: string) => this.get(`application/singlePackageTest/${packageId}`);

    getDeviceCommands = (deviceId: string, urlParams: any = {}) => this.get(`command/device/${deviceId}`, urlParams);

    getOrganizationContracts = () => this.get('org/contract');

    getUserProfile = () => this.get('user/profile');

    getAndroidEnterprise = () => this.get('android/enterprise');

    getEmptyEndpoint = () => this.get('404'); // Use for testing page not available do not remove!

    getAppleCluster = () => this.get('apple/cluster');

    getDeviceExport = (fields: any, filter: any) => this.get(`device/list/export`, { filter, fields });

    getEndpointConfigurations = (endpointId: string) => this.get(`device/${endpointId}/configurations`);

    getDriverPatchQueue = (withOnline = false) => this.get(`driver/patch/queue`, { withOnline });

    getConfigurationEndpointsAssigned = (configurationId: string, pageNumber: number, pageSize: number, filter: any = {}) =>
        this.get(`configuration/${configurationId}/endpoints/assigned`, {
            pageNumber,
            pageSize,
            filter,
        });

    getConfigurationEndpointsAvailable = (configurationId: string, pageNumber: number, pageSize: number, filter: any = {}) =>
        this.get(`configuration/${configurationId}/endpoints/available`, {
            pageNumber,
            pageSize,
            filter,
        });

    getEnrollmentConfiguration = () => this.get(`enrollment`);

    getSingleEnrollmentConfiguration = (id: string) => this.get(`enrollment/${id}`);

    getSecurityDashboardWidgetData = (categoryId: string, urlParams?: any) => this.get(`dashboard/security/${categoryId}`, urlParams);

    getExperienceDashboardWidgetData = (urlParams = {}) =>
        this.get('experience/barchart', {
            binSize: 1,
            bin: 'HOUR', // MINUTE, HOUR, DAY etc.
            interval: 'CD',
            timezone: dayjs.tz.guess(),
            deviceId: undefined,
            ...urlParams,
        });

    getExperienceDashboardWidgetDataAverages = (urlParams: any = {}) =>
        this.get('experience/barchart/avg', {
            interval: 'CD',
            timezone: dayjs.tz.guess(),
            deviceId: undefined,
            ...urlParams,
        });

    getExperienceDashboardNetworkTypeWidgetData = (urlParams: any = {}) =>
        this.get('experience/netType/barchart', {
            binSize: 1,
            bin: 'HOUR', // MINUTE, HOUR, DAY etc.
            interval: 'CD',
            timezone: dayjs.tz.guess(),
            deviceId: undefined,
            ...urlParams,
        });
    getExperienceDashboardNetworkTypeWidgetDataAverages = (urlParams: any = {}) =>
        this.get('experience/netType/barchart/avg', {
            interval: 'CD',
            timezone: dayjs.tz.guess(),
            deviceId: undefined,
            ...urlParams,
        });

    getExperienceDashboardEndpointsList = (urlParams: any = {}) =>
        this.get('experience/table', {
            interval: 'CD',
            timezone: dayjs.tz.guess(),
            pageNumber: 1,
            pageSize: 25,
            ...urlParams,
        });

    getSecurityDashboardEndpoints = (categoryId: string, urlParams: any = {}) =>
        this.get(`dashboard/security/${categoryId}/filter`, {
            pageNumber: 1,
            pageSize: 50,
            orderBy: 'name,ASC',
            ...urlParams,
        });

    getApplicationConfigurations = () => this.get('appEnrollment');

    getApplicationConfiguration = (id: string) => this.get(`appEnrollment/${id}`);

    getApplicationConfigurationEndpointsAssigned = (applicationId: string, pageNumber: number, pageSize: number, filter: any = {}) =>
        this.get(`appEnrollment/${applicationId}/endpoints/assigned`, {
            pageNumber,
            pageSize,
            filter,
        });

    getApplicationConfigurationEndpointsAvailable = (applicationId: string, pageNumber: number, pageSize: number, filter: any = {}) =>
        this.get(`appEnrollment/${applicationId}/endpoints/available`, {
            pageNumber,
            pageSize,
            filter,
        });

    getPackagesWithReleasedVersion = () => this.get('appEnrollment/packs');

    getEndpointApplications = (endpointId: string) => this.get(`device/${endpointId}/applications`);

    getLanguagesForApplication = (applicationId: string) => this.get(`appEnrollment/${applicationId}/language`);

    getPlatformForApplication = (applicationId: string, language: string) =>
        this.get(`appEnrollment/${applicationId}/platform`, {
            language,
        });

    getCustomAppPatch = (deviceId: string) => this.get(`device/${deviceId}/customapp/patch`);

    getSecurityVulnerabilityTableData = (categoryId: string, params?: any) => this.get(`nvd/match/table/${categoryId}`, params);

    getSecurityVulnerabilityTableDataForSingleEndpoint = (categoryId: string, endpointId: string, params?: any) =>
        this.get(`nvd/match/table/${categoryId}/device/${endpointId}`, params);

    getCveIdDetails = (cveId: string, endpointId?: string, filter: any = JSON.stringify({})) => {
        if (endpointId) {
            return this.get(`nvd/cve/${cveId}/device/${endpointId}`, { filter });
        }
        return this.get(`nvd/cve/${cveId}`, { filter });
    };

    getCveIdEndpoints = (cveId: string, params: any) =>
        this.get(`nvd/cve/${cveId}/devices`, {
            pageSize: 50,
            orderBy: `name,${SORT_DIRECTION.ASC}`,
            ...params,
        });

    getSecurityVulnerabilityStatistics = (categoryId: string, endpointId: string, filter: any) => {
        if (endpointId) {
            return this.get(`nvd/match/stats/device/${endpointId}`, {
                cveStats: categoryId, // byCve, byDevice, bySoftware
                filter,
            });
        }
        return this.get(`nvd/match/stats`, {
            cveStats: categoryId, // byCve, byDevice, bySoftware
            filter,
        });
    };

    getReportFromTemplateId = (templateId: string) =>
        this.get('report', {
            template: templateId,
        });

    getReportHistory = () => this.get('report/history');

    getPowerPackWorkflowItems = () => this.get('powerpack/workflow/bricks');

    getPausedStateForQueuedApplications = () => this.get('application/pause');

    getApplicationPowerScript = (customAppId: string) => this.get(`powerpack/workflow/scripts/${customAppId}`);

    get = <T = any>(endpoint: string, params: any = {}, cache = false, withCredentials = true) => {
        const requestedTimestamp = now();
        return new Promise<T>((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                withCredentials,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    /** DELETE */
    deletePolicy = (id: string) => this.delete(`${MOCK_BASE_URL}/policy/${id}`);

    deleteWorkflowItem = (id: string) => this.delete(`${MOCK_BASE_URL}/workflowItem/${id}`);

    deleteWorkflowWithStages = (id: string) => this.delete(`${MOCK_BASE_URL}/workflowWithStages/${id}`);

    deleteRealWorkflow = (id: string) => this.delete(`workflow/${id}`);

    untagDevice = (deviceId: string, tagName: string, tagId: string) => this.delete(`device/${deviceId}/tag/${tagName}`, { tagId });

    deleteTag = (tagId: string, force = false) => this.delete(`tag/${tagId}?force=${force}`);

    deleteDevice = (deviceId: string, traceId: string) => {
        if (traceId) {
            return this.delete(`device/${deviceId}?traceId=${traceId}`); // Complete the delete operation.
        }
        return this.delete(`device/${deviceId}`); // Returns a traceId to be used after confirmation.
    };

    deleteMultiDevice = (filter: any, traceId: string) => {
        if (traceId) {
            return this.delete(`device`, {
                filter,
                traceId,
            }); // Complete the delete operation.
        }
        return this.delete(`device`, { filter }); // Returns a traceId to be used after confirmation.
    };

    untagDevices = (tagToRemove: string, filters: any, tagId?: string) =>
        this.delete('/device/list/action/tag/' + tagToRemove, { filter: JSON.stringify(filters), tagId });

    deleteDeviceFilter = (filterId: string) => this.delete(`device/filter/${filterId}`);

    deleteConfiguration = (id: string, force: boolean) => this.delete(`configuration/${id}?force=${force}`);

    deletePackage = (applicationId: string, packageId: string) => this.delete(`application/${applicationId}/version/${packageId}`);

    removeEndpointsFromConfiguration = (configurationId: string, endpointIds?: string[], filter: any = {}, excludeList?: string[], includeList?: string[]) =>
        this.delete(`configuration/${configurationId}/endpoints`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    removeEndpointFromConfigurations = (endpointId: string, configurationIds: string[]) =>
        this.delete(`device/${endpointId}/configurations`, {
            configurationRefIds: configurationIds,
        });

    deleteEnrollment = (id: string) => this.delete(`enrollment/${id}`);

    removeEndpointsFromApplicationConfiguration = (
        applicationId: string,
        endpointIds: string[],
        filter: any = {},
        excludeList?: string[],
        includeList?: string[],
    ) =>
        this.delete(`appEnrollment/${applicationId}/endpoints`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    deleteCapaPacksApplicationConfiguration = (id: string) => this.delete(`appEnrollment/capapacks/${id}`);

    deleteCustomApplication = (id: string, force = true) => this.delete(`appEnrollment/customapps/${id}?force=${force}`);

    deleteScriptApplication = (id: string, force: boolean) => this.delete(`appEnrollment/scripts/${id}?force=${force}`);

    removeEndpointFromApplications = (endpointId: string, applicationIds: string[]) =>
        this.delete(`device/${endpointId}/applications`, {
            applicationRefIds: applicationIds,
        });

    deleteReportFromHistory = (reportId: string) => this.delete(`report/history/${reportId}`);

    removeQueuedPatchIds = (patchIds = []) =>
        this.delete('application/cleanup', {
            patchIds: patchIds,
        });

    delete = <T = any>(endpoint: string, data: any = {}) => api.delete<T>(endpoint, { data });

    /** UPDATE */
    updateDevice = (id: string, data: any) => this.update(`${MOCK_BASE_URL}/device/${id}`, data);

    updateWorkflow = (id: string, data: any) => this.update(`${MOCK_BASE_URL}/workflow/${id}`, data);

    updateWorkflowWithStage = (id: string, data: any) => this.update(`${MOCK_BASE_URL}/workflowWithStages/${id}`, data);

    updateRealWorkflow = (id: string, data: any) => {
        if (data.type === 'schedule' && data.nodes.find((n: any) => n.id === 'required_schedule')) {
            data.configuration.cronExpression = data.nodes[0].configuration.cronExpression;
            data.configuration.tz = data.nodes[0].configuration.tz ?? window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        const filterNode = data.nodes.find((n: any) => n.id === 'required_filter');
        if (filterNode) {
            data.filterId = filterNode.configuration.filterId || null;
        }
        data.edges = stripRequiredEdges(data.edges);
        /* Delete unconnected nodes and required */
        data.nodes = stripRequiredNodes(data.nodes, data.edges);

        return this.update(`workflow`, data);
    };

    updatePolicy = (id: string, data: any) => this.update(`${MOCK_BASE_URL}/policy/${id}`, data);

    updateWorkflowItem = (id: string, data: any) => this.update(`${MOCK_BASE_URL}/workflowItem/${id}`, data);

    updateTag = (id: string, name: string) =>
        this.update(`tag/${id}`, {
            name,
        });

    updateDeviceFilter = (filterId: string, name: string, query: any, force = false) =>
        this.update(`device/filter/${filterId}?force=${force}`, { name, query: JSON.stringify(query) });

    updateConfiguration = (
        id: string,
        name: string,
        type: string,
        showChallengeMessage: boolean,
        challengeMessage: string,
        showUacMessage: boolean,
        uacMessage: string,
        config: any,
    ) =>
        this.update(`configuration/${id}`, {
            name,
            type,
            showChallengeMessage,
            challengeMessage,
            showUacMessage,
            uacMessage,
            config,
        });

    updateDriverConfiguration = (id: string, name: string, type: string, configMap: any) =>
        this.update(`configuration/${id}`, {
            id,
            name,
            type,
            configMap,
        });

    updateUserProfile = (profileUpdates = {}) => this.update('user/profile', profileUpdates);

    updateConfigurationPriority = (configPriorities: any, movedConfigs: any) =>
        this.update('configuration/priority', {
            configPriorities,
            movedConfigs,
        });

    reconfigureOrgDevice = (orgId: number) => this.update('admin/device/reconfigure', { orgId });

    restartOrgDevice = (orgId: number, all: boolean) => this.update('admin/device/restart', { orgId, all });

    initialScanOrgDevice = (orgId: number) => this.update('configuration/forceInitialScan', { orgId });

    updatePackageStatus = (applicationId: string, packageId: string, status: string) =>
        this.update(`application/${applicationId}/version/${packageId}/status`, {
            status,
        });

    updatePackage = (applicationId: string, packageId: string, updatedPackage: any) =>
        this.update(`application/${applicationId}/version/${packageId}`, updatedPackage);

    updateApplication = (applicationId: string, updatedApplication: any) => this.update(`application/${applicationId}`, updatedApplication);

    addEndpointsToConfiguration = (configurationId: string, endpointIds: string[], filter: any = {}, excludeList: string[], includeList: string[]) =>
        this.update(`configuration/${configurationId}/endpoints`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    addEndpointToConfigurations = (endpointId: string, configurationIds: string[]) =>
        this.update(`device/${endpointId}/configurations`, {
            configurationRefIds: configurationIds,
        });

    updateEnrollment = (enrollmentId: string, name: string, description: string, groupRefIds: string[], configurationRefIds: string[], tagRefIds: string[]) =>
        this.update(`enrollment/${enrollmentId}`, {
            name,
            description,
            groupRefIds,
            configurationRefIds,
            tagRefIds,
        });

    addEndpointsToApplicationConfiguration = (applicationId: string, endpointIds: string[], filter: any = {}, excludeList: string[], includeList: string[]) =>
        this.update(`appEnrollment/${applicationId}/endpoints`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    updateCapaPacksApplicationConfiguration = (applicationId: string, application: any) =>
        this.update(`appEnrollment/capapacks/${applicationId}`, {
            ...application,
        });

    addEndpointToApplications = (endpointId: string, applicationIds: string[]) =>
        this.update(`device/${endpointId}/applications`, {
            applicationRefIds: applicationIds,
        });

    updateCustomApplication = (
        applicationId: string,
        {
            name,
            description,
            thumbnail,
            architecture,
            language,
            version,
            commandLineOptions,
            uninstallCommand,
            releaseStatus,
            installationFileRelativePath,
            install,
            uninstall,
        }: {
            name: string;
            description: string;
            thumbnail: string;
            architecture: string;
            language: string;
            version: string;
            commandLineOptions: string;
            uninstallCommand: string;
            releaseStatus: number;
            installationFileRelativePath: string;
            install: any;
            uninstall: any;
        },
        formData: FormData,
    ) => {
        if (
            name.trim() !== '' &&
            version &&
            Object.values(windowsApplication.releaseStatus).some(({ id }) => id === releaseStatus) &&
            Object.values(windowsApplication.architecture).some(({ id }) => id === architecture) &&
            Object.values(windowsApplication.language).some(({ id }) => id === language)
        ) {
            return this.update(`appEnrollment/customapps/${applicationId}`, {
                name,
                description,
                thumbnail,
                type: windowsApplication.type.customApp.id,
                releaseStatus: Number(releaseStatus),
                config: {
                    platform: architecture,
                    language,
                    version,
                    commandLineOptions,
                    uninstallCommand,
                },
                fileInfo: {
                    localFileName: installationFileRelativePath,
                },
                install,
                uninstall,
            });
            // .then(() => {
            //     return fetch(`${CAPI_ROOT}/${baseApi.defaults.baseURL}/file/upload/customapp/${applicationId}`, {
            //         body: formData,
            //         method: 'POST',
            //         mode: 'cors',
            //         credentials: 'include',
            //     }).then(async (response) => {
            //         if (response.ok) {
            //             return response;
            //         } else {
            //             throw response;
            //         }
            //     });
            // });
        }
        return new Promise((resolve, reject) => {
            reject({ message: 'Invalid CustomApp parameters' });
        });
    };

    updateScriptApplication = (
        applicationId: string,
        {
            name,
            description,
            thumbnail,
            version,
            releaseStatus,
            install,
            uninstall,
        }: {
            name: string;
            description: string;
            thumbnail: string;
            version: string;
            releaseStatus: number;
            install: any;
            uninstall: any;
        },
    ) => {
        if (name.trim() !== '' && version && Object.values(windowsApplication.releaseStatus).some(({ id }) => id === releaseStatus)) {
            return this.update(`appEnrollment/scripts/${applicationId}`, {
                name,
                description,
                thumbnail,
                type: windowsApplication.type.script.id,
                releaseStatus: Number(releaseStatus),
                config: {
                    version,
                },
                install,
                uninstall,
            });
        }
        return new Promise((resolve, reject) => {
            reject({ message: 'Invalid Script parameters' });
        });
    };

    updatePausedStateForQueuedApplications = (paused: boolean) => this.update(`application/pause?pause=${paused}`);

    update = <T = any>(endpoint: string, data?: any, config: any = {}) => {
        return new Promise<T>((resolve, reject) => {
            api.put(endpoint, data, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** POST */
    createPolicy = (data: any) => this.post(`${MOCK_BASE_URL}/policy`, data);

    createDeviceFilter = (name: string, query: any = {}) => this.post('device/filter', { name, query: JSON.stringify(query) });

    createWorkflow = (name: string, description: string) =>
        this.post(`${MOCK_BASE_URL}/workflow`, {
            name,
            description,
            targetGroup: [],
            enabled: true,
            type: 'schedule',
            edges: [],
            nodes: [],
            lastRun: 0,
            configuration: {
                cronExpression: '0 18 * * *', // At 18:00, every day
                startDate: '', // Required
                endDate: '', // Optional
                runOnce: false, // Optional
                tz: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });

    runWorkflowNow = (workflowId: string, filter: any) =>
        this.post(`workflow/${workflowId}/run`, {
            filter,
        });

    runWorkflowItemNow = (workflowItemId: string, deviceIds: string[] = [], workflowItemIConfiguration: any = {}) =>
        this.post(`workflowItem/${workflowItemId}/run`, {
            deviceIds,
            configuration: workflowItemIConfiguration, // like {"powerPackId": 1732 }
        });

    createRealWorkflow = (data: any) => {
        /* so we don't mess with clone */
        if (data.edges) {
            data.edges = data.edges.filter((n: any) => !n.target.includes('required'));
        }
        if (data.nodes) {
            data.nodes = data.nodes.filter((n: any) => !n.id.includes('required'));
        }

        return this.post('workflow', {
            name: '',
            description: '',
            targetGroup: [],
            enabled: true,
            type: 'schedule',
            edges: [],
            nodes: [],
            configuration: {},
            ...data,
        });
    };

    createWorkflowItem = (data: any) => this.post(`${MOCK_BASE_URL}/workflowItem`, data);

    tagDevice = (deviceId: string, tagId: string, tagName: string) =>
        this.post(`device/${deviceId}/tag`, {
            name: tagName,
            tagId,
        });

    performDeviceHardwareScan = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=HWInventory&commandName=HwScan`, {});

    performDeviceSoftwareScan = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=SWInventory&commandName=SwScan`, {});

    performDeviceAgentRestart = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=CapaOneAgent&commandName=RestartService`, {});

    performDeviceAgentReconfigure = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=CapaOneAgent&commandName=ReconfigureService`, {});
    /**
     * @param deviceId Id of the device.
     * @param loglevel "Debug"|"Info"|Warn"|"Error"
     * @param timeoutminutes How many minutes the loglevel should persist. Defaults to 30 minutes in agent.
     */
    setDeviceAgentLogLevel = (deviceId: string, loglevel: string, timeoutminutes = 30) =>
        this.post(`command/device/${deviceId}?moduleName=CapaOneAgent&commandName=SetLogLevel`, { loglevel, timeoutminutes });

    performDeviceLogUpload = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=CapaOneAgent&commandName=UploadLogfiles`, {});

    performDeviceDriverScan = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=DriverInventory&commandName=Scan`, {});

    performDeviceSecurityScan = (deviceId: string) => this.post(`command/device/${deviceId}?moduleName=HWInventory&commandName=HwScan`, {});

    //performDeviceCVEScan = (deviceId) => this.get(`nvd/scan/${deviceId}`, {});

    performOrgCVEScan = (orgId: number) => this.get(`nvd/scan/org`, { orgId });

    createTag = (tagName: string) =>
        this.post('tag', {
            name: tagName,
        });

    installPackagesOnDevices = (packageIds: string[], deviceIds: string[]) =>
        this.post('application/install/specific', {
            deviceId: deviceIds,
            packageId: packageIds,
        });

    installApplicationOnDeviceWithLanguageAndPlatform = (applicationId: string, deviceIds: string[], language: string, platform: string) =>
        this.post('application/install', {
            applicationId,
            deviceId: deviceIds,
            language,
            platform,
        });

    installApplicationOnDeviceCustomApps = (patchId: string, deviceId: string) => this.post(`appEnrollment/customapps/reinstall/${patchId}`, { deviceId });

    reinstallCustomAppOnMultipleDevices = (applicationId: string, all?: boolean, filter?: any, excludeList?: string[], endpointRefIds?: string[]) =>
        this.post(`appEnrollment/customapps/reinstall/multiple`, { applicationId, all, filter, excludeList, endpointRefIds });

    uninstallApplicationOnDeviceCustomApps = (patchId: string) => this.post(`appEnrollment/customapps/uninstall/${patchId}`);

    uninstallCustomAppOnMultipleDevices = (applicationId: string, all?: boolean, filter?: any, excludeList?: string[], endpointRefIds?: string[]) =>
        this.post(`appEnrollment/customapps/uninstall/multiple`, { applicationId, all, filter, excludeList, endpointRefIds });

    installApplicationOnDevices = (applicationId: string, deviceIds: string[]) =>
        this.post('application/install', {
            applicationId,
            deviceId: deviceIds,
        });

    uninstallApplicationOnDevices = (applicationId: string, deviceIds: string[]) =>
        this.post('application/uninstall', {
            applicationId,
            deviceId: deviceIds,
            language: null,
        });

    installDriversOnEndpoints = (deviceIds: string[]) =>
        this.post('driver/install', {
            deviceId: deviceIds,
        });

    cancelPackageInstallOnDevices = (packageId: string, deviceIds: string[]) =>
        this.post('application/cancel', {
            deviceId: deviceIds,
            packageId,
        });

    addTagToDevices = (tagToAdd: string, filters: any, tagId: string) =>
        this.post('/device/list/action/tag', { filter: JSON.stringify(filters), tagToAdd, tagId });

    createConfiguration = (
        name: string,
        type: string,
        showChallengeMessage: boolean,
        challengeMessage: string,
        showUacMessage: boolean,
        uacMessage: string,
        config: any,
    ) =>
        this.post('configuration', {
            name,
            type,
            showChallengeMessage,
            challengeMessage,
            showUacMessage,
            uacMessage,
            config,
        });

    createDriverConfiguration = (name: string, type: string, configMap: any) =>
        this.post('configuration', {
            name,
            type,
            configMap,
        });

    startPackageManualTest = (applicationId: string, packageData: any) => this.post(`application/manualTest/${applicationId}/version`, packageData);

    createUserProfile = (defaultSettings: any) => this.post('user/profile', defaultSettings);

    createEnrollment = (name: string, description: string, groupRefIds: string[], configurationRefIds: string[], tagRefIds: string[]) =>
        this.post(`enrollment`, {
            name,
            description,
            groupRefIds,
            configurationRefIds,
            tagRefIds,
        });

    createApplication = (application: any) => this.post('application', application);

    createPackage = (applicationId: string, packageData: any) => this.post(`application/${applicationId}/version`, packageData);

    createCapaPacksApplicationConfiguration = (
        name: string,
        description: string,
        applicationId: string,
        application: any,
        config: any,
        thumbnail: string,
        vendor: string,
        releaseStatus: number,
    ) =>
        this.post('appEnrollment/capapacks', {
            name,
            description,
            applicationId,
            application,
            config,
            thumbnail,
            vendor,
            releaseStatus,
        });

    createCustomApplication = (
        {
            name = '',
            description,
            thumbnail,
            architecture,
            language,
            version,
            commandLineOptions,
            uninstallCommand,
            releaseStatus,
            installationFileRelativePath,
        }: {
            name: string;
            description: string;
            thumbnail: string;
            architecture: string;
            language: string;
            version: string;
            commandLineOptions: string;
            uninstallCommand: string;
            releaseStatus: number;
            installationFileRelativePath: string;
        },
        formData: FormData,
    ) => {
        if (
            name.trim() !== '' &&
            version &&
            formData.has('files') &&
            Object.values(windowsApplication.releaseStatus).some(({ id }) => id === releaseStatus) &&
            Object.values(windowsApplication.architecture).some(({ id }) => id === architecture) &&
            Object.values(windowsApplication.language).some(({ id }) => id === language)
        ) {
            return this.post('appEnrollment/customapps', {
                name,
                description,
                config: {
                    platform: architecture,
                    language,
                    version,
                    commandLineOptions,
                    uninstallCommand,
                },
                releaseStatus: Number(releaseStatus),
                thumbnail,
                fileInfo: {
                    localFileName: installationFileRelativePath,
                },
            });
            //     .then((appEnrollmentResponse) => {
            //     const xhr = new XMLHttpRequest();
            //     const url = `${CAPI_ROOT}/${axios.defaults.baseURL}/file/upload/customapp/${appEnrollmentResponse.id}`;

            //     xhr.open('POST', url, true);
            //     xhr.withCredentials = true;

            //     // Set up a handler for the progress event
            //     xhr.upload.onprogress = function (event) {
            //         if (event.lengthComputable) {
            //             const percentComplete = (event.loaded / event.total) * 100;
            //             if (progressCallback) {
            //                 progressCallback(percentComplete);
            //             }
            //         }
            //     };

            //     xhr.send(formData);
            //     return appEnrollmentResponse;
            // });
        }
        return new Promise((resolve, reject) => {
            console.log('Invalid CustomApp parameters');
            reject({ message: 'Invalid CustomApp parameters' });
        });
    };

    createScriptApplication = ({
        name,
        description,
        type,
        thumbnail,
        version,
        releaseStatus,
        install,
        uninstall,
    }: {
        name: string;
        description: string;
        type: string;
        thumbnail: string;
        version: string;
        releaseStatus: number;
        install: any;
        uninstall: any;
    }) => {
        if (name.trim() !== '' && version && Object.values(windowsApplication.releaseStatus).some(({ id }) => id === releaseStatus)) {
            return this.post('appEnrollment/scripts', {
                name,
                description,
                config: {
                    version,
                },
                releaseStatus: Number(releaseStatus),
                thumbnail,
                install,
                uninstall,
                type,
            });
        }
        return new Promise((resolve, reject) => {
            console.log('Invalid Script parameters');
            reject({ message: 'Invalid Script parameters' });
        });
    };

    forgotPassword = (email: string) => this.post('/forgotpassword', { email });

    packageMatchTest = (matchNames: string[], testStrings: string[]) =>
        this.post('/application/testPackageMatch', {
            matchNames,
            testStrings,
        });

    generateReportFromTemplateId = (templateId: string) =>
        this.post('report', {
            template: templateId,
        });

    post = <T = any>(endpoint: string, data?: any, config: any = {}) => {
        return new Promise<T>((resolve, reject) => {
            api.post(endpoint, data, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    login = (user: { username: string; password: string }) => this.post('login', user);

    logout = () => this.post('logout');

    postMultipartFormData = (endpoint: string, data: any, config: any) => {
        return this.post(endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' }, ...config });
    };

    /** MISC */
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default Connection;
