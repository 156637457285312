export const AppleNotificationSettingsConfiguration = {
    schema: {
        schemaId: 'appleNotificationSettingsConfiguration',
        title: 'Notifications',
        category: 'User Experience',
        type: 'object',
        properties: {
            'com.apple.notificationsettings': {
                title: '',
                skipIndent: true,
                properties: {
                    NotificationSettings: {
                        title: 'Notification Settings',
                        description: 'An array of notification settings dictionaries.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                BundleIdentifier: {
                                    title: 'Bundle ID',
                                    description: 'The bundle identifier of the app to which to apply these notification settings.',
                                    type: 'appleapplicationsearchdialog',
                                    required: true,
                                },
                                NotificationsEnabled: {
                                    title: 'Notification Enabled',
                                    description: 'Enables notifications for this app',
                                    type: 'boolean',
                                },
                                BadgesEnabled: {
                                    title: 'Badges Enabled',
                                    description: 'Enables badges for this app.',
                                    help: 'Showing how many notifications you have received on the app icon',
                                    type: 'boolean',
                                },
                                CriticalAlertEnabled: {
                                    title: 'Critical Alert Enabled',
                                    description: 'Enables critical alerts that can ignore "Do Not Disturb" and ringer settings for this app.',
                                    type: 'boolean',
                                },
                                ShowInCarPlay: {
                                    title: 'Show In Car Play',
                                    description: 'Enables notifications in CarPlay for this app.',
                                    type: 'boolean',
                                },
                                ShowInLockScreen: {
                                    title: 'Show In Lock Screen',
                                    description: 'Enables notifications on the lock screen for this app.',
                                    type: 'boolean',
                                },
                                ShowInNotificationCenter: {
                                    title: 'Show In Notification Center',
                                    description: 'Enables notifications in the notification center for this app',
                                    type: 'boolean',
                                },
                                SoundsEnabled: {
                                    title: 'Sounds Enabled',
                                    description: 'Enables sounds for this app.',
                                    type: 'boolean',
                                },
                                AlertType: {
                                    title: 'Alert Type',
                                    description: 'The type of alert for notifications for this app.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 0,
                                            name: 'None',
                                        },
                                        {
                                            id: 1,
                                            name: 'Temporary Banner',
                                        },
                                        {
                                            id: 2,
                                            name: 'Persistent Banner',
                                        },
                                    ],
                                },
                                GroupingType: {
                                    title: 'Grouping Type',
                                    description: 'The type of grouping for notifications for this app',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 0,
                                            name: 'Automatic: Group notifications into app-specified groups.',
                                        },
                                        {
                                            id: 1,
                                            name: 'By app: Group notifications into one group.',
                                        },
                                        {
                                            id: 2,
                                            name: "Off: Don't group notifications.",
                                        },
                                    ],
                                },
                                PreviewType: {
                                    title: 'Preview Type',
                                    description: 'The type previews for notifications',
                                    help: 'This key overrides the value at Settings>Notifications>Show Previews. Available in iOS 14 and later.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 0,
                                            name: 'Always: Previews will be shown when the device is locked and unlocked',
                                        },
                                        {
                                            id: 1,
                                            name: 'When Unlocked: Previews will only be shown when the device is unlocked',
                                        },
                                        {
                                            id: 2,
                                            name: 'Never: Previews will never be shown',
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
