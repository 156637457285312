export const WORKFLOW_ICON_TYPE = {
    query: 'devices',
    filter: 'filter',
    action: 'action',
    trigger: 'play',
};

export const WORKFLOW_ITEM_TYPE = 'workflowitem';

export const WORKFLOW_ITEM_TYPE_DEPRECATED = 'workflowitemdeprecated';
