import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import * as React from 'react';

interface CircularProgressWithLabelProps extends CircularProgressProps {
    value: number;
    textColor?: string;
    noPercentage?: boolean; 
}

export const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
    value,
    textColor = 'text.secondary',
    noPercentage = false, 
    ...props
}) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                value={value}
                {...props}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {!noPercentage && (
                    <Typography
                        variant="caption"
                        component="div"
                        color={textColor}
                    >
                        {`${Math.round(value)}%`}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
