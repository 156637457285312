import MUISnackbar, { SnackbarProps } from '@mui/material/Snackbar';
import React from 'react';

const classes = {
    root: 'cs-snackbar',
    anchorOriginTopCenter: 'cs-snackbar-top-center',
    anchorOriginBottomCenter: 'cs-snackbar-bottom-center',
};

/** Snackbar component */
export const Snackbar = React.forwardRef<any, SnackbarProps>((props, ref) => (
    <MUISnackbar
        classes={classes}
        ref={ref}
        open={false}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        {...props}
    />
));

export default Snackbar;
