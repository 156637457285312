export const USER = {
    TYPE: {
        LOCAL: 'LOCAL',
        AZURE: 'AZURE',
        NO_USER: 'NO_USER',
    },
    DESCRIPTION: {
        LOCAL: 'Local user',
        AZURE: 'Azure user',
        NO_USER: 'No user',
    },
};
