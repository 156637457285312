import { TOOLTIP } from '@capasystems/constants';
import { Tooltip } from '@capasystems/ui';
import { linkingMethod } from '@thirdparty/constants';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React from 'react';
import { TailwindBadge } from '../thirdparty-components/thirdparty-components';

export type AppliedTypeProps = {
    appliedDirect: boolean;
    appliedViaGroup: boolean;
    appliedViaGroups: { id: string; name: string }[];
    isVpp: boolean;
};

export const AppliedType: React.FC<AppliedTypeProps> = ({ appliedDirect, appliedViaGroup, appliedViaGroups, isVpp }) => {
    if (appliedViaGroup) {
        return (
            <div>
                <Tooltip
                    content={
                        <div>
                            <p>{pluralize('Group', appliedViaGroups.length)}</p>
                            {appliedViaGroups.map((group) => (
                                <p
                                    className="tw-mt-1 tw-font-bold"
                                    key={group.id}
                                >
                                    {group.name}
                                </p>
                            ))}
                        </div>
                    }
                    position={TOOLTIP.POSITION.TOP_END}
                >
                    {/* @ts-ignore - this is not typed */}
                    <TailwindBadge
                        color={appliedDirect ? 'rose' : 'indigo'}
                        className={classNames(' tw-ml-2', {
                            //'tw-bg-indigo-400 tw-text-white': !appliedDirect,
                            //'tw-bg-rose-400 tw-text-white': appliedDirect,
                        })}
                        size="small"
                        noShadow
                    >
                        Assigned via {pluralize('group', appliedViaGroups.length, appliedViaGroups.length > 1)}
                        {appliedDirect && <span>&nbsp;and directly</span>}
                    </TailwindBadge>
                </Tooltip>
            </div>
        );
    }
    if (appliedDirect) {
        return (
            // @ts-ignore - this is not typed
            <TailwindBadge
                color="indigo"
                className="tw-ml-2"
                size="small"
                noShadow
            >
                {linkingMethod.direct.name}
            </TailwindBadge>
        );
    }
    if (isVpp) {
        return (
            // @ts-ignore - this is not typed
            <TailwindBadge
                color="teal"
                className="tw-ml-2"
                size="small"
            >
                VPP
            </TailwindBadge>
        );
    }
    return null;
};
