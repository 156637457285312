import classNames from 'classnames';
import { PremiumFeature } from '../contact/contact.components';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export const ReliabilityPermissionsCheck = ({ children, teaserProps = {} }) => {
    const { permissions } = useAuthContext();
    if (permissions.canViewReliability) {
        return children;
    }
    return <ReliabilityTeaser {...teaserProps} />;
};

const ReliabilityTeaser = ({ className }) => {
    return (
        <div className={classNames('tw-h-full', className)}>
            <WidgetPaper
                headerless
                className="tw-h-full"
            >
                <PremiumFeature
                    description=""
                    icon="capaoneReliability"
                    defaultSubject="CapaOne Reliability"
                    productPage="https://www.capasystems.com/capaone-reliability"
                />
            </WidgetPaper>
        </div>
    );
};
