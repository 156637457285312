import { api, getUniqueId, now } from '@capasystems/utils';

const expirationTimestamps: any = {};

class ManagementConnection {
    baseUrl: string;
    abortController: AbortController;
    connectionID: string;

    constructor() {
        this.baseUrl = `${api.defaults.baseURL}/management`;
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('ManagementConnectionID');
    }

    /** GET */
    getUsers = (params: any) => this.get(`user`, params);

    getUser = (userID: string) => this.get(`user/${userID}`);

    getEndpointsAssignedToUser = (userID: string) => this.get(`user/${userID}/endpoint`);

    getIntegrationServices = (params?: any) => this.get('integration', params);

    getGroups = () => this.get('group');

    getGroup = (groupId: string) => this.get(`group/${groupId}`);

    getWindowsEndpointsAssignedToGroup = (groupId: string, pageNumber: number, filter: any = {}) =>
        this.get(`group/${groupId}/windows/endpoint/assigned`, {
            pageNumber,
            pageSize: 100,
            filter,
        });

    getAvailableWindowsEndpointsForGroup = (groupId: string, pageNumber: number, filter: any = {}) =>
        this.get(`group/${groupId}/windows/endpoint/available`, {
            pageNumber,
            pageSize: 100,
            filter,
        });

    /* getAndroidEndpointsAssignedToGroup = (groupId, pageNumber, filter = {}) => this.get(`group/${groupId}/windows/endpoint/assigned`, {
        pageNumber,
        pageSize: 100,
        filter
    });

    getAvailableAndroidEndpointsForGroup = (groupId, pageNumber) => this.get(`group/${groupId}/android/endpoint/available`, {
        pageNumber,
        pageSize: 100,
    }) */

    getWindowsConfigurationsForGroup = (groupId: string) => this.get(`group/${groupId}/windows/configuration`);

    getWindowsApplicationsForGroup = (groupId: string) => this.get(`group/${groupId}/windows/application`);

    getAzureGroups = (filter: any, pageNumber: number, pageSize: number) =>
        this.get('externalgroup', {
            filter,
            pageNumber,
            pageSize,
        });

    get = <T = any>(endpoint: string, params: any = {}, overrides: any = {}) => {
        const requestedTimestamp = now();
        return new Promise<T>((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                cache: false,
                withCredentials: true,
                baseURL: this.baseUrl,
                ...overrides,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    /** DELETE */
    deleteGroup = (groupId: string) => this.delete(`group/${groupId}`);

    deleteUser = (userID: string) => this.delete(`user/${userID}`);

    deleteIntegrationService = (integrationServiceId: string) => this.delete(`integration/${integrationServiceId}`);

    removeWindowsEndpointsFromGroup = (groupId: string, endpointIds?: string[], filter: any = {}, excludeList?: string[], includeList?: string[]) =>
        this.delete(`group/${groupId}/windows/endpoint`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    removeAndroidEndpointsFromGroup = (groupId: string, endpointIds: string[]) =>
        this.delete(`group/${groupId}/android/endpoint`, {
            endpointRefIds: endpointIds,
        });

    removeConfigurationsFromGroup = (groupId: string, configurationIds: string[], type: string) =>
        this.delete(`group/${groupId}/${type}/configuration`, {
            configurationRefIds: configurationIds,
        });

    removeApplicationsFromGroup = (groupId: string, applicationIds: string[], type: string) =>
        this.delete(`group/${groupId}/${type}/application`, {
            applicationRefIds: applicationIds,
        });

    removeEndpointFromGroups = (endpointId: string, groupIds: string[], type: string) =>
        this.delete(`endpoint/${endpointId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    removeConfigurationFromGroups = (configurationId: string, groupIds: string[], type: string) =>
        this.delete(`configuration/${configurationId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    removeApplicationFromGroups = (applicationId: string, groupIds: string[], type: string) =>
        this.delete(`application/${applicationId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    delete = <T = any>(endpoint: string, data: any = {}, overrides: any = {}) =>
        api.delete<T>(endpoint, {
            baseURL: this.baseUrl,
            ...overrides,
            data,
        });

    updateUser = (userID: string, userObject: any) => this.update(`user/${userID}`, userObject);

    updateIntegrationService = (integrationServiceId: string, dataObject: any) => this.update(`integration/${integrationServiceId}`, dataObject);

    updateGroup = (groupId: string, dataObject: any) => this.update(`group/${groupId}`, dataObject);

    addWindowsEndpointsToGroup = (groupId: string, endpointIds?: string[], filter: any = {}, excludeList?: string[], includeList?: string[]) =>
        this.update(`group/${groupId}/windows/endpoint`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    addAndroidEndpointsToGroup = (groupId: string, endpointIds: string[]) =>
        this.update(`group/${groupId}/android/endpoint`, {
            endpointRefIds: endpointIds,
        });

    addAppleEndpointsToGroup = (groupId: string, endpointIds: string[]) =>
        this.update(`group/${groupId}/apple/endpoint`, {
            endpointRefIds: endpointIds,
        });

    addWindowsConfigurationsToGroup = (groupId: string, configurationIds: string[]) =>
        this.update(`group/${groupId}/windows/configuration`, {
            configurationRefIds: configurationIds,
        });

    addAndroidConfigurationsToGroup = (groupId: string, configurationIds: string[]) =>
        this.update(`group/${groupId}/android/configuration`, {
            configurationRefIds: configurationIds,
        });

    addAppleConfigurationsToGroup = (groupId: string, configurationIds: string[]) =>
        this.update(`group/${groupId}/apple/configuration`, {
            configurationRefIds: configurationIds,
        });

    addApplicationsToGroup = (groupId: string, applicationIds: string[], type: string) =>
        this.update(`group/${groupId}/${type}/application`, {
            applicationRefIds: applicationIds,
        });

    addAndroidEndpointToGroups = (endpointId: string, groupIds: string[]) =>
        this.update(`endpoint/${endpointId}/android/group`, {
            groupRefIds: groupIds,
        });

    addAppleEndpointToGroups = (endpointId: string, groupIds: string[]) =>
        this.update(`endpoint/${endpointId}/apple/group`, {
            groupRefIds: groupIds,
        });

    addWindowsEndpointToGroups = (endpointId: string, groupIds: string[]) =>
        this.update(`endpoint/${endpointId}/windows/group`, {
            groupRefIds: groupIds,
        });

    addAndroidConfigurationToGroups = (configurationId: string, groupIds: string[]) =>
        this.update(`configuration/${configurationId}/android/group`, {
            groupRefIds: groupIds,
        });

    addAppleConfigurationToGroups = (configurationId: string, groupIds: string[]) =>
        this.update(`configuration/${configurationId}/apple/group`, {
            groupRefIds: groupIds,
        });
    addWindowsConfigurationToGroups = (configurationId: string, groupIds: string[]) =>
        this.update(`configuration/${configurationId}/windows/group`, {
            groupRefIds: groupIds,
        });

    addApplicationToGroups = (applicationId: string, groupIds: string[], type: string) =>
        this.update(`application/${applicationId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    update = (endpoint: string, data?: any, overrides: any = {}) => {
        return new Promise((resolve, reject) => {
            api.put(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** POST */
    createUser = (userData = {}) => this.post('user', userData);

    createGroup = (dataObject: any) => this.post('group', dataObject);

    createIntegrationService = (data: any) => this.post('integration', data);

    performIntegrationServiceSync = (integrationServiceId: string) => this.post(`integration/${integrationServiceId}/sync`);

    contactCapaSystems = ({
        subject,
        message,
        firstName,
        lastName,
        email,
        parentOrganizationId,
        organizationName,
    }: {
        subject: string;
        message: string;
        firstName: string;
        lastName: string;
        email: string;
        parentOrganizationId: number;
        organizationName: string;
    }) =>
        this.post('mail/contact', { subject, message, firstName, lastName, email, parentOrganizationId, organizationName }, { baseURL: api.defaults.baseURL });

    post = <T = any>(endpoint: string, data?: any, overrides: any = {}) => {
        return new Promise<T>((resolve, reject) => {
            api.post(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** MISC */
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default ManagementConnection;
