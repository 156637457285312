//Documentation: Lock Screen Message
//https://developer.apple.com/documentation/devicemanagement/lockscreenmessage
export const AppleLockScreenMessageConfiguration = {
    schema: {
        schemaId: 'appleLockScreenMessageConfiguration',
        category: 'System Configuration',
        title: 'Lock Screen Message',
        type: 'object',
        properties: {
            'com.apple.shareddeviceconfiguration': {
                title: '',
                skipIndent: true,
                properties: {
                    AssetTagInformation: {
                        title: 'Asset Tag Information',
                        description: 'Information displayed in the login window and Lock screen',
                        type: 'string',
                    },
                    LockScreenFootnote: {
                        title: 'Lock Screen Footnote',
                        description: 'The footnote displayed in the login window and Lock screen.',
                        type: 'string',
                    },
                },
            },
            // 'com.apple.homescreenlayout': {
            //     title: '',
            //     skipIndent: true,
            //     properties: {
            //         HomeScreenLayout: {
            //             title: 'Home Screen Layout',
            //             description: 'Defines layout of apps, folders, and web clips for the home screen.',
            //             type: 'object',
            //             properties: {
            //                 Pages: {
            //                     title: 'Pages',
            //                     description: 'An array of dictionaries, each representing a page of the home screen.',
            //                     type: 'array',
            //                     required: true,
            //                     items: {
            //                         type: 'object',
            //                         properties: {
            //                             type: {
            //                                 title: 'Type',
            //                                 description: 'The type of the dock item.',
            //                                 type: 'string',
            //                                 required: true,
            //                                 options: [
            //                                     {
            //                                         id: 'TYPE_APP',
            //                                         name: 'APP',
            //                                     },
            //                                     {
            //                                         id: 'TYPE_FOLDER',
            //                                         name: 'Folder',
            //                                     },
            //                                     {
            //                                         id: 'TYPE_WEB_CLIP',
            //                                         name: 'WebClip',
            //                                     },
            //                                 ],
            //                             },
            //                             bundleID: {
            //                                 title: 'Bundle ID',
            //                                 description: 'The bundle identifier of the app.',
            //                                 help: 'This setting is required if the type is Application.',
            //                                 type: 'string',
            //                             },
            //                             displayName: {
            //                                 title: 'Display Name',
            //                                 description: 'Name shown to the user.',
            //                                 help: 'This setting is valid only if the type is Folder.',
            //                                 type: 'string',
            //                             },
            //                             pages: {
            //                                 //TODO: An array of arrays of dictionaries, each conforming to the icon dictionary format.
            //                                 title: 'Pages',
            //                                 description: 'A list of which conforming to the icon dictionary format.',
            //                                 help: ' This setting is valid only if the type is Folder.',
            //                                 type: 'array',
            //                                 items: {
            //                                     type: 'string',
            //                                     options: [
            //                                         {
            //                                             id: 'ICON_DICTIONARIE_1',
            //                                             name: 'Icon Dictionnarie 1',
            //                                         },
            //                                         {
            //                                             id: 'ICON_DICTIONARIE_2',
            //                                             name: 'Icon Dictionnarie 2',
            //                                         },
            //                                         {
            //                                             id: 'ICON_DICTIONARIE_3',
            //                                             name: 'Icon Dictionnarie 3',
            //                                         },
            //                                     ],
            //                                 },
            //                             },
            //                             url: {
            //                                 title: 'URL',
            //                                 description: 'The URL of the existing web clip for this item.',
            //                                 help: 'This setting is required if type is WebClip.',
            //                                 type: 'string',
            //                             },
            //                         },
            //                     },
            //                 },
            //             },
            //         },
            //     },
            // },
        },
    },
};
