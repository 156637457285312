import React, { useEffect, useMemo, useState } from 'react';
import { PieChart, eSize } from '../charts/pie-chart';
import { useApi } from '../hooks/useApi/useApi';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export type DriverUpdatesByModelProps = {
    className?: string;
};

export const DriverUpdatesByModel: React.FC<DriverUpdatesByModelProps> = ({ className }) => {
    const api = useApi();

    const navigate = useNavigate();

    const [chartData, setChartData] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getDriverPatchQueue()
            .then(setChartData)
            .catch(() => setChartData(undefined))
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const modelsWithUpdates: Record<string, number> = {};

        for (const update of chartData) {
            if (update?.status?.code === 303) {
                if (!modelsWithUpdates[update.model]) {
                    modelsWithUpdates[update.model] = 0;
                }
                modelsWithUpdates[update.model] += 1;
            }
        }

        return Object.entries(modelsWithUpdates)
            .map(([key, value]) => ({
                name: key,
                y: value,
            }))
            .sort((a, b) => b.y - a.y);
    }, [chartData]);

    return (
        // @ts-ignore
        <WidgetPaper
            // @ts-ignore
            title="Driver Updates Available"
            // @ts-ignore
            description="Percentage of devices with drivers that can be updated"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
                onClick={(e: any) => {
                    navigate.to(`windows/driver/list?driver.status.tab=0&search=${e.name}`);
                }}
            />
        </WidgetPaper>
    );
};
