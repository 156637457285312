import { Button, Collapse, Dialog, DialogContent, EmptyState, Grow, Icon, Input, LayoutCentered, Loading, TIconType, Tooltip } from '@capasystems/ui';
import React, { useState } from 'react';
import { useManagementApi } from '../hooks/useApi/useApi';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import { PageTitle } from '../page/page-title';
import { WithExperimentalFeatures } from '../thirdparty-components/thirdparty-components';

export type CapaOneContactDialogProps = {
    open: boolean;
    onClose: () => void;
    defaultSubject?: string;
};

export const CapaOneContactDialog: React.FC<CapaOneContactDialogProps> = ({ open, onClose, defaultSubject }) => {
    const managementApi = useManagementApi();
    const {
        user: { firstName, lastName, email, organization },
    } = useAuthContext();
    const [submitState, setSubmitState] = useState<{ loading?: boolean; sending?: boolean; sent?: boolean; anErrorOccurred?: boolean }>({
        sending: false,
        sent: false,
    });
    const [subject, setSubject] = useState(defaultSubject || '');
    const [message, setMessage] = useState('');

    const onSubmit = () => {
        setSubmitState({
            loading: true,
            sent: false,
        });
        managementApi
            .contactCapaSystems({
                subject,
                message,
                firstName,
                lastName,
                email,
                parentOrganizationId: organization.parentOrganizationId,
                organizationName: organization.name,
            })
            .then(() => {
                setSubmitState({
                    loading: true,
                    sent: true,
                });
                setMessage('');
            })
            .catch(() => {
                setSubmitState({
                    loading: false,
                    sent: false,
                    anErrorOccurred: true,
                });
            });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="md"
            onEntering={() => {
                setSubject(defaultSubject || '');
            }}
            onExited={() => {
                setSubmitState({
                    sending: false,
                    sent: false,
                });
            }}
        >
            <DialogContent className="tw-p-0">
                <div className="tw-grid tw-grid-cols-auto-1fr">
                    <div className="tw-min-h-112 tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-y-8 tw-bg-sky-50 tw-bg-gradient-to-r tw-from-sky-900 tw-to-sky-800 tw-p-4 tw-px-8 tw-pr-24 tw-text-white">
                        <div>
                            <h2>Contact</h2>
                            <h1>CapaSystems</h1>
                        </div>
                        <div className="tw-mst-6 tw-grid tw-grid-cols-auto-1fr tw-content-center tw-items-center tw-gap-x-4 tw-gap-y-8">
                            <div className="tw-rounded-full tw-bg-black/10 tw-p-3">
                                <Icon
                                    type="phone"
                                    className="tw-text-sky-50"
                                />
                            </div>
                            <div className="tw-font-medium">
                                <Tooltip content="Contact us by phone">
                                    <span className="tw-inline-block tw-font-medium tw-transition tw-ease-in-out hover:tw-scale-105">+45 70 10 70 55</span>
                                </Tooltip>
                            </div>
                            <div className="tw-rounded-full tw-bg-black/10 tw-p-3">
                                <Icon
                                    type="email"
                                    className="tw-text-sky-50"
                                />
                            </div>
                            <div>
                                <Tooltip content="Contact us by email">
                                    <a
                                        className="tw-inline-block tw-font-medium tw-transition tw-ease-in-out hover:tw-scale-105"
                                        href={`mailto:sales@capasystems.com?subject=${defaultSubject}`}
                                    >
                                        sales@capasystems.com
                                    </a>
                                </Tooltip>
                            </div>
                            <div className="tw-rounded-full tw-bg-black/10 tw-p-3">
                                <Icon
                                    type="web"
                                    className="tw-text-sky-50"
                                />
                            </div>
                            <div>
                                <Tooltip content="Website">
                                    <a
                                        className="tw-inline-block tw-font-medium tw-transition tw-ease-in-out hover:tw-scale-105"
                                        href="https://www.capasystems.com/contact"
                                        target="_BLANK"
                                        rel="noreferrer"
                                    >
                                        capasystems.com
                                    </a>
                                </Tooltip>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div>
                        {submitState.sent ? (
                            <LayoutCentered direction="column">
                                <div className="tw-text-center">
                                    <Grow
                                        in
                                        timeout={1000}
                                    >
                                        <Icon
                                            type="checkmarkOutlined"
                                            className="tw-h-12 tw-w-12 tw-text-emerald-400"
                                        ></Icon>
                                    </Grow>

                                    <div className="tw-mt-6 tw-text-base tw-font-semibold">Thank you, {firstName}</div>
                                    <div className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-600">We will get back to you shortly</div>
                                </div>
                                <Button
                                    noMargin
                                    className="tw-mt-6"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            </LayoutCentered>
                        ) : (
                            <div className="tw-grid tw-gap-8 tw-p-6 tw-px-8 tw-pt-4">
                                <div className="tw-grid tw-grid-cols-auto-1fr tw-items-center tw-gap-4">
                                    <div>
                                        <Icon
                                            type="userCircleOutlined"
                                            className="tw-h-12 tw-w-12"
                                        />
                                    </div>
                                    <PageTitle description={email}>
                                        {firstName} {lastName}
                                    </PageTitle>
                                </div>
                                <Input
                                    callToAction
                                    value={subject}
                                    label="I would like to hear more about"
                                    placeholder="Enter the CapaOne features you are interested in"
                                    labelClassName="tw--translate-y-2"
                                    inputClassName="tw-font-medium"
                                    light
                                    onChange={(e) => setSubject(e.target.value)}
                                    disabled={submitState.loading}
                                />
                                <Input
                                    callToAction
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    inputClassName="tw-py-2"
                                    light
                                    label="Message"
                                    placeholder="Please let us know if you have any special wishes or requirements"
                                    multiline
                                    rowsMax={2}
                                    labelClassName="tw--translate-y-2"
                                    autoFocus
                                    disabled={submitState.loading}
                                />

                                <div className="tw-flex tw-items-center tw-justify-end tw-gap-4">
                                    <Collapse in={submitState.anErrorOccurred || false}>
                                        <div className="tw-font-medium tw-text-rose-900">Someting went wrong, please try again</div>
                                    </Collapse>
                                    <Button
                                        noMargin
                                        callToAction
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        disabled={subject.trim() === '' || submitState.loading}
                                        startIcon={submitState.loading && <Loading size={12} />}
                                        onClick={onSubmit}
                                    >
                                        {submitState.loading ? 'Sending' : 'Send'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export type PremiumFeatureProps = {
    defaultSubject: string;
    productPage?: string;
    description: string;
    icon: TIconType;
};

export const PremiumFeature: React.FC<PremiumFeatureProps> = ({ defaultSubject, productPage, description, icon }) => {
    const [showContactDialog, setShowContactDialog] = useState(false);

    return (
        <div className="tw-grid tw-h-full tw-grid-cols-auto-1fr tw-align-baseline">
            <div className="tw-flex tw-h-full tw-w-120 tw-flex-col tw-items-center tw-justify-center tw-bg-sky-700 tw-p-4 tw-text-sky-50 tw-backdrop-blur-sm">
                <div className="tw-block tw-rounded-full tw-bg-sky-50 tw-p-4">
                    <Icon
                        type={icon}
                        className="tw-text-sky-700"
                    />
                </div>
                <div className=" tw-mt-4 tw-text-sm tw-font-semibold ">{defaultSubject} </div>
                <div className="tw-mb-6 tw-mt-1 tw-w-88 tw-max-w-full tw-text-center tw-text-xs tw-font-medium tw-leading-relaxed ">{description}</div>
                <div>
                    {productPage && (
                        <Button
                            size="large"
                            href={productPage}
                            target="_BLANK"
                            rel="noreferrer"
                            variant="contained"
                            noMargin
                            className="tw-bg-sky-50 tw-text-sky-700"
                        >
                            Learn more
                        </Button>
                    )}
                </div>
            </div>

            <div className="tw-h-full">
                <EmptyState
                    title="Subscription required"
                    iconType="lock"
                    description={`${defaultSubject} requires a paid subscription. Please contact us to get a quote or demo.`}
                >
                    <CapaOneContactDialog
                        open={showContactDialog}
                        onClose={() => setShowContactDialog(false)}
                        defaultSubject={defaultSubject}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        noMargin
                        onClick={() => setShowContactDialog(true)}
                    >
                        Contact
                    </Button>

                    <WithExperimentalFeatures>
                        <Button //This will be added anyway in security 1.8
                            color="primary"
                            size="large"
                            href={productPage}
                            target="_BLANK"
                            rel="noreferrer"
                            noMargin
                            variant="outlined"
                        >
                            Start trial
                        </Button>
                    </WithExperimentalFeatures>
                </EmptyState>
            </div>
        </div>
    );
};
