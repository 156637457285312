export const WindowsEnrollmentConfiguration = {
    schema: {
        title: 'Set up your enrollment configuration',
        description: 'An enrollment configuration represents a group of settings that control the behavior of the enrollment process of managed devices',
        type: 'object',
        schemaId: 'enrollmentConfiguration',
        properties: {
            name: {
                title: 'Name',
                description: 'Give your configuration a short, descriptive name that describes its purpose. For example, Sales team or Students.',
                type: 'string',
                required: true,
            },
            description: {
                title: 'Description',
                description: 'Describe your enrollment configuration',
                type: 'string',
                multiline: true,
            },
            relations: {
                title: 'Relations',
                description: `Assign groups, configurations and tags to endpoints during enrollment`,
                type: 'sectionheader',
            },
            groupRefIds: {
                title: 'Groups',
                description: 'Assigned during enrollment',
                multiple: true,
                type: 'array',
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
            },
            configurationRefIds: {
                title: 'Configurations',
                description: 'Assigned during enrollment',
                type: 'array',
                multiple: true,
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
            },
            tagRefIds: {
                title: 'Tags',
                description: 'Assigned during enrollment',
                type: 'array',
                multiple: true,
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
                minColumnWidth: 350,
                primaryContentClassName: 'tw-font-semibold',
                secondaryContentClassName: 'tw-text-tiny',
            },
        },
    },
};
