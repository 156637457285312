import { Highcharts } from '@capasystems/ui';
import React, { useRef } from 'react';
import colors from 'tailwindcss/colors';
import { chartFormatters } from '../constants-with-dependencies/constants-with-dependencies';

export enum eSize {
    small = 172,
    medium = 232,
    large = 305,
}

export enum eEntity {
    endpoints = 'endpoints',
    installation = 'installation',
    elevation = 'elevation',
}

export enum eClickType {
    legendClick = 'legendClick',
    pointClick = 'pointClick',
}

export type tPieChartProps = {
    /**
     * data to display in the chart
     * @type {unknown[]}
     */
    data: unknown[];
    /**
     * size of the chart
     * @default eSize.medium
     * @type {string} eSize
     */
    size: eSize;
    /**
     * entity to display in the legend
     * @default eEntity.endpoints
     * @type {string} eEntity
     */
    entity?: eEntity;
    /**
     * function to run on onClick
     * @param e - event
     * @param type - weither you clicked on a legend or a point
     * */
    onClick?: (e: unknown, type: eClickType) => void;
};

export const PieChart = ({ data = [], size = eSize.medium, entity = eEntity.endpoints, onClick }: tPieChartProps) => {
    const textRef = useRef<number>();

    textRef.current = data.reduce((prev: number, d: unknown) => {
        if (typeof d === 'object' && d !== null && 'y' in d && typeof d.y === 'number') {
            return prev + d.y;
        }
        return prev;
    }, 0) as number;

    return (
        <Highcharts
            // @ts-ignore
            options={{
                noData: {
                    style: {
                        fontWeight: 600,
                        fontSize: '12px',
                        color: 'inherit',
                    },
                },
                title: {
                    text: null,
                },
                lang: {
                    noData: 'No data to display',
                },
                chart: {
                    type: 'pie',
                    height: size,
                    spacing: [8, 24, 8, 0],
                    marginLeft: 0,
                    marginRight: 0,
                    spacingTop: 0,
                    spacingBottom: 8,
                    spacingLeft: 0,
                    spacingRight: 16,
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    events: {
                        render: function (this: any) {
                            const rend = this.renderer,
                                pie = this.series[0],
                                left = size / 2 + this.plotLeft,
                                top = this.plotHeight / 2 + this.plotTop - 10;

                            if (!textRef.current || size === eSize.small) {
                                this.customText?.attr({
                                    text: '',
                                });
                                return;
                            }

                            const text = `<div><div class="tw-font-semibold tw-text-2xl tw-text-center tw-leading-none">${textRef.current}</div><div class="tw-text-neutral-700 tw-text-xs tw-leading-none tw-text-center tw-font-semibold tw-mt-2">total</div></div>`;

                            if (!this.customText && this.renderer) {
                                this.customText = this.renderer
                                    .text(text, left, top, true)
                                    .attr({
                                        align: 'center',
                                    })
                                    .add();
                            }
                            this.customText.attr({
                                text,
                                x: left,
                                y: top,
                            });
                        },
                    },
                    // spacing: [0, 16, 8, 0]
                },
                legend: {
                    align: 'right', // or center, right
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    x: 0,
                    y: 0,
                    itemMarginTop: 4,
                    itemMarginBottom: 4,
                    navigation: {
                        activeColor: colors.blue[400],
                        inactiveColor: colors.neutral[100],
                    },
                    enabled: true,
                    floating: false,
                    symbolPadding: 8,
                    reversed: false,
                    width: null,
                    labelFormatter: chartFormatters.pie.legend.labelFormatter(entity),
                    itemStyle: {
                        cursor: onClick ? 'pointer' : 'default',
                    },
                },
                tooltip: {
                    borderRadius: 8,
                    padding: 16,
                    enabled: true,
                    outside: true,
                    shadow: false,
                    formatter: chartFormatters.pie.tooltip.formatter(entity),
                },
                plotOptions: {
                    series: {
                        allowPointSelect: true,
                        cursor: onClick ? 'pointer' : 'default',
                        dataLabels: {
                            enabled: false,
                        },
                        showInLegend: true,
                        innerSize: '75%',
                        borderWidth: data?.length ? 2 : 0,
                        center: [size / 2 - 16],
                    },
                },
                series: [
                    {
                        minPointSize: 10,
                        cursor: onClick ? 'pointer' : 'default',
                        data: data.map((d: any) => {
                            d.events = {
                                legendItemClick(e: any) {
                                    e.preventDefault();
                                    setTimeout(() => {
                                        if (onClick) {
                                            onClick(e.target, eClickType.legendClick);
                                        }
                                    });
                                },
                                click(e: any) {
                                    e.preventDefault();
                                    if (onClick) {
                                        onClick(e.point, eClickType.pointClick);
                                    }
                                },
                            };
                            return d;
                        }),
                    },
                ],
            }}
        />
    );
};
