//Documentation
//https://support.apple.com/da-dk/guide/deployment/depd57d3a18/web 
export const AppleCalendarConfiguration = {
    schema: {
        title: 'Calendar',
        type: 'object',
        schemaId: 'appleCalendarConfiguration',
        properties: {
            accountDescription: {
                title: 'Account description',
                description: 'The display name for the account.',
                type: 'string',
            },
            hostName: {
                title: 'Host Name',
                description: 'The IP address or fully qualified domain name (FQDN) of the CalDAV server.',
                type: 'string',
                required: true,
            }, 
            port: {
                title: 'Port',
                description: 'The port number of the CalDAV server',
                type: 'number',
                required: true,
            },
            principalURL: {
                title: 'Principal URL',
                description: 'The principal URL for the CalDAV account',
                type: 'string',
            },
            accountUserName: {
                title: 'Account User Name',
                description: 'The user name for the CalDAV account',
                type: 'string',
                required: true,
            },
            accountPassword: {
                title: 'Account Password',
                description: 'The password of the CalDAV account.',
                help: 'If you leave this field empty, users must enter their password after the payload is installed on the device.',
                type: 'string',
            },
            useSSL: {
                title: 'Use SSL',
                description: 'When the Use SSL option is selected and the server’s SSL certificate isn’t issued by a trusted certificate authority known to the devices.',
                help: 'Use the Certificates payload to add any root or intermediate certificates that are necessary to validate the server’s SSL certificate.',
                type: 'boolean',
            }
        },
    },
};