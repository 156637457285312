//Documentation
//https://developer.apple.com/documentation/devicemanagement/webclip

export const AppleWebClipConfiguration = {
    //Specify com.apple.webClip.managed as the payload type.
    schema: {
        schemaId: 'appleWebClipConfiguration',
        category: 'Web',
        title: 'Web Clip',
        description: 'A webclip appears as an application on the device but is just a weblink to a webpage',
        type: 'object',
        properties: {
            'com.apple.webClip.managed': {
                title: '',
                type: 'object',
                properties: {
                    URL: {
                        title: 'URL',
                        description: 'The URL to be displayed when opening the Web Clip',
                        help: 'If the URL doesn´t begin with HTTP or HTTPS, it doesn´t work.',
                        type: 'string',
                        required: true,
                    },
                    Label: {
                        title: 'Label',
                        description: 'The name to display for the Web Clip',
                        type: 'string',
                        required: true,
                    },
                    Icon: {
                        title: 'Icon',
                        description: 'The icon to use for the Web Clip',
                        tooltipContent: `The icon should be no larger than 400 x 400 pixels, png or jpeg.\n\nThe maximum allowed file size is 1 MB.`,
                        type: 'uploadbutton',
                        accept: '.jpeg,.png',
                        required: true,
                        shouldRender: () => false,
                    },
                    IsRemovable: {
                        title: 'Removable',
                        description: 'Enable removal of the Web Clip',
                        type: 'boolean',
                    },
                    FullScreen: {
                        title: 'Full Screen',
                        description: 'Enables the web clip as a full-screen web app.',
                        type: 'boolean',
                    },
                    IgnoreManifestScope: {
                        title: 'Ignore Manifest Scope',
                        description: 'Allow web pages that are not in the manifest to load in full screen mode',
                        type: 'boolean',
                        shouldRender: (siblings) => {
                            if (siblings.FullScreen === true) {
                                return true;
                            } else {
                                delete siblings.IgnoreManifestScope;
                                return false;
                            }
                        },
                    },
                    Precomposed: {
                        title: 'Precomposed',
                        description: 'The icon will be displayed with no added visual effects',
                        type: 'boolean',
                    },
                    TargetApplicationBundleIdentifier: {
                        title: 'Application bundle identifier',
                        description: 'Specifies the application which opens the URL. To use this property, the profile must be installed through an MDM.',
                        type: 'string',
                        options: [
                            {
                                id: 'com.apple.mobilesafari',
                                name: 'Safari',
                            },
                            {
                                id: 'com.google.chrome.ios',
                                name: 'Google Chrome',
                            },
                            {
                                id: 'org.mozilla.ios.Firefox',
                                name: 'Firefox',
                            },
                            {
                                id: 'com.microsoft.msedge',
                                name: 'Microsoft Edge',
                            },
                            {
                                id: 'com.brave.ios.browser',
                                name: 'Brave',
                            },
                            {
                                id: 'com.duckduckgo.mobile.ios',
                                name: 'DuckDuckGo',
                            },
                        ],
                    },
                },
            },
        },
    },
};
