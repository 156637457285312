export const LTE = {
    1: '2100 MHz',
    2: '1900 MHz',
    3: '1800 MHz',
    4: 'AWS',
    5: '850 MHz',
    6: '850MHz',
    7: '2600 MHz',
    8: '900 MHz',
    12: '700 MHz',
    13: '700c MHz',
    14: '700 PS',
    17: '700b MHz',
    18: '800 MHz',
    19: '800 MHz',
    20: '800 DD',
    25: '1900 MHz',
    26: '800 MHz',
    28: '700 APT MHz',
    39: 'TD 1900',
    40: 'TD 2300',
    41: 'TD 2500',
    66: 'AWS-3',
};
