import { noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { QUERY_BUILDER_REF_SOFTWARE_DASH } from '@thirdparty/constants';
import { leafsToMongo } from '@thirdparty/utils';
import { useEffect, useRef, useState } from 'react';
import {
    QueryBuilder,
    UpdaterApplicationCompliance,
    UpdaterApplicationInProgress,
    UpdaterApplicationUpToDate,
    UpdaterApplicationUpdateAvailable,
    UpdaterFailedInstall,
    useApi,
    useAuthContext,
} from '../../index';
import { UpdaterPermissionsCheck } from './updater-teaser';

export const UpdaterDashboard = ({ portalContainer }) => {
    const { permissions } = useAuthContext();
    const api = useApi();

    const [softwareData, setSoftwareData] = useState();
    const [filters, setFilters] = useState({ isInit: false, filter: [] });

    const queryBuilderTimeoutRef = useRef();
    const totalDevices = useRef();

    useEffect(() => {
        if (filters.isInit) {
            Promise.all([api.getSoftwareDashboard(JSON.stringify(leafsToMongo(filters.filter, filters.negations))), api.getWindowsDeviceCountBasedOnFilter()])
                .then(([softwareResponse, devicesResponse]) => {
                    totalDevices.current = devicesResponse;
                    setSoftwareData(softwareResponse);
                })
                .catch((errorResponse) => {
                    // TODO: Handle error
                    console.log(errorResponse);
                });
        }
    }, [filters, api]);

    const onMissingLeafData = async (leafRef, callback) => {
        api.cancel();
        leafRef.loading = true;
        try {
            const suggestions = await api.getDeviceFilterSuggestions({ key: leafRef.id.replace('dictionaries', 'hardwareInventory'), search: '' });
            leafRef.options = [
                ...new Set([
                    ...leafRef.value, // Make sure that deleted or outdated values are still shown.
                    ...suggestions,
                ]),
            ].map((suggestion) => ({ id: suggestion, name: suggestion }));
        } catch (_) {
            // Ignore
        }
        leafRef.loading = false;
        callback();
    };

    const onLeafTypeStringFocus = (data, callback) => {
        if (data.leaf.value.trim() === '') {
            onLeafTypeStringChange(data, callback);
        }
    };

    const onLeafTypeStringChange = ({ leaf, event }, callback) => {
        clearTimeout(queryBuilderTimeoutRef.current);
        api.cancel();
        const { selectionStart } = event.target; // Destruct here to ignore potential arrow-back clicks from user.
        queryBuilderTimeoutRef.current = setTimeout(() => {
            const strings = leaf.value.split(',');
            let searchString = leaf.value;
            if (strings.length > 1) {
                let charCount = 0;
                strings.some((s) => {
                    charCount += s.length + 1; // + 1 for the comma.
                    if (charCount > selectionStart) {
                        searchString = s.trim();
                        return true;
                    }
                    return false;
                });
            }
            api.getDeviceFilterSuggestions({ key: leaf.id.replace('dictionaries', 'hardwareInventory'), search: searchString })
                .then((suggestions) => {
                    leaf.suggestions = suggestions
                        .filter((suggestion) => !strings.includes(suggestion))
                        .map((suggestion) => ({ name: suggestion, id: suggestion }));
                })
                .catch(noop)
                .finally(callback);
            callback();
        }, 300);
        leaf.suggestions = [];
        callback();
    };

    return (
        <UpdaterPermissionsCheck>
            <Portal container={portalContainer}>
                <QueryBuilder
                    defaultConfiguration={QUERY_BUILDER_REF_SOFTWARE_DASH}
                    onInit={(filter, _, negations) => setFilters({ isInit: true, filter, negations })}
                    onSubmit={(filter, _, negations) => setFilters((prev) => ({ ...prev, filter, negations }))}
                    onMissingLeafData={onMissingLeafData}
                    onLeafTypeStringFocus={onLeafTypeStringFocus}
                    onLeafTypeStringChange={onLeafTypeStringChange}
                    showMongoHints
                />
            </Portal>
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <UpdaterApplicationCompliance
                    data={{ softwareData, totalDevices: totalDevices.current }}
                    className="tw-col-span-2 tw-h-72 2xl:tw-h-80"
                />
                <UpdaterApplicationUpdateAvailable
                    data={softwareData}
                    className="tw-col-span-2 lg:tw-col-span-1"
                />
                <UpdaterApplicationUpToDate
                    data={softwareData}
                    className="tw-col-span-2 lg:tw-col-span-1"
                />
                <UpdaterApplicationInProgress
                    data={softwareData}
                    className="tw-col-span-2 lg:tw-col-span-1"
                />
                <UpdaterFailedInstall
                    data={softwareData}
                    className="tw-col-span-2 lg:tw-col-span-1"
                />
            </div>
        </UpdaterPermissionsCheck>
    );
};

UpdaterDashboard.propTypes = {};
