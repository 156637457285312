import { SkipKeysElement } from './payloads/PayloadElements/SkipKeysElement';

export const AppleEnrollmentConfiguration = {
    schema: {
        title: 'Set up your enrollment configuration',
        description: 'An enrollment configuration represents a group of settings that control the behavior of the enrollment process of managed devices.',
        type: 'object',
        schemaId: 'enrollmentConfiguration',
        properties: {
            name: {
                title: 'Name',
                description: 'Give your configuration a short, descriptive name that describes its purpose. For example, Sales team or Students.',
                type: 'string',
                required: true,
            },
            description: {
                title: 'Description',
                description: 'Describe your enrollment configuration',
                type: 'string',
                multiline: true,
            },
            groupIds: {
                title: 'Groups',
                description: 'Assigned during enrollment.',
                multiple: true,
                type: 'array',
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
            },
            configurationIds: {
                title: 'Configurations',
                description: 'Assigned during enrollment.',
                type: 'array',
                multiple: true,
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
            },
            applicationIds: {
                title: 'Applications',
                description: 'Assigned during enrollment.',
                type: 'array',
                multiple: true,
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
                minColumnWidth: 350,
                primaryContentClassName: 'tw-font-semibold',
                secondaryContentClassName: 'tw-text-tiny',
            },
            depenrollment: {
                title: 'DEP Enrollment Profile',
                description: '',
                type: 'object',
                properties: {
                    userAuthenticationMethod: {
                        title: 'Authentication',
                        description:
                            'Authenticate users during the enrollment process.<div><b>Microsoft authentication requires Azure AD integration (Management &rarr; Integrations &rarr; Azure AD)</b></div>',
                        type: 'string',
                        required: true,
                        disabled: false,
                        tooltipEnabled: true,
                        options: [
                            {
                                id: 'NONE',
                                name: 'No user authentication',
                                default: true,
                            },
                            {
                                id: 'MICROSOFT',
                                name: 'Microsoft authentication',
                            },
                        ],
                    },
                    devices: {
                        title: 'Devices',
                        description: 'A list of device serial numbers.',
                        type: 'array',
                        multiple: true,
                        items: {
                            type: 'string',
                            options: [], // Lazy loaded
                        },
                    },
                    allow_pairing: {
                        title: 'Allow Pairing',
                        description: 'Allows users to connect supervised devices to a computer host.',
                        help: 'In iOS 13, this property was deprecated.',
                        type: 'boolean',
                    },
                    auto_advance_setup: {
                        title: 'Auto Advance Setup',
                        description: 'If enabled, the device will tell Setup Assistant to automatically advance though its screens.',
                        help: 'This key is valid in X-Server-Protocol-Version 2 and later.',
                        type: 'boolean',
                    },
                    is_mandatory: {
                        title: 'Mandatory',
                        description: 'If enabled, the user may not skip applying the profile returned by the MDM server.',
                        help: 'In iOS 13 and later, all DEP enrollments are mandatory.',
                        type: 'boolean',
                        required: true,
                        defaultValue: true,
                    },
                    is_mdm_removable: {
                        title: 'MDM Removable',
                        description:
                            'If disabled, the MDM payload delivered by the configuration URL cannot be removed by the user via the user interface on the device; that is, the MDM payload is locked onto the device.',
                        help: 'This key can be set to false only if is_supervised is set to true.',
                        type: 'boolean',
                        required: true,
                        defaultValue: false,
                    },
                    is_multi_user: {
                        title: 'Multi User',
                        description: 'If enabled, tells the device to configure for Shared iPad.',
                        help: 'This key is valid only for Apple School Manager or Apple Business Manager organizations using X-Server-Protocol-Version 2 and later. Devices that do not meet the Shared iPad minimum requirements do not honor this command. With iOS devices, com.apple.mdm.per-user-connections must be added to the MDM enrollment profile’s Server Capabilities.',
                        type: 'boolean',
                    },
                    skip_setup_items: {
                        title: 'Skip items',
                        description: 'A list of setup panes to skip.',
                        type: 'array',
                        items: {
                            type: 'string',
                            options: SkipKeysElement,
                        },
                    },
                    department: {
                        title: 'Department',
                        description: 'The user-defined department or location name.',
                        type: 'string',
                    },
                    anchor_certs: {
                        title: 'Anchor Certificates',
                        description: 'A list of certificates that are DER-encoded (Distinguished Encoding Rules) and converted to Base64 encoding.',
                        type: 'string', //TODO: each string should be a DER-encoded certificate
                    },
                    region: {
                        title: 'Region',
                        description: 'Choose which country you are in.',
                        help: 'Example: DK, US, FR',
                        type: 'string',
                    },
                    language: {
                        title: 'Language',
                        description: 'Choose which language that the profile should be on.',
                        help: 'Example: da, en, fr',
                        type: 'string',
                        required: true,
                        shouldRender: (siblings: any) => {
                            if (siblings.region) {
                                return true;
                            } else {
                                delete siblings.language;
                                return false;
                            }
                        },
                    },
                    org_magic: {
                        title: 'Managed Organization Services',
                        description: 'Uniquely identifies various services that are managed by a single organization.',
                        type: 'string',
                    },
                    supervising_host_certs: {
                        title: 'Supervising host certificates',
                        description: 'A list that contains DER-encoded certificates converted to Base concoding.',
                        help: 'If provided, the device will continue to pair with a host that possesses one of these certificates even when allow_pairing is set to false. If is_supervised is false, this list is unused.',
                        type: 'string',
                    },
                    support_email_address: {
                        title: 'Support Email Address',
                        description: 'A support email address for the organization.',
                        help: 'This key is valid in X-Server-Protocol-Version 2 and later.',
                        type: 'string',
                    },
                    support_phone_number: {
                        title: 'Support Phone Number',
                        description: 'A support phone number for the organization.',
                        type: 'string',
                    },
                },
            },
        },
    },
};
