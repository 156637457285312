import { Highcharts } from '@capasystems/ui';
import { SyncedHighcharts } from '@thirdparty/ui';
import React from 'react';
import colors from 'tailwindcss/colors';

const CHART_PROPERTIES = {
    column: {
        type: 'column',
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 8,
        spacing: [0, 0, 0, 0],
    },
    stackedColumn: {
        type: 'column',
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 8,
        spacing: [0, 0, 0, 0],
    },
    spline: {
        type: 'spline',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 8,
        spacing: [4, 0, 0, 0],
    },
    areaspline: {
        type: 'areaspline',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 1,
        spacing: [4, 0, 0, 0],
    },
};

export type StrippedChartProps = {
    type?: 'column' | 'stackedColumn' | 'spline' | 'areaspline';
    series: {
        name: string;
        unit: string;
        data: Array<{ x: number; y: number }>;
    }[];
    yAxis?: {
        max?: number;
        softMax?: number;
    };
    tooltip?: {
        formatter?: (this: any) => string;
    };
    syncKey?: string;
    syncVariant?: string;
    connectNulls?: boolean;
};

const StrippedChart: React.FC<StrippedChartProps> = ({
    type = 'column',
    series = [],
    yAxis = {},
    tooltip = {},
    syncKey = null,
    syncVariant,
    connectNulls = true,
    ...rest
}) => {
    const options = {
        lang: {
            noData: '<span class="tw-text-gray-400 tw-text-xs tw-font-medium">No data to display<br/><span>&nbsp;</span></span>',
        },
        chart: CHART_PROPERTIES[type],
        title: {
            text: null,
        },
        subtitle: {
            text: null,
        },
        xAxis: {
            labels: {
                enabled: false,
            },
            min: 0,
            gridLineWidth: 0,
            title: {
                text: null,
            },
            lineWidth: 0,
            tickLength: 0,
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            min: 0,
            max: yAxis.max,
            softMax: yAxis.softMax,
            title: {
                text: null,
            },
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
            lineWidth: 0,
        },
        tooltip: {
            backgroundColor: colors.slate[900],
            style: {
                color: colors.white,
            },
            borderWidth: 0,
            shared: true,
            borderRadius: 8,
            padding: 8,
            enabled: true,
            outside: true,
            shadow: false,
            formatter: tooltip.formatter,
        },
        plotOptions: {
            series: {
                showInLegend: false,
                clip: false,
                connectNulls,
                animation: true,
            },
            column: {
                stacking: type === 'stackedColumn' ? 'normal' : undefined,
                crisp: false,
                maxPointWidth: 12,
                borderRadius: 4,
                marker: {
                    enabled: false,
                },
                borderWidth: 2,
                states: {
                    hover: {
                        enabled: syncKey === null,
                    },
                },
            },
            spline: {
                lineWidth: 2,
                states: {
                    hover: {
                        lineWidth: 0,
                        lineWidthPlus: 0,
                        halo: {
                            size: 6,
                        },
                    },
                },
                marker: {
                    enabled: true,
                    radius: 1,
                    lineWidth: 0,
                },
            },
            areaspline: {
                fillOpacity: 0.08,
                lineWidth: 2,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
                // trackByArea: true
            },
        },
        series,
    };
    if (syncKey) {
        return (
            <SyncedHighcharts
                immutable
                options={options}
                syncKey={syncKey}
                variant={syncVariant}
                key={type}
                {...rest}
            />
        );
    }

    return (
        <Highcharts
            // @ts-ignore - not typed yet
            immutable
            options={options}
            key={type}
            {...rest}
        />
    );
};

export { StrippedChart };
