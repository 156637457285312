export const DRIVER_CATEGORY_ICON = {
    default: 'configureOutlined',
    'audio inputs and outputs': 'input_output',
    computer: 'laptopWindows',
    'device model': 'laptopWindows',
    'device info': 'laptopWindows',
    'dvd/cd-rom drives': 'disc',
    'disk drives': 'drive',
    'display adapters': 'displayAdapter',
    firmware: 'firmware',
    'human interface devices': 'humanInterface',
    'imaging devices': 'webcam',
    cameras: 'webcam',
    keyboards: 'keyboard',
    'mice and other pointing devices': 'mouse',
    monitors: 'monitor',
    'network adapters': 'lan',
    'ports (com & lpt)': 'ports',
    'print queues': 'printQueue',
    printers: 'printer',
    processors: 'cpu',
    'security devices': 'securityDevice',
    'software component': 'application',
    'software devices': 'application',
    'sound, video and game controllers': 'audio',
    'storage volumes': 'storage',
    'storage controllers': 'storage',
    'storage volume shadow copies': 'storage',
    'storage volume snapshots': 'storage',
    'system devices': 'desktop',
    'universal serial bus controllers': 'usb',
    'windows portable devices (wpd)': 'usbFlashDrive',
    'ide ata/atapi controllers': 'sata',
    'bluetooth devices': 'bluetooth',
    'battery devices': 'battery',
    'biometric device': 'biometric',
};

export const HARDWARE_INVENTORY_CATEGORY_ICON = {
    default: 'infoOutlined',
    'Bios Information': 'developerBoard',
    'Sound Devices': 'audio',
    Memory: 'memory',
    Drives: 'drive',
    'IP Configuration': 'settingsEthernet',
    'Anti-Virus': 'antivirus',
    Batteries: 'battery',
    'Agent Information': 'capaInstaller',
    Keyboards: 'keyboard',
    Monitors: 'monitor',
    Computer: 'computer',
    Disks: 'storage',
    'Operating System': 'windows',
    Mice: 'mouse',
    Processors: 'cpu',
    'Network Adapters': 'lan',
    'Display Adapters': 'displayAdapter',
    'Trusted Platform Module': 'beenhereOutlined',
    'SCSI Controllers': 'infoOutlined',
};

export const SECURITY_INVENTORY_CATEGORY_ICON = {
    default: 'summarizeOutlined',
    Summary: 'privacyTip',
    Antivirus: 'antivirus',
    Firewall: 'firewall', // For summary section.
    'Firewall Products': 'firewall', // Deprecated. Remove in next release.
    'Firewall Profiles': 'firewall', // Deprecated. Remove in next release.
    'Windows Update': 'softwarePatches',
    'Local Administrators': 'adminPanelSettings',
    'Pending Reboot': 'pendingReboot',
    Drives: 'drive',
    Encryption: 'drive',
};

export const SECURITY_INVENTORY_CATEGORY = {
    ANTIVIRUS: 'Antivirus',
    ENCRYPTION: 'Encryption',
    FIREWALL: 'Firewall',
    SUMMARY: 'Summary',
    PENDING_REBOOT: 'Pending Reboot',
    WINDOWS_UPDATE: 'Windows Update',
    LOCAL_ADMINISTRATORS: 'Local Administrators',
    PATCHES: 'Patches', // TODO: Legacy category. Remove in future releases as it will still work, but UI will be card-based.
};

export const SECURITY_SCORE_NAME = {
    GOOD: 'Good',
    FAIR: 'Fair',
    POOR: 'Poor',
    NONE: 'None',
};

export const SECURITY_SCORE_NAME_BASED_STYLING = {
    [SECURITY_SCORE_NAME.GOOD]: 'tw-bg-emerald-100 tw-text-emerald-900',
    [SECURITY_SCORE_NAME.FAIR]: 'tw-bg-amber-100 tw-text-amber-900',
    [SECURITY_SCORE_NAME.POOR]: 'tw-bg-red-100 tw-text-red-900',
};
