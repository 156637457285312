import { AutoComplete, Divider, Icon } from '@capasystems/ui';
import { cloneDeep, now } from '@capasystems/utils';
import classNames from 'classnames';
import { forwardRef, useRef, useState } from 'react';
import { ActionsDialog } from '../thirdparty-components/thirdparty-components';
import { QueryBuilderEndAdornment } from './query-builder-end-adornment';

const QueryBuilderPrimaryLeafComponent = forwardRef(
    ({ applyChanges, onLeafTypeStringChange, onLeafTypeStringFocus, secondaryLeafCount, showMongoHints, children }, primaryLeafRef) => {
        const primaryInputRef = useRef(null);
        const [, setLastRefresh] = useState(0);
        const [actionsDialogState, setActionsDialogState] = useState({
            anchorEl: null,
            open: false,
            actions: [],
        });

        const forceUpdate = () => {
            setLastRefresh(now());
        };

        const onPrimaryLeafClear = (e) => {
            primaryLeafRef.value = '';
            primaryLeafRef.suggestions = [];
            forceUpdate();
            primaryInputRef.current.focus();
        };

        const onKeyDown = ({ target, key }) => {
            if (key === 'Enter' && target) {
                target.blur();
            }
        };

        const onPrimaryLeafBlur = () => {
            if (primaryLeafRef.value !== primaryLeafRef.privateState?.initial.value) {
                applyChanges();
                primaryLeafRef.privateState.initial.value = cloneDeep(primaryLeafRef.value); // Set it after apply to trigger the dirty check.
            }
        };

        const onPrimaryLeafChange = (autoCompleteValueNow, event) => {
            const { target } = event;
            primaryLeafRef.value = target.value || '';
            primaryLeafRef.selected = true;
            primaryLeafRef.suggestions = [];
            onLeafTypeStringChange({ leaf: primaryLeafRef, event }, forceUpdate);
            forceUpdate();
        };

        const onPrimaryLeafSuggestionSelect = (suggestion, event) => {
            const { selectionStart } = primaryInputRef.current;
            const strings = primaryLeafRef.value.split(',');
            if (strings.length > 1) {
                let charCount = 0;
                strings.some((s, index) => {
                    charCount += s.length + 1; // + 1 for the comma.
                    if (charCount > selectionStart) {
                        strings[index] = suggestion.name;
                        return true;
                    }
                    return false;
                });
                primaryLeafRef.value = strings.join();
            } else {
                primaryLeafRef.value = suggestion.name;
            }
            forceUpdate();
            if (event.key === 'Enter') {
                primaryInputRef.current.blur();
            }
            primaryLeafRef.suggestions = [];
        };

        const setPrimaryInputRef = (ref) => {
            primaryInputRef.current = ref;
        };

        const openActionsDialog = (e) => {
            if (primaryLeafRef.operators?.length > 1) {
                setActionsDialogState({
                    anchorEl: e.currentTarget,
                    open: true,
                    actions: primaryLeafRef.operators.map((operator) => ({
                        id: operator.id,
                        name: operator.name,
                        icon: operator.id === primaryLeafRef.operator.id ? 'checkmark' : undefined,
                        data: operator,
                    })),
                });
            }
        };

        const closeActionsDialog = () => {
            setActionsDialogState((c) => ({ ...c, open: false }));
        };

        const onActionClick = (action) => {
            if (action.id !== primaryLeafRef.operator.id) {
                primaryLeafRef.operator = action.data;
                forceUpdate();
                applyChanges();
                primaryLeafRef.privateState.initial.operator = action.data;
            }
            closeActionsDialog();
        };

        if (primaryLeafRef) {
            return (
                <>
                    <AutoComplete
                        onKeyDown={onKeyDown}
                        onBlur={onPrimaryLeafBlur}
                        autoFocus
                        fullWidth
                        searchTerm={primaryLeafRef.value || ''}
                        value={primaryLeafRef.value || ''}
                        suggestions={primaryLeafRef.suggestions || []}
                        onSelect={onPrimaryLeafSuggestionSelect}
                        onSearch={onPrimaryLeafChange}
                        focusInputOnSuggestionClick={false}
                        callToAction
                        containerClassName="tw-w-full"
                        inputClassName="tw-font-medium tw-text-xs"
                        light
                        dense
                        autoComplete="off"
                        type="text"
                        startAdornment={
                            <span
                                onClick={openActionsDialog}
                                className={classNames('tw-flex tw-items-center tw-py-3 tw-text-tiny tw-font-medium tw-text-gray-600', {
                                    'tw-cursor-pointer hover:tw-text-gray-900': primaryLeafRef.operators?.length > 1,
                                })}
                            >
                                {primaryLeafRef.operator.name}
                                {primaryLeafRef.operators?.length > 1 && (
                                    <Icon
                                        type="chevronDown"
                                        className="tw-ml-1 tw-text-xs tw-text-inherit"
                                    />
                                )}
                            </span>
                        }
                        endAdornment={
                            <div className="tw-flex tw-items-center">
                                <QueryBuilderEndAdornment
                                    value={primaryLeafRef.value}
                                    onClick={onPrimaryLeafClear}
                                    showMongoHints={showMongoHints}
                                    placeholder={primaryLeafRef.placeholder}
                                />
                                {secondaryLeafCount > 0 && (
                                    <>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            className="tw-ml-3"
                                            light
                                        />
                                        {children}
                                    </>
                                )}
                            </div>
                        }
                        endAdornmentClassName={classNames({
                            'tw-mr-0': secondaryLeafCount > 0,
                        })}
                        placeholder={primaryLeafRef.placeholder}
                        maxPopperHeight="50vh"
                        disableTextSelectOnFocus
                        inputRef={setPrimaryInputRef}
                        inputProps={{
                            containerClassName: 'tw-pr-0',
                        }}
                    />

                    <ActionsDialog
                        category="Compare method for"
                        title={primaryLeafRef.placeholder}
                        open={actionsDialogState.open}
                        anchorEl={actionsDialogState.anchorEl}
                        actions={actionsDialogState.actions}
                        onActionClick={onActionClick}
                        onClose={closeActionsDialog}
                        transformOrigin={{ vertical: 4, horizontal: 16 }}
                    />
                </>
            );
        }
        return children;
    }
);

export { QueryBuilderPrimaryLeafComponent };
