/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { BUTTON, coreMessage } from '@capasystems/constants';
import {
    epoch,
    formatTimestamp,
    getDefaultTimelineChartConfiguration,
    getTheme,
    getTimelineChartAreasplinerangeFillOpacity,
    getTimelineChartAreasplinerangeLineWidthPlus,
    getTimelineChartLineMarkerEnabled,
    getTimelineChartLineWidth,
    getTimelineChartMarkerRadius,
    getUniqueId,
    isDefined,
    isFunction,
    isUndefined,
    now,
    tickPositioner,
} from '@capasystems/utils';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more'; /** Support for bubble series */
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import { cloneDeep, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {
    Button,
    ContextDialog,
    Icon,
    IconButton,
    Input,
    LayoutCenter,
    LayoutColumn,
    LayoutFill,
    Loading,
    Menu,
    MenuItem,
    Padding,
    Toolbar,
    Tooltip,
} from '../../index';

const {
    capasystems: { spacing },
} = getTheme();

addHighchartsMore(Highcharts);
addNoDataModule(Highcharts);

/** CapaSystems timeline chart. Support spline, line and column series. */
class TimelineChart extends React.Component {
    static defaultProps = {
        htmlId: '',
        actions: undefined,
        categories: undefined,
        series: [],
        staticThresholds: [],
        dynamicThresholds: [],
        hidden: false,
        onCategoryClick: undefined,
        onAxisChange: null,
        connectNulls: false,
        connectPoints: true,
        onPeriodChange: undefined,
        configure: undefined,
        loading: false,
        className: undefined,
        children: undefined,
        /* istanbul ignore next */
        onPointsConnectChange: () => null,
        extremes: {},
    };

    /* istanbul ignore next */
    showingUnitState = {};

    /* istanbul ignore next */
    GRIDLINES_ID = 'gridlines';

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.chartId = props.htmlId ? props.htmlId : getUniqueId('cs-timeline-chart-');
        this.hasCategories = isDefined(props.categories);
        this.addedSeriesIds = [];
        this.addedThresholdIds = [];
        this.addedDynamicThreshodIds = [];
        this.prefixes = {
            yAxis: 'yAxis-',
            series: 'series-',
            threshold: 'threshold-',
        };
        this.zIndexes = {
            rangeSeries: 1,
            series: 2,
            thresholds: 3,
            thresholdLabels: 4,
        };
        this.sizes = {
            // Let each yAxis label have a max width. Text will either break at spaces or add ellipsis.
            yAxisWidthPixels: 150,
            lineWidth: 1,
            anchorEl: null,
        };
        const { connectNulls, connectPoints } = props;
        this.state = {
            connectNulls,
            connectPoints,
            pointsConnectDialogIsOpen: false,
            yAxis: {},
            anchorEl: null,
            isOpen: false,
        };
        this.removeAllSeries = this.removeAllSeries.bind(this);
        this.setTitle = this.setTitle.bind(this);
        this.removeSeries = this.removeSeries.bind(this);
        this.hooks = {
            setTitle: this.setTitle,
            getPlotWidth: this.getPlotWidth,
        };
        this.updateExtremesTimeout = null;
    }

    /* istanbul ignore next */
    componentDidMount() {
        const { onPeriodChange, configure, connectPoints, connectNulls } = this.props;
        const useBuiltInZoom = !isFunction(onPeriodChange);
        this.chartConfiguration = getDefaultTimelineChartConfiguration(this.hasCategories, connectPoints, connectNulls);
        if (useBuiltInZoom) {
            delete this.chartConfiguration.chart.resetZoomButton.theme.display;
        } else {
            this.chartConfiguration.chart.events.selection = this.onSelection;
        }
        if (isFunction(configure)) {
            configure(this.chartConfiguration, this.hooks);
        }
        this.chart = Highcharts.chart(this.chartId, this.chartConfiguration);
    }

    /* istanbul ignore next */
    componentDidUpdate() {
        const { period, categories, series, staticThresholds, dynamicThresholds, onCategoryClick } = this.props;
        if (
            isEqual(this.period, period) &&
            isEqual(this.categories, categories) &&
            isEqual(this.series, series) &&
            isEqual(this.staticThresholds, staticThresholds) &&
            isEqual(this.dynamicThresholds, dynamicThresholds)
        ) {
            return;
        }
        this.period = { ...period };
        this.series = cloneDeep(series);
        this.staticThresholds = [...staticThresholds];
        this.dynamicThresholds = [...dynamicThresholds];

        this.chart.xAxis[0].setExtremes(period.start, period.end);
        if (this.hasCategories) {
            this.categories = [...categories];
            this.addAxis('');
            this.chart.yAxis[0].setCategories(categories);
            this.chart.yAxis[0].setExtremes(0, categories.length > 0 ? categories.length - 1 : 0);
            if (this.series.length > 0) {
                const [{ maxSize = 0 }] = this.series;
                this.chart.update({
                    chart: {
                        scrollablePlotArea: {
                            minHeight: this.categories.length * maxSize,
                        },
                    },
                });
            }
            if (onCategoryClick) {
                setTimeout(() => {
                    if (this.chart.yAxis) {
                        this.chart.yAxis[0].labelGroup.element.childNodes.forEach((label, index) => {
                            label.style.cursor = 'pointer';
                            label.onclick = () => {
                                onCategoryClick(index);
                            };
                        });
                    }
                }, 200);
            }
        } else {
            setTimeout(() => {
                if (this.chart.yAxis) {
                    this.chart.yAxis.forEach((axis) => {
                        if (axis.axisTitle) {
                            axis.axisTitle.element.style.cursor = 'pointer';
                            axis.axisTitle.element.onclick = () => {
                                this.hideYaxisRelatedSeries(axis.axisTitle.textStr, axis);
                            };
                        }
                    });
                }
            }, 200);
        }

        const currentSeriesIds = [];
        const currentThresholdIds = [];
        const currentDyncamicThresholdIds = [];

        this.prepareSeries(series, currentSeriesIds);
        const removedSeriesIds = this.addedSeriesIds.filter((i) => currentSeriesIds.indexOf(i) < 0);

        removedSeriesIds.forEach((id) => {
            this.removeSeries(id);
        });

        this.chart.yAxis.forEach((axis) => {
            axis.update(
                {
                    minRange: 0,
                    plotLines: [],
                },
                false
            );
        });

        this.prepareStaticThresholds(staticThresholds, currentThresholdIds);

        this.prepareDynamicThresholds(dynamicThresholds, currentDyncamicThresholdIds);

        this.removeDynamicThresholds(currentDyncamicThresholdIds);

        this.addedThresholdIds = currentThresholdIds;
        this.addedSeriesIds = currentSeriesIds;
        this.addedDynamicThreshodIds = currentDyncamicThresholdIds;

        this.chart.redraw(false);
        this.chart.reflow();
        setTimeout(this.setMouseEvents, 150);
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        this.chart.destroy();
    }

    /* istanbul ignore next */
    onSelection = ({ xAxis, preventDefault }) => {
        // Call preventDefault to prevent HighCharts from setting xAxis extremes behind the scenes.
        preventDefault();
        this.onPeriodChange(Math.floor(xAxis[0].min), Math.floor(xAxis[0].max));
    };

    /* istanbul ignore next */
    onPeriodChange = (start, end) => {
        const { onPeriodChange } = this.props;
        onPeriodChange(start, end);
    };

    /* istanbul ignore next */
    legendItemInteraction = (series, isMouseOver) => {
        if (series.visible) {
            this.chart.yAxis.forEach((axis) => {
                if (axis.userOptions.isAdjustable) {
                    axis.series.forEach((axisSeries) => {
                        if (series.userOptions.id !== axisSeries.userOptions.id && series.userOptions.id !== axisSeries.userOptions.linkedTo) {
                            if (isMouseOver) {
                                axisSeries.setVisible(false, false);
                            } else if (axisSeries.userOptions.manuallyHidden !== true) {
                                axisSeries.setVisible(true, false);
                            }
                        }
                        if (series.userOptions.id === axisSeries.userOptions.linkedTo && series.userOptions.manuallyHidden) {
                            axisSeries.userOptions.manuallyHidden = true;
                        }
                    });
                    axis.plotLinesAndBands.forEach((plotLine) => {
                        if (series.userOptions.id !== plotLine.options.linkedTo) {
                            if (isMouseOver) {
                                plotLine.svgElem.addClass('hidden');
                                plotLine.label.addClass('hidden');
                            } else if (plotLine.options.manuallyHidden !== true) {
                                plotLine.svgElem.removeClass('hidden');
                                // Application Performance metrics without threshold has label as null
                                if (plotLine.label !== null) {
                                    plotLine.label.removeClass('hidden');
                                }
                            }
                        }
                    });
                }
            });
        }

        this.chart.redraw(false);
    };

    /* istanbul ignore next */
    setMouseEvents = () => {
        this.chart.series.forEach((series) => {
            if (series.legendItem && series.legendItem.element.onmouseover === null) {
                series.legendItem
                    .on('mouseover', () => {
                        this.legendItemInteraction(series, true);
                    })
                    .on('mouseout', () => {
                        this.legendItemInteraction(series, false);
                    })
                    .on('click', () => {
                        if (series.visible) {
                            series.userOptions.manuallyHidden = true;
                            this.legendItemInteraction(series, false);
                            const yAxis = this.chart.get(this.prefixes.yAxis + series.userOptions.unit);
                            yAxis.plotLinesAndBands.forEach((plotLine) => {
                                if (series.userOptions.id === plotLine.options.linkedTo) {
                                    plotLine.options.manuallyHidden = true;
                                    plotLine.svgElem.addClass('hidden');
                                    plotLine.label.addClass('hidden');
                                }
                            });
                        } else {
                            delete series.userOptions.manuallyHidden;
                            const yAxis = this.chart.get(this.prefixes.yAxis + series.userOptions.unit);
                            yAxis.plotLinesAndBands.forEach((plotLine) => {
                                if (series.userOptions.id === plotLine.options.linkedTo) {
                                    delete plotLine.options.manuallyHidden;
                                    plotLine.svgElem.removeClass('hidden');
                                    // Application Performance metrics without threshold has label as null
                                    if (plotLine.label !== null) {
                                        plotLine.label.removeClass('hidden');
                                    }
                                }
                            });
                            setTimeout(() => {
                                this.legendItemInteraction(series, true);
                            });
                        }
                    });
            }
        });
        this.chart.redraw(true);
    };

    /* istanbul ignore next */
    hideYaxisRelatedSeries = (toHide, yAxis) => {
        this.chart.series.forEach((serie) => {
            if (serie.userOptions.unit === toHide) {
                if (this.showingUnitState[toHide] === undefined || this.showingUnitState[toHide]) {
                    serie.update({
                        visible: false,
                    });
                    yAxis.update({
                        showEmpty: true,
                    });
                } else {
                    serie.update({
                        visible: true,
                    });
                    yAxis.update({
                        showEmpty: false,
                    });
                }
            }
        });
        if (this.showingUnitState[toHide] === undefined || this.showingUnitState[toHide]) {
            this.showingUnitState[toHide] = false;
        } else {
            this.showingUnitState[toHide] = true;
        }
        yAxis.axisTitle.element.style.cursor = 'pointer';
        yAxis.axisTitle.element.onclick = () => {
            this.hideYaxisRelatedSeries(toHide, yAxis);
        };
    };

    /* istanbul ignore next */
    getPlotWidth = () => {
        const { series } = this.props;
        if (series.length > 0) {
            return this.chart.plotWidth;
        }
        return this.chart.plotWidth - 150; //
    };

    /* istanbul ignore next */
    zoomOut = () => {
        const { period } = this.props;
        let unit = 'days';
        let thresholds;
        let unitsInInterval;
        // eslint-disable-next-line no-param-reassign
        period.end = Math.min(period.end, now());
        if (dayjs(period.end).diff(period.start, unit) > 0) {
            unitsInInterval = dayjs(period.end).diff(period.start, unit);
            thresholds = [3, 7, 30, 90, 180, 365, 365 + unitsInInterval];
        } else {
            unit = 'minutes';
            unitsInInterval = dayjs(period.end).diff(period.start, unit);
            thresholds = [5, 30, 60, 240, 720, 1440, 1440 + unitsInInterval];
        }
        const diffToNow = dayjs().diff(dayjs(period.end), unit);
        let selectedIndex = 0;
        thresholds.forEach((units, index) => {
            if (unitsInInterval > units - Math.ceil(units * 0.33)) {
                selectedIndex = Math.min(index + 1, thresholds.length - 1);
            }
        });
        let unitCountStart = (thresholds[selectedIndex] - unitsInInterval) / 2;
        const unitCountEnd = (thresholds[selectedIndex] - unitsInInterval) / 2;
        if (unitCountEnd > diffToNow) {
            unitCountStart += unitCountEnd - diffToNow;
        }

        period.start = dayjs.max(dayjs(period.start).subtract(unitCountStart, unit).startOf(unit), dayjs(epoch())).toDate().getTime();
        period.end = dayjs.min(dayjs(period.end).add(unitCountEnd, unit).endOf(unit), dayjs()).toDate().getTime();

        this.period = period;
        clearTimeout(this.updatePeriodTimout);
        this.updatePeriodTimout = setTimeout(() => {
            this.onPeriodChange(period.start, period.end);
        }, 750);
        this.chart.xAxis[0].setExtremes(period.start, period.end);
        this.forceUpdate();
    };

    /* istanbul ignore next */
    validateExtremes = (name, unit) => (event) => {
        const { value } = event.target;
        this.setState(
            (state) => ({
                yAxis: {
                    ...state.yAxis,
                    [unit]: {
                        ...state.yAxis[unit],
                        [name]: value && Number(value),
                    },
                },
            }),
            () => {
                const { yAxis } = this.state;
                let invalidInput = false;
                // eslint-disable-next-line no-restricted-globals
                if (isNaN(value)) {
                    invalidInput = true;
                    return;
                }
                invalidInput = yAxis[unit].min !== null && yAxis[unit].min >= (yAxis[unit].max || this.chart.get(`yAxis-${unit}`).max);
                // We introduce a delay, so we dont update every keystroke, but wait untill the user has finished typing
                if (this.updateExtremesTimeout) {
                    clearTimeout(this.updateExtremesTimeout);
                }
                if (!invalidInput) {
                    this.updateExtremesTimeout = setTimeout(() => {
                        this.updateExtremes(unit);
                        this.updateExtremesTimeout = null;
                    }, 500);
                }
            }
        );
    };

    /* istanbul ignore next */
    updateExtremes = (unit) => {
        const { yAxis } = this.state;
        const axis = this.chart.get(`yAxis-${unit}`);
        if (axis) {
            // eslint-disable-next-line no-nested-ternary
            axis.setExtremes(yAxis[unit].min ? yAxis[unit].min : 0, yAxis[unit].max ? yAxis[unit].max : null);
            // eslint-disable-next-line no-nested-ternary
            axis.update({
                max: yAxis[unit].max ? yAxis[unit].max : unit === '%' ? 100 : null,
            });
        }

        axis.axisTitle.element.style.cursor = 'pointer';
        axis.axisTitle.element.onclick = () => {
            this.hideYaxisRelatedSeries(unit, axis);
        };

        const { onAxisChange } = this.props;
        if (onAxisChange) {
            const axisUpdated = {};
            Object.keys(yAxis).forEach((key) => {
                if (yAxis[key].min || yAxis[key].max) {
                    axisUpdated[key] = {
                        min: yAxis[key].min || 0,
                        max: yAxis[key].max || (key === '%' ? 100 : null),
                    };
                }
            });
            onAxisChange(axisUpdated);
        }
    };

    /* istanbul ignore next */
    handleReset = () => {
        const { series, onAxisChange } = this.props;
        if (series[0]) {
            const axes = this.chart.yAxis;
            axes.forEach((axis) => {
                if (axis.userOptions.isAdjustable) {
                    const thisYAxis = this.chart.get(axis.userOptions.id);
                    thisYAxis.setExtremes(null, null);
                    thisYAxis.update({
                        min: 0,
                        max: axis.userOptions.unit === '%' ? 100 : null,
                    });
                    thisYAxis.axisTitle.element.style.cursor = 'pointer';
                    thisYAxis.axisTitle.element.onclick = () => {
                        this.hideYaxisRelatedSeries(thisYAxis.userOptions.unit, thisYAxis);
                    };
                }
            });

            this.setState({
                yAxis: {},
            });
            if (onAxisChange) {
                onAxisChange({});
            }
        }
    };

    /* istanbul ignore next */
    openPointsConnectChangeDialog = (e) => {
        this.setState({
            pointsConnectDialogIsOpen: true,
            anchorEl: e.currentTarget,
        });
    };

    /* istanbul ignore next */
    closePointsConnectChangeDialog = () => {
        this.setState({
            pointsConnectDialogIsOpen: false,
            anchorEl: null,
        });
    };

    /* istanbul ignore next */
    onPointsConnectChange = (connectNulls, connectPoints) => () => {
        const { onPointsConnectChange } = this.props;
        this.chart.update({
            plotOptions: {
                spline: {
                    lineWidth: getTimelineChartLineWidth(connectPoints),
                    connectNulls,
                    marker: {
                        enabled: getTimelineChartLineMarkerEnabled(connectPoints, connectNulls),
                        radius: getTimelineChartMarkerRadius(connectPoints),
                    },
                },
                areasplinerange: {
                    fillOpacity: getTimelineChartAreasplinerangeFillOpacity(connectPoints),
                    connectNulls,
                    states: {
                        hover: {
                            lineWidthPlus: getTimelineChartAreasplinerangeLineWidthPlus(connectPoints),
                        },
                    },
                    marker: {
                        enabled: getTimelineChartLineMarkerEnabled(connectPoints, connectNulls),
                        radius: getTimelineChartMarkerRadius(connectPoints),
                    },
                },
            },
        });
        this.setState({
            connectNulls,
            connectPoints,
            pointsConnectDialogIsOpen: false,
            anchorEl: null,
        });
        onPointsConnectChange({
            connectNulls,
            connectPoints,
        });
    };

    /* istanbul ignore next */
    changePeriod = (period, moveForward) => () => {
        let offset = (period.end - period.start) / 2;
        if (moveForward) {
            if (period.end + offset > now()) {
                offset = now() - period.end;
            }
            period.start += offset;
            period.end += offset;
        } else {
            if (period.start - offset < epoch()) {
                offset = epoch() + period.start;
            }
            period.start -= offset;
            period.end -= offset;
        }
        this.period = period;
        clearTimeout(this.updatePeriodTimout);
        this.updatePeriodTimout = setTimeout(() => {
            this.onPeriodChange(period.start, period.end);
        }, 750);
        this.chart.xAxis[0].setExtremes(period.start, period.end);
        this.forceUpdate();
    };

    /* istanbul ignore next */
    removeAllSeries = () => {
        // slice(0) will clone the series.
        this.chart.series.slice(0).forEach((series) => {
            this.removeSeries(series.userOptions.id);
        });
    };

    /* istanbul ignore next */
    setTitle = (newTitle) => {
        this.chart.setTitle({ text: newTitle });
    };

    /* istanbul ignore next */
    removeSeries = (seriesId) => {
        const hcs = this.chart.get(seriesId);
        if (isDefined(hcs) && hcs.userOptions.removable) {
            hcs.remove(false, false);
        }
    };

    /* istanbul ignore next */
    addAxis = (unit = '') => {
        if (isUndefined(this.chart.get(this.prefixes.yAxis + unit))) {
            const { extremes } = this.props;
            this.setState((state) => ({
                yAxis: {
                    ...state.yAxis,
                    [unit]: {
                        min: extremes[unit] ? extremes[unit].min : '',
                        // eslint-disable-next-line no-nested-ternary
                        max: extremes[unit] ? extremes[unit].max : '',
                    },
                },
            }));
            const yAxisObject = {
                id: this.prefixes.yAxis + unit,
                isAdjustable: true,
                unit,
                tickmarkPlacement: 'on',
                showEmpty: false,
                title: {
                    text: unit,
                },
                min: extremes[unit] ? extremes[unit].min : 0,
                // eslint-disable-next-line no-nested-ternary
                max: extremes[unit] ? extremes[unit].max : unit === '%' ? 100 : null,
                labels: {
                    style: {
                        width: this.sizes.yAxisWidthPixels,
                        wordWrap: 'break-word',
                    },
                    formatter() {
                        if (this.value >= 10000) {
                            return this.value.toLocaleString();
                        }
                        return this.value;
                    },
                },
            };

            if (this.chartConfiguration.chart.alignTicks === false) {
                yAxisObject.gridLineWidth = 0;
                yAxisObject.tickPositioner = tickPositioner;
            }
            this.chart.addAxis(yAxisObject, false, false);
        }
    };

    /* istanbul ignore next */
    getUnique = (arr, comp) => {
        const unique = arr
            .map((e) => e[comp])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e) => arr[e])
            .map((e) => arr[e]);
        return unique;
    };

    /* istanbul ignore next */
    prepareDynamicThresholds = (dynamicThresholds, currentDyncamicThresholdIds) => {
        dynamicThresholds.forEach((threshold) => {
            const isExisting = this.chart.get(`dynamic-threshold-${threshold.id}`);
            if (isExisting) {
                isExisting.remove(false, false);
            }
            currentDyncamicThresholdIds.push(`dynamic-threshold-${threshold.id}`);
            this.chart.addSeries(
                {
                    id: `dynamic-threshold-${threshold.id}`,
                    enableMouseTracking: false /* Do not highlight the series when hovering the chart. */,
                    dashStyle: 'Dash',
                    step: 'left' /* https://api.highcharts.com/highcharts/plotOptions.series.step */,
                    isDynamicThreshold: true,
                    linkedTo: threshold.linkedTo,
                    name: threshold.id, // Shown in legend if legend is enabled.
                    type: 'line',
                    zIndex: 3, // Must be in front of standard series and potential arearanges.
                    showInLegend: false,
                    lineWidth: 1,
                    marker: {
                        enabled: false,
                    },
                    dataLabels: {
                        allowOverlap: false /* Overlapping dataLabels will be hidden, ie. only the last added label will be visisble. Otherwise all the dataLabels will become unreadable. */,
                        x: 0,
                        zIndex: 4, // Place the dataLabel above the series line.
                        style: {
                            textOutline: 'none',
                            color: Highcharts.theme.capasystems.palette.danger.dark,
                            fontWeight: '400',
                            fontSize: Highcharts.theme.fontSize.small,
                        },
                        enabled: true,
                        defer: false /* Show the dataLabels immediately, ie. do not wait for animation to end. */,
                        formatter() {
                            return this.point.index === 1 ? threshold.id : null;
                        },
                    },
                    yAxis: this.prefixes.yAxis + threshold.unit,
                    color: Highcharts.theme.capasystems.palette.danger.dark,
                    data: threshold.data,
                },
                true
            );
        });
    };

    /* istanbul ignore next */
    prepareStaticThresholds = (staticThresholds, currentThresholdIds) => {
        staticThresholds.forEach((threshold) => {
            currentThresholdIds.push(threshold.id);
            const yAxis = this.chart.get(this.prefixes.yAxis + threshold.unit);
            if (isDefined(yAxis)) {
                const plotLine = {
                    id: threshold.id,
                    value: threshold.value,
                    dashStyle: 'LongDash',
                    color: Highcharts.theme.capasystems.palette.danger.dark,
                    width: 1,
                    zIndex: this.zIndexes.thresholds,
                    isStaticThreshold: true,
                    label: {
                        x: 0,
                        style: {
                            color: Highcharts.theme.capasystems.palette.danger.dark,
                            fontSize: Highcharts.theme.fontSize.small,
                        },
                        text: threshold.label,
                    },
                };
                if (isDefined(threshold.linkedTo)) {
                    plotLine.linkedTo = threshold.linkedTo;
                }
                yAxis.addPlotLine(plotLine, false);
                // Consider adding a property to threshold object whether to calculate minRange.
                let max = 0;
                yAxis.plotLinesAndBands.forEach((plotline) => {
                    max = Math.max(plotline.options.value, max);
                });
                yAxis.update(
                    {
                        minRange: max,
                    },
                    false
                );
            }
        });
    };

    /* istanbul ignore next */
    prepareSeries = (series, currentSeriesIds) => {
        const tempSeries = cloneDeep(series);
        tempSeries.forEach((s) => {
            const hcs = this.chart.get(s.id);
            if (isUndefined(hcs)) {
                if (isUndefined(s.name)) {
                    s.name = s.id;
                }
                if (['areasplinerange', 'arearange'].indexOf(s.type) > -1) {
                    s.isRange = true;
                    s.zIndex = this.zIndexes.rangeSeries;
                } else {
                    s.zIndex = this.zIndexes.series;
                }
                s.removable = true;
                if (!this.hasCategories) {
                    this.addAxis(s.unit);
                    s.yAxis = this.prefixes.yAxis + s.unit;
                }
                this.chart.addSeries(s, false, false);
            } else {
                hcs.setData(s.data, false, false, false);
                // Set data
            }
            currentSeriesIds.push(s.id);
        });
    };

    /* istanbul ignore next */
    handleMenuClick = (e) => {
        this.setState({
            anchorEl: e.currentTarget,
            isOpen: true,
        });
    };

    /* istanbul ignore next */
    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            isOpen: false,
        });
    };

    /* istanbul ignore next */
    removeDynamicThresholds(currentDyncamicThresholdIds) {
        const removedDynamicThresholds = this.addedDynamicThreshodIds.filter((i) => currentDyncamicThresholdIds.indexOf(i) < 0);
        removedDynamicThresholds.forEach((id) => {
            const chart = this.chart.get(id);
            if (chart) {
                chart.remove(false, false);
            }
        });
    }

    /* istanbul ignore next */
    render() {
        const { period, series, actions, loading, hidden, className, children } = this.props;
        const { yAxis, anchorEl, isOpen, pointsConnectDialogIsOpen, connectNulls, connectPoints } = this.state;

        return (
            <LayoutColumn
                fill
                className={classNames(className, 'cs-timeline-chart-wrapper')}
            >
                <Toolbar>
                    {period.start > 0 && period.end > 0 && (
                        <Fragment>
                            <IconButton
                                noMargin
                                onClick={this.changePeriod(period, false)}
                                size={BUTTON.SMALL}
                                color={BUTTON.INHERIT}
                                className="cs-timeline-chart-period-back-button"
                            >
                                <Icon
                                    type="arrowLeft"
                                    size="small"
                                    color="primary"
                                />
                            </IconButton>
                            &nbsp;
                            <span className="text-small">{formatTimestamp(period.start)}</span>
                            &nbsp;-&nbsp;
                            <span className="text-small">{formatTimestamp(period.end)}</span>
                            &nbsp;
                            <IconButton
                                noMargin
                                onClick={this.changePeriod(period, true)}
                                size={BUTTON.SMALL}
                                color={BUTTON.INHERIT}
                                className="cs-timeline-chart-period-forward-button"
                            >
                                <Icon
                                    type="arrowRight"
                                    size="small"
                                    color="primary"
                                />
                            </IconButton>
                            <Button
                                onClick={this.zoomOut}
                                size={BUTTON.SMALL}
                                color={BUTTON.PRIMARY}
                                className="cs-timeline-chart-zoom-out-button"
                            >
                                {coreMessage.zoomOut}
                            </Button>
                        </Fragment>
                    )}
                    <LayoutFill />
                    {!this.hasCategories && (
                        <Fragment>
                            <IconButton
                                onClick={this.openPointsConnectChangeDialog}
                                color={BUTTON.INHERIT}
                                size={BUTTON.SMALL}
                                className="cs-timeline-chart-points-connect-change-button"
                            >
                                {connectNulls === false && connectPoints && (
                                    <Icon
                                        type="connectPointsAutomatically"
                                        color="primary"
                                        size="small"
                                    />
                                )}
                                {connectNulls && connectPoints && (
                                    <Icon
                                        type="connectAllPoints"
                                        color="primary"
                                        size="small"
                                    />
                                )}
                                {connectPoints === false && (
                                    <Icon
                                        type="disconnectAllPoints"
                                        color="primary"
                                        size="small"
                                    />
                                )}
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={pointsConnectDialogIsOpen}
                                onClose={this.closePointsConnectChangeDialog}
                                disableAutoFocusItem
                            >
                                <MenuItem onClick={this.onPointsConnectChange(false, true)}>
                                    <CheckMarkIcon visible={connectNulls === false && connectPoints} />
                                    {coreMessage.automaticallyConnectPoints}
                                </MenuItem>

                                <MenuItem onClick={this.onPointsConnectChange(true, true)}>
                                    <CheckMarkIcon visible={connectNulls && connectPoints} />
                                    {coreMessage.connectAllPoints}
                                </MenuItem>
                                <MenuItem onClick={this.onPointsConnectChange(false, false)}>
                                    <CheckMarkIcon visible={connectPoints === false} />
                                    {coreMessage.disconnectAllPoints}
                                </MenuItem>
                            </Menu>
                            <Tooltip
                                content={coreMessage.adjustYAxis}
                                position="top"
                            >
                                <IconButton
                                    onClick={this.handleMenuClick}
                                    color={BUTTON.INHERIT}
                                    size={BUTTON.SMALL}
                                    className="cs-timeline-chart-adjust-y-axis-button"
                                >
                                    <Icon
                                        type="adjustYAxis"
                                        size="small"
                                        color="primary"
                                    />
                                </IconButton>
                            </Tooltip>
                            <ContextDialog
                                anchorEl={anchorEl}
                                open={isOpen}
                                onClose={this.handleMenuClose}
                            >
                                <Padding
                                    top={8}
                                    bottom={8}
                                >
                                    {this.chart &&
                                        this.getUnique(series, 'unit').map((serie) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                key={serie.id}
                                            >
                                                <Grid item>
                                                    <Input
                                                        label={serie.unit}
                                                        value={yAxis && yAxis[serie.unit] ? yAxis[serie.unit].min : ''}
                                                        type="number"
                                                        placeholder="0"
                                                        onChange={this.validateExtremes('min', serie.unit)}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs
                                                >
                                                    <Input
                                                        label=" "
                                                        value={yAxis && yAxis[serie.unit] ? yAxis[serie.unit].max : ''}
                                                        type="number"
                                                        placeholder="Max"
                                                        onChange={this.validateExtremes('max', serie.unit)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    <Button
                                        fullWidth
                                        onClick={this.handleReset}
                                        style={{ marginTop: 8 }}
                                    >
                                        Reset all
                                    </Button>
                                </Padding>
                            </ContextDialog>
                        </Fragment>
                    )}

                    {actions}
                </Toolbar>
                <div
                    id={this.chartId}
                    className={classNames({
                        'cs-timeline-chart-container': true,
                        'cs-timeline-chart-container-has-categories': this.hasCategories,
                        'cs-timeline-chart-container-hidden': hidden || loading,
                    })}
                />
                {loading && (
                    <LayoutCenter>
                        <Padding>
                            <Loading />
                        </Padding>
                    </LayoutCenter>
                )}
                {hidden && !loading && children}
            </LayoutColumn>
        );
    }
}

/* istanbul ignore next */
// eslint-disable-next-line react/prop-types
const CheckMarkIcon = ({ visible }) => (
    <Icon
        type="checkmark"
        size="small"
        style={{
            visibility: visible ? 'visible' : 'hidden',
            marginRight: spacing,
        }}
    />
);

TimelineChart.propTypes = {
    htmlId: PropTypes.string,
    actions: PropTypes.node,
    series: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
            type: PropTypes.oneOf(['spline', 'line', 'column', 'bubble', 'areasplinerange', 'arearange']),
            showInLegend: PropTypes.bool,
            minSize: PropTypes.number, // for bubble series only
            maxSize: PropTypes.number, // for bubble series only
            unit: PropTypes.string.isRequired,
            data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.shape()])).isRequired,
        })
    ),
    /** Must have 'start' and 'end' timestamps. Example: {start: 1541113200000, end: 1541144349603} */
    period: PropTypes.shape({
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
    }).isRequired,
    connectNulls: PropTypes.bool,
    connectPoints: PropTypes.bool,
    onPointsConnectChange: PropTypes.func,
    /** Represented by a dashed line across the chart. */
    staticThresholds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
            unit: PropTypes.string,
            value: PropTypes.number,
        })
    ),
    /** Represented by a dashed step line series across the chart. */
    dynamicThresholds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
            unit: PropTypes.string.isRequired,
            data: PropTypes.arrayOf(PropTypes.array),
        })
    ),
    categories: PropTypes.arrayOf(PropTypes.string),
    onPeriodChange: PropTypes.func,
    configure: PropTypes.func,
    hidden: PropTypes.bool,
    loading: PropTypes.bool,
    onCategoryClick: PropTypes.func,
    onAxisChange: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    extremes: PropTypes.objectOf(
        PropTypes.shape({
            min: PropTypes.number.isRequired,
            max: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string, // Null only
            ]).isRequired,
        })
    ),
};

export default TimelineChart;
export { TimelineChart };
