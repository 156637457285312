import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Badge,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Grid,
    Icon,
    IconButton,
    Input,
    LayoutFill,
    LayoutRow,
    RichTooltip,
    Select,
    Switch,
    Tooltip,
} from '@capasystems/ui';
import { Url, cloneDeep, isBoolean, isDefined, isEqual, isUndefined, noop, now, useKeyPress } from '@capasystems/utils';
import { LEAF_OPERATOR, LEAF_TYPE, NOT_AVAILABLE } from '@thirdparty/constants';
import classNames from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Fragment, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PageTitle } from '../page/page-title';
import { TailwindBadge } from '../thirdparty-components/thirdparty-components';
import { QueryBuilderLeafComponent } from './query-builder-leaf-component';
import { QueryBuilderPrimaryLeafComponent } from './query-builder-primary-leaf-component';

const paperProps = {
    style: {
        minWidth: '25vw',
        maxWidth: '100vw',
        width: 960,
    },
};

const populatePrivateState = (leaf, branchDependencies, currentRoot) => {
    const branchDependency = branchDependencies.shift();
    // add path device.name fx to each option.
    leaf.privateState.branchDependenciesNiceNames.push(currentRoot.options.find((o) => o.id === branchDependency).name);
    leaf.privateState.gridItemColumns -= currentRoot.gridItemColumns;
    if (branchDependencies.length > 0) {
        populatePrivateState(leaf, branchDependencies, currentRoot.children[branchDependency]);
    }
};

const readMinMax = (leaf) => {
    leaf.value = {
        min: Url.getString(leaf.id + '.min', leaf.locked ? cloneDeep(leaf.defaultValue.min) : null),
        max: Url.getString(leaf.id + '.max', leaf.locked ? cloneDeep(leaf.defaultValue.max) : null),
    };
    if (leaf.value.min || leaf.value.max) {
        leaf.selected = true;
        leaf.active = true;
    }
};

const initializeLeafs = (ref, notifyAboutMissingLeafData, isInitialScan) => {
    ref.current.leafs.forEach((leaf) => {
        if (isInitialScan) {
            leaf.privateState = {
                leafHasMinMaxValue: leaf.type === LEAF_TYPE.DATE || leaf.type === LEAF_TYPE.NUMBER,
            };
        }
        leaf.privateState.branchDependenciesNiceNames = [];
        leaf.privateState.gridItemColumns = 12;
        leaf.selected = leaf.locked || false;
        leaf.active = false;

        populatePrivateState(leaf, [...leaf.branchDependencies], ref.current.root);

        const selectedOperatorID = Url.getString(leaf.id + '.operator');
        if (selectedOperatorID) {
            const selectedOperator = Object.values(LEAF_OPERATOR).find((operator) => operator.id === selectedOperatorID);
            if (selectedOperator) {
                leaf.operator = selectedOperator;
            }
        }

        if (leaf.type === LEAF_TYPE.STRING) {
            leaf.value = Url.getString(leaf.id, leaf.locked || leaf.primary ? cloneDeep(leaf.defaultValue) : null);
            if (leaf.value !== null) {
                leaf.selected = true;
                leaf.active = leaf.value !== '';
            }
        } else if (leaf.type === LEAF_TYPE.DATE) {
            if (leaf.operator === LEAF_OPERATOR.DATE_SUBTRACT) {
                leaf.unit = Url.getString(`${leaf.id}.unit`);
                leaf.value = Url.getNumber(`${leaf.id}`);
                if (leaf.unit && leaf.value) {
                    leaf.selected = true;
                    leaf.active = true;
                }
            } else {
                readMinMax(leaf);
            }
        } else if (leaf.type === LEAF_TYPE.NUMBER) {
            readMinMax(leaf);
        } else if (leaf.type === LEAF_TYPE.BOOLEAN) {
            leaf.value = Url.getBoolean(leaf.id, leaf.locked ? cloneDeep(leaf.defaultValue) : null);
            if (leaf.value !== null) {
                leaf.selected = true;
                leaf.active = true;
            }
        } else if (leaf.type === LEAF_TYPE.SELECT) {
            if (isUndefined(leaf.required)) {
                leaf.required = true;
            }
            if (leaf.numeric) {
                leaf.value = Url.getNumberArray(leaf.id, leaf.locked ? cloneDeep(leaf.defaultValue) : null);
            } else {
                leaf.value = Url.getArray(leaf.id, leaf.locked ? cloneDeep(leaf.defaultValue) : null);
            }
            if (leaf.value !== null) {
                leaf.selected = true;
                leaf.active = leaf.value.length > 0;
                if (leaf.active && leaf.options.length === 0) {
                    notifyAboutMissingLeafData(leaf);
                }
            }
        } else {
            console.error('Unhandled leaf type', leaf.type);
        }

        leaf.privateState.initial = {
            value: cloneDeep(leaf.value),
            operator: cloneDeep(leaf.operator),
            unit: cloneDeep(leaf.unit),
        };
    });
    ref.current.root.options.forEach((rootItem) => {
        if (rootItem.negateAble) {
            const value = Url.getBoolean(`${rootItem.id}.negate`, false);
            rootItem.value = value;
            rootItem.initial = value;
        }
    });
};

const getActiveLeafs = (ref) => {
    return ref.current.leafs.filter((leaf) => leaf.active);
};

const getSelectedLeafs = (ref) => {
    return ref.current.leafs.filter((leaf) => leaf.selected);
};

const QueryBuilder = forwardRef(
    (
        {
            onMissingLeafData = noop,
            onLeafTypeStringChange = noop,
            onLeafTypeStringFocus = noop,
            defaultConfiguration,
            onInit,
            onSubmit,
            onUnmount = noop,
            onClose = noop,
            onCancel = noop,
            urlIsDirty = false,
            initiallyOpen = false,
            onSaveFilter,
            onSaveFilterAs,
            onChooseFilter,
            applyChangesText = null,
            showMongoHints = false,
            children,
            ...rest
        },
        queryBuilderRef
    ) => {
        const [, setLastRefresh] = useState(0);
        const [filtersAreDirty, setFiltersAreDirty] = useState(false);
        const ref = useRef(cloneDeep(defaultConfiguration));
        const [drawerIsOpen, setDrawerIsOpen] = useState(initiallyOpen);
        const [inClearFiltersMode, setInClearFiltersMode] = useState(false);

        const [primaryLeaf, secondaryLeafCount] = useMemo(() => {
            return [ref.current.leafs.find((leaf) => leaf.primary), ref.current.leafs.filter((leaf) => !leaf.primary).length];
        }, [ref]);

        const clearFiltersKeyRef = useRef(now());

        const forceUpdate = () => {
            setLastRefresh(now());
        };

        const notifyAboutMissingLeafData = (leafRef) => {
            onMissingLeafData(leafRef, forceUpdate);
        };

        const openDrawer = () => {
            initializeLeafs(ref, notifyAboutMissingLeafData, false);
            setDrawerIsOpen(true);
        };

        useKeyPress(
            useCallback(() => {
                if (secondaryLeafCount > 0 && !drawerIsOpen) {
                    openDrawer();
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [drawerIsOpen, secondaryLeafCount]),
            ['Control', 's']
        );

        const performDirtyLeafsCheck = () => {
            let isDirty =
                ref.current.leafs.some((leaf) => {
                    return !isEqual(leaf.privateState.initial, {
                        value: leaf.value,
                        operator: leaf.operator,
                        unit: leaf.unit,
                    });
                }) ||
                ref.current.root.options.some((rootItem) => {
                    return !isEqual(rootItem.value, rootItem.initial);
                });
            return isDirty;
        };

        useEffect(() => {
            ref.current.searchableLeafs = ref.current.leafs.some((leaf) => leaf.locked !== true);
            initializeLeafs(ref, notifyAboutMissingLeafData, true);
            onInit(getActiveLeafs(ref), getSelectedLeafs(ref), prepareNegations(ref));
            return onUnmount;
        }, []);

        const applyChanges = (event) => {
            const isDirty = performDirtyLeafsCheck();
            setDrawerIsOpen(false);
            if (isDirty) {
                collectLeafsAndUpdateUrl();
            }
            onClose(isDirty);
        };

        const handleCustomAction = (requestedAction) => () => {
            applyChanges();
            requestedAction();
        };

        const discardChanges = () => {
            const primaryLeaf = ref.current.leafs.find((leaf) => leaf.primary);
            if (primaryLeaf) {
                primaryLeaf.value = cloneDeep(primaryLeaf.privateState.initial.value);
                primaryLeaf.operator = cloneDeep(primaryLeaf.privateState.initial.operator);
            }
            setDrawerIsOpen(false);
            onCancel();
        };

        const deselectLeaf = (leaf) => {
            if (leaf.locked) {
                leaf.value = cloneDeep(leaf.defaultValue);
            } else {
                leaf.selected = false;
                if (leaf.operator === LEAF_OPERATOR.RANGE) {
                    leaf.value = {
                        min: null,
                        max: null,
                    };
                } else {
                    leaf.value = null;
                }
            }
            if (leaf.suggestions) {
                leaf.suggestions = [];
            }
        };

        const deselectRootItem = (rootItem) => {
            if (rootItem.negateAble) {
                rootItem.value = false;
            }
        };

        const clearFilters = () => {
            clearFiltersKeyRef.current = now();
            ref.current.leafs.forEach(deselectLeaf);
            ref.current.root.options.forEach(deselectRootItem);
            forceUpdate();
        };

        const clearFiltersAndApplyChanges = () => {
            setInClearFiltersMode(false);
            clearFilters();
            collectLeafsAndUpdateUrl();
        };

        const prepareNegations = (ref) => {
            const negations = {};
            ref.current.root.options.forEach(({ id, value, negateAble }) => {
                if (negateAble) {
                    negations[id] = value;
                }
            });

            return negations;
        };

        const collectLeafsAndUpdateUrl = () => {
            const urlSetters = [];
            ref.current.leafs.forEach((leaf) => {
                if (leaf.type === LEAF_TYPE.DATE) {
                    urlSetters.push({
                        name: leaf.id,
                        value: null,
                    });
                    urlSetters.push({
                        name: leaf.id + '.unit',
                        value: null,
                    });
                    urlSetters.push({
                        name: leaf.id + '.min',
                        value: null,
                    });
                    urlSetters.push({
                        name: leaf.id + '.max',
                        value: null,
                    });
                } else if (leaf.operator?.id === LEAF_OPERATOR.RANGE.id) {
                    urlSetters.push({
                        name: leaf.id + '.min',
                        value: null,
                    });
                    urlSetters.push({
                        name: leaf.id + '.max',
                        value: null,
                    });
                } else {
                    urlSetters.push({
                        name: leaf.id,
                        value: null,
                    });
                }
                urlSetters.push({
                    name: leaf.id + '.operator',
                    value: null,
                });
                leaf.active = false;
                if (!leaf.selected) {
                    return;
                }

                if (leaf.type === LEAF_TYPE.STRING) {
                    urlSetters.push({
                        name: leaf.id,
                        value: leaf.value,
                    });
                    leaf.active = leaf.value && leaf.value !== '';
                } else if (leaf.operator?.id === LEAF_OPERATOR.RANGE.id) {
                    urlSetters.push({
                        name: leaf.id + '.min',
                        value: leaf.value.min,
                    });
                    urlSetters.push({
                        name: leaf.id + '.max',
                        value: leaf.value.max,
                    });
                    leaf.active = leaf.value.min || leaf.value.max;
                } else if (leaf.operator?.id === LEAF_OPERATOR.DATE_SUBTRACT.id) {
                    if (leaf.value !== null && leaf.value !== undefined) {
                        urlSetters.push({
                            name: leaf.id,
                            value: leaf.value,
                        });
                        urlSetters.push({
                            name: leaf.id + '.unit',
                            value: leaf.unit,
                        });
                        leaf.active = true;
                    } else {
                        leaf.active = false;
                    }
                } else if (leaf.type === LEAF_TYPE.BOOLEAN) {
                    urlSetters.push({
                        name: leaf.id,
                        value: leaf.value,
                    });
                    leaf.active = isBoolean(leaf.value);
                } else if (leaf.type === LEAF_TYPE.SELECT) {
                    urlSetters.push({
                        name: leaf.id,
                        value: leaf.value,
                    });
                    leaf.active = leaf.value.length > 0;
                } else {
                    console.error('Unhandled leaf type', leaf.type);
                }
                if (leaf.operator && leaf.active) {
                    urlSetters.push({
                        name: leaf.id + '.operator',
                        value: leaf.operator.id,
                    });
                }
            });
            ref.current.root.options.forEach((rootItem) => {
                if (rootItem.negateAble) {
                    urlSetters.push({
                        name: `${rootItem.id}.negate`,
                        value: rootItem.value || null,
                    });
                }
            });
            Url.setMany(urlSetters);
            onSubmit(getActiveLeafs(ref), getSelectedLeafs(ref), prepareNegations(ref));
        };

        useEffect(() => {
            setFiltersAreDirty(false);
        }, [drawerIsOpen]);

        useEffect(() => {
            if (urlIsDirty) {
                initializeLeafs(ref, notifyAboutMissingLeafData, false);
                collectLeafsAndUpdateUrl();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [urlIsDirty]);

        const performFiltersAreDirtyCheck = () => {
            const isDirty = performDirtyLeafsCheck();
            if (filtersAreDirty !== isDirty) {
                setFiltersAreDirty(isDirty);
            }
        };

        const onSelectedLeafBranchDependenciesChange = (leaf) => (branchDependencyLevel, newID) => {
            const PIPE = '|';
            deselectLeaf(leaf);
            const tempBranchDependencies = [...leaf.branchDependencies];
            tempBranchDependencies[branchDependencyLevel] = newID;
            tempBranchDependencies.length = branchDependencyLevel + 1;
            const availableLeaf = ref.current.leafs.find(
                (leaf) => leaf.branchDependencies.join(PIPE).startsWith(tempBranchDependencies.join(PIPE)) && leaf.selected === false
            );
            if (availableLeaf) {
                availableLeaf.selected = true;
                availableLeaf.value = cloneDeep(availableLeaf.defaultValue);
                forceUpdate();
            }
        };

        const onClearFilters = () => {
            clearFilters();
            performFiltersAreDirtyCheck();
        };

        const removeSelectedFilter = (leaf) => () => {
            deselectLeaf(leaf);
            performFiltersAreDirtyCheck();
            forceUpdate();
        };

        const addNewFilterRow = (rootDependency) => () => {
            const availableLeaf = ref.current.leafs.find((leaf) => leaf.branchDependencies.indexOf(rootDependency) === 0 && leaf.selected === false);
            if (availableLeaf) {
                availableLeaf.selected = true;
                availableLeaf.value = cloneDeep(availableLeaf.defaultValue);
                performFiltersAreDirtyCheck();
                forceUpdate();
            }
        };

        const [filterSuggestionState, setFilterSuggestionState] = useState({
            value: '',
            matches: [],
            anchorEl: null,
            open: false,
        });

        const selectLeaf = (leaf) => () => {
            leaf.selected = true;
            leaf.value = cloneDeep(leaf.defaultValue);
            performFiltersAreDirtyCheck();
            forceUpdate();
        };

        const onFilterSuggestionSearch = ({ target }) => {
            const value = target.value;
            setFilterSuggestionState({
                ...filterSuggestionState,
                value,
                matches: ref.current.leafs.filter((leaf) =>
                    leaf.privateState.branchDependenciesNiceNames.join(' ').toLocaleLowerCase().includes(value.toLocaleLowerCase())
                ),
            });
        };

        const onFilterSuggestionFocus = ({ target }) => {
            setFilterSuggestionState({
                ...filterSuggestionState,
                anchorEl: target,
                open: true,
                matches: ref.current.leafs,
                paperProps: {
                    dark: true,
                    className: 'tw-p-4',
                    style: { maxHeight: '50vh', width: target.clientWidth },
                },
            });
        };

        const onFilterSuggestionClose = () => {
            setFilterSuggestionState({
                ...filterSuggestionState,
                value: '',
                open: false,
            });
        };

        const onContextMenu = (event) => {
            event.preventDefault();
            setInClearFiltersMode((c) => !c);
        };

        const onTooltipClose = () => {
            setInClearFiltersMode(false);
        };

        const activeLeafsCount = getActiveLeafs(ref).length;

        const enableSave = isDefined(onSaveFilter);
        const enableSaveAs = isDefined(onSaveFilterAs);
        const enableFilterPicker = isDefined(onChooseFilter);

        const onChangeRootItemNegate = (rootItem) => (event) => {
            const { checked } = event.currentTarget;
            rootItem.value = checked;
            forceUpdate();
            performFiltersAreDirtyCheck();
        };

        if (queryBuilderRef) {
            queryBuilderRef.current.openDrawer = openDrawer;
            queryBuilderRef.current.clearFiltersAndApplyChanges = clearFiltersAndApplyChanges;
            queryBuilderRef.current.activeLeafsCount = activeLeafsCount;
        }

        return (
            <>
                <LayoutRow
                    {...rest}
                    verticalAlign="center"
                >
                    <QueryBuilderPrimaryLeafComponent
                        ref={primaryLeaf}
                        onLeafTypeStringChange={onLeafTypeStringChange}
                        onLeafTypeStringFocus={onLeafTypeStringFocus}
                        applyChanges={applyChanges}
                        secondaryLeafCount={secondaryLeafCount}
                        showMongoHints={showMongoHints}
                    >
                        {secondaryLeafCount > 0 && (
                            <Tooltip
                                position={TOOLTIP.POSITION.TOP}
                                onClose={onTooltipClose}
                                content={
                                    <>
                                        {inClearFiltersMode && (
                                            <div className="tw-font-semibold">
                                                <div className="tw-text-yellow-400">Clear filters</div>
                                                {activeLeafsCount === 0 ? (
                                                    <>
                                                        <div className="tw-mt-2">
                                                            <span>&middot; No filters applied yet</span>
                                                        </div>
                                                        <div className="tw-mt-2">&middot; Left-click to activate filters</div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="tw-mt-2">
                                                            <span>&middot; Left-click to confirm</span>
                                                        </div>
                                                        <div className="tw-mt-2">
                                                            <span>&middot; Right-click or move mouse to cancel</span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {!inClearFiltersMode && (
                                            <div className="tw-font-semibold">
                                                <div className="tw-text-yellow-400">Shortcuts</div>
                                                <div className="tw-mt-2">&middot; Press "ctrl + s" to activate filters</div>
                                                <div className="tw-mt-2">
                                                    <span>&middot; Right-click to clear filters</span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                }
                            >
                                <div>
                                    {isDefined(primaryLeaf) ? (
                                        <Badge
                                            badgeContent={activeLeafsCount}
                                            color="error"
                                            overlap="circular"
                                        >
                                            <Button
                                                noMargin
                                                color={inClearFiltersMode && activeLeafsCount > 0 ? BUTTON.DANGER : BUTTON.PRIMARY}
                                                onClick={inClearFiltersMode && activeLeafsCount > 0 ? clearFiltersAndApplyChanges : openDrawer}
                                                onContextMenu={onContextMenu}
                                                tabIndex={-1}
                                                size="large"
                                                className="tw-rounded-l-none"
                                            >
                                                <Icon type={inClearFiltersMode && activeLeafsCount > 0 ? 'clear' : 'filter'} />
                                            </Button>
                                        </Badge>
                                    ) : (
                                        <Badge
                                            badgeContent={activeLeafsCount}
                                            color="error"
                                            overlap="circular"
                                        >
                                            <IconButton
                                                noMargin
                                                color={inClearFiltersMode && activeLeafsCount > 0 ? BUTTON.DANGER : BUTTON.PRIMARY}
                                                variant={BUTTON.TEXT}
                                                onClick={inClearFiltersMode && activeLeafsCount > 0 ? clearFiltersAndApplyChanges : openDrawer}
                                                onContextMenu={onContextMenu}
                                                tabIndex={-1}
                                            >
                                                <Icon type={inClearFiltersMode && activeLeafsCount > 0 ? 'clear' : 'filter'} />
                                            </IconButton>
                                        </Badge>
                                    )}
                                </div>
                            </Tooltip>
                        )}
                    </QueryBuilderPrimaryLeafComponent>
                    {children}
                </LayoutRow>
                <Drawer
                    anchor="right"
                    PaperProps={paperProps}
                    open={drawerIsOpen}
                    onClose={applyChanges}
                    disableEscapeKeyDown
                >
                    {(enableSaveAs || enableSave || enableFilterPicker) && (
                        <div className="tw-pl-6 tw-pr-4 tw-pt-3">
                            <LayoutRow align="space-between">
                                <div>
                                    {enableSave && (
                                        <Button
                                            color={BUTTON.PRIMARY}
                                            variant={BUTTON.RAISED}
                                            // color={BUTTON.INHERIT}
                                            noMargin
                                            // className="tw-rounded-fusll tw-bg-emerald-500 tw-text-white tw-px-4 tw-text-xs"
                                            onClick={handleCustomAction(onSaveFilter)}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    {enableFilterPicker && (
                                        <Button
                                            color={BUTTON.INHERIT}
                                            variant={BUTTON.RAISED}
                                            className="tw-bg-blue-100 tw-px-4 tw-py-2 tw-text-xs"
                                            onClick={handleCustomAction(onChooseFilter)}
                                        >
                                            Open filter...
                                        </Button>
                                    )}
                                    {enableSaveAs && (
                                        <Button
                                            color={BUTTON.INHERIT}
                                            className="tw-bg-blue-100 tw-px-4 tw-py-2 tw-text-xs"
                                            onClick={handleCustomAction(onSaveFilterAs)}
                                        >
                                            Save filter as...
                                        </Button>
                                    )}
                                </div>
                            </LayoutRow>
                        </div>
                    )}

                    {ref.current.searchableLeafs && (
                        <DialogTitle>
                            <LayoutRow align="space-between">
                                <Input
                                    callToAction
                                    placeholder="Search filter collection"
                                    light
                                    value={filterSuggestionState.value}
                                    onChange={onFilterSuggestionSearch}
                                    onFocus={onFilterSuggestionFocus}
                                />
                                <RichTooltip
                                    anchorEl={filterSuggestionState.anchorEl}
                                    open={filterSuggestionState.open}
                                    position="bottom-start"
                                    paperProps={filterSuggestionState.paperProps}
                                    onClose={onFilterSuggestionClose}
                                >
                                    {filterSuggestionState.matches.filter((leaf) => !leaf.selected).length === 0 && <b>No search results...</b>}
                                    {filterSuggestionState.matches
                                        .filter((leaf) => !leaf.selected)
                                        .map((leaf, index) => {
                                            const popArray = [...leaf.privateState.branchDependenciesNiceNames];
                                            const popped = popArray.pop();
                                            return (
                                                <LayoutRow
                                                    key={leaf.id}
                                                    className={classNames({
                                                        'tw-mt-4': index > 0,
                                                    })}
                                                    verticalAlign="center"
                                                >
                                                    <IconButton
                                                        onClick={selectLeaf(leaf)}
                                                        color={BUTTON.INHERIT}
                                                        variant={BUTTON.RAISED}
                                                        className="tw-mr-4"
                                                        size="small"
                                                    >
                                                        <Icon
                                                            type="add"
                                                            color="primary"
                                                        ></Icon>
                                                    </IconButton>
                                                    <div>
                                                        <LayoutRow
                                                            verticalAlign="center"
                                                            className="tw-text-gray-400"
                                                        >
                                                            {popArray.map((name, index) => {
                                                                return (
                                                                    <Fragment key={name}>
                                                                        {index > 0 && (
                                                                            <Icon
                                                                                size="small"
                                                                                type="arrowRight"
                                                                                className="tw-mx-1 tw-text-xs"
                                                                            />
                                                                        )}
                                                                        <span className="tw-text-xs">{name}</span>
                                                                    </Fragment>
                                                                );
                                                            })}
                                                        </LayoutRow>
                                                        <b className="tw-text-sm">{popped}</b>
                                                    </div>
                                                </LayoutRow>
                                            );
                                        })}
                                </RichTooltip>
                            </LayoutRow>
                        </DialogTitle>
                    )}

                    <DialogContent key={clearFiltersKeyRef.current}>
                        {ref.current.root.options.map((rootItem, rootItemIndex) => {
                            return (
                                <div
                                    key={rootItem.id}
                                    className={classNames({
                                        'tw-mt-4': rootItemIndex > 0,
                                    })}
                                >
                                    {!rootItem.hidden && (
                                        <LayoutRow
                                            verticalAlign="center"
                                            className="tw-mb-4"
                                        >
                                            {ref.current.searchableLeafs && (
                                                <Tooltip
                                                    content="Add a new filter"
                                                    position={TOOLTIP.POSITION.LEFT}
                                                >
                                                    <div>
                                                        <IconButton
                                                            onClick={addNewFilterRow(rootItem.id)}
                                                            color={BUTTON.SUCCESS}
                                                            variant={BUTTON.RAISED}
                                                            noMargin
                                                            className="tw-mr-4"
                                                            size="small"
                                                        >
                                                            <Icon type="add"></Icon>
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}

                                            <LayoutRow
                                                align="space-between"
                                                fill
                                                verticalAlign="center"
                                            >
                                                <div>
                                                    <PageTitle
                                                        description={`${pluralize(
                                                            'filter',
                                                            ref.current.leafs.filter((l) => l.branchDependencies.indexOf(rootItem.id) === 0).length,
                                                            true
                                                        )}`}
                                                    >
                                                        {rootItem.name}
                                                    </PageTitle>
                                                </div>
                                                {rootItem.negateAble && (
                                                    <Switch
                                                        label="Not including"
                                                        labelClasses={{ label: 'tw-text-xs tw-font-medium' }}
                                                        checked={rootItem.value}
                                                        onChange={onChangeRootItemNegate(rootItem)}
                                                    />
                                                )}
                                            </LayoutRow>
                                        </LayoutRow>
                                    )}

                                    {ref.current.leafs
                                        .filter((leaf) => leaf.selected && leaf.branchDependencies.indexOf(rootItem.id) === 0)
                                        .map((leaf) => {
                                            const showOperator = leaf.operator !== LEAF_OPERATOR.BOOLEAN;

                                            return (
                                                <Grid
                                                    container
                                                    key={leaf.id}
                                                    spacing={2}
                                                    className="tw-mb-4 tw-items-center"
                                                >
                                                    <BranchDependencies
                                                        root={ref.current.root.children[rootItem.id]}
                                                        branchDependencies={leaf.branchDependencies.filter((d) => d !== rootItem.id)}
                                                        onChange={onSelectedLeafBranchDependenciesChange(leaf)}
                                                        level={1}
                                                        locked={leaf.locked}
                                                    />
                                                    {showOperator && (
                                                        <Grid
                                                            item
                                                            xs={2}
                                                        >
                                                            <OperatorComponent
                                                                ref={leaf}
                                                                forceUpdate={forceUpdate}
                                                                performFiltersAreDirtyCheck={performFiltersAreDirtyCheck}
                                                            />
                                                        </Grid>
                                                    )}

                                                    <Grid
                                                        item
                                                        xs={leaf.privateState.gridItemColumns - (showOperator ? 2 : 0)}
                                                    >
                                                        <LayoutRow verticalAlign="center">
                                                            <QueryBuilderLeafComponent
                                                                ref={leaf}
                                                                notifyAboutMissingLeafData={notifyAboutMissingLeafData}
                                                                onLeafTypeStringChange={onLeafTypeStringChange}
                                                                onLeafTypeStringFocus={onLeafTypeStringFocus}
                                                                performFiltersAreDirtyCheck={performFiltersAreDirtyCheck}
                                                                showMongoHints={showMongoHints}
                                                            />
                                                            {!leaf.locked && (
                                                                <Tooltip
                                                                    content="Remove filter"
                                                                    position={TOOLTIP.POSITION.LEFT}
                                                                    disableHoverListener={leaf.locked}
                                                                >
                                                                    <div>
                                                                        <IconButton
                                                                            noMargin
                                                                            className="tw-ml-4"
                                                                            onClick={removeSelectedFilter(leaf)}
                                                                            disabled={leaf.locked}
                                                                        >
                                                                            <Icon
                                                                                type={leaf.locked ? 'lock' : 'deleteOutlined'}
                                                                                size="small"
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                </Tooltip>
                                                            )}
                                                        </LayoutRow>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                </div>
                            );
                        })}
                    </DialogContent>
                    <DialogActions className="tw-bg-gray-50 tw-px-4 tw-py-2">
                        <Button
                            onClick={applyChanges}
                            color={filtersAreDirty || applyChangesText ? BUTTON.PRIMARY : BUTTON.DEFAULT}
                            variant={BUTTON.RAISED}
                            noMargin
                        >
                            {applyChangesText ? applyChangesText : filtersAreDirty ? 'Apply changes' : 'Close'}
                        </Button>
                        <LayoutFill />
                        <Button onClick={onClearFilters}>Clear filters</Button>
                        <Button
                            noMargin
                            onClick={discardChanges}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Drawer>
            </>
        );
    }
);

const BranchDependencies = ({ root, branchDependencies, onChange, level, locked }) => {
    const { children, options } = root;
    const [selectedId, ...remainingBranchDependencies] = branchDependencies;
    const [searchTerm, setSearchTerm] = useState(options.length > 5 ? '' : null);
    const [selectedOptions] = useState(options.filter((option) => option.id === selectedId));

    const onChangeHandler = ([{ id }]) => {
        onChange(level, id);
    };

    return (
        <>
            <Grid
                item
                xs={root.gridItemColumns}
            >
                {locked ? (
                    <TailwindBadge
                        noShadow
                        className="tw-w-full"
                        color="neutral"
                    >
                        {selectedOptions[0]?.name || NOT_AVAILABLE}
                    </TailwindBadge>
                ) : (
                    <Select
                        options={
                            searchTerm
                                ? options.filter((option) => option.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                                : locked
                                ? selectedOptions
                                : options
                        }
                        selectedOptions={selectedOptions}
                        required
                        light
                        onChange={onChangeHandler}
                        searchTerm={searchTerm}
                        onSearch={setSearchTerm}
                        callToAction
                        endAdornment={undefined}
                    />
                )}
            </Grid>
            {children && selectedOptions.length > 0 && (
                <BranchDependencies
                    root={children[selectedId]}
                    branchDependencies={remainingBranchDependencies}
                    onChange={onChange}
                    level={level + 1}
                    locked={locked}
                />
            )}
        </>
    );
};

const OperatorComponent = forwardRef(({ performFiltersAreDirtyCheck, forceUpdate }, leafRef) => {
    const [selectedOptions, setSelectedOptions] = useState([leafRef.operator]);
    const [disabled] = useState(isUndefined(leafRef.operators));

    const onSelectChange = (selectedOperator) => {
        setSelectedOptions(selectedOperator);
        leafRef.operator = selectedOperator[0];
        performFiltersAreDirtyCheck();
        forceUpdate();
    };
    if (disabled) {
        return (
            <TailwindBadge
                noShadow
                className="tw-w-full"
                color="neutral"
            >
                {selectedOptions[0]?.name || NOT_AVAILABLE}
            </TailwindBadge>
        );
    }

    return (
        <Select
            light
            required
            options={leafRef.operators || selectedOptions}
            selectedOptions={selectedOptions}
            onChange={onSelectChange}
            className="tw-mr-4"
            callToAction
            endAdornment={undefined}
        />
    );
});

QueryBuilder.propTypes = {
    defaultConfiguration: PropTypes.shape({}).isRequired,
    onInit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUnmount: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onMissingLeafData: PropTypes.func,
    onLeafTypeStringChange: PropTypes.func,
    onLeafTypeStringFocus: PropTypes.func,
    urlIsDirty: PropTypes.bool, // Only set to true when updating filters via the URL from the outside and reset on next onSubmit. QueryBuilder will pick up the change immediately and notify.
    initiallyOpen: PropTypes.bool, // Open the drawer on mount. Useful for edit mode.
    showMongoHints: PropTypes.bool, // If onInit and onSubmit is using leafsToMongo we show hints like: Use a comma-separated list to search for multiple items etc.
    onSaveFilter: PropTypes.func,
    onSaveFilterAs: PropTypes.func,
    onChooseFilter: PropTypes.func,
    applyChangesText: PropTypes.string,
};

export { QueryBuilder };
