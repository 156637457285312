import { isArray, noop } from '@capasystems/utils';
import { useEffect } from 'react';

const SEPERATOR = ' · ';
const DEFAULT_DOCUMENT_TITLE = document.title;

export type PageProps = {
    /** The title of the page. */
    title?: string | string[];
    /** Called when the page is initialized. */
    onInit?: () => void;
    /** The children to render. */
    children?: React.ReactNode;
};

/** Simply controls the document title. Defaults to the apps index.html title. */
const Page: React.FC<PageProps> = ({ title = null, onInit = noop, children = null }) => {
    useEffect(() => {
        if (title) {
            document.title = `${isArray(title) ? title.join(SEPERATOR) : title}${SEPERATOR}${DEFAULT_DOCUMENT_TITLE}`;
        }
    }, [title]);

    useEffect(() => {
        onInit();
        return () => {
            // Only reset if a title has been passed. Useful for conditional document titles.
            if (title) {
                document.title = DEFAULT_DOCUMENT_TITLE;
            }
        };
    }, []);

    return children;
};

export { Page };
export default Page;
