export const AppleNetworkUsageRulesConfiguration = {
    schema: {
        schemaId: 'appleNetworkUsageRulesConfiguration',
        category: 'Networking',
        title: 'Network Usage Rules',
        type: 'object',
        properties: {
            'com.apple.networkusagerules': {
                title: '',
                skipIndent: true,
                type: 'object',
                properties: {
                    ApplicationRules: {
                        title: 'Application Rules',
                        description: 'An array of application rules, that apply to only managed apps.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                AllowCellularData: {
                                    title: 'Allow Cellular Data',
                                    description: 'If false, disables cellular data for all matching managed apps.',
                                    type: 'boolean',
                                },
                                AllowRoamingCellularData: {
                                    title: 'Allow Roaming Cellular Data',
                                    description: 'If false, disables cellular data while roaming for all matching managed apps.',
                                    type: 'boolean',
                                },
                                AppIdentifierMatches: {
                                    title: 'App Identifier Matches',
                                    description:
                                        'A list of managed app identifiers, as strings, that must follow the associated rules. If this key is missing, the rules apply to all managed apps on the device.',
                                    help: 'Each string in the AppIdentifierMatches array may either be an exact app identifier match (for example, com.mycompany.myapp) or it may specify a prefix match for the bundle ID by using the * wildcard character. If used, this character must appear after a period (.) and may only appear once, at the end of the string; for example, com.mycompany.*.',
                                    type: 'array',
                                    items: {
                                        type: 'appleapplicationsearchdialog',
                                    },
                                },
                            },
                        },
                    },
                    SIMRules: {
                        title: 'SIM Rules',
                        description: 'An array of SIM rules, that apply to all apps.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                ICCIDs: {
                                    title: 'ICCID',
                                    description: 'One or more ICCIDs of SIM cards for which the WiFiAssistPolicy applies',
                                    help: 'All ICCIDs in all installed Network Usage Rules payloads must be unique. An example ICCID is 89310410106543789301.',
                                    type: 'array',
                                    required: true,
                                    items: {
                                        type: 'string',
                                    },
                                },
                                WiFiAssistPolicy: {
                                    title: 'WiFi Assist Policy',
                                    description: 'The Wi-Fi Assist policy to apply to the SIM cards specified in the ICCIDs.',
                                    type: 'string',
                                    required: true,
                                    options: [
                                        {
                                            id: 2,
                                            name: 'Use the default system policy for the specified SIM card(s).',
                                        },
                                        {
                                            id: 3,
                                            name: 'Make Wi-Fi Assist switch more aggressively from a poor Wi-Fi connection to cellular data for the specified SIM card(s). This setting may increase cellular data use and may impact battery life.',
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
