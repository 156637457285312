import { AutoComplete, Ellipsis, Icon, Input, LayoutRow, Select, Tab, Tabs } from '@capasystems/ui';
import { isBoolean, now } from '@capasystems/utils';
import { LEAF_OPERATOR, LEAF_TYPE, LEAF_UNIT } from '@thirdparty/constants';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { QueryBuilderEndAdornment } from './query-builder-end-adornment';

const LEAF_UNIT_OPTIONS = {
    [LEAF_UNIT.BYTES]: [
        { id: 'mb', name: 'MB' },
        { id: 'gb', name: 'GB' },
        { id: 'tb', name: 'TB' },
    ],
    [LEAF_UNIT.HZ]: [
        { id: 'mhz', name: 'MHz' },
        { id: 'ghz', name: 'GHz' },
    ],
    [LEAF_UNIT.BIT]: [
        { id: 'bit', name: 'bit' },
        { id: 'mbit', name: 'mbit' },
    ],
    [LEAF_UNIT.CM]: [
        { id: 'cm', name: 'cm' },
        { id: 'm', name: 'm' },
    ],
    [LEAF_UNIT.INCH]: [{ id: 'inch', name: 'inch' }],
    [LEAF_UNIT.PERCENT]: [{ id: 'percent', name: '%' }],
    [LEAF_UNIT.MAH]: [{ id: 'mah', name: 'mAh' }],
    [LEAF_UNIT.VOLT]: [{ id: 'v', name: 'Volt' }],
    [LEAF_UNIT.RELATIVE]: [
        { id: 'minute', name: 'minute' },
        { id: 'hour', name: 'hour' },
        { id: 'day', name: 'day' },
        { id: 'week', name: 'week' },
        { id: 'month', name: 'month' },
        { id: 'year', name: 'year' },
    ],
};

const QueryBuilderLeafComponent = forwardRef(
    ({ performFiltersAreDirtyCheck, notifyAboutMissingLeafData, onLeafTypeStringChange, onLeafTypeStringFocus, showMongoHints }, leafRef) => {
        const [, setLastRefresh] = useState(0);

        const { type } = leafRef;

        const refreshAndDirtyCheck = () => {
            setLastRefresh(now());
            performFiltersAreDirtyCheck();
        };

        if (type === LEAF_TYPE.STRING) {
            return (
                <LeafTypeString
                    ref={leafRef}
                    onChange={refreshAndDirtyCheck}
                    onLeafTypeStringChange={onLeafTypeStringChange}
                    onLeafTypeStringFocus={onLeafTypeStringFocus}
                    showMongoHints={showMongoHints}
                />
            );
        }
        if (type === LEAF_TYPE.DATE) {
            return (
                <LeafTypeDate
                    ref={leafRef}
                    onChange={refreshAndDirtyCheck}
                />
            );
        }
        if (type === LEAF_TYPE.NUMBER) {
            return (
                <LeafTypeNumber
                    ref={leafRef}
                    onChange={refreshAndDirtyCheck}
                />
            );
        }

        if (type === LEAF_TYPE.BOOLEAN) {
            return (
                <LeafTypeBoolean
                    ref={leafRef}
                    onChange={refreshAndDirtyCheck}
                />
            );
        }
        if (type === LEAF_TYPE.SELECT) {
            return (
                <LeafTypeSelect
                    ref={leafRef}
                    onChange={refreshAndDirtyCheck}
                    notifyAboutMissingLeafData={notifyAboutMissingLeafData}
                />
            );
        }
        return (
            <LayoutRow
                className="tw-ml-2"
                verticalAlign="center"
            >
                <Ellipsis>
                    <b>Unhandled leaf type "{type}"</b>
                </Ellipsis>
            </LayoutRow>
        );
    }
);

const LeafTypeNumber = forwardRef(({ onChange }, leafRef) => {
    const { unit } = leafRef;
    const minRef = useRef();
    const maxRef = useRef();

    const getSelectedUnit = (val) => {
        if (unit === LEAF_UNIT.BYTES) {
            if (Number(val) >= 1099511627776) {
                return LEAF_UNIT_OPTIONS[unit][2];
            }
            if (Number(val) >= 1073741824) {
                return LEAF_UNIT_OPTIONS[unit][1];
            }
        } else if (unit === LEAF_UNIT.HZ) {
            if (Number(val) >= 1000000000) {
                return LEAF_UNIT_OPTIONS[unit][1];
            }
        } else if (unit === LEAF_UNIT.CM) {
            if (Number(val) >= 100) {
                return LEAF_UNIT_OPTIONS[unit][1];
            }
        } else if (unit === LEAF_UNIT.BIT) {
            if (Number(val) >= 1000000) {
                return LEAF_UNIT_OPTIONS[unit][1];
            }
        }
        return LEAF_UNIT_OPTIONS[unit][0];
    };

    const [selectedUnit, setSelectedUnit] = useState(unit ? [getSelectedUnit(leafRef.value.min)] : []);

    const convertUnit = (val) => {
        if (val) {
            switch (selectedUnit[0].id) {
                case 'mb': {
                    return val * 1048576;
                }
                case 'gb': {
                    return val * 1073741824;
                }
                case 'tb': {
                    return val * 1099511627776;
                }
                case 'mhz': {
                    return val * 1000000;
                }
                case 'ghz': {
                    return val * 1000000000;
                }
                case 'mbit': {
                    return val * 1000000;
                }
                case 'm': {
                    return val * 100;
                }
            }
        }
        return val;
    };

    const deconvertUnit = (val) => {
        if (val) {
            switch (selectedUnit[0].id) {
                case 'mb': {
                    return val / 1048576;
                }
                case 'gb': {
                    return val / 1073741824;
                }
                case 'tb': {
                    return val / 1099511627776;
                }
                case 'mhz': {
                    return val / 1000000;
                }
                case 'ghz': {
                    return val / 1000000000;
                }
                case 'mbit': {
                    return val / 1000000;
                }
                case 'm': {
                    return val / 100;
                }
            }
        }
        return val;
    };

    const onChangeHandler = () => {
        let newMax = maxRef.current.value,
            newMin = minRef.current.value;
        if (newMax && Number(newMin) > Number(newMax)) {
            newMax = newMin;
        }

        if (unit) {
            newMin = convertUnit(newMin);
            newMax = convertUnit(newMax);
        }
        leafRef.value.min = newMin;
        leafRef.value.max = newMax;
        onChange();
    };

    useEffect(onChangeHandler, [selectedUnit]);

    const getValue = (val) => {
        if (unit) {
            return String(deconvertUnit(val));
        }
        return val;
    };

    const min = getValue(leafRef.value.min),
        max = getValue(leafRef.value.max);

    return (
        <>
            <Input
                inputRef={(ref) => (minRef.current = ref)}
                type="number"
                light
                placeholder="Min"
                value={min || ''}
                onChange={onChangeHandler}
                min={0}
                max={max || undefined}
                disableSpinner
                callToAction
            />
            <Icon
                type="dash"
                size="small"
                className="tw-mx-1 tw-text-gray-300"
            />
            <Input
                inputRef={(ref) => (maxRef.current = ref)}
                type="number"
                light
                placeholder="Max"
                defaultValue={max || ''}
                onChange={onChangeHandler}
                min={min || 0}
                disableSpinner
                callToAction
            />
            {unit && (
                <Select
                    options={LEAF_UNIT_OPTIONS[unit]}
                    selectedOptions={selectedUnit}
                    onChange={setSelectedUnit}
                    light
                    disableLabelEllipsis
                    required
                    className="tw-ml-2"
                    callToAction
                />
            )}
        </>
    );
});

const LeafTypeSelect = forwardRef(({ onChange, notifyAboutMissingLeafData }, leafRef) => {
    const [searchTerm, setSearchTerm] = useState('');

    const onSelectChange = (selectedOptions) => {
        leafRef.value = selectedOptions.map((option) => option.id);
        onChange();
    };

    const onSelectOpen = () => {
        if (leafRef.options.length === 0) {
            notifyAboutMissingLeafData(leafRef);
        }
    };

    return (
        <Select
            light
            required={leafRef.required}
            options={leafRef.options.filter((option) => option.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))}
            selectedOptions={leafRef.options.filter((option) => leafRef.value.includes(option.id))}
            placeholder={leafRef.placeholder}
            multiple={leafRef.multiple}
            onChange={onSelectChange}
            onOpen={onSelectOpen}
            loading={leafRef.loading}
            searchTerm={searchTerm}
            onSearch={setSearchTerm}
            callToAction
        />
    );
});

const LeafTypeBoolean = forwardRef(({ onChange }, leafRef) => {
    const labels = leafRef.labels || ['Yes', 'No'];

    if (leafRef.locked && leafRef.required === false) {
        labels.push('Any');
    }

    const onPillChange = (e, tabIndex) => {
        leafRef.value = tabIndex > 1 ? undefined : tabIndex === 0;
        onChange();
    };

    return (
        <div className="tw-flex tw-flex-1 tw-justify-end">
            <div>
                <Tabs
                    value={!isBoolean(leafRef.value) ? 2 : leafRef.value ? 0 : 1}
                    onChange={onPillChange}
                    pills
                    variant="fullWidth"
                    compact
                    style={{ minWidth: 140 }}
                    className="tw-bg-transparent tw-ring-1 tw-ring-gray-200/75"
                    classes={{ indicator: 'tw-bg-sky-500/10' }}
                >
                    {labels.map((label) => (
                        <Tab
                            label={label}
                            key={label}
                            disableRipple
                            className="tw-text-sky-900"
                            classes={{ root: 'tw-text-xs' }}
                        />
                    ))}
                </Tabs>
            </div>
        </div>
    );
});

const LeafTypeString = forwardRef(({ onChange, onLeafTypeStringChange, onLeafTypeStringFocus, showMongoHints }, leafRef) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const inputRef = useRef(null);

    const setInputRef = (ref) => {
        inputRef.current = ref;
    };

    const onStringChange = (autoCompleteValueNow, event) => {
        const { target } = event;
        if (leafRef.pattern) {
            const specialCharacters = /[()|&:!]/;
            if (specialCharacters.test(target.value)) {
                setErrorMessage('Search field cannot contain special characters');
            } else {
                leafRef.value = target.value;
                setErrorMessage(null);
            }
        } else {
            leafRef.value = target.value || '';
        }
        leafRef.suggestions = [];
        onLeafTypeStringChange({ leaf: leafRef, event }, onChange);
        onChange();
    };

    const onClear = (e) => {
        leafRef.value = '';
        leafRef.suggestions = [];
        onChange();
        inputRef.current.focus();
    };

    const onFocus = (event) => {
        onLeafTypeStringFocus({ leaf: leafRef, event }, onChange);
    };

    const onSuggestionSelect = (suggestion) => {
        const { selectionStart } = inputRef.current;
        const strings = leafRef.value.split(',');
        if (strings.length > 1) {
            let charCount = 0;
            strings.some((s, index) => {
                charCount += s.length + 1; // + 1 for the comma.
                if (charCount > selectionStart) {
                    strings[index] = suggestion.name;
                    return true;
                }
                return false;
            });
            leafRef.value = strings.join();
        } else {
            leafRef.value = suggestion.name;
        }
        onChange();
        leafRef.suggestions = [];
    };

    return (
        <AutoComplete
            fullWidth
            callToAction
            searchTerm={leafRef.value}
            value={leafRef.value}
            suggestions={leafRef.suggestions || []}
            onSelect={onSuggestionSelect}
            onSearch={onStringChange}
            containerClassName="tw-w-full"
            onFocus={onFocus}
            light
            autoComplete="off"
            type="text"
            endAdornment={
                <QueryBuilderEndAdornment
                    value={leafRef.value}
                    onClick={onClear}
                    showMongoHints={showMongoHints}
                    placeholder={leafRef.placeholder}
                />
            }
            placeholder={leafRef.placeholder}
            error={errorMessage !== null}
            helperText={errorMessage}
            maxPopperHeight={320}
            disableTextSelectOnFocus
            inputRef={setInputRef}
            dense
        />
    );
});

const LeafTypeDate = forwardRef(({ onChange }, leafRef) => {
    const minRef = useRef();
    const maxRef = useRef();

    const onMinMaxChangeHandler = () => {
        let newMax = maxRef.current.value,
            newMin = minRef.current.value;
        if (!leafRef.value?.min) {
            leafRef.value = {
                min: undefined,
                max: undefined,
            };
        }
        leafRef.value.min = newMin;
        leafRef.value.max = newMax;
        onChange();
    };

    const getSelectedUnit = (unit) => {
        return LEAF_UNIT_OPTIONS[LEAF_UNIT.RELATIVE].find(({ id }) => id === unit) || LEAF_UNIT_OPTIONS[LEAF_UNIT.RELATIVE][1];
    };

    const [selectedUnit, setSelectedUnit] = useState([getSelectedUnit(leafRef.unit)]);

    const onRelativeChangeHandler = () => {
        const value = minRef.current.value ? Math.floor(Number(minRef.current.value)) : undefined;
        leafRef.value = value;
        leafRef.unit = selectedUnit[0].id;
        onChange();
    };

    useEffect(() => {
        if (leafRef.operator.id === LEAF_OPERATOR.DATE_SUBTRACT.id) {
            onRelativeChangeHandler();
        }
    }, [selectedUnit]);

    useEffect(() => {
        if (leafRef.operator.id === LEAF_OPERATOR.DATE_SUBTRACT.id && typeof leafRef.privateState.initial.value === 'object') {
            leafRef.value = undefined;
        }
        if (leafRef.operator.id === LEAF_OPERATOR.RANGE.id && typeof leafRef.privateState.initial.value === 'number') {
            leafRef.value = {
                min: undefined,
                max: undefined,
            };
        }
    }, [leafRef.operator]);

    if (leafRef.operator.id === LEAF_OPERATOR.DATE_SUBTRACT.id) {
        return (
            <>
                <Input
                    inputRef={(ref) => (minRef.current = ref)}
                    type="number"
                    light
                    value={leafRef.value}
                    min={0}
                    onChange={onRelativeChangeHandler}
                    callToAction
                />
                <Select
                    options={LEAF_UNIT_OPTIONS[LEAF_UNIT.RELATIVE]}
                    selectedOptions={selectedUnit}
                    onChange={setSelectedUnit}
                    light
                    disableLabelEllipsis
                    required
                    className="tw-ml-2"
                    callToAction
                />
            </>
        );
    } else {
        return (
            <>
                <Input
                    inputRef={(ref) => (minRef.current = ref)}
                    type="date"
                    light
                    value={leafRef.value?.min || ''}
                    onChange={onMinMaxChangeHandler}
                    callToAction
                />
                <Icon
                    type="dash"
                    size="small"
                    className="tw-mx-1 tw-text-gray-300"
                />
                <Input
                    inputRef={(ref) => (maxRef.current = ref)}
                    type="date"
                    light
                    value={leafRef.value?.max || ''}
                    onChange={onMinMaxChangeHandler}
                    callToAction
                />
            </>
        );
    }
});

export { QueryBuilderLeafComponent };
