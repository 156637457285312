export const AndroidWifiConfiguration = {
    schema: {
        title: 'WiFi',
        type: 'object',
        category: 'Networking',
        schemaId: 'wifiConfiguration',
        properties: {
            wifiConfigDisabled: {
                title: 'Wifi Config Disabled',
                description: 'Whether configuring Wi-Fi access points is disabled.',
                help: "Note: If a network connection can't be made at boot time and configuring Wi-Fi is disabled then network escape hatch will be shown in order to refresh the device policy",
                type: 'boolean',
                isDeprecated: true,
                setUrl: 'Policy.FIELDS.wifi_config_disabled',
            },
            openNetworkConfiguration: {
                title: 'Open Network Configuration',
                description: "Network configuration for the device using Google's Open Network Configuration",
                type: 'object',
                properties: {
                    NetworkConfigurations: {
                        title: 'Network Configurations',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                GUID: {
                                    title: 'GUID',
                                    description:
                                        'A unique identifier for this network connection, which exists to make it possible to update previously imported configurations.',
                                    help: 'Note: Must be a non-empty string.',
                                    type: 'string',
                                    required: true,
                                },
                                Name: {
                                    title: 'Name',
                                    description:
                                        'A user-friendly description of this connection. This name will not be used for referencing and may not be unique. Instead it may be used for describing the network to the user.',
                                    type: 'string',
                                    required: true,
                                },
                                Type: {
                                    title: 'Type',
                                    description: 'Indicates which kind of connection this is.',
                                    help: 'Note: Only WiFi is currently supported.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'WiFi',
                                            name: 'WiFi',
                                        },
                                    ],
                                },
                                WiFi: {
                                    title: 'WiFi',
                                    description: 'WiFi settings',
                                    type: 'object',
                                    properties: {
                                        SSID: {
                                            title: 'SSID',
                                            description: 'Property to access the decoded SSID of a network.',
                                            help: "Note: If this field is set, but 'HexSSID' is not, its value will be UTF-8 encoded and the hex representation will be assigned to 'HexSSID'. To configure a non-UTF-8 SSID, field 'HexSSID' must be used. When reading the configuration of a network, both this field and 'HexSSID' might be set. Then this field is the decoding of 'HexSSID'. If possible the HexSSID is decoded using UTF-8, otherwise an encoding is guessed on a best effort basis.",
                                            type: 'string',
                                        },
                                        Security: {
                                            title: 'Security',
                                            description: '',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'None',
                                                    name: 'None',
                                                },
                                                {
                                                    id: 'WEP-PSK',
                                                    name: 'WEP-PSK',
                                                },
                                                {
                                                    id: 'WEP-8021X',
                                                    name: 'WEP-8021X',
                                                },
                                                {
                                                    id: 'WPA-PSK',
                                                    name: 'WPA-PSK',
                                                },
                                                {
                                                    id: 'WPA-EAP',
                                                    name: 'WPA-EAP',
                                                },
                                            ],
                                            required: true,
                                        },
                                        Passphrase: {
                                            title: 'Passphrase',
                                            description: 'Describes the passphrase for WEP/WPA/WPA2 connections.',
                                            help: "Note: If 'WEP-PSK' is used, the passphrase must be of the format 0x<hex-number>, where <hex-number> is 40, 104, 128, or 232 bits.",
                                            type: 'string',
                                            required: true,
                                            shouldRender: (siblings: any) => {
                                                if (siblings.Security === 'WEP-PSK' || siblings.Security === 'WPA-PSK') {
                                                    return true;
                                                }
                                                delete siblings.Passphrase;
                                                return false;
                                            },
                                        },
                                        AutoConnect: {
                                            title: 'AutoConnect',
                                            description: 'Indicating that the network should be connected to automatically when in range.',
                                            type: 'boolean',
                                        },
                                        HexSSID: {
                                            title: 'HexSSID',
                                            description: "Hex representation of the network's SSID.",
                                            type: 'string',
                                        },
                                        HiddenSSID: {
                                            title: 'HiddenSSID',
                                            description: 'Indicating if the SSID will be broadcast.',
                                            type: 'boolean',
                                        },
                                        EAP: {
                                            title: 'EAP',
                                            description: 'EAP settings.',
                                            type: 'object',
                                            properties: {
                                                ClientCertType: {
                                                    title: 'ClientCertType',
                                                    description:
                                                        'Ref and Pattern indicate that the associated property should be used to identify the client certificate.',
                                                    help: "Note: 'PKCS11Id' is used when representing a certificate in a local store and is only valid when describing a local configuration. None indicates that the server is configured to not require client certificates.",
                                                    type: 'string',
                                                    options: [
                                                        {
                                                            id: 'None',
                                                            name: 'None',
                                                        },
                                                        {
                                                            id: 'Ref',
                                                            name: 'Ref',
                                                        },
                                                    ],
                                                },
                                                ClientCertRef: {
                                                    title: 'ClientCertRef',
                                                    description: 'Reference to client certificate stored in certificate section.',
                                                    help: "Note: Required if 'Client Cert Type' is set to 'Ref', otherwise ignored.",
                                                    type: 'string',
                                                    required: true,
                                                    shouldRender: (siblings: any) => {
                                                        if (siblings.ClientCertType === 'Ref') {
                                                            return true;
                                                        }
                                                        delete siblings.ClientCertRef;
                                                        return false;
                                                    },
                                                },
                                                Outer: {
                                                    title: 'Outer',
                                                    description: '',
                                                    type: 'string',
                                                    options: [
                                                        {
                                                            id: 'EAP-AKA',
                                                            name: 'EAP-AKA',
                                                        },
                                                        {
                                                            id: 'EAP-TLS',
                                                            name: 'EAP-TLS',
                                                        },
                                                        {
                                                            id: 'EAP-TTLS',
                                                            name: 'EAP-TTLS',
                                                        },
                                                        {
                                                            id: 'EAP-SIM',
                                                            name: 'EAP-SIM',
                                                        },
                                                        {
                                                            id: 'PEAP',
                                                            name: 'PEAP',
                                                        },
                                                    ],
                                                },
                                                Inner: {
                                                    title: 'Inner',
                                                    description: 'For tunneling outer protocols.',
                                                    help: "Note: Optional if 'Outer' is set to 'EAP-TTLS' or 'PEAP', otherwise ignored, defaults to 'Automatic'.",
                                                    type: 'string',
                                                    options: [
                                                        {
                                                            id: 'Automatic',
                                                            name: 'Automatic',
                                                        },
                                                        {
                                                            id: 'MSCHAPv2',
                                                            name: 'MSCHAPv2',
                                                        },
                                                        {
                                                            id: 'PAP',
                                                            name: 'PAP',
                                                        },
                                                    ],
                                                    required: true,
                                                    shouldRender: (siblings: any) => {
                                                        if (siblings.Outer === 'EAP-TTLS' || siblings.Outer === 'PEAP') {
                                                            return true;
                                                        }
                                                        delete siblings.Inner;
                                                        return false;
                                                    },
                                                },
                                                Identity: {
                                                    title: 'Identity',
                                                    description:
                                                        "Identity of user. For tunneling outer protocols (PEAP and EAP-TTLS), this is used to authenticate inside the tunnel, and 'Anonymous Identity' is used for the EAP identity outside the tunnel. For non-tunneling outer protocols, this is used for the EAP identity.",
                                                    help: 'Note: This value is subject to string expansions.',
                                                    type: 'string',
                                                    shouldRender: (siblings: any) => {
                                                        if (siblings.Outer === 'EAP-TTLS' || siblings.Outer === 'PEAP') {
                                                            return true;
                                                        }
                                                        delete siblings.Identity;
                                                        return false;
                                                    },
                                                },
                                                AnonymousIdentity: {
                                                    title: 'AnonymousIdentity',
                                                    description:
                                                        'For tunnelling protocols only, this indicates the identity of the user presented to the outer protocol.',
                                                    help: 'Note: This value is subject to string expansions. If not specified, use empty string.',
                                                    type: 'string',
                                                    shouldRender: (siblings: any) => {
                                                        if (siblings.Outer) {
                                                            return true;
                                                        }
                                                        delete siblings.AnonymousIdentity;
                                                        return false;
                                                    },
                                                },
                                                Password: {
                                                    title: 'Password',
                                                    description: 'Password of user.',
                                                    help: 'Note: If not specified, defaults to prompting the user.',
                                                    type: 'string',
                                                    isPassword: true,
                                                },
                                                ServerCAPEMs: {
                                                    title: 'ServerCAPEMs',
                                                    description: 'Non-empty list of CA certificates in PEM format.',
                                                    help: "Note: If this field is set, 'ServerCARefs' must be unset.",
                                                    type: 'array',
                                                    items: {
                                                        type: 'string',
                                                    },
                                                    shouldRender: (siblings: any) => {
                                                        if (siblings.ServerCARefs && siblings.ServerCARefs.length) {
                                                            delete siblings.ServerCAPEMs;
                                                            return false;
                                                        }
                                                        return true;
                                                    },
                                                },
                                                ServerCARefs: {
                                                    title: 'ServerCARefs',
                                                    description:
                                                        "Non-empty list of references to CA certificates in 'Certificates' to be used for verifying the host's certificate chain.",
                                                    help: "Note: At least one of the CA certificates must match. If 'ServerCARefs' is set, the client does not check that the server certificate is signed by a specific CA. A verification using the system's CA certificates may still apply. See UseSystemCAs for this.",
                                                    type: 'array',
                                                    items: {
                                                        type: 'string',
                                                    },
                                                    shouldRender: (siblings: any) => {
                                                        if (siblings.ServerCAPEMs && siblings.ServerCAPEMs.length) {
                                                            delete siblings.ServerCARefs;
                                                            return false;
                                                        }
                                                        return true;
                                                    },
                                                },
                                                SubjectMatch: {
                                                    title: 'SubjectMatch',
                                                    description:
                                                        'WiFi only. A substring which a remote RADIUS service certificate subject name must contain in order to connect.',
                                                    type: 'string',
                                                },
                                                SubjectAlternativeNameMatch: {
                                                    title: 'SubjectAlternativeNameMatch',
                                                    description:
                                                        'WiFi only. A list of alternative subject names to be matched against the alternative subject name of an authentication server certificate.',
                                                    type: 'array',
                                                    items: {
                                                        type: 'object',
                                                        properties: {
                                                            Type: {
                                                                title: 'Type',
                                                                description: 'Type of the alternative subject name.',
                                                                type: 'string',
                                                                options: [
                                                                    {
                                                                        id: 'EMAIL',
                                                                        name: 'EMAIL',
                                                                    },
                                                                    {
                                                                        id: 'DNS',
                                                                        name: 'DNS',
                                                                    },
                                                                    {
                                                                        id: 'URI',
                                                                        name: 'URI',
                                                                    },
                                                                ],
                                                            },
                                                            Value: {
                                                                title: 'Value',
                                                                description: 'Value of the alternative subject name.',
                                                                type: 'string',
                                                            },
                                                        },
                                                    },
                                                },
                                                DomainSuffixMatch: {
                                                    title: 'DomainSuffixMatch',
                                                    description: 'WiFi only. A list of constraints for the server domain name.',
                                                    help: 'Note: If set, the entries will be used as suffix match requirements against the DNS name element(s) of the alternative subject name of an authentication server certificate. When multiple match strings are specified, a match with any one of the values is considered a sufficient match for the server certificate.',
                                                    type: 'array',
                                                    items: {
                                                        type: 'string',
                                                    },
                                                },
                                                TLSVersionMax: {
                                                    title: 'TLSVersionMax',
                                                    description: 'Sets the maximum TLS protocol version used by the OS for EAP.',
                                                    help: 'Note: This is only needed when connecting to an AP with a buggy TLS implementation, as the protocol will normally auto-negotiate.',
                                                    type: 'string',
                                                    options: [
                                                        {
                                                            id: '1.0',
                                                            name: '1.0',
                                                        },
                                                        {
                                                            id: '1.1',
                                                            name: '1.1',
                                                        },
                                                        {
                                                            id: '1.2',
                                                            name: '1.2',
                                                        },
                                                    ],
                                                },
                                                UseProactiveKeyCaching: {
                                                    title: 'UseProactiveKeyCaching',
                                                    description:
                                                        "Indicates whether 'Proactive Key Caching' (also known as Opportunistic Key Caching) should be used on a per-service basis.",
                                                    type: 'boolean',
                                                },
                                            },
                                        },
                                    },
                                    shouldRender: (siblings: any) => {
                                        if (siblings.Type === 'WiFi') {
                                            return true;
                                        }
                                        delete siblings.WiFi;
                                        return false;
                                    },
                                },
                            },
                        },
                    },
                    Certificates: {
                        title: 'Certificates',
                        description: 'Contains certificates stored in X.509 or PKCS#12 format.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                GUID: {
                                    title: 'GUID',
                                    description: 'A unique identifier for this certificate.',
                                    help: 'Note: Must be a non-empty string.',
                                    type: 'string',
                                    required: true,
                                },
                                Type: {
                                    title: 'Type',
                                    description:
                                        'Client indicates the certificate is for identifying the user or device over HTTPS or for VPN/802.1X. Server indicates the certificate identifies an HTTPS or VPN/802.1X peer. Authority indicates the certificate is a certificate authority and any certificates it issues should be trusted.',
                                    help: "Note: If 'Type' disagrees with the x509 v3 basic constraints or key usage attributes, the 'Type' field should be honored.",
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'Client',
                                            name: 'Client',
                                        },
                                        {
                                            id: 'Server',
                                            name: 'Server',
                                        },
                                        {
                                            id: 'Authority',
                                            name: 'Authority',
                                        },
                                    ],
                                },
                                PKCS12: {
                                    title: 'PKCS12',
                                    description: 'For certificates with private keys, this is the base64 encoding of a PKCS#12 file.',
                                    help: "Note: Required if 'Type' is set to 'Client', otherwise ignored.",
                                    type: 'string',
                                    required: true,
                                    shouldRender: (siblings: any) => {
                                        if (siblings.Type === 'Client') {
                                            return true;
                                        }
                                        delete siblings.PKCS12;
                                        return false;
                                    },
                                },
                                X509: {
                                    title: 'X509',
                                    description: 'For certificate without private keys, this is the X509 certificate in PEM format.',
                                    help: "Note: Required if 'Type' is set to 'Server' or 'Authority', otherwise ignored.",
                                    type: 'string',
                                    required: true,
                                    shouldRender: (siblings: any) => {
                                        if (siblings.Type === 'Server' || siblings.Type === 'Authority') {
                                            return true;
                                        }
                                        delete siblings.X509;
                                        return false;
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
