export const AppleLdapConfiguration = {
    schema: {
        schemaId: 'appleLDAPAccountConfiguration',
        category: 'Accounts',
        title: 'LDAP',
        type: 'object',
        properties: {
            'com.apple.ldap.account': {
                title: '',
                type: 'object',
                properties: {
                    LDAPAccountHostName: {
                        title: 'LDAP Account Host Name',
                        description: 'The server’s address.',
                        type: 'string',
                        required: true,
                    },
                    LDAPAccountUserName: {
                        title: 'LDAP Account UserName',
                        description: 'The user’s username.',
                        type: 'string',
                    },
                    LDAPAccountPassword: {
                        title: 'LDAP Account Password',
                        description: 'The user’s password. The password is enabled only with encrypted profiles.',
                        type: 'string',
                        isPassword: true,
                    },
                    LDAPAccountDescription: {
                        title: 'LDAP Account Description',
                        description: 'The description of the account.',
                        type: 'string',
                        multiline: true,
                    },
                    LDAPAccountUseSSL: {
                        title: 'LDAP Account UseSSL',
                        description: 'If true, enables SSL.',
                        type: 'boolean',
                    },
                    VPNUUID: {
                        title: 'VPN UUID',
                        description: 'The VPNUUID of the per-app VPN the account uses for network communication.',
                        type: 'string',
                    },
                    LDAPSearchSettings: {
                        title: 'LDAP Search Settings',
                        description: 'An array of search settings dictionaries.',
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                LDAPSearchSettingSearchBase: {
                                    title: 'LDAP Search Setting Search Base',
                                    description: 'The path to the node where a search should start',
                                    type: 'string',
                                    required: true,
                                },
                                LDAPSearchSettingDescription: {
                                    title: 'LDAP Search Setting Description',
                                    description: 'The description of this search setting.',
                                    type: 'string',
                                    multiline: true,
                                },
                                LDAPSearchSettingScope: {
                                    title: 'LDAP Search Setting Scope',
                                    description: 'The type of recursion to use in the search',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'LDAPSearchSettingScopeBase',
                                            name: 'LDAPSearchSettingScopeBase: Only the immediate node that the search base points to.',
                                        },
                                        {
                                            id: 'LDAPSearchSettingScopeOneLevel',
                                            name: 'LDAPSearchSettingScopeOneLevel: The node plus its immediate children.',
                                        },
                                        {
                                            id: 'LDAPSearchSettingScopeSubtree',
                                            name: 'LDAPSearchSettingScopeSubtree: The node plus all children, regardless of depth.',
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
