import TabMUI, { TabProps as MUITabProps } from '@mui/material/Tab';
import classnames from 'classnames';
import React from 'react';

type TTabsClasses = MUITabProps['classes'];

const tabClasses = ({ root, selected, disabled, ...rest }: TTabsClasses = {}) => ({
    root: classnames('cs-tab', root),
    selected: classnames('cs-tab-selected', selected),
    disabled: classnames('cs-tab-disabled', disabled),
    ...rest,
});

export type TabProps = MUITabProps;

/** Tab component */
export const Tab: React.FC<MUITabProps> = ({ classes = {}, ...rest }) => (
    <TabMUI
        classes={tabClasses(classes)}
        disableRipple
        {...rest}
    />
);

export default Tab;
