//Documentation

//Domains
//https://developer.apple.com/documentation/devicemanagement/domains

export const AppleDomainsConfiguration = {
    //Specify com.apple.domains as the payload type
    schema: {
        schemaId: 'appleDomainsConfiguration',
        category: 'Networking',
        title: 'Domains',
        type: 'object',
        properties: {
            'com.apple.domains': {
                title: '',
                skipIndent: true,
                type: 'object',
                properties: {
                    WebDomains: {
                        title: 'Web Domains',
                        description: 'An array of domains. The system considers URLs matching the patterns listed in this property managed.',
                        help: 'Available in iOS 9.3 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    EmailDomains: {
                        title: 'Email Domains',
                        description:
                            'An array of domains. The system considers email addresses that lack a suffix matching any of these strings out of domain and marked in Mail.',
                        help: 'Available in iOS 8 and later and macOS 10.10 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    SafariPasswordAutoFillDomains: {
                        title: 'Safari Password Auto Fill Domains',
                        description:
                            'An array of domains. Users can only save passwords in Safari from URLs matching the patterns listed here. This property doesn’t disable the autofill feature itself.',
                        help: 'Supervised devices or Shared iPads need this property to enable saving passwords in Safari. Available in iOS 9.3 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    CrossSiteTrackingPreventionRelaxedDomains: {
                        title: 'Cross Site Tracking Prevention Relaxed Domains',
                        description:
                            'An array of up to 10 strings. URLs matching the patterns listed here have relaxed enforcement of cross-site tracking prevention.',
                        help: 'Available in iOS 16.2 and later and macOS 13.1 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                },
            },
        },
    },
};
