const BASE = 'cs-button-base';
const VARIANT_CLASS_PREFIX = `${BASE}-`;
const COLOR_CLASS_PREFIX = `${BASE}-color-`;
const SIZE_CLASS_PREFIX = `${BASE}-size-`;

export const ROOT_CLASS_BUTTON = 'cs-button';
export const ROOT_CLASS_ICON_BUTTON = 'cs-icon-button';
export const DISABLED_CLASS = `${BASE}-disabled`;
export const FOCUSED_CLASS = `${BASE}-focused`;
export const FULL_WIDTH_CLASS = `${BASE}-full-width`;
export const LABEL_CLASS = `${BASE}-label`;
export const START_ICON_CLASS = `${BASE}-start-icon`;
export const END_ICON_CLASS = `${BASE}-end-icon`;

const getMarginClass = (noMargin) => {
    if (noMargin) {
        return `${BASE}-no-margin`;
    }
    return '';
};

export const getRootClasses = (rootClass, color, variant, size, noMargin) =>
    `${rootClass} ${BASE} ${COLOR_CLASS_PREFIX}${color} ${VARIANT_CLASS_PREFIX}${variant} ${SIZE_CLASS_PREFIX}${size} ${getMarginClass(noMargin)}`;
