export const convertCapapacksPlatform = (platform: string) => {
    switch (platform.toLocaleLowerCase()) {
        case 'both':
            return 'Auto';
        default:
            return platform;
    }
};

export const convertCapapacksLanguage = (language: string) => {
    switch (language.toLocaleLowerCase()) {
        case 'neutral':
            return 'System language';
        default:
            return language;
    }
};
