/* eslint-disable linebreak-style, react/jsx-one-expression-per-line */
import { bool, func, node, number } from 'prop-types';
import React, { useEffect, useState } from 'react';

export const DragDropContext = React.createContext(null);

export const DragAndDropProvider = ({
    strictGroupDragging = false,
    strictGroupSelecting = false,
    maxDraggable = 9999999,
    children,
    getState = () => null,
    showSelectedCount = false,
    supportMoveSelectedFromMenu = false,
}) => {
    /* istanbul ignore next */
    const [state, setstate] = useState({
        updateDragItems: (item, remove = false) => {
            if (window.event.shiftKey) {
                doSelectMulti(item);
            } else if (!remove) {
                doSelectSingle(item);
            } else if (remove) {
                if (state.selected.length > 1 && !window.event.ctrlKey && !window.event.metaKey) {
                    state.selected = state.selected.filter((stateItem) => stateItem === item);
                    state.lastSelected = state.selected[state.selected.length - 1];
                } else {
                    state.selected = state.selected.filter((stateItem) => stateItem !== item);
                    state.lastSelected = item;
                }
            }
            getState(state);
            setstate({ ...state });
        },
        removeAllItems: () => {
            state.selected = [];
            setstate({ ...state });
        },
        isSelected: (item) => state.selected.indexOf(item) >= 0,
        setAsSelectedable: (item) => {
            const isIn = state.selectable.find((selectable) => item.id === selectable.id);
            if (state.selectable.find((selectable) => item.id === selectable.id)) {
                state.selectable[state.selectable.indexOf(isIn)] = item;
            } else {
                state.selectable.push(item);
            }
        },
        resetSelectable: () => {
            state.selectable = [];
            setstate({ ...state });
        },
        setIsDragging: (isDragging) => {
            state.isDragging = isDragging;
            setstate({ ...state });
        },
        checkContainAcceptedItems: (checking) => {
            /** using find for optimizing iterations */
            const contains = state.selected.find((item) => {
                if (item) {
                    if (item.id === checking.id) {
                        return item;
                    }
                    if (
                        (state.strictGroupDragging &&
                            ((item.group.length > checking.group.length && item.group.split('_')[item.group.split('_').length - 2] === checking.id) ||
                                checking.group.includes(item.id))) ||
                        checking.dropTypes.indexOf(item.itemType) < 0
                    ) {
                        return item;
                    }
                    return null;
                }
            });

            return !contains && state.selected.length > 0;
        },
        selectable: [],
        selected: [],
        lastSelected: null,
        isDragging: false,
        strictGroupDragging,
        strictGroupSelecting,
        showSelectedCount,
        supportMoveSelectedFromMenu,
    });

    /* istanbul ignore next */
    const doSelectSingle = (item) => {
        const doItemHaveSame = state.selected.find(
            (tempItem) => tempItem.group.substr(0, tempItem.group.lastIndexOf('_')) === item.group.substr(0, item.group.lastIndexOf('_'))
        );
        if ((!window.event.ctrlKey && !window.event.metaKey) || (strictGroupSelecting && !doItemHaveSame)) {
            state.selected = [];
        }
        if (state.selected.length < maxDraggable) {
            state.selected.push(item);
            state.lastSelected = item;
        }
    };

    /* istanbul ignore next */
    const selectIfNotSelected = (i) => {
        const data = state.selectable[i];
        const doItemHaveSame = state.selected.find(
            (tempItem) => tempItem.group.substr(0, tempItem.group.lastIndexOf('_')) === data.group.substr(0, data.group.lastIndexOf('_'))
        );
        if (state.selected.indexOf(data) < 0 && (state.selected.length > 0 ? doItemHaveSame : true)) {
            state.selected.push(data);
        }
    };

    /* istanbul ignore next */
    const doSelectFromFirstGroupMember = (item) => {
        const firstOfGroup = state.selectable.find(
            (tempItem) => tempItem.group.substr(0, tempItem.group.lastIndexOf('_')) === item.group.substr(0, item.group.lastIndexOf('_'))
        );
        const startIndex = state.selectable.indexOf(firstOfGroup);
        const endIndex = state.selectable.indexOf(item);
        state.selected = [];
        for (let i = startIndex; i <= endIndex; i += 1) {
            const itemOfIndex = state.selectable[i];
            if (item.group.substr(0, item.group.lastIndexOf('_')) === itemOfIndex.group.substr(0, itemOfIndex.group.lastIndexOf('_'))) {
                state.selected.push(state.selectable[i]);
            }
        }
        state.lastSelected = firstOfGroup;
    };

    /* istanbul ignore next */
    const doSelectMulti = (item) => {
        /** If stricSelecting is triggered make it single select */
        const doItemHaveSame = state.selected.find(
            (tempItem) => tempItem.group.substr(0, tempItem.group.lastIndexOf('_')) === item.group.substr(0, item.group.lastIndexOf('_'))
        );

        if (state.selected.length < 1 || (strictGroupSelecting && !doItemHaveSame)) {
            doSelectFromFirstGroupMember(item);
            return;
        }

        const index = state.selectable.indexOf(item);
        const lastSelectedIndex = state.selectable.indexOf(state.lastSelected);
        state.selected = [];
        if (index < lastSelectedIndex) {
            for (let i = lastSelectedIndex; i >= index && state.selected.length < maxDraggable; i -= 1) {
                selectIfNotSelected(i);
            }
        } else if (index >= lastSelectedIndex) {
            for (let i = lastSelectedIndex; i < state.selectable.length && state.selected.length < maxDraggable && i <= index; i += 1) {
                selectIfNotSelected(i);
            }
        }

        setstate({ ...state });
    };

    useEffect(() => {
        getState(state);
    }, [state]);

    return <DragDropContext.Provider value={state}>{children}</DragDropContext.Provider>;
};

DragAndDropProvider.propTypes = {
    children: node.isRequired,
    maxDraggable: number,
    /** Don't allow draggables to drag into it's own group */
    strictGroupDragging: bool,
    /** Only allow selecting draggable objects with same group */
    strictGroupSelecting: bool,
    /** Hook into the state of the provider (get all methods as well) */
    getState: func,
    /** Show "Move selected items to" in menu */
    supportMoveSelectedFromMenu: bool,
    showSelectedCount: bool,
};

export default DragAndDropProvider;
