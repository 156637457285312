import AppBar from '@mui/material/AppBar';
import TabsMUI, { TabsProps as MUITabsProps } from '@mui/material/Tabs';
import classnames from 'classnames';
import React from 'react';

const tabsClasses = (
    { root, indicator, vertical, scrollable, flexContainer, ...rest }: MUITabsProps['classes'] & { scrollable?: boolean } = {},
    { onRails }: { onRails: boolean }
) => ({
    root: classnames('cs-tabs', root),
    indicator: classnames('cs-tabs-indicator', indicator),
    vertical: classnames('cs-tabs-vertical', vertical),
    scrollable: classnames('cs-tabs-scrollable', scrollable),
    flexContainer: classnames(
        {
            'tw-border-b-2 tw-border-blue-100': onRails,
        },
        flexContainer
    ),
    ...rest,
});

export type TabsProps = MUITabsProps & {
    pills?: boolean;
    onRails?: boolean;
    compact?: boolean;
    classes?: MUITabsProps['classes'] & { scrollable?: boolean };
};

/** Tabs component with Material UI */
export const Tabs: React.FC<TabsProps> = ({
    pills = false,
    className,
    compact = false,
    classes = {},
    onRails = false,
    variant = 'standard',
    centered = false,
    scrollButtons = false,
    value = false,
    textColor = 'inherit',
    orientation = 'horizontal',
    ...props
}) => {
    if (pills) {
        return (
            <div
                className={classnames(
                    {
                        'cs-tabs-container cs-tabs-pills': true,
                        'cs-tabs-pills-vertical': orientation === 'vertical',
                        'cs-tabs-pills-compact': compact,
                        'cs-tabs-pills-scrollable': variant === 'scrollable',
                    },
                    className
                )}
            >
                <TabsMUI
                    classes={tabsClasses(classes, { onRails: false })}
                    variant={variant}
                    centered={centered}
                    scrollButtons={scrollButtons}
                    value={value}
                    textColor={textColor}
                    orientation={orientation}
                    {...props}
                />
            </div>
        );
    }
    return (
        <AppBar
            color="transparent"
            elevation={0}
            position="static"
            className={classnames('cs-tabs-container', className)}
        >
            <TabsMUI
                classes={tabsClasses(classes, { onRails })}
                variant={variant}
                centered={centered}
                scrollButtons={scrollButtons}
                value={value}
                textColor={textColor}
                orientation={orientation}
                {...props}
            />
        </AppBar>
    );
};

export default Tabs;
