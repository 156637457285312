import { PolicyEnforcementRulesEnum } from '../android-management-enums';

export const AndroidPolicyEnforcementRulesConfiguration = {
    schema: {
        title: 'Policy Enforcement Rules',
        category: 'System',
        type: 'object',
        schemaId: 'policyEnforcementRulesConfiguration',
        properties: {
            policyEnforcementRules: {
                title: 'Policy Enforcement Rules',
                description: 'Rules that define the behavior when a particular policy can not be applied on device',
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        blockAction: {
                            title: 'Block Action',
                            description:
                                'An action to block access to apps and data on a fully managed device or in a work profile. This action also triggers a user-facing notification with information (where possible) on how to correct the compliance issue.',
                            help: 'Note: wipeAction must also be specified.',
                            type: 'object',
                            properties: {
                                blockAfterDays: {
                                    title: 'Block After Days',
                                    description: 'Number of days the policy is non-compliant before the device or work profile is blocked.',
                                    help: 'Note: To block access immediately, set to 0. Block After Days must be less than Wipe After Days.',
                                    type: 'number',
                                    required: true,
                                },
                                blockScope: {
                                    title: 'Block Scope',
                                    description: 'Specifies the scope of this BlockAction.',
                                    help: 'Note: Only applicable to devices that are company-owned.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'BLOCK_SCOPE_WORK_PROFILE',
                                            name: 'Apps in work profile only',
                                        },
                                        {
                                            id: 'BLOCK_SCOPE_DEVICE',
                                            name: 'Apps on entire device',
                                        },
                                    ],
                                },
                            },
                        },
                        wipeAction: {
                            title: 'Wipe Action',
                            description: 'An action to reset a fully managed device or delete a work profile.',
                            help: 'Note: Block Action must also be specified.',
                            type: 'object',
                            properties: {
                                wipeAfterDays: {
                                    title: 'Wipe After Days',
                                    description: 'Number of days the policy is non-compliant before the device or work profile is wiped.',
                                    help: 'Note: Wipe After Days must be greater than Block After Days.',
                                    type: 'number',
                                    required: true,
                                },
                                preserveFrp: {
                                    title: 'Preserve FRP',
                                    description: 'Whether the factory-reset protection data is preserved on the device.',
                                    help: "Note: This setting doesn't apply to work profiles.",
                                    type: 'boolean',
                                },
                            },
                            shouldRender: (siblings: any) => {
                                if (
                                    (siblings.blockAction?.blockAfterDays !== undefined || siblings.blockAction?.blockScope !== undefined) &&
                                    (siblings.blockAction?.blockAfterDays !== '' || siblings.blockAction?.blockScope !== '')
                                ) {
                                    return true;
                                } else if (siblings.blockAction?.blockAfterDays === 0) {
                                    return true;
                                }
                                delete siblings.wipeAction;
                                return false;
                            },
                        },
                        settingName: {
                            title: 'Setting Name',
                            description: 'The top-level policy to enforce. For example, applications or passwordPolicies.',
                            type: 'string',
                            required: true,
                            options: Object.entries(PolicyEnforcementRulesEnum).map(([id, name]) => ({ id, name })),
                        },
                    },
                },
            },
        },
    },
};
