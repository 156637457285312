import { Column, Ellipsis, Icon, LayoutCenter, useLocation, VirtualizedTable } from '@capasystems/ui';
import { LEAF_ID, LOCAL_STORAGE_ID } from '@thirdparty/constants';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useApi } from '../hooks/useApi/useApi';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { RELIABILITY, ReliabilityContext, useNavigate } from './../../index';

const validSeverities = Object.values(RELIABILITY.SEVERITY).map((s) => s.id);

const ReliabilityMostEventsByDeviceWidget = ({ severity, activeFilters }) => {
    const api = useApi();
    const { lockedPeriod, timezone, inDetailedMode, sampleInterval } = useContext(ReliabilityContext);
    const [widgetPaperProps, setWidgetPaperProps] = useState({
        loading: true,
    });
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        api.cancel();
        if (activeFilters === null) {
            return;
        }
        setWidgetPaperProps({
            loading: true,
        });
        const logTypeFilter = activeFilters.find((filter) => filter.id === LEAF_ID.LOG_TYPE);
        const sourceFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SOURCE);
        const evendIdFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.EVENT_ID);
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);
        api.getEventTopAffected(
            lockedPeriod,
            sampleInterval,
            timezone,
            severity.id,
            logTypeFilter?.value.join(),
            sourceFilter?.value.join(),
            evendIdFilter?.value.join(),
            searchFilter?.value
        )
            .then((response) => {
                setTableData(response);
                setWidgetPaperProps({});
            })
            .catch((error) => {
                setWidgetPaperProps({
                    errorMessage: 'Could not load the table',
                });
                console.log(error);
            });
    }, [lockedPeriod, activeFilters, api, severity, timezone]);

    const navigate = useNavigate();
    const location = useLocation();

    const onRowClick = ({ rowData }) => {
        const clickedDeviceId = rowData.mongoId || rowData['_id'];
        navigate.to(`windows/device/${clickedDeviceId}/reliability/${severity.id}${location.search || '?'}&${LOCAL_STORAGE_ID.SHOW_ACTIVITY_LOG}=false`);
    };

    const noRowsRenderer = () => (
        <LayoutCenter
            direction="column"
            className="tw-text-slaste-400 tw-text-sm"
        >
            <Icon
                type="checkmark"
                color="inherit"
                className="tw-text-8xl"
            ></Icon>
            <b className="tw-mx-4">No {pluralize(severity.name.toLocaleLowerCase())} in this period</b>
        </LayoutCenter>
    );

    return (
        <WidgetPaper
            title={`Most ${pluralize(severity.name.toLocaleLowerCase())}`}
            className="tw-h-80"
            headerClassName="tw-pb-0"
            {...widgetPaperProps}
        >
            <VirtualizedTable
                disableHeader={tableData.length === 0}
                totalRowCount={tableData.length}
                showRowCount={false}
                items={tableData}
                onRowClick={onRowClick}
                noRowsRenderer={noRowsRenderer}
            >
                <Column
                    key="deviceName"
                    dataKey="deviceName"
                    label="Endpoint"
                    minWidth={80}
                    type="string"
                    cellRenderer={({ cellData }) => <Ellipsis className="cs-link cs-link-primary">{cellData}</Ellipsis>}
                />

                {inDetailedMode ? (
                    <Column
                        key="applicationEventsCount"
                        dataKey="events"
                        label="Application"
                        minWidth={72}
                        maxWidth={72}
                        type="numbe"
                        cellRenderer={({ cellData }) => <Ellipsis>{cellData.application}</Ellipsis>}
                    />
                ) : null}
                {inDetailedMode ? (
                    <Column
                        key="systemEventsCount"
                        dataKey="events"
                        label="System"
                        minWidth={72}
                        maxWidth={72}
                        type="number"
                        cellRenderer={({ cellData }) => <Ellipsis>{cellData.system}</Ellipsis>}
                    />
                ) : null}
                {!inDetailedMode ? (
                    <Column
                        key="totalEventsCount"
                        dataKey="events"
                        label="Event count"
                        minWidth={96}
                        maxWidth={96}
                        type="number"
                        cellRenderer={({ cellData }) => <Ellipsis>{cellData.application + cellData.system}</Ellipsis>}
                    />
                ) : null}
            </VirtualizedTable>
        </WidgetPaper>
    );
};

ReliabilityMostEventsByDeviceWidget.propTypes = {
    severity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        colorRepresentation: PropTypes.string.isRequired,
    }).isRequired,
};

export { ReliabilityMostEventsByDeviceWidget };
export default ReliabilityMostEventsByDeviceWidget;
