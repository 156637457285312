export const AppleScepConfiguration = {
    schema: {
        schemaId: 'appleScepConfiguration',
        category: 'Certificates',
        title: 'SCEP',
        type: 'object',
        properties: {
            'com.apple.security.scep': {
                title: '',
                type: 'object',
                skipIndent: true,
                properties: {
                    PayloadContent: {
                        title: 'Payload Content',
                        type: 'object',
                        properties: {
                            URL: {
                                title: 'URL',
                                description: 'The URL of the SCEP server.',
                                type: 'string',
                                required: true,
                            },
                            AllowAllAppsAccess: {
                                title: 'Allow All Apps Access',
                                description: 'If true, all apps have access to the private key.',
                                type: 'boolean',
                            },
                            // CAFingerprint: {
                            //     title: 'CA Fingerprint',
                            //     description: 'The fingerprint of the CA certificate.',
                            //     type: 'data', //TODO: Support this data type?
                            // },
                            Challenge: {
                                title: 'Challenge',
                                description: 'A preshared secret.',
                                type: 'string',
                            },
                            'Key Type': {
                                //TODO: Should be "Key Type"?
                                title: 'Key Type',
                                description: 'The type of key pair to generate.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'RSA',
                                        name: 'RSA',
                                    },
                                ],
                            },
                            'Key Usage': {
                                //TODO: Should be "Key Usage"?
                                title: 'Key Usage',
                                description: 'A bitmask indicating the use of the key.',
                                help: 'Some certificate authorities, such as Windows CA, support only encryption or signing, but not both at the same time.',
                                type: 'string',
                                options: [
                                    {
                                        id: 1,
                                        name: 'Signing',
                                    },
                                    {
                                        id: 4,
                                        name: 'Encryption',
                                    },
                                ],
                            },
                            KeyIsExtractable: {
                                title: 'Key Is Extractable',
                                description: 'If false, disables exporting the private key from the keychain.',
                                type: 'boolean',
                            },
                            Keysize: {
                                title: 'Keysize',
                                description: 'The size of the key in bits.',
                                type: 'string',
                                options: [
                                    {
                                        id: 1024,
                                        name: '1024',
                                    },
                                    {
                                        id: 2048,
                                        name: '2048',
                                    },
                                    {
                                        id: 4096,
                                        name: '4096',
                                    },
                                ],
                            },
                            Name: {
                                title: 'Name',
                                description: 'A string that’s understood by the SCEP server; for example, a domain name like example.org.',
                                help: 'If a certificate authority has multiple CA certificates, this field can be used to distinguish which is required.',
                                type: 'string',
                            },
                            Retries: {
                                title: 'Retries',
                                description: 'The number of times the device should retry if the server sends a PENDING response.',
                                type: 'number',
                            },
                            RetryDelay: {
                                title: 'Retry Delay',
                                description: 'The number of seconds to wait between retries.',
                                help: 'The first retry is attempted without this delay.',
                                type: 'number',
                            },
                            Subject: {
                                title: 'Subject',
                                description: 'The representation of an X.500 name as an array of OID and value.',
                                help: 'For example, /C=US/O=Apple Inc./CN=foo/1.2.5.3=bar translates to [ [ [“C”, “US”] ], [ [“O”, “Apple Inc."] ], …, [ [ “1.2.5.3”, “bar” ] ] ]. OIDs can be represented as dotted numbers, with shortcuts for country (C), locality (L), state (ST), organization (O), organizational unit (OU), and common name (CN).',
                                type: 'string', //Converts to three dimensional array in engine
                            },
                            SubjectAltName: {
                                title: 'Subject Alt Name',
                                description: 'The subject alternative name extension.',
                                help: 'The SCEP payload can specify an optional SubjectAltName dictionary that provides values required by the CA for issuing a certificate. You can specify a single string or an array of strings for each key. The values you specify depend on the CA you’re using, but might include DNS name, URL, or email values.',
                                type: 'object',
                                properties: {
                                    dNSName: {
                                        title: 'DNS Name',
                                        description: 'The DNS name.',
                                        type: 'string',
                                    },
                                    ntPrincipalName: {
                                        title: 'NT Principal Name',
                                        description: 'The NT principal name.',
                                        type: 'string',
                                    },
                                    rfc822Name: {
                                        title: 'RFC 822 Name',
                                        description: 'The RFC 822 (email address) string.',
                                        type: 'string',
                                    },
                                    uniformResourceIdentifier: {
                                        title: 'Uniform Resource Identifier',
                                        description: 'The uniform resource identifier.',
                                        type: 'string',
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
