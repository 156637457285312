import { Url, getTheme } from '@capasystems/utils';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Loading from '../loading/loading';
import './tree-select.scss';

import { coreMessage } from '@capasystems/constants';
import {
    AutoComplete,
    Button,
    ContextDialog,
    Ellipsis,
    Icon,
    Input,
    LayoutColumn,
    LayoutFill,
    LayoutRow,
    List,
    ListItem,
    ListItemText,
    Padding,
    Toolbar,
    Tree,
    TreeNode,
} from '../../index';

const {
    capasystems: { borderColor },
} = getTheme();

const listBorderStyles = { borderRight: `1px solid ${borderColor}` };
const selectedOptionsStyle = { minWidth: 300 };
const paperProps = {
    style: {
        minWidth: 960,
        height: 700,
    },
};

export const TreeSelect = ({
    label = '',
    treeData,
    fullWidth = true,
    disabled = false,
    onClose = () => null,
    onOpen = () => null,
    searchTerm = null,
    searchSuggestions = [],
    onSearchSelect,
    onSearch = () => null,
    searching = false,
    multiple = false,
    visibleBackdrop = true,
    selectedOptions,
    noSearchResults = false,
    updateUrl = false,
    name = '',
    onExpand,
    onChange,
    onSearchSuggestionsScrolledToBottom = () => null,
}) => {
    const firstUpdate = useRef(true);
    const [contextDialogState, setContextDialogState] = useState({
        anchorEl: null,
        isOpen: false,
    });

    const [selected, setSelected] = useState({
        ids: [],
        names: '',
    });

    /* istanbul ignore next */
    useEffect(() => {
        const { isOpen } = contextDialogState;
        const selectedIds = selectedOptions.map((e) => e.id);
        setSelected({
            ids: selectedIds,
            names: selectedOptions.map((e) => e.name).join(', '),
        });
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            if (updateUrl && name) {
                Url.set(name, selectedIds);
            }
            if (isOpen && !multiple && selectedIds.length > 0) {
                // eslint-disable-next-line no-use-before-define
                closeDialog();
            }
        }
    }, [selectedOptions]);

    /* istanbul ignore next */
    const openDialog = ({ currentTarget }) => {
        if (!disabled) {
            setContextDialogState({ anchorEl: currentTarget, isOpen: true });
        }
    };

    /* istanbul ignore next */
    const closeDialog = () => {
        onClose();
        setContextDialogState({ ...contextDialogState, isOpen: false });
    };

    /* istanbul ignore next */
    const onSelect = (option, allowDeselect) => () => {
        const selectedOptionsClone = selectedOptions.slice(0);
        if (multiple) {
            const alreadySelected = selectedOptionsClone.some((opt, index) => {
                if (opt.id === option.id) {
                    if (allowDeselect) {
                        selectedOptionsClone.splice(index, 1);
                    }
                    return true;
                }
                return false;
            });
            if (!alreadySelected) {
                selectedOptionsClone.push(option);
            }
            // eslint-disable-next-line max-len
            selectedOptionsClone.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
            onChange(selectedOptionsClone);
        } else {
            const alreadySelected = selectedOptionsClone.some((opt) => opt.id === option.id);
            if (alreadySelected) {
                if (allowDeselect) {
                    onChange([]);
                }
            } else {
                onChange([option]);
            }
        }
    };

    /* istanbul ignore next */
    const renderTreeNodes = (data) =>
        data.map((item) => (
            <TreeNode
                {...item}
                key={item.id}
                dataRef={item}
                onExpand={onExpand}
                onSelect={onSelect(item, true)}
                selected={selected.ids.indexOf(item.id) > -1}
            >
                {item.children && renderTreeNodes(item.children)}
            </TreeNode>
        ));

    /* istanbul ignore next */
    const handleOnSearchSelect = (item) => {
        onSelect(item, false)();
        onSearchSelect(item);
    };

    /* istanbul ignore next */
    const onDeselect = (index) => () => {
        const selectedOptionsClone = selectedOptions.slice(0);
        selectedOptionsClone.splice(index, 1);
        onChange(selectedOptionsClone);
    };

    /* istanbul ignore next */
    const clearSelectedOptions = () => {
        onChange([]);
    };

    return (
        <Fragment>
            <Input
                value={selected.names}
                label={label}
                onClick={openDialog}
                fullWidth={fullWidth}
                disabled={disabled}
                endAdornment={
                    <Icon
                        type="unfold"
                        size="small"
                    />
                }
            />
            <ContextDialog
                open={contextDialogState.isOpen}
                onClose={closeDialog}
                onEnter={onOpen}
                visibleBackdrop={visibleBackdrop}
                anchorEl={contextDialogState.anchorEl}
                paperProps={paperProps}
                className="cs-tree-select-context-dialog"
            >
                <LayoutColumn fill>
                    {searchTerm !== null && (
                        /* istanbul ignore next */
                        <Toolbar>
                            <LayoutFill>
                                <AutoComplete
                                    autoFocus
                                    fullWidth
                                    searchTerm={searchTerm}
                                    value={searchTerm}
                                    suggestions={searchSuggestions}
                                    onSelect={handleOnSearchSelect}
                                    onSearch={onSearch}
                                    onScrolledToBottom={onSearchSuggestionsScrolledToBottom}
                                    disablePointerEvents
                                    type={searching ? 'text' : 'search'}
                                    endAdornment={searching && <Loading size={16} />}
                                    startAdornment={
                                        <Icon
                                            type="search"
                                            size="small"
                                        />
                                    }
                                    noSearchResults={noSearchResults}
                                />
                            </LayoutFill>
                        </Toolbar>
                    )}
                    <LayoutRow fill>
                        <LayoutColumn
                            scrollContent
                            fill
                        >
                            <Padding
                                factor={0.5}
                                right={32}
                            >
                                <Tree>{renderTreeNodes(treeData)}</Tree>
                            </Padding>
                        </LayoutColumn>
                        {multiple && /* istanbul ignore next */ selectedOptions.length > 0 && (
                            <React.Fragment>
                                <div style={listBorderStyles} />
                                <LayoutColumn
                                    scrollContent
                                    style={selectedOptionsStyle}
                                >
                                    <List disablePadding>
                                        {selectedOptions.map((option, index) => (
                                            <ListItem
                                                className="cs-tree-select-option"
                                                key={option.id}
                                                onClick={onDeselect(index)}
                                            >
                                                <Icon
                                                    type="checkmark"
                                                    size="small"
                                                    color="primary"
                                                />
                                                <ListItemText primary={<Ellipsis>{option.name}</Ellipsis>} />
                                                <ListItemSecondaryAction>{option.secondaryContent}</ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </LayoutColumn>
                            </React.Fragment>
                        )}
                    </LayoutRow>
                    <Toolbar compact>
                        {multiple && (
                            /* istanbul ignore next */
                            <Button
                                color="inherit"
                                onClick={clearSelectedOptions}
                                disabled={selectedOptions.length === 0}
                            >
                                <b>
                                    {coreMessage.deselect}&nbsp;({selectedOptions.length})
                                </b>
                            </Button>
                        )}
                        {!multiple && selectedOptions.length > 0 && (
                            /* istanbul ignore next */
                            <Button
                                color="inherit"
                                onClick={clearSelectedOptions}
                            >
                                {coreMessage.deselect}&nbsp;<b>{selectedOptions.map((e) => e.name).join(', ')}</b>
                            </Button>
                        )}
                        <LayoutFill />
                        <Button
                            onClick={closeDialog}
                            color="primary"
                            noMargin
                        >
                            <b>{coreMessage.apply}</b>
                        </Button>
                    </Toolbar>
                </LayoutColumn>
            </ContextDialog>
        </Fragment>
    );
};

TreeSelect.propTypes = {
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onExpand: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    treeData: PropTypes.oneOfType([
        // eslint-disable-next-line max-len
        /** Define the object later, Contains key, name, selectable, expanded, children, isLeaf etc. */
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    multiple: PropTypes.bool,
    updateUrl: PropTypes.bool,
    disabled: PropTypes.bool,
    noSearchResults: PropTypes.bool,
    searchTerm: PropTypes.string,
    onSearch: PropTypes.func,
    onSearchSuggestionsScrolledToBottom: PropTypes.func,
    searchSuggestions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            secondaryContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        })
    ),
    selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onSearchSelect: PropTypes.func.isRequired,
    name: PropTypes.string,
    searching: PropTypes.bool,
    visibleBackdrop: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

export default TreeSelect;
