import { Page, Paper, useParams } from '@capasystems/ui';
import { isDefined } from '@capasystems/utils';
import { AndroidApplication, AndroidWebAppConfiguration } from '@thirdparty/constants';
import React, { useEffect, useRef, useState } from 'react';
import { SchemaBuilder, useNavigate } from '../../../index';
import { useAndroidApi } from '../../hooks/useApi/useApi';

export type AndroidApplicationSummaryProps = {
    isApplication: boolean;
    configuration: any;
};

const AndroidApplicationSummary: React.FC<AndroidApplicationSummaryProps> = ({ isApplication, configuration }) => {
    const androidApi = useAndroidApi();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { configurationId, packageName } = useParams();

    const navigateToApplicationSummary = () => {
        navigate.to(`android/application/${configurationId}/membership`);
    };

    const propertiesBuilderRef = useRef({});

    useEffect(() => {
        if (isDefined(configurationId)) {
            propertiesBuilderRef.current = configuration.data || {};
            navigateToApplicationSummary();
        }
    }, [androidApi, configurationId, packageName]);

    if (!propertiesBuilderRef.current || Object.keys(propertiesBuilderRef.current).length === 0) {
        return (
            <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                <div className="tw-p-6 tw-font-semibold">No application data found</div>
            </div>
        );
    }
    return (
        <Page title={'Application · Summary'}>
            <div className="tw-col-span-1 tw-flex tw-h-full">
                <div className="tw-col-span-3"></div>
                <div>
                    {errorMessage !== null && (
                        <div className="tw-col-span-3 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                    )}
                </div>
                <Paper
                    className="tw-h-auto tw-overflow-auto tw-shadow-sm"
                    style={{ flexGrow: 1 }}
                >
                    {configuration.configurationType === 'application' && (
                        <SchemaBuilder
                            key="applicationSchemaBuilder"
                            properties={AndroidApplication.schema.properties}
                            schema={AndroidApplication.schema}
                            currentState={propertiesBuilderRef.current}
                            readOnly
                        />
                    )}
                    {configuration.configurationType === 'webApp' && (
                        <SchemaBuilder
                            key="webappSchemaBuilder"
                            properties={AndroidWebAppConfiguration.schema.properties}
                            schema={AndroidWebAppConfiguration.schema}
                            currentState={propertiesBuilderRef.current}
                            readOnly
                        />
                    )}
                </Paper>
            </div>
        </Page>
    );
};

export { AndroidApplicationSummary };
