import { Avatar, Icon, LayoutCenter, LayoutColumn, LayoutRow, Loading, Padding, Paper, Truncate } from '@capasystems/ui';
import { useWindowSize } from '@capasystems/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Heading from '../heading/heading';
import { useApi } from '../hooks/useApi/useApi';

const YoutubeEmbed = ({ videoId = null, autoplay = false, loading = false, errorMessage = 'Invalid video id' }) => {
    if (videoId) {
        return (
            <iframe
                className="tw-h-full tw-w-full"
                src={`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=${autoplay ? 1 : 0}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        );
    }
    return (
        <LayoutCenter
            fill
            className="tw-bg-black tw-font-semibold tw-text-neutral-300"
        >
            {loading ? (
                <Loading
                    color="inherit"
                    className="tw-text-neutral-500"
                    thickness={2}
                />
            ) : (
                errorMessage
            )}
        </LayoutCenter>
    );
};

YoutubeEmbed.propTypes = {
    videoId: PropTypes.string,
    loading: PropTypes.bool,
    autoplay: PropTypes.bool,
    errorMessage: PropTypes.string,
};

const TutorialsPage = () => {
    const api = useApi();
    const windowSize = useWindowSize();
    const [playlistItems, setPlaylistItems] = useState([]);
    const [youtubeEmbedState, setYoutubeEmbedState] = useState({
        videoId: null,
        autoplay: false,
        loading: true,
    });

    const playVideo = (videoId) => () => {
        setYoutubeEmbedState({
            videoId: videoId,
            autoplay: true,
            loading: false,
        });
    };

    useEffect(() => {
        api.getPlaylistItems()
            .then(({ items }) => {
                setYoutubeEmbedState({
                    videoId: items[0]?.snippet.resourceId.videoId,
                    autoplay: false,
                    loading: false,
                });
                setPlaylistItems(items);
            })
            .catch(() => {
                setYoutubeEmbedState((current) => ({
                    ...current,
                    loading: false,
                    errorMessage: 'Could not load videos',
                }));
            });
    }, [api]);

    return (
        <div className="tw-px-4 tw-pt-4">
            <Heading bold>A brief look at our services</Heading>
            <div className="tw-my-8 tw-h-128 tw-overflow-auto tw-rounded-lg tw-shadow tw-shadow-slate-500">
                <YoutubeEmbed {...youtubeEmbedState} />
            </div>
            <div className="tw-mx-auto tw-grid tw-max-w-screen-2xl tw-grid-cols-1 tw-gap-4 xl:tw-grid-cols-2 xl:tw-gap-8">
                {playlistItems.map(({ snippet, contentDetails }) => {
                    const {
                        title,
                        thumbnails,
                        channelTitle,
                        description,
                        resourceId: { videoId },
                    } = snippet;
                    const isPlaying = youtubeEmbedState.videoId === videoId;
                    if (Object.keys(thumbnails).length === 0) {
                        return null; // Private video detected.
                    }
                    return (
                        <Paper className="tw-overflow-auto tw-shadow-sm">
                            <div className="tw-grid tw-grid-cols-auto-1fr">
                                <div className="tw-relative">
                                    <Padding className="tw-relative">
                                        <div
                                            className="tw-absolute"
                                            style={{
                                                width: thumbnails.default.width,
                                                height: thumbnails.default.height,
                                            }}
                                        >
                                            <LayoutCenter
                                                fill
                                                className={classNames({
                                                    'tw-rounded tw-bg-slate-200': isPlaying,
                                                })}
                                            >
                                                {isPlaying ? (
                                                    <b className="tw-rounded tw-px-4 tw-py-2 tw-text-tiny tw-text-slate-900">Selected</b>
                                                ) : (
                                                    <div className="tw-rounded-full tw-bg-slate-900 tw-p-1 tw-text-white">
                                                        <Icon
                                                            type="play"
                                                            color="inherit"
                                                        />
                                                    </div>
                                                )}
                                            </LayoutCenter>
                                        </div>
                                        <LayoutCenter
                                            className={classNames({
                                                'tw-opacity-5': isPlaying,
                                                'hover:tw-opacity-5': true,
                                            })}
                                        >
                                            <Avatar
                                                alt={title}
                                                src={thumbnails.medium.url}
                                                variant="rounded"
                                                style={{
                                                    width: thumbnails.default.width,
                                                    height: thumbnails.default.height,
                                                }}
                                                onClick={playVideo(videoId)}
                                                className={classNames({
                                                    'tw-cursor-pointer': !isPlaying,
                                                })}
                                            />
                                        </LayoutCenter>
                                    </Padding>
                                </div>
                                <div className="tw-overflow-auto">
                                    <Padding
                                        className="tw-relative tw-h-full"
                                        left={0}
                                    >
                                        <LayoutColumn
                                            alisgn="space-between"
                                            fsill
                                        >
                                            <LayoutRow
                                                verticalAlign="center"
                                                align="space-between"
                                            >
                                                <div>
                                                    <b className="tw-text-xs">{channelTitle}</b>
                                                    <Heading
                                                        subheading
                                                        bold
                                                        className="tw-leading-0 tw-mb-0"
                                                    >
                                                        {title}
                                                    </Heading>
                                                </div>
                                            </LayoutRow>
                                            <p className="tw-mt-2 tw-text-xs">
                                                <Truncate
                                                    text={description}
                                                    maxLength={Math.min(windowSize.width, 1680) / (windowSize.width < 1280 ? 5 : 10)}
                                                />
                                            </p>
                                        </LayoutColumn>
                                    </Padding>
                                </div>
                            </div>
                        </Paper>
                    );
                })}
            </div>
        </div>
    );
};

TutorialsPage.propTypes = {};

export { TutorialsPage };
