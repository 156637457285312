/* istanbul ignore file */
import classNames from 'classnames';
import React from 'react';
import { NavLink as ReactRouterDomNavLink } from 'react-router-dom';
import Button, { ButtonProps } from '../button/button';

export type NavLinkProps = ButtonProps & {
    className?: string;
    to: string | { pathname: string; search?: string };
    children: React.ReactNode;
};

/** NavLink */
export const NavLink: React.FC<NavLinkProps> = ({ className, ...otherProps }) => (
    <Button
        component={ReactRouterDomNavLink}
        className={classNames(className, 'cs-nav-link')}
        {...otherProps}
    />
);

export default NavLink;
