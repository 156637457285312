import { BUTTON } from '@capasystems/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, LayoutCentered, Tooltip, Zoom } from '@capasystems/ui';
import { windowsApplication } from '@thirdparty/constants';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthContext from '../../hooks/useAuthContext/useAuthContext';

const WindowsApplicationTypePicker = ({ showApplicationList, setType, initialSearchTerm = '', buttonText = 'New', ...props }) => {
    const navigate = useNavigate();
    const { permissions } = useAuthContext();
    const [showConfigurationTypePicker, setShowConfigurationTypePicker] = useState(false);

    const [availableOptions] = useState(() => {
        const tempAvailableOptions = [];
        if (permissions.canManageSoftwarePatching) {
            tempAvailableOptions.push({
                name: windowsApplication.type.capaPacks.name,
                iconType: windowsApplication.type.capaPacks.icon,
                type: windowsApplication.type.capaPacks.id,
                onClick: () => {
                    setType(windowsApplication.type.capaPacks.id);
                    showApplicationList(true);
                },
            });
        }
        if (permissions.canManageCustomApps) {
            tempAvailableOptions.push({
                name: windowsApplication.type.customApp.name,
                iconType: windowsApplication.type.customApp.icon,
                type: windowsApplication.type.customApp.id,
                onClick: () => {
                    navigate('../new/customapp');
                },
            });
            tempAvailableOptions.push({
                name: windowsApplication.type.script.name,
                iconType: windowsApplication.type.script.icon,
                type: windowsApplication.type.script.id,
                onClick: () => {
                    navigate('../new/scripts');
                },
            });
        }
        return tempAvailableOptions;
    });

    const openConfigurationTypePicker = () => {
        if (availableOptions.length === 1) {
            availableOptions[0].onClick();
        } else {
            setShowConfigurationTypePicker(true);
        }
    };

    const closeConfigurationTypePicker = () => {
        setShowConfigurationTypePicker(false);
    };

    return (
        <>
            <Button
                noMargin
                color={BUTTON.PRIMARY}
                variant={BUTTON.RAISED}
                {...props}
                onClick={openConfigurationTypePicker}
            >
                {buttonText}
            </Button>
            <Dialog
                open={showConfigurationTypePicker}
                onClose={closeConfigurationTypePicker}
                size="md"
            >
                <DialogTitle>
                    <span>Application type</span>
                </DialogTitle>

                <DialogContent className="tw-w-full">
                    <Zoom in>
                        <div className={`tw-grid tw-grid-cols-${availableOptions.length} tw-gap-8 tw-text-xs`}>
                            {availableOptions.map((option) => (
                                <Tooltip
                                    dark
                                    arrow
                                    content={
                                        option.type === windowsApplication.type.capaPacks.id ? (
                                            <span>
                                                Select this to choose from a collection with pre-made applications.
                                                <br />
                                                <br />
                                                If you want to install Google Chrome in a few clicks, this is the best option.
                                            </span>
                                        ) : option.type === windowsApplication.type.customApp.id ? (
                                            <span>
                                                Select this to upload and customize your own application.
                                                <br />
                                                <br />
                                                If you want to install corporate software or distribute files, this is the best option.
                                            </span>
                                        ) : (
                                            <span>
                                                Select this to create an application without content.
                                                <br />
                                                <br />
                                                If you want to delete a file or set a registry value, this is the best option.
                                            </span>
                                        )
                                    }
                                >
                                    <div
                                        className="tw-h-44 tw-w-full tw-cursor-pointer tw-rounded-lg tw-bg-slate-100 tw-shadow-sm tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow"
                                        onClick={option.onClick}
                                        key={option.name}
                                    >
                                        <LayoutCentered direction="column">
                                            <Icon
                                                type={option.iconType}
                                                className={classNames('tw-text-7xl tw-text-sky-900', {
                                                    'tw-mb-3 ': option.type !== windowsApplication.type.script.id,
                                                })}
                                            />
                                            <strong className="tw-text-base">{option.name}</strong>
                                        </LayoutCentered>
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </Zoom>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfigurationTypePicker}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { WindowsApplicationTypePicker };
