import { COMMAND_IDS } from '@apple';
import { BUTTON, SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Column, Icon, IconButton, LayoutRow, Page, Paper, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { Url } from '@capasystems/utils';
import { AppleApplicationDetails, CapaOneLink, useApi, useAppleApi, useMessageContext, useNavigate } from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ActionsDialog, QueryBuilder, TwoLineCellRenderer, useAppleVppLicensesSocket } from '../../../index';

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'VPP license name' });
const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDir';

const AppleVppLicenseList = () => {
    const appleApi = useAppleApi();
    const api = useApi();
    const [dirtyCount, setDirtyCount] = useState(0);
    const [dimensions, setDimensions] = useState({});
    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });
    const queryBuilderRef = useRef({});
    const [loading, setLoading] = useState(true);
    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: { name: '' },
    });
    const [searchTerm, setSearchTerm] = useState(Url.getString('search') || '');
    const [vppLicenseList, setVppLicenseList] = useState([]);
    const [sortingState, setSortingState] = useState({
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        pageNumber: 0,
        pageSize: 50,
    });

    const { to } = useNavigate();
    const { handleApiError } = useMessageContext();

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    useEffect(() => {
        fetchData();
    }, [searchTerm, sortingState]);

    const fetchData = useCallback(() => {
        appleApi.cancel();
        setLoading(true);
        appleApi
            .getAppleVppLicenses({
                search: searchTerm,
                sortBy: sortingState.sortBy,
                sortDirection: sortingState.sortDirection,
                pageNumber: sortingState.pageNumber,
            })
            .then((response) => {
                pagingRef.current.totalRowCount = response.count;
                if (sortingState.pageNumber === 0) {
                    setVppLicenseList(response.content);
                } else {
                    setVppLicenseList((currentList) => [...currentList, ...response]);
                }
            })

            .catch((_) => {
                // ignore
            })
            .finally(() => {
                setLoading(false);
            });
        setDirtyCount(0);
    });
    useAppleVppLicensesSocket(
        useCallback(
            ({ updateDescription, documentId, fullDocument, operationType }, { insertOperation, updateOperation, deleteOperation }) => {
                if (updateOperation) {
                    setVppLicenseList((currentVppLicenses) => {
                        return currentVppLicenses.map((vppLicense) => {
                            if (vppLicense._id === documentId) {
                                return {
                                    ...vppLicense,
                                    ...updateDescription.updatedFields,
                                };
                            }
                            return vppLicense;
                        });
                    });
                } else if (insertOperation) {
                    fetchData();
                } else if (deleteOperation) {
                    setVppLicenseList((currentList) => {
                        return currentList.filter((vppLicense) => vppLicense._id !== documentId);
                    });
                }
            },
            [fetchData, setVppLicenseList],
        ),
    );

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onScrollToBottom = () => {
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: currentPagingState.pageNumber + 1,
        }));
    };

    const onOrderByChange = ({ sortBy, sortDirection }) => {
        Url.set(SORT_BY, sortBy);
        Url.set(SORT_DIR, sortDirection);
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: 0,
            sortBy,
            sortDirection,
        }));
    };

    const forceRefresh = () => {
        setSortingState((currentPagingState) => ({ ...currentPagingState }));
    };

    const sendVppSync = () => {
        appleApi.sendCommand({ commandId: COMMAND_IDS.SYNC_VPP });
    };

    return (
        <>
            <Page title="VPP Licenses">
                <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                    <div>
                        <LayoutRow align="space-between">
                            <div className="tw-ml-auto tw-flex tw-items-center tw-gap-4">
                                <Tooltip
                                    content={
                                        <span>
                                            To assign VPP licenses to an endpoint, create an application and configure it to use VPP <br />
                                            <strong>(Applications &rarr; Create &rarr; VPP Settings)</strong>
                                        </span>
                                    }
                                >
                                    <Icon
                                        type="capaKnowledgeBase"
                                        className={'tw-text-xl tw-text-stone-400'}
                                    />
                                </Tooltip>
                                <QueryBuilder
                                    defaultConfiguration={queryBuilderConfiguration}
                                    onInit={onSubmit}
                                    onSubmit={onSubmit}
                                    className="tw-w-64"
                                    ref={queryBuilderRef}
                                />
                            </div>
                        </LayoutRow>
                    </div>
                    <Paper className="tw-h-full tw-shadow-sm">
                        <VirtualizedTable
                            entity="VPP License"
                            items={vppLicenseList}
                            onResize={setDimensions}
                            isLoading={loading}
                            onScrollToBottom={onScrollToBottom}
                            totalRowCount={pagingRef.current.totalRowCount}
                            showRowCount
                            sort={onOrderByChange}
                            sortBy={sortingState.sortBy}
                            sortDirection={sortingState.sortDirection}
                            disableHeader={vppLicenseList.length === 0 && searchTerm === ''}
                            actions={
                                <Tooltip content="Sync VPP">
                                    <IconButton
                                        onClick={sendVppSync}
                                        size={BUTTON.SMALL}
                                        color={BUTTON.PRIMARY}
                                    >
                                        <Icon type="sync" />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            <Column
                                minWidth={32}
                                maxWidth={32}
                                dataKey="id"
                                disableSort
                                label=""
                                type="icon"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <IconButton
                                            onClick={(e) => {
                                                setMenuState({
                                                    open: true,
                                                    anchorEl: e.currentTarget,
                                                    rowData,
                                                    actions: [
                                                        {
                                                            id: 'createApplication',
                                                            name: 'Create Application',
                                                        },
                                                    ],
                                                });
                                            }}
                                            noMargin
                                        >
                                            <Icon type="moreVert" />
                                        </IconButton>
                                    );
                                }}
                            />
                            <Column
                                minWidth={32}
                                maxWidth={32}
                                dataKey="iconUrl"
                                label=""
                                type="avatar"
                                cellRenderer={({ rowData }) => {
                                    if (rowData.iconUrl) {
                                        return (
                                            <Avatar
                                                src={rowData.iconUrl}
                                                alt={rowData.name}
                                                variant="rounded"
                                                className="tw-h-8 tw-w-8"
                                            />
                                        );
                                    }
                                    return (
                                        <Icon
                                            type="app"
                                            className="tw-h-8 tw-w-8"
                                        />
                                    );
                                }}
                            />
                            <Column
                                dataKey="name"
                                minWidth={160}
                                label="Name"
                                type="multiLine"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <CapaOneLink to={`apple/vpp/${rowData.adamId}/assignments`}>
                                            <TwoLineCellRenderer
                                                main={rowData.name}
                                                callToAction
                                                secondary={rowData.bundleId}
                                            />
                                        </CapaOneLink>
                                    );
                                }}
                            />
                            <Column
                                minWidth={120}
                                maxWidth={120}
                                dataKey="availableCount"
                                label="Available"
                                type="number"
                            />
                            <Column
                                minWidth={120}
                                maxWidth={120}
                                disableSort
                                dataKey="assignedCount"
                                label="Assigned / Total"
                                type="number"
                                cellRenderer={({ rowData }) => {
                                    return rowData?.assignedCount + ' / ' + rowData?.totalCount;
                                }}
                            />
                            <Column
                                width={64}
                                maxWidth={64}
                                minWidth={64}
                                flexGrow={1}
                                disableSort
                                dataKey="id"
                                label=""
                                className="tw-overflow-visible"
                                type="info"
                                cellRenderer={({ rowData }) => {
                                    return (
                                        <div className="tw-flex tw-place-content-end">
                                            <AppleApplicationDetails application={rowData} />
                                        </div>
                                    );
                                }}
                            />
                        </VirtualizedTable>
                    </Paper>
                </div>
            </Page>
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Application"
                title={menuState.rowData.name}
                pages={menuState.pages}
                actions={menuState.actions}
                onActionClick={(actionId) => {
                    // This is the action that is triggered when the user clicks the "Create Application" button
                    if (actionId.id === 'createApplication') {
                        appleApi
                            .createAppleApplication(menuState.rowData.name, menuState.rowData.iconUrl, menuState.rowData.supportedPlatforms, 'application', {
                                Identifier: menuState.rowData.bundleId,
                                Options: { PurchaseMethod: 1 },
                            })
                            .then((response) => {
                                to(`apple/application/${response.id}/membership`);
                            })
                            .catch((error) => {
                                handleApiError(error);
                            });
                    }
                }}
            />
        </>
    );
};

export { AppleVppLicenseList };
