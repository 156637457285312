import HighchartsReact from 'highcharts-react-official';
import HighchartsHighmaps from 'highcharts/highmaps';
import drilldown from 'highcharts/modules/drilldown';
import sunburst from 'highcharts/modules/sunburst';
import PropTypes from 'prop-types';
import React from 'react';
import './highmaps.scss';

import { sharedHighchartsSettings } from '@capasystems/utils';

drilldown(HighchartsHighmaps);
sunburst(HighchartsHighmaps);

HighchartsHighmaps.theme = {
    ...sharedHighchartsSettings,
    legend: {
        enabled: false,
    },
};
HighchartsHighmaps.setOptions(HighchartsHighmaps.theme);

const defaultContainerProps = {
    className: 'cs-highmaps',
};

/** Highmaps from Highcharts - sunburst and heatmap */
export const Highmaps = React.forwardRef(
    ({ options, allowChartUpdate = true, onInit, containerProps = {}, immutable = false, updateArgs = [true, true, true] }, chartRef) => (
        <HighchartsReact
            highcharts={HighchartsHighmaps}
            constructorType="mapChart"
            options={options}
            allowChartUpdate={allowChartUpdate}
            immutable={immutable}
            callback={onInit}
            ref={chartRef}
            updateArgs={updateArgs}
            containerProps={{
                ...defaultContainerProps,
                ...containerProps,
            }}
        />
    )
);

Highmaps.propTypes = {
    /** Highcharts chart configuration object. Please refer to the Highcharts API documentation. */
    options: PropTypes.shape({}).isRequired,
    /** Control chart updates */
    allowChartUpdate: PropTypes.bool,
    /** Reinitialises the chart on prop update (as oppose to chart.update()) - useful in some cases but slower than a regular update. */
    immutable: PropTypes.bool,
    /** The props object passed to the chart container. Useful for adding styles, id or class. */
    containerProps: PropTypes.shape({}),
    /** Callback is called when the chart has been initialized. The chart instance is passed to onInit. Alternatively use ref. */
    onInit: PropTypes.func,
    /**
     * Array of the update function optional arguments. Parameters should be defined in the same order like in native Highcharts function: [redraw, oneToOne, animation].
     * Here is a more specific description of the parameters -
     * https://api.highcharts.com/class-reference/Highcharts.Chart#update
     * */
    updateArgs: PropTypes.arrayOf(PropTypes.bool),
};

export default Highmaps;
