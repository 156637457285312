//Documentation
//https://developer.apple.com/documentation/devicemanagement/mail

export const AppleMailConfiguration = { //Specify com.apple.mail.managed as the payload type
    schema: {
        title: 'Mail',
        type: 'object',
        schemaId: 'appleMailConfiguration',
        properties: {
            allowMailDrop: {
                title: 'Allow Mail Drop',
                description: 'Whether Mail Drop is enabled.',
                type: 'boolean',
                required: true,
                default: false,
            },
            disableMailRecentsSyncing: {
                title: 'Mail Recents Syncing',
                description: 'Whether Mail Recents Syncing is disabled',
                type: 'boolean',
                required: true,
            },
            emailAccountDescription: {
                title: 'Email Account Description',
                description: 'A user-visible description of the email account, shown in the Mail and Settings applications.',
                type: 'string',
                multiline: true,
            },
            emailAccountName: {
                title: 'Email Account Name',
                description: 'The full user name for the account. This name is shown in sent messages.',
                type: 'string',
            },
            emailAccountType: {
                title: 'Email Account Type',
                description: 'Defines the protocol to be used for the account.',
                type: 'string',
                required: true,
                options: [
                    {
                        id: 'EMAIL_TYPE_IMAP',
                        name: 'Internet Message Access Protocol (IMAP)',
                    },
                    {
                        id: 'EMAIL_TYPE_POP',
                        name: 'Post Office Protocol (POP)',
                    },
                ],
            },
            emailAddress: {
                title: 'Email Address',
                description: 'The full email address for the account.',
                help: 'If this string isn´t present in the payload, the device prompts for this string during interactive profile installation in Settings or System Preferences.',
                type: 'string',
            },
            incomingMailServerAuthentication: {
                title: 'Incoming Mail Server Authentication',
                description: 'The authentication scheme for incoming mail.',
                type: 'string',
                required: true,
                options: [
                    {
                        id: 'EMAIL_AUTH_NONE',
                        name: 'None',
                    },
                    {
                        id: 'EMAIL_AUTH_PASSWORD',
                        name: 'Password',
                    },
                    {
                        id: 'EMAIL_AUTTH_CRAMMD5',
                        name: 'CRAMMD5',
                    },
                    {
                        id: 'EMAIL_AUTH_NTLM',
                        name: 'NTLM',
                    },
                    {
                        id: 'EMAIL_AUTH_HTTPMD5',
                        name: 'HTTPMD5',
                    },
                ],
            },
            incomingMailServerHostName: {
                title: 'Incoming Server Host Name',
                description: '',
                type:'string',
                required: true,
            },
            incomingMailServerIMAPPathPrefix: {
                title: 'Incoming Server IMAP Path',
                description: 'The path prefix for the IMAP mail server',
                type: 'string',
            },
            incomingMailServerPortNumber: {
                title: 'Incoming Server Port Number',
                description: 'The incoming mail server port number. If no port number is specified, the default port for a given protocol is used.',
                type: 'number',
            },
            incomingMailServerUsername: {
                title: 'Incoming Server User Name',
                description: ' If the user name isn´t present in the payload and the account is set up to require authentication for incoming email, the device prompts for this string during interactive profile installation in Settings or System Preferences.',
                help: 'The user name for the email account, usually the same as the email address.',
                type: 'string',
            },
            incomingMailServerUseSSL: {
                title: 'Incoming Server Use SSL',
                description: 'Whether enables SSL for authentication on the incoming mail server.',
                type: 'boolean',
                default: false,
            },
            incomingPassword: {
                title: 'Incoming Password',
                description: 'The password for the incoming mail server.',
                help: 'This password is used only with encrypted profiles.',
                type: 'string',
            },
            outgoingMailServerAuthentication: {
                title: 'Outgoing Mail Server Authentication',
                description: 'The authentication scheme for outgoing mail',
                type: 'string',
                required: true,
                options: [
                    {
                        id: 'EMAIL_AUTH_NONE',
                        name: 'None',
                    },
                    {
                        id: 'EMAIL_AUTH_PASSWORD',
                        name: 'Password',
                    },
                    {
                        id: 'EMAIL_AUTTH_CRAMMD5',
                        name: 'CRAMMD5',
                    },
                    {
                        id: 'EMAIL_AUTH_NTLM',
                        name: 'NTLM',
                    },
                    {
                        id: 'EMAIL_AUTH_HTTPMD5',
                        name: 'HTTPMD5',
                    },
                ],          
            },
            outgoingMailServerHostName: {
                title: 'Outgoing Mail Server Host Name',
                description: '',
                type: 'string',
                required: true,
            },
            OutgoingMailServerPortNumber: {
                title: 'Outgoing Mail Server Port Number',
                description: '',
                help: 'If no port number is specified, ports 25, 587, and 465 are used, in that order',
                type: 'number',
            },
            outgoingMailServerUsername: {
                title: 'Outgoing Mail Server User Name',
                description: 'If the user name isn´t present in the payload and the account is set up to require authentication for outgoing email, the device prompts for this string during interactive profile installation in Settings or System Preferences.',
                help: 'The user name for the email account, usually the same as the email address.',
                type: 'string',
            },
            outgoingMailServerUseSSL: {
                title: 'Outgoing Mail Server Use SSL',
                description: 'Enables SSL authentication on the outgoing mail server',
                type: 'boolean',
                required: true,
                default: false,
            },
            outgoingPassword: {
                title: 'Outgoing Password',
                description: 'The password for the outgoing mail server.',
                help: 'This password is used only with encrypted profiles.',
                type: 'string',
            },
            outgoingPasswordSameAsIncomingPassword: {
                title: 'Outgoing Password Same As Incoming Password',
                description: 'Enables so the user is prompted only once for the password, which is used for both outgoing and incoming mail.',
                type: 'boolean',
                required: true,
                default: false,
            },
            preventAppSheet: {
                title: 'Prevent App Sheet',
                description: 'Enables preventing this account from sending emails in any app other than the Apple Mail app.',
                type: 'boolean',
                required: true,
                default: false,
            },
            preventMove: {
                title: 'Prevent Move',
                description: 'Enables preventing messages from being moved out of this email account and into another account. It also prevents forwarding or replying from an account other than one the message was sent to.',
                type: 'boolean',
                required: true, 
                default: false,
            },
            sMIMEEnabled: {
                title: 'S/MIME Enabled',
                description: 'Enables S/MIME allows for encryption and digital signature of e-mails, making it more difficult for unauthorized parties to read or modify the content of e-mails in transit.',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMEEnableEncryptionPerMessageSwitch: {
                title: 'S/MIME Enable Encryption Per Message Switch',
                description: 'Enables the display of the per-message encryption switch in the Mail Compose UI.',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMEEncryptByDefault: {
                title: 'S/MIME Encrypt By Default',
                description: 'Enables S/MIME encryption by default',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMEEncryptByDefaultUserOverrideable: {
                title: 'S/MIME Encrypt By Default User Overrideable',
                description: 'Enables the user to turn encryption by default on/off, and encryption is on',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMEEncryptionCertificateUUID: {
                title: 'SMIME Encryption Certificate UUID',
                description: 'Enables the user to select the S/MIME encryption identity, and encryption is turned on.',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMEEncryptionEnabled: {
                title: 'S/MIME Encryption Enabled',
                description: 'Enables S/MIME encryption for this account',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMESigningCertificateUUID: {
                title: 'S/MIME Signing Certificate UUID',
                description: 'The payload UUID of the identity certificate used to sign messages sent from this account',
                type: 'string',
            },
            sMIMESigningCertificateUUIDUserOverrideable: {
                title: 'S/MIME Signing Certificate UUID User Overrideable',
                description: 'Enables the user to select the signing identity',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMESigningEnabled: {
                title: 'S/MIME Signing Enabled',
                description: 'Enables S/MIME signing for this account.',
                type: 'boolean',
                required: true,
                default: false,
            },
            sMIMESigningUserOverrideable: {
                title: 'S/MIME Signing User Overrideable',
                description: 'Enables the user to turn S/MIME signing on or off in Settings',
                type: 'boolean',
                required: true,
                default: false,
            },
            vPNUUID: {
                title: 'VPNUUID',
                description: 'The VPNUUID of the per-app VPN the account uses for network communication.',
                type: 'string',
            },
        },
    },
};
