import { LTE } from '@apple/constants';
import { Column, Ellipsis, EmptyState, Icon, LayoutColumn, LayoutRow, LoadingLinear, Tab, Tabs, VirtualizedTable } from '@capasystems/ui';
import { Url, isDefined, isUndefined } from '@capasystems/utils';
import { NONE } from '@thirdparty/constants';
import { formatBytes } from '@thirdparty/utils';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { WidgetPaper } from '../../../../index';

const AppleEndpointHardwareTab = ({ appleData: { data, security, modelName, activationLockBypassCode } }) => {
    const [activeTab, setActiveTab] = useState(Url.getString('hardwareTab', 'general'));
    const capitalize = (value) => {
        return value?.charAt(0).toUpperCase() + value?.slice(1) || <b className="tw-italic tw-text-gray-300">{NONE}</b>;
    };

    let batteryEnable;
    if (data.BatteryLevel <= 0 || data.BatteryLevel === undefined) {
        batteryEnable = { name: 'Battery level', value: <b className="tw-italic tw-text-gray-300">{NONE}</b> };
    } else {
        batteryEnable = {
            name: 'Battery level',
            displayType: 'percentage',
            value: (data.BatteryLevel * 100).toFixed(0),
        };
    }
    const tableItems = useMemo(() => {
        if (isUndefined(data)) {
            return [];
        }
        if (activeTab === 'general') {
            if (data.ModelName.includes('Mac')) {
                return [
                    {
                        name: 'Model',
                        value: modelName,
                    },
                    {
                        name: 'Storage', // "Capacity",
                        displayType: 'bytes',
                        used: (data.DeviceCapacity - data.AvailableDeviceCapacity) * 1024 * 1024 * 1024,
                        available: data.DeviceCapacity * 1073741824,
                    },
                    batteryEnable,
                    {
                        name: 'Name of the hardware',
                        value: data.DeviceName,
                    },
                    {
                        name: 'Wifi Mac',
                        value: data.WiFiMAC,
                    },
                    {
                        name: 'Has a Battery',
                        value: data.HasBattery,
                        displayType: 'bool',
                    },
                ];
            } else {
                return [
                    {
                        name: 'Model',
                        value: modelName,
                    },
                    {
                        name: 'MEID',
                        value: data.MEID || data.ServiceSubscriptions?.[0]?.MEID,
                    },
                    {
                        name: 'IMEI',
                        value: data.IMEI || data.ServiceSubscriptions?.[0]?.IMEI,
                    },
                    {
                        name: 'UDID',
                        value: data.UDID,
                    },
                    {
                        name: 'Activation Lock Bypass Code',
                        value: activationLockBypassCode,
                    },
                    {
                        name: 'WiFi Mac',
                        value: data.WiFiMAC,
                    },
                    {
                        name: 'Storage', // "Capacity",
                        displayType: 'bytes',
                        used: (data.DeviceCapacity - data.AvailableDeviceCapacity) * 1024 * 1024 * 1024,
                        available: data.DeviceCapacity * 1073741824,
                    },
                    batteryEnable,
                    {
                        name: 'Name of the hardware',
                        value: data.DeviceName,
                    },
                    {
                        name: 'Modem Firmware Version',
                        value: data.ModemFirmwareVersion,
                    },
                    {
                        name: 'Cellular Technology',
                        value: LTE[data.CellularTechnology] || data.CellularTechnology,
                    },
                ];
            }
        }
        if (activeTab === 'system') {
            if (isUndefined(data)) {
                return [];
            }
            if (data.ModelName.includes('Mac')) {
                return [
                    {
                        name: 'Awaiting Configuration',
                        value: data?.AwaitingConfiguration,
                        displayType: 'bool',
                    },
                    {
                        name: 'Apple build number',
                        value: data?.BuildVersion,
                    },
                    {
                        name: 'Is Activation Lock Supported',
                        value: data?.IsActivationLockSupported,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is Apple Silicon',
                        value: data?.IsAppleSilicon,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is Supervised',
                        value: data?.IsSupervised,
                        displayType: 'bool',
                    },
                    {
                        name: 'OS Version',
                        value: data?.OSVersion,
                    },
                    {
                        name: 'PIN Required For Device Lock',
                        value: data?.PINRequiredForDeviceLock,
                        displayType: 'bool',
                    },
                    {
                        name: 'PIN Required For Erase Device',
                        value: data?.PINRequiredForEraseDevice,
                        displayType: 'bool',
                    },
                    {
                        name: 'Software Update Device ID',
                        value: data?.SoftwareUpdateDeviceID,
                    },
                    {
                        name: 'Supplemental Build Version',
                        value: data?.SupplementalBuildVersion,
                    },
                    {
                        name: 'Supports LOM Device',
                        value: data?.SupportsLOMDevice,
                        displayType: 'bool',
                    },
                    {
                        name: 'Supports iOS App Installs',
                        value: data?.SupportsiOSAppInstalls,
                        displayType: 'bool',
                    },
                    {
                        name: 'System Integrity Protection Enabled',
                        value: data?.SystemIntegrityProtectionEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'FDE Enabled',
                        value: security?.FDE_Enabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Enrolled Via DEP',
                        value: security?.ManagementStatus?.EnrolledViaDEP,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is Activation Lock Manageable',
                        value: security?.ManagementStatus?.IsActivationLockManageable,
                        displayType: 'bool',
                    },
                    {
                        name: 'User Approved Enrollment',
                        value: security?.ManagementStatus?.UserApprovedEnrollment,
                        displayType: 'bool',
                    },
                    {
                        name: 'Remote Desktop Enabled',
                        value: security?.RemoteDesktopEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'External Boot Level',
                        value: capitalize(security?.SecureBoot?.ExternalBootLevel),
                    },
                    {
                        name: 'Secure Boot Level',
                        value: capitalize(security?.SecureBoot?.SecureBootLevel),
                    },
                    {
                        name: 'Windows Boot Level',
                        value: capitalize(security?.SecureBoot?.WindowsBootLevel),
                    },
                    {
                        name: 'System Integrity Protection Enabled',
                        value: security?.SystemIntegrityProtectionEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Authenticated Root Volume Enabled',
                        value: security?.AuthenticatedRootVolumeEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Bootstrap Token Allowed For Authentication',
                        value: capitalize(security?.BootstrapTokenAllowedForAuthentication),
                    },
                    {
                        name: 'Bootstrap Token Required For Kernel Extension Approval',
                        value: security?.BootstrapTokenRequiredForKernelExtensionApproval,
                        displayType: 'bool',
                    },
                    {
                        name: 'Bootstrap Token Required For Software Update',
                        value: security?.BootstrapTokenRequiredForSoftwareUpdate,
                        displayType: 'bool',
                    },
                ];
            } else {
                return [
                    {
                        name: 'Awaiting Configuration',
                        value: data?.AwaitingConfiguration,
                        displayType: 'bool',
                    },
                    {
                        name: 'App Analytics Enable',
                        value: data?.AppAnalyticsEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'iTunes Store Account Is Active',
                        value: data?.iTunesStoreAccountIsActive,
                        displayType: 'bool',
                    },
                    {
                        name: 'Build Version',
                        value: data?.BuildVersion,
                    },
                    {
                        name: 'Diagnostic Submission Enabled',
                        value: data?.DiagnosticSubmissionEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is Cloud Backup Enabled',
                        value: data?.IsCloudBackupEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is Device Locator Service Enabled',
                        value: data?.IsDeviceLocatorServiceEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is "Do Not Disturb" In Effect',
                        value: data?.IsDoNotDisturbInEffect,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is MDM LostMode Enabled',
                        value: data?.IsMDMLostModeEnabled,
                        displayType: 'bool',
                    },
                    {
                        name: 'Is Multi User',
                        value: data?.IsMultiUser,
                        displayType: 'bool',
                    },
                    {
                        name: 'Time Zone',
                        value: data?.TimeZone,
                    },
                    {
                        name: 'Modem Firmware Version',
                        value: data?.ModemFirmwareVersion,
                    },
                    {
                        name: 'OS Version',
                        value: data?.OSVersion,
                    },
                    {
                        name: 'Hardware Encryption Caps',
                        value: security?.HardwareEncryptionCaps,
                    },
                    {
                        name: 'Is User Enrollment',
                        value: security?.ManagementStatus?.IsUserEnrollment,
                        displayType: 'bool',
                    },
                    {
                        name: 'Passcode Compliant',
                        value: security?.PasscodeCompliant,
                        displayType: 'bool',
                    },
                    {
                        name: 'Passcode Compliant With Profiles',
                        value: security?.PasscodeCompliantWithProfiles,
                        displayType: 'bool',
                    },
                    {
                        name: 'Passcode Lock Grace Period',
                        value: security?.PasscodeLockGracePeriod,
                    },
                    {
                        name: 'Passcode Lock Grace Period Enforced',
                        value: security?.PasscodeLockGracePeriodEnforced,
                    },
                    {
                        name: 'Passcode Present',
                        value: security?.PasscodePresent,
                        displayType: 'bool',
                    },
                ];
            }
        }
        if (activeTab === 'cellular') {
            let cellularRows = [];
            const checkIfCellularRows = (value, name, index, displayType) => {
                if (value !== undefined) {
                    cellularRows[index].push({ value, name, displayType });
                }
            };
            data.ServiceSubscriptions?.forEach((ServiceSubscriptions, index) => {
                cellularRows.push([]);
                checkIfCellularRows(ServiceSubscriptions.CarrierSettingsVersion, 'Carrier settings version', index);
                checkIfCellularRows(ServiceSubscriptions.CurrentCarrierNetwork, 'Carrier', index);
                checkIfCellularRows(ServiceSubscriptions.CurrentMCC, 'MCC', index);
                checkIfCellularRows(ServiceSubscriptions.CurrentMNC, 'MNC', index);
                checkIfCellularRows(ServiceSubscriptions.IMEI, 'IMEI', index);
                checkIfCellularRows(ServiceSubscriptions.ICCID, 'Simcard ID', index);
                checkIfCellularRows(ServiceSubscriptions.IsRoaming, 'Is Roaming', index, 'bool');
                checkIfCellularRows(ServiceSubscriptions.PhoneNumber, 'Phone number', index);
                checkIfCellularRows(ServiceSubscriptions.Label, 'Label', index);
                checkIfCellularRows(ServiceSubscriptions.LabelID, 'Label ID', index);
                checkIfCellularRows(ServiceSubscriptions.IsDataPreferred, 'Data Preferred', index, 'bool');
                checkIfCellularRows(ServiceSubscriptions.IsVoicePreferred, 'Voice Preferred', index, 'bool');
                checkIfCellularRows(ServiceSubscriptions.SubscriberCarrierNetwork, 'Subscriber Carrier Network', index);
            });

            return cellularRows;
        }

        if (activeTab === 'firewall') {
            return [
                {
                    name: 'Firewall Enabled',
                    displayType: 'bool',
                    value: security?.FirewallSettings?.FirewallEnabled,
                },
                {
                    name: 'Stealth Mode',
                    displayType: 'bool',
                    value: security?.FirewallSettings?.StealthMode,
                },
                {
                    name: 'Allow Signed',
                    displayType: 'bool',
                    value: security?.FirewallSettings?.AllowSigned,
                },
                {
                    name: 'Allow Signed App',
                    displayType: 'bool',
                    value: security?.FirewallSettings?.AllowSignedApp,
                },
                {
                    name: 'Block All Incoming',
                    displayType: 'bool',
                    value: security?.FirewallSettings?.BlockAllIncoming,
                },
                {
                    name: 'Logging Enabled',
                    displayType: 'bool',
                    value: security?.FirewallSettings?.LoggingEnabled,
                },
                {
                    name: 'Logging Option',
                    value: capitalize(security?.FirewallSettings?.LoggingOption),
                },
            ];
        }

        const rows = [];
        if (data.ModelName.includes('Mac')) {
            rows.push({
                name: 'Ethernet MAC',
                value: data.EthernetMAC,
            });

            rows.push({
                name: 'WiFi MAC',
                value: data.WiFiMAC,
            });
            rows.push({
                name: 'Bluetooth MAC',
                value: data.BluetoothMAC,
            });
        } else {
            rows.push({
                name: 'Is Network Tethered',
                displayType: 'bool',
                value: data?.IsNetworkTethered,
            });
            rows.push({
                name: 'Is Roaming',
                displayType: 'bool',
                value: data?.IsRoaming,
            });
            rows.push({
                name: 'Is Data Roaming Enabled',
                displayType: 'bool',
                value: data?.DataRoamingEnabled,
            });
            rows.push({
                name: 'Personal Hotspot Enabled',
                displayType: 'bool',
                value: data?.PersonalHotspotEnabled,
            });
            rows.push({
                name: 'Bluetooth MAC',
                value: data?.BluetoothMAC,
            });
            rows.push({
                name: 'WiFi MAC',
                value: data?.WiFiMAC,
            });
        }
        return rows;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    return (
        <div className="tw-grid tw-h-full tw-grid-cols-auto-1fr tw-gap-4 tw-pb-4 tw-pt-0">
            <div className="tw-w-48">
                <Tabs
                    value={activeTab}
                    pills
                    onChange={(_, tab) => {
                        setActiveTab(tab);
                        Url.set('hardwareTab', tab);
                    }}
                    orientation="vertical"
                >
                    <Tab
                        label="General"
                        value="general"
                        className="tw-items-start tw-text-sm tw-font-semibold"
                        disableRipple
                    />
                    <Tab
                        label="System"
                        value="system"
                        className="tw-items-start tw-text-sm tw-font-semibold"
                        disableRipple
                    />
                    <Tab
                        label="Network"
                        value="network"
                        className="tw-items-start tw-text-sm tw-font-semibold"
                        disableRipple
                    />
                    {data.ServiceSubscriptions === undefined ? null : (
                        <Tab
                            label="Cellular"
                            value="cellular"
                            className="tw-items-start tw-text-sm tw-font-semibold"
                            disableRipple
                        />
                    )}
                    {!data.ModelName.includes('Mac') ? null : (
                        <Tab
                            label="Firewall"
                            value="firewall"
                            className="tw-items-start tw-text-sm tw-font-semibold"
                            disableRipple
                        />
                    )}
                </Tabs>
            </div>
            {activeTab === 'cellular' ? (
                <div className="tw-grid tw-w-full tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-2">
                    {tableItems.map((simCard, index) => {
                        return (
                            <HardwareInvTable
                                items={simCard}
                                title={'Sim slot ' + (index + 1)}
                                data={data}
                                activeTab={activeTab}
                            />
                        );
                    })}
                </div>
            ) : (
                <HardwareInvTable
                    items={tableItems}
                    data={data}
                    activeTab={activeTab}
                />
            )}
        </div>
    );
};

const HardwareInvTable = ({ items, title, data, activeTab }) => {
    return (
        <WidgetPaper
            title={title}
            headerless={isUndefined(title)}
            className="tw-h-full"
        >
            <VirtualizedTable
                disableHeader
                items={items}
                className="tw-w-full tw-text-xs"
                noRowsRenderer={() => (
                    <EmptyState
                        iconType="lightbulbOutlined"
                        title={isUndefined(data) ? 'Hardware info reporting is disabled' : 'No info available'}
                        description={`Endpoint did not report ${activeTab} information`}
                    />
                )}
            >
                <Column
                    dataKey="name"
                    label={'Name'}
                    minWidth={240}
                    maxWidth={240}
                    type="string"
                    cellRenderer={({ cellData }) => cellData}
                />
                <Column
                    dataKey="value"
                    label=""
                    minWidth={240}
                    type="string"
                    cellRenderer={valueCellRenderer}
                />
            </VirtualizedTable>
        </WidgetPaper>
    );
};

const valueCellRenderer = ({ rowData }) => {
    if (rowData.displayType === 'bytes') {
        if (isDefined(rowData.used)) {
            const percentage = (rowData.used / rowData.available) * 100;
            return (
                <LayoutColumn className="tw-mt-1 tw-w-64">
                    <LoadingLinear
                        variant="determinate"
                        value={percentage}
                        classes={{
                            root: 'tw-h-2 tw-rounded-full',
                        }}
                    />
                    <LayoutRow
                        align="space-between"
                        className="tw-mt-1 tw-text-tiny"
                    >
                        <b>{formatBytes({ value: rowData.used, decimalPoints: 1, asText: true })}</b>
                        <b>{formatBytes({ value: rowData.available, asText: true })}</b>
                    </LayoutRow>
                </LayoutColumn>
            );
        }
        return <b>{formatBytes({ value: rowData.available, asText: true })}</b>;
    }
    if (rowData.displayType === 'percentage') {
        if (isDefined(rowData.value)) {
            return (
                <LayoutColumn className="tw-mt-1 tw-w-64">
                    <LoadingLinear
                        variant="determinate"
                        value={rowData.value}
                        classes={{
                            root: classNames({
                                'tw-h-2 tw-rounded-full': true,
                                'tw-bg-red-100': rowData.value < 20,
                                'tw-bg-emerald-100': rowData.value >= 20,
                            }),
                            bar: classNames({
                                'tw-bg-red-600': rowData.value < 20,
                                'tw-bg-emerald-400': rowData.value >= 20,
                            }),
                        }}
                    />
                    <LayoutRow
                        align="space-between"
                        className="tw-mt-1 tw-text-tiny"
                    >
                        <b>{rowData.value} %</b>
                    </LayoutRow>
                </LayoutColumn>
            );
        }
        return <b className="tw-italic tw-text-gray-300">{NONE}</b>;
    }
    if (rowData.displayType === 'bool') {
        if (isDefined(rowData.value)) {
            return (
                <LayoutColumn className="tw-mt-1 tw-w-64">
                    <Icon
                        type={rowData.value === false ? 'dashOutlined' : 'checkmarkOutlined'}
                        size="medium"
                    />
                </LayoutColumn>
            );
        }
        return <span className="tw-italic tw-text-gray-300">{NONE}</span>;
    }

    if (!isDefined(rowData.value)) {
        return <span className="tw-italic tw-text-gray-300">{NONE}</span>;
    }

    return <Ellipsis>{rowData.value}</Ellipsis>;
};

export { AppleEndpointHardwareTab };
