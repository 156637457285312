import { isBeta } from '@capasystems/constants';
import { api, getUniqueId, isString, now } from '@capasystems/utils';
import {
    TAndroidConfiguration,
    TAndroidConfigurationWithId,
    TAndroidDevice,
    TAndroidDeviceWithId,
    TAndroidEnrollmentConfiguration,
    TAndroidEnrollmentConfigurationWithId,
} from '@db/party';
import { ApplicationInstallTypeEnum, coreAndroidManagement } from '@thirdparty/constants';

// const CAPAONE_API_ROOT = isBeta ? "https://apibeta.capaone.com" : "https://api.capaone.com";

/* const CAPAONE_OVERRIDES = {
    withCredentials: false,
    baseURL: CAPAONE_API_ROOT,
    transformRequest: (data, headers) => {
        // Handle CORS.
        delete headers['cache-control'];
        return data;
    }
}; */

const expirationTimestamps: any = {};

export const streamlineApplicationDocument = (app: TAndroidConfigurationWithId) => {
    if (app.configurationType === coreAndroidManagement.configurationType.application) {
        const installTypeId = app.data.applications[0]?.installType || 'INSTALL_TYPE_UNSPECIFIED';
        return {
            ...app,
            installTypeName: ApplicationInstallTypeEnum[installTypeId]?.split('.')[0],
            installTypeId,
        };
    }
    return {
        ...app,
        installTypeName: ApplicationInstallTypeEnum[app.installType || 'INSTALL_TYPE_UNSPECIFIED']?.split('.')[0],
        installTypeId: app.installType,
    };
};

class AndroidConnection {
    baseUrl: string;
    baseEnterpriseUrl: string;
    abortController: AbortController;
    connectionID: string;

    constructor(enterpriseId: string) {
        this.baseUrl = `${api.defaults.baseURL}/android`;
        this.baseEnterpriseUrl = `${this.baseUrl}/enterprise/${enterpriseId}`;
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('AndroidConnectionID');
    }

    /** GET */
    getDevices = (params: any) => this.get<{ content: TAndroidDeviceWithId[]; count: number }>(`endpoint`, params); // In use!

    getDevice = (id: string) => this.get<TAndroidDeviceWithId>(`endpoint/${id}`); // In use!

    getPolicies = () => this.get(`policy`); // In use!

    getPolicy = (id: string) => this.get(`policy/${id}`); // In use!

    getAndroidConfigurations = () => this.get(`configuration`); // In use!

    getEnrollmentConfigurations = () => this.get<TAndroidEnrollmentConfigurationWithId[]>(`enrollmentConfiguration`); // In use!

    getEnrollmentConfiguration = (id: string) => this.get<TAndroidEnrollmentConfigurationWithId>(`enrollmentConfiguration/${id}`); // In use!

    getAndroidConfiguration = (id: string) => this.get<TAndroidConfigurationWithId>(`configuration/${id}`); // In use!

    getEnterprises = () =>
        this.get(
            'enterprise',
            {},
            {
                baseURL: this.baseUrl,
            }
        ); // In use!

    getEnterpriseDetails = () => this.get(''); // In use!

    getApplications = () =>
        this.get(`application`).then((applications) => {
            return applications.map(streamlineApplicationDocument);
        }); // In use!

    getSingleApplication = (id: string) =>
        this.get<TAndroidConfigurationWithId>(`application/${id}`).then((app) => {
            return streamlineApplicationDocument(app);
        }); // In use!

    getTemplate = (orgId: number, osType: string, templateType: string, params: any) => this.get(`${osType}/template/${templateType}`, params); // In use!

    getGooglePlayStoreApplicationInfo = (packageName = '') => this.get(`playstore/application/${packageName}`, {}); // packageName like "com.evernote" or "com.google.android.youtube". Also returns managed fields (maybe put on seperate tab on application conf. edit page)

    getGooglePlayStoreWebToken = () =>
        this.post('webToken', {
            parentFrameUrl: window.location.origin,
            enabledFeatures: ['MANAGED_CONFIGURATIONS'],
        }); // See https://developers.google.com/android/work/play/emm-api/managed-play-iframe#web-apps & https://developers.google.com/android/management/reference/rest/v1/enterprises.webTokens#iframefeature

    getGooglePlayStoreSearchWebToken = () =>
        this.post('webToken', {
            parentFrameUrl: window.location.origin,
            enabledFeatures: [
                'PLAY_SEARCH',
                'PRIVATE_APPS', // Adds suppport for selecting "Private apps" in Google Play Store.
            ],
        }); // See https://developers.google.com/android/management/apps#step_3_handle_iframe_events

    getZeroTouchWebToken = () =>
        this.post('webToken', {
            parentFrameUrl: window.location.origin,
            enabledFeatures: ['ZERO_TOUCH_CUSTOMER_MANAGEMENT'],
        }); // See https://developers.google.com/android/management/zero-touch-iframe#add_the_iframe_to_your_console

    get = <T = any>(endpoint: string, params: any = {}, overrides: any = {}) => {
        const requestedTimestamp = now();
        return new Promise<T>((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                cache: false,
                withCredentials: true,
                baseURL: this.baseEnterpriseUrl,
                ...overrides,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    /** DELETE */
    deletePolicy = (id: string) => this.delete(`policy/${id}`); // In use!

    deleteApplication = (id: string, force: boolean) => this.delete(`application/${id}?force=${force}`); // In use!

    deleteAndroidConfiguration = (id: string, force: boolean) => this.delete(`configuration/${id}?force=${force}`); // In use!

    removeEndpointFromApplications = (endpointId: string, applicationRefIds: string[]) =>
        this.delete(`endpoint/${endpointId}/application`, {
            applicationRefIds,
        });

    removeEndpointFromConfigurations = (endpointId: string, configurationRefIds: string[]) =>
        this.delete(`endpoint/${endpointId}/configuration`, {
            configurationRefIds,
        });

    removeEndpointsFromApplication = (applicationId: string, endpointRefIds: string[]) =>
        this.delete(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    removeEndpointsFromConfiguration = (configurationId: string, endpointRefIds: string[]) =>
        this.delete(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    deleteDevice = (endpointId: string) => this.delete(`endpoint/${endpointId}`);

    deleteEnrollmentConfiguration = (id: string) => this.delete(`enrollmentConfiguration/${id}`); // In use!

    delete = <T = any>(endpoint: string, data: any = {}, overrides: any = {}) =>
        api.delete<T>(endpoint, {
            baseURL: this.baseEnterpriseUrl,
            ...overrides,
            data,
        });

    updateAndroidApplication = (id: string, applicationObject: Partial<TAndroidConfiguration>) => this.update(`application/${id}`, applicationObject); // In use!

    updateAndroidApplications = (updatedApplications: Partial<TAndroidConfigurationWithId>[] = []) =>
        this.update(
            'application',
            updatedApplications.map(({ id, priority }) => ({ id, priority }))
        );

    updateAndroidConfiguration = (configurationId: string, configurationObject: Partial<TAndroidConfiguration>) =>
        this.update(`configuration/${configurationId}`, configurationObject); // In use!

    updateAndroidConfigurations = (updatedConfigurations = []) =>
        this.update(
            'configuration',
            updatedConfigurations.map(({ id, priority }) => ({ id, priority }))
        );

    updateDevice = (id: string, data: Partial<TAndroidDevice> = {}) => this.update(`endpoint/${id}`, data); // In use!

    updateAndroidEnrollmentConfiguration = (id: string, params: Partial<TAndroidEnrollmentConfiguration> = {}) =>
        this.update(`enrollmentConfiguration/${id}`, params);

    syncDevice = (id: string) => this.update(`endpoint/${id}/sync`);

    syncDeviceList = () => this.update('endpoint/sync');

    update = <T>(endpoint: string, data?: any) => {
        return new Promise<T>((resolve, reject) => {
            api.put(endpoint, data, {
                baseURL: this.baseEnterpriseUrl,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** POST */
    createEnrollmentToken = (data: any = {}) => this.post(`enrollmentToken`, data);

    createSignupUrl = () =>
        this.post(
            `enterprise/generateSignupUrl?hostname=${isBeta ? 'dashbeta.capaone.com' : 'dash.capaone.com'}`,
            {},
            {
                baseURL: this.baseUrl,
            }
        );

    createAndroidConfiguration = (name = '', configurationObject = {}) =>
        this.post(`configuration`, {
            name,
            configurationType: coreAndroidManagement.configurationType.android,
            data: configurationObject,
        }); // In use!

    createAndroidApplication = (applicationObject: Partial<TAndroidConfiguration>) => this.post(`application`, applicationObject); // In use!

    addEndpointToApplications = (endpointId: string, applicationRefIds: string[]) =>
        this.post(`endpoint/${endpointId}/application`, {
            applicationRefIds,
        });

    addEndpointToConfigurations = (endpointId: string, configurationRefIds: string[]) =>
        this.post(`endpoint/${endpointId}/configuration`, {
            configurationRefIds,
        });

    addEndpointsToApplication = (applicationId: string, endpointRefIds: string[]) =>
        this.post(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    addEndpointsToConfiguration = (configurationId: string, endpointRefIds: string[]) =>
        this.post(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    createEnrollmentConfigurations = (configurationObject: TAndroidEnrollmentConfiguration) => this.post(`enrollmentConfiguration`, configurationObject); // In use!

    // https://developers.google.com/android/management/reference/rest/v1/enterprises.devices/issueCommand#Command
    sendLockDeviceCommand = (endpointId: string) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'LOCK',
        });

    sendRebootDeviceCommand = (endpointId: string) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'REBOOT',
        });

    sendClearApplicationDataCommand = (endpointId: string, packageNames: string[] = []) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'CLEAR_APP_DATA',
            clearAppsDataParams: {
                packageNames,
            },
        });

    sendResetPasswordCommand = (
        endpointId: string,
        data: { newPassword?: string; resetPasswordFlags?: ('REQUIRE_ENTRY' | 'DO_NOT_ASK_CREDENTIALS_ON_BOOT' | 'LOCK_NOW')[] } = {}
    ) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'RESET_PASSWORD',
            newPassword: data.newPassword, // type string
            resetPasswordFlags: data.resetPasswordFlags, // Available flags ["REQUIRE_ENTRY", "DO_NOT_ASK_CREDENTIALS_ON_BOOT", "LOCK_NOW"]
        });

    sendStartLostModeCommand = (
        endpointId: string,
        data: { lostMessage?: any; lostPhoneNumber?: any; lostEmailAddress?: any; lostStreetAddress?: any; lostOrganization?: any } = {}
    ) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'START_LOST_MODE',
            startLostModeParams: {
                lostMessage: data.lostMessage, // type: object
                lostPhoneNumber: data.lostPhoneNumber, // type object
                lostEmailAddress: data.lostEmailAddress, // type string
                lostStreetAddress: data.lostStreetAddress, // type object
                lostOrganization: data.lostOrganization, // type object
            },
        });

    sendStopLostModeCommand = (endpointId: string) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'STOP_LOST_MODE',
            stopLostModeParams: {},
        });

    sendCommand = (endpointId: string, { type, data }: any) => {
        if (isString(endpointId) && Object.values(type)) {
            return this.post(`endpoint/${endpointId}/issueCommand`, { type, ...data });
        }
        return Promise.reject({ data: { message: 'Command rejected. Invalid endpoint or command detected.' } });
    };

    sendBulkCommand = ({ type, filter, data }: any) => {
        if (isString(filter) && Object.values(type)) {
            return this.post(`endpoint/bulk/issueCommand`, {
                type,
                filter,
                ...data,
                token: btoa(`${type}.${filter}`),
            });
        }
        return Promise.reject({ data: { message: 'Command rejected. Invalid filter or command detected.' } });
    };

    post = <T = any>(endpoint: string, data: any, overrides: any = {}) => {
        return new Promise<T>((resolve, reject) => {
            api.post(endpoint, data, {
                baseURL: this.baseEnterpriseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** PUT */

    renameAndroidEnrollmentConfiguration = (id: string, data: any) => this.update(`enrollmentConfiguration/${id}/rename`, data);

    /** PATCH */
    patchPolicy = (policyId: string, policy: any) => this.patch(`policy/${policyId}`, policy); // In use!

    // patchEnterprise = (enterpriseDataToPatch = { signinDetails: [{ signinUrl: "https://dashbeta.capaone.com/api/whatever", allowPersonalUsage: PERSONAL_USAGE_ENUM.ALLOWED }]}) => this.patch("", enterpriseDataToPatch);

    patch = <T>(endpoint: string, data: any) => {
        return new Promise<T>((resolve, reject) => {
            api.patch(endpoint, data, {
                baseURL: this.baseEnterpriseUrl,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** MISC */
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default AndroidConnection;
