import { Highcharts } from '@capasystems/ui';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import colors from 'tailwindcss/colors';

const chartManagers = {};

const HIDE_DELAY_ON_HOVER = 60000;

const onMouseMove = (event, chartObject, { syncKey, variant }) => {
    const { hoverPoint } = chartObject;
    if (hoverPoint && chartManagers[syncKey].length > 1) {
        chartManagers[syncKey].forEach((chart) => {
            const { series = [], xAxis = [] } = chart;
            if (series.length === 0) {
                return;
            }
            const visibleSeries = series.filter((serie) => serie.visible);
            const points = visibleSeries.reduce((memo, serie) => {
                memo.push(...serie.points);
                return memo;
            }, []);
            const { min = Infinity, max = -Infinity } = xAxis[0] || {};
            const point = points.find((item) => item.x === hoverPoint.x);
            chart.pointer.normalize(event);
            if (point) {
                if (point.series && hoverPoint.x >= min && hoverPoint.x <= max) {
                    try {
                        if (variant === 'tooltip') {
                            chart.update({
                                tooltip: {
                                    hideDelay: HIDE_DELAY_ON_HOVER,
                                },
                            });
                            point.onMouseOver();
                        } else if (variant === 'plotBand') {
                            chart.update({
                                xAxis: {
                                    plotBands: [
                                        {
                                            color: colors.gray[200],
                                            from: hoverPoint.x - 0.5,
                                            to: hoverPoint.x + 0.5,
                                        },
                                    ],
                                },
                            });
                        } else if (variant === 'plotLine') {
                            chart.update({
                                xAxis: {
                                    plotLines: [
                                        {
                                            width: 2,
                                            value: hoverPoint.x,
                                            snap: true,
                                        },
                                    ],
                                },
                            });
                        }
                    } catch (error) {
                        // Ignore.
                    }
                } else {
                    chart.tooltip.hide();
                }
            }
        });
    }
};

const onMouseLeave = ({ hideDelay, syncKey }) => {
    chartManagers[syncKey].forEach((chart) => {
        const { tooltip, xAxis = [] } = chart;
        chart.update({
            tooltip: {
                hideDelay: hideDelay,
            },
            xAxis: {
                plotLines: [],
                plotBands: [],
            },
        });
        if (tooltip) {
            tooltip.hide();
        }
        xAxis.forEach((axis) => {
            if (axis.cross) {
                axis.cross.hide();
            }
        });
    });
};

const registerChart = (chartObject, { syncKey }) => {
    if (isUndefined(chartManagers[syncKey])) {
        chartManagers[syncKey] = [];
    }
    const index = chartManagers[syncKey].indexOf(chartObject);
    if (index < 0) {
        chartManagers[syncKey].push(chartObject);
    }
};

const unregisterChart = (chartObject, { syncKey }) => {
    const index = chartManagers[syncKey].indexOf(chartObject);
    if (index >= 0) {
        if (chartManagers[syncKey].length === 1) {
            delete chartManagers[syncKey];
        } else {
            chartManagers[syncKey].splice(index, 1);
        }
    }
};

const syncTooltip = (chartObject = {}, props) => {
    const { container } = chartObject;
    if (!container) {
        return () => null;
    }
    registerChart(chartObject, props);
    const mouseMoveHandler = (event) => onMouseMove(event, chartObject, props);
    const mouseLeaveHandler = () => onMouseLeave(props);
    container.addEventListener('mousemove', mouseMoveHandler);
    container.addEventListener('mouseleave', mouseLeaveHandler);
    return () => {
        unregisterChart(chartObject, props);
        container.removeEventListener('mousemove', mouseMoveHandler);
        container.removeEventListener('mouseleave', mouseLeaveHandler);
    };
};

const SyncedHighcharts = ({ syncKey = 'default', variant = 'tooltip', ...props }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        return syncTooltip(chartRef.current.chart, {
            hideDelay: chartRef.current.chart.userOptions.tooltip?.hideDelay,
            syncKey,
            variant,
        }); // Return to unsubscribe.
    }, [syncKey, variant]);

    return (
        <Highcharts
            ref={chartRef}
            {...props}
        />
    );
};

SyncedHighcharts.propTypes = {
    syncKey: PropTypes.string,
    variant: PropTypes.oneOf(['tooltip', 'plotLine', 'plotBand']),
};

export { SyncedHighcharts };
