import React, { useContext, useEffect } from 'react';
import { LayoutRow, Tab, Tabs } from '@capasystems/ui';
import { QueryBuilder, ReliabilityContext, useApi, PERIODS, RELIABILITY } from './../../index';
import { QUERY_BUILDER_RELIABILITY_CONFIGURATION } from '@thirdparty/constants';
import { dispatchResizeEvent } from '@capasystems/utils';
import dayjs from 'dayjs';

const severityTabs = Object.values(RELIABILITY.SEVERITY);

const ReliabilityToolbar = ({ setFilters, urlIsDirty }) => {
    const { severityId, setSeverityId, inDetailedMode, setInDetailedMode, selectedPeriodId, setSelectedPeriodId, retentionDays } =
        useContext(ReliabilityContext);
    const api = useApi();

    useEffect(dispatchResizeEvent, []);

    const onMissingLeafData = (leafRef, callback) => {
        const timezone = dayjs.tz.guess();
        const interval = dayjs().subtract(retentionDays, 'days').startOf('day').valueOf();
        api.getEventFilters(leafRef.apiKey, interval, timezone)
            .then((response) => {
                leafRef.options = response.options;
            })
            .finally(callback);
    };

    const changeSeverity = (e, v) => setSeverityId(v);

    return (
        <LayoutRow
            verticalAlign="center"
            align="end"
            className="tw-w-full"
        >
            {severityId && (
                <div className="tw-mt-0 tw-block">
                    <Tabs
                        value={severityId}
                        onChange={changeSeverity}
                        pills
                    >
                        {severityTabs.map((severity) => (
                            <Tab
                                label={severity.name}
                                value={severity.id}
                                key={severity.id}
                                disableRipple
                            />
                        ))}
                    </Tabs>
                </div>
            )}

            {!severityId && (
                <div>
                    <Tabs
                        value={inDetailedMode ? 1 : 0}
                        onChange={(e, index) => setInDetailedMode(index === 1)}
                        pills
                        className="tw-ml-4"
                    >
                        <Tab
                            label="Summary"
                            disableRipple
                        />
                        <Tab
                            label="Detailed"
                            disableRipple
                        />
                    </Tabs>
                </div>
            )}

            <div>
                <Tabs
                    value={selectedPeriodId}
                    onChange={setSelectedPeriodId}
                    pills
                    className="tw-ml-4"
                >
                    {PERIODS.filter((p) => p.minRetentionDays <= retentionDays).map((period) => (
                        <Tab
                            label={period.name}
                            disableRipple
                            value={period.id}
                            key={period.id}
                        />
                    ))}
                </Tabs>
            </div>

            <div className="tw-ml-2">
                <QueryBuilder
                    defaultConfiguration={QUERY_BUILDER_RELIABILITY_CONFIGURATION}
                    onInit={setFilters}
                    onSubmit={setFilters}
                    onMissingLeafData={onMissingLeafData}
                    urlIsDirty={urlIsDirty}
                    className="tw-ml-2"
                />
            </div>
        </LayoutRow>
    );
};

export default ReliabilityToolbar;
