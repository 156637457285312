export const WindowsDriversConfigurationInstallationMode = {
    schema: {
        title: 'Installation mode',
        type: 'object',
        schemaId: 'installationMode',
        properties: {
            forceInstallation: {
                title: 'Force driver installations',
                description: 'Always force driver installations and reboots without asking end user.',
                type: 'boolean',
                required: true,
                defaultValue: false,
            },
            installTitle: {
                title: 'Installation title',
                description: 'The title displayed to the end user when asked to install drivers.',
                type: 'string',
                placeholder: 'The hardware drivers on your computer needs to be updated.',
                wide: true,
            },
            installMessage: {
                title: 'Installation text',
                description: 'The text displayed to the end user when asked to install drivers.',
                type: 'string',
                multiline: true,
                wide: true,
                placeholder:
                    'During the update, your screen may flicker and the network connection may be lost.\nDo you want to continue and update the hardware drivers now?',
            },
            rebootTitle: {
                title: 'Reboot title',
                description: 'The title displayed to the end user when asked to reboot.',
                type: 'string',
                placeholder: 'A reboot is required to complete the update of hardware drivers.',
                wide: true,
            },
            rebootMessage: {
                title: 'Reboot text',
                description: 'The text displayed to the end user when asked to reboot.',
                type: 'string',
                multiline: true,
                wide: true,
                placeholder: 'Do you want to restart now?',
            },
            // systemRestorePoint: {
            //     title: "System restore point",
            //     description: "Specify if the endpoint should create a system restore point.",
            //     type: "boolean",
            //     required: true,
            //     defaultValue: true,
            // },
        },
    },
};
