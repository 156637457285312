import { ApplicationInstallTypeEnum } from '../android-management-enums';

const kioskCustomLauncherEnabledTitle = 'Custom Launcher Enabled';

export const AndroidKioskConfiguration = {
    schema: {
        title: 'Kiosk',
        type: 'object',
        category: 'System',
        schemaId: 'kioskConfiguration',
        properties: {
            kioskCustomLauncherEnabled: {
                title: kioskCustomLauncherEnabledTitle,
                description:
                    'Whether the kiosk custom launcher is enabled. This replaces the home screen with a launcher that locks down the device to the apps installed via the applications setting. Apps appear on a single page in alphabetical order.',
                help: "Note: Use 'Kiosk Customization' to further configure the kiosk device behavior.",
                type: 'boolean',
            },
            kioskCustomization: {
                title: 'Kiosk Customization',
                description: `Settings controlling the behavior of a device in kiosk mode. To enable kiosk mode, set <strong class='tw-font-bold'>${kioskCustomLauncherEnabledTitle}</strong> to <strong class='tw-font-bold'>Yes</strong> or specify an application in the policy with <strong class='tw-font-bold'>Install type</strong> set to <strong class='tw-font-bold'>${ApplicationInstallTypeEnum.KIOSK}</strong>.`,
                type: 'object',
                properties: {
                    powerButtonActions: {
                        title: 'Power button actions',
                        description: 'Sets the behavior of a device in kiosk mode when a user presses and holds (long-presses) the Power button.',
                        type: 'string',
                        options: [
                            {
                                id: 'POWER_BUTTON_AVAILABLE',
                                name: 'Available',
                            },
                            {
                                id: 'POWER_BUTTON_BLOCKED',
                                name: 'Blocked',
                            },
                        ],
                    },
                    systemErrorWarnings: {
                        title: 'System error warnings',
                        description: 'Specifies whether system error dialogs for crashed or unresponsive apps are blocked in kiosk mode.',
                        help: "Note: When blocked, the system will force-stop the app as if the user chooses the 'close app' option on the UI.",
                        type: 'string',
                        options: [
                            {
                                id: 'ERROR_AND_WARNINGS_ENABLED',
                                name: 'Enabled',
                            },
                            {
                                id: 'ERROR_AND_WARNINGS_MUTED',
                                name: 'Blocked. Force stops apps instead.',
                            },
                        ],
                    },
                    systemNavigation: {
                        title: 'System navigation',
                        description: 'Specifies which navigation features are enabled (e.g. Home, Overview buttons) in kiosk mode.',
                        type: 'string',
                        options: [
                            {
                                id: 'NAVIGATION_ENABLED',
                                name: 'Home and overview buttons enabled',
                            },
                            {
                                id: 'NAVIGATION_DISABLED',
                                name: 'Home and overview buttons disabled',
                            },
                            {
                                id: 'HOME_BUTTON_ONLY',
                                name: 'Only home button enabled.',
                            },
                        ],
                    },
                    statusBar: {
                        title: 'Status bar',
                        description: 'Specifies whether system info and notifications are disabled in kiosk mode.',
                        type: 'string',
                        options: [
                            {
                                id: 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED',
                                name: 'System info and notifications enabled',
                            },
                            {
                                id: 'NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED',
                                name: 'System info and notifications disabled',
                            },
                            {
                                id: 'SYSTEM_INFO_ONLY',
                                name: 'Only system info enabled',
                            },
                        ],
                    },
                    deviceSettings: {
                        title: 'Device settings',
                        description: 'Specifies whether the Settings app is allowed in kiosk mode.',
                        type: 'string',
                        options: [
                            {
                                id: 'SETTINGS_ACCESS_ALLOWED',
                                name: 'Allow access',
                            },
                            {
                                id: 'SETTINGS_ACCESS_BLOCKED',
                                name: 'Disallow access',
                            },
                        ],
                    },
                },
            },
        },
    },
};
