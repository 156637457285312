import { isBeta } from '@capasystems/constants';
import { LayoutCenter, Loading, MainContent, useLocation } from '@capasystems/ui';
import { isObject, noop } from '@capasystems/utils';
import { LOCAL_STORAGE_ID, PRODUCT_NAME, STATUS_CODE } from '@thirdparty/constants';
import { inTestMode } from '@thirdparty/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useAnalytics, useApi, useAuthContext } from '../../../index';

const CoreContext = React.createContext();

const defaultUserProfileSettings = {
    version: 1,
    completedGettingStartedSteps: ['agentInstall'],
    powerBrickFavourites: [],
    columnPickers: [],
};

const CoreContextProvider = ({ children }) => {
    const analytics = useAnalytics();
    const location = useLocation();
    const api = useApi();
    const { user, permissions, selectedOrganization } = useAuthContext();
    const [androidEnterpriseAccount, setAndroidEnterpriseAccount] = useState(null);
    const [appleEnterpriseAccount, setAppleEnterpriseAccount] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [totalWindowsAgentCountOnInit, setTotalWindowsAgentCountOnInit] = useState(null);
    const [gettingStartedIsVisible, setGettingStartedIsVisible] = useState(false);
    const [anErrorOccurred, setAnErrorOccurred] = useState(false);
    const [showBetaFlag, setShowBetaFlag] = useState(isBeta || selectedOrganization.id === 1);

    useEffect(() => {
        analytics.initialize();
        api.getWindowsDeviceCountBasedOnFilter()
            .then(setTotalWindowsAgentCountOnInit)
            .catch(() => setAnErrorOccurred(true));

        api.getUserProfile()
            .then(({ settings }) => {
                setUserProfile({
                    ...defaultUserProfileSettings, // Make sure all the keys are available to avoid errors on user profile checks.
                    ...settings,
                });
            })
            .catch((error) => {
                if (error.status.code === STATUS_CODE.USER_PROFILE_NOT_INITIALIZED) {
                    api.createUserProfile(defaultUserProfileSettings)
                        .then(() => {
                            setUserProfile(defaultUserProfileSettings);
                        })
                        .catch((error) => {
                            console.log('Error while creating user profile:', error);
                            setAnErrorOccurred(true);
                        });
                } else {
                    setAnErrorOccurred(true);
                }
            });

        const onMissingAndroidEnterprise = (hasBackendConnection) => {
            setAndroidEnterpriseAccount({
                id: null,
                active: false,
                token: null,
                hasBackendConnection,
            });
        };

        if (permissions.canViewAndroidManagement) {
            const apiMethod = inTestMode ? api.getEmptyEndpoint : api.getAndroidEnterprise;
            apiMethod()
                .then((response) => {
                    const [details] = response;
                    if (details) {
                        const { enterpriseId, active, webToken } = details;
                        setAndroidEnterpriseAccount({
                            id: enterpriseId,
                            active,
                            token: webToken?.token || null,
                            hasBackendConnection: true,
                        });
                    } else {
                        onMissingAndroidEnterprise(true);
                    }
                })
                .catch((error) => {
                    onMissingAndroidEnterprise(false);
                    console.log('Error while fetching enterprise:', error);
                });
        } else {
            onMissingAndroidEnterprise(true);
        }

        const onMissingAppleEnterprise = (hasBackendConnection) => {
            setAppleEnterpriseAccount({ hasBackendConnection });
        };

        if (permissions.canViewAppleManagement) {
            const apiMethod = inTestMode ? api.getEmptyEndpoint : api.getAppleCluster;
            apiMethod()
                .then((response) => {
                    if (response?.deployment) {
                        setAppleEnterpriseAccount({ hasBackendConnection: true, ...response });
                    } else {
                        onMissingAppleEnterprise(true);
                    }
                })
                .catch((error) => {
                    onMissingAppleEnterprise(false);
                    console.log('Error while fetching enterprise:', error);
                });
        } else {
            onMissingAppleEnterprise(true);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem(LOCAL_STORAGE_ID.LATEST_PAGE_VIEW(user.id), location.pathname + location.search);
    }, [location]);

    const [appIsReady, providerValue] = useMemo(() => {
        const theAppIsReady =
            totalWindowsAgentCountOnInit !== null &&
            userProfile !== null &&
            anErrorOccurred === false &&
            androidEnterpriseAccount !== null &&
            appleEnterpriseAccount !== null;

        const updateUserProfile = (json) => {
            if (isObject(json)) {
                setUserProfile((current) => ({
                    ...current,
                    ...json,
                }));
                api.updateUserProfile(json).then(noop).catch(noop);
            }
        };

        let availableWindowsServices = [];
        let availableAndroidServices = [];
        let availableAppleServices = [];
        let availableEnrollments = [];
        let availableHelpPages = [];
        const managementSpace = {
            icon: 'hubOutlined',
            text: 'Management',
            title: 'Management',
            namespace: 'management',
            services: [],
            boxClassNames: 'tw-bg-indigo-300 tw-bg-opacity-25',
            iconClassNames: 'tw-text-indigo-400',
        };

        if (theAppIsReady) {
            const accessDeniedFilter = ({ accessDenied }) => !accessDenied;

            availableWindowsServices = [
                {
                    to: 'windows/dashboard',
                    text: 'Dashboard',
                    description: 'View the overall status for Windows endpoints',
                    icon: 'dashboard',
                },
                {
                    to: 'windows/device',
                    text: 'Endpoints',
                    description: 'Manage your Windows endpoints',
                    icon: 'devices',
                    accessDenied: totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/configuration',
                    text: 'Configurations',
                    description: 'Manage your Windows configurations',
                    icon: 'configureOutlined',
                    accessDenied: (!permissions.canViewAdminOnDemand && !permissions.canManageDrivers) || totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/application',
                    text: 'Applications',
                    description: 'Manage your Windows applications',
                    icon: 'apps',
                    accessDenied: (!permissions.canManageSoftwarePatching && !permissions.canManageCustomApps) || totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/updater',
                    text: 'Updater',
                    description: 'Software management',
                    icon: 'capaoneUpdater',
                    accessDenied: totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/driver',
                    text: 'Drivers',
                    description: 'Drivers management',
                    icon: 'capaDrivers',
                    accessDenied: totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/adminOnDemand',
                    text: 'AdminOnDemand',
                    description: 'Privileged Access Management',
                    icon: 'capaoneAdminOnDemand',
                    accessDenied: totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/security',
                    text: 'Security',
                    description: 'CapaOne Security',
                    icon: 'capaoneSecurity',
                    accessDenied: totalWindowsAgentCountOnInit === 0, // TODO: Add permissions.canViewSecurity to accessDenied check when Security Summary is no longer free, but contract based.
                },
                {
                    to: 'windows/reliability',
                    text: 'Reliability',
                    description: 'The overall stability of your Windows endpoints',
                    icon: 'capaoneReliability',
                    accessDenied: totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/experience?period=CD',
                    text: 'Experience',
                    description: 'CapaOne Experience',
                    icon: 'capaoneExperience',
                    accessDenied: !permissions.canViewExperience || totalWindowsAgentCountOnInit === 0,
                },
                {
                    to: 'windows/workflow',
                    text: 'Workflow',
                    description: 'Setup workflows to automate installment and updating of application and drivers',
                    icon: 'play',
                    accessDenied: !permissions.canManageSoftwarePatching && !permissions.canManageDrivers,
                },
                {
                    to: 'windows/enrollment',
                    text: `Enrollment`,
                    description: `Download and install the ${PRODUCT_NAME} agent for Windows.`,
                    icon: 'download',
                },
            ].filter(accessDeniedFilter);

            availableAndroidServices = [
                {
                    to: 'android/device',
                    text: `Endpoints`,
                    description: `Manage your Android endpoints`,
                    icon: 'androidEndpoint',
                    accessDenied: !permissions.canViewAndroidManagement || androidEnterpriseAccount?.id === null,
                },
                {
                    to: 'android/configuration',
                    text: `Configurations`,
                    description: `Manage your Android configurations`,
                    icon: 'androidConfiguration',
                    accessDenied: !permissions.canViewAndroidManagement || androidEnterpriseAccount?.id === null,
                },
                {
                    to: 'android/application',
                    text: `Applications`,
                    description: `Manage your Android applications`,
                    icon: 'androidApplication',
                    accessDenied: !permissions.canViewAndroidManagement || androidEnterpriseAccount?.id === null,
                },
                {
                    to: 'android/enrollment',
                    text: `Enrollment`,
                    description: `Enroll your Android devices`,
                    icon: 'download',
                    accessDenied: !permissions.canViewAndroidManagement,
                },
            ].filter(accessDeniedFilter);

            if (appleEnterpriseAccount?.hasCertificate) {
                availableAppleServices = [
                    {
                        to: 'apple/endpoint',
                        text: `Endpoints`,
                        description: `Manage your Apple endpoints`,
                        icon: 'iphone',
                        accessDenied: !permissions.canViewAppleManagement,
                    },
                    {
                        to: 'apple/configuration',
                        text: `Configurations`,
                        description: `Manage your Apple configurations`,
                        icon: 'appleConfiguration',
                        accessDenied: !permissions.canViewAppleManagement,
                    },
                    {
                        to: 'apple/application',
                        text: `Applications`,
                        description: `Manage your Apple applications`,
                        icon: 'appleApplication',
                        accessDenied: !permissions.canViewAppleManagement || appleEnterpriseAccount?.id === null,
                    },
                    {
                        to: 'apple/vpp',
                        text: `VPP Licenses`,
                        description: `View your Apple VPP licenses`,
                        icon: 'descriptionOutlined',
                        accessDenied: !permissions.canViewAppleManagement,
                    },
                    {
                        to: 'apple/enrollment',
                        text: `Enrollment`,
                        description: `Enroll your Apple devices`,
                        icon: 'download',
                        accessDenied: !permissions.canViewAppleManagement,
                    },
                    {
                        to: 'apple/certificates',
                        text: `Certificates`,
                        description: `Manage your Apple certificates`,
                        icon: 'certificate',
                        accessDenied: !permissions.canViewAppleManagement,
                    },
                ].filter(accessDeniedFilter);
            } else {
                availableAppleServices = [
                    {
                        to: 'apple',
                        text: `Setup`,
                        description: `Manage your Apple endpoints`,
                        icon: 'iphone',
                        accessDenied: !permissions.canViewAppleManagement,
                    },
                ].filter(accessDeniedFilter);
            }

            availableHelpPages = [
                {
                    to: 'help/tutorials',
                    text: 'Tutorials',
                    description: `A brief look at the services available in ${PRODUCT_NAME}`,
                    icon: 'play',
                },
            ];

            managementSpace.services = [
                {
                    to: 'management/group/list',
                    text: 'Groups',
                    description: 'Assign endpoints, applications and configurations to groups',
                    icon: 'diversity',
                },
                {
                    to: 'management/user/list',
                    text: 'Users',
                    description: `Manage your endpoint users in ${PRODUCT_NAME}`,
                    icon: 'supervisorAccount',
                    accessDenied: !permissions.canViewAndroidManagement && !permissions.canViewAppleManagement,
                },
                {
                    to: 'management/integration',
                    text: 'Integrations',
                    description: `Sync. users from services like Azure AD into ${PRODUCT_NAME}`,
                    icon: 'link',
                },
                {
                    to: 'management/reporting',
                    text: 'Reporting',
                    icon: 'reporting',
                    description: 'Generate and view your CapaOne reports',
                },
            ].filter(accessDeniedFilter);
        }

        return [
            theAppIsReady,
            {
                totalWindowsAgentCountOnInit,
                setTotalWindowsAgentCountOnInit,
                gettingStartedIsVisible,
                userProfile,
                updateUserProfile,
                setGettingStartedIsVisible,
                availableWindowsServices,
                availableAndroidServices,
                availableAppleServices,
                availableEnrollments,
                managementSpace,
                availableHelpPages,
                androidEnterpriseAccount,
                showBetaFlag,
                setShowBetaFlag,
                setAppleEnterpriseAccount,
                appleEnterpriseAccount,
            },
        ];
    }, [
        totalWindowsAgentCountOnInit,
        userProfile,
        anErrorOccurred,
        api,
        setTotalWindowsAgentCountOnInit,
        gettingStartedIsVisible,
        permissions,
        androidEnterpriseAccount,
        showBetaFlag,
        setShowBetaFlag,
        appleEnterpriseAccount,
    ]);

    if (appIsReady) {
        return <CoreContext.Provider value={providerValue}>{children}</CoreContext.Provider>;
    }
    if (anErrorOccurred) {
        return (
            <MainContent key="app-error">
                <LayoutCenter direction="column">
                    <b className="tw-mt-8 tw-text-slate-600">An unknown error occurred. Please try reloading the page.</b>
                </LayoutCenter>
            </MainContent>
        );
    }
    return (
        <MainContent key="loading-app">
            <LayoutCenter direction="column">
                <Loading
                    color="inherit"
                    className="tw-text-neutral-500"
                    thickness={2}
                />
            </LayoutCenter>
        </MainContent>
    );
};

export { CoreContext, CoreContextProvider };
