import { useState } from 'react';

const useElementSize = (onResize: (updatedSize: { width: number; height: number }) => void = () => null) => {
    const [elementSize, setElementSize] = useState({
        width: 0,
        height: 0,
    });

    const elementRef = (element: any) => {
        if (element && (element.clientWidth !== elementSize.width || element.clientHeight !== elementSize.height)) {
            const updatedSize = {
                width: element.clientWidth,
                height: element.clientHeight,
            };
            setElementSize(updatedSize);
            onResize(updatedSize);
        }
    };

    return [elementRef, elementSize];
};

export { useElementSize };
