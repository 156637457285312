import { SORT_DIRECTION } from '@capasystems/constants';
import { LayoutCentered, Page } from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { AppliedType, CapaOneLink } from '@thirdparty/ui';
import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TailwindBadge, TransferList, useAppleApi, useAppleApplicationsSocket, useGroupsSocket, useManagementApi } from '../../../../index';
import { ApplicationCellRenderer } from './AppleApplicationCellRenderer';

const AppleEndpointApplicationsTab = ({ endpoint }) => {
    const [applicationsList, setApplicationsList] = useState([]);
    const [groupsList, setGroupsList] = useState([]);
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const [dimensions, setDimensions] = useState({});
    const [viewMode, setViewMode] = useState(true);
    const [transferListProps, setTransferListProps] = useState({
        loading: true,
    });

    useAppleApplicationsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setApplicationsList((currentApplications) => {
                    return currentApplications.map((application) => {
                        if (application.id === documentId) {
                            return {
                                ...application,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return application;
                    });
                });
            } else if (insertOperation) {
                setApplicationsList((currentApplications) => {
                    return [
                        ...currentApplications,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setApplicationsList((currentApplications) => {
                    return currentApplications.filter((application) => application.id !== documentId);
                });
            }
        }, [])
    );

    useGroupsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setGroupsList((currentGroups) => {
                    return currentGroups.map((group) => {
                        if (group.id === documentId) {
                            return {
                                ...group,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return group;
                    });
                });
            } else if (insertOperation) {
                setGroupsList((currentGroups) => {
                    return [
                        ...currentGroups,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setGroupsList((currentList) => {
                    return currentList.filter((group) => group.id !== documentId);
                });
            }
        }, [])
    );

    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        const filteredGroups = groupsList.filter((group) => group.endpointRefs.some(({ refId }) => refId === endpoint.id));
        return applicationsList
            .map((application) => {
                const appliedDirect = application.endpointRefIds.includes(endpoint.id);
                const appliedViaGroups = filteredGroups.filter((group) => group.applicationRefs.some(({ refId }) => refId === application.id));
                const appliedViaGroup = appliedViaGroups.length > 0;
                return {
                    ...application,
                    applied: viewMode ? appliedDirect || appliedViaGroup : appliedDirect,
                    appliedViaGroup,
                    appliedViaGroups,
                    appliedDirect,
                    isVpp: application.data?.Options?.PurchaseMethod === 1,
                };
            })
            .sort(sortingFunction);
    }, [applicationsList, endpoint, groupsList, viewMode]);

    useEffect(() => {
        Promise.all([appleApi.getAppleApplications(), managementApi.getGroups()])
            .then(([applicationsResponse, groupsResponse]) => {
                setApplicationsList(applicationsResponse);
                setGroupsList(groupsResponse);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get applications or groups');
            });
    }, [appleApi]);

    const onAdd = (applicationIds) => {
        setTransferListProps({
            savingChanges: true,
        });
        appleApi
            .addEndpointToApplications(endpoint.id, applicationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const onRemove = (applicationIds) => {
        setTransferListProps({
            savingChanges: true,
        });
        appleApi
            .removeEndpointFromApplications(endpoint.id, applicationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const getState = (app, configuredApplication) => {
        if (app.configurationType === 'webclip') {
            const isInstalled = endpoint.profiles.find((profile) => profile.PayloadIdentifier === 'webClip');
            return isInstalled ? 'Installed' : 'Installing';
        }

        const inventoryApplication = endpoint.applications?.find((ia) => ia.Identifier === app.data.Identifier);
        if (inventoryApplication) {
            return 'Installed';
        }
        if (configuredApplication) {
            return configuredApplication.State;
        }
        return null;
    };

    const cellRenderer = ({ rowData }) => {
        if (viewMode) {
            const configuredApplication = endpoint.configuredApplications?.find((ca) => ca.Identifier === rowData.data.Identifier);
            return (
                <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto-auto tw-items-center">
                    <CapaOneLink
                        to={`apple/application/${rowData.id}/membership?tab=summary`}
                        className="tw-overflow-auto"
                    >
                        <ApplicationCellRenderer
                            dimensions={dimensions}
                            rowData={rowData}
                            verified={rowData.configurationType === 'webclip' ? true : !!configuredApplication}
                            state={getState(rowData)}
                            {...rowData}
                        />
                    </CapaOneLink>
                    {rowData.isVpp && (
                        <TailwindBadge
                            color="teal"
                            className="tw-ml-3"
                            size="small"
                            noShadow
                        >
                            VPP
                        </TailwindBadge>
                    )}
                    <AppliedType {...rowData} />
                </div>
            );
        }
        return (
            <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center">
                <ApplicationCellRenderer
                    dimensions={dimensions}
                    rowData={rowData}
                    {...rowData}
                />
                <AppliedType {...rowData} />
            </div>
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <Page title="Endpoints · Applications">
            <TransferList
                items={memoizedApplications}
                onAdd={onAdd}
                onRemove={onRemove}
                entity="application"
                className="tw-py-4"
                onResize={setDimensions}
                viewMode={viewMode}
                setViewMode={setViewMode}
                cellRenderer={cellRenderer}
                totalRowCount={memoizedApplications.filter((a) => !a.applied).length}
                totalRowCountAssigned={memoizedApplications.filter((a) => a.applied === true).length}
                {...transferListProps}
            />
        </Page>
    );
};

export { AppleEndpointApplicationsTab };
