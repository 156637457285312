import { getTheme } from '@capasystems/utils';
import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';
import Tree from '../tree/tree';
import DraggableTreeNode from './draggable-tree-node';

const { capasystems } = getTheme();

export const DraggableTree = ({
    items,
    onExpand,
    onDrop,
    acceptedColor = capasystems.palette.success.light,
    selectedColor = capasystems.palette.success.light,
    onMenuOpen = () => null,
    onMenuClose = () => null,
}) => {
    /* istanbul ignore next */
    const renderTreeNodes = (data) =>
        data.map((item) => (
            <DraggableTreeNode
                key={item.content}
                {...item}
                dataRef={item}
                onExpand={onExpand}
                onDrop={onDrop}
                acceptedColor={acceptedColor}
                selectedColor={selectedColor}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
            >
                {item.children && renderTreeNodes(item.children)}
            </DraggableTreeNode>
        ));

    /* istanbul ignore next */
    return <Tree>{renderTreeNodes(items)}</Tree>;
};

DraggableTree.propTypes = {
    items: arrayOf(shape).isRequired,
    onExpand: func.isRequired,
    onMenuOpen: func,
    onMenuClose: func,
    onDrop: func.isRequired,
    /** The color of the dropzones that accepts the dragged item */
    acceptedColor: string,
    /** The color of the slected items */
    selectedColor: string,
};

export default DraggableTree;
