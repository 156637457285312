import { COMMAND_IDS } from '@apple';
import { api, getUniqueId, isString, now } from '@capasystems/utils';
import { TAppleApplicationWithId, TAppleEndpointWithId, TAppleEnrollmentConfiguration, TAppleEnrollmentConfigurationWithId } from '@db/party';
import { coreAppleManagement } from '@thirdparty/constants';

const expirationTimestamps: any = {};

class AppleConnection {
    baseUrl: string;
    abortController: AbortController;
    connectionID: string;

    constructor() {
        this.baseUrl = `${api.defaults.baseURL}/apple`;
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('AppleConnectionID');
    }

    //*GET*//

    getEndpoints = (params: any) => this.get<{ content: TAppleEndpointWithId[]; count: number }>(`endpoint`, params);

    getEndpoint = (id: string) => this.get<TAppleEndpointWithId>(`endpoint/${id}`);

    getEndpointSettings = (id: string) => this.get(`endpoint/${id}/settings`);

    getEndpointCommands = (id: string) => this.get(`endpoint/${id}/command`);

    getDepEndpoints = (params: any = {}) => this.get(`endpoint/dep`, { pageSize: 50, ...params });

    getAppleConfigurations = (params?: any) => this.get(`configuration`, params);

    getAppleConfiguration = (id: string) => this.get(`configuration/${id}`);

    getAppleApplications = (params?: any) => this.get(`application`, params);

    getAppleVppLicenses = (params: any) => this.get(`vpp`, params);

    getAppleVppAssignments = (id: string) => this.get(`vpp/${id}/assignments`);

    getAppleSingleVppLicense = (id: string) => this.get(`vpp/${id}`);

    getSingleAppleApplication = (id: string) => this.get<TAppleApplicationWithId>(`application/${id}`);

    getEnrollmentConfigurations = (params: any) => this.get<TAppleEnrollmentConfigurationWithId[]>(`enrollmentConfiguration`, params);

    getEnrollmentConfiguration = (id: string) => this.get<TAppleEnrollmentConfigurationWithId>(`enrollmentConfiguration/${id}`);

    getEndpointData = (id: string) => this.get(`endpoint/${id}/update`);

    getAppleModels = () =>
        fetch('https://gist.githubusercontent.com/adamawolf/3048717/raw/07ad6645b25205ef2072a560e660c636c8330626/Apple_mobile_device_types.txt').then(
            (response) => response.text()
        );

    getItunesApplications = (search: string, deviceType: string, countryId: string) =>
        fetch(
            `https://itunes.apple.com/search?term=${search.toLocaleLowerCase()}${deviceType ? '&entity=' + deviceType : ''}${
                countryId ? '&country=' + countryId : ''
            }`
        ).then(async (response) => {
            if (response.ok) {
                return response.json();
            } else {
                const error = await response.json();
                throw new Error(error.errorMessage);
            }
        });

    getItunesApplicationLookup = (trackId: string, query: string) => this.get('application/itunes/' + trackId, query);

    getConfigurationApplications = () => this.get('configuration/application');

    get = <T = any>(endpoint: string, params: any = {}, overrides: any = {}) => {
        const requestedTimestamp = now();
        return new Promise<T>((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                cache: false,
                withCredentials: true,
                baseURL: this.baseUrl,
                ...overrides,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    //*POST*//
    createEnrollmentConfiguration = (data: TAppleEnrollmentConfiguration) => this.post(`enrollmentConfiguration`, data);

    appleSignup = (data: any) => this.post(`signup`, data);

    updatePushRequest = () => this.post(`pushrequest`);

    updateDepRequest = () => this.post(`deprequest`);

    createAppleConfiguration = (name: string, configurationObject: any) =>
        this.post('configuration', {
            name,
            configurationType: coreAppleManagement.configurationType.apple,
            data: configurationObject,
        });

    createAppleApplication = (name: string, iconUrl: string, supportedPlatforms: string[], configurationType: string, applicationObject: any, vendor: string) =>
        this.post('application', {
            name,
            iconUrl,
            supportedPlatforms,
            configurationType,
            data: applicationObject,
            vendor,
        });

    uploadCertificate = (data: any) => this.post(`upload/certificate`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    uploadDepCertificate = (data: any) => this.post(`upload/depcertificate`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    uploadVppToken = (data: any) => this.post(`upload/vpp`, data, { headers: { 'Content-Type': 'multipart/form-data' } });

    //Endpoint side linking
    addEndpointToConfigurations = (endpointId: string, configurationIds: string[]) =>
        this.post(`endpoint/${endpointId}/configurations`, {
            configurationRefIds: configurationIds,
        });

    //Configuration side linking
    addEndpointsToConfiguration = (configurationId: string, endpointRefIds: string[]) =>
        this.post(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    //Endpoint side linking
    addEndpointToApplications = (endpointId: string, applicationIds: string[]) =>
        this.post(`endpoint/${endpointId}/applications`, {
            applicationRefIds: applicationIds,
        });

    //Application side linking
    addEndpointsToApplication = (applicationId: string, endpointRefIds: string[]) =>
        this.post(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    sendEndpointCommand = (udid: string, { commandId, data }: any) => {
        if (isString(udid) && Object.values(COMMAND_IDS).includes(commandId)) {
            return this.post(`endpoint/${udid}/command`, { commandId, data });
        }
        return Promise.reject({ data: { message: 'Command rejected. Invalid endpoint or command detected.' } });
    };
    sendCommand = ({ commandId, data }: any) => {
        if (Object.values(COMMAND_IDS).includes(commandId)) {
            return this.post(`endpoint/null/command`, { commandId, data });
        }
        return Promise.reject({ data: { message: 'Command rejected. Invalid command detected.' } });
    };

    sendBulkCommand = ({ commandId, filter, data }: any) => {
        if (isString(filter) && Object.values(COMMAND_IDS).includes(commandId)) {
            return this.post('endpoint/bulk/command', {
                commandId,
                filter,
                data,
                token: btoa(`${commandId}.${filter}`),
            });
        }
        return Promise.reject({ data: { message: 'Command rejected. Invalid filter or command detected.' } });
    };

    post = <T = any>(endpoint: string, data?: any, overrides: any = {}) => {
        return new Promise<T>((resolve, reject) => {
            api.post(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    //*PUT*//
    updateEndpoint = (id: string, data: any) => this.update(`endpoint/${id}`, data);

    renameEndpoint = (id: string, data: any) => this.update(`endpoint/${id}/rename`, data);

    linkUserEndpoint = (id: string, data: any) => this.update(`endpoint/${id}/user`, data);

    /** Do not provide user if delete */
    updateOrDeleteEndpointUser = (endpointId: string, userId: string | null = null) => {
        if (userId === null) {
            return this.delete(`endpoint/${endpointId}/user`);
        } else {
            return this.update(`endpoint/${endpointId}/user`, { user: userId });
        }
    };

    updateEnrollmentConfiguration = (id: string, data: any) => this.update(`enrollmentConfiguration/${id}`, data);

    updateAppleConfiguration = (id: string, configurationObject: any) => this.update(`configuration/${id}`, configurationObject);

    updateAppleApplication = (id: string, applicationObject: any) => this.update(`application/${id}`, applicationObject);

    updateAppleDepEnrollmentlinking = (enrollmentId: string, data: any) => this.update(`endpoint/dep/${enrollmentId}/link`, data);

    updateAppleConfigurationPriority = (updatedConfigurations: any[] = []) =>
        this.update(
            'configuration',
            updatedConfigurations.map(({ id, priority }) => ({ id, priority }))
        );

    updateAppleApplicationPriority = (updatedApplications: any[] = []) =>
        this.update(
            'application',
            updatedApplications.map(({ id, priority }) => ({ id, priority }))
        );

    update = <T = any>(endpoint: string, data: any, overrides: any = {}) => {
        return new Promise<T>((resolve, reject) => {
            api.put(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    //*DELETE*//
    deleteEndpoint = (id: string) => this.delete(`endpoint/${id}`);

    deleteEnrollmentConfiguration = (id: string) => this.delete(`enrollmentConfiguration/${id}`);

    unlinkUserEndpoint = (id: string) => this.delete(`endpoint/${id}/user`);

    deleteMdmStack = () => this.delete(`removeMDM`);

    deleteAppleConfiguration = (id: string, force: boolean) => this.delete(`configuration/${id}?force=${force}`);

    deleteAppleApplication = (id: string, force: boolean) => this.delete(`application/${id}?force=${force}`);

    //Endpoint side linking
    removeEndpointFromConfigurations = (endpointId: string, configurationIds: string[]) =>
        this.delete(`endpoint/${endpointId}/configurations`, {
            configurationRefIds: configurationIds,
        });

    //Configuration side linking
    removeEndpointsFromConfiguration = (configurationId: string, endpointRefIds: string[]) =>
        this.delete(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    //Endpoint side linking
    removeEndpointFromApplications = (endpointId: string, applicationIds: string[]) =>
        this.delete(`endpoint/${endpointId}/applications`, {
            applicationRefIds: applicationIds,
        });

    //Application side linking
    removeEndpointsFromApplication = (applicationId: string, endpointRefIds: string[]) =>
        this.delete(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    delete = <T = any>(endpoint: string, data: any = {}, overrides: any = {}) =>
        api.delete<T>(endpoint, {
            baseURL: this.baseUrl,
            ...overrides,
            data,
        });

    //*PATCH*//

    //* MISC*//
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default AppleConnection;
