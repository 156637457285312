import { TSocketMethod } from '@capasystems/types';
import { useParams } from '@capasystems/ui';
import { isDefined } from '@capasystems/utils';
import {
    TAndroidConfigurationWithId,
    TAndroidDeviceWithId,
    TAppleApplicationWithId,
    TAppleConfigurationWithId,
    TAppleDEPEndpointWithId,
    TAppleEndpointWithId,
    TAppleEnrollmentConfigurationWithId,
    TAppleVPPLicenses,
} from '@db/party';
import {
    ANDROID_APPLICATIONS_EVENT,
    ANDROID_APPLICATION_EVENT,
    ANDROID_CONFIGURATIONS_EVENT,
    ANDROID_CONFIGURATION_EVENT,
    ANDROID_ENDPOINTS_EVENT,
    ANDROID_ENDPOINT_EVENT,
    APPLE_APPLICATIONS_EVENT,
    APPLE_APPLICATION_EVENT,
    APPLE_CLUSTER_EVENT,
    APPLE_COMMANDS_EVENT,
    APPLE_CONFIGURATIONS_EVENT,
    APPLE_CONFIGURATION_EVENT,
    APPLE_DEP_ENDPOINTS_EVENT,
    APPLE_ENDPOINTS_EVENT,
    APPLE_ENDPOINT_EVENT,
    APPLE_ENROLLMENT_CONFIGURATIONS_EVENT,
    APPLE_VPP_LICENSES_EVENT,
    APPLICATION_CUSTOM_APP_PATCH_QUEUE_EVENT,
    APPLICATION_EVENT,
    APPLICATION_EVENT_WITH_ID,
    APPLICATION_PATCHING_EVENT,
    APPLICATION_PATCH_UPDATE_AVAILABLE_EVENT,
    CONFIGURATION_EVENT,
    CONFIGURATION_EVENT_WITH_ID,
    DEVICES_EVENT,
    DEVICE_CUSTOM_APP_PATCH_QUEUE_EVENT,
    DEVICE_DRIVER_PATCHING_EVENT,
    DEVICE_EVENT,
    DEVICE_LOG_EVENT,
    DEVICE_PATCHING_EVENT,
    DRIVER_INVENTORY_EVENT,
    GROUPS_EVENT,
    GROUP_EVENT,
    HARDWARE_INVENTORY_EVENT,
    JOIN_ROOM,
    LEAVE_ROOM,
    MANAGEMENT_INTEGRATIONS_EVENT,
    OPERATION_TYPE_DELETE,
    OPERATION_TYPE_INSERT,
    OPERATION_TYPE_REPLACE,
    OPERATION_TYPE_UPDATE,
    ORG_CONFIG_EVENT,
    ORG_DRIVER_PATCHING_EVENT,
    ORG_PATCHING_EVENT,
    PACKAGE_EVENT,
    REPORT_EXPORT_HISTORY_EVENT,
    SECURITY_INVENTORY_EVENT,
    SOFTWARE_INVENTORY_EVENT,
    WORKFLOW_EVENT,
    WORKFLOW_EVENT_WITH_ID,
} from '@thirdparty/socket';
import { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';

const socket = io({
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    autoConnect: false, // Controlled in AuthContext.
});

let subscriptions: { roomId: string; roomParams: any; subscriptionId: string }[] = [];

const numberOfRetries = 10;
let retries = 0;
const checkConnectionTimeout = 1000;

// TODO: Make sure connect waits until after authenticated.
socket.on('connect', () => {
    // eslint-disable-next-line no-console
    console.log('Socket connected');
    retries = 0;
    subscriptions.forEach(({ roomId, roomParams }) => {
        socket.emit(JOIN_ROOM, { roomId, roomParams });
    });
});

// eslint-disable-next-line no-console
socket.on('connect_error', () => console.error('Socket connection error'));

const checkConnection = () => {
    if (!socket.connected && retries < numberOfRetries) {
        console.log('Socket reconnecting');
        socket.disconnect();
        socket.connect();
        retries += 1;
        setTimeout(checkConnection, checkConnectionTimeout * retries);
    }
};

socket.on('disconnect', (reason) => {
    // eslint-disable-next-line no-console
    console.log('Socket disconnected with reason: ', reason);
    if (reason === 'io server disconnect' || reason === 'transport error') {
        // The disconnection was initiated by the server.
        socket.connect();
        setTimeout(checkConnection, checkConnectionTimeout);
    }
});

const useSocket = () => socket;

const useCoreSocket = (roomId: string, roomParams: any, callback: TSocketMethod) => {
    const initialized = useRef(false);
    const { organizationId } = useParams();

    useEffect(() => {
        const subscriptionObject = {
            roomId,
            roomParams: {
                orgId: Number(organizationId),
                ...roomParams,
            },
        };
        const subscriptionId = JSON.stringify(subscriptionObject);
        socket.emit(JOIN_ROOM, subscriptionObject);
        subscriptions.push({
            ...subscriptionObject,
            subscriptionId,
        });
        initialized.current = true;
        return () => {
            // Cleanup on unMount.
            subscriptions = subscriptions.filter((s) => s.subscriptionId !== subscriptionId);
            socket.emit(LEAVE_ROOM, subscriptionObject);
        };
    }, []);

    // If the callback depends on state.
    useEffect(() => {
        if (initialized.current) {
            socket.off(roomId);
        }
        /*
        Returns object with keys:
        operationType
        documentId, // document _id
        fullDocument (on operationTypes 'insert', 'update' and 'replace'),
        updateDescription (on operationType 'update' only).
        */
        socket.on(roomId, (response) =>
            callback(response, {
                syntheticDeleteOperation: isDefined(response.updateDescription?.updatedFields.expireDate),
                insertOperation: response.operationType === OPERATION_TYPE_INSERT,
                updateOperation: response.operationType === OPERATION_TYPE_UPDATE,
                replaceOperation: response.operationType === OPERATION_TYPE_REPLACE,
                deleteOperation: response.operationType === OPERATION_TYPE_DELETE,
            })
        );
    }, [roomId, callback]);
};

/**
 * @param callback Should always be wrapped in useCallback
 */
const useDevicesSocket = (callback: TSocketMethod) => useCoreSocket(DEVICES_EVENT, {}, callback);
const useDeviceSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(DEVICE_EVENT, { deviceId }, callback);
const useDeviceLogSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(DEVICE_LOG_EVENT, { deviceId }, callback);
const useDevicePatchingSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(DEVICE_PATCHING_EVENT, { deviceId }, callback);
const useDeviceDriverPatchingSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(DEVICE_DRIVER_PATCHING_EVENT, { deviceId }, callback);
const useApplicationPatchingSocket = (applicationId: string, callback: TSocketMethod) => useCoreSocket(APPLICATION_PATCHING_EVENT, { applicationId }, callback);
const useApplicationPatchUpdateAvailableSocket = (applicationId: string, callback: TSocketMethod) =>
    useCoreSocket(APPLICATION_PATCH_UPDATE_AVAILABLE_EVENT, { applicationId }, callback);
const useOrgPatchingSocket = (callback: TSocketMethod) => useCoreSocket(ORG_PATCHING_EVENT, {}, callback);
const useOrgDriverPatchingSocket = (callback: TSocketMethod) => useCoreSocket(ORG_DRIVER_PATCHING_EVENT, {}, callback);
const useSoftwareSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(SOFTWARE_INVENTORY_EVENT, { deviceId }, callback);
const useHardwareSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(HARDWARE_INVENTORY_EVENT, { deviceId }, callback);
const useEndpointSecurityInventorySocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(SECURITY_INVENTORY_EVENT, { deviceId }, callback);
const useDriverSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(DRIVER_INVENTORY_EVENT, { deviceId }, callback);
const useWorkflowSocket = (callback: TSocketMethod) => useCoreSocket(WORKFLOW_EVENT, {}, callback);
const useWorkflowWithIDSocket = (workflowId: string, callback: TSocketMethod) => useCoreSocket(WORKFLOW_EVENT_WITH_ID, { workflowId }, callback);
const useConfigurationSocket = (callback: TSocketMethod) => useCoreSocket(CONFIGURATION_EVENT, {}, callback);
const useConfigurationWithIdSocket = (configurationId: string, callback: TSocketMethod) =>
    useCoreSocket(CONFIGURATION_EVENT_WITH_ID, { configurationId }, callback);
const usePackageSocket = (callback: TSocketMethod) => useCoreSocket(PACKAGE_EVENT, {}, callback);
const useAndroidEndpointsSocket = (callback: TSocketMethod<TAndroidDeviceWithId>) => useCoreSocket(ANDROID_ENDPOINTS_EVENT, {}, callback);
const useAndroidEndpointSocket = (endpointId: string, callback: TSocketMethod<TAndroidDeviceWithId>) =>
    useCoreSocket(ANDROID_ENDPOINT_EVENT, { endpointId }, callback);
const useAndroidConfigurationsSocket = (callback: TSocketMethod<TAndroidConfigurationWithId>) => useCoreSocket(ANDROID_CONFIGURATIONS_EVENT, {}, callback);
const useAndroidApplicationsSocket = (callback: TSocketMethod<TAndroidConfigurationWithId>) => useCoreSocket(ANDROID_APPLICATIONS_EVENT, {}, callback);
const useAndroidApplicationOrConfigurationSocket = (isApplication: boolean, id: string, callback: TSocketMethod<TAndroidConfigurationWithId>) =>
    useCoreSocket(isApplication ? ANDROID_APPLICATION_EVENT : ANDROID_CONFIGURATION_EVENT, { id }, callback);
const useAppleConfigurationsSocket = (callback: TSocketMethod<TAppleConfigurationWithId>) => useCoreSocket(APPLE_CONFIGURATIONS_EVENT, {}, callback);
const useAppleApplicationsSocket = (callback: TSocketMethod<TAppleApplicationWithId>) => useCoreSocket(APPLE_APPLICATIONS_EVENT, {}, callback);
const useAppleVppLicensesSocket = (callback: TSocketMethod<TAppleVPPLicenses>) => useCoreSocket(APPLE_VPP_LICENSES_EVENT, {}, callback);
const useAppleApplicationOrConfigurationSocket = (isApplication: boolean, id: string, callback: TSocketMethod) =>
    useCoreSocket(isApplication ? APPLE_APPLICATION_EVENT : APPLE_CONFIGURATION_EVENT, { id }, callback);
const useAppleConfigurationSocket = (id: string, callback: TSocketMethod<TAppleConfigurationWithId>) =>
    useCoreSocket(APPLE_CONFIGURATIONS_EVENT, { id }, callback);
const useManagementIntegrationsSocket = (callback: TSocketMethod) => useCoreSocket(MANAGEMENT_INTEGRATIONS_EVENT, {}, callback);
const useGroupsSocket = (callback: TSocketMethod) => useCoreSocket(GROUPS_EVENT, {}, callback);
const useGroupSocket = (id: string, callback: TSocketMethod) => useCoreSocket(GROUP_EVENT, { id }, callback);
const useApplicationSocket = (callback: TSocketMethod) => useCoreSocket(APPLICATION_EVENT, {}, callback);
const useApplicationWithIdSocket = (applicationId: string, callback: TSocketMethod) => useCoreSocket(APPLICATION_EVENT_WITH_ID, { applicationId }, callback);
const useAppleEndpointWithIdSocket = (endpointId: string, callback: TSocketMethod<TAppleEndpointWithId>) =>
    useCoreSocket(APPLE_ENDPOINT_EVENT, { endpointId }, callback);
const useAppleEndpointsSocket = (callback: TSocketMethod<TAppleEndpointWithId>) => useCoreSocket(APPLE_ENDPOINTS_EVENT, {}, callback);
const useAppleDEPEndpointsSocket = (callback: TSocketMethod<TAppleDEPEndpointWithId>) => useCoreSocket(APPLE_DEP_ENDPOINTS_EVENT, {}, callback);
const useAppleClusterEvent = (callback: TSocketMethod) => useCoreSocket(APPLE_CLUSTER_EVENT, {}, callback);
const useAppleEnrollmentConfigurationsSocket = (callback: TSocketMethod<TAppleEnrollmentConfigurationWithId>) =>
    useCoreSocket(APPLE_ENROLLMENT_CONFIGURATIONS_EVENT, {}, callback);
const useDeviceCustomAppPatchQueueSocket = (deviceId: string, callback: TSocketMethod) =>
    useCoreSocket(DEVICE_CUSTOM_APP_PATCH_QUEUE_EVENT, { deviceId }, callback);
const useAppleDeviceCommandQueueSocket = (deviceId: string, callback: TSocketMethod) => useCoreSocket(APPLE_COMMANDS_EVENT, { deviceId }, callback);
const useReportExportHistorySocket = (callback: TSocketMethod) => useCoreSocket(REPORT_EXPORT_HISTORY_EVENT, {}, callback);
const useOrgConfigSocket = (callback: TSocketMethod) => useCoreSocket(ORG_CONFIG_EVENT, {}, callback);
const useCustomAppPatchQueueSocket = (applicationId: string, callback: TSocketMethod) =>
    useCoreSocket(APPLICATION_CUSTOM_APP_PATCH_QUEUE_EVENT, { applicationId }, callback);

export {
    useAndroidApplicationOrConfigurationSocket,
    useAndroidApplicationsSocket,
    useAndroidConfigurationsSocket,
    useAndroidEndpointSocket,
    useAndroidEndpointsSocket,
    useAppleApplicationOrConfigurationSocket,
    useAppleApplicationsSocket,
    useAppleClusterEvent,
    useAppleConfigurationSocket,
    useAppleConfigurationsSocket,
    useAppleDEPEndpointsSocket,
    useAppleDeviceCommandQueueSocket,
    useAppleEndpointWithIdSocket,
    useAppleEndpointsSocket,
    useAppleEnrollmentConfigurationsSocket,
    useAppleVppLicensesSocket,
    useApplicationPatchUpdateAvailableSocket,
    useApplicationPatchingSocket,
    useApplicationSocket,
    useApplicationWithIdSocket,
    useConfigurationSocket,
    useConfigurationWithIdSocket,
    useCustomAppPatchQueueSocket,
    useDeviceCustomAppPatchQueueSocket,
    useDeviceDriverPatchingSocket,
    useDeviceLogSocket,
    useDevicePatchingSocket,
    useDeviceSocket,
    useDevicesSocket,
    useDriverSocket,
    useEndpointSecurityInventorySocket,
    useGroupSocket,
    useGroupsSocket,
    useHardwareSocket,
    useManagementIntegrationsSocket,
    useOrgConfigSocket,
    useOrgDriverPatchingSocket,
    useOrgPatchingSocket,
    usePackageSocket,
    useReportExportHistorySocket,
    useSocket,
    useSoftwareSocket,
    useWorkflowSocket,
    useWorkflowWithIDSocket,
};
