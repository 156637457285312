import MUIMenu, { MenuProps } from '@mui/material/Menu';
import React from 'react';

/** Accepts the same props as ContextDialog. Will be attached to left and bottom.  */
export const Menu = React.forwardRef<HTMLDivElement, MenuProps>((props, ref) => (
    <MUIMenu
        ref={ref}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        classes={{ paper: 'tw-shadow' }}
        {...props}
    />
));

export default Menu;
