import {
    patchInstallOrUninstallFailed,
    patchIsAvailable,
    patchIsInProgress,
    patchIsInstalled,
    patchIsInstalledButRestartRequired,
    patchIsNotAvailable,
} from '@thirdparty/utils';
import React from 'react';

import { Badge, BadgeProps, Tab, Tabs } from '@capasystems/ui';
import './patch-status-tabs.scss';

export type PatchStatusTabsProps = {
    onTabChange: (event: React.SyntheticEvent, value: any) => void;
    selectedTab: number;
    items: any[];
    searchFilter: (item: any) => boolean;
    includeNotInstalled?: boolean;
};

export const PatchStatusTabs: React.FC<PatchStatusTabsProps> = ({ onTabChange, selectedTab, items, searchFilter, includeNotInstalled = true }) => {
    const tabContent = [
        {
            text: `Update available`,
            count: items.filter(patchIsAvailable).filter(searchFilter).length,
        },
        {
            text: `Not installed`,
            count: items.filter(patchIsNotAvailable).filter(searchFilter).length,
        },
        {
            text: 'In progress',
            count: items.filter(patchIsInProgress).filter(searchFilter).length,
        },
        {
            text: 'Restart required',
            count: items.filter(patchIsInstalledButRestartRequired).filter(searchFilter).length,
        },
        {
            text: 'Up-to-date',
            count: items.filter(patchIsInstalled).filter(searchFilter).length,
        },
        {
            text: 'Failed',
            count: items.filter(patchInstallOrUninstallFailed).filter(searchFilter).length,
            badgeColor: 'error',
        },
    ];

    if (!includeNotInstalled) {
        tabContent.splice(1, 1);
    }

    return (
        <div>
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                orientation="vertical"
                pills
            >
                {tabContent.map((tab) => (
                    <Tab
                        key={tab.text}
                        disableRipple
                        label={
                            <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                <div className="tw-text-left">{tab.text}</div>
                                <div className="tw-ml-6 tw-mr-2">
                                    <Badge
                                        badgeContent={tab.count}
                                        color={tab.badgeColor as BadgeProps['color']}
                                        max={9999}
                                    >
                                        <div />
                                    </Badge>
                                </div>
                            </div>
                        }
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default PatchStatusTabs;
