/* eslint-disable react-hooks/exhaustive-deps */
import { SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import { Avatar, Badge, Ellipsis, Icon, TIconType, Tooltip, virtualizedTableColumnPropTypes } from '@capasystems/ui';
import { formatDate, formatTimestamp, isDefined } from '@capasystems/utils';
import { NOT_AVAILABLE, OWNERSHIP } from '@thirdparty/constants';
import { useAuthContext } from '@thirdparty/ui';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import {
    BatteryLevel,
    BooleanBadge,
    CapaOneLink,
    DeviceAgentState,
    DeviceTypeIcon,
    TailwindBadge,
    TwoLineCellRenderer,
    UnitConverter,
    UserCellRenderer,
    WithExperimentalFeatures,
} from '../../../index';

const DeviceDetails = ({ device, osTime }: { device: any; osTime: any }) => {
    const TooltipField = ({ children, label }: { children: React.ReactNode; label: string }) => {
        return (
            <div>
                <div className="tw-mb-1 tw-text-xs tw-font-medium tw-text-gray-200">{label}</div>
                <div className="tw-text-xs tw-font-semibold">{children || NOT_AVAILABLE}</div>
            </div>
        );
    };

    return (
        <Tooltip
            noPadding
            position={TOOLTIP.POSITION.LEFT_START}
            content={
                <div className="tw-grid tw-justify-between tw-gap-4 tw-p-4">
                    <TooltipField label="OS Version">{device.osVersion}</TooltipField>

                    <TooltipField label="Latest OS Startup">
                        <TwoLineCellRenderer
                            main={formatTimestamp(osTime)}
                            callToAction
                            secondary={<span className="tw-font-semibold">{dayjs(osTime).fromNow()}</span>}
                        />
                    </TooltipField>
                    <TooltipField label="Agent version">{device.agentVersion}</TooltipField>
                </div>
            }
        >
            <Icon
                type="infoOutlined"
                className="tw-text-black/60"
            />
        </Tooltip>
    );
};

type cellRenderer = { cellData: any; rowData: any };

const useEndpointSoftwareInventoryColumns = () => {
    const { permissions } = useAuthContext();

    const availableColumns = useMemo(() => {
        const allColumns = [
            {
                key: 'name',
                dataKey: 'Name',
                label: 'Name',
                minWidth: 300,
                maxWidth: 900,
                type: 'multiLine',
                cellRenderer: ({ cellData, rowData }: cellRenderer) => (
                    <TwoLineCellRenderer
                        main={cellData}
                        callToAction
                        secondary={rowData.Publisher}
                    />
                ),
            },
            {
                key: 'IsSystemComponent',
                dataKey: 'IsSystemComponent',
                label: 'System component',
                disableSort: true,
                minWidth: 120,
                maxWidth: 120,
                type: 'icon',
                cellRenderer: ({ cellData }: cellRenderer) => <BooleanBadge value={cellData} />,
            },
            {
                key: 'version',
                dataKey: 'Version',
                label: 'Version',
                minWidth: 160,
                maxWidth: 400,
                disableSort: true,
                type: 'multiLine',
                cellRenderer: ({
                    cellData,
                    rowData: { Language, Platform, platformName, languageName },
                }: {
                    cellData: any;
                    rowData: {
                        Language: string;
                        Platform: string;
                        platformName: string;
                        languageName: string;
                    };
                }) => (
                    <TwoLineCellRenderer
                        main={cellData}
                        secondary={
                            <>
                                <span>{languageName}</span>
                                {languageName && <span>&nbsp;&middot;&nbsp;</span>}
                                <span>{platformName}</span>
                            </>
                        }
                    />
                ),
            },
            {
                key: 'installDate',
                dataKey: 'InstallDate',
                label: 'Install date',
                minWidth: 96,
                maxWidth: 96,
                defaultSortDirection: SORT_DIRECTION.DESC,
                type: 'date',
                cellRenderer: ({ cellData }: cellRenderer) => (cellData ? formatDate(cellData) : cellData),
            },
            {
                key: 'size',
                dataKey: 'Size',
                label: 'Size',
                minWidth: 96,
                maxWidth: 96,
                type: 'number',
                cellRenderer: ({ cellData }: cellRenderer) => (cellData ? <UnitConverter data={{ unit: 'bytes', value: cellData }} /> : null),
                defaultSortDirection: SORT_DIRECTION.DESC,
            },
            {
                key: 'userAccount',
                dataKey: 'UserAccount',
                label: 'User account',
                minWidth: 160,
                maxWidth: 400,
                type: 'string',
            },
            {
                key: 'Upgrade code',
                dataKey: 'UpgradeCode',
                label: 'Upgrade code',
                minWidth: 280,
                maxWidth: 400,
                disableSort: true,
                type: 'string',
            },
            {
                key: 'AppCode',
                dataKey: 'AppCode',
                label: 'Product code',
                minWidth: 280,
                maxWidth: 400,
                disableSort: true,
            },
        ];
        if (permissions.canViewExperimentalFeatures) {
            allColumns.splice(2, 0, {
                key: 'CpeMatchString',
                dataKey: 'CpeMatchString',
                label: 'Cpe Match',
                minWidth: 240,
                maxWidth: 400,
                disableSort: false,
                type: 'string',
                cellRenderer: ({ rowData }: cellRenderer) => {
                    return (
                        <WithExperimentalFeatures tooltipPosition={TOOLTIP.POSITION.LEFT}>
                            {isDefined(rowData.CpeMatchString) ? (
                                <a
                                    target="_BLANK"
                                    href={`https://nvd.nist.gov/vuln/search/results?query=${rowData.CpeMatchString}`}
                                    rel="noreferrer"
                                >
                                    <Ellipsis>{rowData.CpeMatchString}</Ellipsis>
                                </a>
                            ) : (
                                <span className="tw-text-gray-300">{NOT_AVAILABLE}</span>
                            )}
                        </WithExperimentalFeatures>
                    );
                },
            });
        }
        return allColumns;
    }, []);
    return availableColumns;
};

const useWindowsEndpointListColumns = (organizations: any, permissions: any, reporting: any) => {
    const [organizationsIdNameObject] = useState(
        organizations.reduce(function (acc: any, cur: any, i: number) {
            acc[cur.id] = cur.name;
            return acc;
        }, {} as any)
    );

    const availableColumns = useMemo(() => {
        const columns = [
            {
                key: 'name',
                dataKey: 'name',
                label: 'Name',
                minWidth: 300,
                type: 'multiLine',
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    const nameCellContent = (
                        <div className="tw-flex tw-items-center">
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.osName}
                            />
                            {rowData.software && rowData.software.failedPatchCount > 0 && (
                                <Tooltip
                                    position={TOOLTIP.POSITION.TOP_START}
                                    content={`Failed Patch Count: ${rowData.software.failedPatchCount}`}
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-ml-2 tw-text-red-500"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );

                    if (reporting) {
                        return nameCellContent;
                    }

                    // @ts-ignore this is not typed
                    return <CapaOneLink to={`windows/device/${rowData.id || rowData['_id']}`}>{nameCellContent}</CapaOneLink>;
                },
            },
            {
                key: 'online',
                dataKey: 'online',
                label: 'Agent Status',
                minWidth: 95,
                maxWidth: 140,
                type: 'badge',
                // @ts-ignore this is not typed
                cellRenderer: ({ cellData, rowData }) => <DeviceAgentState device={rowData} />,
            },
            {
                key: 'deviceType',
                dataKey: 'deviceType',
                label: '',
                minWidth: 75,
                maxWidth: 95,
                type: 'icon',
                cellRenderer: ({ rowData }: { rowData: any }) => (
                    <Avatar className="tw-h-10 tw-w-10 tw-bg-slate-100 tw-text-sm tw-font-semibold tw-uppercase tw-text-slate-600">
                        <DeviceTypeIcon
                            device={rowData}
                            className="tw-text-lg"
                        />
                    </Avatar>
                ),
            },
            {
                key: 'lastCheckIn',
                dataKey: 'lastCheckIn',
                label: 'Last Check-in',
                minWidth: 80,
                type: 'timestamp',
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    return rowData.lastCheckIn ? dayjs(rowData.lastCheckIn).fromNow() : NOT_AVAILABLE;
                },
            },
            {
                key: 'agentVersion',
                dataKey: 'agentVersion',
                label: 'Agent Version',
                minWidth: 95,
                type: 'string',
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    const { software, driver } = rowData;
                    return (
                        <WithExperimentalFeatures>
                            <Ellipsis>{rowData.agentVersion || NOT_AVAILABLE}</Ellipsis>
                        </WithExperimentalFeatures>
                    );
                },
            },
            {
                key: 'software.patchCount',
                dataKey: 'software.patchCount', // Use patchCount for sorting.
                secondary: 'driver.patchCount',
                label: 'Updates',
                minWidth: 96,
                maxWidth: 96,
                type: 'badge',
                defaultSortDirection: SORT_DIRECTION.DESC,
                accessDenied: !permissions.canManageSoftwarePatching && !permissions.canManageDrivers,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    const { software, driver } = rowData;
                    return (
                        <div className="tw-flex tw-gap-x-4">
                            {permissions.canManageSoftwarePatching && software && isDefined(software.patchCount) && (
                                /* @ts-ignore - is not typed */
                                <CapaOneLink to={`windows/device/${rowData.id}/updates/software?updater.status.tab=0`}>
                                    <PatchCountBadge
                                        count={software.patchCount}
                                        iconType="capaoneUpdater"
                                        tooltipContent={<div>{pluralize('software update', software.patchCount, true)} available</div>}
                                        upToDateTooltipContent="Software is up-to-date"
                                    />
                                </CapaOneLink>
                            )}
                            {permissions.canManageDrivers && driver && isDefined(driver.patchCount) && (
                                /* @ts-ignore - is not typed */
                                <CapaOneLink to={`windows/device/${rowData.id}/inventory/drivers`}>
                                    <PatchCountBadge
                                        count={driver.patchCount}
                                        iconType="capaDrivers"
                                        tooltipContent={<div>{pluralize('driver update', driver.patchCount, true)} available</div>}
                                        upToDateTooltipContent="Drivers are up-to-date"
                                    />
                                </CapaOneLink>
                            )}
                        </div>
                    );
                },
            },
            {
                key: 'osStartUp',
                dataKey: 'osStartUp', // To pass the OS startup data
                label: '',
                minWidth: 64,
                maxWidth: 64,
                type: 'info',
                cellRenderer: ({ cellData, rowData }: { cellData: any; rowData: any }) => {
                    return (
                        <DeviceDetails
                            device={rowData}
                            osTime={cellData}
                        />
                    );
                },
            },
        ];
        return columns.filter((c) => !c.accessDenied);
    }, []);

    return availableColumns;
};

const PatchCountBadge = ({
    count = null,
    iconType,
    tooltipContent,
    upToDateTooltipContent,
}: {
    count: number | null;
    iconType: string;
    tooltipContent: React.ReactNode;
    upToDateTooltipContent: React.ReactNode;
}) => {
    if (count === 0) {
        return (
            <Tooltip
                position={TOOLTIP.POSITION.LEFT}
                content={upToDateTooltipContent}
            >
                <div>
                    <Badge
                        overlap="circular"
                        small
                        color="secondary"
                        badgeContent={
                            <div className="tw-relative">
                                <Icon
                                    type="checkmark"
                                    className="tw-absolute tw--left-2 tw--top-2 tw-h-4 tw-w-4"
                                />
                            </div>
                        }
                    >
                        <Icon
                            type={iconType as TIconType}
                            color="primary"
                        ></Icon>
                    </Badge>
                </div>
            </Tooltip>
        );
    }
    return (
        <Tooltip
            position={TOOLTIP.POSITION.LEFT}
            content={tooltipContent}
        >
            <div>
                <Badge
                    overlap="circular"
                    badgeContent={count}
                    small
                    color="error"
                >
                    <Icon
                        type={iconType as TIconType}
                        color="primary"
                    ></Icon>
                </Badge>
            </div>
        </Tooltip>
    );
};

const useAndroidEndpointListColumns = (organizations: any, permissions: any, reporting: any) => {
    const [organizationsIdNameObject] = useState(
        organizations.reduce(function (acc: any, cur: any, i: any) {
            acc[cur.id] = cur.name;
            return acc;
        }, {})
    );

    const availableColumns = useMemo(() => {
        const columns = [
            {
                key: 'name',
                dataKey: 'name',
                label: 'Name',
                minWidth: 160,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    if (reporting) {
                        return (
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.androidData?.hardwareInfo?.model}
                            />
                        );
                    }
                    return (
                        // @ts-ignore this is not typed
                        <CapaOneLink to={`android/device/${rowData.id}/dashboard`}>
                            <TwoLineCellRenderer
                                main={rowData.name}
                                secondary={rowData.androidData?.hardwareInfo?.model || NOT_AVAILABLE}
                            />
                        </CapaOneLink>
                    );
                },
            },
            {
                key: 'user',
                dataKey: 'user.name',
                label: 'User',
                minWidth: 160,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    return (
                        // @ts-ignore this is not typed
                        <UserCellRenderer
                            user={rowData.user}
                            viewOnly={true}
                        />
                    );
                },
            },
            {
                key: 'lastCheckIn',
                dataKey: 'androidData.lastStatusReportTime',
                label: 'Last check-in',
                minWidth: 120,
                maxWidth: 160,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ rowData }: { rowData: any }) =>
                    rowData.androidData?.lastStatusReportTime ? dayjs(rowData.androidData?.lastStatusReportTime).fromNow() : 'never',
            },
            {
                key: 'batteryLevel',
                dataKey: 'batteryLevel',
                label: 'Battery',
                minWidth: 96,
                maxWidth: 96,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ cellData }: { cellData: any }) => {
                    return <BatteryLevel percent={cellData ? cellData : null} />;
                },
            },
            {
                key: 'serialNumber',
                dataKey: 'androidData.hardwareInfo.serialNumber',
                label: 'Serial number',
                minWidth: 160,
                maxWidth: 160,
                cellRenderer: ({ rowData }: { rowData: any }) => <Ellipsis>{rowData.androidData?.hardwareInfo?.serialNumber || NOT_AVAILABLE}</Ellipsis>,
            },
            {
                key: 'imei',
                dataKey: 'androidData.networkInfo.imei', // Use patchCount for sorting.
                label: 'IMEI',
                minWidth: 160,
                maxWidth: 160,
                cellRenderer: ({ rowData }: { rowData: any }) => <Ellipsis>{rowData.androidData?.networkInfo?.imei || NOT_AVAILABLE}</Ellipsis>,
            },
            {
                key: 'ownership',
                dataKey: 'androidData.ownership', // To pass the OS startup data
                label: 'Ownership',
                minWidth: 128,
                maxWidth: 128,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    if (rowData.androidData?.ownership === OWNERSHIP.COMPANY_OWNED.id) {
                        return (
                            <Tooltip
                                content="This device is owned by your organization."
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="sky"
                                    noShadow
                                >
                                    {OWNERSHIP.COMPANY_OWNED.name}
                                </TailwindBadge>
                            </Tooltip>
                        );
                    } else if (rowData.androidData?.ownership === OWNERSHIP.PERSONALLY_OWNED.id) {
                        return (
                            <Tooltip
                                content="This device is personally owned by the user."
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="orange"
                                    noShadow
                                >
                                    {OWNERSHIP.PERSONALLY_OWNED.name}
                                </TailwindBadge>
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Tooltip
                                content="The ownership of this device is unspecified."
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="slate"
                                    noShadow
                                >
                                    {OWNERSHIP.OWNERSHIP_UNSPECIFIED.name}
                                </TailwindBadge>
                            </Tooltip>
                        );
                    }
                },
            },
            {
                key: 'membershipMode',
                dataKey: 'androidData.state', // To pass the OS startup data
                label: 'Membership mode',
                minWidth: 128,
                maxWidth: 128,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    if (rowData.androidData?.state === 'LOST') {
                        return (
                            <Tooltip
                                content="This device is currently in Lost Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="red"
                                    noShadow
                                >
                                    Lost Mode
                                </TailwindBadge>
                            </Tooltip>
                        );
                    }
                    const { kioskCustomLauncherEnabled } = rowData.resultingPolicy;
                    if (kioskCustomLauncherEnabled) {
                        return (
                            <Tooltip
                                content="Device is in Kiosk Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="cyan"
                                    className="tw-gap-1"
                                    dark
                                    noShadow
                                >
                                    <Icon
                                        type="kioskMode"
                                        className="tw-text-sm"
                                    />
                                    <span>Kiosk Mode</span>
                                </TailwindBadge>
                            </Tooltip>
                        );
                    } else if (rowData.androidData?.managementMode === 'DEVICE_OWNER') {
                        return (
                            <Tooltip
                                content="Enrolled in Fully Managed Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="indigo"
                                    noShadow
                                >
                                    Fully Managed
                                </TailwindBadge>
                            </Tooltip>
                        );
                    } else if (rowData.androidData?.managementMode === 'PROFILE_OWNER') {
                        return (
                            <Tooltip
                                content="Enrolled in Work Profile Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="purple"
                                    noShadow
                                >
                                    Work Profile
                                </TailwindBadge>
                            </Tooltip>
                        );
                    }
                },
            },
        ];
        //@ts-ignore
        return columns.filter((c) => !c.accessDenied);
    }, []);

    return availableColumns;
};

const useAppleEndpointListColumns = (organizations: any, permissions: any, reporting: any) => {
    const [organizationsIdNameObject] = useState(
        organizations.reduce(function (acc: any, cur: any, i: any) {
            acc[cur.id] = cur.name;
            return acc;
        }, {})
    );

    const availableColumns = useMemo(() => {
        const columns = [
            {
                key: 'name',
                dataKey: 'name',
                label: 'Name',
                minWidth: 160,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    if (reporting) {
                        return (
                            // @ts-ignore this is not typed
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                            />
                        );
                    }
                    return (
                        // @ts-ignore this is not typed
                        <CapaOneLink to={`android/device/${rowData.id}/dashboard`}>
                            <TwoLineCellRenderer
                                main={rowData.name}
                                secondary={rowData.androidData?.hardwareInfo?.model || NOT_AVAILABLE}
                            />
                        </CapaOneLink>
                    );
                },
            },
            {
                key: 'user',
                dataKey: 'user.name',
                label: 'User',
                minWidth: 160,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    return (
                        // @ts-ignore this is not typed
                        <UserCellRenderer
                            user={rowData.user}
                            viewOnly={true}
                        />
                    );
                },
            },
            {
                key: 'deviceType',
                dataKey: 'updatedAt',
                label: 'Last check-in',
                minWidth: 160,
                maxWidth: 240,
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    return rowData.lastCheckin ? <Ellipsis>{dayjs(rowData?.lastCheckin).fromNow()}</Ellipsis> : 'Never';
                },
            },
            {
                key: 'batteryLevel',
                minWidth: 96,
                maxWidth: 96,
                dataKey: 'data.BatteryLevel',
                label: 'Battery',
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    const batteryPercentage = rowData.data.BatteryLevel * 100;
                    /* @ts-ignore  */
                    return <BatteryLevel percent={batteryPercentage < 0 ? null : batteryPercentage} />;
                },
            },
            {
                key: 'serialNumber',
                minWidth: 136,
                maxWidth: 160,
                dataKey: 'serial_number',
                label: 'Serial Number',
            },
            {
                key: 'IMEI',
                minWidth: 160,
                maxWidth: 160,
                dataKey: 'IMEI',
                label: 'IMEI',
                cellRenderer: ({ rowData }: { rowData: any }) => (
                    <Ellipsis className={rowData.data?.IMEI ? '' : 'tw-text-gray-300'}>{rowData.data?.IMEI || NOT_AVAILABLE}</Ellipsis>
                ),
            },
            {
                key: 'managementMode',
                minWidth: 128,
                maxWidth: 128,
                dataKey: '',
                label: 'Management Mode',
                cellRenderer: ({ rowData }: { rowData: any }) => {
                    if (rowData?.isKioskMode) {
                        return (
                            <Tooltip
                                content="Device is in Kiosk Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="cyan"
                                    className="tw-gap-1"
                                    dark
                                    noShadow
                                >
                                    <Icon
                                        type="kioskMode"
                                        className="tw-text-sm"
                                    />
                                    <span>Kiosk Mode</span>
                                </TailwindBadge>
                            </Tooltip>
                        );
                    } else if (rowData.data?.IsSupervised) {
                        return (
                            <Tooltip
                                content="This device is currently in Supervised Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="indigo"
                                    noShadow
                                >
                                    Supervised
                                </TailwindBadge>
                            </Tooltip>
                        );
                    } else if (!rowData.data?.IsSupervised) {
                        return (
                            <Tooltip
                                content="This device is currently in Unsupervised Mode"
                                position={TOOLTIP.POSITION.TOP_END}
                            >
                                {/* @ts-ignore  */}
                                <TailwindBadge
                                    size="small"
                                    color="purple"
                                    noShadow
                                >
                                    Unsupervised
                                </TailwindBadge>
                            </Tooltip>
                        );
                    }
                },
            },
        ];
        //@ts-ignore
        return columns.filter((c) => !c.accessDenied);
    }, []);

    return availableColumns;
};

export { useAndroidEndpointListColumns, useAppleEndpointListColumns, useEndpointSoftwareInventoryColumns, useWindowsEndpointListColumns };
