import { BUTTON } from '@capasystems/constants';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from '../drag-and-drop-provider/drag-and-drop-provider';
import Draggable from '../drag-and-drop-provider/draggable';
import DropZone from '../drag-and-drop-provider/drop-zone';
import IconButton from '../icon-button/icon-button';
import { Icon } from '../icon/icon';
import Menu from '../menu/menu';
import Tree from '../tree/tree';

const iconSize = 24;

const DraggableTreeNode = React.memo((props) => {
    const {
        isLeaf = false,
        onExpand,
        expanded = false,
        selectable,
        onSelect,
        selected,
        description,
        content,
        children,
        group,
        nodeType,
        draggable,
        dropTypes,
        id,
        onDrop,
        childCount,
        editable,
        menuContent,
        selectedColor,
        acceptedColor,
        onConfigClick,
        isInfo,
        searchItem,
        onMenuOpen,
        onMenuClose,
    } = props;

    /* istanbul ignore next */
    const [isSearchItem, setSearchItem] = useState(searchItem);
    /* istanbul ignore next */
    useEffect(() => {}, [isSearchItem]);
    /* istanbul ignore next */
    if (isSearchItem) {
        setTimeout(() => {
            setSearchItem(false);
        }, 1200);
    }
    /* istanbul ignore next */
    const [menu, setMenu] = useState({
        isOpen: false,
        anchorEl: null,
    });

    const openMenu = (event) => {
        onMenuOpen();
        setMenu({
            isOpen: true,
            anchorEl: event.currentTarget,
        });
    };

    const onClose = () => {
        onMenuClose();
        setMenu({
            isOpen: false,
            anchorEl: null,
        });
    };

    /* istanbul ignore next */
    const dragDropContext = useContext(DragDropContext);

    // eslint-disable-next-line prefer-const
    let data = { ...props };

    /* istanbul ignore next */
    return (
        <li className="cs-draggable-tree-node">
            <div className="cs-draggable-tree-node-content">
                {childCount > 0 && (
                    <IconButton
                        disabled={isLeaf}
                        onClick={() => onExpand(props)}
                        color={BUTTON.INHERIT}
                        noMargin
                    >
                        {expanded ? (
                            <Icon
                                type="chevronDown"
                                size="small"
                            />
                        ) : (
                            <Icon
                                type="chevronRight"
                                size="small"
                            />
                        )}
                    </IconButton>
                )}
                {draggable ? (
                    <DropZone
                        acceptedTypes={dropTypes}
                        data={data}
                        group={group}
                        onDrop={onDrop}
                        id={id}
                        isSearch={isSearchItem}
                        style={{ marginLeft: childCount > 0 ? null : iconSize }}
                        acceptedColor={acceptedColor}
                        shouldBlowUp
                    >
                        <Draggable
                            group={group}
                            itemType={nodeType}
                            data={data}
                            style={{ padding: '0 8px', display: 'inline-block', borderRadius: '0.4em' }}
                            id={id}
                            selectedColor={selectedColor}
                        >
                            <b style={{ verticalAlign: 'middle' }}>{content}</b>
                        </Draggable>
                    </DropZone>
                ) : (
                    <DropZone
                        acceptedTypes={dropTypes}
                        data={data}
                        group={group}
                        onDrop={onDrop}
                        id={id}
                        isSearch={isSearchItem}
                        style={{ marginLeft: childCount > 0 ? null : iconSize }}
                        acceptedColor={acceptedColor}
                        shouldBlowUp
                    >
                        <b style={{ verticalAlign: 'middle' }}>{content}</b>
                    </DropZone>
                )}
                {description && <span style={{ verticalAlign: 'middle', marginLeft: 8, fontWeight: 200 }}>{description}</span>}
                {(editable || (draggable && menuContent)) && (
                    <IconButton
                        onClick={onConfigClick || openMenu}
                        className={classNames('cs-draggable-tree-node-actions-button', menu.isOpen ? 'cs-draggable-tree-node-actions-button-active' : null)}
                        color={BUTTON.PRIMARY}
                        noMargin
                    >
                        {isInfo ? (
                            <Icon
                                type="info"
                                color={BUTTON.PRIMARY}
                                size="small"
                            />
                        ) : (
                            <Icon
                                type="configure"
                                color={BUTTON.PRIMARY}
                                size="small"
                            />
                        )}
                    </IconButton>
                )}
                <Menu
                    anchorEl={menu.anchorEl}
                    open={menu.isOpen}
                    onClose={onClose}
                    onClick={onClose}
                >
                    {dragDropContext.supportMoveSelectedFromMenu && dragDropContext.checkContainAcceptedItems(data) && (
                        <MenuItem
                            onClick={() => {
                                onDrop(data, dragDropContext.selected);
                            }}
                        >
                            Move selected items to {data.shortName}
                        </MenuItem>
                    )}
                    {dragDropContext.supportMoveSelectedFromMenu && data.id !== data.group && (
                        <MenuItem
                            onClick={() => {
                                onDrop({ id: 'root' }, [data]);
                            }}
                        >
                            Remove from group
                        </MenuItem>
                    )}
                    {menuContent && menuContent.map((item) => item)}
                </Menu>
            </div>
            {children && (
                <Collapse in={expanded}>
                    <Tree>{children}</Tree>
                </Collapse>
            )}
        </li>
    );
});

DraggableTreeNode.propTypes = {
    /** The entire treeNode */
    dataRef: PropTypes.object.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    expanded: PropTypes.bool,
    isLeaf: PropTypes.bool,
    onExpand: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func.isRequired,
    onMenuClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
};

export default DraggableTreeNode;
