import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, LayoutCentered, LayoutRow, Loading, Tooltip } from '@capasystems/ui';
import { noop } from '@capasystems/utils';
import { windowsApplication } from '@thirdparty/constants';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from '../../hooks/useApi/useApi';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import { WidgetPaper } from '../../thirdparty-components/thirdparty-components';

const WindowsApplicationPicker = ({ type, showApplicationList }) => {
    const api = useApi();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showApplicationPicker, setShowApplicationPicker] = useState(true);

    useEffect(() => {
        if (showApplicationPicker) {
            if (type === windowsApplication.type.capaPacks.id) {
                api.getPackagesWithReleasedVersion()
                    .then((content) => {
                        setApplications(content.sort((a, b) => (a.application > b.application ? 1 : -1)));
                    })
                    .catch(noop)
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }, [showApplicationPicker]);

    const openApplicationPicker = () => {
        setShowApplicationPicker(true);
    };

    const closeApplicationPicker = () => {
        showApplicationList(false);
        setShowApplicationPicker(false);
    };

    const memoizedApplicationList = useMemo(() => {
        return applications.filter((a) => a.application.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }, [applications, searchTerm]);

    return (
        <Dialog
            open={showApplicationPicker}
            onClose={closeApplicationPicker}
            PaperProps={{ style: { minWidth: '80vw', height: '80vh' } }}
        >
            <DialogTitle>
                <LayoutRow align="space-between">
                    <div>
                        <span className="tw-align-middle">Available CapaPacks</span>
                        <Input
                            value={searchTerm}
                            placeholder="Search"
                            fullWidth
                            callToAction
                            light
                            className="tw-ml-4 tw-w-40 lg:tw-w-60"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            containerClassName="tw-py-1 tw-text-sm tw-h-10"
                            autoFocus
                        ></Input>
                    </div>
                </LayoutRow>
            </DialogTitle>
            <DialogContent className="tw-w-full tw-bg-slate-100 tw-p-2">
                {loading ? (
                    <LayoutCentered>
                        <Loading></Loading>
                    </LayoutCentered>
                ) : (
                    <div className="tw-p-4">
                        <Grid
                            container
                            spacing={2}
                        >
                            {memoizedApplicationList.map((application) => (
                                <Tooltip
                                    content={application.description}
                                    key={application.id}
                                >
                                    <div className="tw-p-2">
                                        <WidgetPaper
                                            headerless
                                            className="tw-h-40 tw-w-40 tw-cursor-pointer hover:tw-bg-slate-300"
                                            onClick={() => {
                                                navigate.to(`windows/application/new/repositoryapp?packageId=${application.applicationId}`);
                                            }}
                                        >
                                            <div className="tw-flex tw-content-center tw-justify-center tw-pb-4 tw-pt-8">
                                                <Avatar
                                                    src={application.thumbnail}
                                                    alt={application.application}
                                                    variant="rounded"
                                                    className="tw-h-14 tw-w-14"
                                                />
                                            </div>
                                            <p
                                                className="tw-pl-2 tw-pr-2 tw-font-semibold"
                                                align="center"
                                            >
                                                {application.application}
                                            </p>
                                        </WidgetPaper>
                                    </div>
                                </Tooltip>
                            ))}
                        </Grid>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeApplicationPicker}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export { WindowsApplicationPicker };
