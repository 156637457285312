import { LAYOUT } from '@capasystems/constants';
import React from 'react';
import { LayoutCore, LayoutCoreProps } from '../layout-core/layout-core';
import './layout-column.scss';

export type LayoutColumnProps = LayoutCoreProps & {
    width?: number | string;
    children: React.ReactNode;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    tabIndex?: number;
};

export const LayoutColumn: React.FC<LayoutColumnProps> = ({ style, width, verticalAlign = 'stretch', fill = false, scrollContent = false, ...otherProps }) => {
    let styles: any = {};
    /* istanbul ignore next */
    if (width) {
        styles = LAYOUT.WIDTH;
        styles.maxWidth = `${width}%`;
    }
    return (
        <LayoutCore
            direction="column"
            style={{ ...styles, ...style }}
            verticalAlign={verticalAlign}
            fill={fill}
            scrollContent={scrollContent}
            {...otherProps}
        />
    );
};

export default LayoutColumn;
