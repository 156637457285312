export const SkipKeysElement = [
    {
        id: 'Accessibility',
        name: 'Accessibility',
    },
    {
        id: 'Android',
        name: 'Android',
    },
    {
        id: 'Appearance',
        name: 'Appearance',
    },
    {
        id: 'AppleID',
        name: 'AppleID',
    },
    {
        id: 'AppStore',
        name: 'AppStore',
    },
    {
        id: 'Biometric',
        name: 'Biometric',
    },
    {
        id: 'DeviceToDeviceMigration',
        name: 'DeviceToDeviceMigration',
    },
    {
        id: 'Diagnostics',
        name: 'Diagnostics',
    },
    {
        id: 'EnableLockdownMode',
        name: 'EnableLockdownMode',
    },
    {
        id: 'FileVault',
        name: 'FileVault',
    },
    {
        id: 'iCloudDiagnostics',
        name: 'iCloudDiagnostics',
    },
    {
        id: 'iCloudStorage',
        name: 'iCloudStorage',
    },
    {
        id: 'iMessageAndFaceTime',
        name: 'iMessageAndFaceTime',
    },
    {
        id: 'Location',
        name: 'Location',
    },
    {
        id: 'MessagingActivationUsingPhoneNumber',
        name: 'MessagingActivationUsingPhoneNumber',
    },
    {
        id: 'Passcode',
        name: 'Passcode',
    },
    {
        id: 'Payment',
        name: 'Payment',
    },
    {
        id: 'Privacy',
        name: 'Privacy',
    },
    {
        id: 'Restore',
        name: 'Restore',
    },
    {
        id: 'RestoreCompleted',
        name: 'RestoreCompleted',
    },
    {
        id: 'Safety',
        name: 'Safety',
    },
    {
        id: 'ScreenSaver',
        name: 'ScreenSaver',
    },
    {
        id: 'ScreenTime',
        name: 'ScreenTime',
    },
    {
        id: 'SIMSetup',
        name: 'SIMSetup',
    },
    {
        id: 'Siri',
        name: 'Siri',
    },
    {
        id: 'SoftwareUpdate',
        name: 'SoftwareUpdate',
    },
    {
        id: 'TapToSetup',
        name: 'TapToSetup',
    },
    {
        id: 'TermsOfAddress',
        name: 'TermsOfAddress',
    },
    {
        id: 'TOS',
        name: 'TOS',
    },
    {
        id: 'TVHomeScreenSync',
        name: 'TVHomeScreenSync',
    },
    {
        id: 'TVProviderSignIn',
        name: 'TVProviderSignIn',
    },
    {
        id: 'TVRoom',
        name: 'TVRoom',
    },
    {
        id: 'UpdateCompleted',
        name: 'UpdateCompleted',
    },
    {
        id: 'WatchMigration',
        name: 'WatchMigration',
    },
    {
        id: 'Welcome',
        name: 'Welcome',
    },
    {
        id: 'Wallpaper',
        name: 'Wallpaper',
    },
];
