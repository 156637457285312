import { coreMessage, KEY_CODE } from '@capasystems/constants';
import { arraysAreEqual, getTheme, isDefined, Url } from '@capasystems/utils';
import { Fade } from '@mui/material';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Button } from '../button/button';
import ContextDialog from '../context-dialog/context-dialog';
import Ellipsis from '../ellipsis/ellipsis';
import { Icon } from '../icon/icon';
import Input from '../input/input';
import { LayoutColumn } from '../layout-column/layout-column';
import { LayoutFill } from '../layout-fill/layout-fill';
import { LayoutRow } from '../layout-row/layout-row';
import { ListItem } from '../list/list-item';
import { ListItemText } from '../list/list-item-text';
import Loading from '../loading/loading';
import Toolbar from '../toolbar/toolbar';
import './select.scss';

const {
    capasystems: { borderColor, spacing },
} = getTheme();
const secondaryActionStyles = { right: spacing * 2 };
const ROW_HEIGHT = 40;
const classNamesObject: Record<string, string> = {
    true: 'cs-select cs-select-multiple',
    false: 'cs-select cs-select-single',
    input: 'cs-select cs-select-input',
    active: 'cs-select-list-item-active',
};

const virtuosoStyles = {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
};

export type SelectProps = {
    /** Label for the input field */
    label?: string;
    /** Placeholder for the input field */
    placeholder?: string;
    /** Should the input field be full width */
    fullWidth?: boolean;
    /** Is the input field disabled */
    disabled?: boolean;
    /** Selected options */
    selectedOptions: { id: string | number; name: string; secondaryContent?: React.ReactNode; disabled?: boolean }[];
    /** Called when user selects or unselects */
    onChange: (selectedOptions: { id: string | number; name: string; secondaryContent?: React.ReactNode; disabled?: boolean }[]) => void;
    /** Called when user has scrolled close to bottom */
    onScrolledToBottom?: () => void;
    /** Called when user searches */
    onSearch?: (searchTerm: string) => void;
    /** Called when user opens the select */
    onOpen?: () => void;
    /** Called when user closes the select */
    onClose?: (args: { isDirty: boolean }) => void;
    /** Called when the select has been closed */
    onExited?: () => void;
    /** Multiple select */
    multiple?: boolean;
    /** Options for user to select */
    options: { id: string | number; name: string; secondaryContent?: React.ReactNode; disabled?: boolean }[];
    /** Search term */
    searchTerm?: string;
    /** Loading state */
    loading?: boolean;
    /** Placeholder for search input */
    searchPlaceholder?: string;
    /** No search results */
    noSearchResults?: boolean;
    /** Show backdrop */
    visibleBackdrop?: boolean;
    /** Minimum column width */
    minColumnWidth?: number;
    /** Update URL */
    updateUrl?: boolean;
    /** Name for URL update */
    name?: string;
    /** Required */
    required?: boolean;
    /** Visible rows */
    visibleRows?: number;
    /** Helper text */
    helperText?: React.ReactNode;
    /** Class name */
    className?: string;
    /** Container class name */
    containerClassName?: string;
    /** Input class name */
    inputClassName?: string;
    /** Multiline textfield */
    multiline?: boolean;
    /** Rows for multiline textfield */
    rows?: number;
    /** Maximum rows for multiline textfield */
    rowsMax?: number;
    /** Call to action */
    callToAction?: boolean;
    /** Light */
    light?: boolean;
    /** Naked */
    naked?: boolean;
    /** Style */
    style?: React.CSSProperties;
    /** Disable label ellipsis */
    disableLabelEllipsis?: boolean;
    /** Instant change */
    instantChange?: boolean;
    /** Start adornment */
    startAdornment?: React.ReactNode;
    /** End adornment */
    endAdornment?: React.ReactNode;
    /** Primary content class name */
    primaryContentClassName?: string;
    /** Secondary content class name */
    secondaryContentClassName?: string;
};

export const Select: React.FC<SelectProps> = React.memo(
    ({
        label = '',
        placeholder = '',
        fullWidth = true,
        disabled = false,
        selectedOptions,
        onOpen = () => null,
        onClose = () => null,
        onExited = () => null,
        multiple = false,
        options,
        onChange,
        searchTerm = null,
        onSearch = () => null,
        loading = false,
        searchPlaceholder = '',
        onScrolledToBottom = () => null,
        noSearchResults = false,
        visibleBackdrop = true,
        minColumnWidth = 240,
        updateUrl = false,
        name = '',
        required = false,
        visibleRows = 10,
        helperText = null,
        className,
        containerClassName,
        inputClassName,
        multiline = false,
        rows = 5,
        rowsMax = 5,
        callToAction = false,
        light = false,
        naked = false,
        style,
        disableLabelEllipsis,
        instantChange = false,
        startAdornment,
        endAdornment,
        primaryContentClassName,
        secondaryContentClassName,
    }) => {
        const firstUpdate = useRef(true);
        const previousScrollHeightRef = useRef(0);
        const scrollToIndex = useRef(0);
        const stateRef = useRef<{
            selectedIdsOnOpen: (string | number)[];
        }>({
            selectedIdsOnOpen: [],
        });
        const [contextDialogState, setContextDialogState] = useState<{
            anchorEl: HTMLElement | null;
            isOpen: boolean;
            currentDetailIndex: number;
            paperProps: { style: { width?: number; minWidth?: number } };
        }>({
            anchorEl: null,
            isOpen: false,
            currentDetailIndex: 0,
            paperProps: {
                style: {
                    width: undefined,
                    minWidth: undefined,
                },
            },
        });

        const [selected, setSelected] = useState({
            ids: selectedOptions.map(({ id }) => id),
            names: selectedOptions.map(({ name }) => name).join(', '),
            namesMultiline: selectedOptions.map(({ name }) => name).join('\n'),
        });

        const [visibleOptions, setVisibleOptions] = useState<SelectProps['options']>([]);
        const [arrowSelection, setArrowSelection] = useState(-1);
        const [arrowHover, setArrowHover] = useState<{ ids: (string | number)[]; names: string }>({ ids: [], names: '' });
        const virtuosoRef = useRef(null);

        /* istanbul ignore next */
        useEffect(() => {
            if (options.length >= 1 && arrowSelection !== -1) {
                setArrowHover({
                    ids: [options[arrowSelection].id] as (string | number)[],
                    names: options[arrowSelection].name,
                });
            }
        }, [arrowSelection]);

        /* istanbul ignore next */
        useEffect(() => {
            const { isOpen } = contextDialogState;
            const selectedIds = selectedOptions.map((e) => e.id);
            setSelected({
                ids: selectedIds,
                names: selectedOptions.map((e) => e.name).join(', '),
                namesMultiline: selectedOptions.map((e) => e.name).join('\n'),
            });
            if (multiple) {
                const vo = options.filter((option) => selectedIds.indexOf(option.id) < 0);
                setVisibleOptions(vo);
                if (isOpen && options.length > visibleRows && vo.length <= visibleRows) {
                    // Potentially lazy load more options.
                    onScrolledToBottom();
                }
            }
            if (firstUpdate.current) {
                firstUpdate.current = false;
            } else {
                if (updateUrl && name) {
                    Url.set(name, selectedIds as string[]);
                }
                if (isOpen && !multiple && selectedIds.length > 0) {
                    // eslint-disable-next-line no-use-before-define
                    handleClose();
                }
            }
        }, [selectedOptions]);

        /* istanbul ignore next */
        useEffect(() => {
            const { isOpen } = contextDialogState;
            if (multiple) {
                const selectedIds = selectedOptions.map((e) => e.id);
                const vo = options.filter((option) => selectedIds.indexOf(option.id) < 0);
                setVisibleOptions(vo);
                if (isOpen && options.length > visibleRows && vo.length <= visibleRows) {
                    // Potentially lazy load more options.
                    onScrolledToBottom();
                }
            }
        }, [options]);

        /* istanbul ignore next */
        const handleOpen: (event: React.MouseEvent<HTMLInputElement>) => void = ({ currentTarget }) => {
            if (!disabled) {
                stateRef.current.selectedIdsOnOpen = selected.ids;
                if (multiple) {
                    if (options.length > visibleRows && visibleOptions.length <= visibleRows) {
                        // Potentially lazy load more options.
                        onScrolledToBottom();
                    }
                } else if (selectedOptions.length > 0) {
                    const [selectedOption] = selectedOptions;
                    const indexOfSelectedId = options.findIndex((option) => option.id === selectedOption.id);
                    scrollToIndex.current = indexOfSelectedId > 0 ? indexOfSelectedId : 0;
                }
                setContextDialogState({
                    anchorEl: currentTarget,
                    currentDetailIndex: 0,
                    isOpen: true,
                    paperProps: {
                        style: {
                            width: currentTarget.parentElement?.clientWidth,
                            minWidth: multiple ? Math.min(minColumnWidth * 2, window.innerWidth - 32) : minColumnWidth, // Using Math.min to prevent overflowing the window on small devices.
                        },
                    },
                });
                onOpen();
            }
        };

        /* istanbul ignore next */
        const handleClose = () => {
            const { paperProps } = contextDialogState;
            setContextDialogState({
                anchorEl: null,
                currentDetailIndex: 0,
                isOpen: false,
                paperProps,
            });
            setArrowSelection(-1);
            setArrowHover({ ids: [], names: '' });
            const isDirty = !arraysAreEqual(
                selectedOptions.map((e) => e.id),
                stateRef.current.selectedIdsOnOpen
            );
            onClose({ isDirty });
        };

        /* istanbul ignore next */
        const handleSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ target }) => {
            onSearch(target.value);
            setArrowSelection(-1);
            setArrowHover({ ids: [], names: '' });
        };

        /* istanbul ignore next */
        const onDeselect: (index: number) => () => void = (index) => () => {
            const selectedOptionsClone = selectedOptions.slice(0);
            selectedOptionsClone.splice(index, 1);
            onChange(selectedOptionsClone);
        };

        /* istanbul ignore next */
        const clearSelectedOptions = () => {
            onChange([]);
        };

        /* istanbul ignore next */
        const selectAllVisibleOptions = () => {
            previousScrollHeightRef.current = 0;
            const selectedOptionsClone = selectedOptions.slice(0);
            options.forEach((option) => {
                const isSelected = selected.ids.indexOf(option.id) > -1;
                if (!isSelected) {
                    selectedOptionsClone.push(option);
                }
            });
            onChange(selectedOptionsClone);
        };

        /* istanbul ignore next */
        const onSelect: (newOption: SelectProps['options'][0]) => () => void = (newOption) => () => {
            const newOptions = [];
            if (multiple) {
                const alreadySelected = selectedOptions.some((option) => {
                    if (option.id === newOption.id) {
                        return true;
                    }
                    newOptions.push(option);
                    return false;
                });
                if (!alreadySelected) {
                    newOptions.push(newOption);
                    newOptions.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                    onChange(newOptions);
                }
            } else {
                const alreadySelected = selectedOptions.some((option) => option.id === newOption.id);
                if (!alreadySelected) {
                    newOptions.push(newOption);
                    onChange(newOptions);
                } else {
                    handleClose();
                }
            }
        };

        /* istanbul ignore next */
        const checkIfAlreadySelected = () => {
            for (let i = arrowSelection + 1; i < options.length; i += 1) {
                if (!selectedOptions.find((el) => el.name === options[i].name)) {
                    setArrowSelection(i);
                    break;
                }
            }
        };

        /* istanbul ignore next */
        const onKeyPressed = (e: any) => {
            if (e.keyCode === KEY_CODE.ARROW_UP && arrowSelection > 0) {
                for (let i = arrowSelection - 1; i >= 0; i -= 1) {
                    if (!selectedOptions.find((el) => el.name === options[i].name)) {
                        setArrowSelection(i);
                        break;
                    }
                }
            } else if (e.keyCode === KEY_CODE.ARROW_DOWN && arrowSelection !== options.length - 1) {
                checkIfAlreadySelected();
            } else if (e.keyCode === KEY_CODE.ENTER && arrowSelection >= 0) {
                checkIfAlreadySelected();
                onSelect(options[arrowSelection])();
            }
        };

        /* istanbul ignore next */
        return (
            <React.Fragment>
                <Input
                    onClick={handleOpen}
                    label={label}
                    placeholder={placeholder}
                    readOnly
                    value={multiline ? selected.namesMultiline : selected.names}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    helperText={helperText}
                    endAdornment={
                        isDefined(endAdornment) ? (
                            endAdornment
                        ) : (
                            <Icon
                                type="unfold"
                                size="small"
                            />
                        )
                    }
                    className={classNames(classNamesObject.input, className)}
                    multiline={multiline}
                    rows={rows}
                    rowsMax={rowsMax}
                    callToAction={callToAction}
                    light={light}
                    naked={naked}
                    style={style}
                    disableLabelEllipsis={disableLabelEllipsis}
                    startAdornment={startAdornment}
                    containerClassName={classNames({ 'tw-cursor-pointer': !disabled }, containerClassName)}
                    inputClassName={inputClassName}
                />
                <ContextDialog
                    visibleBackdrop={visibleBackdrop}
                    onClose={handleClose}
                    open={contextDialogState.isOpen}
                    anchorEl={contextDialogState.anchorEl}
                    paperProps={contextDialogState.paperProps}
                    className={classNamesObject[multiple ? 'true' : 'false']}
                    onExited={onExited}
                    TransitionComponent={Fade} // Use Fade component to prevent https://github.com/petyosi/react-virtuoso/issues/1082 and https://github.com/petyosi/react-virtuoso/issues/542.
                >
                    {multiple ? (
                        <LayoutColumn
                            onKeyDown={onKeyPressed}
                            tabIndex={0}
                        >
                            <LayoutFill>
                                {searchTerm !== null && (
                                    <div className="tw-p-2 tw-shadow-sm">
                                        <Input
                                            placeholder={searchPlaceholder}
                                            startAdornment={
                                                loading ? (
                                                    <Loading size={15} />
                                                ) : (
                                                    <Icon
                                                        type="search"
                                                        size="small"
                                                    />
                                                )
                                            }
                                            type={loading ? 'text' : 'search'}
                                            value={searchTerm}
                                            onChange={handleSearch}
                                            disablePointerEvents
                                            autoFocus
                                            error={noSearchResults}
                                            naked
                                            callToAction
                                        />
                                    </div>
                                )}
                                <LayoutRow
                                    style={{
                                        // eslint-disable-next-line max-len
                                        height: Math.min(
                                            visibleRows * ROW_HEIGHT,
                                            Math.max(visibleOptions.length * ROW_HEIGHT, selectedOptions.length * ROW_HEIGHT)
                                        ),
                                    }}
                                >
                                    <LayoutColumn
                                        scrollContent
                                        width={50}
                                    >
                                        <Virtuoso
                                            style={{
                                                ...virtuosoStyles,
                                                borderRight: `1px solid ${borderColor}`,
                                            }}
                                            data={visibleOptions}
                                            endReached={onScrolledToBottom}
                                            itemContent={(optionIndex, option) => {
                                                return (
                                                    <ListItem
                                                        ContainerComponent="div"
                                                        key={optionIndex}
                                                        onClick={onSelect(option)}
                                                        className={arrowHover.ids.indexOf(option.id) >= 0 ? classNamesObject.active : ''}
                                                    >
                                                        <ListItemText
                                                            classes={{ primary: primaryContentClassName }}
                                                            primary={<Ellipsis>{option.name}</Ellipsis>}
                                                        />
                                                        <ListItemSecondaryAction
                                                            style={secondaryActionStyles}
                                                            className={secondaryContentClassName}
                                                        >
                                                            {option.secondaryContent}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            }}
                                        />
                                    </LayoutColumn>
                                    <LayoutColumn
                                        scrollContent
                                        width={50}
                                    >
                                        <Virtuoso
                                            style={virtuosoStyles}
                                            data={selectedOptions}
                                            itemContent={(optionIndex, option) => {
                                                return (
                                                    <ListItem
                                                        ContainerComponent="div"
                                                        key={optionIndex}
                                                        onClick={onDeselect(optionIndex)}
                                                    >
                                                        <Icon
                                                            type="checkmark"
                                                            size="small"
                                                            className="cs-select-multiple-checkmark"
                                                        />
                                                        <ListItemText
                                                            classes={{ primary: primaryContentClassName }}
                                                            primary={<Ellipsis>{option.name}</Ellipsis>}
                                                        />
                                                        <ListItemSecondaryAction
                                                            style={secondaryActionStyles}
                                                            className={secondaryContentClassName}
                                                        >
                                                            {option.secondaryContent}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            }}
                                        />
                                    </LayoutColumn>
                                </LayoutRow>
                            </LayoutFill>
                            <div className="tw-h-px tw-bg-gray-100" />
                            <Toolbar compact>
                                <Button
                                    onClick={selectAllVisibleOptions}
                                    disabled={visibleOptions.length === 0}
                                    size="small"
                                >
                                    {coreMessage.select}
                                    &nbsp;({visibleOptions.length})
                                </Button>
                                <Button
                                    onClick={clearSelectedOptions}
                                    disabled={selectedOptions.length === 0}
                                    size="small"
                                >
                                    {coreMessage.deselect}
                                    &nbsp;({selectedOptions.length})
                                </Button>
                                <LayoutFill />
                                <Button
                                    noMargin
                                    onClick={handleClose}
                                    size="small"
                                >
                                    {instantChange ? coreMessage.close : coreMessage.apply}
                                </Button>
                            </Toolbar>
                        </LayoutColumn>
                    ) : (
                        <LayoutColumn
                            onKeyDown={onKeyPressed}
                            tabIndex={0}
                        >
                            {searchTerm !== null && (
                                <div className="tw-p-2 tw-shadow-sm">
                                    <Input
                                        placeholder={searchPlaceholder}
                                        startAdornment={
                                            loading ? (
                                                <Loading size={16} />
                                            ) : (
                                                <Icon
                                                    type="search"
                                                    size="small"
                                                />
                                            )
                                        }
                                        type={loading ? 'text' : 'search'}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        disablePointerEvents
                                        error={noSearchResults}
                                        autoFocus
                                        naked
                                        callToAction
                                    />
                                </div>
                            )}
                            <div style={{ height: Math.min(visibleRows * ROW_HEIGHT, options.length * ROW_HEIGHT) }}>
                                <Virtuoso
                                    ref={virtuosoRef}
                                    style={virtuosoStyles}
                                    data={options}
                                    initialTopMostItemIndex={scrollToIndex.current}
                                    endReached={onScrolledToBottom}
                                    itemContent={(optionIndex, option) => {
                                        return (
                                            <ListItem
                                                component="div"
                                                key={optionIndex}
                                                onClick={onSelect(option)}
                                                autoFocus={optionIndex === 2}
                                                // selected={selected.ids.indexOf(option.id) >= 0}
                                                className={arrowHover.ids.indexOf(option.id) >= 0 ? classNamesObject.active : ''}
                                                // disabled={option.disabled}
                                                secondaryAction={option.secondaryContent}
                                            >
                                                <div className="tw-grid tw-w-full tw-grid-cols-auto-1fr">
                                                    <span className="tw-w-[30px]">
                                                        {selected.ids.indexOf(option.id) >= 0 && (
                                                            <Icon
                                                                type="checkmark"
                                                                size="small"
                                                            />
                                                        )}
                                                    </span>
                                                    <ListItemText
                                                        classes={{ primary: primaryContentClassName }}
                                                        primary={<Ellipsis>{option.name}</Ellipsis>}
                                                    />
                                                </div>
                                            </ListItem>
                                        );
                                    }}
                                />
                            </div>
                            {!required && (
                                <div
                                    className="tw-px-2 tw-py-1"
                                    style={{ borderTop: `1px solid ${borderColor}` }}
                                >
                                    <Button
                                        noMargin
                                        fullWidth
                                        onClick={clearSelectedOptions}
                                        disabled={selectedOptions.length === 0}
                                        size="small"
                                    >
                                        {coreMessage.deselect}
                                    </Button>
                                </div>
                            )}
                        </LayoutColumn>
                    )}
                </ContextDialog>
            </React.Fragment>
        );
    }
);

export default Select;
