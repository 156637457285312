//Documentation: ExhangeActiveSync
//https://developer.apple.com/documentation/devicemanagement/exchangeactivesync

export const AppleExchangeActiveSyncConfiguration = {
    schema: {
        schemaId: 'appleExchangeActiveSyncConfiguration',
        category: 'Mail',
        title: 'Exchange ActiveSync',
        description: 'The payload you use to configure Exchange ActiveSync accounts.',
        skipIndent: true,
        type: 'object',
        properties: {
            'com.apple.eas.account': {
                title: '',
                type: 'object',
                properties: {
                    UserName: {
                        title: 'User Name',
                        description: 'This user name for this Exchange account.',
                        required: true,
                        help: 'If not present in the payload, the device prompts for this string during profile installation.',
                        type: 'string',
                    },
                    Password: {
                        title: 'Password',
                        description: 'The password of the account.',
                        help: 'Use only with encrypted profiles.',
                        type: 'string',
                    },
                    Host: {
                        title: 'Host',
                        description: 'The Exchange server host name or IP address.',
                        help: 'If you are using OAuth without an OAuthSignInURL, the host name is ignored.',
                        type: 'string',
                    },
                    EmailAddress: {
                        title: 'Email Address',
                        description: 'The full email address for the account.',
                        help: 'If not present in the payload, the device prompts for this string during profile installation.',
                        type: 'string',
                    },
                    MailNumberOfPastDaysToSync: {
                        title: 'Number Of Past Days To Sync',
                        description: 'The number of days in the past to sync mail on the device. \nPossible values: 0, 1, 3, 7, 14, and 31.',
                        help: 'For no limit, use the value 0.',
                        type: 'number',
                    },
                    OAuth: {
                        title: 'OAuth',
                        type: 'boolean',
                        description: 'If true, enables OAuth for authentication.',
                        help: 'If enabled, don’t specify a password. Available only in iOS 12.0 and above.',
                    },
                    OAuthSignInURL: {
                        title: 'OAuth Sign In URL',
                        description: 'The URL that this account should use for signing in through OAuth.',
                        help: 'If you specify this URL, auto-discovery isn’t used for this account, so you need to also specify a host.',
                        shouldRender: (siblings: any) => {
                            if (siblings.OAuth) {
                                return true;
                            }
                            delete siblings.OAuthSignInURL;
                            return false;
                        },
                        type: 'string',
                    },
                    OAuthTokenRequestURL: {
                        title: 'OAuth Token Request URL',
                        description: 'The URL that this account should use for token requests through OAuth.',
                        shouldRender: (siblings: any) => {
                            if (siblings.OAuth) {
                                return true;
                            }
                            delete siblings.OAuthTokenRequestURL;
                            return false;
                        },
                        type: 'string',
                    },
                    EnableCalendars: {
                        title: 'Enable Calendars',
                        description:
                            'If false, the system disables the Calendars service for this account. The user can reenable Calendars service in Settings unless EnableCalendarsUserOverridable is false.',
                        help: 'At least of the following fields needs to be true: EnableMail, EnableContacts, EnableCalendars, EnableReminders, and EnableNotes.',
                        type: 'boolean',
                    },
                    EnableCalendarsUserOverridable: {
                        title: 'Enable Calendars User Overridable',
                        description: 'If false, the system prevents the user from changing the state of the Calendars service for this account in Settings.',
                        type: 'boolean',
                    },
                    EnableContacts: {
                        title: 'Enable Contacts',
                        description:
                            'If false, the system disables the Contacts service for this account. The user can reenable Contacts service in Settings unless EnableContactsUserOverridable is false.',
                        help: 'At least of the following fields needs to be true: EnableMail, EnableContacts, EnableCalendars, EnableReminders, and EnableNotes.',
                        type: 'boolean',
                    },
                    EnableContactsUserOverridable: {
                        title: 'Enable Contacts User Overridable',
                        description: 'If false, the system prevents the user from changing the state of the Contacts service for this account in Settings.',
                        type: 'boolean',
                    },
                    EnableMail: {
                        title: 'Enable Mail',
                        description:
                            'If false, the system disables the Mail service for this account. The user can reenable Mail service in Settings unless EnableMailUserOverridable is false.',
                        help: 'At least of the following fields needs to be true: EnableMail, EnableContacts, EnableCalendars, EnableReminders, and EnableNotes.',
                        type: 'boolean',
                    },
                    EnableMailUserOverridable: {
                        title: 'Enable Mail User Overridable',
                        description: 'If false, the system prevents the user from changing the state of the Mail service for this account in Settings.',
                        type: 'boolean',
                    },
                    EnableNotes: {
                        title: 'Enable Notes',
                        description:
                            'If false, the system disables the Notes service for this account. The user can reenable Notes service in Settings unless EnableNotesUserOverridable is false.',
                        help: 'At least of the following fields needs to be true: EnableMail, EnableContacts, EnableCalendars, EnableReminders, and EnableNotes.',
                        type: 'boolean',
                    },
                    EnableNotesUserOverridable: {
                        title: 'Enable Notes User Overridable',
                        description: 'If false, prevents the user from changing the state of the Notes service for this account in Settings.',
                        type: 'boolean',
                    },
                    EnableReminders: {
                        title: 'Enable Reminders',
                        description:
                            'If false, the system disables the Reminders service for this account. The user can reenable Reminders service in Settings unless EnableRemindersUserOverridable is false.',
                        help: 'At least of the following fields needs to be true: EnableMail, EnableContacts, EnableCalendars, EnableReminders, and EnableNotes.',
                        type: 'boolean',
                    },
                    EnableRemindersUserOverridable: {
                        title: 'Enable Reminders User Overridable',
                        description: 'If false, the system prevents the user from changing the state of the Reminders service for this account in Settings.',
                        type: 'boolean',
                    },
                    PreventMove: {
                        title: 'Prevent Move',
                        description:
                            'If true, the system prevents moving messages from out of this email account into another account. This setting also prevents forwarding or replying from an account other than the recipient of the message.',
                        type: 'boolean',
                    },
                    PreventAppSheet: {
                        title: 'Prevent App Sheet',
                        description: 'If true, prevents this account from sending mail in any app other than the Apple Mail app.',
                        type: 'boolean',
                    },
                    SSL: {
                        title: 'Enable SSL',
                        description: 'If true, the system enables SSL for authentication.',
                        type: 'boolean',
                    },
                    allowMailDrop: {
                        title: 'Allow Mail Drop',
                        description: 'If true, the system enables this account to use Mail Drop.',
                        type: 'boolean',
                    },
                    disableMailRecentsSyncing: {
                        title: 'Disable Mail Recents Syncing',
                        description: 'If true, the system excludes this account from Recent Addresses syncing.',
                        type: 'boolean',
                    },
                    OverridePreviousPassword: {
                        title: 'Override Previous Password',
                        description: 'If true, the system overrides the previous user/EAS password with the new EAS password in the payload.',
                        help: 'Available in iOS 14 and later.',
                        type: 'boolean',
                    },
                    SMIMESigningEnabled: {
                        title: 'S/MIME Signing Enabled',
                        description: 'If true, the system enables S/MIME signing for this account.',
                        help: 'Available in iOS 10.0 and later.',
                        type: 'boolean',
                    },
                    SMIMESigningUserOverrideable: {
                        title: 'S/MIME Signing User Overridable',
                        description: 'If true, the user can turn S/MIME signing on or off in Settings.',
                        help: 'Available in iOS 12.0 and later.',
                        type: 'boolean',
                    },
                    SMIMEEnableEncryptionPerMessageSwitch: {
                        title: 'S/MIME Enable Encryption Per Message Switch',
                        description: 'If true, the system displays the per-message encryption switch in the Mail Compose UI.',
                        help: 'Available in iOS 12.0 and later.',
                        type: 'boolean',
                    },
                    SMIMEEncryptByDefault: {
                        title: 'S/MIME Encrypt By Default',
                        description:
                            'If true, the system enables S/MIME encryption by default. If SMIMEEnableEncryptionPerMessageSwitch is false, the user can’t change this default.',
                        help: 'Available in iOS 12.0 and later.',
                        shouldRender: (siblings: any) => {
                            if (siblings.SMIMEEnableEncryptionPerMessageSwitch) {
                                return true;
                            }
                            delete siblings.SMIMEEncryptByDefault;
                            return false;
                        },
                        type: 'boolean',
                    },
                    SMIMEEncryptByDefaultUserOverrideable: {
                        title: 'S/MIME Encrypt By Default User Overridable',
                        description: 'If true, the system enables encryption by default and the user can’t change it.',
                        help: 'Available in iOS 12.0 and later.',
                        type: 'boolean',
                    },
                    VPNUUID: {
                        title: 'VPN UUID',
                        description: 'The VPNUUID of the per-app VPN the account uses for network communication.',
                        help: 'Available in iOS 14 and later.',
                        type: 'string',
                    },
                },
            },
        },
    },
};
