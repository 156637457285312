/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props, react/forbid-prop-types, no-param-reassign, react/prop-types, max-len */
import { BUTTON } from '@capasystems/constants';
import { getDefaultHeatmapConfiguration, getUniqueId } from '@capasystems/utils';
import Highmaps from 'highcharts/highmaps';
import drilldown from 'highcharts/modules/drilldown';
import { cloneDeep, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../button/button';
import { Icon } from '../icon/icon';
import { LayoutRow } from '../layout-row/layout-row';
import './heatmap.scss';

drilldown(Highmaps);

export class Heatmap extends React.Component {
    static defaultProps = { onDrillUpdate: (xName, yName, drilledSeries) => null, xAxis: {}, yAxis: {}, chart: {} };

    /* istanbul ignore next */
    xActive = null;

    /* istanbul ignore next */
    yActive = null;

    /* istanbul ignore next */
    seriesCopy = null;

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.chartId = props.htmlId ? props.htmlId : getUniqueId('heatmap-');
        this.chartConfiguration = getDefaultHeatmapConfiguration();
        this.state = {
            drillEvents: [],
        };
    }

    /* istanbul ignore next */
    componentDidMount() {
        const { series, xAxis, yAxis, chart, onDrillup } = this.props;

        const firstSeries = cloneDeep(series);

        this.seriesCopy = cloneDeep(series);

        if (series) {
            this.chartConfiguration.series = firstSeries;
        }
        this.chartConfiguration.xAxis.categories = firstSeries[0].xCategories;
        this.chartConfiguration.xAxis = {
            ...this.chartConfiguration.xAxis,
            ...xAxis,
        };
        this.chartConfiguration.yAxis.categories = firstSeries[0].yCategories;
        this.chartConfiguration.yAxis = {
            ...this.chartConfiguration.yAxis,
            ...yAxis,
        };

        this.chartConfiguration.chart.events = {
            drillup: (e) => {
                onDrillup(e);
            },
            drilldown: this.drilldownTo,
        };
        this.chartConfiguration.chart = {
            ...this.chartConfiguration.chart,
            ...chart,
        };

        this.chart = Highmaps.chart(this.chartId, this.chartConfiguration);
        this.checkForInitialDrill();
        this.updateTickLabelDrill();
    }

    /* istanbul ignore next */
    componentDidUpdate() {
        const { series } = this.props;
        if (!isEqual(series[0].data, this.seriesCopy[0].data)) {
            this.chart.series[0].setData(series[0].data, false, false, false);
            this.chart.xAxis[0].categories = series[0].xCategories;
            this.chart.yAxis[0].categories = series[0].yCategories;
            this.checkForInitialDrill();
            this.updateTickLabelDrill();
            this.chart.redraw();
            this.chart.reflow();
            this.seriesCopy = cloneDeep(series);
        }
    }

    /* istanbul ignore next */
    componentWillUnmount() {
        this.chart.destroy();
    }

    /* istanbul ignore next */
    onXAxisTitleClick() {
        const { drilldown, series, onDrillUpdate } = this.props;
        this.xActive = null;
        const toDrillUp = cloneDeep(drilldown[this.yActive]);
        this.chart.xAxis[0].axisTitle.attr({
            text: `${this.chartConfiguration.xAxis.title.text}`,
        });
        if (this.yActive && toDrillUp) {
            this.chart.xAxis[0].categories = toDrillUp.xCategories;
            this.chart.yAxis[0].categories = toDrillUp.yCategories;
            this.chart.series[0].setData(toDrillUp.data, false, false, false);
            this.updateDrillEvents(toDrillUp);
            onDrillUpdate(this.xActive, this.yActive, toDrillUp);
        } else {
            this.chart.xAxis[0].categories = series[0].xCategories;
            this.chart.yAxis[0].categories = series[0].yCategories;
            this.chart.series[0].setData(cloneDeep(series[0].data), false, false, false);
            this.resetDrillEvents();
            onDrillUpdate(this.xActive, this.yActive, series[0]);
        }
        this.chart.redraw();
        this.updateTickLabelDrill();
    }

    /* istanbul ignore next */
    onYAxisTitleClick() {
        const { drilldown, series, onDrillUpdate } = this.props;
        this.yActive = null;
        const toDrillUp = cloneDeep(drilldown[this.xActive]);
        this.chart.yAxis[0].setTitle(
            {
                text: `${this.chartConfiguration.yAxis.title.text}`,
            },
            false
        );
        if (this.xActive && toDrillUp) {
            this.chart.xAxis[0].categories = toDrillUp.xCategories;
            this.chart.yAxis[0].update({ categories: toDrillUp.yCategories }, false);
            this.chart.series[0].setData(toDrillUp.data, false, false, false);
            this.updateDrillEvents(toDrillUp);
            onDrillUpdate(this.xActive, this.yActive, toDrillUp);
        } else {
            this.chart.xAxis[0].categories = series[0].xCategories;
            this.chart.yAxis[0].update({ categories: series[0].yCategories }, false);
            this.chart.series[0].setData(cloneDeep(series[0].data), false, false, false);
            this.resetDrillEvents();
            onDrillUpdate(this.xActive, this.yActive, series[0]);
        }
        this.chart.redraw();
        this.updateTickLabelDrill();
    }

    /* istanbul ignore next */
    resetDrillEvents = () => {
        this.setState({ drillEvents: [] });
    };

    /* istanbul ignore next */
    prepareXTitle = (name) => {
        this.chart.xAxis[0].axisTitle.attr({
            text: this.createTitleText(name),
        });
        this.xActive = name;
        this.chart.xAxis[0].axisTitle.element.onclick = () => this.onXAxisTitleClick();
    };

    /* istanbul ignore next */
    prepareYTitle = (name) => {
        this.chart.yAxis[0].setTitle({ text: this.createTitleText(name) }, false);
        this.yActive = name;
        setTimeout(() => {
            this.chart.yAxis[0].axisTitle.element.onclick = () => this.onYAxisTitleClick();
        });
    };

    /* istanbul ignore next */
    checkForInitialDrill = () => {
        const { drillTo, drilldown, series, onDrillUpdate } = this.props;
        const drilledSeries = drilldown[drillTo];
        if (drilledSeries) {
            this.chart.xAxis[0].categories = drilledSeries.xCategories;
            this.chart.series[0].setData(drilledSeries.data, false, false, false);

            const [x, y] = drillTo.split(':');
            if (x && y) {
                this.updateDrillEvents(drilledSeries);
                this.prepareXTitle(x);
                this.prepareYTitle(y);
            } else if (series[0].xCategories.indexOf(drillTo) >= 0) {
                this.prepareXTitle(drillTo);
                this.updateDrillEvents(drilledSeries);
            } else if (series[0].yCategories.indexOf(drillTo) >= 0) {
                this.prepareYTitle(drillTo);
                this.updateDrillEvents(drilledSeries);
            }

            this.chart.yAxis[0].update({ categories: drilledSeries.yCategories }, false);
            this.chart.redraw();
            onDrillUpdate(this.xActive, this.yActive, drilledSeries);
        }
    };

    /* istanbul ignore next */
    updateDrillEvents = (newSeries) => {
        const { drillEvents } = this.state;
        const { series } = this.props;

        if (drillEvents.length === 0) {
            drillEvents.push(series[0]);
        }
        drillEvents.push({ ...newSeries, currentTitles: { x: this.xActive, y: this.yActive } });
        this.setState({ drillEvents });
    };

    /* istanbul ignore next */
    drilldownTo = (e) => {
        const { drilldown, onDrillUpdate } = this.props;
        const newSeries = cloneDeep(drilldown[e.point.drilldown]);
        if (newSeries) {
            const [x, y] = e.point.drilldown.split(':');
            this.prepareXTitle(x);
            this.prepareYTitle(y);
            this.chart.yAxis[0].update({ categories: newSeries.yCategories }, false);
            this.chart.xAxis[0].categories = newSeries.xCategories;
            this.chart.series[0].setData(newSeries.data, false, false, false);
            this.chart.redraw();

            this.updateDrillEvents(newSeries);

            this.updateTickLabelDrill();

            onDrillUpdate(this.xActive, this.yActive, newSeries);
        }
    };

    /* istanbul ignore next */
    createTitleText = (text) => `
        <div class="cs-heatmap-axis-title">
            ${text}
            <span class="cs-heatmap-axis-title-close-button">
                &times;
            </span>
        </div>
    `;

    /* istanbul ignore next */
    getChart = () => this.chart;

    /* istanbul ignore next */
    updateTickLabelDrill = () => {
        // eslint-disable-next-line no-shadow
        const { drilldown, onDrillUpdate } = this.props;
        setTimeout(() => {
            Object.values(this.chart.yAxis[0].ticks).forEach((tick) => {
                if (tick.label) {
                    Highmaps.Tick.prototype.drillable = () => null;
                    let drillSeries = null;
                    if (this.xActive) {
                        drillSeries = cloneDeep(drilldown[`${this.xActive}:${tick.label.textStr}`]);
                    } else {
                        drillSeries = cloneDeep(drilldown[tick.label.textStr]);
                    }
                    if (drillSeries) {
                        tick.label.element.onclick = () => {
                            this.prepareYTitle(tick.label.textStr);
                            this.chart.yAxis[0].update({ categories: drillSeries.yCategories }, false);
                            this.chart.xAxis[0].categories = drillSeries.xCategories;
                            this.chart.series[0].setData(drillSeries.data, false, false, false);
                            this.chart.redraw();
                            this.updateTickLabelDrill();
                            this.updateDrillEvents(drillSeries);
                            onDrillUpdate(this.xActive, this.yActive, drillSeries);
                        };
                        tick.label.element.style.cursor = 'pointer';
                    } else {
                        tick.label.element.onclick = undefined;
                        tick.label.element.style.cursor = 'default';
                    }
                }
            });
        }, 200);
        setTimeout(() => {
            Object.values(this.chart.xAxis[0].ticks).forEach((tick) => {
                if (tick.label) {
                    Highmaps.Tick.prototype.drillable = () => null;
                    let drillSeries = null;
                    if (this.yActive) {
                        drillSeries = cloneDeep(drilldown[`${tick.label.textStr}:${this.yActive}`]);
                    } else {
                        drillSeries = cloneDeep(drilldown[tick.label.textStr]);
                    }
                    if (drillSeries) {
                        tick.label.element.onclick = () => {
                            this.prepareXTitle(tick.label.textStr);
                            this.chart.yAxis[0].update({ categories: drillSeries.yCategories }, false);
                            this.chart.xAxis[0].categories = drillSeries.xCategories;
                            this.chart.series[0].setData(drillSeries.data, false, false, false);
                            this.chart.redraw();
                            this.updateTickLabelDrill();
                            this.updateDrillEvents(drillSeries);
                            onDrillUpdate(this.xActive, this.yActive, drillSeries);
                        };
                        tick.label.element.style.cursor = 'pointer';
                    } else {
                        tick.label.element.onclick = undefined;
                        tick.label.element.style.cursor = 'default';
                    }
                }
            });
        }, 200);
    };

    /* istanbul ignore next */
    onReturnToPrevius = () => {
        const { onDrillUpdate } = this.props;
        const { drillEvents } = this.state;
        drillEvents.pop();
        const newSeries = cloneDeep(drillEvents[drillEvents.length - 1]);
        if (drillEvents.length > 1) {
            if (newSeries.currentTitles.x) {
                this.prepareXTitle(newSeries.currentTitles.x);
            } else {
                this.xActive = null;
                this.chart.xAxis[0].axisTitle.attr({
                    text: `${this.chartConfiguration.xAxis.title.text}`,
                });
            }

            if (newSeries.currentTitles.y) {
                this.prepareYTitle(newSeries.currentTitles.y);
            } else {
                this.yActive = null;
                this.chart.yAxis[0].setTitle(
                    {
                        text: `${this.chartConfiguration.yAxis.title.text}`,
                    },
                    false
                );
            }
        } else {
            this.xActive = null;
            this.yActive = null;
            this.chart.yAxis[0].setTitle(
                {
                    text: `${this.chartConfiguration.yAxis.title.text}`,
                },
                false
            );
            this.chart.xAxis[0].axisTitle.attr({
                text: `${this.chartConfiguration.xAxis.title.text}`,
            });
        }
        this.chart.yAxis[0].update({ categories: newSeries.yCategories }, false);
        this.chart.xAxis[0].categories = newSeries.xCategories;
        this.chart.series[0].setData(newSeries.data, false, false, false);
        this.chart.redraw();
        this.updateTickLabelDrill();
        this.setState({ drillEvents });
        onDrillUpdate(this.xActive, this.yActive, newSeries);
    };

    /* istanbul ignore next */
    render() {
        const { drillEvents } = this.state;
        return (
            <LayoutRow
                fill
                align="flex-end"
                style={{ position: 'relative' }}
            >
                <div
                    className="cs-heatmap-container"
                    id={this.chartId}
                />
                {drillEvents.length > 1 && (
                    <div className="cs-heatmap-floating-box">
                        <Button
                            onClick={this.onReturnToPrevius}
                            variant={BUTTON.OUTLINED}
                        >
                            <Icon
                                type="arrowLeft"
                                size="small"
                            />
                            &nbsp;
                            {drillEvents[drillEvents.length - 2].name}
                        </Button>
                    </div>
                )}
            </LayoutRow>
        );
    }
}

Heatmap.propTypes = {
    series: PropTypes.arrayOf(
        PropTypes.shape({
            xCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
            yCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            borderWidth: PropTypes.number,
            data: PropTypes.arrayOf(PropTypes.object).isRequired,
        })
    ).isRequired,
    xAxis: PropTypes.object,
    yAxis: PropTypes.object,
    chart: PropTypes.object,
    drilldown: PropTypes.shape({}).isRequired,
    /** Get notified when the chart updates via drilling. */
    onDrillUpdate: PropTypes.func,
};

export default Heatmap;
