import { inRange, numberFormat } from '@capasystems/utils';
import { NOT_AVAILABLE } from '@thirdparty/constants';
import classnames from 'classnames';
import languageNames from './language-names';

export type TFormatUnit = {
    value: number | string;
    powersOf1000?: boolean;
    units: string[];
    decimalPoints?: number;
    asText?: boolean;
};

type formatUnitReturnType<T extends boolean> = T extends true ? string : { value: number | string; unit: string };

const formatUnit = <T extends boolean>({
    value,
    powersOf1000 = false,
    units,
    decimalPoints = 0,
    asText = false as T,
}: {
    value: number | string;
    powersOf1000?: boolean;
    units: string[];
    decimalPoints?: number;
    asText?: T;
}): formatUnitReturnType<T> => {
    let result: { value: number | string; unit: string };
    if (!+value) {
        const tempValue = value ?? NOT_AVAILABLE;
        result = {
            value: tempValue,
            unit: tempValue === NOT_AVAILABLE ? '' : units[0],
        };
    } else {
        const threshold = powersOf1000 ? 1000 : 1024;
        const unitIndex = +value < threshold ? 0 : Math.floor(Math.log(+value) / Math.log(threshold));
        result = {
            value: parseFloat((+value / Math.pow(threshold, unitIndex)).toFixed(decimalPoints)),
            unit: units[unitIndex],
        };
    }
    if (asText) {
        return `${result.value} ${result.unit}` as formatUnitReturnType<T>;
    }
    return result as formatUnitReturnType<T>;
};

type formatPercentageReturnType<T extends boolean> = T extends true ? string : { value: string; unit: string };

export const formatPercentage = <T extends boolean>({
    value,
    decimalPoints = 1,
    asText = false as T,
}: {
    value: number;
    decimalPoints?: number;
    asText?: T;
}): formatPercentageReturnType<T> => {
    const tempValue = typeof value === 'number' ? numberFormat(value, decimalPoints) : NOT_AVAILABLE;
    const result = {
        value: tempValue,
        unit: tempValue === NOT_AVAILABLE ? '' : '%',
    };
    if (asText) {
        return `${result.value} ${result.unit}` as formatPercentageReturnType<T>;
    }
    return result as formatPercentageReturnType<T>;
};

type formatMillisecondsReturnType<T extends boolean> = T extends true ? string : ReturnType<typeof formatUnit>;

export const formatMilliseconds = <T extends boolean>({ value, asText = false as T }: { value: number; asText?: T }): formatMillisecondsReturnType<T> => {
    return formatUnit({
        value,
        decimalPoints: value < 1 || inRange(value, 1000, 10000) ? 1 : 0,
        asText,
        powersOf1000: true,
        units: ['ms', 's'],
    }) as formatMillisecondsReturnType<T>;
};

type FormatBytesReturnType<T extends boolean> = T extends true ? string : ReturnType<typeof formatUnit>;

export const formatBytes = <T extends boolean>({
    value,
    decimalPoints = 0,
    asText = false as T,
}: {
    value: number;
    decimalPoints?: number;
    asText?: T;
}): FormatBytesReturnType<T> => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: false,
        units: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    }) as FormatBytesReturnType<T>;
};

export const formatBytesToGigabytes = (bytes: number) => {
    if (!bytes) {
        return 0;
    }
    return bytes / Math.pow(1024, 3);
};

type formatBitsPerSecondReturnType<T extends boolean> = T extends true ? string : ReturnType<typeof formatUnit>;

export const formatBitsPerSecond = <T extends boolean>({
    value,
    decimalPoints = 0,
    asText = false as T,
}: {
    value: number;
    decimalPoints?: number;
    asText?: T;
}): formatBitsPerSecondReturnType<T> => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: true,
        units: ['bps', 'Kbps', 'Mbps', 'Gbps'],
    }) as formatBitsPerSecondReturnType<T>;
};

type formatBytesPerSecondReturnType<T extends boolean> = T extends true ? string : ReturnType<typeof formatUnit>;

export const formatBytesPerSecond = <T extends boolean>({
    value,
    decimalPoints = 0,
    asText = false as T,
}: {
    value: number;
    decimalPoints?: number;
    asText?: T;
}): formatBytesPerSecondReturnType<T> => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: false,
        units: ['Bps', 'KBps', 'MBps', 'GBps'],
    }) as formatBytesPerSecondReturnType<T>;
};

type formatHertzReturnType<T extends boolean> = T extends true ? string : ReturnType<typeof formatUnit>;

export const formatHertz = <T extends boolean>({
    value,
    decimalPoints = 2,
    asText = false as T,
}: {
    value: number;
    decimalPoints?: number;
    asText?: T;
}): formatHertzReturnType<T> => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: true,
        units: ['Hz', 'KHz', 'MHz', 'GHz'],
    }) as formatHertzReturnType<T>;
};

export const getLanguageName = (isoLanguageCode: string) => {
    try {
        return languageNames.of(isoLanguageCode);
    } catch {
        return isoLanguageCode;
    }
};

export const getPlatformName = (bit: number) => `${bit}-bit`;

export const getSeverityClassName = (severity: number, asBackground = true) => {
    if (asBackground) {
        return classnames({
            // 'tw-bg-blue-900 tw-text-blue-100': severity === 0, // None
            'tw-bg-blue-50 tw-text-blue-700': severity === 0, // None
            'tw-bg-green-500 tw-text-green-100': severity === 1, // Low
            'tw-bg-yellow-50 tw-text-yellow-700': severity === 2, // Medium
            'tw-bg-orange-500 tw-text-orange-100': severity === 4, // High
            'tw-bg-red-500 tw-text-red-100': severity === 8, // Critical
        });
    }
    return classnames({
        'tw-text-blue-500': severity === 0, // None
        'tw-text-green-500': severity === 1, // Low
        'tw-text-yellow-500': severity === 2, // Medium
        'tw-text-orange-500': severity === 4, // High
        'tw-text-red-500': severity === 8, // Critical
    });
};

export const getSeverityDescription = (severity: number) => {
    switch (severity) {
        case 0:
            return 'None';
        case 1:
            return 'Low';
        case 2:
            return 'Medium';
        case 4:
            return 'High';
        case 8:
            return 'Critical';
        default:
            return 'Unknown';
    }
};

export const highchartsTooltipHeadingAndText = (header: string, text: string, addMarginTop = true) => {
    return `
        <div class="tw-text-tiny tw-block ${addMarginTop ? 'tw-mt-2' : 'tw-mt-0'}">
            ${header}
            <br />
            <span class="tw-text-xs tw-font-semibold">${text}</span>
        </div>
    `;
};
