export const AndroidNetworkConfiguration = {
    schema: {
        title: 'Network',
        type: 'object',
        category: 'Networking',
        schemaId: 'networkConfiguration',
        properties: {
            mobileNetworksConfigDisabled: {
                title: 'Mobile Networks Config Disabled',
                description: 'Whether configuring mobile networks is disabled.',
                type: 'boolean',
            },
            networkResetDisabled: {
                title: 'Network Reset Disabled',
                description: 'Whether resetting network settings is disabled.',
                type: 'boolean',
            },
            dataRoamingDisabled: {
                title: 'Data Roaming Disabled',
                description: 'Whether roaming data services are disabled.',
                type: 'boolean',
            },
            networkEscapeHatchEnabled: {
                title: 'Network Escape Hatch Enabled',
                description: 'Whether the network escape hatch is enabled.',
                type: 'boolean',
            },
            tetheringConfigDisabled: {
                title: 'Tethering Config Disabled',
                description: 'Whether configuring tethering and portable hotspots is disabled.',
                type: 'boolean',
                isDeprecated: true,
                help: 'See documentation.',
                setUrl: 'Policy.FIELDS.tethering_config_disabled',
            },
            outgoingBeamDisabled: {
                title: 'Outgoing Beam Disabled',
                description: 'Whether using NFC to beam data from apps is disabled.',
                type: 'boolean',
            },
            preferentialNetworkService: {
                title: 'Preferential Network Service',
                description: 'Controls whether preferential network service is enabled on the work profile.',
                type: 'string',
                options: [
                    {
                        id: 'PREFERENTIAL_NETWORK_SERVICE_DISABLED',
                        name: 'Preferential network service disabled',
                    },
                    {
                        id: 'PREFERENTIAL_NETWORK_SERVICE_ENABLED',
                        name: 'Preferential network service enabled',
                    },
                ],
            },
            deviceRadioState: {
                title: 'Device Radio State',
                description: 'Controls the device radio state.',
                type: 'object',
                properties: {
                    wifiState: {
                        title: 'WiFi State',
                        description: 'Controls whether the Wi-Fi is on or off as a state and if the user can change said state.',
                        help: 'Supported on company-owned devices running Android 13 and above.',
                        setUrl: 'wifistate',
                        type: 'string',
                        options: [
                            {
                                id: 'WIFI_STATE_USER_CHOICE',
                                name: 'User choice',
                            },
                            {
                                id: 'WIFI_ENABLED',
                                name: 'Enable Wi-Fi',
                            },
                            {
                                id: 'WIFI_DISABLED',
                                name: 'Disable Wi-Fi',
                            },
                        ],
                    },
                    airplaneModeState: {
                        title: 'Airplane Mode State',
                        description: 'Controls whether airplane mode can be toggled by the user or not.',
                        type: 'string',
                        help: 'Supported on Android 9 and above. Supported on fully managed devices and work profiles on company-owned devices.',
                        setUrl: 'AirplaneModeState',
                        options: [
                            {
                                id: 'AIRPLANE_MODE_USER_CHOICE',
                                name: 'The user is allowed to toggle airplane mode on or off',
                            },
                            {
                                id: 'AIRPLANE_MODE_DISABLED',
                                name: 'Airplane mode is disabled',
                            },
                        ],
                    },
                    ultraWidebandState: {
                        title: 'Ultra Wideband State',
                        description: 'Controls the state of the ultra wideband setting and whether the user can toggle it on or off.',
                        type: 'string',
                        help: 'Supported on Android 14 and above. Supported on fully managed devices and work profiles on company-owned devices.',
                        setUrl: 'UltraWidebandState',
                        options: [
                            {
                                id: 'ULTRA_WIDEBAND_USER_CHOICE',
                                name: 'The user is allowed to toggle ultra wideband on or off',
                            },
                            {
                                id: 'ULTRA_WIDEBAND_ENABLED',
                                name: 'Ultra wideband is enabled',
                            },
                        ],
                    },
                    cellularTwoGState: {
                        title: 'Cellular 2G State',
                        description: 'Controls the state of cellular 2G setting and whether the user can toggle it on or off.',
                        type: 'string',
                        help: 'Supported on Android 14 and above. Supported on fully managed devices and work profiles on company-owned devices.',
                        setUrl: 'CellularTwoGState',
                        options: [
                            {
                                id: 'CELLULAR_TWO_G_USER_CHOICE',
                                name: 'The user is allowed to toggle cellular 2G on or off',
                            },
                            {
                                id: 'CELLULAR_TWO_G_DISABLED',
                                name: 'Cellular 2G is disabled',
                            },
                        ],
                    },
                    minimumWifiSecurityLevel: {
                        title: 'Minimum Wifi Security Level',
                        description: 'Defines the different minimum Wi-Fi security levels required to connect to Wi-Fi networks.',
                        type: 'string',
                        help: 'Supported on Android 13 and above. Supported on fully managed devices and work profiles on company-owned devices.',
                        setUrl: 'MinimumWifiSecurityLevel',
                        options: [
                            {
                                id: 'OPEN_NETWORK_SECURITY',
                                name: 'The device will be able to connect to all types of Wi-Fi networks.',
                            },
                            {
                                id: 'PERSONAL_NETWORK_SECURITY',
                                name: 'A personal network such as WEP, WPA2-PSK is the minimum required security.',
                            },
                            {
                                id: 'ENTERPRISE_NETWORK_SECURITY',
                                name: 'An enterprise EAP network is the minimum required security level. ',
                            },
                            {
                                id: 'ENTERPRISE_BIT192_NETWORK_SECURITY',
                                name: 'A 192-bit enterprise network is the minimum required security level.',
                            },
                        ],
                    },
                },
            },
            recommendedGlobalProxy: {
                title: 'Recommended Global Proxy',
                description:
                    'The network-independent global HTTP proxy. Typically proxies should be configured per-network in openNetworkConfiguration. However for unusual configurations like general internal filtering a global HTTP proxy may be useful.',
                help: 'Note: If the proxy is not accessible, network access may break. The global proxy is only a recommendation and some apps may ignore it.',
                type: 'object',
                properties: {
                    host: {
                        title: 'Host',
                        description: 'The host of the direct proxy.',
                        type: 'string',
                    },
                    port: {
                        title: 'Port',
                        description: 'The port of the direct proxy.',
                        type: 'number',
                    },
                    excludedHosts: {
                        title: 'Excluded Hosts',
                        description: 'For a direct proxy, the hosts for which the proxy is bypassed.',
                        help: 'Note: The host names may contain wildcards such as *.example.com.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    pacUri: {
                        title: 'PAC Uri',
                        description: 'The URI of the PAC script used to configure the proxy.',
                        type: 'string',
                    },
                },
            },
        },
    },
};
