import classnames from 'classnames';
import React from 'react';
import './main-content.scss';

export type MainContentProps = {
    children: React.ReactNode;
    className?: string;
};

export const MainContent: React.FC<MainContentProps> = ({ className, ...rest }) => (
    <div
        className={classnames('cs-content-area', className)}
        {...rest}
    />
);

export default MainContent;
