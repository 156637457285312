import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Icon, LayoutCentered, Loading, Zoom } from '@capasystems/ui';
import React, { useRef, useState } from 'react';
import { useAndroidApi } from '../../hooks/useApi/useApi';
import useNavigate from '../../hooks/useNavigate/useNavigate';

declare global {
    interface Window {
        gapi: any;
    }
}

export type AndroidApplicationPickerProps = ButtonProps & {
    initialSearchTerm?: string;
    buttonText?: string;
};

const AndroidApplicationPicker: React.FC<AndroidApplicationPickerProps> = ({ initialSearchTerm = '', buttonText = 'New', ...props }) => {
    const navigate = useNavigate();
    const androidApi = useAndroidApi();
    const [showApplicationTypePicker, setShowApplicationTypePicker] = useState(false);
    const iframeContainerRef = useRef();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showGooglePlayStore, setShowGooglePlayStore] = useState(false);

    const openApplicationTypePicker = () => {
        setShowApplicationTypePicker(true);
    };

    const closeApplicationTypePicker = () => {
        setShowApplicationTypePicker(false);
    };

    const onEntering = () => {
        androidApi
            .getGooglePlayStoreSearchWebToken()
            .then((webToken) => {
                window.gapi.load('gapi.iframes', function () {
                    const iframeOptions = {
                        url: `https://play.google.com/work/embedded/search?token=${webToken.value}&mode=SELECT&locale=en_US`,
                        where: iframeContainerRef.current,
                        attributes: { style: 'height: 100%', scrolling: 'yes' },
                    };
                    if (initialSearchTerm) {
                        iframeOptions.url += `&search=${initialSearchTerm}`;
                    }
                    const iframe = window.gapi.iframes.getContext().openChild(iframeOptions);
                    iframe.register(
                        'onproductselect',
                        function (event: any) {
                            navigate.to(`android/application/create/${event.packageName}`);
                        },
                        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
                    );
                });
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage('Could not generate web token');
            });
    };
    const closePlayStore = () => {
        setShowGooglePlayStore(false);
    };

    const showTheStore = () => {
        closeApplicationTypePicker();
        setShowGooglePlayStore(true);
    };

    const configureWebApp = () => {
        navigate.to(`android/application/create/webapp`);
    };

    return (
        <>
            <Button
                noMargin
                color="primary"
                variant="contained"
                {...props}
                onClick={openApplicationTypePicker}
            >
                {buttonText}
            </Button>
            <Dialog
                open={showApplicationTypePicker}
                onClose={closeApplicationTypePicker}
                size="xs"
            >
                <DialogTitle>
                    <span>Application type</span>
                </DialogTitle>

                <DialogContent className="tw-w-full">
                    <Zoom in>
                        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-8 tw-text-xs">
                            <div
                                className="tw-h-44 tw-w-44 tw-cursor-pointer tw-rounded-lg tw-bg-slate-100 tw-shadow-sm tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow"
                                onClick={configureWebApp}
                            >
                                <LayoutCentered direction="column">
                                    <Icon
                                        type="googlePlayWebApp"
                                        className="tw-mb-3 tw-text-6xl tw-text-sky-900"
                                    />
                                    <strong>Web app</strong>
                                </LayoutCentered>
                            </div>
                            <div
                                className="tw-h-44 tw-w-44 tw-cursor-pointer tw-rounded-lg tw-bg-slate-100 tw-shadow-sm tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow"
                                onClick={showTheStore}
                            >
                                <LayoutCentered direction="column">
                                    <Icon
                                        type="googlePlayStore"
                                        className="tw-mb-3 tw-text-6xl"
                                    />
                                    <strong>Google Play app</strong>
                                </LayoutCentered>
                            </div>
                        </div>
                    </Zoom>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeApplicationTypePicker}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showGooglePlayStore}
                onClose={closePlayStore}
                onEntering={onEntering}
                PaperProps={{ style: { minWidth: '80vw', height: '80vh' } }}
            >
                <DialogContent
                    className="tw-h-full tw-w-full tw-p-0"
                    ref={iframeContainerRef}
                >
                    <LayoutCentered>
                        {errorMessage ? (
                            <div>
                                <h2>{errorMessage}</h2>
                                <Button
                                    className="tw-mt-4"
                                    color="primary"
                                    variant="contained"
                                    onClick={closePlayStore}
                                >
                                    Close
                                </Button>
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </LayoutCentered>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePlayStore}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { AndroidApplicationPicker };
