export const coreMessage = {
    add: 'Add',
    apply: 'Apply',
    anErrorOccurred: 'An error occurred',
    anUnknownErrorOccurred: 'An unknown error occurred',
    copyToClipboard: 'Copy to clipboard',
    copiedToClipboard: 'Copied to clipboard',
    logOut: 'Log out',
    cancel: 'Cancel',
    clear: 'Clear',
    close: 'Close',
    username: 'Username',
    rememberMe: 'Remember me',

    configure: 'Configure',
    delete: 'Delete',
    deselect: 'Deselect',
    select: 'Select',
    duplicate: 'Duplicate',
    email: 'E-mail',
    password: 'Password',
    noDataToDisplay: 'No data to display',
    signIn: 'Sign in',
    submit: 'Submit',
    noSearchResults: 'No search results...',
    exitFullscreen: 'Exit fullscreen',
    fullscreen: 'Fullscreen',
    remove: 'Remove',
    savingChanges: 'Saving changes',
    saved: 'Saved',
    showingXofYEntities: ({ x, y, entity }: { x: any; y: any; entity: any }) => `Showing ${x} of ${y} ${entity}`,
    showingXEntities: ({ x, entity }: { x: any; entity: any }) => `Showing ${x} ${entity}`,
    emptySlot: '',
    adjustYAxis: 'Adjust y-axis',
    automaticallyConnectPoints: 'Automatically connect points',
    connectAllPoints: 'Connect all points',
    disconnectAllPoints: 'Disconnect all points',
    zoomOut: 'Zoom out',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
};
