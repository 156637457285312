export const AndroidReportingConfiguration = {
    schema: {
        title: 'Reporting',
        type: 'object',
        category: 'Reporting',
        schemaId: 'reportingConfiguration',
        properties: {
            statusReportingSettings: {
                title: null,
                type: 'object',
                properties: {
                    applicationReportsEnabled: {
                        title: 'Application Reports Enabled',
                        description: 'Whether app reports are enabled.',
                        type: 'boolean',
                    },
                    applicationReportingSettings: {
                        title: 'Application Reporting Settings',
                        description: 'Application reporting settings. Only applicable if applicationReportsEnabled is true.',
                        type: 'object',
                        properties: {
                            includeRemovedApps: {
                                title: 'Include Removed Apps',
                                description: 'Whether removed apps are included in application reports.',
                                type: 'boolean',
                            },
                        },
                        shouldRender: (siblings: any) => {
                            if (siblings.applicationReportsEnabled) {
                                return true;
                            }
                            delete siblings.applicationReportingSettings;
                            return false;
                        },
                    },
                    deviceSettingsEnabled: {
                        title: 'Device Settings Enabled',
                        description: 'Whether device settings reporting is enabled.',
                        type: 'boolean',
                    },
                    softwareInfoEnabled: {
                        title: 'Software Info Enabled',
                        description: 'Whether software info reporting is enabled.',
                        type: 'boolean',
                    },
                    memoryInfoEnabled: {
                        title: 'Memory Info Enabled',
                        description: 'Whether memory event reporting is enabled.',
                        type: 'boolean',
                    },
                    networkInfoEnabled: {
                        title: 'Network Info Enabled',
                        description: 'Whether network info reporting is enabled.',
                        type: 'boolean',
                    },
                    displayInfoEnabled: {
                        title: 'Display Info Enabled',
                        description: 'Whether displays reporting is enabled.',
                        help: 'Note: Report data is not available for personally owned devices with work profiles.',
                        type: 'boolean',
                    },
                    powerManagementEventsEnabled: {
                        title: 'Power Management Events Enabled',
                        description: 'Whether power management event reporting is enabled.',
                        help: 'Note: Report data is not available for personally owned devices with work profiles.',
                        type: 'boolean',
                    },
                    hardwareStatusEnabled: {
                        title: 'Hardware Status Enabled',
                        description: 'Whether hardware status reporting is enabled.',
                        help: 'Note: Report data is not available for personally owned devices with work profiles.',
                        type: 'boolean',
                    },
                    systemPropertiesEnabled: {
                        title: 'System Properties Enabled',
                        description: 'Whether system properties reporting is enabled.',
                        type: 'boolean',
                    },
                    commonCriteriaModeEnabled: {
                        title: 'Common Criteria Mode Enabled',
                        description: 'Whether Common Criteria Mode reporting is enabled.',
                        type: 'boolean',
                    },
                },
            },
        },
    },
};
