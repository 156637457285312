import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { green, red } from '@mui/material/colors';
import ChipInput from './chipInput';
import { Tooltip } from '@capasystems/ui';

/**
 * Special version of ChipInput that will color the chips based on testResults input.
 * Designed to support both "matchName" and "testString" fields from CreateApplicationDialog.
 */
const PackageMatchChipInput = ({
    label,
    value,
    placeholder,
    onAdd,
    onDelete,
    testResults,
    mode = 'matchName',
    ...props
}) => {
    const chipRenderer = (
        {
            value,
            text,
            chip,
            isFocused,
            isDisabled,
            isReadOnly,
            handleClick,
            handleDelete,
            className,
        },
        key
    ) => {
        const bgColor = testResults?.[text]?.length > 0 ? green[200] : red[200];
        const tooltip = generateTooltip(mode, text, testResults);
        return (
            <Tooltip content={tooltip}>
                <Chip
                    key={key}
                    className={className}
                    style={{
                        pointerEvents:
                            isDisabled || isReadOnly ? 'none' : undefined,
                        // backgroundColor: isFocused ? blue[300] : undefined,
                        backgroundColor: bgColor,
                    }}
                    onClick={handleClick}
                    onDelete={handleDelete}
                    label={text}
                />
            </Tooltip>
        );
    };

    return (
        <ChipInput
            {...props}
            label={label}
            value={value}
            placeholder={placeholder}
            onAdd={onAdd}
            onDelete={onDelete}
            style={{ marginRight: 10 }}
            chipRenderer={chipRenderer}
        />
    );
};

const generateTooltip = (mode, text, matches) => {
    if (!matches?.[text]?.length > 0) mode += 0;

    switch (mode) {
        case 'matchName':
            return `Matches: ${matches[text].join(', ')}.`;
        case 'matchName0':
            return "Doesn't match any test strings!";
        case 'testString':
            return `Matched by: ${matches[text].join(', ')}.`;
        case 'testString0':
            return 'Not matched by any match names!';
        default:
            throw new Error('Unsupported mode');
    }
};

PackageMatchChipInput.propTypes = {
    label: PropTypes.node,
    value: PropTypes.array,
    placeholder: PropTypes.string,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    testResults: PropTypes.object,
    mode: PropTypes.oneOf(['matchName', 'testString']),
};

export { PackageMatchChipInput };
