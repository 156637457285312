import React from 'react';
import ReactDOM from 'react-dom/client';

import Index from './app/index';

import { isBeta, isLocalHost } from '@capasystems/constants';

import { clarity } from 'react-microsoft-clarity';

// Encountered Webpack issue with 'import' - solved using 'require'.
// eslint-disable-next-line @typescript-eslint/no-var-requires

if(!isLocalHost && !isBeta) {
    clarity.init('nj27jn1v0l');
}

const tagManagerArgs = {
    gtmId: isBeta ? 'GTM-WR2KD82' : 'GTM-5C9H4DZ',
};

//TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<Index />);
