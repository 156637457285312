import { OnDemandRulesElement } from '../PayloadElements/OnDemandRulesElement';
//DNSproxy
//https://developer.apple.com/documentation/devicemanagement/dnsproxy#discussion

//DNSSettings
//https://developer.apple.com/documentation/devicemanagement/dnssettings

export const AppleDNSConfiguration = {
    schema: {
        schemaId: 'appleDNSConfiguration',
        category: 'Networking',
        title: 'DNS Settings',
        type: 'object',
        properties: {
            'com.apple.dnsSettings.managed': {
                title: '',
                description: '',
                type: 'object',
                properties: {
                    DNSSettings: {
                        title: 'DNS Settings',
                        description: '',
                        type: 'object',
                        properties: {
                            DNSProtocol: {
                                title: 'DNS Protocol',
                                description: 'The encrypted transport protocol used to communicate with the DNS server.',
                                type: 'string',
                                required: true,
                                options: [
                                    {
                                        id: 'HTTPS',
                                        name: 'HTTPS',
                                    },
                                    {
                                        id: 'TLS',
                                        name: 'TLS',
                                    },
                                ],
                            },
                            ServerName: {
                                title: 'Server Name',
                                description: 'The hostname of a DNS-over-TLS server used to validate the server certificate, as defined in RFC 7858.',
                                help: 'If no ServerAddresses are provided, the hostname in the ServerName will be used to determine the server addresses.',
                                type: 'string',
                                shouldRender: (siblings: any) => {
                                    if (siblings.DNSProtocol === 'TLS') {
                                        return true;
                                    } else {
                                        delete siblings.ServerName;
                                        return false;
                                    }
                                },
                            },
                            ServerURL: {
                                title: 'Server URL',
                                description: 'The URI template of a DNS-over-HTTPS server, as defined in RFC 8484.',
                                help: 'This URL must use the https:// scheme, and the hostname or address in the URL will be used to validate the server certificate. If no ServerAddresses are provided, the hostname or address in the URL will be used to determine the server addresses.',
                                type: 'string',
                                shouldRender: (siblings: any) => {
                                    if (siblings.DNSProtocol === 'HTTPS') {
                                        return true;
                                    } else {
                                        delete siblings.ServerURL;
                                        return false;
                                    }
                                },
                            },
                            ServerAddresses: {
                                title: 'Server Addresses',
                                description: 'An unordered list of DNS server IP address.',
                                help: 'These IP addresses can be a mixture of IPv4 and IPv6 addresses.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            SupplementalMatchDomains: {
                                title: 'Supplemental Match Domains',
                                description: 'A list of domain strings used to determine which DNS queries will use the DNS server.',
                                help: 'If this array is not provided, all domains will use the DNS server. A single wildcard * prefix is supported, but is not required. For example, both *.example.com and example.com match against mydomain.example.com and your.domain.example.com, but do not match against mydomain-example.com.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                    OnDemandRules: OnDemandRulesElement,
                    PayloadCertificateUUID: {
                        title: 'Payload Certificate UUID',
                        description: 'The UUID that points to an identity certificate payload.',
                        help: 'The system uses this identity to authenticate the user to the DNS resolver.',
                        type: 'string',
                    },
                    ProhibitDisablement: {
                        title: 'Prohibit Disablement',
                        description: 'If true, prohibits users from disabling DNS settings.',
                        help: 'This key is only available on supervised devices.',
                        type: 'boolean',
                    },
                },
            },
        },
    },
};
