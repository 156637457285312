import React from 'react';
import { Highcharts } from '@capasystems/ui';

export const SparkChart = ({ series = [], title = null, yAxis = {}, xAxis = {}, tooltip = {}, onClick, mouseOver, mouseOut }) => (
    <Highcharts
        options={{
            chart: {
                animation: true,
                spacingTop: 0,
                spacingRight: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                margin: [0, 0, 0, 0],
                type: 'areaspline',
                events: {
                    click: onClick,
                },
                // type: 'area',
            },
            title: {
                enabled: false,
                text: title,
            },
            xAxis: {
                minPadding: 0,
                maxPadding: 0,
                tickmarkPlacement: 'on',
                startOnTick: false,
                endOnTick: false,
                labels: {
                    enabled: false,
                },
                tickLength: 0,
                ...xAxis,
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    enabled: false,
                },
                tickLength: 0,
                title: {
                    enabled: false,
                    text: null,
                },
                // gridLineColor: '#FFF',
                gridLineColor: 'transparent',
                ...yAxis,
            },
            tooltip: {
                outside: true,
                borderRadius: 8,
                formatter(e) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return `${this.series.name}<br /><b>${this.y}%</b>`;
                },
                ...tooltip,
            },
            plotOptions: {
                series: {
                    // fillOpacity: 0.5,
                    lineWidth: 0,
                    opacity: 0.5,
                    marker: {
                        enabled: false,
                    },
                    showInLegend: false,
                    events: {
                        mouseOver,
                        mouseOut,
                    },
                },
            },
            series,
        }}
    />
);

export const sparkChartpercentageZones = [
    {
        color: '#1B8753',
        value: 70,
    },
    {
        // color: '#EE766B',
        color: '#ef9a9a',
        value: 80,
    },
    {
        // color: '#F3443A',
        color: '#e57373',
        value: 90,
    },
    {
        // color: '#F42156',
        color: '#ef5350',
    },
];

export default SparkChart;
