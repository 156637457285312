import {
    AutoComplete,
    Avatar,
    Button,
    Collapse,
    Grow,
    Icon,
    IconButton,
    IconProps,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    SavingChanges,
    Select,
    Slider,
    Tab,
    Tabs,
    Tooltip,
    UploadButton,
} from '@capasystems/ui';
import { getUniqueId, isDefined, isFunction, isUndefined, now } from '@capasystems/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { useRef, useState } from 'react';
import { AppleApplicationSearchDialog } from '../../apple/components/application/AppleApplicationSearchDialog';
import { TailwindBadge, useForceRefresh } from './../../../index';

const PROPERTY_TYPE = {
    STRING: 'string',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    OBJECT: 'object',
    ARRAY: 'array',
    MAP: 'map',
    SECTION_HEADER: 'sectionheader',
    RANGE: 'range',
    UPLOADBUTTON: 'uploadbutton',
    SEARCH_DIALOG: 'appleapplicationsearchdialog',
    DATE: 'datepicker',
};

const readOnlyText = 'Enter edit mode to configure these fields.';

export type SchemaBuilderProps = {
    loading?: boolean;
    schema: any;
    disabled?: boolean;
    readOnly?: boolean;
    hoursToSeconds?: boolean;
    currentState: TSchemaBuilderRef;
    onRefChange?: () => void;
    properties?: any;
};

export type TSchemaBuilderRef = {
    [key: string]: any;
};

const SchemaBuilder: React.FC<SchemaBuilderProps> = ({
    loading = false,
    schema,
    disabled = false,
    hoursToSeconds = false,
    readOnly = false,
    onRefChange,
    currentState,
    ...props
}) => {
    const refresh = useForceRefresh();

    const [internalLoading, setInternalLoading] = useState(false);

    if (loading || internalLoading) {
        return (
            <LayoutCentered>
                <Loading />
            </LayoutCentered>
        );
    }

    const onClearSchemaRef = () => {
        setInternalLoading(true);
        const entries = Object.keys(schema.properties);
        entries.forEach((key) => {
            delete currentState[key];
        });
        if (onRefChange) {
            onRefChange();
        }
        refresh();
        setTimeout(() => {
            setInternalLoading(false);
        });
    };

    return (
        <div className="tw-mb-2 tw-mt-2">
            <SchemaBuilderSectionHeader
                title={schema.title}
                description={schema.description}
                className="tw-mb-4 tw-mt-2 tw-px-6 tw-pt-3"
                mainHeader
                readOnly={readOnly}
                onClearSchemaRef={onClearSchemaRef}
            />
            <SchemaBuilderInternal
                {...schema}
                currentState={currentState}
                onRefChange={() => {
                    if (onRefChange) {
                        onRefChange();
                    }
                    refresh();
                }}
                level={1}
                allowSticky
                readOnly={readOnly}
                disabled={disabled}
            />
        </div>
    );
};

export type SchemaBuilderInternalProps = {
    properties?: any;
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
    onRefChange: () => void;
    currentState: { [key: string]: any };
    level: number;
    allowSticky?: boolean;
    skipIndent?: boolean;
};

const SchemaBuilderInternal: React.FC<SchemaBuilderInternalProps> = ({ properties = {}, className, disabled, readOnly, currentState, ...props }) => {
    const entries = Object.entries(properties);

    return entries.map(([id, schemaProps]: [id: any, schemaProps: any], index) => {
        let shouldRender = true;
        let hidden = false;

        if (!schemaProps || Object.keys(schemaProps).length === 0) {
            hidden = true;
        }

        if (readOnly) {
            shouldRender = isDefined(currentState[id]);
        } else if (isFunction(schemaProps.shouldRender)) {
            const initialLength = Object.keys(currentState).length;
            shouldRender = schemaProps.shouldRender(currentState);
            if (!shouldRender && initialLength !== Object.keys(currentState).length) {
                if (!props.onRefChange) {
                    return;
                }
                props.onRefChange(); // shouldRender modified the ref. Update the UI.
            }
        }
        return (
            <Collapse
                in={shouldRender && !hidden}
                unmountOnExit
                key={id}
            >
                <div
                    className={classNames(className, {
                        'tw-px-6 tw-py-3': true,
                    })}
                    data-schema-property-id={id}
                >
                    <PropertyBuilder
                        id={id}
                        currentState={currentState}
                        {...props}
                        {...schemaProps}
                        readOnly={readOnly}
                    />
                </div>
            </Collapse>
        );
    });
};

export type PropertyBuilderProps = {
    id: string;
    title: string;
    type: string;
    properties: any;
    currentState: TSchemaBuilderRef;
    onRefChange: () => void;
    level: number;
    allowSticky: boolean;
    readOnly: boolean;
    disabled: boolean;
    required: boolean;
    defaultValue: any;
    packs: boolean;
    description: string;
    help: string;
    setUrl: string;
    isDeprecated: boolean;
    placeholder: string;
    wide: boolean;
    multiline: boolean;
    suggestions: any;
    isPassword: boolean;
    hoursToSeconds: boolean;
    className?: string;
    numberOfSteps: number;
};

const PropertyBuilder: React.FC<PropertyBuilderProps> = ({ type, currentState, ...props }) => {
    if (type === PROPERTY_TYPE.STRING) {
        return (
            <SchemaBuilderStringType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.NUMBER) {
        return (
            <SchemaBuilderNumberType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.BOOLEAN) {
        if (props.packs) {
            return (
                <SchemaBuilderBooleanTypePacks
                    currentState={currentState}
                    {...props}
                />
            );
        }
        return (
            <SchemaBuilderBooleanType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.ARRAY) {
        return (
            <SchemaBuilderArrayType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.OBJECT) {
        return (
            <SchemaBuilderObjectType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.MAP) {
        return (
            <SchemaBuilderMapType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.SECTION_HEADER) {
        return <SchemaBuilderSectionHeader {...props} />;
    }

    if (type === PROPERTY_TYPE.RANGE) {
        return (
            <SchemaBuilderRangeType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.UPLOADBUTTON) {
        return (
            <SchemaBuilderUploadButtonType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.SEARCH_DIALOG) {
        return (
            <SchemaBuilderAppleApplicationSearchDialogType
                currentState={currentState}
                {...props}
            />
        );
    }
    if (type === PROPERTY_TYPE.DATE) {
        return (
            <SchemaBuilderDateType
                currentState={currentState}
                {...props}
            />
        );
    }

    return (
        <>
            <SchemaBuilderTitle
                currentState={currentState}
                {...props}
            >
                {props.title}
            </SchemaBuilderTitle>
            <p>Unhandled type: {type}.</p>
        </>
    );
};

export type SchemaBuilderSectionHeaderProps = {
    title: string;
    description?: string;
    className?: string;
    mainHeader?: boolean;
    readOnly?: boolean;
    onClearSchemaRef?: () => void;
};

const SchemaBuilderSectionHeader: React.FC<SchemaBuilderSectionHeaderProps> = ({
    title,
    description,
    className,
    mainHeader = false,
    readOnly = false,
    onClearSchemaRef = () => null,
}) => {
    if (title) {
        return (
            <LayoutRow
                verticalAlign="center"
                align="space-between"
                className={classNames('tw-text-sky-700', className)}
            >
                <div>
                    <b
                        className={classNames({
                            'tw-block tw-font-bold tw-tracking-wide': true,
                            'tw-text-xl': !mainHeader,
                            'tw-text-2xl': mainHeader,
                        })}
                    >
                        {title}
                    </b>
                    {description && (
                        <div
                            className="tw-mt-1 tw-block tw-whitespace-pre-wrap tw-text-xs tw-font-semibold tw-leading-relaxed"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    )}
                </div>
                {mainHeader && !readOnly && (
                    <Button
                        onClick={onClearSchemaRef}
                        color="primary"
                    >
                        Clear
                    </Button>
                )}
            </LayoutRow>
        );
    }
    return null;
};

export type SchemaBuilderObjectTypeProps = {
    id: string;
    title: string;
    onRefChange: () => void;
    level: number;
    allowSticky: boolean;
    readOnly: boolean;
    disabled: boolean;
    properties?: any;
    currentState: TSchemaBuilderRef;
    description?: string;
    skipIndent?: boolean;
};

const SchemaBuilderObjectType: React.FC<SchemaBuilderObjectTypeProps> = ({
    id,
    title,
    onRefChange,
    level,
    allowSticky,
    readOnly,
    disabled,
    currentState,
    ...rest
}) => {
    const intertalState = useRef(isDefined(currentState[id]) ? currentState[id] : {});

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <div
            className={classNames({
                'tw-relative tw-mb-2 tw-ml-0 tw-overflow-auto tw-border-l-2 tw-border-amber-300 tw-py-3 tw-pl-6 hover:tw-border-amber-400': level > 1,
            })}
        >
            <SchemaBuilderSectionHeader
                title={title}
                description={rest.description}
                className="tw-mb-4"
            />
            <div>
                <SchemaBuilderInternal
                    properties={rest.properties}
                    currentState={intertalState.current}
                    className="tw-pl-0 tw-pr-0 tw-pt-0"
                    onRefChange={() => {
                        if (Object.keys(intertalState.current).length === 0) {
                            delete currentState[id];
                        } else {
                            currentState[id] = intertalState.current;
                        }
                        onRefChange();
                    }}
                    level={rest.skipIndent ? 1 : level + 1}
                    allowSticky={allowSticky}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
};

export type ShcemaBuilderMapTypeProps = {
    id: string;
    title: string;
    properties: any;
    readOnly: boolean;
    onRefChange: () => void;
    currentState: TSchemaBuilderRef;
};

const SchemaBuilderMapType: React.FC<ShcemaBuilderMapTypeProps> = ({ properties, readOnly, onRefChange, currentState, ...props }) => {
    const internalState = useRef(
        isDefined(currentState[props.id])
            ? {
                  [props.id]: Object.entries(currentState[props.id]).map(([key, value]) => ({ key, value })),
              }
            : {}
    );

    return (
        <SchemaBuilderArrayType
            currentState={internalState.current}
            items={{
                type: PROPERTY_TYPE.OBJECT,
                properties: properties,
            }}
            onRefChange={(s) => {
                const arrayOfKeyValues = internalState.current[props.id];
                currentState[props.id] = {};
                if (arrayOfKeyValues) {
                    arrayOfKeyValues.forEach((option) => {
                        if (option.key && option.value) {
                            currentState[props.id][option.key] = option.value;
                        }
                    });
                }
                if (Object.keys(currentState[props.id]).length === 0) {
                    delete currentState[props.id];
                }
                onRefChange();
            }}
            {...props}
        />
    );
};

const schemaBuilderBooleanTypeLabels = ['Not configured', 'No', 'Yes'];

export type SchemaBuilderBooleanTypeProps = {
    id: string;
    title: string;
    onRefChange: () => void;
    readOnly: boolean;
    required: boolean;
    defaultValue: boolean;
    currentState: TSchemaBuilderRef;
    help?: string;
    setUrl?: string;
    isDeprecated?: boolean;
    description?: string;
    disabled?: boolean;
    tooltipEnabled?: boolean;
    packs?: boolean;
};

const SchemaBuilderBooleanType: React.FC<SchemaBuilderBooleanTypeProps> = ({ id, title, onRefChange, readOnly, required, currentState, ...rest }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
        if (isUndefined(currentState[id])) {
            if (required) {
                currentState[id] = rest.defaultValue === true;
            } else {
                return 0;
            }
        }
        return currentState[id] === true ? 2 : 1;
    });

    // If no data is selected the options wont show. If required=true 'Not configured' don't show
    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    const onPillChange = (e: any, tabIndex: number) => {
        if (tabIndex === 0 && !required) {
            delete currentState[id];
        } else {
            currentState[id] = tabIndex === 2;
        }
        setSelectedTabIndex(tabIndex);
        onRefChange();
    };

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <div className="tw-flex">
                    <SchemaBuilderTitle
                        id={id}
                        currentState={currentState}
                        help={rest.help}
                        setUrl={rest.setUrl}
                        isDeprecated={rest.isDeprecated}
                    >
                        {title}
                    </SchemaBuilderTitle>
                </div>

                <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
            </div>
            <Tooltip content={readOnly ? readOnlyText : rest.disabled && rest.tooltipEnabled ? 'This option cannot be changed in edit mode.' : ''}>
                <div>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={onPillChange}
                        pills
                        className="tw-rounded tw-bg-gray-100"
                        classes={{
                            scroller: 'tw-overflow-visible',
                            root: 'tw-p-0.5',
                            indicator: classNames({
                                'tw-outline-2 tw-rounded tw-outline-double': true,
                                'tw-outline-neutral-400': selectedTabIndex === 0 || rest.disabled,
                                'tw-outline-rose-600': selectedTabIndex === 1 && !rest.disabled && !rest.packs,
                                'tw-outline-emerald-600': selectedTabIndex === 2 && !rest.disabled && !rest.packs,
                            }),
                        }}
                    >
                        {schemaBuilderBooleanTypeLabels.map((label, index) => {
                            return (
                                <Tab
                                    label={label}
                                    key={label}
                                    disableRipple
                                    disabled={rest.disabled || readOnly}
                                    className={classNames({
                                        'tw-font-semibold': true,
                                        'tw-w-16': index > 0,
                                        'tw-text-gray-400': index !== selectedTabIndex,
                                        'tw-text-neutral-600': index === selectedTabIndex && selectedTabIndex === 0,
                                        'tw-text-rose-600': index === selectedTabIndex && selectedTabIndex === 1,
                                        'tw-text-emerald-600': index === selectedTabIndex && selectedTabIndex === 2,
                                        'tw-hidden': (index === 0 && required) || (readOnly && index === 0),
                                    })}
                                />
                            );
                        })}
                    </Tabs>
                </div>
            </Tooltip>
        </div>
    );
};

const schemaBuilderBooleanTypeLabelsPacks = ['Disabled', 'Maintenance', 'Released'];

export type SchemaBuilderBooleanTypePacksProps = {
    id: string;
    title: string;
    onRefChange: () => void;
    readOnly: boolean;
    required: boolean;
    defaultValue: number;
    currentState: TSchemaBuilderRef;
    help?: string;
    setUrl?: string;
    isDeprecated?: boolean;
    description?: string;
    disabled?: boolean;
    tooltipEnabled?: boolean;
    packs?: boolean;
};

const SchemaBuilderBooleanTypePacks: React.FC<SchemaBuilderBooleanTypePacksProps> = ({ id, title, onRefChange, required, currentState, ...rest }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
        currentState[id] = schemaBuilderBooleanTypeLabelsPacks[rest.defaultValue];
        return rest.defaultValue;
    });

    const onPillChange = (e: any, tabIndex: any) => {
        if (tabIndex === 0 && !required) {
            delete currentState[id];
        } else {
            currentState[id] = schemaBuilderBooleanTypeLabelsPacks[tabIndex];
        }
        setSelectedTabIndex(tabIndex);
        onRefChange();
    };

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={id}
                    currentState={currentState}
                    help={rest.help}
                    setUrl={rest.setUrl}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
                <SchemaBuilderHelp
                    {...rest}
                    isBoolean
                    setUrl={rest.setUrl}
                />
            </div>
            <Tooltip content={rest.disabled && rest.tooltipEnabled ? 'This option cannot be changed in edit mode.' : ''}>
                <div>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={onPillChange}
                        pills
                        className="tw-rounded tw-bg-gray-100"
                        classes={{
                            scroller: 'tw-overflow-visible',
                            root: 'tw-p-0.5',
                            indicator: classNames({
                                'tw-outline-2 tw-rounded tw-outline-double': true,
                                'tw-outline-neutral-400': selectedTabIndex === 0 || rest.disabled,
                                'tw-outline-rose-600': selectedTabIndex === 1 && !rest.disabled && !rest.packs,
                                'tw-outline-emerald-600': selectedTabIndex === 2 && !rest.disabled && !rest.packs,
                            }),
                        }}
                    >
                        {schemaBuilderBooleanTypeLabelsPacks.map((label, index) => (
                            <Tab
                                label={label}
                                key={label}
                                disableRipple
                                disabled={rest.disabled}
                                className={classNames({
                                    'tw-font-semibold': true,
                                    'tw-w-24': index > 0,
                                    'tw-text-gray-400': index !== selectedTabIndex,
                                    'tw-text-neutral-600': index === selectedTabIndex && selectedTabIndex === 0,
                                })}
                            />
                        ))}
                    </Tabs>
                </div>
            </Tooltip>
        </div>
    );
};

const keysChecker = (theArray: any[]) => {
    if (isUndefined(theArray)) {
        return [];
    }
    return theArray.map((value, index: number) => now() + index);
};

export type SchemaBuilderArrayTypeProps = {
    id: string;
    title: string;
    items?: any;
    readOnly?: boolean;
    onRefChange: (ref?: any) => void;
    currentState: TSchemaBuilderRef;
    isDeprecated?: boolean;
    description?: string;
    disabled?: boolean;
    tooltipEnabled?: boolean;
    suggestions?: any;
};

const SchemaBuilderArrayType: React.FC<SchemaBuilderArrayTypeProps> = ({ currentState, ...props }) => {
    if (props.items.type === PROPERTY_TYPE.OBJECT) {
        return (
            <SchemaBuilderArrayTypeObject
                currentState={currentState}
                {...props}
                isDeprecated={props.isDeprecated}
            />
        );
    }

    if (props.items.type === PROPERTY_TYPE.STRING) {
        if (isDefined(props.items.options)) {
            return (
                <SchemaBuilderArrayTypeEnum
                    currentState={currentState}
                    {...props}
                />
            );
        }
        return (
            <SchemaBuilderArrayTypeString
                currentState={currentState}
                {...props}
            />
        );
    }
    if (props.items.type === PROPERTY_TYPE.SEARCH_DIALOG) {
        if (isDefined(props.items.options)) {
            return (
                <SchemaBuilderArrayTypeEnum
                    currentState={currentState}
                    {...props}
                />
            );
        }
        return (
            <SchemaBuilderArrayTypeAppleApplicationSearchDialog
                currentState={currentState}
                {...props}
            />
        );
    }
    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={props.id}
                    currentState={currentState}
                >
                    {props.title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{props.description}</SchemaBuilderDescription>
            </div>
            <div>
                <h2 className="tw-animate-pulse tw-rounded-full tw-bg-red-500 tw-p-4 tw-text-white">Unhandled type: {props.items.type}</h2>
            </div>
        </div>
    );
};

export type SchemaBuilderNumberTypeProps = {
    id: string;
    title: string;
    readOnly: boolean;
    onRefChange: () => void;
    hoursToSeconds: boolean;
    help: string;
    required: boolean;
    setUrl: string;
    isDeprecated: boolean;
    description: string;
    disabled: boolean;
    placeholder: string;
    currentState: TSchemaBuilderRef;
};

const SchemaBuilderNumberType: React.FC<SchemaBuilderNumberTypeProps> = ({ id, title, readOnly, hoursToSeconds, onRefChange, currentState, ...rest }) => {
    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    if (hoursToSeconds) {
        const hoursToSeconds = currentState[id] === undefined ? '' : currentState[id] * 3600;
        if (hoursToSeconds === '') {
            delete currentState[id];
        } else {
            currentState[id] = Number(hoursToSeconds);
        }
    }
    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    currentState={currentState}
                    id={id}
                    help={rest.help}
                    required={rest.required && isUndefined(currentState[id])}
                    setUrl={rest.setUrl}
                    isDeprecated={rest.isDeprecated}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
            </div>
            <div className="tw-w-96">
                <Input
                    type="number"
                    defaultValue={currentState[id] || ''}
                    className="tw-mt-2"
                    callToAction
                    disabled={rest.disabled || readOnly}
                    endAdornment={
                        readOnly && (
                            <Tooltip content={readOnlyText}>
                                <Icon
                                    type="lock"
                                    size="small"
                                ></Icon>
                            </Tooltip>
                        )
                    }
                    onChange={(e) => {
                        const trimmedValue = e.target.value.trim();
                        if (trimmedValue === '') {
                            delete currentState[id];
                        } else {
                            currentState[id] = Number(trimmedValue);
                        }
                        onRefChange();
                    }}
                />
            </div>
        </div>
    );
};

export type SchemaBuilderStringTypeProps = {
    id: string;
    title: string;
    onRefChange: () => void;
    readOnly: boolean;
    isDeprecated: boolean;
    options?: any;
    required?: boolean;
    help?: string;
    setUrl?: string;
    description?: string;
    placeholder?: string;
    disabled?: boolean;
    wide?: boolean;
    multiline?: boolean;
    suggestions?: any;
    isPassword?: boolean;
    currentState: TSchemaBuilderRef;
    tooltipEnabled?: boolean;
};

const SchemaBuilderStringType: React.FC<SchemaBuilderStringTypeProps> = ({ id, title, onRefChange, readOnly, isDeprecated, currentState, ...rest }) => {
    const [stringEnumProps, setStringEnumProps] = useState(() => {
        let selectedOptions = [];

        if (rest.options) {
            selectedOptions = rest.options.filter((option: any) => option.id === currentState[id]);

            if (rest.required && selectedOptions.length === 0) {
                selectedOptions = [];
            }
        }

        return {
            options: rest.options || [],
            selectedOptions: selectedOptions,
        };
    });

    const [stringType, setStringType] = useState('password');
    const [iconType, setIconType] = useState<IconProps['type']>('visibilityOff');

    const handleEyeToggle = () => {
        if (stringType === 'password') {
            setIconType('visibility');
            setStringType('string');
        } else {
            setIconType('visibilityOff');
            setStringType('password');
        }
    };

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    if (rest.options) {
        return (
            <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
                <div>
                    <div className="tw-flex tw-items-center">
                        <div className="tw-mr-1">
                            <SchemaBuilderTitle
                                id={id}
                                help={rest.help}
                                setUrl={rest.setUrl}
                                required={rest.required && (isUndefined(currentState[id]) || currentState[id] === '')}
                                isDeprecated={isDeprecated}
                                currentState={currentState}
                            >
                                {title}
                            </SchemaBuilderTitle>
                        </div>
                        <div className="tw-ml-1">
                            <SchemaBuilderDeprecated
                                {...rest}
                                isDeprecated={isDeprecated}
                                isBoolean
                            />
                        </div>
                    </div>

                    <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
                </div>

                <Tooltip content={rest.disabled && rest.tooltipEnabled ? 'This option cannot be changed in edit mode.' : ''}>
                    <div className="tw-w-96">
                        <Select
                            {...stringEnumProps}
                            // @ts-ignore - Select is not typed
                            onChange={(opts) => {
                                if (opts.length === 0) {
                                    delete currentState[id];
                                } else {
                                    currentState[id] = opts[0].id;
                                }

                                setStringEnumProps((c) => ({
                                    ...c,
                                    selectedOptions: opts,
                                }));
                                onRefChange();
                            }}
                            className="tw-mt-2"
                            callToAction
                            fullWidth
                            placeholder={rest.placeholder}
                            required={rest.required}
                            disabled={rest.disabled || readOnly}
                            endAdornment={
                                readOnly ? (
                                    <Tooltip content={readOnlyText}>
                                        <Icon
                                            type="lock"
                                            size="small"
                                        ></Icon>
                                    </Tooltip>
                                ) : undefined
                            }
                        />
                    </div>
                </Tooltip>
            </div>
        );
    }

    if (rest.suggestions) {
        return (
            <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
                <div>
                    <SchemaBuilderTitle
                        id={id}
                        required={rest.required && isUndefined(currentState[id])}
                        currentState={currentState}
                    >
                        {title}
                    </SchemaBuilderTitle>
                    <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
                </div>
                <div
                    className={classNames({
                        'tw-w-96': !rest.wide,
                        'tw-w-128': rest.wide,
                    })}
                >
                    <AutoComplete
                        fullWidth
                        searchTerm={currentState[id] || ''}
                        value={currentState[id] || ''}
                        suggestions={rest.suggestions.filter((l: any) =>
                            l.name.toLocaleLowerCase().includes(currentState[id] ? currentState[id].toLocaleLowerCase() : '')
                        )}
                        onSelect={(e: any) => {
                            currentState[id] = e.name;
                            onRefChange();
                        }}
                        onSearch={(e) => {
                            const trimmedValue = e.trim();
                            if (trimmedValue === '') {
                                delete currentState[id];
                            } else {
                                currentState[id] = trimmedValue;
                            }
                            onRefChange();
                        }}
                        autoComplete="off"
                        type="text"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={id}
                    required={rest.required && isUndefined(currentState[id])}
                    currentState={currentState}
                    help={rest.help}
                    setUrl={rest.setUrl}
                    isDeprecated={isDeprecated}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
            </div>
            <div
                className={classNames({
                    'tw-w-96': !rest.wide,
                    'tw-w-128': rest.wide,
                })}
            >
                <Input
                    type={rest.isPassword ? stringType : 'string'}
                    defaultValue={currentState[id] || ''}
                    className=""
                    callToAction
                    onChange={(e) => {
                        const trimmedValue = e.target.value.trim();
                        if (trimmedValue === '') {
                            delete currentState[id];
                        } else {
                            currentState[id] = trimmedValue;
                        }
                        onRefChange();
                    }}
                    fullWidth
                    placeholder={rest.placeholder}
                    disabled={rest.disabled || readOnly}
                    multiline={rest.multiline}
                    rows={1}
                    isPassword={rest.isPassword}
                    rowsMax={Infinity}
                    endAdornment={
                        (!readOnly && rest.isPassword && (
                            <Icon
                                type={iconType}
                                onClick={handleEyeToggle}
                            />
                        )) ||
                        (readOnly && (
                            <Tooltip content={readOnlyText}>
                                <Icon
                                    type="lock"
                                    size="small"
                                ></Icon>
                            </Tooltip>
                        ))
                    }
                />
            </div>
        </div>
    );
};

export type SchemaBuilderArrayTypeObjectProps = {
    id: string;
    title: string;
    onRefChange: (ref?: any) => void;
    readOnly?: boolean;
    allowSticky?: boolean;
    description?: string;
    currentState: TSchemaBuilderRef;
    disabled?: boolean;
    items?: any;
    help?: string;
    setUrl?: string;
    isDeprecated?: boolean;
};

const SchemaBuilderArrayTypeObject: React.FC<SchemaBuilderArrayTypeObjectProps> = ({
    id,
    title,
    description,
    readOnly,
    onRefChange,
    allowSticky,
    currentState,
    ...rest
}) => {
    const forceRefresh = useForceRefresh();
    const keysRef = useRef(keysChecker(currentState[id]));

    const onAdd = () => {
        if (isUndefined(currentState[id])) {
            currentState[id] = [];
        }
        currentState[id].push({});
        keysRef.current.push(now());
        onRefChange();
        forceRefresh();
    };

    const removeItem = (index: any) => () => {
        currentState[id].splice(index, 1);
        keysRef.current.splice(index, 1);
        if (currentState[id].length === 0) {
            delete currentState[id];
        }
        onRefChange();
        forceRefresh();
    };

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <>
            <LayoutRow
                verticalAlign="center"
                align="space-between"
                className={classNames({
                    'tw-mb-2 tw-py-2 ': true,
                    'tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-bg-opacity-95': allowSticky,
                })}
            >
                <div>
                    <SchemaBuilderTitle
                        currentState={currentState}
                        id={id}
                        help={rest.help}
                        isDeprecated={rest.isDeprecated}
                        setUrl={rest.setUrl}
                    >
                        {title}
                    </SchemaBuilderTitle>
                    <SchemaBuilderDescription>{description}</SchemaBuilderDescription>
                </div>
                {!readOnly && (
                    <div>
                        <Tooltip content={<span>Add {pluralize.singular(title)}</span>}>
                            <IconButton
                                color="primary"
                                variant="contained"
                                noMargin
                                onClick={onAdd}
                            >
                                <Icon type="add" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </LayoutRow>

            {currentState[id] &&
                currentState[id].map((item: any, itemIndex: any) => {
                    return (
                        <div
                            className="tw-mt-4 tw-grid tw-grid-cols-auto-1fr-auto tw-gap-4"
                            key={keysRef.current[itemIndex]}
                        >
                            <div>
                                <div className="tw-sticky tw-top-24 tw-h-10 tw-w-10 tw-rounded-full tw-bg-amber-300 tw-px-4 tw-text-tiny tw-font-bold tw-text-amber-700">
                                    <LayoutCentered>
                                        <div>
                                            <span className="tw-block  tw-w-12 tw-text-center">
                                                {itemIndex + 1} / {currentState[id].length}
                                            </span>
                                        </div>
                                    </LayoutCentered>
                                </div>
                            </div>
                            <div>
                                <div className="tw-relative tw-overflow-auto tw-rounded-lg tw-border tw-border-slate-200 tw-pt-2 hover:tw-border-slate-300">
                                    <SchemaBuilderInternal
                                        properties={rest.items.properties}
                                        currentState={currentState[id][itemIndex]}
                                        onRefChange={onRefChange}
                                        level={1}
                                        allowSticky={false}
                                        readOnly={rest.disabled || readOnly}
                                    />
                                </div>
                            </div>
                            <div>
                                {!readOnly && (
                                    <div className="tw-sticky tw-top-24">
                                        <Tooltip
                                            content={
                                                <span>
                                                    Remove {pluralize.singular(title)} #{itemIndex + 1}
                                                </span>
                                            }
                                        >
                                            <IconButton
                                                color="danger"
                                                variant="contained"
                                                noMargin
                                                onClick={removeItem(itemIndex)}
                                            >
                                                <Icon type="remove" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export type SchemaBuilderArrayTypeStringProps = {
    id: string;
    title: string;
    readOnly?: boolean;
    description?: string;
    onRefChange: (ref?: any) => void;
    required?: boolean;
    help?: string;
    setUrl?: string;
    isDeprecated?: boolean;
    placeholder?: string;
    disabled?: boolean;
    currentState: TSchemaBuilderRef;
};

const SchemaBuilderArrayTypeString: React.FC<SchemaBuilderArrayTypeStringProps> = ({
    id,
    title,
    readOnly,
    description,
    onRefChange,
    currentState,
    ...rest
}) => {
    const forceRefresh = useForceRefresh();
    const internalRef = useRef(isDefined(currentState[id]) ? currentState[id] : []);
    const keysRef = useRef(keysChecker(currentState[id]));

    const updateRef = () => {
        currentState[id] = [...new Set(internalRef.current)].filter((value: any) => value.trim() !== ''); // Do not allow empty strings and duplicates.
    };

    const isNoneSelected = rest.required && (isUndefined(currentState[id]) || currentState[id].length === 0);

    const onAdd = () => {
        if (isUndefined(currentState[id])) {
            currentState[id] = [];
            onRefChange();
        }
        internalRef.current.push('');
        keysRef.current.push(now());
        forceRefresh();
    };

    const removeItem = (index: any) => () => {
        internalRef.current.splice(index, 1);
        keysRef.current.splice(index, 1);
        if (internalRef.current.length === 0) {
            delete currentState[id];
        } else {
            updateRef();
        }
        onRefChange();
        forceRefresh();
    };

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={id}
                    help={rest.help}
                    setUrl={rest.setUrl}
                    required={isNoneSelected}
                    currentState={currentState}
                    isDeprecated={rest.isDeprecated}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{description}</SchemaBuilderDescription>
            </div>
            <div className="tw-w-96">
                {internalRef.current.map((item: any, itemIndex: any) => {
                    const itemOccurrences = item ? internalRef.current.reduce((a: any, v: any) => (v === item ? a + 1 : a), 0) : 0;
                    return (
                        <div
                            className="tw-mb-2 tw-grid tw-grid-cols-1fr-auto tw-gap-4"
                            key={keysRef.current[itemIndex]}
                        >
                            <div
                                className={classNames({
                                    'tw-w-96': readOnly,
                                })}
                            >
                                <Input
                                    type="string"
                                    defaultValue={item}
                                    callToAction
                                    disabled={rest.disabled || readOnly}
                                    onChange={(e) => {
                                        internalRef.current[itemIndex] = e.target.value;
                                        updateRef();
                                        onRefChange();
                                    }}
                                    error={itemOccurrences > 1}
                                    helperText={
                                        itemOccurrences > 1 && (
                                            <div>
                                                {pluralize.singular(title)} "{item}" is already added
                                            </div>
                                        )
                                    }
                                    placeholder={rest.placeholder}
                                    endAdornment={
                                        readOnly && (
                                            <Tooltip content={readOnlyText}>
                                                <Icon
                                                    type="lock"
                                                    size="small"
                                                ></Icon>
                                            </Tooltip>
                                        )
                                    }
                                />
                            </div>
                            {!readOnly && (
                                <LayoutCentered>
                                    <Tooltip
                                        content={
                                            <span>
                                                Remove {pluralize.singular(title)} #{itemIndex + 1}
                                            </span>
                                        }
                                    >
                                        <IconButton
                                            color="danger"
                                            variant="contained"
                                            noMargin
                                            onClick={removeItem(itemIndex)}
                                        >
                                            <Icon
                                                type="remove"
                                                size="small"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </LayoutCentered>
                            )}
                        </div>
                    );
                })}
                {!readOnly && (
                    <LayoutRow align="end">
                        <Tooltip content={<span>Add {pluralize.singular(title)}</span>}>
                            <IconButton
                                color="primary"
                                variant="contained"
                                noMargin
                                onClick={onAdd}
                                disabled={currentState[id] && currentState[id].includes('')}
                            >
                                <Icon type="add" />
                            </IconButton>
                        </Tooltip>
                    </LayoutRow>
                )}
            </div>
        </div>
    );
};

export type SchemaBuilderArrayTypeEnumProps = {
    id: string;
    title: string;
    description?: string;
    readOnly?: boolean;
    onRefChange: (ref?: any) => void;
    items?: any;
    required?: boolean;
    placeholder?: string;
    minColumnWidth?: number;
    primaryContentClassName?: string;
    secondaryContentClassName?: string;
    disabled?: boolean;
    currentState: TSchemaBuilderRef;
    help?: string;
    setUrl?: string;
    isDeprecated?: boolean;
};

const SchemaBuilderArrayTypeEnum: React.FC<SchemaBuilderArrayTypeEnumProps> = ({
    id,
    title,
    description,
    readOnly,
    onRefChange,
    items,
    currentState,
    ...rest
}) => {
    const selectedOptions = currentState[id] ? items.options.filter((option: any) => currentState[id].includes(option.id)) : [];

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={id}
                    required={rest.required}
                    currentState={currentState}
                    help={rest.help}
                    setUrl={rest.setUrl}
                    isDeprecated={rest.isDeprecated}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{description}</SchemaBuilderDescription>
            </div>
            <div className="tw-w-96">
                <Select
                    // @ts-ignore - Select is not typed
                    options={items.options}
                    selectedOptions={selectedOptions}
                    onChange={(options: any) => {
                        if (options.length === 0) {
                            delete currentState[id];
                        } else {
                            currentState[id] = options.map((option: any) => option.id);
                        }
                        onRefChange();
                    }}
                    multiple
                    callToAction
                    fullWidth
                    multiline={selectedOptions.length > 1}
                    rows={1}
                    rowsMax={Infinity}
                    placeholder={rest.placeholder}
                    minColumnWidth={rest.minColumnWidth}
                    primaryContentClassName={rest.primaryContentClassName}
                    secondaryContentClassName={rest.secondaryContentClassName}
                    disabled={rest.disabled || readOnly}
                />
            </div>
        </div>
    );
};

export type SchemaBuilderRangeTypeProps = {
    id: string;
    title: string;
    description: string;
    onRefChange: () => void;
    readOnly: boolean;
    allowSticky: boolean;
    steps?: number;
    marks?: any;
    numberOfSteps: number;
    required: boolean;
    defaultValue: number[];
    currentState: TSchemaBuilderRef;
};

const SchemaBuilderRangeType: React.FC<SchemaBuilderRangeTypeProps> = ({
    id,
    title,
    description,
    onRefChange,
    allowSticky,
    steps,
    marks,
    readOnly,
    numberOfSteps,
    required,
    currentState,
    ...rest
}) => {
    const [value, setValue] = useState(() => {
        if (isUndefined(currentState[id])) {
            if (required) {
                currentState[id] = rest.defaultValue;
            } else {
                return [0, 0];
            }
        }
        return currentState[id];
    });

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={id}
                    required={required}
                    currentState={currentState}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{description}</SchemaBuilderDescription>
            </div>
            <div style={{ width: 20 * numberOfSteps }}>
                <Slider
                    defaultValue={value}
                    marks={marks}
                    step={1}
                    min={0}
                    max={numberOfSteps}
                    valueLabelDisplay="on"
                    onChange={(event, newValue) => {
                        currentState[id] = newValue;
                        onRefChange();
                    }}
                />
            </div>
        </div>
    );
};

export type SchemaBuilderUploadButtonTypeProps = {
    id: string;
    title: string;
    onRefChange: () => void;
    readOnly: boolean;
    required: boolean;
    currentState: TSchemaBuilderRef;
    description: string;
    tooltipContent?: string;
};

const SchemaBuilderUploadButtonType: React.FC<SchemaBuilderUploadButtonTypeProps> = ({ id, title, onRefChange, readOnly, required, currentState, ...rest }) => {
    const [savingChangesState, setSavingChangesState] = useState<{
        loading?: boolean;
        loadingMessage?: string;
        successMessage?: string;
        errorMessage?: string;
    }>({
        loading: false,
    });

    const buttonId = useRef(getUniqueId('upload-image-button'));

    const onImageChange = (fileDetails: any) => {
        if (fileDetails) {
            const [image] = fileDetails.files;
            if (image) {
                setSavingChangesState({
                    loading: true,
                    loadingMessage: 'Processing image',
                    successMessage: 'Validated',
                });
                if (image.size > 1024 * 1024) {
                    setTimeout(() => {
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            errorTitle: 'File is too big',
                            errorMessage: 'The maximum allowed file size is 1 MB',
                            loading: false,
                        }));
                    });
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(image);
                    fileReader.onload = function () {
                        const imageData = (fileReader.result as string | null)?.split('base64,')[1]; /* .replace(/\+/g, '-').replace(/\//g, '_') */
                        currentState[id] = imageData;
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            loading: false,
                        }));
                        onRefChange();
                    };
                    fileReader.onerror = function (error) {
                        setSavingChangesState({
                            errorMessage: 'Could not read image',
                        });
                        console.log('Error: ', error);
                    };
                }
            } else {
                delete currentState[id];
                onRefChange();
            }
        }
    };
    return (
        <LayoutRow
            verticalAlign="center"
            align="space-between"
        >
            <div>
                <SchemaBuilderTitle
                    id={id}
                    currentState={currentState}
                    required={currentState[id] ? false : true}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
            </div>
            <div>
                <LayoutRow verticalAlign="center">
                    {currentState[id] ? (
                        <Avatar
                            src={currentState[id].includes('data:image/png;base64') ? currentState[id] : `data:image/png;base64,${currentState[id]}`}
                            alt={currentState[id]}
                            variant="rounded"
                            className="tw-h-16 tw-w-16"
                        />
                    ) : (
                        <Tooltip
                            content={<div className="tw-whitespace-pre-wrap">{rest.tooltipContent}</div>}
                            interactive
                        >
                            <UploadButton
                                variant="outlined"
                                color="primary"
                                htmlId={buttonId.current}
                                accept=".jpeg,.png"
                                onChange={onImageChange}
                                noMargin
                                hideSelectedFiles
                                className="tw-h-16 tw-w-16 tw-rounded-xl tw-border-2 tw-border-dashed"
                                disabled={readOnly}
                            ></UploadButton>
                        </Tooltip>
                    )}
                    <LayoutRow verticalAlign="center">
                        {!readOnly && (
                            <UploadButton
                                variant={currentState[id] ? 'outlined' : 'contained'}
                                className={'tw-ml-2'}
                                color="primary"
                                htmlId={buttonId.current}
                                accept=".jpeg,.png"
                                onChange={onImageChange}
                                noMargin
                                hideSelectedFiles
                                size="large"
                            >
                                {currentState[id] ? 'Change image' : 'Select image'}
                            </UploadButton>
                        )}
                    </LayoutRow>
                </LayoutRow>
            </div>
            {/* @ts-ignore - SavingChanges is not typed */}
            <SavingChanges
                minLoadingTime={1000}
                {...savingChangesState}
            />
        </LayoutRow>
    );
};

export type SchemaBuilderAppleApplicationSearchDialogTypeProps = {
    id: string;
    title: string;
    description: string;
    onRefChange: () => void;
    readOnly: boolean;
    required: boolean;
    currentState: TSchemaBuilderRef;
    help: string;
    setUrl: string;
    isDeprecated: boolean;
    disabled: boolean;
    wide: boolean;
};

const SchemaBuilderAppleApplicationSearchDialogType: React.FC<SchemaBuilderAppleApplicationSearchDialogTypeProps> = ({
    id,
    title,
    description,
    onRefChange,
    readOnly,
    currentState,
    ...rest
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [SelectedBundleId, setSelectedBundleId] = useState();

    const handleDialogOpen = () => {
        if (!readOnly) {
            setIsDialogOpen(true);
        }
    };

    const handleDialogClose = (selectedValue: any) => {
        setIsDialogOpen(false);
        if (selectedValue) {
            currentState[id] = selectedValue;
            onRefChange();
        }
    };

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <SchemaBuilderTitle
                    id={id}
                    currentState={currentState}
                    required={rest.required && (isUndefined(currentState[id]) || currentState[id] === '')}
                >
                    {title}
                </SchemaBuilderTitle>
                <SchemaBuilderDescription>{description}</SchemaBuilderDescription>
            </div>
            <div className={classNames({ 'tw-w-96': !rest.wide, 'tw-w-128': rest.wide, position: 'relative' })}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                        type="text"
                        defaultValue={currentState[id] || ''}
                        className=""
                        callToAction
                        helperText={<SchemaBuilderHelp {...rest} />}
                        onChange={(e) => {
                            const trimmedValue = e.target.value.trim();
                            if (trimmedValue === '') {
                                delete currentState[id];
                            } else {
                                currentState[id] = trimmedValue;
                            }
                            onRefChange();
                        }}
                        value={SelectedBundleId}
                        fullWidth
                        style={{ flex: 1, marginRight: '8px' }}
                        placeholder=""
                        disabled={rest.disabled || readOnly}
                        rowsMax={Infinity}
                        endAdornment={
                            readOnly && (
                                <Tooltip content={readOnlyText}>
                                    <Icon
                                        type="lock"
                                        size="small"
                                    ></Icon>
                                </Tooltip>
                            )
                        }
                    />
                    {!readOnly && (
                        <Button
                            noMargin
                            color="primary"
                            variant="outlined"
                            onClick={handleDialogOpen}
                        >
                            Search
                        </Button>
                    )}
                </div>

                <AppleApplicationSearchDialog
                    open={isDialogOpen}
                    // @ts-ignore - AppleApplicationSearchDialog is not typed
                    onClose={() => setIsDialogOpen(false)}
                    // @ts-ignore - AppleApplicationSearchDialog is not typed
                    onSubmit={({ bundleId }) => {
                        setSelectedBundleId(bundleId);
                        currentState[id] = bundleId;
                        setIsDialogOpen(false);
                        onRefChange();
                    }}
                />
            </div>
            {isDialogOpen && (
                <AppleApplicationSearchDialog
                    // @ts-ignore - AppleApplicationSearchDialog is not typed
                    onClose={handleDialogClose}
                />
            )}
        </div>
    );
};

export type SchemaBuilderArrayTypeAppleApplicationSearchDialogProps = {
    id: string;
    title: string;
    readOnly?: boolean;
    description?: string;
    onRefChange: (ref?: any) => void;
    currentState: TSchemaBuilderRef;
    isDeprecated?: boolean;
    disabled?: boolean;
    help?: string;
    setUrl?: string;
    required?: boolean;
};

const SchemaBuilderArrayTypeAppleApplicationSearchDialog: React.FC<SchemaBuilderArrayTypeAppleApplicationSearchDialogProps> = ({
    id,
    title,
    readOnly,
    description,
    onRefChange,
    currentState,
    ...rest
}) => {
    const forceRefresh = useForceRefresh();
    const internalRef = useRef(isDefined(currentState[id]) ? currentState[id] : []);
    const keysRef = useRef(keysChecker(currentState[id]));
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        if (!readOnly) {
            setIsDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const onAdd = () => {
        if (isUndefined(currentState[id])) {
            currentState[id] = [];
        }
        handleDialogOpen();
    };

    const removeItem = (index: any) => () => {
        internalRef.current.splice(index, 1);
        keysRef.current.splice(index, 1);
        if (internalRef.current.length === 0) {
            delete currentState[id];
        } else {
            currentState[id] = [...internalRef.current];
        }
        onRefChange();
        forceRefresh();
    };

    if (readOnly && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <>
            <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
                <div>
                    <SchemaBuilderTitle
                        id={id}
                        required={rest.required}
                        currentState={currentState}
                        help={rest.help}
                        setUrl={rest.setUrl}
                    >
                        {title}
                    </SchemaBuilderTitle>
                    <SchemaBuilderDescription>{description}</SchemaBuilderDescription>
                </div>
                <div className="tw-w-96">
                    {internalRef.current.map((item: never, itemIndex: any) => {
                        return (
                            <div
                                className="tw-mb-2 tw-grid tw-grid-cols-1fr-auto tw-gap-4"
                                key={keysRef.current[itemIndex]}
                            >
                                <div className="">
                                    <Input
                                        type="text"
                                        defaultValue=""
                                        className=""
                                        callToAction
                                        onChange={(e) => {
                                            internalRef.current[itemIndex] = e.target.value;
                                            onRefChange();
                                        }}
                                        value={currentState[id][itemIndex]}
                                        fullWidth
                                        placeholder=""
                                        disabled={rest.disabled || readOnly}
                                        rowsMax={Infinity}
                                        endAdornment={
                                            readOnly && (
                                                <Tooltip content={readOnlyText}>
                                                    <Icon
                                                        type="lock"
                                                        size="small"
                                                    ></Icon>
                                                </Tooltip>
                                            )
                                        }
                                    />
                                </div>
                                {!readOnly && (
                                    <LayoutCentered>
                                        <Tooltip
                                            content={
                                                <span>
                                                    Remove {pluralize.singular(title)} #{itemIndex + 1}
                                                </span>
                                            }
                                        >
                                            <IconButton
                                                color="danger"
                                                variant="contained"
                                                noMargin
                                                onClick={removeItem(itemIndex)}
                                            >
                                                <Icon
                                                    type="remove"
                                                    size="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </LayoutCentered>
                                )}
                            </div>
                        );
                    })}
                    {!readOnly && (
                        <LayoutRow align="end">
                            <Tooltip content={<span>Add {pluralize.singular(title)}</span>}>
                                <IconButton
                                    color="primary"
                                    variant="contained"
                                    noMargin
                                    onClick={onAdd}
                                >
                                    <Icon type="add" />
                                </IconButton>
                            </Tooltip>
                        </LayoutRow>
                    )}
                </div>
                {isDialogOpen && (
                    <AppleApplicationSearchDialog
                        // @ts-ignore - AppleApplicationSearchDialog is not typed
                        onClose={handleDialogClose}
                    />
                )}
            </div>
            <AppleApplicationSearchDialog
                open={isDialogOpen}
                // @ts-ignore - AppleApplicationSearchDialog is not typed
                onClose={() => setIsDialogOpen(false)}
                // @ts-ignore - AppleApplicationSearchDialog is not typed
                onSubmit={({ bundleId }) => {
                    currentState[id] = [...internalRef.current];
                    if (!currentState[id].includes(bundleId)) {
                        internalRef.current.push(bundleId);
                        currentState[id] = [...internalRef.current];
                    }
                    setIsDialogOpen(false);
                    onRefChange();
                    forceRefresh();
                }}
            />
        </>
    );
};

type SchemaBuilderDateTypeProps = {
    id: string;
    title: string;
    onRefChange: () => void;
    readOnly: boolean;
    required: boolean;
    currentState: TSchemaBuilderRef;
    description: string;
    placeholder: string;
    disabled: boolean;
    wide: boolean;
    help: string;
    setUrl: string;
};

const SchemaBuilderDateType: React.FC<SchemaBuilderDateTypeProps> = ({ id, title, onRefChange, readOnly, required, currentState, ...rest }) => {
    const [value, setValue] = useState(currentState[id] || '');

    const handleChange = (e) => {
        const newValue = e.target.value.trim();
        setValue(newValue);
        if (newValue === '') {
            delete currentState[id];
        } else {
            currentState[id] = newValue;
        }
        onRefChange();
    };

    const isNoneSelected = required && (isUndefined(currentState[id]) || currentState[id] === '');

    return (
        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
            <div>
                <div className="tw-flex tw-items-center">
                    <SchemaBuilderTitle
                        help={rest.help}
                        setUrl={rest.setUrl}
                        required={isNoneSelected}
                    >
                        {title}
                    </SchemaBuilderTitle>
                </div>
                <SchemaBuilderDescription>{rest.description}</SchemaBuilderDescription>
            </div>
            <div
                className={classNames({
                    'tw-w-96': !rest.wide,
                    'tw-w-128': rest.wide,
                })}
            >
                <Input
                    type="date"
                    defaultValue={value}
                    callToAction
                    onChange={handleChange}
                    fullWidth
                    placeholder={rest.placeholder}
                    disabled={rest.disabled || readOnly}
                />
            </div>
        </div>
    );
};

export type SchemaBuilderTitleProps = {
    required?: boolean;
    isDeprecated?: boolean;
    deprecatedUrl?: string;
    help?: string;
    setUrl?: string;
    children: React.ReactNode;
    id?: string;
    readOnly?: boolean;
    className?: string;
    isBoolean?: boolean;
    currentState?: TSchemaBuilderRef;
};

const SchemaBuilderTitle: React.FC<SchemaBuilderTitleProps> = ({
    required,
    isDeprecated,
    deprecatedUrl,
    help,
    setUrl,
    children,
    id,
    readOnly,
    className,
    isBoolean = false,
    currentState,
    ...props
}) => {
    const isRequired = isFunction(required) ? required(currentState) : required;

    if (readOnly && currentState && id && (isUndefined(currentState[id]) || currentState[id] === null)) {
        return null;
    }

    return (
        <LayoutRow verticalAlign="center">
            <b
                className={classNames('tw-mr-2 tw-block tw-text-base tw-font-semibold tw-text-stone-700', className)}
                {...props}
            >
                {children}
            </b>
            {help && (
                <span className={`tw-mr-2`}>
                    <SchemaBuilderHelp
                        help={help}
                        isBoolean={isBoolean}
                        setUrl={setUrl}
                    />
                </span>
            )}
            {isRequired && (
                <Grow in={true}>
                    {/* @ts-ignore - TailwindBadge is not typed */}
                    <TailwindBadge
                        color="custom"
                        size="small"
                        className="tw-border tw-border-red-300 tw-text-red-500"
                    >
                        Required
                    </TailwindBadge>
                </Grow>
            )}
            {isDeprecated && (
                <SchemaBuilderDeprecated
                    isDeprecated={isDeprecated}
                    isBoolean={isBoolean}
                />
            )}
        </LayoutRow>
    );
};

export type SchemaBuilderDescriptionProps = {
    children: React.ReactNode;
};

const SchemaBuilderDescription: React.FC<SchemaBuilderDescriptionProps> = ({ children, ...props }) => {
    return (
        <div
            className="tw-mt-2 tw-block tw-whitespace-pre-wrap tw-text-xs tw-font-semibold tw-leading-relaxed tw-text-slate-500"
            {...props}
            dangerouslySetInnerHTML={{ __html: children as string }}
        />
    );
};

export type SchemaBuilderHelpProps = {
    help?: string;
    isBoolean?: boolean;
    setUrl?: string;
};

const SchemaBuilderHelp: React.FC<SchemaBuilderHelpProps> = ({ help, isBoolean = false, setUrl }) => {
    if (help) {
        const currentUrl = window.location.href;
        let baseUrl;
        if (currentUrl.includes('android') && currentUrl.includes('configuration')) {
            baseUrl = 'https://developers.google.com/android/management/reference/rest/v1/enterprises.policies';
        } else if (currentUrl.includes('android') && currentUrl.includes('application')) {
            baseUrl = 'https://developers.google.com/android/management/reference/rest/v1/enterprises.applications';
        } else if (currentUrl.includes('apple')) {
            baseUrl = 'https://developer.apple.com/documentation/devicemanagement';
        } else {
            return null;
        }

        const separator = currentUrl.includes('apple') ? '/' : '#';
        const fullUrl = `${baseUrl}${separator}${setUrl ? setUrl : ''}`;
        const linkText = setUrl ? 'Learn More' : '';

        const tooltipContentWithLink = (
            <div>
                <p>{help}</p>
                {setUrl && (
                    <div>
                        <br></br>
                        <a
                            href={fullUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline' }}
                        >
                            {linkText}
                        </a>
                    </div>
                )}
            </div>
        );
        return (
            <Tooltip
                interactive
                content={tooltipContentWithLink}
            >
                <Icon
                    type="infoOutlined"
                    className="tw-text-xl tw-text-gray-400 "
                >
                    <div
                        className={classNames({
                            'tw-mb-1 tw-mt-1 tw-block tw-text-xs tw-font-normal tw-italic': true,
                            'tw-pl-8 tw-text-right': !isBoolean,
                        })}
                        dangerouslySetInnerHTML={{ __html: help }}
                    />
                </Icon>
            </Tooltip>
        );
    }
    return null; // No help property found
};

export type SchemaBuilderDeprecatedProps = {
    isDeprecated: boolean;
    isBoolean?: boolean;
};

const SchemaBuilderDeprecated: React.FC<SchemaBuilderDeprecatedProps> = ({ isDeprecated }) => {
    if (isDeprecated) {
        const currentUrl = window.location.href;
        let baseUrl;
        let deprecatedText;

        if (currentUrl.includes('android') && currentUrl.includes('configuration')) {
            baseUrl = 'https://developers.google.com/android/management/reference/rest/v1/enterprises.policies#';
            deprecatedText = 'This field has been deprecated by Google. ';
        } else if (currentUrl.includes('android') && currentUrl.includes('application')) {
            baseUrl = 'https://developers.google.com/android/management/reference/rest/v1/enterprises.applications';
            deprecatedText = 'This field has been deprecated by Google. ';
        } else if (currentUrl.includes('apple')) {
            baseUrl = 'https://developer.apple.com/documentation/devicemanagement';
            deprecatedText = 'This field has been deprecated by Apple. ';
        } else {
            return null;
        }

        const linkUrl = `${baseUrl}`;
        return (
            <Tooltip content={deprecatedText}>
                {/* @ts-ignore - TailwindBadge is not type */}
                <TailwindBadge
                    color="red"
                    dark
                    size="small"
                >
                    <p className="tw-text-xs">Deprecated</p>
                </TailwindBadge>
            </Tooltip>
        );
    }
    return null; // Return null if not deprecated, meaning no badge is displayed
};

export { SchemaBuilder, SchemaBuilderDescription, SchemaBuilderTitle };
