import { VpnOnDemandRulesElement } from '../PayloadElements/VpnOnDemandRulesElement';
//Documentation
//https://developer.apple.com/documentation/devicemanagement/vpn

//AppLayerVPN
//https://developer.apple.com/documentation/devicemanagement/applayervpn

export const AppleVPNConfiguration = {
    schema: {
        schemaId: 'appleVPNConfiguration',
        category: 'VPN',
        title: 'VPN',
        type: 'object',
        properties: {
            'com.apple.vpn.managed': {
                title: '',
                type: 'object',
                properties: {
                    UserDefinedName: {
                        title: 'User Defined Name',
                        description: 'The description of the VPN connection that the system displays on the device.',
                        type: 'string',
                        required: true,
                    },
                    VPNType: {
                        title: 'VPN Type',
                        description: 'The type of the VPN, which defines which settings are appropriate for this VPN payload.',
                        help: 'If the type is VPN, then the system requires a value for VPNSubType. TransparentProxy is only available in macOS. L2TP and IPSec aren’t available in tvOS. AlwaysOn is only available on iOS and Apple Watch pairing isn’t supported with AlwaysOn. For a previously paired Apple Watch, all phone-watch communications cease when AlwaysOn is enabled. Not available in watchOS.',
                        type: 'string',
                        required: true,
                        options: [
                            {
                                id: 'VPN',
                                name: 'VPN',
                            },
                            {
                                id: 'L2TP',
                                name: 'L2TP',
                            },
                            {
                                id: 'IPSec',
                                name: 'IPSec',
                            },
                            {
                                id: 'AlwaysOn',
                                name: 'AlwaysOn',
                            },
                            {
                                id: 'IKEv2',
                                name: 'IKEv2',
                            },
                            {
                                id: 'TransparentProxy',
                                name: 'TransparentProxy',
                            },
                        ],
                    },
                    VPNSubType: {
                        title: 'VPN Sub Type',
                        description: 'An identifier for a vendor-specified configuration dictionary when the value for VPN Type is VPN.',
                        help: 'If VPN Type is VPN, the system requires this field. If the configuration targets a VPN solution that uses a VPN plugin, then this field contains the bundle identifier of the plugin. If the configuration targets a VPN solution that uses a network extension provider, then this field contains the bundle identifier of the app that contains the provider. Contact the VPN solution vendor for the value of the identifier. If VPN Type is IKEv2, then the VPNSubType field is optional and reserved for future use. If it’s specified, it needs to contain an empty string.',
                        type: 'string',
                        required: (siblings: any) => {
                            if (siblings.VPNType === 'VPN') {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'VPN') {
                                return true;
                            } else {
                                delete siblings.VPNSubType;
                                return false;
                            }
                        },
                        options: [
                            {
                                id: 'com.cisco.anyconnect',
                                name: 'Cisco AnyConnect',
                            },
                            {
                                id: 'net.pulsesecure.PulseSecure.vpnplugin',
                                name: 'PulseSecure',
                            },
                            {
                                id: 'com.f5.F5-Edge-Client.vpnplugin',
                                name: 'F5 SSL',
                            },
                            {
                                id: 'com.sonicwall.SonicWALL-SSLVPN.vpnplugin',
                                name: 'SonicWALL Mobile Connect',
                            },
                            {
                                id: 'com.arubanetworks.aruba-via.vpnplugin',
                                name: 'Aruba VIA',
                            },
                            {
                                id: 'com.checkpoint.CheckPoint-VPN.vpnplugin',
                                name: 'Check Point VPN',
                            },
                        ],
                    },
                    DNS: {
                        title: 'DNS',
                        description: 'A dictionary to use for all VPN types.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType) {
                                return true;
                            }
                            delete siblings.DNS;
                            return false;
                        },
                        properties: {
                            ServerAddresses: {
                                title: 'Server Addresses',
                                description: 'The list of DNS server IP address strings.',
                                help: 'These IP addresses can be a mixture of IPv4 and IPv6 addresses.',
                                type: 'array',
                                required: true,
                                items: {
                                    type: 'string',
                                },
                            },
                            ServerName: {
                                title: 'Server Name',
                                description:
                                    'The hostname of a DNS-over-TLS server to validate the server certificate, as defined in RFC 7858. If ServerAddresses isn’t specified, the system uses the hostname to determine the server addresses.',
                                help: 'This key is required if the DNSProtocol is TLS.',
                                type: 'string',
                            },
                            DNSProtocol: {
                                title: 'DNS Protocol',
                                description: 'The transport protocol to communicate with the DNS server.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'ClearText',
                                        name: 'Cleartext',
                                    },
                                    {
                                        id: 'HTTPS',
                                        name: 'HTTPS',
                                    },
                                    {
                                        id: 'TLS',
                                        name: 'TLS',
                                    },
                                ],
                            },
                            DomainName: {
                                title: 'Domain Name',
                                description: 'The primary domain of the tunnel.',
                                type: 'string',
                            },
                            PayloadCertificateUUID: {
                                title: 'Payload Certificate UUID',
                                description:
                                    'That UUID that points to an identity certificate payload. The system uses this identity to authenticate the user to the DNS resolver',
                                type: 'string',
                            },
                            SearchDomains: {
                                title: 'Search Domains',
                                description: 'The list of domain strings used to fully qualify single-label host names.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            ServerURL: {
                                title: 'Server URL',
                                description:
                                    'The URI template of a DNS-over-HTTPS server, as defined in RFC 8484, which needs to use the https:// scheme. The system uses the hostname or address in the URL to validate the server certificate. If ServerAddresses isn’t specified, the system uses the hostname or address in the URL to determine the server addresses.',
                                help: 'This key is required if the DNSProtocol is HTTPS.',
                                type: 'string',
                            },
                            SupplementalMatchDomains: {
                                title: 'Supplemental Match Domains',
                                description:
                                    'The list of domain strings used to determine which DNS queries use the DNS resolver settings in ServerAddresses. The system uses this key to create a split DNS configuration where it resolves only hosts in certain domains using the tunnel’s DNS resolver. The system uses the default resolver for hosts that aren’t in one of the domains in this list. Split-tunnel configurations can direct all DNS queries to the VPN DNS servers before the primary DNS servers. If the VPN tunnel becomes the network’s default route, the servers listed in ServerAddresses become the default resolver and the system ignores the SupplementalMatchDomains list.',
                                help: 'If SupplementalMatchDomains contains the empty string it becomes the default domain.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            SupplementalMatchDomainsNoSearch: {
                                title: 'Supplemental Match Domains No Search',
                                description: 'If false, append the domains in the SupplementalMatchDomains list to the resolver’s list of search domains.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                        },
                    },
                    VPN: {
                        title: 'VPN',
                        description: 'The dictionary to use to specify a VPN when VPN Type is VPN, IPSec, or IKEv2.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'VPN' || siblings.VPNType === 'IPSec' || siblings.VPNType === 'IKEv2') {
                                return true;
                            }
                            delete siblings.VPN;
                            return false;
                        },
                        properties: {
                            AuthenticationMethod: {
                                title: 'Authentication Method',
                                description: 'The authentication method for the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'Certificate',
                                        name: 'Certificate',
                                    },
                                    {
                                        id: 'Password',
                                        name: 'Password',
                                    },
                                    {
                                        id: 'Password+Certificate',
                                        name: 'Password+ and Certificate',
                                    },
                                ],
                            },
                            DisconnectOnIdle: {
                                title: 'Disconnect On Idle',
                                description: 'If true, disconnects after an on demand connection idles.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            DisconnectOnIdleTimer: {
                                title: 'Disconnect On Idle Timer',
                                description: 'The length of time to wait before disconnecting an on demand connection.',
                                help: 'The maximum allowed value is 15.',
                                type: 'number',
                            },
                            EnforceRoutes: {
                                title: 'Enforce Routes',
                                description: 'If true, all the VPN’s non-default routes take precedence over any locally defined routes.',
                                help: 'If IncludeAllNetworks is true, the system ignores the value of EnforceRoutes.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeAPNs: {
                                title: 'Exclude APNs',
                                description:
                                    'If true and IncludeAllNetworks is true, then the system excludes the network traffic for the Apple Push Notification service (APNs) from the tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeCellularServices: {
                                title: 'Exclude Cellular Services',
                                description:
                                    'If true and IncludeAllNetworks is true, then the system excludes the network traffic for cellular services from the tunnel.',
                                help: 'Note that some cellular carriers route cellular services traffic directly to the carrier network, bypassing the internet. Such cellular services traffic is always excluded from the tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeLocalNetworks: {
                                title: 'Exclude Local Networks',
                                description: 'If true and IncludeAllNetworks is true, routes all local network traffic outside the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            IncludeAllNetworks: {
                                title: 'Include All Networks',
                                description: 'If true, all network traffic routes through the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            OnDemandMatchDomainsAlways: {
                                title: 'On Demand Match Domains Always',
                                description: 'A list of domain names.',
                                help: 'The system treats associated domain names as though they’re associated with the OnDemandMatchDomainsOnRetry key. This behavior can be overridden by OnDemandRules.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            OnDemandMatchDomainsNever: {
                                title: 'On Demand Match Domains Never',
                                description:
                                    'A list of domain names. If the host name ends with one of these domain names, the system doesn’t start the VPN automatically. The system uses this value to exclude a subdomain within an included domain.',
                                help: 'In iOS 7 and later, this key is deprecated (but still supported) in favor of EvaluateConnection actions in the OnDemandRules dictionaries.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            OnDemandMatchDomainsOnRetry: {
                                title: 'On Demand Match Domains On Retry',
                                description:
                                    'If the host name ends with one of these domain names and a DNS query for that domain name fails, the system starts the VPN automatically.',
                                help: 'In iOS 7 and later, this key is deprecated (but still supported) in favor of EvaluateConnection actions in the OnDemandRules dictionaries.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            OnDemandRules: VpnOnDemandRulesElement,
                            OnDemandUserOverrideDisabled: {
                                title: 'On Demand User Override Disabled',
                                description: 'If true, the system disables the Connect On Demand toggle in Settings for this configuration.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            Password: {
                                title: 'Password',
                                description: 'The password for the VPN account.',
                                type: 'string',
                            },
                            PayloadCertificateUUID: {
                                title: 'Payload Certificate UUID',
                                description: 'The UUID of the certificate payload within the same profile to use for account credentials.',
                                type: 'string',
                            },
                            ProviderBundleIdentifier: {
                                title: 'Provider Bundle Identifier',
                                description: 'The bundle identifier of the VPN provider app.',
                                type: 'string',
                            },
                            ProviderDesignatedRequirement: {
                                title: 'Provider Designated Requirement',
                                description: 'If the VPN provider is implemented as a system extension, this field is required.',
                                type: 'string',
                            },
                            ProviderType: {
                                title: 'Provider Type',
                                description:
                                    'The type of VPN service. If the value is app-proxy, the service tunnels traffic at the app level. If the value is packet-tunnel, the service tunnels traffic at the IP layer.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'app-proxy',
                                        name: 'app-proxy',
                                    },
                                    {
                                        id: 'packet-tunnel',
                                        name: 'packet-tunnel',
                                    },
                                ],
                            },
                        },
                    },
                    AlwaysOn: {
                        title: 'Always On',
                        description: 'The dictionary to use when VPN is set to AlwaysOn.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'AlwaysOn') {
                                return true;
                            }
                            delete siblings.AlwaysOn;
                            return false;
                        },
                        properties: {
                            TunnelConfigurations: {
                                title: 'Tunnel Configurations',
                                description: 'An array that contains an arbitrary number of tunnel configurations.',
                                type: 'array',
                                required: true,
                                items: {
                                    type: 'object',
                                    properties: {
                                        ProtocolType: {
                                            title: 'Protocol Type',
                                            description: 'The type of connection, which needs to be IKEv2.',
                                            type: 'string',
                                            required: true,
                                            options: [
                                                {
                                                    id: 'IKEv2',
                                                    name: 'IKEv2',
                                                },
                                            ],
                                        },
                                        Interfaces: {
                                            title: 'Interfaces',
                                            description: 'The interfaces to apply this configuration to',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'Cellular',
                                                    name: 'Cellular',
                                                },
                                                {
                                                    id: 'Wifi',
                                                    name: 'WiFi',
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                            AllowAllCaptiveNetworkPlugins: {
                                title: 'Allow All Captive Network Plugins',
                                description:
                                    'If true, allows traffic from all captive networking apps outside the VPN tunnel to perform captive network handling.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            AllowCaptiveWebSheet: {
                                title: 'Allow Captive Web Sheet',
                                description: 'true, allows traffic from Captive Web Sheet outside the VPN tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            AllowedCaptiveNetworkPlugins: {
                                title: 'Allowed Captive Network Plugins',
                                description:
                                    'The array of captive networking apps whose traffic is allowed outside the VPN tunnel, to perform captive network handling.',
                                help: 'Used only when AllowAllCaptiveNetworkPlugins is false.',
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        BundleIdentifier: {
                                            title: 'Bundle Identifier',
                                            description: 'The bundle ID for the app that’s allowed on the captive network.',
                                            type: 'string',
                                            required: true,
                                        },
                                    },
                                },
                            },
                            ApplicationExceptions: {
                                title: 'Application Exceptions',
                                description: 'The dictionary that defines which applications can have traffic outside the VPN tunnel.',
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        BundleIdentifier: {
                                            title: 'Bundle Identifier',
                                            description: 'The app´s bundle ID',
                                            type: 'string',
                                            required: true,
                                        },
                                        LimitToProtocols: {
                                            title: 'Limit To Protocols',
                                            description: 'Limit the exception to only the specified list of protocols, with support for UDP only.',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'UDP',
                                                    name: 'UDP',
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                            ServiceExceptions: {
                                title: 'Service Exception',
                                description: 'An array that contains an arbitrary number of service exceptions.',
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        Action: {
                                            title: 'Action',
                                            description: 'The action to take with network connections from the named service.',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'Allow',
                                                    name: 'Allow',
                                                },
                                                {
                                                    id: 'Drop',
                                                    name: 'Drop',
                                                },
                                            ],
                                        },
                                        ServiceName: {
                                            title: 'Service Name',
                                            description: 'The name of a service that’s exempt from Always On VPN.',
                                            help: 'CellularServices is available in iOS 11.3 and later; it exempts VoLTE, IMS and MMS. WiFiCalling is exempted in iOS 13.4 and later.',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'Voicemail',
                                                    name: 'VoiceMail',
                                                },
                                                {
                                                    id: 'AirPrint',
                                                    name: 'AirPrint',
                                                },
                                                {
                                                    id: 'CellularServices',
                                                    name: 'CellularServices',
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                            UIToggleEnabled: {
                                title: 'UI Toggle Enabled',
                                description: 'If true, allows the user to disable the VPN configuration.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                        },
                    },
                    IKEv2: {
                        title: 'IKEv2',
                        description: 'The dictionary to use when VPN Type is IKEv2.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'IKEv2') {
                                return true;
                            }
                            delete siblings.IKEv2;
                            return false;
                        },
                        properties: {
                            LocalIdentifier: {
                                title: 'Local ID',
                                description: 'Identifier of the IKEv2 client',
                                type: 'string',
                                required: true,
                            },
                            RemoteAddress: {
                                title: 'Remote Address',
                                description: 'The IP address or host name of the VPN server',
                                type: 'string',
                                required: true,
                            },
                            RemoteIdentifier: {
                                title: 'Remote Identifier',
                                description: 'The remote ID',
                                type: 'string',
                                required: true,
                            },
                            AuthenticationMethod: {
                                title: 'Authentication Method',
                                description:
                                    'To enable EAP-only authentication, set this to None and ExtendedAuthEnabled to 1. If this is None and the ExtendedAuthEnabled key isn’t set, the authentication configuration defaults to SharedSecret.',
                                help: 'The type of authentication method for the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'None',
                                        name: 'None',
                                    },
                                    {
                                        id: 'SharedSecret',
                                        name: 'SharedSecret',
                                    },
                                    {
                                        id: 'Certificate',
                                        name: 'Certificate',
                                    },
                                ],
                            },
                            AuthName: {
                                title: 'Auth Name',
                                description: 'The user name to use for authentication.',
                                type: 'string',
                            },
                            AuthPassword: {
                                title: 'Auth Password',
                                description: 'The password to use for authentication.',
                                type: 'string',
                            },
                            CertificateType: {
                                title: 'Certificate Type',
                                description: 'The type of PayloadCertificateUUID to use for IKEv2 machine authentication.',
                                help: 'If this key is included, the system requires a value for ServerCertificateIssuerCommonName.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'RSA',
                                        name: 'RSA',
                                    },
                                    {
                                        id: 'ECDSA256',
                                        name: 'ECDSA256',
                                    },
                                    {
                                        id: 'ECDSA384',
                                        name: 'ECDSA384',
                                    },
                                    {
                                        id: 'ECDSA521',
                                        name: 'ECDSA521',
                                    },
                                    {
                                        id: 'RSA-PSS',
                                        name: 'RSA-PSS',
                                    },
                                ],
                            },
                            ChildSecurityAssociationParameters: {
                                title: 'Child Security Association Parameters',
                                description: 'The ChildSecurityAssociationParameters dictionaries.',
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        diffieHellmanGroup: {
                                            title: 'Diffie-Hellman Group',
                                            description:
                                                'Diffie-Hellman creates a secret connection between two parties, even on a monitored network like the internet.',
                                            help: 'Minimum allowed Diffie Hellman Group is 14 in iOS 14.2 and later.',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 1,
                                                    name: '1',
                                                },
                                                {
                                                    id: 2,
                                                    name: '2',
                                                },
                                                {
                                                    id: 5,
                                                    name: '5',
                                                },
                                                {
                                                    id: 14,
                                                    name: '14',
                                                },
                                                {
                                                    id: 15,
                                                    name: '15',
                                                },
                                                {
                                                    id: 16,
                                                    name: '16',
                                                },
                                                {
                                                    id: 17,
                                                    name: '17',
                                                },
                                                {
                                                    id: 18,
                                                    name: '18',
                                                },
                                                {
                                                    id: 19,
                                                    name: '19',
                                                },
                                                {
                                                    id: 20,
                                                    name: '20',
                                                },
                                                {
                                                    id: 21,
                                                    name: '21',
                                                },
                                                {
                                                    id: 31,
                                                    name: '31',
                                                },
                                                {
                                                    id: 32,
                                                    name: '32',
                                                },
                                            ],
                                        },
                                        EncryptionAlgorithm: {
                                            title: 'Encryption Algorithm',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'DES',
                                                    name: 'DES',
                                                },
                                                {
                                                    id: '3DES',
                                                    name: '3DES',
                                                },
                                                {
                                                    id: 'AES-128',
                                                    name: 'AES-128',
                                                },
                                                {
                                                    id: 'AES-256',
                                                    name: 'AES-256',
                                                },
                                                {
                                                    id: 'AES-128-GCM',
                                                    name: 'AES-128-GCM',
                                                },
                                                {
                                                    id: 'AES-256-GCM',
                                                    name: 'AES-256-GCM',
                                                },
                                                {
                                                    id: 'ChaCha20Poly1305',
                                                    name: 'ChaCha20Poly1305',
                                                },
                                            ],
                                        },
                                        IntegrityAlgorithm: {
                                            title: 'Integrity Algorithm',
                                            type: 'string',
                                            options: [
                                                {
                                                    id: 'SHA1-96',
                                                    name: 'SHA1-96',
                                                },
                                                {
                                                    id: 'SHA1-160',
                                                    name: 'SHA1-160',
                                                },
                                                {
                                                    id: 'SHA2-256',
                                                    name: 'SHA2-256',
                                                },
                                                {
                                                    id: 'SHA2-384',
                                                    name: 'SHA2-384',
                                                },
                                                {
                                                    id: 'SHA2-512',
                                                    name: 'SHA2-512',
                                                },
                                            ],
                                        },
                                        LifeTimeInMinutes: {
                                            title: 'LifeTime In Minutes',
                                            description: 'The Security Association lifetime (rekey interval) in minutes',
                                            help: 'Minimum Value: 10, Maximum Value: 1440',
                                            type: 'number',
                                        },
                                    },
                                },
                            },
                            DeadPeerDetectionRate: {
                                title: 'Dead Peer Detection Rate',
                                description: '',
                                type: 'string',
                                options: [
                                    {
                                        id: 'None',
                                        name: 'None: No keepalive',
                                    },
                                    {
                                        id: 'Low',
                                        name: 'Low: Send keepalive every 30 minutes',
                                    },
                                    {
                                        id: 'Medium',
                                        name: 'Medium: Send keepalive every 10 minutes',
                                    },
                                    {
                                        id: 'High',
                                        name: 'High: Send keepalive every 1 minute',
                                    },
                                ],
                            },
                            DisableMOBIKE: {
                                title: 'Disable MOBIKE',
                                description: 'If true, the system disables MOBIKE.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            DisableRedirect: {
                                title: 'Disable Redirect',
                                description:
                                    'If true, the system disables IKEv2 redirect. If not set, the system redirects an IKEv2 connection when it receives a redirect request from the server.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            EnableCertificateRevocationCheck: {
                                title: 'Enable Certificate Revocation Check',
                                description:
                                    'If true, the system performs a certificate revocation check for IKEv2 connections. This is a best-effort revocation check and server response timeouts won’t cause it to fail.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            EnableFallback: {
                                title: 'Enable Fallback',
                                description:
                                    'If true, the system enables a tunnel over cellular data to carry traffic that’s eligible for WiFi Assist and also requires VPN. Enabling fallback requires that the server support multiple tunnels for a single user.',
                                help: 'This field is available in iOS 13 and later, and tvOS 17 and later. Not available in watchOS.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            EnablePFS: {
                                title: 'Enable PFS',
                                description: 'If true,  enables Perfect Forward Secrecy (PFS) for IKEv2 Connections.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            EnforceRoutes: {
                                title: 'Enforce Routes',
                                description:
                                    'If true, all the VPN’s non-default routes take precedence over any locally-defined routes. If IncludeAllNetworks is true, the system ignores EnforceRoutes.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeAPNs: {
                                title: 'Exclude APNs',
                                description:
                                    'If true and IncludeAllNetworks is true, the system excludes network traffic for the Apple Push Notification service (APNs) from the tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeCellularServices: {
                                title: 'Exclude Cellular Services',
                                description:
                                    'If true and IncludeAllNetworks is true, the system excludes internet-routable network traffic for cellular services (VoLTE, Wi-Fi Calling, IMS, MMS, Visual Voicemail, etc.) from the tunnel. Note that some cellular carriers route cellular services traffic directly to the carrier network, bypassing the internet. Such cellular services traffic is always excluded from the tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeLocalNetworks: {
                                title: 'Exclude Local Networks',
                                description:
                                    'If true and either IncludeAllNetworks or EnforceRoutes are true, then the system routes local network traffic outside of the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExtendedAuthEnabled: {
                                title: 'Extended Auth Enabled',
                                description: 'If true, enables EAP-only authentication.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            IKESecurityAssociationParameters: {
                                title: 'IKE Security Association Parameters',
                                description: 'These parameters apply to Child Security Association unless ChildSecurityAssociationParameters is specified.',
                                type: 'object',
                                properties: {
                                    DiffieHellmanGroup: {
                                        title: 'Diffie Hellman Group',
                                        description: '',
                                        help: 'For AlwaysOn VPN in iOS 14.2 and later, the minimum allowed value is 14.',
                                        type: 'string',
                                        options: [
                                            {
                                                id: 1,
                                                name: '1',
                                            },
                                            {
                                                id: 2,
                                                name: '2',
                                            },
                                            {
                                                id: 5,
                                                name: '5',
                                            },
                                            {
                                                id: 14,
                                                name: '14',
                                            },
                                            {
                                                id: 15,
                                                name: '15',
                                            },
                                            {
                                                id: 16,
                                                name: '16',
                                            },
                                            {
                                                id: 17,
                                                name: '17',
                                            },
                                            {
                                                id: 18,
                                                name: '18',
                                            },
                                            {
                                                id: 19,
                                                name: '19',
                                            },
                                            {
                                                id: 20,
                                                name: '20',
                                            },
                                            {
                                                id: 21,
                                                name: '21',
                                            },
                                            {
                                                id: 31,
                                                name: '31',
                                            },
                                            {
                                                id: 32,
                                                name: '32',
                                            },
                                        ],
                                    },
                                    EncryptionAlgorithm: {
                                        title: 'Encryption Algorithm',
                                        description: '',
                                        help: 'In watchOS and tvOS, the default value is AES-256-GCM.',
                                        type: 'string',
                                        options: [
                                            {
                                                id: 'DES',
                                                name: 'DES',
                                            },
                                            {
                                                id: '3DES',
                                                name: '3DES',
                                            },
                                            {
                                                id: 'AES-128',
                                                name: 'AES-128',
                                            },
                                            {
                                                id: 'AES-256',
                                                name: 'AES-256',
                                            },
                                            {
                                                id: 'AES-128-GCM',
                                                name: 'AES-128-GCM',
                                            },
                                            {
                                                id: 'AES-256-GCM',
                                                name: 'AES-256-GCM',
                                            },
                                            {
                                                id: 'ChaCha20Poly1305',
                                                name: 'ChaCha20Poly1305',
                                            },
                                        ],
                                    },
                                    IntegrityAlgorithm: {
                                        title: 'Integrity Algorithm',
                                        description: '',
                                        help: 'In watchOS and tvOS, the minimum allowed value is SHA-2.',
                                        type: 'string',
                                        options: [
                                            {
                                                id: 'SHA1-96',
                                                name: 'SHA1-96',
                                            },
                                            {
                                                id: 'SHA1-160',
                                                name: 'SHA1-160',
                                            },
                                            {
                                                id: 'SHA2-256',
                                                name: 'SHA2-256',
                                            },
                                            {
                                                id: 'SHA2-384',
                                                name: 'SHA2-384',
                                            },
                                            {
                                                id: 'SHA2-512',
                                                name: 'SHA2-512',
                                            },
                                        ],
                                    },
                                    LifeTimeInMinutes: {
                                        title: 'LifeTime In Minutes',
                                        description: 'The Security Association lifetime (rekey interval) in minutes',
                                        help: 'Minimum Value: 10, Maximum Value: 1440',
                                        type: 'number',
                                    },
                                },
                            },
                            IncludeAllNetworks: {
                                title: 'Include All Networks',
                                description:
                                    'If set to 1, the system sends all internet activity through a secure path (VPN). Some exceptions can be controlled, but the system always lets specific necessary activities bypass this secure path, like connecting to Wi-Fi or communicating with devices like smartwatches.',
                                help: 'Possible Values: 0, 1',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: '0',
                                    },
                                    {
                                        id: 1,
                                        name: '1',
                                    },
                                ],
                            },
                            MTU: {
                                title: 'MTU',
                                description:
                                    'The Maximum Transmission Unit (MTU) specifies the maximum size in bytes of each packet that the system sends over the IKEv2 VPN interface.',
                                help: 'Minimum Value: 1280, Maximum Value: 1400.',
                                type: 'number',
                            },
                            NATKeepAliveInterval: {
                                title: 'NAT KeepAlive Interval',
                                description:
                                    'If set to 1, the device will send regular signals to maintain a stable connection, even when it´s asleep. This helps in situations where there might be interruptions in the network. However, doing this might use up the battery faster, especially during sleep. The frequency of these signals can be adjusted to balance between maintaining the connection and saving battery life.',
                                help: 'Possible Values: 0, 1',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: '0',
                                    },
                                    {
                                        id: 1,
                                        name: '1',
                                    },
                                ],
                            },
                            OnDemandEnabled: {
                                title: 'OnDemand Enabled',
                                description: 'If true, enables VPN up on demand',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            OnDemandRules: VpnOnDemandRulesElement,
                            OnDemandUserOverrideDisabled: {
                                title: 'OnDemand User Override Disabled',
                                description: 'If true, the system disables the Connect On Demand toggle in Settings for this configuration.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            PayloadCertificateUUID: {
                                title: 'Payload Certificate UUID',
                                description:
                                    'The UUID of the certificate payload within the same profile to use as the account credential. If the value of AuthenticationMethod is Certificate, the system sends this certificate out for IKEv2 machine authentication. If extended authentication (EAP) is used, the system sends this certificate out for EAP-TLS authentication.',
                                type: 'string',
                            },
                            ServerCertificateCommonName: {
                                title: 'Server Certificate Common Name',
                                description:
                                    'Common Name of the server certificate issuer. If set, this field causes IKE to send a certificate request based on this certificate issuer to the server.',
                                help: 'This key is required if the CertificateType key is included and the ExtendedAuthEnabled key is 1.',
                                type: 'string',
                            },
                            ServerCertificateIssuerCommonName: {
                                title: 'Server Certificate Issuer Common Name',
                                description:
                                    'Common Name of the server certificate issuer. If set, this field causes IKE to send a certificate request based on this certificate issuer to the server.',
                                help: 'This key is required if the CertificateType key is included and the ExtendedAuthEnabled key is true.',
                                type: 'string',
                            },
                            SharedSecret: {
                                title: 'Shared Secret',
                                description: 'If AuthenticationMethod is SharedSecret, this value is used for IKE authentication.',
                                type: 'string',
                            },
                            TLSMaximumVersion: {
                                title: 'TLS Maximum Version',
                                description: 'The maximum TLS version to use with EAP-TLS authentication.',
                                type: 'string',
                                options: [
                                    {
                                        id: '1.0',
                                        name: '1.0',
                                    },
                                    {
                                        id: '1.1',
                                        name: '1.1',
                                    },
                                    {
                                        id: '1.2',
                                        name: '1.2',
                                    },
                                ],
                            },
                            TLSMinimumVersion: {
                                title: 'TLS Minimum Version',
                                description: 'The minimum TLS version to use with EAP-TLS authentication.',
                                type: 'string',
                                options: [
                                    {
                                        id: '1.0',
                                        name: '1.0',
                                    },
                                    {
                                        id: '1.1',
                                        name: '1.1',
                                    },
                                    {
                                        id: '1.2',
                                        name: '1.2',
                                    },
                                ],
                            },
                            UseConfigurationAttributeInternalIPSubnet: {
                                title: 'Use Configuration Attribute Internal IP Subnet',
                                description: 'If true, negotiations should use IKEv2 Configuration Attribute INTERNAL_IP4_SUBNET and INTERNAL_IP6_SUBNET.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                        },
                    },
                    TransparentProxy: {
                        title: 'Transparent Proxy',
                        description: 'The dictionary to use when VPN Type is TransparentProxy.',
                        help: 'Available in macOS 14 and later.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'TransparentProxy') {
                                return true;
                            }
                            delete siblings.TransparentProxy;
                            return false;
                        },
                        properties: {
                            Order: {
                                title: 'Order',
                                description: 'The order of the proxy configuration.',
                                help: 'Must be a positive integer.',
                                type: 'number',
                            },
                            AuthenticationMethod: {
                                title: 'Authentication Method',
                                description: 'The authentication method for the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'Certificate',
                                        name: 'Certificate',
                                    },
                                    {
                                        id: 'Password',
                                        name: 'Password',
                                    },
                                    {
                                        id: 'Password+Certificate',
                                        name: 'Password+ and Certificate',
                                    },
                                ],
                            },
                            DisconnectOnIdle: {
                                title: 'Disconnect On Idle',
                                description: 'If true, disconnects after an on demand connection idles.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            DisconnectOnIdleTimer: {
                                title: 'Disconnect On Idle Timer',
                                description: 'The length of time to wait before disconnecting an on demand connection.',
                                help: 'The maximum allowed value is 15.',
                                type: 'number',
                            },
                            EnforceRoutes: {
                                title: 'Enforce Routes',
                                description: 'If true, all the VPN’s non-default routes take precedence over any locally defined routes.',
                                help: 'If IncludeAllNetworks is true, the system ignores the value of EnforceRoutes.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeAPNs: {
                                title: 'Exclude APNs',
                                description:
                                    'If true and IncludeAllNetworks is true, then the system excludes the network traffic for the Apple Push Notification service (APNs) from the tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeCellularServices: {
                                title: 'Exclude Cellular Services',
                                description:
                                    'If true and IncludeAllNetworks is true, then the system excludes the network traffic for cellular services from the tunnel.',
                                help: 'Note that some cellular carriers route cellular services traffic directly to the carrier network, bypassing the internet. Such cellular services traffic is always excluded from the tunnel.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ExcludeLocalNetworks: {
                                title: 'Exclude Local Networks',
                                description: 'If true and IncludeAllNetworks is true, routes all local network traffic outside the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            IncludeAllNetworks: {
                                title: 'Include All Networks',
                                description: 'If true, all network traffic routes through the VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            OnDemandMatchDomainsAlways: {
                                title: 'On Demand Match Domains Always',
                                description: 'A list of domain names.',
                                help: 'The system treats associated domain names as though they’re associated with the OnDemandMatchDomainsOnRetry key. This behavior can be overridden by OnDemandRules.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            OnDemandMatchDomainsNever: {
                                title: 'On Demand Match Domains Never',
                                description:
                                    'A list of domain names. If the host name ends with one of these domain names, the system doesn’t start the VPN automatically. The system uses this value to exclude a subdomain within an included domain.',
                                help: 'In iOS 7 and later, this key is deprecated (but still supported) in favor of EvaluateConnection actions in the OnDemandRules dictionaries.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            OnDemandMatchDomainsOnRetry: {
                                title: 'On Demand Match Domains On Retry',
                                description:
                                    'If the host name ends with one of these domain names and a DNS query for that domain name fails, the system starts the VPN automatically.',
                                help: 'In iOS 7 and later, this key is deprecated (but still supported) in favor of EvaluateConnection actions in the OnDemandRules dictionaries.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                            OnDemandRules: VpnOnDemandRulesElement,
                            OnDemandUserOverrideDisabled: {
                                title: 'On Demand User Override Disabled',
                                description: 'If true, the system disables the Connect On Demand toggle in Settings for this configuration.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            Password: {
                                title: 'Password',
                                description: 'The password for the VPN account.',
                                type: 'string',
                            },
                            PayloadCertificateUUID: {
                                title: 'Payload Certificate UUID',
                                description: 'The UUID of the certificate payload within the same profile to use for account credentials.',
                                type: 'string',
                            },
                            ProviderBundleIdentifier: {
                                title: 'Provider Bundle Identifier',
                                description: 'The bundle identifier of the VPN provider app.',
                                type: 'string',
                            },
                            ProviderDesignatedRequirement: {
                                title: 'Provider Designated Requirement',
                                description: 'If the VPN provider is implemented as a system extension, this field is required.',
                                type: 'string',
                            },
                            ProviderType: {
                                title: 'Provider Type',
                                description:
                                    'The type of VPN service. If the value is app-proxy, the service tunnels traffic at the app level. If the value is packet-tunnel, the service tunnels traffic at the IP layer.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'app-proxy',
                                        name: 'app-proxy',
                                    },
                                    {
                                        id: 'packet-tunnel',
                                        name: 'packet-tunnel',
                                    },
                                ],
                            },
                        },
                    },
                    IPSec: {
                        title: 'IPSec',
                        description: 'The dictionary to use to specify a VPN when VPN Type is IPSec.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'IPSec') {
                                return true;
                            }
                            delete siblings.IPSec;
                            return false;
                        },
                        properties: {
                            AuthenticationMethod: {
                                title: 'Authentication Method',
                                description: 'The authentication method for L2TP and Cisco IPSec.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'SharedSecret',
                                        name: 'SharedSecret',
                                    },
                                    {
                                        id: 'Certificate',
                                        name: 'Certificate',
                                    },
                                ],
                            },
                            DisconnectOnIdle: {
                                title: 'Disconnect On Idle',
                                description: 'If true, disconnect after an on-demand connection idles.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            DisconnectOnIdleTimer: {
                                title: 'Disconnect On Idle Timer',
                                description: 'The length of time to wait before disconnecting an on-demand connection.',
                                type: 'number',
                            },
                            LocalIdentifier: {
                                title: 'Local Identifier',
                                description: 'The name of the group. For hybrid authentication, the string needs to end with "hybrid".',
                                help: 'Present only for Cisco IPSec if AuthenticationMethod is SharedSecret.',
                                type: 'string',
                            },
                            LocalIdentifierType: {
                                title: 'Local Identifier Type',
                                description:
                                    'Present only if AuthenticationMethod is SharedSecret. The value is KeyID. The system uses this value for L2TP and Cisco IPSec VPNs.',
                                type: 'string',
                            },
                            OnDemandEnabled: {
                                title: 'OnDemand Enabled',
                                description: 'If true, enables bringing the VPN connection up on demand.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            OnDemandRules: VpnOnDemandRulesElement,
                            PayloadCertificateUUID: {
                                title: 'Payload Certificate UUID',
                                description: 'The UUID of the certificate payload within the same profile to use for the account credentials.',
                                help: 'Only use this with Cisco IPSec VPNs and if the AuthenticationMethod key is to Certificate.',
                                type: 'string',
                            },
                            PromptForVPNPIN: {
                                title: 'Prompt For VPN PIN',
                                description: 'If true, prompts for a PIN when connecting to Cisco IPSec VPNs.',
                                type: 'boolean',
                            },
                            RemoteAddress: {
                                title: 'Remote Address',
                                description: 'The IP address or host name of the VPN server.',
                                type: 'string',
                            },
                            SharedSecret: {
                                title: 'Shared Secret',
                                description: 'The shared secret for this VPN account.',
                                help: 'Only use this with L2TP and Cisco IPSec VPNs and if the AuthenticationMethod key is to SharedSecret.',
                                type: 'string',
                            },
                            XAuthEnabled: {
                                title: 'XAuth Enabled',
                                description: 'If true, enables XAuth for Cisco IPSec VPNs.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            XAuthName: {
                                title: 'XAuth Name',
                                description: 'The XAuth username for Cisco IPSec VPNs.',
                                type: 'string',
                            },
                            XAuthPassword: {
                                title: 'XAuth Password',
                                description: 'The XAuth password for Cisco IPSec VPNs.',
                                type: 'string',
                            },
                            XAuthPasswordEncryption: {
                                title: 'XAuth Password Encryption',
                                description: 'A string that either has the value “Prompt” or isn’t present.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'Prompt',
                                        name: 'Prompt',
                                    },
                                ],
                            },
                        },
                    },
                    PPP: {
                        title: 'PPP',
                        description: 'The dictionary to use to specify a VPN when VPN Type is L2TP or PTPP.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNType === 'L2TP' || siblings.VPNType === 'PTPP') {
                                return true;
                            }
                            delete siblings.PPP;
                            return false;
                        },
                        properties: {
                            AuthEAPPlugins: {
                                title: 'Auth EAP Plugins',
                                description: 'A list of EAP plugins to use for authentication.',
                                help: 'For use of RSA SecurID, this array should only have one value: EAP-RSA. This key is for use with L2TP and PPTP networks.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'EAP-RSA',
                                            name: 'EAP-RSA',
                                        },
                                        {
                                            id: 'EAP-TLS',
                                            name: 'EAP-TLS',
                                        },
                                        {
                                            id: 'EAP-KRB',
                                            name: 'EAP-KRB',
                                        },
                                    ],
                                },
                            },
                            AuthName: {
                                title: 'Auth Name',
                                description: 'The VPN account user name.',
                                help: 'This key is for use with L2TP and PPTP networks.',
                                type: 'string',
                            },
                            AuthPassword: {
                                title: 'Auth Password',
                                description: 'If TokenCard is true, use this password for authentication.',
                                help: 'This key is for use with L2TP and PPTP networks.',
                                type: 'string',
                            },
                            AuthProtocol: {
                                title: 'Auth Protocol',
                                description: 'An array of authentication protocols.',
                                help: 'For use of RSA SecurID, this array should have one value, EAP. This key is for use with L2TP and PPTP networks.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'EAP',
                                        name: 'EAP',
                                    },
                                ],
                            },
                            CCPEnabled: {
                                title: 'CCP Enabled',
                                description: 'If true, enables encryption on the connection.',
                                help: 'This key is for use with PPTP networks.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            CCPMPPE128Enabled: {
                                title: 'CCP MPPE 128 Enabled',
                                description: 'true and CCPEnabled is also true, enables CCPMPPE40 encryption.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            CCPMPPE40Enabled: {
                                title: 'CCP MPPE 40 Enabled',
                                description: 'If true and CCPEnabled is also true, enables CCPMPPE128 encryption.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            CommRemoteAddress: {
                                title: 'Comm Remote Address',
                                description: 'The IP address or host name of VPN server.',
                                help: 'This key is for use with L2TP and PPTP networks.',
                                type: 'string',
                            },
                            DisconnectOnIdle: {
                                title: 'Disconnect On Idle',
                                description: 'If true, disconnects after an on demand connection idles.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            DisconnectOnIdleTimer: {
                                title: 'Disconnect On Idle Timer',
                                description: 'The length of time to wait before disconnecting an on demand connection.',
                                type: 'number',
                            },
                            TokenCard: {
                                title: 'Token Card',
                                description: 'If true, uses a token card such as an RSA SecurID card for connecting.',
                                help: 'This key is for use with L2TP networks.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                        },
                    },
                    //TODO: Add support for Transparent Proxy Settings. Only available for macOS 14 or later.
                    VendorConfig: {
                        title: 'Vendor Config',
                        description: 'The vendor-specific configuration dictionary, which the system reads only when VPNSubType has a value.',
                        type: 'object',
                        shouldRender: (siblings: any) => {
                            if (siblings.VPNSubType) {
                                return true;
                            }
                            delete siblings.VendorConfig;
                            return false;
                        },
                        properties: {
                            Group: {
                                title: 'Group',
                                description: 'The group to connect to on the head end.',
                                help: 'This key is only valid for Cisco AnyConnect.',
                                type: 'string',
                            },
                            LoginGroupOrDomain: {
                                title: 'Login Group Or Domain',
                                description: 'The login group or domain.',
                                type: 'string',
                            },
                            Realm: {
                                title: 'Realm',
                                description: 'The Kerberos realm name.',
                                help: 'This value needs to be properly capitalized.',
                                type: 'string',
                            },
                            Role: {
                                title: 'Role',
                                description: 'The role to select when connecting to the server.',
                                help: 'This key is valid only for Juniper SSL.',
                                type: 'string',
                            },
                        },
                    },
                    Proxies: {
                        title: 'Proxies',
                        description: 'The dictionary to use to configure Proxies for use with VPN.',
                        type: 'object',
                        properties: {
                            HTTPEnable: {
                                title: 'HTTP Enable',
                                description: 'If true, enables the HTTP proxy.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            HTTPPort: {
                                title: 'HTTP Port',
                                description: 'The port number of the HTTP proxy.',
                                help: 'Minimum Value: 0. Maximum Value: 65535',
                                type: 'number',
                            },
                            HTTPProxy: {
                                title: 'HTTP Proxy',
                                description: 'The port number of the HTTP proxy.',
                                help: 'This field is required if HTTPProxy is specified.',
                                type: 'string',
                            },
                            HTTPProxyUsername: {
                                title: 'HTTP Proxy Username',
                                description: 'The user name used for authentication.',
                                type: 'string',
                            },
                            HTTPProxyPassword: {
                                title: 'HTTP Proxy Password',
                                description: 'The password used for authentication.',
                                type: 'string',
                            },
                            HTTPSEnable: {
                                title: 'HTTPS Enable',
                                description: 'If true, enables proxy for HTTPS traffic.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            HTTPSPort: {
                                title: 'HTTPS Port',
                                description: 'The port number of the HTTPS proxy.',
                                help: 'This field is required if HTTPSProxy is specified. Minimum Value: 0. Maximum Value: 65535',
                                type: 'number',
                            },
                            HTTPSProxy: {
                                title: 'HTTPS Proxy',
                                description: 'The host name of the HTTPS proxy.',
                                type: 'string',
                            },
                            ProxyAutoConfigEnable: {
                                title: 'Proxy Auto Config Enable',
                                description: 'If true, enables proxy auto configuration.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            ProxyAutoConfigURLString: {
                                title: 'Proxy Auto Config URL String',
                                description: 'The URL of the proxy auto configuration file.',
                                help: 'Used only when ProxyAutoConfigEnable is true.',
                                type: 'string',
                            },
                            ProxyAutoDiscoveryEnable: {
                                title: 'Proxy Auto Discovery Enable',
                                description: 'If true, enables proxy auto discovery.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                            SupplementalMatchDomains: {
                                title: 'Supplemental Match Domains',
                                description: 'An array of domains that defines which hosts use proxy settings for hosts.',
                                type: 'array',
                                items: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                    IPv4: {
                        title: 'IPv4',
                        description: 'The dictionary that contains IPv4 settings.',
                        type: 'object',
                        properties: {
                            OverridePrimary: {
                                title: 'Override Primary',
                                description: 'If true, the system sends all network traffic over VPN.',
                                type: 'string',
                                options: [
                                    {
                                        id: 0,
                                        name: 'false',
                                    },
                                    {
                                        id: 1,
                                        name: 'true',
                                    },
                                ],
                            },
                        },
                    },
                },
            },
        },
    },
};
