import { Page, Tab, Tabs, useParams } from '@capasystems/ui';
import { isUndefined } from '@capasystems/utils';
import { LOCAL_STORAGE_ID } from '@thirdparty/constants';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { DriverDashboard, useAnalytics, useLocalStorageState, useNavigate } from '../../index';
import { DriverUpdateManagement } from './driver-updater-management';
import { DriversSupportedList } from './drivers-supported-list';
import { DriversPermissionsCheck } from './drivers-teaser';

const PAGE_TITLE_DASHBOARD = ['Drivers', 'Dashboard'];
const PAGE_TITLE_LIST = ['Drivers', 'List'];
const PAGE_TITLE_SUPPORTED_LIST = ['Drivers', 'Supported models'];

const TAB_ID = {
    DASHBOARD: 'dashboard',
    LIST: 'list',
    SUPPORTED_LIST: 'supported',
    IN_PROGRESS: 'inProgress',
};

const DriverLandingPage = () => {
    const analytics = useAnalytics();
    const { tabId } = useParams();
    const navigate = useNavigate();
    const [latestTab, setSelectedTabId] = useLocalStorageState(LOCAL_STORAGE_ID.SELECTED_DRIVER_TAB, TAB_ID.LIST);

    const [portalContainer, setPortalContainer] = useState(null);

    const onRefChange = useCallback((node: any) => {
        setPortalContainer(node);
    }, []);

    const onTabChange = (e: any, newTabId: string) => {
        setSelectedTabId(newTabId);
        navigate.to(`windows/driver/${newTabId}`);
    };

    if (isUndefined(tabId)) {
        return (
            <Navigate
                to={latestTab}
                replace
            />
        );
    }

    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-px-4 tw-pt-2">
            <div className="tw-grid tw-grid-cols-1fr-auto">
                <Tabs
                    value={tabId}
                    onChange={onTabChange}
                    onRails
                >
                    <Tab
                        value={TAB_ID.DASHBOARD}
                        label="Dashboard"
                    />
                    <Tab
                        value={TAB_ID.LIST}
                        label="List"
                    />
                    <Tab
                        value={TAB_ID.SUPPORTED_LIST}
                        label="Supported models"
                    />
                </Tabs>
                <div
                    className={classNames({ 'tw-flex tw-w-full tw-items-end tw-pl-4': tabId !== TAB_ID.DASHBOARD })}
                    ref={onRefChange}
                />
            </div>
            {tabId === TAB_ID.DASHBOARD && (
                <Page title={PAGE_TITLE_DASHBOARD}>
                    <DriversPermissionsCheck teaserProps={{ className: 'tw-py-4' }}>
                        <div className="tw-overflow-auto tw-pr-2">
                            <DriverDashboard />
                        </div>
                    </DriversPermissionsCheck>
                </Page>
            )}

            {tabId === TAB_ID.LIST && portalContainer !== null && (
                <Page
                    title={PAGE_TITLE_LIST}
                    onInit={analytics.sendPageView}
                >
                    <DriversPermissionsCheck teaserProps={{ className: 'tw-py-4' }}>
                        <DriverUpdateManagement portalContainer={portalContainer} />
                    </DriversPermissionsCheck>
                </Page>
            )}
            {tabId === TAB_ID.SUPPORTED_LIST && portalContainer !== null && (
                <Page
                    title={PAGE_TITLE_SUPPORTED_LIST}
                    onInit={analytics.sendPageView}
                >
                    <DriversSupportedList portalContainer={portalContainer} />
                </Page>
            )}
        </div>
    );
};
export { DriverLandingPage };
