import { BUTTON } from '@capasystems/constants';
import {
    Avatar,
    Badge,
    Button,
    Checkbox,
    CircularProgressWithLabel,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Icon,
    LayoutColumn,
    LayoutRow,
    Tooltip,
} from '@capasystems/ui';
import { formatTimestamp } from '@capasystems/utils';
import { windowsApplication } from '@thirdparty/constants';
import { formatBytes } from '@thirdparty/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Fragment, useRef, useState } from 'react';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import { PageTitle } from '../../page/page-title';
import { InfoIcon, TailwindBadge } from '../../thirdparty-components/thirdparty-components';
import { TransferList } from '../../transfer-list/transfer-list';

const WindowsApplicationReleaseStatusBadge = ({ application, className, ...rest }) => {
    const isRetired = application.releaseStatus === windowsApplication.releaseStatus.retired.id;
    const inTesting = application.releaseStatus === windowsApplication.releaseStatus.testing.id;
    const rsName = isRetired
        ? windowsApplication.releaseStatus.retired.name
        : inTesting
        ? windowsApplication.releaseStatus.testing.name
        : windowsApplication.releaseStatus.released.name;
    const rsColor = isRetired ? 'gray' : inTesting ? 'amber' : 'emerald';

    return (
        <Tooltip content={`Release status is set to ${rsName}`}>
            <div>
                <TailwindBadge
                    size="small"
                    noShadow
                    className={className}
                    color={rsColor}
                    {...rest}
                >
                    {rsName}
                </TailwindBadge>
            </div>
        </Tooltip>
    );
};
const WindowsApplicationReleaseStatus = ({ application, className, ...rest }) => {
    const isRetired = application.releaseStatus === windowsApplication.releaseStatus.retired.id;
    const inTesting = application.releaseStatus === windowsApplication.releaseStatus.testing.id;
    const rsName = isRetired
        ? windowsApplication.releaseStatus.retired.name
        : inTesting
        ? windowsApplication.releaseStatus.testing.name
        : windowsApplication.releaseStatus.released.name;
    return (
        <span className={classNames('tw-flex tw-items-center tw-gap-1', className)}>
            <TailwindBadge
                color={classNames({
                    gray: isRetired,
                    amber: inTesting,
                    emerald: !inTesting && !isRetired,
                })}
                size="small"
                noShadow
            >
                {rsName}
            </TailwindBadge>
        </span>
    );
};

const WindowsApplicationTransferList = ({ application, ...props }) => {
    const [blockedDialogProps, setBlockedDialogProps] = useState({
        open: false,
        applications: [],
        selectedItems: [],
    });
    const [verificationNeededDialogProps, setVerificationNeededDialogProps] = useState({
        open: false,
        applications: [],
        selectedItems: [],
    });

    const transferListRef = useRef({ confirmationRequired: false });

    const onAdd = (selectedItems) => {
        if (application.releaseStatus === windowsApplication.releaseStatus.retired.id) {
            transferListRef.current.confirmationRequired = true;
            setBlockedDialogProps({
                open: true,
                applications: [application],
                selectedItems,
            });
        } else if (application.releaseStatus === windowsApplication.releaseStatus.testing.id) {
            transferListRef.current.confirmationRequired = true;
            setVerificationNeededDialogProps({
                open: true,
                applications: [application],
                selectedItems,
            });
        } else {
            transferListRef.current.confirmationRequired = false;
            props.onAdd(selectedItems);
        }
    };

    const onCancel = () => {
        transferListRef.current.confirmationRequired = false;
        setVerificationNeededDialogProps((c) => ({
            ...c,
            selectedItems: [],
            open: false,
        }));
    };

    const onSubmit = () => {
        transferListRef.current.confirmTransfer();
        transferListRef.current.confirmationRequired = false;
        setVerificationNeededDialogProps((c) => ({
            ...c,
            open: false,
        }));
        props.onAdd(verificationNeededDialogProps.selectedItems);
    };

    const onClose = () => {
        transferListRef.current.confirmationRequired = false;
        setBlockedDialogProps((c) => ({
            ...c,
            selectedItems: [],
            open: false,
        }));
    };

    return (
        <>
            <BlockedDialog
                {...blockedDialogProps}
                onClose={onClose}
            />
            <VerificationNeededDialog
                {...verificationNeededDialogProps}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
            <TransferList
                {...props}
                onAdd={onAdd}
                ref={transferListRef}
            />
        </>
    );
};

const WindowsApplicationsTransferList = ({ children, ...props }) => {
    const [verificationNeededDialogProps, setVerificationNeededDialogProps] = useState({
        open: false,
        applications: [],
        selectedApplicationIds: [],
    });
    const [blockedDialogProps, setBlockedDialogProps] = useState({
        open: false,
        applications: [],
        selectedApplicationIds: [],
    });

    const transferListRef = useRef({ confirmationRequired: false });

    const onAdd = (selectedApplicationIds) => {
        const selectedApplicationsInTesting = props.items.filter(
            (application) => selectedApplicationIds.includes(application.id) && application.releaseStatus === windowsApplication.releaseStatus.testing.id
        );
        const selectedApplicationsIsRetired = props.items.filter(
            (application) => selectedApplicationIds.includes(application.id) && application.releaseStatus === windowsApplication.releaseStatus.retired.id
        );

        if (selectedApplicationsIsRetired.length > 0) {
            transferListRef.current.confirmationRequired = true;
            setBlockedDialogProps({
                open: true,
                applications: selectedApplicationsIsRetired,
                selectedApplicationIds,
            });
        } else if (selectedApplicationsInTesting.length > 0) {
            transferListRef.current.confirmationRequired = true;
            setVerificationNeededDialogProps({
                open: true,
                applications: selectedApplicationsInTesting,
                selectedApplicationIds,
            });
        } else {
            transferListRef.current.confirmationRequired = false;
            props.onAdd(selectedApplicationIds);
        }
    };

    const onCancel = () => {
        transferListRef.current.confirmationRequired = false;
        setVerificationNeededDialogProps((c) => ({
            ...c,
            selectedApplicationIds: [],
            open: false,
        }));
    };

    const onSubmit = () => {
        transferListRef.current.confirmTransfer();
        transferListRef.current.confirmationRequired = false;
        setVerificationNeededDialogProps((c) => ({
            ...c,
            open: false,
        }));
        props.onAdd(verificationNeededDialogProps.selectedApplicationIds);
    };

    const onClose = () => {
        transferListRef.current.confirmationRequired = false;
        setBlockedDialogProps((c) => ({
            ...c,
            selectedApplicationIds: [],
            open: false,
        }));
    };

    return (
        <>
            <BlockedDialog
                {...blockedDialogProps}
                onClose={onClose}
            />
            <VerificationNeededDialog
                {...verificationNeededDialogProps}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
            <TransferList
                {...props}
                disableSelectAllAvailable
                onAdd={onAdd}
                ref={transferListRef}
            >
                {children}
                <Column
                    minWidth={100}
                    maxWidth={100}
                    dataKey="name"
                    label="Release Status"
                    type="badge"
                    cellRenderer={({ rowData }) => <WindowsApplicationReleaseStatus application={rowData} />}
                />
            </TransferList>
        </>
    );
};

const Steps = ({ page, pageCount, pagesEnabled }) => {
    const pages = [];
    for (let i = 0; i < pageCount; i++) {
        pages.push(i + 1);
    }
    return (
        <LayoutRow
            // fill={fill}
            align="space-between"
            verticalAlign="center"
        >
            {pages.map((p) => (
                <Fragment key={`page-${p}`}>
                    <div
                        className={classNames('tw-flex tw-items-center tw-justify-center tw-rounded-full tw-p-2', {
                            'tw-bg-green-100 tw-text-green-700': p <= pagesEnabled,
                            'tw-bg-gray-100': p > pagesEnabled,
                        })}
                        style={{ width: 30, height: 30 }}
                    >
                        {p}
                    </div>
                    {p !== pageCount && (
                        <div
                            className={classNames({
                                'tw-bg-green-100': p < pagesEnabled,
                                'tw-bg-gray-100': p >= pagesEnabled,
                            })}
                            style={{ width: 20, height: 2 }}
                        />
                    )}
                </Fragment>
            ))}
        </LayoutRow>
    );
};

const WindowsApplicationConfirmCreationDialog = ({ application, open, onClose }) => {
    const navigate = useNavigate();
    return (
        <Dialog
            open={open}
            confirm
        >
            <DialogContent>
                {!application?.fileInfo?.fileUploaded ? (
                    <LayoutColumn
                        verticalAlign="center"
                        align="center"
                    >
                        <div className="tw-mt-2 tw-text-center tw-text-sm tw-font-medium tw-leading-relaxed">Your application is finishing being created</div>
                        <div className="tw-mt-2 tw-text-center tw-text-sm tw-font-medium tw-leading-relaxed">
                            You can close this promt to proceed in the background
                        </div>
                        <div className="tw-mt-2 tw-text-center tw-text-sm tw-font-medium tw-leading-relaxed">
                            <CircularProgressWithLabel
                                size={70}
                                color="secondary"
                                value={Math.trunc(application?.fileInfo?.totalProgress) || 0}
                            />
                        </div>
                        <div className="tw-text-center tw-text-sm tw-font-medium tw-leading-relaxed">
                            {application?.fileInfo?.uploadStatus.replace(/\s\d+%/gi, '') || 'Starting upload'}
                        </div>
                        <div className="tw-mt-4 tw-w-[16rem]">
                            <Steps
                                page={application?.fileInfo?.step || 1}
                                pageCount={application?.fileInfo?.totalSteps || 1}
                                pagesEnabled={application?.fileInfo?.step || 1}
                            />
                        </div>
                    </LayoutColumn>
                ) : (
                    <>
                        <div className="tw-mt-2 tw-grid tw-justify-center">
                            <Icon
                                type="checkmarkOutlined"
                                className="tw-h-16 tw-w-16 tw-text-emerald-400"
                            />
                        </div>
                        <div className="tw-mt-2 tw-text-center tw-text-sm tw-font-medium tw-leading-relaxed">Your application was successfully created</div>
                    </>
                )}
                <div className="tw-mt-6 tw-grid tw-grid-cols-auto-1fr tw-gap-4 tw-rounded-lg tw-border tw-p-4">
                    <WindowsApplicationThumbnail
                        application={application}
                        size="xl"
                    />
                    <PageTitle
                        description={
                            <WindowsApplicationConfigDetails
                                application={application}
                                className="tw-text-xs tw-font-medium"
                            />
                        }
                    >
                        <strong>
                            <WindowsApplicationNameAndVersion application={application} />
                        </strong>
                    </PageTitle>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                    noMargin
                    disabled={!application?.fileInfo?.fileUploaded}
                    onClick={() => {
                        navigate.to(`windows/application/${application.id}/membership`);
                    }}
                >
                    Assign groups and endpoints
                </Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const VerificationNeededDialog = ({ open, onSubmit, onCancel, applications = [] }) => {
    const [confirmed, setConfirmed] = useState(false);

    return (
        <Dialog
            confirm
            open={open}
            onExited={() => setConfirmed(false)}
            unmountOnExit
        >
            <DialogContent>
                <div className="tw-grid tw-gap-y-6">
                    <div className="tw-place-self-center">
                        <Icon
                            type="badgeExclamation"
                            className="tw-h-16 tw-w-16 tw-text-rose-500"
                        />
                    </div>
                    <div className="tw-text-centers tw-grid tw-gap-1.5 tw-place-self-center">
                        <div className="tw-text-xl tw-font-semibold">Confirmation required</div>
                        <div className="tw-text-sm tw-font-medium tw-leading-relaxed">
                            Applications in the <span className="tw-font-bold">{windowsApplication.releaseStatus.testing.name}</span> state have not been
                            thoroughly tested and may contain bugs or instability issues.
                        </div>
                    </div>
                    <div className="tw-grid-cols-1fr tw-grid tw-max-h-44 tw-gap-y-4 tw-overflow-auto tw-rounded-lg tw-border tw-p-4">
                        {applications.map((application, index) => {
                            return (
                                <div className="tw-flex tw-items-center tw-gap-4">
                                    <WindowsApplicationThumbnail
                                        application={application}
                                        size="large"
                                    />
                                    <PageTitle
                                        subtitle
                                        description={<WindowsApplicationConfigDetails application={application} />}
                                    >
                                        <WindowsApplicationNameAndVersion application={application} />
                                    </PageTitle>
                                </div>
                            );
                        })}
                    </div>
                    <div className="tw-justify-centesr tw-flex">
                        <Checkbox
                            checked={confirmed}
                            label={<strong>I understand and want to continue</strong>}
                            size="large"
                            onChange={(e) => setConfirmed(e.target.checked)}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onSubmit}
                    disabled={!confirmed}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                >
                    Continue
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const WindowsApplicationTitleAndDescription = ({ title, description, first = false }) => {
    return (
        <div
            className={classNames('tw-grid tw-grid-cols-1 tw-gap-1.5 tw-px-6', {
                'tw-mt-4': first,
                'tw-mt-6': !first,
            })}
        >
            <div className="tw-text-lg tw-font-medium">{title}</div>
            <div className="tw-text-xs tw-font-normal tw-text-gray-700">{description}</div>
        </div>
    );
};

const constructClassNames = (enabled, className) => {
    return classNames(className, {
        'tw-text-sky-500': enabled,
        'tw-text-slate-200': !enabled,
    });
};

const WindowsApplicationDetails = ({ application }) => {
    return (
        <InfoIcon
            content={[
                {
                    label: 'Type',
                    value: classNames({
                        [windowsApplication.type.capaPacks.name]: application.type === windowsApplication.type.capaPacks.id,
                        [windowsApplication.type.customApp.name]: application.type === windowsApplication.type.customApp.id,
                        [windowsApplication.type.script.name]: application.type === windowsApplication.type.script.id,
                    }),
                },
                { label: 'Description', value: application.description },
                { label: 'Updated', value: `${formatTimestamp(application.updatedAt)} · ${application.updatedBy}` },
                { label: 'Created', value: `${formatTimestamp(application.createdAt)} · ${application.createdBy}` },
                application.type === windowsApplication.type.customApp.id && { label: 'File name', value: application.fileInfo?.localFileName },
                application.type === windowsApplication.type.customApp.id && {
                    label: 'Size',
                    value: formatBytes({ value: application.fileInfo?.fileSizeBytes, decimalPoints: 1, asText: true }),
                },
            ]}
        />
    );
};
const WindowsApplicationEndpointMembershipIcon = ({ count, className, ...rest }) => {
    return (
        <Badge
            badgeContent={count}
            small
            max={999}
        >
            <Tooltip content={`${pluralize('endpoint', count, true)} assigned`}>
                <Icon
                    type="windowsEndpoints"
                    className={constructClassNames(count > 0, className)}
                    {...rest}
                />
            </Tooltip>
        </Badge>
    );
};
const WindowsApplicationGroupMembershipIcon = ({ count, className, ...rest }) => {
    return (
        <Badge
            badgeContent={count}
            small
            max={999}
        >
            <Tooltip content={`${pluralize('group', count, true)} assigned`}>
                <Icon
                    type="diversity"
                    className={constructClassNames(count > 0, className)}
                    {...rest}
                />
            </Tooltip>
        </Badge>
    );
};

const WindowsApplicationTypeIcon = ({ application, className, disableHoverListener = false, ...rest }) => {
    if (application.type === windowsApplication.type.customApp.id) {
        return (
            <Tooltip
                content={windowsApplication.type.customApp.name}
                disableHoverListener={disableHoverListener}
            >
                <Icon
                    type={windowsApplication.type.customApp.icon}
                    className={classNames(className, {
                        'tw-text-sky-700': true,
                    })}
                    {...rest}
                />
            </Tooltip>
        );
    }
    return (
        <Tooltip
            content={windowsApplication.type.capaPacks.name}
            disableHoverListener={disableHoverListener}
        >
            <Icon
                type={windowsApplication.type.capaPacks.icon}
                className={classNames(className, {
                    'tw-text-sky-700': true,
                })}
                {...rest}
            />
        </Tooltip>
    );
};

WindowsApplicationTypeIcon.propTypes = {
    /** Allows duplicate chips if set to true. */
    disableHoverListener: PropTypes.bool,
};

const WindowsApplicationThumbnail = ({ application, className, size = 'default', onClick, ...rest }) => {
    const classes = classNames(className, {
        'tw-uppercase': true,
        'tw-h-8 tw-w-8': size === 'default',
        'tw-h-10 tw-w-10': size === 'large',
        'tw-h-12 tw-w-12': size === 'xl',
    });

    if (application.thumbnail) {
        return (
            <Avatar
                src={application.thumbnail}
                alt={application.name}
                variant="rounded"
                className={classes}
                onClick={onClick}
            />
        );
    }

    if (application?.fileInfo?.icon) {
        return (
            <Avatar
                src={`data:image/png;base64,${application.fileInfo.icon}`}
                alt={application.name}
                variant="rounded"
                className={classes}
                onClick={onClick}
                {...rest}
            />
        );
    }

    return (
        <Avatar
            variant="rounded"
            className={classNames(classes, 'tw-bg-slate-500')}
            onClick={onClick}
        >
            {application.name ? application.name.substring(0, 1) : 'A'}
        </Avatar>
    );
};

const WindowsApplicationConfigDetails = ({ application, className }) => {
    const platformName = windowsApplication.architecture[application.config.platform]?.name || application.config.platform;

    const languageName =
        application.config.language === windowsApplication.language.system.id ? windowsApplication.language.system.name : application.config.language;

    const version = application.config.version;

    const details = [platformName, languageName, version].filter(Boolean).join(' · ');

    return <span className={className}>{details}</span>;
};

const WindowsApplicationNameAndVersion = ({ application, className }) => {
    return (
        <span className={className}>
            {application.name}
            {application.config.version && <span>&nbsp;&middot;&nbsp;{application.config.version}</span>}
        </span>
    );
};

const WindowsApplicationNameAndVendor = ({ application, className }) => {
    return (
        <span className={className}>
            {application.name}
            {/* {application.vendor} */}
        </span>
    );
};

const WindowsApplicationDeprecatedDetails = ({ application, className }) => {
    if (application.deprecated) {
        return (
            <Tooltip content="The selected version for this application is no longer supported in CapaOne. Edit or delete the application">
                <TailwindBadge
                    size="small"
                    color="red"
                    className={className}
                    noShadow
                >
                    Deprecated
                </TailwindBadge>
            </Tooltip>
        );
    }
    return null;
};

const WindowsApplicationFormProgressContainer = ({
    valid,
    required = true,
    label,
    description,
    isSourceFiles = false,
    hideConnector = false,
    children,
    TooltipInfo,
}) => {
    return (
        <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-gap-8 tw-py-2">
            <div className=" tw-flex tw-flex-col tw-items-center tw-gap-2">
                <div>
                    <Tooltip content={TooltipInfo}>
                        <Icon
                            type="badgeCheckmark"
                            className={classNames({
                                'tw-text-gray-200': !required && !valid,
                                'tw-text-red-400': required && !valid,
                                'tw-text-emerald-400': valid,
                            })}
                        />
                    </Tooltip>
                </div>
                {!hideConnector && (
                    <Divider
                        orientation="vertical"
                        light
                        // Consider min height
                    />
                )}
            </div>
            <div>
                <strong>{label}</strong>
                <div className="tw-mt-1 tw-text-tiny tw-leading-loose">{description}</div>
            </div>
            <div className={classNames({ 'tw-w-88': !isSourceFiles })}>{children}</div>
        </div>
    );
};

const BlockedDialog = ({ open, onClose, applications = [] }) => {
    const [confirmed, setConfirmed] = useState(false);

    return (
        <Dialog
            confirm
            open={open}
            onExited={() => setConfirmed(false)}
            unmountOnExit
        >
            <DialogContent>
                <div className="tw-grid tw-gap-y-6">
                    <div className="tw-place-self-center">
                        <Icon
                            type="badgeExclamation"
                            className="tw-h-16 tw-w-16 tw-text-rose-500"
                        />
                    </div>
                    <div className="tw-text-centers tw-grid tw-gap-1.5 tw-place-self-center">
                        <div className="tw-text-xl tw-font-semibold">Retired applications</div>
                        <div className="tw-text-sm tw-font-medium tw-leading-relaxed">
                            Applications in the <span className="tw-font-bold">{windowsApplication.releaseStatus.retired.name}</span> state cannot be assigned
                            to groups or endpoints. Please deselect the affected applications before proceeding.
                        </div>
                    </div>
                    <div className="tw-grid-cols-1fr tw-grid tw-max-h-44 tw-gap-y-4 tw-overflow-auto tw-rounded-lg tw-border tw-p-4">
                        {applications.map((application, index) => {
                            return (
                                <div className="tw-flex tw-items-center tw-gap-4">
                                    <WindowsApplicationThumbnail
                                        application={application}
                                        size="large"
                                    />
                                    <PageTitle
                                        subtitle
                                        description={<WindowsApplicationConfigDetails application={application} />}
                                    >
                                        <WindowsApplicationNameAndVersion application={application} />
                                    </PageTitle>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const SummaryRow = ({ completed = false, children }) => {
    return (
        <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-4">
            {children}
            <div>
                <Icon
                    type="badgeCheckmark"
                    className={classNames('tw-h-8tw-w-8', {
                        'tw-text-red-400': !completed,
                        'tw-text-emerald-400': completed,
                    })}
                />
            </div>
        </div>
    );
};

const fileTransform = (file) => ({
    name: file.name,
    size: formatBytes({
        value: file.size,
        decimalPoints: 1,
        asText: true,
    }),
    relativePath: file.webkitRelativePath || file.name,
    type: file.type,
});

export {
    fileTransform,
    SummaryRow,
    WindowsApplicationConfigDetails,
    WindowsApplicationConfirmCreationDialog,
    WindowsApplicationDeprecatedDetails,
    WindowsApplicationDetails,
    WindowsApplicationEndpointMembershipIcon,
    WindowsApplicationFormProgressContainer,
    WindowsApplicationGroupMembershipIcon,
    WindowsApplicationNameAndVendor,
    WindowsApplicationNameAndVersion,
    WindowsApplicationReleaseStatus,
    WindowsApplicationReleaseStatusBadge,
    WindowsApplicationsTransferList,
    WindowsApplicationThumbnail,
    WindowsApplicationTitleAndDescription,
    WindowsApplicationTransferList,
    WindowsApplicationTypeIcon,
};
