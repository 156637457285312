//Documentation: WiFi
//https://developer.apple.com/documentation/devicemanagement/wifi
export const AppleWifiConfiguration = {
    schema: {
        title: 'Wifi',
        type: 'object',
        category: 'Networking',
        schemaId: 'appleWifiConfiguration',
        properties: {
            'com.apple.wifi.managed': {
                title: 'Wifi Configurations',
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        SSID_STR: {
                            title: 'SSID STR',
                            description: 'The SSID of the Wi-Fi network to use.',
                            help: 'In iOS 7.0 and later, the SSID is optional if a value exists for DomainName value.',
                            type: 'string',
                        },
                        EncryptionType: {
                            title: 'Encryption Type',
                            description: 'The encryption type for the network.',
                            type: 'string',
                            options: [
                                {
                                    id: 'WEP',
                                    name: 'WEP',
                                },
                                {
                                    id: 'WPA',
                                    name: 'WPA',
                                },
                                {
                                    id: 'WPA2',
                                    name: 'WPA2',
                                },
                                {
                                    id: 'WPA3',
                                    name: 'WPA3',
                                },
                                {
                                    id: 'Any',
                                    name: 'Any',
                                },
                                {
                                    id: 'None',
                                    name: 'None',
                                },
                            ],
                        },
                        Password: {
                            title: 'Password',
                            description: 'The password for the access point.',
                            type: 'string',
                            isPassword: true,
                            shouldRender: (siblings: any) => {
                                if (siblings.EncryptionType && siblings.EncryptionType !== 'None') {
                                    return true;
                                } else {
                                    delete siblings.Password;
                                    return false;
                                }
                            },
                        },
                        PayloadCertificateUUID: {
                            title: 'Payload Certificate UUID',
                            description: 'The UUID of the certificate payload within the same profile to use for the client credential.',
                            type: 'string',
                            shouldRender: (siblings: any) => {
                                if (siblings.EncryptionType && siblings.EncryptionType !== 'None') {
                                    return true;
                                } else {
                                    delete siblings.PayloadCertificateUUID;
                                    return false;
                                }
                            },
                        },
                        EAPClientConfiguration: {
                            title: 'EAP Client Configuration',
                            description: 'A dictionary that configures an enterprise network.',
                            type: 'object',
                            shouldRender: (siblings: any) => {
                                if (siblings.EncryptionType && siblings.EncryptionType !== 'None') {
                                    return true;
                                } else {
                                    delete siblings.EAPClientConfiguration;
                                    return false;
                                }
                            },
                            properties: {
                                AcceptEAPTypes: {
                                    title: 'Accept EAP Types',
                                    description: 'The EAP types to accept.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 13,
                                            name: 'TLS',
                                        },
                                        {
                                            id: 17,
                                            name: 'LEAP',
                                        },
                                        {
                                            id: 18,
                                            name: 'EAP-SIM',
                                        },
                                        {
                                            id: 21,
                                            name: 'TTLS',
                                        },
                                        {
                                            id: 23,
                                            name: 'EAP-AKA',
                                        },
                                        {
                                            id: 25,
                                            name: 'PEAP',
                                        },
                                        {
                                            id: 43,
                                            name: 'EAP-FAST',
                                        },
                                    ],
                                    required: true,
                                },
                                EAPFASTUsePAC: {
                                    title: 'EAP FAST Use PAC',
                                    description:
                                        'If true, the device uses an existing PAC if it’s present. Otherwise, the server must present its identity using a certificate.',
                                    type: 'boolean',
                                },
                                EAPFASTProvisionPAC: {
                                    title: 'EAP FAST Provision PAC',
                                    description: 'If true, allows PAC provisioning.',
                                    help: 'This value must be true for EAP-FAST PAC usage to succeed because there’s no other way to provision a PAC.',
                                    type: 'boolean',
                                    shouldRender: (siblings: any) => {
                                        if (siblings.EAPFASTUsePAC === true) {
                                            return true;
                                        } else {
                                            delete siblings.EAPFASTProvisionPAC;
                                            return false;
                                        }
                                    },
                                },
                                EAPFASTProvisionPACAnonymously: {
                                    title: 'EAP FAST Provision PAC Anonymously',
                                    description: 'If true, provisions the device anonymously.',
                                    help: 'Note that there are known machine-in-the-middle attacks for anonymous provisioning.',
                                    type: 'boolean',
                                },
                                EAPSIMNumberOfRANDs: {
                                    title: 'EAP SIM Number Of RANDs',
                                    description: 'The minimum number of RAND values to accept from the server.',
                                    help: 'For use with EAP-SIM only.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 2,
                                            name: '2',
                                        },
                                        {
                                            id: 3,
                                            name: '3',
                                        },
                                    ],
                                    shouldRender: (siblings: any) => {
                                        if (siblings.AcceptEAPTypes === 18) {
                                            return true;
                                        } else {
                                            delete siblings.EAPSIMNumberOfRANDs;
                                            return false;
                                        }
                                    },
                                },
                                OneTimeUserPassword: {
                                    title: 'One Time User Password',
                                    description: 'If true, the user receives a prompt for a password each time they connect to the network.',
                                    type: 'boolean',
                                },
                                OuterIdentity: {
                                    title: 'Outer Identity',
                                    description:
                                        'A name that hides the user’s true name. The user’s actual name appears only inside the encrypted tunnel. For example, you might set this to anonymous or anon, or anon@mycompany.net. It can increase security because an attacker can’t see the authenticating user’s name in the clear.',
                                    type: 'string',
                                    shouldRender: (siblings: any) => {
                                        if (siblings.AcceptEAPTypes === 21 || siblings.AcceptEAPTypes === 25 || siblings.AcceptEAPTypes === 43) {
                                            return true;
                                        } else {
                                            delete siblings.OuterIdentity;
                                            return false;
                                        }
                                    },
                                },
                                PayloadCertificateAnchorUUID: {
                                    title: 'Payload Certificate Anchor UUID',
                                    description:
                                        'An array of the UUID of a certificate payloads in the same profile to trust for authentication. Use this key to prevent the device from asking the user whether to trust the listed certificates.',
                                    help: 'Dynamic trust (the certificate dialogue) is in a disabled state if you specify this property without also enabling TLSAllowTrustExceptions.',
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                    },
                                },
                                SystemModeCredentialsSource: {
                                    title: 'System Mode Credentials Source',
                                    description: 'Set this string to ActiveDirectory to use the AD computer name and password credentials.',
                                    help: 'If using this property, you can’t use SystemModeUseOpenDirectoryCredentials.',
                                    type: 'string',
                                    shouldRender: (siblings: any) => {
                                        if (
                                            siblings.SystemModeUseOpenDirectoryCredentials === false ||
                                            siblings.SystemModeUseOpenDirectoryCredentials === true
                                        ) {
                                            delete siblings.SystemModeCredentialsSource;
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                                SystemModeUseOpenDirectoryCredentials: {
                                    title: 'System Mode Use Open Directory Credentials',
                                    description: 'If true, the system mode connection tries to use the Open Directory credentials.',
                                    help: 'If using this property, you can’t use SystemModeCredentialsSource.',
                                    type: 'boolean',
                                    shouldRender: (siblings: any) => {
                                        if (siblings.SystemModeCredentialsSource) {
                                            delete siblings.SystemModeUseOpenDirectoryCredentials;
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                                TLSCertificateIsRequired: {
                                    title: 'TLS Certificate Is Required',
                                    description:
                                        'If true, allows for two-factor authentication for EAP-TTLS, PEAP, or EAP-FAST. If false, allows for zero-factor authentication for EAP-TLS.',
                                    help: 'If you don’t specify a value, the default is true for EAP-TLS, and false for other EAP types.',
                                    type: 'boolean',
                                },
                                TLSMinimumVersion: {
                                    title: 'TLS Minimum Version',
                                    description: 'The minimum TLS version for EAP authentication.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: '1.0',
                                            name: '1.0',
                                        },
                                        {
                                            id: '1.1',
                                            name: '1.1',
                                        },
                                        {
                                            id: '1.2',
                                            name: '1.2',
                                        },
                                        {
                                            id: '1.3',
                                            name: '1.3',
                                        },
                                    ],
                                },
                                TLSMaximumVersion: {
                                    title: 'TLS Maximum Version',
                                    description: 'The maximum TLS version for EAP authentication.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: '1.0',
                                            name: '1.0',
                                        },
                                        {
                                            id: '1.1',
                                            name: '1.1',
                                        },
                                        {
                                            id: '1.2',
                                            name: '1.2',
                                        },
                                        {
                                            id: '1.3',
                                            name: '1.3',
                                        },
                                    ],
                                },
                                TLSTrustedCertificates: {
                                    title: 'TLS Trusted Certificates',
                                    description:
                                        'An array of trusted certificates. Each entry in the array must contain certificate data that represents an anchor certificate used for verifying the server certificate.',
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                    },
                                },
                                TLSTrustedServerNames: {
                                    title: 'TLS Trusted Server Names',
                                    description:
                                        'The list of accepted server certificate common names. If a server presents a certificate that isn’t in this list, the system doesn’t trust it.',
                                    help: 'If you specify this property, the system disables dynamic trust (the certificate dialog) unless you also specify TLSAllowTrustExceptions with the value true. If necessary, use wildcards to specify the name, such as wpa.*.example.com.',
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                    },
                                },
                                TTLSInnerAuthentication: {
                                    title: 'TTLS Inner Authentication',
                                    description: 'The inner authentication that the TTLS module uses.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'MSCHAPv2',
                                            name: 'MSCHAPv2',
                                        },
                                        {
                                            id: 'MSCHAP',
                                            name: 'MSCHAP',
                                        },
                                        {
                                            id: 'PAP',
                                            name: 'PAP',
                                        },
                                        {
                                            id: 'CHAP',
                                            name: 'CHAP',
                                        },
                                        {
                                            id: 'EAP',
                                            name: 'EAP',
                                        },
                                    ],
                                },
                                UserName: {
                                    title: 'User Name',
                                    description: 'The user name for the account. If you don’t specify a value, the system prompts the user during login.',
                                    type: 'string',
                                },
                                UserPassword: {
                                    title: 'User Password',
                                    description: 'The password for the account. If you don’t specify a value, the system prompts the user during login.',
                                    type: 'string',
                                    isPassword: true,
                                },
                            },
                        },
                        AutoJoin: {
                            title: 'Auto Join',
                            description: 'If true, the device automatically joins this network.',
                            type: 'boolean',
                        },
                        CaptiveBypass: {
                            title: 'Captive Bypass',
                            description: 'If true, the device does not display a captive portal when joining this network.',
                            type: 'boolean',
                        },
                        DisableAssociationMACRandomization: {
                            title: 'Disable Association MAC Randomization',
                            description:
                                'If true, disables MAC address randomization for a Wi-Fi network while associated with that network. This feature also shows a privacy warning in Settings indicating that the network has reduced privacy protections. If false, then the system enables MAC address randomization.',
                            help: 'This value is only locked when MDM installs the profile. If the profile is manually installed, the system sets the value but the user can change it. Available in iOS 14 and later, and watchOS 7 and later.',
                            type: 'boolean',
                        },
                        DisplayedOperatorName: {
                            title: 'Displayed Operator Name',
                            description:
                                'The operator name to display when connected to this network. Used only with Wi-Fi Hotspot 2.0 access points. Available in iOS 7.0 and later, and in macOS 10.9 and later.',
                            type: 'string',
                        },
                        DomainName: {
                            title: 'Domain Name',
                            description: 'The primary domain of the tunnel. Available in iOS 7.0 and later, and in macOS 10.9 and later.',
                            type: 'string',
                        },
                        EnableIPv6: {
                            title: 'Enable IPv6',
                            description: 'If true, enables IPv6.',
                            type: 'boolean',
                        },
                        HESSID: {
                            title: 'HE SSID',
                            description: 'The HESSID used for Wi-Fi Hotspot 2.0 negotiation.',
                            type: 'string',
                        },
                        HIDDEN_NETWORK: {
                            title: 'Hidden Network',
                            description: 'If true, define the network as hidden.',
                            type: 'boolean',
                        },
                        IsHotspot: {
                            title: 'Is Hotspot',
                            description: 'If true, the device treats the network as a hotspot.',
                            help: 'Available in iOS 7.0 and later, and in macOS 10.9 and later.',
                            type: 'boolean',
                        },
                        MCCAndMNCs: {
                            title: 'MCC And MNCs',
                            description: 'An array of Mobile Country Code/Mobile Network Code (MCC/MNC) pairs used for Wi-Fi Hotspot 2.0 negotiation.',
                            help: 'Each string must contain exactly six digits. Available in iOS 7.0 and later. This feature isn’t supported in macOS.',
                            type: 'array',
                            items: {
                                type: 'string',
                            },
                        },
                        NAIRealmNames: {
                            title: 'NAI Realm Names',
                            description: 'An array of Network Access Identifier Realm names used for Wi-Fi Hotspot 2.0 negotiation.',
                            help: 'Available in iOS 7.0 and later, and in macOS 10.9 and later.',
                            type: 'array',
                            items: {
                                type: 'string',
                            },
                        },
                        ProxyType: {
                            title: 'Proxy Type',
                            description: 'The proxy type, if any, to use.',
                            help: 'If you choose the manual proxy type, you need the proxy server address, including its port and optionally a user name and password into the proxy server. If you choose the auto proxy type, you can enter a proxy autoconfiguration (PAC) URL.',
                            type: 'string',
                            options: [
                                {
                                    id: 'None',
                                    name: 'None',
                                },
                                {
                                    id: 'Manual',
                                    name: 'Manual',
                                },
                                {
                                    id: 'Auto',
                                    name: 'Auto',
                                },
                            ],
                        },
                        ProxyServer: {
                            title: 'Proxy Server',
                            description: 'The address of the proxy server.',
                            type: 'string',
                            shouldRender: (siblings: any) => {
                                if (siblings.ProxyType === 'Manual') {
                                    return true;
                                } else {
                                    delete siblings.ProxyServer;
                                    return false;
                                }
                            },
                            required: (siblings: any) => {
                                if (siblings.ProxyType === 'Manual') {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                        ProxyServerPort: {
                            title: 'Proxy Server Port',
                            description: 'The port number of the proxy server.',
                            help: 'Allows values from 0 to 65535.',
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.ProxyType === 'Manual') {
                                    return true;
                                } else {
                                    delete siblings.ProxyServerPort;
                                    return false;
                                }
                            },
                            required: (siblings: any) => {
                                if (siblings.ProxyType === 'Manual') {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                        ProxyUsername: {
                            title: 'Proxy Username',
                            description: 'The user name used to authenticate to the proxy server.',
                            type: 'string',
                            shouldRender: (siblings: any) => {
                                if (siblings.ProxyType === 'Manual') {
                                    return true;
                                } else {
                                    delete siblings.ProxyUsername;
                                    return false;
                                }
                            },
                        },
                        ProxyPassword: {
                            title: 'Proxy Password',
                            description: 'The password used to authenticate to the proxy server.',
                            type: 'string',
                            shouldRender: (siblings: any) => {
                                if (siblings.ProxyType === 'Manual') {
                                    return true;
                                } else {
                                    delete siblings.ProxyPassword;
                                    return false;
                                }
                            },
                        },
                        ProxyPACURL: {
                            title: 'Proxy PAC URL',
                            description: 'The URL of the PAC file that defines the proxy configuration.',
                            type: 'string',
                            shouldRender: (siblings: any) => {
                                if (siblings.ProxyType === 'Auto') {
                                    return true;
                                } else {
                                    delete siblings.ProxyPACURL;
                                    return false;
                                }
                            },
                        },
                        ProxyPACFallbackAllowed: {
                            title: 'Proxy PAC Fallback Allowed',
                            description: 'If true, allows connecting directly to the destination if the PAC file is unreachable.',
                            type: 'boolean',
                            shouldRender: (siblings: any) => {
                                if (siblings.ProxyType === 'Auto') {
                                    return true;
                                } else {
                                    delete siblings.ProxyPACFallbackAllowed;
                                    return false;
                                }
                            },
                        },
                        QoSMarkingPolicy: {
                            title: 'QoS Marking Policy',
                            description: 'A dictionary that contains the list of apps that the system allows to benefit from L2 and L3 marking.',
                            help: 'When this dictionary isn’t present, the system allows all apps to use L2 and L3 marking when the Wi-Fi network supports Cisco QoS fast lane. Available in iOS 10.0 and later, and in macOS 10.13 and later.',
                            type: 'object',
                            properties: {
                                QoSMarkingAppleAudioVideoCalls: {
                                    title: 'QoS Marking Apple Audio Video Calls',
                                    description:
                                        'If true, adds audio and video traffic of built-in audio/video services, such as FaceTime and Wi-Fi Calling, to the allow list for L2 and L3 marking for traffic that goes to the Wi-Fi network.',
                                    type: 'boolean',
                                },
                                QoSMarkingEnabled: {
                                    title: 'QoS Marking Enabled',
                                    description:
                                        'If true, disables L3 marking and only uses L2 marking for traffic that goes to the Wi-Fi network. If false, the system behaves as if Wi-Fi doesn’t have an association with a Cisco QoS fast lane network.',
                                    type: 'boolean',
                                },
                                QoSMarkingAllowListAppIdentifiers: {
                                    title: 'QoS Marking Allow List App Identifiers',
                                    description:
                                        'An array of app bundle identifiers that defines the allow list for L2 and L3 marking for traffic that goes to the Wi-Fi network.',
                                    help: 'If the array isn’t present, but the QoSMarkingPolicy key is present — even empty — no apps can use L2 and L3 marking.',
                                    type: 'array',
                                    items: {
                                        type: 'appleapplicationsearchdialog',
                                    },
                                },
                                RoamingConsortiumOIs: {
                                    title: 'Roaming Consortium OIs',
                                    description: 'An array of Roaming Consortium Organization Identifiers used for Wi-Fi Hotspot 2.0 negotiation.',
                                    help: 'Available in iOS 7.0 and later, and in macOS 10.9 and later.',
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                    },
                                },
                                ServiceProviderRoamingEnabled: {
                                    title: 'Service Provider Roaming Enabled',
                                    description: 'If true, allows roaming to other service providers.',
                                    help: 'Available in iOS 7.0 and later, and in macOS 10.9 and later.',
                                    type: 'boolean',
                                },
                                SetupModes: {
                                    title: 'Setup Modes',
                                    description: 'An array of strings that contain the type of connection mode to attach.',
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                        options: [
                                            {
                                                id: 'System',
                                                name: 'System',
                                            },
                                            {
                                                id: 'Loginwindow',
                                                name: 'Loginwindow',
                                            },
                                        ],
                                    },
                                },
                                TLSCertificateRequired: {
                                    title: 'TLS Certificate Required',
                                    description:
                                        'If true, allows for two-factor authentication for EAP-TTLS, PEAP, or EAP-FAST. If false, allows for zero-factor authentication for EAP-TLS.',
                                    type: 'boolean',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
