import {
    Avatar,
    Button,
    Checkbox,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Icon,
    LayoutCentered,
    Tooltip,
    VirtualizedTable,
} from '@capasystems/ui';
import { isDefined, isFunction, noop } from '@capasystems/utils';
import { TAndroidDevice, TAndroidDeviceWithId } from '@db/party';
import { coreAndroidManagement } from '@thirdparty/constants';
import {
    ActionsDialog,
    ActionsIconButton,
    RenamingDialog,
    SchemaBuilder,
    TwoLineCellRenderer,
    useAndroidApi,
    useMessageContext,
    useNavigate,
} from '@thirdparty/ui';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';

type TAction = {
    id: string;
    name: string;
    icon?: string;
    tooltipProps?: {
        content: string;
        fullWidth: boolean;
    };
    disabled?: boolean;
    row?: any;
    schema?: any;
    data?: any;
    newPassword?: string;
    resetPasswordFlags?: string[];
    dataIsValid?: (data: any) => boolean;
};

const minApiLevel = {
    reboot: 24,
    clearAppData: 28,
    startLostModeFullyManaged: 30,
    startLostModeWorkProfile: 33,
};

const getListOfAvailableEndpointCommands = (isWorkProfile: boolean, ApiLevel: number, endpointOnly: boolean) => {
    const isDisabledReboot = ApiLevel < minApiLevel.reboot;
    const isDisabledClearData = ApiLevel < minApiLevel.clearAppData;
    const isDisabledLostMode = ApiLevel < minApiLevel.startLostModeFullyManaged || (isWorkProfile && ApiLevel < minApiLevel.startLostModeWorkProfile);

    const actions = [
        {
            id: 'GETDATA',
            name: 'Synchronize device',
            icon: 'configSync',
            tooltipProps: {
                content: 'Synchronize endpoint with latest policy',
                fullWidth: true,
            },
        },
        {
            id: 'LOCK',
            name: 'Lock',
            icon: 'lock',
            tooltipProps: {
                content: isWorkProfile ? (
                    <div>
                        <span className="tw-block tw-text-xs">Lock the work profile</span>
                        <span className="tw-mt-1 tw-block tw-text-tiny">Users will be prompted to enter their passcode</span>
                    </div>
                ) : (
                    'Lock the device, as if the lock screen timeout had expired'
                ),
                fullWidth: true,
            },
        },
        {
            id: 'RESET_PASSWORD',
            name: 'Reset password',
            icon: 'key',
            tooltipProps: {
                content: isWorkProfile ? 'Choose a new password for the work profile' : 'Choose a new password for the device',
            },
            schema: {
                type: 'object',
                schemaId: 'resetPassword',
                properties: {
                    newPassword: {
                        title: 'New password',
                        description: 'Enter a new password',
                        type: 'string',
                    },
                    REQUIRE_ENTRY: {
                        title: 'Require entry',
                        description: "Don't allow other admins to change the password again until the user has entered it",
                        type: 'boolean',
                        default: false,
                        required: true,
                    },
                    LOCK_NOW: {
                        title: 'Lock device now',
                        description: 'Lock the device after password reset',
                        type: 'boolean',
                        default: false,
                        required: true,
                    },
                    DO_NOT_ASK_CREDENTIALS_ON_BOOT: {
                        title: "Don't ask for user credentials",
                        description: "Don't ask for user credentials on device boot",
                        type: 'boolean',
                        default: false,
                        required: true,
                    },
                },
            },
        },

        {
            id: 'REBOOT',
            name: 'Reboot',
            icon: 'reset',
            disabled: isDisabledReboot,
            tooltipProps: {
                content: isDisabledReboot
                    ? `Only supported on fully managed devices running Android 7.0 (API level ${minApiLevel.reboot}) or higher`
                    : 'Reboot the device',
                fullWidth: true,
            },
        },
        {
            id: 'START_LOST_MODE',
            name: 'Start lost mode',
            icon: 'search',
            disabled: isDisabledLostMode,
            tooltipProps: {
                content: isDisabledLostMode
                    ? `Only supported on fully managed devices running Android 11 (API level ${minApiLevel.startLostModeFullyManaged}) or higher, or on company owned devices with a work profile running Android 13 (API level ${minApiLevel.startLostModeWorkProfile}) or higher.`
                    : 'Start lost mode on the device',
                fullWidth: true,
            },
            schema: {
                type: 'object',
                schemaId: 'startLostMode',
                properties: {
                    lostMessage: {
                        title: 'Lost message',
                        description: 'Message to be displayed on the device screen',
                        type: 'string',
                        required: true,
                    },
                    lostPhoneNumber: {
                        title: 'Lost phone number',
                        description: 'Phone number to call if the device is found',
                        type: 'string',
                        required: false,
                    },
                    lostEmailAddress: {
                        title: 'Lost email address',
                        description: 'Email address to contact if the device is found',
                        type: 'string',
                        required: false,
                    },
                    lostStreetAddress: {
                        title: 'Lost street address',
                        description: 'Street address where the device was lost',
                        type: 'string',
                        required: false,
                    },
                    lostOrganization: {
                        title: 'Lost organization',
                        description: 'Organization that owns the device',
                        type: 'string',
                        required: false,
                    },
                },
            },
            dataIsValid: (data: any) => {
                return (data.lostMessage || '') !== '';
            },
        },
        {
            id: 'STOP_LOST_MODE',
            name: 'Stop lost mode',
            icon: 'searchOff',
            disabled: isDisabledLostMode,
            tooltipProps: {
                content: isDisabledLostMode
                    ? `Only supported on fully managed devices running Android 11 (API level ${minApiLevel.startLostModeFullyManaged}) or higher, or on company owned devices with a work profile running Android 13 (API level ${minApiLevel.startLostModeWorkProfile}) or higher.`
                    : 'Stop lost mode on the device',
                fullWidth: true,
            },
        },
    ];

    if (endpointOnly) {
        actions.splice(-2, 0, {
            id: 'CLEAR_APP_DATA',
            name: 'Clear application data',
            icon: 'deleteSweepOutlined',
            disabled: isDisabledClearData,
            tooltipProps: {
                content: isDisabledClearData
                    ? `Only supported on devices running Android 9 (API level ${minApiLevel.clearAppData}) or higher`
                    : 'Clear the application data of specified apps',
                fullWidth: true,
            },
        });
    }

    return actions;
};

const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const policyLogAction = {
    id: 'showPolicyLog',
    name: 'Policy Log',
};

const deleteAction = {
    id: 'delete',
    name: 'Delete',
};

type TCoreAndroidEndpointCommandsProps = {
    endpoint: TAndroidDeviceWithId | null;
    filter: string | null;
    affectedEndpointsCount: number;
};

type TAndroidEndpointCommandsProps = {
    endpoint: TAndroidDevice;
};

type TAndroidEndpointListCommandsProps = {
    filter: string;
    affectedEndpointsCount: number;
};

type TActionDialogProps = {
    open: boolean;
    anchorEl: any;
    category: string;
    title: string;
};

const AndroidEndpointListCommands: React.FC<TAndroidEndpointListCommandsProps> = ({ filter, affectedEndpointsCount }) => {
    return (
        <CoreAndroidEndpointCommands
            filter={filter}
            affectedEndpointsCount={affectedEndpointsCount}
            endpoint={null}
        />
    );
};

const AndroidEndpointCommands: React.FC<TAndroidEndpointCommandsProps> = ({ endpoint }) => {
    return (
        <CoreAndroidEndpointCommands
            endpoint={endpoint ?? undefined}
            filter={null}
            affectedEndpointsCount={1}
        />
    );
};

const CoreAndroidEndpointCommands: React.FC<TCoreAndroidEndpointCommandsProps> = ({ endpoint, filter, affectedEndpointsCount }) => {
    const androidApi = useAndroidApi();
    const [actionDialogProps, setActionDialogProps] = useState<TActionDialogProps>({
        open: false,
        anchorEl: null,
        category: '',
        title: '',
    });
    const [selectedAction, setSelectedAction] = useState<TAction | null>(null);

    const [actions] = useState<any>(() => {
        if (endpoint) {
            return [
                {
                    id: 'requestAction',
                    name: 'Request',
                    actions: getListOfAvailableEndpointCommands(endpoint.androidData.managementMode === 'PROFILE_OWNER', endpoint.androidData.apiLevel, true),
                },
                renameAction,
                policyLogAction,
                deleteAction,
            ];
        }
        return getListOfAvailableEndpointCommands(false, 9999, false);
    });

    const [isRenaming, setIsRenaming] = useState(false);
    const [viewRequests, setViewRequest] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        if (endpoint && endpoint._id) {
            androidApi
                .updateDevice(endpoint?.id, {
                    name: newName,
                })
                .then(noop)
                .catch(() => {
                    setIsRenaming(true);
                });
            return;
        }
    };

    const openMenu = (e: React.MouseEvent) => {
        setActionDialogProps({
            open: true,
            anchorEl: e.currentTarget,
            category: endpoint ? 'Endpoint' : 'Available requests',
            title: endpoint?.name || `${pluralize('endpoint', affectedEndpointsCount, true)} affected`,
        });
    };

    const closeMenu = () => {
        setActionDialogProps((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action: TAction) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                setIsRenaming(true);
                break;
            case policyLogAction.id:
                setViewRequest(true);
                break;
            case deleteAction.id:
                setShowDeleteDialog(true);
                break;
            default:
                setSelectedAction(action);
        }
        return null;
    };

    return (
        <>
            <ActionsIconButton
                /** @ts-ignore */
                onClick={openMenu}
                noMargin
                isBulkAction={endpoint === null}
            />
            <ActionsDialog
                {...actionDialogProps}
                actions={actions}
                onClose={closeMenu}
                onActionClick={onActionClick}
                description=""
            />
            <SelectedActionDialog
                action={selectedAction}
                onClose={() => {
                    setSelectedAction(null);
                }}
                affectedEndpointsCount={affectedEndpointsCount}
                filter={filter}
                /* @ts-ignore - this is not typed */
                endpoint={endpoint}
            />
            {endpoint && (
                <>
                    <RenamingDialog
                        onCancel={() => setIsRenaming(false)}
                        onSubmit={onRenameSubmit}
                        currentName={endpoint.name}
                        open={isRenaming}
                    />
                    <ViewPolicy
                        onClose={() => {
                            setViewRequest(false);
                        }}
                        open={viewRequests}
                        /* @ts-ignore - this is not typed */
                        endpoint={endpoint}
                    />
                    <DeleteEndpointDataDialog
                        open={showDeleteDialog}
                        onClose={() => setShowDeleteDialog(false)}
                        /* @ts-ignore - this is not typed */
                        endpoint={endpoint}
                    />
                </>
            )}
        </>
    );
};

type ViewPolicyProps = { open: boolean; onClose: () => void; endpoint: TAndroidDevice };

type selectedActionDialogProps = {
    action: TAction | null;
    onClose: () => void;
    affectedEndpointsCount: number;
    filter: string | null;
    endpoint: TAndroidDevice | null;
};

const SelectedActionDialog: React.FC<selectedActionDialogProps> = ({ action, onClose, affectedEndpointsCount, filter, endpoint }) => {
    const androidApi = useAndroidApi();
    const { handleApiError } = useMessageContext();
    const schemaBuilderRef = useRef({});
    const [disabled, setDisabled] = useState(true);
    const [accepted, setAccepted] = useState(endpoint !== null);
    const [isProcessing, setIsProcessing] = useState(false);

    if (action === null) {
        schemaBuilderRef.current = {};
        return null;
    }

    const onSubmitHandler = () => {
        action.row = schemaBuilderRef.current;
        action.data = action.data || {};
        switch (action.id) {
            case 'RESET_PASSWORD': {
                const newPassword = action.row.newPassword || '';
                const resetPasswordFlags: string[] = [];
                if (action.row.LOCK_NOW) {
                    resetPasswordFlags.push('LOCK_NOW');
                }
                if (action.row.DO_NOT_ASK_CREDENTIALS_ON_BOOT) {
                    resetPasswordFlags.push('DO_NOT_ASK_CREDENTIALS_ON_BOOT');
                }
                if (action.row.REQUIRE_ENTRY) {
                    resetPasswordFlags.push('REQUIRE_ENTRY');
                }

                action.data = {
                    newPassword,
                    resetPasswordFlags,
                };
                onSubmit();
                break;
            }
            case 'START_LOST_MODE': {
                const lostMessage: object = {
                    defaultMessage: action.row.lostMessage,
                };
                const lostPhoneNumber: object = {
                    defaultMessage: action.row.lostPhoneNumber,
                };
                const lostStreetAddress: object = {
                    defaultMessage: action.row.lostStreetAddress,
                };
                const lostOrganization: object = {
                    defaultMessage: action.row.lostOrganization,
                };

                const lostEmailAddress: string = action.row.lostEmailAddress;

                const startLostModeParams = { lostMessage, lostPhoneNumber, lostEmailAddress, lostStreetAddress, lostOrganization };
                action.data = {
                    startLostModeParams,
                };
                onSubmit();
                break;
            }
            case 'STOP_LOST_MODE': {
                action.data = { stopLostModeParams: {} };
                onSubmit();
                break;
            }
            case 'CLEAR_APP_DATA': {
                const clearAppsDataParams = { packageNames: action.row.packageNames };
                action.data = { clearAppsDataParams };
                onSubmit();
                break;
            }

            default: {
                onSubmit();
                break;
            }
        }
    };

    const onSubmit = () => {
        setIsProcessing(true);

        if (endpoint) {
            action.id === 'GETDATA'
                ? androidApi
                      .syncDevice(endpoint.id)
                      .then(onClose)
                      .catch((error) => {
                          handleApiError(error);
                          setIsProcessing(false);
                      })
                : androidApi
                      .sendCommand(endpoint.id, { type: action.id, filter, data: action.data })
                      .then(onClose)
                      .catch((error) => {
                          handleApiError(error);
                          setIsProcessing(false);
                      });
        } else {
            action.id === 'GETDATA'
                ? androidApi
                      .syncDeviceList()
                      .then(onClose)
                      .catch((error) => {
                          handleApiError(error);
                          setIsProcessing(false);
                      })
                : androidApi
                      .sendBulkCommand({ type: action.id, filter, data: action.data })
                      .then(onClose)
                      .catch((error) => {
                          handleApiError(error);
                          setIsProcessing(false);
                      });
        }
    };

    return (
        <Dialog
            open={isDefined(action.id)}
            onClose={onClose}
            size="md"
            onEnter={() => {
                setAccepted(endpoint !== null);
                if (isFunction(action.dataIsValid)) {
                    setDisabled(!action.dataIsValid(schemaBuilderRef.current));
                } else {
                    setDisabled(false);
                }
                setIsProcessing(false);
            }}
            onExited={() => {
                schemaBuilderRef.current = {};
            }}
        >
            <DialogTitle className="tw-pb-0s tw-text-lg">
                {filter && (
                    <>
                        Send <span className="tw-underline tw-underline-offset-4">{action.name}</span> command to{' '}
                        {pluralize('endpoint', affectedEndpointsCount, true)}?
                    </>
                )}
                {endpoint && (
                    <>
                        Send <span className="tw-underline tw-underline-offset-4">{action.name}</span> command to {endpoint.name}?
                    </>
                )}

                <div className="tw-mt-1 tw-text-sm tw-text-gray-700">The command will be sent immediately. You can't undo this action.</div>
            </DialogTitle>
            {action.id === 'CLEAR_APP_DATA' ? (
                <>
                    <Divider light />
                    <ClearAppDataDialog
                        endpoint={endpoint}
                        disable={setDisabled}
                        currentState={schemaBuilderRef.current}
                    />
                    <Divider
                        className="tw-mb-1"
                        light
                    />
                </>
            ) : (
                action.schema && (
                    <>
                        <Divider light />

                        <SchemaBuilder
                            schema={action.schema}
                            currentState={schemaBuilderRef.current}
                            onRefChange={() => {
                                if (isFunction(action.dataIsValid)) {
                                    setDisabled(!action.dataIsValid(schemaBuilderRef.current));
                                } else {
                                    setDisabled(false);
                                }
                            }}
                        />

                        <Divider
                            className="tw-mb-1"
                            light
                        />
                    </>
                )
            )}
            <DialogActions className="tw-items-center tw-px-6">
                <div className="tw-flex-1">
                    <AcceptCheckbox
                        accepted={accepted}
                        setAccepted={setAccepted}
                    />
                </div>
                <Tooltip
                    content={disabled ? 'Please fill in the required fields' : !accepted && 'You must accept if you want to proceed'}
                    disableHoverListener={endpoint !== null && !disabled && accepted}
                >
                    <div>
                        <Button
                            onClick={onSubmitHandler}
                            color="primary"
                            variant="contained"
                            disabled={disabled || !accepted || isProcessing}
                        >
                            {action.name}
                        </Button>
                    </div>
                </Tooltip>
                <Button
                    onClick={onClose}
                    noMargin
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ViewPolicy: React.FC<ViewPolicyProps> = ({ open, onClose, endpoint }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="lg"
        >
            <DialogTitle>Policy Log</DialogTitle>
            <div className="tw-grid-cols-auto tw-grid tw-max-h-full tw-overflow-auto">
                <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white">
                    {endpoint?.resultingConfigurationDescription}
                </div>
            </div>
            <DialogActions>
                <Button onClick={() => onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

type ClearAppDataDialogProps = {
    endpoint?: TAndroidDeviceWithId;
    currentState: any;
    disable: (value: boolean) => void;
};

const ClearAppDataDialog: React.FC<ClearAppDataDialogProps> = ({ endpoint, currentState, disable }) => {
    const androidApi = useAndroidApi();
    const [packageNames, setPackageNames] = useState<any[]>([]);
    const [applicationsList, setApplicationsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        disable(packageNames.length === 0);
        currentState.packageNames = packageNames;
    }, [packageNames]);

    const onEnter = () => {
        setPackageNames([]);
        setIsProcessing(false);
        if (endpoint?.resultingPolicy.applications && endpoint?.resultingPolicy.applications.length > 0) {
            setIsLoading(true);
            androidApi
                .getApplications()
                .then((applicationsResponse) => {
                    setApplicationsList(
                        endpoint.resultingPolicy.applications?.map((application: any) => {
                            const appData = applicationsResponse.find((app: any) => {
                                if (app.configurationType === coreAndroidManagement.configurationType.webapp) {
                                    return app.googlePlayId.endsWith(application.packageName);
                                }
                                return (
                                    app.configurationType === coreAndroidManagement.configurationType.application &&
                                    app.data.applications.some((a) => a.packageName === application.packageName)
                                );
                            });
                            return {
                                ...application,
                                ...appData,
                            };
                        }),
                    );
                })
                .catch((e) => {
                    console.log('error', e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        onEnter();
    }, []);
    return (
        <div className="tw-h-112">
            <VirtualizedTable
                items={applicationsList}
                disableHeader
                noRowsRenderer={() => <LayoutCentered>No assigned applications</LayoutCentered>}
                isLoading={isLoading}
                onRowClick={({ rowData }: { rowData: any }) => {
                    if (!isProcessing) {
                        if (packageNames.includes(rowData.packageName)) {
                            setPackageNames((currentPackageNames) => {
                                return currentPackageNames.filter((currentPackageName) => currentPackageName !== rowData.packageName);
                            });
                        } else {
                            setPackageNames([...packageNames, rowData.packageName]);
                        }
                    }
                }}
            >
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="packageName"
                    disableSort
                    label=""
                    type="checkbox"
                    cellRenderer={({ rowData }) => {
                        return <Checkbox checked={packageNames.includes(rowData.packageName)} />;
                    }}
                />
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="iconUrl"
                    label=""
                    type="avatar"
                    cellRenderer={({ rowData }) => {
                        if (rowData.iconUrl) {
                            return (
                                <Avatar
                                    src={rowData.iconUrl}
                                    alt={rowData.name}
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                />
                            );
                        }
                        return (
                            <Icon
                                type="app"
                                className="tw-h-8 tw-w-8"
                            />
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="name"
                    label="Name"
                    type="multiLine"
                    cellRenderer={({ rowData }) => {
                        return (
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.installTypeName}
                            />
                        );
                    }}
                />
            </VirtualizedTable>
        </div>
    );
};

type deleteEndpointDataDialogProps = { open: boolean; onClose: () => void; endpoint: TAndroidDevice };

const DeleteEndpointDataDialog: React.FC<deleteEndpointDataDialogProps> = ({ open, onClose, endpoint }) => {
    const androidApi = useAndroidApi();
    const navigate = useNavigate();
    const { handleApiError } = useMessageContext();
    const [accepted, setAccepted] = useState(false);

    const onDeleteSubmit = () => {
        androidApi
            .deleteDevice(endpoint?.id)
            .then(() => {
                navigate.to('android/device', true);
            })
            .catch(handleApiError);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="sm"
            onEnter={() => setAccepted(false)}
        >
            <DialogTitle>Delete Endpoint Data?</DialogTitle>
            <DialogContent className="tw-font-medium">
                <div>This will permanently delete all data stored on the device and its connection to CapaOne.</div>
                <div className="tw-mt-4">The device will then be restored to its original factory settings.</div>
            </DialogContent>
            <DialogActions className="tw-px-6">
                <div className="tw-flex-1">
                    <AcceptCheckbox
                        accepted={accepted}
                        setAccepted={setAccepted}
                    />
                </div>
                <Button
                    onClick={onDeleteSubmit}
                    color="primary"
                    variant="contained"
                    noMargin
                    disabled={!accepted}
                >
                    Delete
                </Button>
                <Button
                    onClick={onClose}
                    noMargin
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AcceptCheckbox: React.FC<{ accepted: boolean; setAccepted: React.Dispatch<React.SetStateAction<boolean>> }> = ({ accepted, setAccepted }) => {
    return (
        <Checkbox
            checked={accepted}
            label="I accept and want to proceed"
            onChange={(e, checked) => {
                setAccepted(checked);
            }}
            labelProps={{ className: 'tw-font-medium' }}
        />
    );
};

export { AndroidEndpointCommands, AndroidEndpointListCommands };
