import { PasswordQualityEnum } from '../android-management-enums';

export const AndroidPasswordConfiguration = {
    schema: {
        title: 'Password',
        type: 'object',
        category: 'Security',
        schemaId: 'passwordConfiguration',
        properties: {
            passwordPolicies: {
                title: 'Password Policies',
                description:
                    'Password requirement policies. Different policies can be set for work profile or fully managed devices by setting the Password Scope field in the policy.',
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        passwordQuality: {
                            title: 'Password Quality',
                            description: 'The required password quality.',
                            type: 'string',
                            options: Object.entries(PasswordQualityEnum).map(([id, name]) => ({ id, name })),
                        },
                        passwordMinimumLength: {
                            title: 'Password Minimum Length',
                            description: 'The minimum allowed password length.',
                            help: "Note: A value of 0 means there is no restriction. Only enforced when 'Password Quality' is Numeric, Complex numeric, Alphabetic, Alphanumeric, or Complex.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (
                                    siblings.passwordQuality === 'COMPLEX' ||
                                    siblings.passwordQuality === 'NUMERIC' ||
                                    siblings.passwordQuality === 'NUMERIC_COMPLEX' ||
                                    siblings.passwordQuality === 'ALPHABETIC' ||
                                    siblings.passwordQuality === 'ALPHANUMERIC'
                                ) {
                                    return true;
                                }
                                delete siblings.passwordMinimumLength;
                                return false;
                            },
                        },
                        passwordMinimumLetters: {
                            title: 'Password Minimum Letters',
                            description: 'Minimum number of letters required in the password.',
                            help: "Note: Only enforced when 'Password Quality' is set to 'Complex'.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.passwordQuality === 'COMPLEX') {
                                    return true;
                                }
                                delete siblings.passwordMinimumLetters;
                                return false;
                            },
                        },
                        passwordMinimumLowerCase: {
                            title: 'Password Minimum Lower Case',
                            description: 'Minimum number of lower case letters required in the password.',
                            help: "Note: Only enforced when 'Password Quality' is set to 'Complex'.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.passwordQuality === 'COMPLEX') {
                                    return true;
                                }
                                delete siblings.passwordMinimumLowerCase;
                                return false;
                            },
                        },
                        passwordMinimumNonLetter: {
                            title: 'Password Minimum Non Letters',
                            description: 'Minimum number of non-letter characters (numerical digits or symbols) required in the password.',
                            help: "Note: Only enforced when 'Password Quality' is set to 'Complex'.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.passwordQuality === 'COMPLEX') {
                                    return true;
                                }
                                delete siblings.passwordMinimumNonLetter;
                                return false;
                            },
                        },
                        passwordMinimumNumeric: {
                            title: 'Password Minimum Numeric',
                            description: 'Minimum number of numerical digits required in the password.',
                            help: "Note: Only enforced when 'Password Quality' is set to 'Complex'.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.passwordQuality === 'COMPLEX') {
                                    return true;
                                }
                                delete siblings.passwordMinimumNumeric;
                                return false;
                            },
                        },
                        passwordMinimumSymbols: {
                            title: 'Password Minimum Symbols',
                            description: 'Minimum number of symbols required in the password.',
                            help: "Note: Only enforced when 'Password Quality' is set to 'Complex'.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.passwordQuality === 'COMPLEX') {
                                    return true;
                                }
                                delete siblings.passwordMinimumSymbols;
                                return false;
                            },
                        },
                        passwordMinimumUpperCase: {
                            title: 'Password Minimum Upper Case',
                            description: 'Minimum number of upper case letters required in the password.',
                            help: "Note: Only enforced when 'Password Quality' is set to 'Complex'.",
                            type: 'number',
                            shouldRender: (siblings: any) => {
                                if (siblings.passwordQuality === 'COMPLEX') {
                                    return true;
                                }
                                delete siblings.passwordMinimumUpperCase;
                                return false;
                            },
                        },
                        passwordHistoryLength: {
                            title: 'Password History Length',
                            description:
                                "The length of the password history. After setting this field, the user won't be able to enter a new password that is the same as any password in the history.",
                            help: 'Note: A value of 0 means there is no restriction.',
                            type: 'number',
                        },
                        maximumFailedPasswordsForWipe: {
                            title: 'Maximum Failed Passwords For Wipe',
                            description: 'Number of incorrect device-unlock passwords that can be entered before a device is wiped.',
                            help: 'Note: A value of 0 means there is no restriction.',
                            type: 'number',
                        },
                        passwordExpirationTimeout: {
                            title: 'Password Expiration Timeout',
                            description: 'Expiration Time in Seconds',
                            type: 'string',
                        },
                        passwordScope: {
                            title: 'Password Scope',
                            description: 'The scope that the password requirement applies to.',
                            type: 'string',
                            options: [
                                {
                                    id: 'SCOPE_DEVICE',
                                    name: 'Password requirements are applied to entire device',
                                },
                                {
                                    id: 'SCOPE_PROFILE',
                                    name: 'Password requirements are only applied to the work profile',
                                },
                            ],
                        },
                        requirePasswordUnlock: {
                            title: 'Require Password Unlock',
                            description:
                                'The length of time after a device or work profile is unlocked using a strong form of authentication (password, PIN, pattern) that it can be unlocked using any other authentication method (e.g. fingerprint, trust agents, face).',
                            help: 'Note: After the specified time period elapses, only strong forms of authentication can be used to unlock the device or work profile.',
                            type: 'string',
                            options: [
                                {
                                    id: 'USE_DEFAULT_DEVICE_TIMEOUT',
                                    name: 'Device default',
                                },
                                {
                                    id: 'REQUIRE_EVERY_DAY',
                                    name: '24 hours',
                                },
                            ],
                        },
                        unifiedLockSettings: {
                            title: 'Unified Lock Settings',
                            description:
                                'Controls whether a unified lock is allowed for the device and the work profile, on devices running Android 9 and above with a work profile. This has no effect on other devices.',
                            type: 'string',
                            options: [
                                {
                                    id: 'ALLOW_UNIFIED_WORK_AND_PERSONAL_LOCK',
                                    name: 'A common lock for the device and the work profile is allowed',
                                },
                                {
                                    id: 'REQUIRE_SEPARATE_WORK_LOCK',
                                    name: 'A separate lock for the work profile is required',
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
};
