import { BUTTON } from '@capasystems/constants';
import {
    Button,
    Dialog,
    Ellipsis,
    Grow,
    Icon,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Menu,
    MenuItem,
    Page,
    Paper,
    Tab,
    Tabs,
    Tooltip,
    useParams,
} from '@capasystems/ui';
import { Url, isDefined, useWindowSize } from '@capasystems/utils';
import {
    AppleAppLayerVPNConfiguration,
    AppleAppLockConfiguration,
    AppleCellularConfiguration,
    AppleDNSConfiguration,
    AppleDnsProxyConfiguration,
    AppleDomainsConfiguration,
    AppleExchangeActiveSyncConfiguration,
    AppleHomeScreenLayoutConfiguration,
    AppleLdapConfiguration,
    AppleLockScreenMessageConfiguration,
    AppleNetworkUsageRulesConfiguration,
    AppleNotificationSettingsConfiguration,
    ApplePasscodeConfiguration,
    AppleRestrictionsConfiguration,
    AppleScepConfiguration,
    AppleVPNConfiguration,
    AppleWallpaperConfiguration,
    AppleWebContentFilterConfiguration,
    AppleWifiConfiguration,
} from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { default as classNames } from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { JsonViewer, QueryBuilder, SchemaBuilder, WithExperimentalFeatures, useAppleApi, useAuthContext, useForceRefresh, useNavigate } from '../../../index';
import { AppleHomeScreenLayoutEdit } from '../components/AppleHomeScreenLayout';

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Category or Configuration' });

const configurationTypes = [
    AppleVPNConfiguration.schema,
    AppleDNSConfiguration.schema,
    AppleAppLayerVPNConfiguration.schema,
    AppleDomainsConfiguration.schema,
    AppleRestrictionsConfiguration.schema,
    ApplePasscodeConfiguration.schema,
    AppleWifiConfiguration.schema,
    AppleCellularConfiguration.schema,
    AppleLockScreenMessageConfiguration.schema,
    AppleNetworkUsageRulesConfiguration.schema,
    AppleScepConfiguration.schema,
    AppleLdapConfiguration.schema,
    AppleWebContentFilterConfiguration.schema,
    AppleDnsProxyConfiguration.schema,
    AppleNotificationSettingsConfiguration.schema,
    AppleWallpaperConfiguration.schema,
    AppleHomeScreenLayoutConfiguration.schema,
    AppleAppLockConfiguration.schema,
    AppleExchangeActiveSyncConfiguration.schema,
].sort((a, b) => {
    const categoryCalc = a.category.toLocaleLowerCase().localeCompare(b.category.toLocaleLowerCase());
    if (categoryCalc === 0) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
        return categoryCalc;
    }
});

const WIDTH_THRESHOLD = 1440;

const AppleConfigurationCreateAndEdit = () => {
    const { configurationId } = useParams();
    const [selectedTab, setSelectedTab] = useState(Url.getString('schemaId', configurationTypes[0].schemaId));
    const [schemaBuilderSchema, setSchemaBuilderSchema] = useState(
        configurationTypes.find((cc) => cc.schemaId === selectedTab) || { title: 'Not found', properties: {} }
    );
    const propertiesBuilderRef = useRef({});
    const queryBuilderRef = useRef({});
    const [configurationName, setConfigurationName] = useState('');
    const appleApi = useAppleApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const { width } = useWindowSize();
    const [loading, setLoading] = useState(isDefined(configurationId));
    const { permissions } = useAuthContext();

    const changeTab = (_, tab) => {
        const configurationType = configurationTypes.find((cc) => cc.schemaId === tab);
        setSchemaBuilderSchema(configurationType);
        Url.set('schemaId', tab);
        setSelectedTab(tab);
    };

    const navigateToConfigurationList = () => {
        navigate.to('apple/configuration/list');
    };

    const navigateToConfigurationMembership = (response) => {
        navigate.to(`apple/configuration/${configurationId || response.id}/membership`);
    };

    const onSubmitSearch = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const onSubmit = () => {
        setErrorMessage(null);
        if (configurationName.trim() === '') {
            setErrorMessage('You must provide a configuration name');
        } else {
            if (isDefined(configurationId)) {
                appleApi
                    .updateAppleConfiguration(configurationId, {
                        name: configurationName,
                        data: { ...propertiesBuilderRef.current },
                    })
                    .then(navigateToConfigurationMembership)
                    .catch((response) => {
                        setErrorMessage(response.data?.message || 'Failed to update apple configuration');
                    });
            } else {
                appleApi
                    .createAppleConfiguration(configurationName, { ...propertiesBuilderRef.current })
                    .then(navigateToConfigurationMembership)
                    .catch((response) => {
                        setErrorMessage(response.data?.message || 'Failed to create apple configuration');
                    });
            }
        }
    };

    useEffect(() => {
        if (isDefined(configurationId)) {
            appleApi
                .getAppleConfiguration(configurationId)
                .then((foundConfiguration) => {
                    propertiesBuilderRef.current = foundConfiguration.data || {};
                    setConfigurationName(foundConfiguration.name);
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('The supplied configuration ID is unknown');
                });
        }
    }, [appleApi, configurationId]);

    const propertiesBuilderRefKeys = Object.keys(propertiesBuilderRef.current);

    const memorizedTabs = useMemo(() => {
        const groupedByCategory = Object.groupBy(configurationTypes, (ct) => ct.category);

        const filteredGroupedByCategory = Object.entries(groupedByCategory)
            .filter(([categoryName, ctArray]) => {
                const categoryMatches = categoryName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());

                if (categoryMatches) {
                    return true;
                }

                return ctArray.some((ct) => ct.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            })
            .reduce((acc, [categoryName, ctArray]) => {
                if (categoryName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    acc[categoryName] = ctArray;
                } else {
                    acc[categoryName] = ctArray.filter((ct) => ct.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
                }
                return acc;
            }, {});

        return [].concat(
            ...Object.entries(filteredGroupedByCategory)
                .sort(([keyA], [keyB]) => keyA.toLocaleLowerCase().localeCompare(keyB.toLocaleLowerCase()))
                .map(([key, ctArray], i) => {
                    const sortedCtArray = ctArray.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));

                    const catTabs = [
                        <Tab
                            value={key}
                            disabled
                            className={classNames('tw-mb-1 tw-min-h-0 tw-py-0 tw-opacity-100', { 'tw-mt-2': i === 0, 'tw-mt-6': i !== 0 })}
                            label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{key}</div>}
                            key={'cat - ' + key}
                        />,
                    ];

                    sortedCtArray.forEach((ct) => {
                        const inUse = Object.keys(ct.properties).some((propertiesKey) => propertiesBuilderRefKeys.includes(propertiesKey));
                        catTabs.push(
                            <Tab
                                value={ct.schemaId}
                                classes={{ root: 'tw-pl-4' }}
                                label={
                                    <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                        <div className="tw-mr-2 tw-text-left">{ct.title}</div>
                                        <Grow in={inUse}>
                                            <Icon
                                                type="checkmarkCircle"
                                                color="success"
                                                size="small"
                                            />
                                        </Grow>
                                    </div>
                                }
                                key={'tab - ' + ct.title}
                                disableRipple
                            />
                        );
                    });

                    return catTabs;
                })
        );
    }, [configurationTypes, propertiesBuilderRefKeys, searchTerm]);

    if (loading) {
        if (errorMessage) {
            return (
                <LayoutCentered direction="column">
                    <div>
                        <h2 className="tw-mb-4">{errorMessage}</h2>
                        <Button
                            noMargin
                            onClick={navigateToConfigurationList}
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                        >
                            Back
                        </Button>
                    </div>
                </LayoutCentered>
            );
        }
        return (
            <LayoutCentered>
                <Loading thickness={2} />
            </LayoutCentered>
        );
    }
    const currentUrl = window.location.href;
    let baseUrl;
    if (currentUrl.includes('apple') && currentUrl.includes('configuration')) {
        baseUrl = 'https://developer.apple.com/documentation/devicemanagement/profile-specific_payload_keys';
    } else {
        return null;
    }
    const appleDoc = 'Apple documentation';
    const linkText = 'Learn More';

    const appleTooltipContentWithLink = (
        <div>
            <p>{appleDoc}</p>
            <a
                href={baseUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
            >
                {linkText}
            </a>
        </div>
    );
    const showJsonMiniMap = width >= WIDTH_THRESHOLD && permissions.canViewExperimentalFeatures;

    return (
        <Page title={isDefined(configurationId) ? 'Configuration · Edit' : 'Configuration · Create'}>
            <div
                className={classNames({
                    'tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-auto-1fr-auto tw-gap-4 tw-p-4': true,
                    'tw-grid-rows-auto-auto-1fr': errorMessage !== null,
                    'tw-grid-rows-auto-1fr': errorMessage === null,
                })}
            >
                <div className="tw-col-span-3">
                    <LayoutRow
                        align="space-between"
                        verticalAlign="center"
                    >
                        <div className="tw-flex tw-justify-start">
                            <Input
                                value={configurationName}
                                placeholder="Configuration name"
                                fullWidth
                                callToAction
                                light
                                className="tw-w-96 "
                                onChange={(e) => {
                                    setErrorMessage(null);
                                    setConfigurationName(e.target.value);
                                }}
                                containerClassName="tw-py-1 tw-text-sm"
                                autoFocus
                            />
                            <div className="tw-ml-4 tw-flex tw-items-center">
                                <Tooltip
                                    fullWidth
                                    interactive
                                    content={
                                        <>
                                            <MacroInfoSection heading="Device IMEI number">$hwi.imei$</MacroInfoSection>
                                            <MacroInfoSection heading="Device serial number">$hwi.serialNumber$</MacroInfoSection>
                                            <MacroInfoSection heading="Device user full name">$user.fullName$</MacroInfoSection>
                                            <MacroInfoSection heading="Device user principal name">$user.userPrincipalName$</MacroInfoSection>
                                            <MacroInfoSection heading="Device user email">$user.email$</MacroInfoSection>
                                        </>
                                    }
                                >
                                    <h2 className="tw-cursor-pointer tw-text-sm tw-text-gray-500">Supported Macros</h2>
                                </Tooltip>
                            </div>
                            <div className="tw-ml-4 tw-flex tw-items-center">
                                <Tooltip
                                    fullWidth
                                    interactive
                                    content={appleTooltipContentWithLink}
                                >
                                    <Icon type="capaKnowledgeBase">
                                        <div
                                            className={classNames({
                                                'tw-mb-1 tw-mt-1 tw-block tw-text-xs tw-font-normal tw-italic': true,
                                            })}
                                        />
                                    </Icon>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <Button
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                onClick={onSubmit}
                            >
                                {isDefined(configurationId) ? 'Save' : 'Create'}
                            </Button>
                            <Button
                                noMargin
                                onClick={isDefined(configurationId) ? navigateToConfigurationMembership : navigateToConfigurationList}
                            >
                                Cancel
                            </Button>
                        </div>
                    </LayoutRow>
                </div>
                {errorMessage !== null && (
                    <div className="tw-col-span-3 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                )}
                <div className="tw-grid tw-grid-rows-auto-1fr tw-gap-4 tw-overflow-auto">
                    <QueryBuilder
                        defaultConfiguration={queryBuilderConfiguration}
                        onInit={onSubmitSearch}
                        onSubmit={onSubmitSearch}
                        className="tw-mx-auto tw-w-full"
                        ref={queryBuilderRef}
                    />
                    <div className="tw-w-[260px] tw-overflow-auto">
                        <Tabs
                            value={selectedTab}
                            onChange={changeTab}
                            orientation="vertical"
                            pills
                            variant="scrollable"
                        >
                            {memorizedTabs}
                        </Tabs>
                    </div>
                </div>
                <Paper
                    className={classNames({
                        'tw-h-full tw-overflow-auto tw-shadow-sm': true,
                        'tw-col-span-2': !showJsonMiniMap,
                    })}
                >
                    {selectedTab === AppleHomeScreenLayoutConfiguration.schema.schemaId ? (
                        <AppleHomeScreenLayoutEdit
                            schema={schemaBuilderSchema}
                            propertiesRef={propertiesBuilderRef.current}
                        />
                    ) : (
                        <SchemaBuilder
                            key={selectedTab}
                            // properties={schemaBuilderProperties}
                            schema={schemaBuilderSchema}
                            currentState={propertiesBuilderRef.current}
                        />
                    )}
                </Paper>
                {showJsonMiniMap && (
                    <WithExperimentalFeatures>
                        <JsonMiniMap
                            json={propertiesBuilderRef.current}
                            className="tw-w-80 tw-text-tiny"
                        />
                    </WithExperimentalFeatures>
                )}
            </div>
        </Page>
    );
};

const AppleHomeScreenLayout = ({ schema, ref }) => {
    const PAGE_ROWS_MAX = 4 * 6;
    const [selectedPage, setSelectedPage] = useState(1);
    const [pages, setPages] = useState([[]]);
    const [dock, setDock] = useState([]);

    const [configurationApplications, setConfigurationApplications] = useState([]);
    const [addDialog, setAddDialog] = useState({ open: false, addFunction: null });

    const [menu, setMenu] = useState({ open: false, anchorEl: null, addFunction: null, removeFunction: null });

    const api = useAppleApi();

    const getConfigurationApplications = async () => {
        const apps = await api.getConfigurationApplications();

        setConfigurationApplications(apps);
    };

    useEffect(() => {
        getConfigurationApplications();
    }, []);

    const addToPageRow = (item) => {
        const tmpPage = pages[selectedPage - 1];
        tmpPage.push(item);
        if (tmpPage.length === PAGE_ROWS_MAX) {
            pages.push([]);
            setSelectedPage((prev) => prev + 1);
        }
        setPages([...pages]);
    };

    const replaceOnPageRow = (i) => (item) => {
        const tmpPage = pages[selectedPage - 1];
        tmpPage[i] = item;
        setPages([...pages]);
    };

    const removeFromPageRow = (i) => () => {
        const tmpPage = pages[selectedPage - 1];
        tmpPage.splice(i, 1);

        if (tmpPage.length === 0 && selectedPage !== 1) {
            pages.splice(selectedPage - 1, 1);
            setSelectedPage((prev) => prev - 1);
        }

        setPages([...pages]);
    };

    const addPage = () => {
        pages.push([]);
        setSelectedPage(pages.length);
        setPages([...pages]);
    };

    const removePage = () => {
        pages.splice(selectedPage - 1, 1);
        setSelectedPage((prev) => prev - 1);
        setPages([...pages]);
    };

    const addToDock = (item) => {
        setDock([...dock, item]);
    };

    const removeFromDock = (i) => () => {
        // remove from dock
        const tmpDock = dock;
        tmpDock.splice(i, 1);
        setDock([...dock]);
    };

    const replaceOnDock = (i) => (item) => {
        const tmpDock = dock;
        tmpDock[i] = item;
        setDock([...dock]);
    };

    const openAddDialog = (addFunction) => () => {
        setAddDialog({ open: true, addFunction });
    };

    const closeAddDialog = () => {
        setAddDialog({ open: false, addFunction: null });
    };

    const onOpenMenu = (addFunction, removeFunction) => (event) => {
        setMenu({ open: true, anchorEl: event.currentTarget, addFunction, removeFunction });
    };

    const onCloseMenu = () => {
        setMenu({ open: false, anchorEl: null, index: null });
    };

    console.log('ref', ref);
    return (
        <>
            <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
                <div className="tw-flex tw-h-auto tw-w-[325px] tw-flex-col tw-rounded tw-shadow-lg tw-outline tw-outline-1 tw-outline-slate-400/50">
                    <div className="tw-flex tw-h-[587px] tw-flex-wrap tw-content-start tw-gap-x-6 tw-gap-y-4 tw-overflow-auto tw-p-4">
                        {pages[selectedPage - 1]?.map((item, i) => (
                            <ItemViewer
                                item={configurationApplications.find((app) =>
                                    app.type === 'webApp' ? app.url === item.URL : app.data.Identifier === item.BundleId
                                )}
                                index={i}
                                onClick={onOpenMenu(replaceOnPageRow(i), removeFromPageRow(i))}
                            />
                        ))}
                        {pages[selectedPage - 1]?.length < PAGE_ROWS_MAX && <AddButton onAdd={openAddDialog(addToPageRow)} />}
                    </div>
                    <div className="tw-mb-2 tw-mt-2 tw-flex tw-justify-center tw-gap-4">
                        {pages.map((page, i) => (
                            <div
                                key={i}
                                onClick={() => {
                                    if (selectedPage !== i + 1) {
                                        setSelectedPage(i + 1);
                                    } else {
                                        removePage();
                                    }
                                }}
                                className={classNames('tw-flex tw-h-5 tw-w-5 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full', {
                                    'tw-bg-blue-400 tw-text-blue-400 hover:tw-text-gray-600': selectedPage === i + 1,
                                    'tw-bg-slate-400': selectedPage !== i + 1,
                                })}
                            >
                                {selectedPage === i + 1 && (
                                    <Icon
                                        type="remove"
                                        size="small"
                                    />
                                )}
                            </div>
                        ))}
                        <div
                            className="tw-flex tw-h-5 tw-w-5 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-slate-200 tw-text-blue-500"
                            onClick={addPage}
                        >
                            <Icon
                                type="add"
                                size="small"
                            />
                        </div>
                    </div>
                    <div className="tw-flex tw-justify-center tw-gap-4 tw-rounded tw-bg-slate-100 tw-p-4 tw-backdrop-blur-sm">
                        {dock.map((item, i) => (
                            <ItemViewer
                                item={configurationApplications.find((app) =>
                                    app.type === 'webApp' ? app.url === item.URL : app.data.Identifier === item.BundleId
                                )}
                                index={i}
                                onClick={onOpenMenu(replaceOnDock(i), removeFromDock(i))}
                                withoutText
                            />
                        ))}
                        {dock.length < 4 && <AddButton onAdd={openAddDialog(addToDock)} />}
                    </div>
                </div>
            </div>
            <Dialog
                open={addDialog.open}
                onClose={closeAddDialog}
                size="md"
            >
                <div className="tw-flex tw-flex-wrap tw-gap-6 tw-p-4">
                    {configurationApplications.map((app) => (
                        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                            <div
                                key={app.id}
                                onClick={() => {
                                    const toAdd = {
                                        Type: app.type === 'webApp' ? 'WebClip' : 'App',
                                        ...(app.type === 'webApp' ? { URL: app.url } : { BundleId: app.data.Identifier }),
                                    };
                                    addDialog.addFunction(toAdd);
                                    closeAddDialog();
                                }}
                                className="tw-flex tw-h-[65px] tw-w-[65px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl"
                            >
                                <img
                                    src={app.type === 'webApp' ? `data:image/png;base64,${app.iconUrl}` : app.iconUrl}
                                    alt={app.name}
                                />
                            </div>
                            <p className="tw-max-w-[65px]">
                                <Ellipsis>{app.name}</Ellipsis>
                            </p>
                        </div>
                    ))}
                </div>
            </Dialog>
            <Menu
                open={menu.open}
                anchorEl={menu.anchorEl}
                onClose={onCloseMenu}
            >
                <MenuItem
                    onClick={() => {
                        openAddDialog(menu.addFunction)();
                        onCloseMenu();
                    }}
                >
                    Change
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        menu.removeFunction();
                        onCloseMenu();
                    }}
                >
                    Remove
                </MenuItem>
            </Menu>
        </>
    );
};

const AddButton = ({ onAdd }) => {
    return (
        <div
            onClick={onAdd}
            className="tw-flex tw-h-[55px] tw-w-[55px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-xl tw-outline-dashed tw-outline-blue-400"
        >
            <Icon
                className="tw-text-blue-400"
                type="add"
            />
        </div>
    );
};

const ItemViewer = ({ item, index, onClick, withoutText }) => {
    console.log(item);
    return (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1">
            <div
                key={index}
                className="tw-flex tw-h-[55px] tw-w-[55px] tw-cursor-pointer tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl"
                onClick={onClick}
            >
                <img
                    src={item?.type === 'webApp' ? `data:image/png;base64,${item?.iconUrl}` : item?.iconUrl}
                    alt={item?.name}
                />
            </div>
            {!withoutText && (
                <p className="tw-max-w-[50px]">
                    <Ellipsis>{item?.name}</Ellipsis>
                </p>
            )}
        </div>
    );
};

const MacroInfoSection = ({ heading, last = false, children }) => (
    <>
        <p className="tw-mb-1 tw-mt-0">{heading}</p>
        <p
            className={classNames({
                'tw-mt-0 tw-text-xs tw-font-semibold': true,
                'tw-mb-4': !last,
                'tw-mb-0': last,
            })}
        >
            {children}
        </p>
    </>
);

const JsonMiniMap = React.forwardRef((props, ref) => {
    const forceRefresh = useForceRefresh();

    useEffect(() => {
        const interval = setInterval(forceRefresh, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <JsonViewer
            {...props}
            ref={ref}
        />
    );
});

export { AppleConfigurationCreateAndEdit };
