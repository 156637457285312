import { BUTTON } from '@capasystems/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, LayoutCentered, Loading, useParams, Zoom } from '@capasystems/ui';
import { useRef, useState } from 'react';
import { useAppleApi } from '../../../hooks/useApi/useApi';
import useNavigate from '../../../hooks/useNavigate/useNavigate';
import { AppleApplicationSearchDialog } from '../application/AppleApplicationSearchDialog';

const AppleApplicationPicker = ({ initialSearchTerm = '', buttonText = 'New', ...props }) => {
    const navigate = useNavigate();
    const appleApi = useAppleApi();
    const [showApplicationTypePicker, setShowApplicationTypePicker] = useState(false);
    const iframeContainerRef = useRef();
    const [errorMessage, setErrorMessage] = useState(null);
    const [showAppleAppStore, setShowAppleAppStore] = useState(false);
    const [createApplicationDialogIsOpen, setCreateApplicationDialogIsOpen] = useState(false);

    const openApplicationTypePicker = () => {
        setShowApplicationTypePicker(true);
    };

    const closeApplicationTypePicker = () => {
        setShowApplicationTypePicker(false);
    };

    const closeAppStore = () => {
        setCreateApplicationDialogIsOpen(false);
    };

    const showTheStore = () => {
        setCreateApplicationDialogIsOpen(true);
    };

    const configureWebClip = () => {
        navigate.to(`apple/application/create/webclip`);
    };

    return (
        <>
            <Button
                noMargin
                color={BUTTON.PRIMARY}
                variant={BUTTON.RAISED}
                {...props}
                onClick={openApplicationTypePicker}
            >
                {buttonText}
            </Button>
            <Dialog
                open={showApplicationTypePicker}
                onClose={closeApplicationTypePicker}
                size="xs"
            >
                <DialogTitle>
                    <span>Application type</span>
                </DialogTitle>

                <DialogContent className="tw-w-full">
                    <Zoom in>
                        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-8 tw-text-xs">
                            <div
                                className="tw-h-44 tw-w-44 tw-cursor-pointer tw-rounded-lg tw-bg-slate-100 tw-shadow-sm tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow"
                                onClick={configureWebClip}
                            >
                                <LayoutCentered direction="column">
                                    <Icon
                                        type="appleWebClip"
                                        className="tw-mb-3 tw-text-6xl tw-text-sky-900"
                                    />
                                    <strong>Web Clip app</strong>
                                </LayoutCentered>
                            </div>
                            <div
                                className="tw-h-44 tw-w-44 tw-cursor-pointer tw-rounded-lg tw-bg-slate-100 tw-shadow-sm tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow"
                                onClick={showTheStore}
                            >
                                <LayoutCentered direction="column">
                                    <Icon
                                        type="appleAppStore"
                                        className="tw-mb-3 tw-text-6xl"
                                    />
                                    <strong>App Store app</strong>
                                </LayoutCentered>
                            </div>
                        </div>
                    </Zoom>
                    <AppleApplicationSearchDialog
                        open={createApplicationDialogIsOpen}
                        onClose={() => setCreateApplicationDialogIsOpen(false)}
                        onSubmit={({ bundleId, trackId, country }) =>
                            navigate.to('apple/application/create/' + bundleId + '/' + trackId + '?country=' + country)
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeApplicationTypePicker}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showAppleAppStore}
                onClose={closeAppStore}
                onClick={setCreateApplicationDialogIsOpen}
                PaperProps={{ style: { minWidth: '80vw', height: '80vh' } }}
            >
                <DialogContent
                    className="tw-h-full tw-w-full tw-p-0"
                    ref={iframeContainerRef}
                >
                    <LayoutCentered>
                        {errorMessage ? (
                            <div>
                                <h2>{errorMessage}</h2>
                                <Button
                                    className="tw-mt-4"
                                    color={BUTTON.PRIMARY}
                                    variant={BUTTON.RAISED}
                                    onClick={closeAppStore}
                                >
                                    Close
                                </Button>
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </LayoutCentered>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAppStore}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { AppleApplicationPicker };
