import { SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import { Button, Column, Dialog, DialogActions, DialogTitle, Ellipsis, EmptyState, Icon, IconButton, Page, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { Url } from '@capasystems/utils';
import { TAndroidDevice, TAndroidDeviceWithId } from '@db/party';
import { ENDPOINT_TYPE, NOT_AVAILABLE, OWNERSHIP, QUERY_BUILDER_ANDROID_DEVICE_LIST } from '@thirdparty/constants';
import { leafsToMongo } from '@thirdparty/utils';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionsDialog,
    BatteryLevel,
    CapaOneLink,
    JsonViewer,
    QueryBuilder,
    RenamingDialog,
    TailwindBadge,
    TwoLineCellRenderer,
    UserCellRenderer,
    UserManagementAssignUserToEndpoint,
    WidgetPaper,
    useAndroidApi,
    useAndroidEndpointsSocket,
    useColumnPicker,
    useNavigate,
} from '../../../index';
import { AndroidEndpointListCommands } from '../components/command/AndroidEndpointCommands';

const manageUserAction = {
    id: 'userManagement',
    name: 'User management',
};
const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const syncDevices = {
    id: 'syncList',
};

const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDir';

const endpointActions = [renameAction, manageUserAction];

export const AndroidDeviceList = () => {
    const androidApi = useAndroidApi();
    const columnPicker = useColumnPicker({
        id: 'android-endpoint-list',
        lockedColumns: ['Name'],
    });
    const [filter, setFilter] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState<any[]>([]);
    const [menuState, setMenuState] = useState<{ open: boolean; anchorEl: any; rowData: Partial<TAndroidDevice>; pages?: any[]; actions?: any[] }>({
        open: false,
        anchorEl: null,
        rowData: {
            name: '',
        },
        pages: [],
        actions: [],
    });
    const [isRenaming, setIsRenaming] = useState(false);
    const [isManagingUser, setIsManagingUser] = useState(false);
    const [dimensions, setDimensions] = useState({});
    const queryBuilderRef = useRef<any>({});

    const { to } = useNavigate();

    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
    });
    const [sortingState, setSortingState] = useState({
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        pageNumber: 0,
        pageSize: 100,
    });

    useEffect(() => {
        fetchData();
    }, [filter, sortingState]);

    const fetchData = () => {
        setIsLoading(true);
        androidApi
            .getDevices({
                filter: filter,
                sortBy: sortingState.sortBy,
                sortDirection: sortingState.sortDirection,
                pageNumber: sortingState.pageNumber,
                pageSize: sortingState.pageSize,
            })
            .then((response) => {
                pagingRef.current.totalRowCount = response.count;
                if (sortingState.pageNumber === 0) {
                    setDevices(response.content);
                } else {
                    setDevices((currentList) => [...currentList, ...response.content]);
                }
            })
            .catch((_) => {
                // ignore
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSubmit = (activeLeafs: any) => {
        setIsLoading(true);
        setFilter(JSON.stringify(leafsToMongo(activeLeafs)));
        setSortingState((c) => ({
            ...c,
            pageNumber: 0,
        }));
    };

    const onScrollToBottom = () => {
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: currentPagingState.pageNumber + 1,
        }));
    };

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        androidApi
            .updateDevice(menuState.rowData.id || '', {
                name: newName,
            })
            .then(() => {
                setDevices((currentList) =>
                    currentList.map((device) => {
                        if (device.id === menuState.rowData.id) {
                            return {
                                ...device,
                                name: newName,
                            };
                        }
                        return device;
                    }),
                );
            })
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const onActionClick = (action: any) => {
        closeMenu();
        if (action.id === renameAction.id) {
            onRename();
        }
        if (action.id === manageUserAction.id) {
            setIsManagingUser(true);
        }
        if (action.id === syncDevices.id) {
            androidApi.syncDeviceList();
        }
    };

    useAndroidEndpointsSocket(
        // @ts-ignore - this is not typed
        useCallback<TSocketMethod<TAndroidDevice>>(({ documentId, fullDocument }, { updateOperation, insertOperation, deleteOperation }) => {
            if (updateOperation) {
                setDevices((currentList) =>
                    currentList.map((device) => {
                        if (device.id === documentId) {
                            return {
                                ...fullDocument,
                                id: documentId,
                                user: device.user,
                            };
                        }
                        return device;
                    }),
                );
            } else if (insertOperation) {
                pagingRef.current.totalRowCount += 1;
                setDevices((currentList) => {
                    return [
                        ...currentList,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                pagingRef.current.totalRowCount -= 1;
                setDevices((currentList) => {
                    return currentList.filter((device) => device.id !== documentId);
                });
            }
        }, []),
    );

    const onOrderByChange = ({ sortBy, sortDirection }: any) => {
        Url.set(SORT_BY, sortBy);
        Url.set(SORT_DIR, sortDirection);
        setSortingState((prev) => ({
            ...prev,
            pageNumber: 0,
            sortBy,
            sortDirection,
        }));
    };
    const [showResultingPolicyErrorsColumn] = useMemo(() => {
        return [devices.some((d) => d.resultingPolicyErrors && d.resultingPolicyErrors.length > 0)];
    }, [devices]);

    return (
        <Page title="Endpoints">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center">
                    <div className="tw-ml-auto tw-flex tw-items-center tw-gap-4">
                        <QueryBuilder
                            // @ts-ignore - this is not typed
                            defaultConfiguration={QUERY_BUILDER_ANDROID_DEVICE_LIST}
                            onInit={onSubmit}
                            onSubmit={onSubmit}
                            className="tw-mx-autotw-max-w-screen-md tw-w-88"
                            ref={queryBuilderRef}
                        />
                        {/* TODO: Inport the component when its made */}
                        <AndroidEndpointListCommands
                            filter={filter}
                            affectedEndpointsCount={pagingRef.current.totalRowCount}

                            // isWorkProfile={false}
                        />
                    </div>
                    <ActionsDialog
                        open={menuState.open}
                        anchorEl={menuState.anchorEl}
                        onClose={closeMenu}
                        category="Endpoint"
                        title={menuState.rowData.name || ''}
                        pages={menuState.pages}
                        actions={endpointActions}
                        onActionClick={onActionClick}
                    />
                    <RenamingDialog
                        onCancel={onRename}
                        onSubmit={onRenameSubmit}
                        currentName={menuState.rowData.name || ''}
                        open={isRenaming}
                    />
                    <UserManagementAssignUserToEndpoint
                        open={isManagingUser}
                        onSubmit={(user: any) => {
                            setIsManagingUser(false);
                            setDevices((currentList) =>
                                currentList.map((device) => {
                                    if (device.id === menuState.rowData.id) {
                                        return {
                                            ...device,
                                            user,
                                        };
                                    }
                                    return device;
                                }),
                            );
                        }}
                        endpointType={ENDPOINT_TYPE.ANDROID}
                        endpoint={menuState.rowData}
                        onClose={() => setIsManagingUser(false)}
                    />
                </div>
                <WidgetPaper headerless>
                    <VirtualizedTable
                        isLoading={isLoading}
                        items={devices}
                        totalRowCount={pagingRef.current.totalRowCount}
                        entity="endpoint"
                        showRowCount={pagingRef.current.totalRowCount > 0}
                        disableHeader={pagingRef.current.totalRowCount === 0}
                        sort={onOrderByChange}
                        sortBy={sortingState.sortBy}
                        sortDirection={sortingState.sortDirection}
                        onScrollToBottom={onScrollToBottom}
                        // @ts-ignore - this is not typed
                        onResize={setDimensions}
                        noRowsRenderer={() => {
                            const isSearching = Object.keys(filter).length > 0;
                            return (
                                <EmptyState
                                    isSearching={isSearching}
                                    entity="endpoint"
                                    description="Get started by setting up an enrollment configuration."
                                    onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                                    onClick={isSearching ? undefined : () => to('android/enrollment')}
                                    buttonText="Enrollment"
                                />
                            );
                        }}
                        columnPicker={columnPicker}
                    >
                        <Column
                            minWidth={32}
                            maxWidth={32}
                            dataKey=""
                            label=""
                            disableSort
                            type="icon"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <IconButton
                                        onClick={(e) => {
                                            setMenuState({
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                rowData,
                                                pages: [
                                                    {
                                                        name: 'Software',
                                                        url: `android/device/${rowData.id}/inventory/software`,
                                                        icon: 'categoryOutlined',
                                                    },
                                                    {
                                                        name: 'Hardware',
                                                        url: `android/device/${rowData.id}/inventory/hardware`,
                                                        icon: 'memory',
                                                    },
                                                    {
                                                        name: 'Configurations',
                                                        icon: 'androidConfiguration',
                                                        url: `android/device/${rowData.id}/configurations`,
                                                    },
                                                    {
                                                        name: 'Applications',
                                                        icon: 'androidApplication',
                                                        url: `android/device/${rowData.id}/applications`,
                                                    },
                                                    {
                                                        name: 'Groups',
                                                        icon: 'androidGroup',
                                                        url: `android/device/${rowData.id}/groups`,
                                                    },
                                                ],
                                            });
                                        }}
                                        noMargin
                                    >
                                        <Icon type="moreVert" />
                                    </IconButton>
                                );
                            }}
                        />
                        <Column
                            minWidth={160}
                            dataKey="name"
                            label="Name"
                            type="multiLine"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <CapaOneLink to={`android/device/${rowData.id}/dashboard`}>
                                        <TwoLineCellRenderer
                                            main={rowData.name}
                                            secondary={rowData.androidData?.hardwareInfo?.model || NOT_AVAILABLE}
                                        />
                                    </CapaOneLink>
                                );
                            }}
                        />
                        {showResultingPolicyErrorsColumn && (
                            <Column
                                minWidth={40}
                                maxWidth={40}
                                dataKey="name"
                                label=""
                                type="string"
                                cellRenderer={({ rowData }) => {
                                    return <ResultingPolicyErrorsInformation endpoint={rowData} />;
                                }}
                            />
                        )}
                        <Column
                            minWidth={160}
                            dataKey="user.name"
                            label="User"
                            type="string"
                            cellRenderer={({ rowData }) => {
                                return (
                                    <UserCellRenderer
                                        user={rowData.user}
                                        onAddUserClick={() => {
                                            setMenuState((c) => ({
                                                ...c,
                                                rowData,
                                            }));
                                            setIsManagingUser(true);
                                        }}
                                    />
                                );
                            }}
                        />

                        <Column
                            minWidth={120}
                            maxWidth={160}
                            dataKey="androidData.lastStatusReportTime"
                            label="Last check-in"
                            type="timestamp"
                            cellRenderer={({ rowData }) => {
                                return rowData.androidData?.lastStatusReportTime ? dayjs(rowData.androidData?.lastStatusReportTime).fromNow() : 'never';
                            }}
                        />
                        <Column
                            minWidth={96}
                            maxWidth={96}
                            dataKey="batteryLevel"
                            label="Battery"
                            type="icon"
                            cellRenderer={({ cellData }) => {
                                return <BatteryLevel percent={cellData ? cellData : null} />;
                            }}
                        />
                        <Column
                            minWidth={160}
                            maxWidth={160}
                            dataKey="androidData"
                            label="Serial number"
                            disableSort
                            type="string"
                            cellRenderer={({ rowData }) => <Ellipsis>{rowData.androidData?.hardwareInfo?.serialNumber || NOT_AVAILABLE}</Ellipsis>}
                        />
                        <Column
                            minWidth={160}
                            maxWidth={160}
                            disableSort
                            dataKey="androidData"
                            label="IMEI"
                            type="string"
                            cellRenderer={({ rowData }) => <Ellipsis>{rowData.androidData?.networkInfo?.imei || NOT_AVAILABLE}</Ellipsis>}
                        />
                        <Column
                            minWidth={128}
                            maxWidth={128}
                            dataKey="androidData"
                            label="Ownership"
                            type="badge"
                            cellRenderer={({ rowData }) => {
                                if (rowData.androidData?.ownership === OWNERSHIP.COMPANY_OWNED.id) {
                                    return (
                                        <Tooltip
                                            content="This device is owned by your organization."
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="sky"
                                                noShadow
                                            >
                                                {OWNERSHIP.COMPANY_OWNED.name}
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                } else if (rowData.androidData?.ownership === OWNERSHIP.PERSONALLY_OWNED.id) {
                                    return (
                                        <Tooltip
                                            content="This device is personally owned by the user."
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="orange"
                                                noShadow
                                            >
                                                {OWNERSHIP.PERSONALLY_OWNED.name}
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                } else {
                                    return (
                                        <Tooltip
                                            content="The ownership of this device is unspecified."
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="slate"
                                                noShadow
                                            >
                                                {OWNERSHIP.OWNERSHIP_UNSPECIFIED.name}
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                }
                            }}
                        />
                        <Column
                            disableSort
                            minWidth={128}
                            maxWidth={128}
                            dataKey=""
                            label="Management mode"
                            type="badge"
                            cellRenderer={({ rowData }) => {
                                if (rowData.androidData?.state === 'LOST') {
                                    return (
                                        <Tooltip
                                            content="This device is currently in Lost Mode"
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="red"
                                                noShadow
                                            >
                                                Lost Mode
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                }
                                const { kioskCustomLauncherEnabled } = rowData.resultingPolicy;
                                if (kioskCustomLauncherEnabled) {
                                    return (
                                        <Tooltip
                                            content="Device is in Kiosk Mode"
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="cyan"
                                                className="tw-gap-1"
                                                dark
                                                noShadow
                                            >
                                                <Icon
                                                    type="kioskMode"
                                                    className="tw-text-sm"
                                                />
                                                <span>Kiosk Mode</span>
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                } else if (rowData.androidData?.managementMode === 'DEVICE_OWNER') {
                                    return (
                                        <Tooltip
                                            content="Enrolled in Fully Managed Mode"
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="indigo"
                                                noShadow
                                            >
                                                Fully Managed
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                } else if (rowData.androidData?.managementMode === 'PROFILE_OWNER') {
                                    return (
                                        <Tooltip
                                            content="Enrolled in Work Profile Mode"
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <TailwindBadge
                                                size="small"
                                                color="purple"
                                                noShadow
                                            >
                                                Work Profile
                                            </TailwindBadge>
                                        </Tooltip>
                                    );
                                }
                            }}
                        />
                    </VirtualizedTable>
                </WidgetPaper>
            </div>
        </Page>
    );
};

export type ResultingPolicyErrorsInformationProps = {
    endpoint?: TAndroidDeviceWithId;
};

export const ResultingPolicyErrorsInformation: React.FC<ResultingPolicyErrorsInformationProps> = ({ endpoint }) => {
    const [isDialogOpen, setDialogOpen] = useState(false);

    if (endpoint && endpoint.resultingPolicyErrors.length > 0) {
        return (
            <>
                <Tooltip content="Resulting Policy Error">
                    <div
                        className="tw-inline-flex tw-cursor-pointer tw-rounded-full tw-p-2 hover:tw-bg-gray-200"
                        onClick={() => setDialogOpen(true)}
                    >
                        <Icon
                            type="badgeExclamation"
                            className="tw-animate-pulse tw-text-rose-600"
                        />
                    </div>
                </Tooltip>

                <Dialog
                    open={isDialogOpen}
                    size="lg"
                    onClose={() => setDialogOpen(false)}
                >
                    <DialogTitle>Resulting Policy Error Log</DialogTitle>
                    <div className="tw-grid-cols-auto tw-grid tw-max-h-full tw-overflow-auto">
                        <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white ">
                            <JsonViewer
                                json={endpoint.resultingPolicyErrors}
                                colorControlled
                            />
                        </div>
                    </div>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
};
