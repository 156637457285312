import classNames from 'classnames';
import React from 'react';
import { PremiumFeature } from '../contact/contact.components';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export type AdminOnDemandPermissionsCheckProps = {
    children: React.ReactNode;
    teaserProps?: AdminInDemandTeaserProps;
};

export const AdminOnDemandPermissionsCheck: React.FC<AdminOnDemandPermissionsCheckProps> = ({ children, teaserProps = {} }) => {
    const { permissions } = useAuthContext();
    if (permissions.canViewAdminOnDemand) {
        return children;
    }
    return <AdminInDemandTeaser {...teaserProps} />;
};

export type AdminInDemandTeaserProps = {
    className?: string;
};

const AdminInDemandTeaser: React.FC<AdminInDemandTeaserProps> = ({ className }) => {
    return (
        <div className={classNames('tw-h-full tw-p-0.5', className)}>
            <WidgetPaper
                headerless
                className="tw-h-full"
            >
                <PremiumFeature
                    description="CapaOne AdminOnDemand ensures that end-users are only granted elevated permissions when they need them. It’s one of the most simple and powerful Privileged Access Management (PAM) solutions available."
                    icon="capaoneAdminOnDemand"
                    defaultSubject="CapaOne AdminOnDemand"
                    productPage="https://www.capasystems.com/product-adminondemand"
                />
            </WidgetPaper>
        </div>
    );
};
