import React, { useEffect, useMemo, useRef, useState } from 'react';
import TWC from 'tailwindcss/colors';
import { PieChart, eSize } from '../charts/pie-chart';
import { useApi } from '../hooks/useApi/useApi';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

export type DriverSupportedDevicesProps = {
    className?: string;
};

export const DriverSupportedDevices: React.FC<DriverSupportedDevicesProps> = ({ className }) => {
    const api = useApi();

    const navigate = useNavigate();

    const [chartData, setChartData] = useState<any>();
    const [loading, setLoading] = useState(true);

    const textRef = useRef<any>();

    useEffect(() => {
        api.getDevicesDriverSupported()
            .then(setChartData)
            .catch(() => setChartData(undefined))
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const supported = {
            name: 'Supported Devices',
            color: TWC.emerald[400],
            y: chartData.supported,
            isSupported: true,
        };

        const unsupported = {
            name: 'Unsupported Devices',
            color: TWC.red[400],
            y: chartData.unsupported,
            isSupported: false,
        };

        return [supported, unsupported];
    }, [chartData]);

    textRef.current = calculatedData.reduce((p, c) => p + c.y, 0);

    return (
        <WidgetPaper
            // @ts-ignore - this is not typed yet
            title="Driver Supported Devices"
            // @ts-ignore - this is not typed yet
            description="Percentage of devices supported by driver updater"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
                onClick={(e: any) => {
                    if (e.isSupported) {
                        navigate.to(`windows/device/list?driver.isSupported=true`);
                    } else {
                        navigate.to(`windows/device/list?driver.isSupported=false`);
                    }
                }}
            />
        </WidgetPaper>
    );
};
