import { useEffect, useState } from 'react';
import { UpdaterApplicationUpToDate, UpdaterApplicationUpdateAvailable, WidgetsContainer, useApi, useAuthContext } from '../../index';

export const UpdaterHome = ({ children }) => {
    const { permissions } = useAuthContext();
    const api = useApi();

    const [softwareData, setSoftwareData] = useState();

    useEffect(() => {
        if (permissions.canManageSoftwarePatching) {
            api.getSoftwareDashboard()
                .then(setSoftwareData)
                .catch((errorResponse) => {
                    console.log(errorResponse);
                });
        }
    }, [permissions, api]);

    if (!permissions.canManageSoftwarePatching) {
        return null;
    }

    return (
        <WidgetsContainer>
            {children}
            <div className="tw-grid tw-grid-cols-8 tw-gap-4">
                <UpdaterApplicationUpdateAvailable
                    data={softwareData}
                    legendWidth={null}
                    className="tw-col-span-8 lg:tw-col-span-4 2xl:tw-col-span-4"
                />
                <UpdaterApplicationUpToDate
                    data={softwareData}
                    legendWidth={null}
                    className="tw-col-span-8 lg:tw-col-span-4 2xl:tw-col-span-4"
                />
            </div>
        </WidgetsContainer>
    );
};

UpdaterHome.propTypes = {};
