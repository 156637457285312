import { SORT_DIRECTION } from '@capasystems/constants';
import { LayoutCentered, Page } from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CapaOneLink, TransferList, useGroupsSocket, useManagementApi } from '../../../../index';

const AppleEndpointGroupsTab = ({ endpoint }) => {
    const [groupsList, setGroupsList] = useState([]);
    const managementApi = useManagementApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const [viewMode, setViewMode] = useState(true);
    const [transferListProps, setTransferListProps] = useState({
        loading: true,
    });

    useEffect(() => {
        managementApi
            .getGroups()
            .then((groupsResponse) => {
                setGroupsList(groupsResponse);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get groups');
            });
    }, [managementApi]);

    useGroupsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setGroupsList((currentGroups) => {
                    return currentGroups.map((group) => {
                        if (group.id === documentId) {
                            return {
                                ...group,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return group;
                    });
                });
            } else if (insertOperation) {
                setGroupsList((currentGroups) => {
                    return [
                        ...currentGroups,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setGroupsList((currentList) => {
                    return currentList.filter((group) => group.id !== documentId);
                });
            }
        }, [])
    );

    const memoizedGroups = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        return groupsList
            .map((group) => ({
                ...group,
                applied: group.endpointRefs.some(({ refId }) => refId === endpoint.id),
            }))
            .sort(sortingFunction);
    }, [endpoint, groupsList]);

    const onAdd = (groupIds) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .addAppleEndpointToGroups(endpoint.id, groupIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('group', groupIds.length)}`,
                });
            });
    };

    const onRemove = (groupIds) => {
        setTransferListProps({
            savingChanges: true,
        });
        managementApi
            .removeEndpointFromGroups(endpoint.id, groupIds, 'apple')
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('group', groupIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }) => {
        if (viewMode) {
            return (
                <CapaOneLink to={`management/group/${rowData.id}/apple/endpoints`}>
                    <b>{rowData.name}</b>
                </CapaOneLink>
            );
        }
        return <b>{rowData.name}</b>;
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }

    return (
        <Page title="Endpoints · Groups">
            <TransferList
                items={memoizedGroups}
                onAdd={onAdd}
                onRemove={onRemove}
                entity="group"
                className="tw-py-4"
                viewMode={viewMode}
                setViewMode={setViewMode}
                cellRenderer={cellRenderer}
                totalRowCount={memoizedGroups.filter((g) => !g.applied).length}
                totalRowCountAssigned={memoizedGroups.filter((g) => g.applied === true).length}
                {...transferListProps}
            />
        </Page>
    );
};

export { AppleEndpointGroupsTab };
