//Documentation
// https://developer.apple.com/documentation/devicemanagement/installapplicationcommand/command?language=objc

export const AppleInstallApplicationCommand = {
    schema: {
        title: 'Install Application Command',
        description: '',
        category: 'Application',
        type: 'object',
        schemaId: 'appleInstallApplicationCommand',
        properties: {
            Identifier: {
                title: 'The app’s bundle ID',
                type: 'string',
                required: true,
                disabled: true,
            },
            ManagementFlags: {
                title: 'ManagementFlags',
                type: 'string',
                description: 'Set of management flags for the app.',
                options: [
                    { id: 1, name: 'Remove the app upon removal of the MDM Profile' },
                    { id: 4, name: 'Prevent backup of app data' },
                    { id: 5, name: 'Both of the above' },
                ],
            },
            Attributes: {
                title: '',
                type: 'object',
                properties: {
                    Removable: {
                        title: 'Removable',
                        description: 'If disabled, this app isn’t removable while it’s a managed app.',
                        help: 'Available in iOS 14 and later, and tvOS 14 and later.',
                        type: 'boolean',
                    },
                    TapToPayScreenLock: {
                        title: 'Tap to Pay',
                        description:
                            'If enabled, it requires users to use Face ID or a passcode to unlock their device on iPhone after every transaction that requires a customer’s card PIN. If disabled, the user can configure this setting on their device.',
                        type: 'boolean',
                    },
                    AssociatedDomains: {
                        title: 'Associated Domains',
                        description: 'A list that contains the associated domains to add to this app.',
                        help: 'Available in iOS 13 and later.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    AssociatedDomainsEnableDirectDownloads: {
                        title: 'Domains Enable Direct Downloads',
                        description:
                            'If enabled, perform claimed site association verification directly at the domain instead of on Apple’s servers. Only set this to true for domains that can’t access the internet',
                        help: 'Available in iOS 14 and later.',
                        type: 'boolean',
                    },
                },
            },
            Options: {
                title: '',
                type: 'object',
                properties: {
                    PurchaseMethod: {
                        title: 'VPP Settings',
                        description: 'If enabled, this app uses a VPP license when linked to a device if one is available',
                        type: 'string',
                        options: [
                            {
                                name: 'Free app. No VPP licenses required',
                                id: 0,
                            },
                            {
                                name: 'VPP app. VPP licenses required',
                                id: 1,
                            },
                        ],
                    },
                },
            },
        },
    },
};
