import MUIListItem, { ListItemProps } from '@mui/material/ListItem';
import React from 'react';

const listItemClasses = {
    container: 'cs-list-item-container',
    root: 'cs-list-item',
    selected: 'cs-list-item-selected',
};

export const ListItem: React.FC<ListItemProps> = (props) => {
    return (
        <MUIListItem
            classes={listItemClasses}
            {...props}
        />
    );
};
