export const AndroidLocationConfiguration = {
    schema: {
        title: 'Location',
        type: 'object',
        category: 'Networking',
        schemaId: 'locationConfiguration',
        properties: {
            shareLocationDisabled: {
                title: 'Share Location Disabled',
                description: 'Whether location sharing is disabled.',
                help: 'ShareLocationDisabled is supported for both fully managed devices and personally owned work profiles.',
                type: 'boolean',
            },
            locationMode: {
                title: 'Location Mode',
                description: 'The degree of location detection enabled.',
                type: 'string',
                options: [
                    {
                        id: 'LOCATION_USER_CHOICE',
                        name: 'User choice',
                    },
                    {
                        id: 'LOCATION_ENFORCED',
                        name: 'Enable location settings',
                    },
                    {
                        id: 'LOCATION_DISABLED',
                        name: 'Disable location settings',
                    },
                ],
            },
        },
    },
};
