import { LAYOUT } from '@capasystems/constants';
import React from 'react';
import { LayoutCore, LayoutCoreProps } from '../layout-core/layout-core';
import './layout-row.scss';

type LayoutRowProps = {
    /** start | center | end */
    align?: string;
    /** Specify the width */
    width?: number | string;
    /** Fill out layout. */
    fill?: boolean;
    /** Scroll content. */
    scrollContent?: boolean;
    children: React.ReactNode;
    onClick?: (input: any) => void;
};

export const LayoutRow: React.FC<LayoutRowProps & LayoutCoreProps> = ({ style = {}, width, align = 'start', scrollContent = false, ...otherProps }) => {
    let styles: any = {};
    if (width) {
        styles = LAYOUT.WIDTH;
        styles.maxWidth = `${width}%`;
    }
    return (
        <LayoutCore
            direction="row"
            style={{ ...styles, ...style }}
            align={align}
            scrollContent={scrollContent}
            {...otherProps}
        />
    );
};
