import React, { useState, useEffect, useCallback } from 'react';
import { useParams, LayoutCentered, Loading } from '@capasystems/ui';
import { useApi, PageTitle, useDeviceSocket } from './../../../index';

const WindowsEndpointContext = React.createContext();

const defaultDriverState = {
    compliant: true,
    isSupported: false,
    patchCount: 0,
};

const WindowsEndpointContextProvider = ({ children }) => {
    const { deviceId } = useParams();
    const api = useApi();
    const [initialized, setInitialized] = useState(false);
    const [anErrorOccurred, setAnErrorOccurred] = useState();
    const [endpoint, setEndpoint] = useState(null);

    useDeviceSocket(
        deviceId,
        useCallback(({ fullDocument }) => {
            const { _id: id, tags, ...changes } = fullDocument;
            setEndpoint({
                driver: defaultDriverState,
                ...changes,
                tags: tags || [],
                id,
            });
        }, [])
    );

    useEffect(() => {
        if (deviceId) {
            api.getDevice(deviceId)
                .then((data) => {
                    setEndpoint({
                        driver: defaultDriverState,
                        ...data,
                        tags: data.tags || [],
                    });
                    setInitialized(true);
                })
                .catch(() => {
                    setAnErrorOccurred(true);
                });
        }
    }, [api, deviceId]);

    if (initialized) {
        return (
            <WindowsEndpointContext.Provider
                value={{
                    endpoint,
                }}
            >
                {children}
            </WindowsEndpointContext.Provider>
        );
    }
    return (
        <LayoutCentered>
            {anErrorOccurred ? (
                <div>
                    <PageTitle category="An error occurred">The selected endpoint was not found.</PageTitle>
                </div>
            ) : (
                <Loading />
            )}
        </LayoutCentered>
    );
};

export { WindowsEndpointContext, WindowsEndpointContextProvider };
