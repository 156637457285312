export const COMPANY_NAME = 'CapaSystems';

export const PRODUCT_NAME = 'CapaOne';

export const BASE_ORGANIZATION_ROUTE = '/organization/:organizationId';

export const STATUS_CODE = {
    USER_PROFILE_NOT_INITIALIZED: 409,
    DEPENDENCIES: 409, // Delete not allowed, due to dependencies to other parts.
};
export const LOG_LEVEL = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
};

export const AZURE = {
    SYNC_STATUS: {
        NEVER_SYNCED: 'NEVER_SYNCED',
        SYNC_IN_PROGRESS: 'SYNC_IN_PROGRESS',
        FAILED: 'FAILED',
        SUCCESS: 'SUCCESS',
    },
};

export const NOT_AVAILABLE = 'N/A';

export const NONE = 'None';

export const ENDPOINT_TYPE = { ANDROID: 'ANDROID', APPLE: 'APPLE', WINDOWS: 'WINDOWS' };

export const linkingMethod = {
    direct: {
        name: 'Assigned Directly',
        id: 'Assigned direct',
    },
    group: {
        name: 'Assigned via Group',
        id: 'Assigned via group',
    },
    groupAndDirect: {
        name: 'Assigned via group and directly',
        id: 'Assigned via group and direct',
    },
};

export const SESSION_STORAGE_KEY = {
    UNSAVED_CHANGES: 'unsavedChanges',
};

export const SYSTEM_UPDATE_STATUS = {
    UP_TO_DATE: { id: 'UP_TO_DATE', name: 'Up to date' },
    UNKNOWN: { id: 'UNKNOWN', name: 'Unknown' },
    UPDATE_AVAILABLE: { id: 'UPDATE_AVAILABLE', name: 'Update available' },
};

export const OWNERSHIP = {
    COMPANY_OWNED: { id: 'COMPANY_OWNED', name: 'Company owned' },
    PERSONALLY_OWNED: { id: 'PERSONALLY_OWNED', name: 'Personally owned' },
    OWNERSHIP_UNSPECIFIED: { id: 'OWNERSHIP_UNSPECIFIED', name: 'Unspecified' },
};

export const MANAGEMENT_MODE = {
    PROFILE_OWNER: { id: 'PROFILE_OWNER', name: 'Work profile' },
    DEVICE_OWNER: { id: 'DEVICE_OWNER', name: 'Fully managed' },
    MANAGEMENT_MODE_UNSPECIFIED: { id: 'MANAGEMENT_MODE_UNSPECIFIED', name: 'Management mode unspecified' },
};
