import React from 'react';
import { DriverCompliance, DriverUpdateCategory, WidgetsContainer, useAuthContext } from '../../index';

export type DriverHomeProps = {
    children: React.ReactNode;
};

export const DriverHome: React.FC<DriverHomeProps> = ({ children }) => {
    const { permissions } = useAuthContext();

    if (!permissions.canManageDrivers) {
        return null;
    }

    return (
        // @ts-ignore - this is not typed yet
        <WidgetsContainer>
            {children}
            <div className="tw-grid tw-grid-cols-8 tw-gap-4">
                <DriverCompliance className="tw-col-span-8 lg:tw-col-span-4 2xl:tw-col-span-4" />
                <DriverUpdateCategory className="tw-col-span-8 lg:tw-col-span-4 2xl:tw-col-span-4" />
            </div>
        </WidgetsContainer>
    );
};

DriverHome.propTypes = {};
