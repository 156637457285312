import MUIPaper, { PaperProps as MUIPaperProps } from '@mui/material/Paper';
import classnames from 'classnames';
import React from 'react';

const paperClasses = {
    root: 'cs-paper',
    rounded: 'cs-paper-rounded',
    outlined: 'cs-paper-outlined',
};

export type PaperProps = MUIPaperProps & {
    dark?: boolean;
};

export const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
    ({ className, elevation = 1, square = false, dark = false, variant = 'elevation', ...props }, ref) => {
        return (
            <MUIPaper
                ref={ref}
                classes={paperClasses}
                className={classnames(
                    {
                        'cs-paper-dark': dark,
                    },
                    className
                )}
                elevation={elevation}
                square={square}
                variant={variant}
                {...props}
            />
        );
    }
);

export default Paper;
