import { Fade } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React, { CSSProperties } from 'react';
import './loading.scss';

export type LoadingProps = CircularProgressProps & {
    transitionDelay?: CSSProperties['transitionDelay'];
};

export const Loading: React.FC<LoadingProps> = ({
    variant = 'indeterminate',
    color = 'primary',
    size = 32,
    thickness = 3.6,
    transitionDelay = 250,
    ...rest
}) => (
    <Fade
        in
        style={{
            transitionDelay: transitionDelay as CSSProperties['transitionDelay'], // In milliseconds. See: https://www.nngroup.com/articles/response-times-3-important-limits/
        }}
    >
        <CircularProgress
            className="cs-loading"
            variant={variant}
            color={color}
            size={size}
            thickness={thickness}
            {...rest}
        />
    </Fade>
);

export default Loading;
