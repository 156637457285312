const WIDTH = {
    WebkitBoxFlex: 1,
    WebkitFlex: '1 1 100%',
    flex: '1 1 100%',
    boxSizing: 'border-box',
    maxHeight: '100%',
    overflow: 'auto',
} as React.CSSProperties;
const FLEX = {
    WebkitFlex: 1,
    flex: 1,
    WebkitBoxFlex: 1,
    boxSizing: 'border-box',
    overflow: 'auto',
} as React.CSSProperties;

export const LAYOUT = {
    WIDTH,
    FLEX,
};

export default LAYOUT;
