import { BUTTON, TOOLTIP } from '@capasystems/constants';
import { Button, Column, Dialog, DialogActions, DialogContent, EmptyState, Icon, IconButton, Input, Select, Tooltip, VirtualizedTable } from '@capasystems/ui';
import countriesAndTimezones from 'countries-and-timezones';
import countries from 'country-list';
import { useEffect, useMemo, useState } from 'react';
import { useAppleApi } from '../../../hooks/useApi/useApi';
import useMessageContext from '../../../hooks/useMessageContext/useMessageContext';
import { TwoLineCellRenderer } from '../../../thirdparty-components/thirdparty-components';

export const AppleApplicationSearchDialog = ({ open = false, onClose = () => null, onSubmit = () => null }) => {
    const country = countriesAndTimezones.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const countryData = countries.getData().map((c) => ({ id: c.code, name: c.name }));
    countryData.sort((a, b) => a.name.localeCompare(b.name));

    const deviceTypeOptions = {
        deviceType: {
            iPhone: {
                name: 'iPhone',
                id: 'software',
            },
            iPad: {
                name: 'iPad',
                id: 'iPadSoftware',
            },
        },
    };

    const deviceOptions = Object.values(deviceTypeOptions.deviceType);

    //#region states
    const [search, setSearch] = useState('');
    const [applications, setApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [detailView, setDetailView] = useState(false);
    const [selectedDeviceType, setSelectedDeviceType] = useState([deviceOptions[0]]);
    const [deviceType, setDeviceType] = useState(deviceOptions[0].id);
    const [selectedCountry, setSelectedCountry] = useState([countryData.find((c) => c.id === country.id) || { id: 'DK', name: 'Denmark' }]);
    //#endregion states

    const { showErrorMessage } = useMessageContext();

    const api = useAppleApi();
    const handleDeviceTypeChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            const selectedId = selectedOptions[selectedOptions.length - 1].id;
            setDeviceType(selectedId);
            setSelectedDeviceType(selectedOptions);
        }
    };

    const handleCountryChange = (selectedOptions) => {
        setSelectedCountry(selectedOptions);
    };

    const searchApplications = (search) => {
        setSearch(search);
        setIsLoading(true);
        api.getItunesApplications(search, deviceType, selectedCountry[0].id)
            .then((data) => {
                setApplications(data.results);
            })
            .catch((e) => {
                showErrorMessage(e.message, { autoHideDuration: 2500 });
                setApplications([]);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (open) {
            setApplications([]);
            setSearch('');
        }
    }, [open]);

    const selectedApp = useMemo(() => {
        return applications.find((app) => app.selected);
    }, [applications]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size={'lg'}
        >
            <DialogContent className="tw-px-0">
                <div className="tw-flex tw-items-center tw-gap-x-4 tw-px-4">
                    <Input
                        onSubmit={(e) => searchApplications(e.target.value)}
                        endAdornment={<Icon type="search" />}
                        onClearSearch={() => setSearch('')}
                        placeholder="Search"
                        type="search"
                        light
                        autoFocus
                        style={{ flexGrow: 1 }}
                        callToAction
                    />
                    <div>
                        <Select
                            multiple
                            options={deviceOptions}
                            selectedOptions={selectedDeviceType}
                            onChange={handleDeviceTypeChange}
                            required
                            light
                            callToAction
                        />
                    </div>
                    <div>
                        <Select
                            options={countryData}
                            selectedOptions={selectedCountry}
                            onChange={handleCountryChange}
                            required
                            light
                            callToAction
                        />
                    </div>
                </div>

                <div style={{ height: 600, width: '100%' }}>
                    <VirtualizedTable
                        items={applications}
                        totalRowCount={applications.length}
                        noRowsRenderer={() => (
                            <EmptyState
                                entity={'application'}
                                onClearSearch={() => setSearch('')}
                                isSearching={search !== ''}
                                description={search !== '' ? 'No Application was found' : 'Please search for an application'}
                            />
                        )}
                        isLoading={isLoading}
                        onRowClick={({ rowData }) => {
                            const tmpApps = applications.map((app) => {
                                if (app.trackId === rowData.trackId) {
                                    app.selected = true;
                                } else {
                                    app.selected = false;
                                }
                                return app;
                            });
                            setApplications([...tmpApps]);
                        }}
                    >
                        <Column
                            label=" "
                            dataKey="artworkUrl60"
                            minWidth={48}
                            maxWidth={48}
                            type="avatar"
                            cellRenderer={({ cellData, rowData }) => (
                                <img
                                    alt={rowData.trackName}
                                    src={cellData}
                                    width={48}
                                    height={48}
                                />
                            )}
                        />
                        <Column
                            label="Name"
                            dataKey="trackName"
                            minWidth={160}
                            type="multiLine"
                            cellRenderer={({ cellData, rowData }) => (
                                <TwoLineCellRenderer
                                    main={cellData}
                                    secondary={rowData.artistName}
                                />
                            )}
                        />
                        <Column
                            label="Bundle ID"
                            dataKey="bundleId"
                            minWidth={160}
                            type="string"
                        />
                        <Column
                            label="Price"
                            dataKey="price"
                            minWidth={160}
                            maxWidth={160}
                            type="number"
                            cellRenderer={({ cellData, rowData }) => (
                                <div>
                                    {rowData.formattedPrice === undefined ? (
                                        <Tooltip
                                            content="Will be installed when available"
                                            position={TOOLTIP.POSITION.TOP_END}
                                        >
                                            <span>COMING SOON</span>
                                        </Tooltip>
                                    ) : (
                                        new Intl.NumberFormat(undefined, { style: 'currency', currency: rowData.currency }).format(cellData)
                                    )}
                                </div>
                            )}
                        />
                        <Column
                            label=" "
                            minWidth={40}
                            maxWidth={40}
                            type="info"
                            cellRenderer={({ cellData, rowData }) => (
                                <IconButton
                                    onClick={() => {
                                        setDetailView(true);
                                    }}
                                >
                                    <Icon type="infoOutlined" />
                                </IconButton>
                            )}
                        />
                    </VirtualizedTable>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onSubmit({ ...selectedApp, country: selectedCountry[0].id });
                    }}
                    disabled={applications.filter((app) => app.selected).length === 0}
                    variant={BUTTON.RAISED}
                    color={BUTTON.PRIMARY}
                >
                    Submit
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
            <Dialog
                open={detailView}
                size="md"
                onClose={() => {
                    setDetailView(false);
                }}
            >
                <DialogContent>
                    <div className="tw-flex tw-items-center tw-gap-4 tw-pb-4">
                        <img
                            src={selectedApp?.artworkUrl60}
                            alt={'App logo'}
                            width={60}
                            height={60}
                        />
                        <div>
                            <h2>{selectedApp?.trackName}</h2>
                            <p>{selectedApp?.artistName}</p>
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-4">
                        <p>
                            <b>Minimum Os Version: </b> {selectedApp?.minimumOsVersion}
                        </p>
                        <p>
                            <b>App Version: </b>
                            {selectedApp?.version}
                        </p>
                        <p>
                            <b>Language: </b> {selectedApp?.languageCodesISO2A?.join(', ')}
                        </p>
                        <p>
                            <b>Description: </b> {selectedApp?.description}
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
        </Dialog>
    );
};
