const PRIMARY = 'primary';
const SUCCESS = 'success';
const DANGER = 'danger';
const INHERIT = 'inherit';
const RAISED = 'contained';
const OUTLINED = 'outlined';
const TEXT = 'text';
const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';
const DEFAULT = 'default';

export const BUTTON = {
    PRIMARY,
    SUCCESS,
    DANGER,
    INHERIT,
    RAISED,
    OUTLINED,
    TEXT,
    SMALL,
    MEDIUM,
    LARGE,
    DEFAULT,
};

export default BUTTON;
