import { BUTTON } from '@capasystems/constants';
import { Button, Icon, IconButton, Input, Loading, Paper, Snackbar, SnackbarContent, Tooltip, UploadButton } from '@capasystems/ui';
import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DrawerStepRow } from '../../drawer-step-row/drawer-step-row';
import { useAppleApi } from '../../hooks/useApi/useApi';
import useAuthContext from '../../hooks/useAuthContext/useAuthContext';
import { useCoreContext } from '../../hooks/useCoreContext/useCoreContext';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import { useAppleClusterEvent } from '../../hooks/useSocket/useSocket';

export const AppleFirstTimeSetup = () => {
    const { setAppleEnterpriseAccount, appleEnterpriseAccount } = useCoreContext();
    const [step, setStep] = useState(appleEnterpriseAccount?.isReady ? 1 : 0);
    const enterPriseAccount = useRef(appleEnterpriseAccount);
    const [errorMessage, setErrorMessage] = useState(null);
    const hasSendRequest = useRef(false);

    const appleApi = useAppleApi();
    const navigate = useNavigate();

    useAppleClusterEvent(async (data) => {
        if (data?.operationType === 'update') {
            if (data.fullDocument?.hasCertificate) {
                await appleApi.createEnrollmentConfiguration({ name: 'Default', description: 'Default configuration', osType: 'IOS' }).catch((_) => {
                    // Ignore error
                });
                navigate.to('apple/enrollment');
            }
        }
        setAppleEnterpriseAccount(data.fullDocument);
    });

    useEffect(() => {
        if (appleEnterpriseAccount?.isReady) {
            setStep(1);
            return;
        } else if (!appleEnterpriseAccount?.isReady && step === 0 && appleEnterpriseAccount?.clusterHasStarted && !hasSendRequest.current) {
            appleApi.updatePushRequest().catch((error) => {
                setErrorMessage(error.data.message ?? error.message);
            });
        }
    }, [appleApi, appleEnterpriseAccount, step]);

    return (
        <>
            {step === 0 && (
                <Step1
                    enterPriseRef={enterPriseAccount}
                    setErrorMessage={setErrorMessage}
                />
            )}
            {step === 1 && (
                <Step2
                    enterpriseAccount={appleEnterpriseAccount}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={errorMessage !== null}
                onClose={() => setErrorMessage(null)}
                autoHideDuration={3000}
            >
                <SnackbarContent
                    severity="error"
                    message={errorMessage}
                />
            </Snackbar>
        </>
    );
};

const Step1 = ({ enterPriseRef, setErrorMessage }) => {
    const [loading, setLoading] = useState(enterPriseRef.current?.isReady ? !enterPriseRef.current.isReady : false);
    const api = useAppleApi();
    const { selectedOrganization } = useAuthContext();
    return (
        <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
            <Paper className="tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-p-4">
                <h1 className="tw-mb-2">Welcome to Apple Management</h1>
                <div>
                    <p>Configure and setup your apple devices with apps, restrictions and more with ease.</p>
                    <p className="tw-mt-2">Do you wish to setup our service to manage your Apple devices?</p>
                </div>
                {!loading && (
                    <div className="tw-mt-4 tw-flex tw-w-full tw-justify-center">
                        <Button
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                            disabled={loading}
                            onClick={() => {
                                setLoading(true);
                                api.appleSignup({ companyName: selectedOrganization?.name }).catch((error) => {
                                    setErrorMessage(error.data.message ?? error.message);
                                    setLoading(false);
                                });
                            }}
                        >
                            Let's get started!
                        </Button>
                    </div>
                )}
                {loading && (
                    <>
                        <div className="tw-mb-4 tw-mt-4 tw-flex tw-justify-center">
                            <Loading size={24} />
                        </div>
                        <p className="tw-text-xs tw-text-gray-400">Setting up your environment... This could take a while</p>
                    </>
                )}
            </Paper>
        </div>
    );
};

const Step2 = ({ setErrorMessage, enterpriseAccount }) => {
    const [certificateFile, setCertificateFile] = useState(null);
    const [appleAccount, setAppleAccount] = useState(enterpriseAccount?.appleAccount ?? '');
    const [isUploading, setIsUploading] = useState(false);

    const appleApi = useAppleApi();

    const onCertificateFileChange = (fileDetails) => {
        if (fileDetails.files.length !== 0) {
            if (fileDetails.filename.endsWith('.pem')) {
                setCertificateFile(fileDetails.files[0]);
                setErrorMessage(null);
            } else {
                setErrorMessage('The application file must be in a zip file format');
            }
        }
    };

    return (
        <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
            <Paper className="tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-p-4">
                <h1 className="tw-mb-2">Push Certificate</h1>
                <p>
                    We need your Apple push certificate, you can always update this later on in the settings menu. If you do not have any push certificate do
                    these steps
                </p>
                <div>
                    <AppleCertificateSteps appleAccount={enterpriseAccount?.appleAccount} />
                </div>
                <div className="tw-mt-4 tw-grid tw-w-full tw-grid-cols-1 tw-gap-4">
                    <UploadButton
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        htmlId="upload-certificate-button"
                        accept=".pem"
                        onChange={onCertificateFileChange}
                        hideSelectedFiles
                        size="large"
                        disabled={isUploading}
                        fullWidth
                    >
                        {certificateFile?.name ?? 'Select Certificate'}
                    </UploadButton>
                    <Input
                        label="Apple Acount"
                        helperText="This is the account you used to create the push certificate"
                        value={appleAccount}
                        onChange={(event) => setAppleAccount(event.target.value)}
                        className="tw-col-span-1"
                    />
                    <Button
                        className="tw-col-span-1"
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        noMargin
                        disabled={isUploading || !certificateFile || !appleAccount}
                        onClick={async () => {
                            setIsUploading(true);
                            const formData = new FormData();
                            formData.append('account', appleAccount);
                            formData.append('cert', new Blob([certificateFile], { type: 'application/octet-stream' }));
                            try {
                                await appleApi.uploadCertificate(formData);
                            } catch (error) {
                                setErrorMessage(error.data?.message ?? error.message);
                                setIsUploading(false);
                            }
                        }}
                    >
                        {isUploading ? 'Uploading...' : 'Upload'}
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export const AppleCertificateSteps = ({ appleAccount }) => {
    const { selectedOrganization } = useAuthContext();

    const theme = useTheme();
    const api = useAppleApi();

    return (
        <>
            <DrawerStepRow badgeContent="1">
                Download this{' '}
                <a
                    href={`${window.location.protocol}//${window.location.host}/api/organizations/${selectedOrganization?.id}/apple/enrollment/pushrequest/download`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: theme.palette.primary.main }}
                >
                    <b>Push Request</b>
                </a>{' '}
                <Tooltip
                    content="Regenerate push request"
                    position="top"
                >
                    <IconButton
                        noMargin
                        onClick={api.updatePushRequest}
                        size="small"
                    >
                        <Icon
                            size="small"
                            type="reload"
                        />
                    </IconButton>
                </Tooltip>
            </DrawerStepRow>
            <DrawerStepRow badgeContent="2">
                <div>
                    Go to{' '}
                    <a
                        href="https://identity.apple.com/pushcert/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: theme.palette.primary.main }}
                    >
                        <b>Apple Push Certificates</b>
                    </a>{' '}
                    and {appleAccount ? 'renew existing' : 'create a new push'} certificate with the downloaded push request
                </div>
                {appleAccount && <p className="tw-mt-4">Last apple account used: {appleAccount}</p>}
            </DrawerStepRow>
            <DrawerStepRow badgeContent="3">Download the newly created certificate</DrawerStepRow>
            <DrawerStepRow badgeContent="4">Upload the certificate</DrawerStepRow>
            <DrawerStepRow
                badgeContent="5"
                requirements
            >
                You are done!
            </DrawerStepRow>
        </>
    );
};
