//Documentation
//Restrictions
//https://developer.apple.com/documentation/devicemanagement/restrictions

export const AppleRestrictionsConfiguration = {
    //Specify com.apple.applicationaccess as the payload type.
    schema: {
        schemaId: 'appleRestrictionsConfiguration',
        category: 'Restrictions',
        title: 'Restrictions',
        type: 'object',
        properties: {
            'com.apple.applicationaccess': {
                skipIndent: true,
                title: '',
                type: 'object',
                properties: {
                    allowAccountModification: {
                        title: 'Allow Account Modification',
                        description: 'Disables account modification.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowActivityContinuation: {
                        title: 'Allow Activity Continuation',
                        description: 'Disables activity continuation.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices and with managed Apple IDs is deprecated.',
                        default: true,
                    },
                    allowAddingGameCenterFriends: {
                        title: 'Allow Adding Game Center Friends',
                        description: 'Prohibits adding friends to Game Center',
                        help: 'As of iOS 13, requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAirDrop: {
                        title: 'Allow Air Drop',
                        description: 'Disables AirDrop.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAirPlayIncomingRequests: {
                        title: 'Allow AirPlay Incoming Requests',
                        description: 'Disables incoming AirPlay requests.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAirPrint: {
                        title: 'Allow AirPrint',
                        description: 'Disables AirPrint',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAirPrintCredentialsStorage: {
                        title: 'Allow AirPrint Credentials Storage',
                        description: 'Disables keychain storage of user name and password for AirPrint.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAirPrintiBeaconDiscovery: {
                        title: 'Allow AirPrint iBeacon Discovery',
                        description:
                            'Disables iBeacon discovery of AirPrint printers, which prevents spurious AirPrint Bluetooth beacons from phishing for network traffic.',
                        help: 'Requires a supervised device',
                        type: 'boolean',
                        default: true,
                    },
                    allowAppCellularDataModification: {
                        title: 'Allow App Cellular Data Modification',
                        description: 'Disables changing settings for cellular data usage for apps.',
                        help: 'Requires a supervised device',
                        type: 'boolean',
                        default: true,
                    },
                    allowAppClips: {
                        title: 'Allow App Clips',
                        description: 'If disabled, prevents a user from adding any App Clips, and removes any existing App Clips on the device.',
                        help: 'Requires a supervised device',
                        type: 'boolean',
                        default: true,
                    },
                    allowAppInstallation: {
                        title: 'Allow App Installation',
                        description: 'Disables the App Store, and its icon is removed from the Home Screen. Users are unable to install or update their apps.',
                        help: 'Requires a supervised device',
                        type: 'boolean',
                        default: true,
                    },
                    allowApplePersonalizedAdvertising: {
                        title: 'Allow Apple Personalized Advertising',
                        description: 'Limits Apple personalized advertising.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAppRemoval: {
                        title: 'Allow App Removal',
                        description: 'Disables removal of apps from the device.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowARDRemoteManagementModification: {
                        title: 'Allow ARD Remote Management Modification',
                        description: 'If disabled, prevents modifying the Remote Management Sharing setting in System Settings.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAssistant: {
                        title: 'Allow Assistant',
                        description: 'Disables Siri.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAssistantUserGeneratedContent: {
                        title: 'Allow Assistant User Generated Content',
                        description: 'If disabled, prevents Siri from querying user-generated content from the web.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAssistantWhileLocked: {
                        title: 'Allow Assistant While Locked',
                        description: 'Disables Siri when the device is locked.',
                        help: 'This restriction is ignored if the device doesn’t have a passcode set.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAutoCorrection: {
                        title: 'Allow Auto Correction',
                        description: 'Disables keyboard autocorrection.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAutomaticAppDownloads: {
                        title: 'Allow Automatic App Downloads',
                        description:
                            'If disabled, prevents automatic downloading of apps purchased on other devices. This setting doesn’t affect updates to existing apps.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowAutomaticScreenSaver: {
                        title: 'Allow Automatic Screen Saver',
                        description: 'Disables Apple TV´s automatic screen saver',
                        type: 'boolean',
                        default: true,
                    },
                    allowAutoUnlock: {
                        title: 'Allow Auto Unlock',
                        description: 'Disallows auto unlock.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowBluetoothModification: {
                        title: 'Allow Bluetooth Modification',
                        description: 'Prevents modifying Bluetooth settings in System Settings.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowBluetoothSharingModification: {
                        title: 'Allow Bluetooth Sharing Modification',
                        description: 'Prevent modifying Bluetooth setting in System Settings.',
                        type: 'boolean',
                        default: true,
                    },
                    allowBookstore: {
                        title: 'Allow Book store',
                        description: 'Removes the Book store tab from the Books app.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowBookstoreErotica: {
                        title: 'Allow Book store Erotica',
                        description: 'The user can´t download Apple Books media that´s tagged as erotica',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowCamera: {
                        title: 'Allow Camera',
                        description: 'Disables the camera, and its icon is removed from the Home Screen.',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        type: 'boolean',
                        default: true,
                    },
                    allowCellularPlanModification: {
                        title: 'Allow Cellular Plan Modification',
                        description: 'Users can´t change any settings related to their cellular plan.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowChat: {
                        title: 'Allow Chat',
                        description:
                            'Disables the use of the iMessage with supervised devices. If the device supports text messaging, the user can still send and receive text messages.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudAddressBook: {
                        title: 'Allow Cloud Address Book',
                        description: 'Disables iCloud Address Book services.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudBackup: {
                        title: 'Allow Cloud Backup',
                        description: 'Disables backing up the device yo iCloud.',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudBookmarks: {
                        title: 'Allow Cloud Bookmarks',
                        description: 'Disables iCloud Bookmark sync.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudCalendar: {
                        title: 'Allow Cloud Calendar',
                        description: 'Disables iCloud Calendar services.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudDesktopAndDocuments: {
                        title: 'Allow Cloud Desktop And Documents',
                        description: 'Disables cloud desktop and document services',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudDocumentSync: {
                        title: 'Allow Cloud Document Sync',
                        description: 'Disables document and key-value syncing to iCloud.',
                        help: 'Requires a supervised device in iOS 13 and later, and Shared iPad doesn’t support it. Support for this restriction on unsupervised devices and with managed Apple IDs is deprecated.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudFreeform: {
                        title: 'Allow Cloud Freeform',
                        description: 'Disallows iCloud Freeform services.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudKeychainSync: {
                        title: 'Allow Cloud Keychain Sync',
                        description: 'Disables iCloud keychain synchronization.',
                        help: 'Support for this restriction on unsupervised devices and with managed Apple IDs is deprecated.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudMail: {
                        title: 'Allow Cloud Mail',
                        description: 'Disables iCloud Mail services.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudNotes: {
                        title: 'Allow Cloud Notes',
                        description: 'Disables iCloud notes services',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudPhotoLibrary: {
                        title: 'Allow Cloud Photo Library',
                        description: 'Disables iCloud Photo Library. Any photos not fully downloaded from iCloud Photo Library to the device are removed from local storage.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices and with managed Apple IDs is deprecated.',
                        default: true,
                    },
                    allowCloudPrivateRelay: {
                        title: 'Allow Cloud Private Relay',
                        description: 'Disables',
                        help: 'Requires a supervised device in iOS. Support for this restriction on unsupervised devices and with managed Apple IDs is deprecated.',
                        type: 'boolean',
                        default: true,
                    },
                    allowCloudReminders: {
                        title: 'Allow Cloud Reminders',
                        description: 'Disables iCloud Reminder services.',
                        type: 'boolean',
                        default: true,
                    },

                    allowContentCaching: {
                        title: 'Allow Content Caching',
                        description: 'Disables content caching',
                        type: 'boolean',
                        default: true,
                    },
                    allowContinuousPathKeyboard: {
                        title: 'Allow Continuous Path Keyboard',
                        description: 'Disables QuickPath keyboard.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowDefinitionLookup: {
                        title: 'Allow Definition Lookup',
                        description: 'Disables definition lookup.',
                        type: 'boolean',
                        default: true,
                    },
                    allowDeviceNameModification: {
                        title: 'Allow Device Name Modification',
                        description: 'If disabled, prevents the user from changing the device name.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowDeviceSleep: {
                        title: 'Allow Device Sleep',
                        description: 'If disabled, prevents device from automatically sleeping.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowDiagnosticSubmission: {
                        title: 'Allow Diagnostic Submission',
                        description: 'If disabled, prevents the device from automatically submitting diagnostic report to Apple.',
                        type: 'boolean',
                        default: true,
                    },
                    allowDiagnosticSubmissionModification: {
                        title: 'Allow Diagnostic Submission Modification',
                        description: 'Disables changing the diagnostic submission and app analytics setting in the Diagnostics & Usage UI in Settings.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowDictation: {
                        title: 'Allow Dictation',
                        description: 'Disallows dictation input.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowEnablingRestrictions: {
                        title: 'Allow Enabling Restrictions',
                        description:
                            'Disables the "Enable Restrictions" option in the Restrictions UI in Setting. disables the “Enable ScreenTime” option in the ScreenTime UI in Settings and disables ScreenTime if already enabled.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowEnterpriseAppTrust: {
                        title: 'Allow Enterprise App Trust',
                        description:
                            'Removes the Trust Enterprise DEveloper button in Settings > General > Profiles > & Device Management, preventing apps from being provisioning profiles. This restriction applies to free developers who are trusted because their apps were pushed through MDM. It also doesn´t revoke previously granted trust.',
                        type: 'boolean',
                        default: true,
                    },
                    allowEnterpriseBookBackup: {
                        title: 'Allow Enterprise Book Backup',
                        description: 'Disables backup Enterprise books.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowEnterpriseBookMetadataSync: {
                        title: 'Allow Enterprise Book Metadata Sync',
                        description: 'Disables sync of Enterprise books, notes, and highlights.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowEraseContentAndSettings: {
                        title: 'Allow Erase Content And Settings',
                        description: 'Disables the Erase All Content And Settings option in the Rest UI.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowESIMModification: {
                        title: 'Allow ESIM Modification',
                        description: 'Disables modifications to carrier plan related settings.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowExplicitContent: {
                        title: 'Allow Explicit Content',
                        description:
                            'Hides explicit music or video content purchased from the iTunes Store. Explicit content is marked as suck by content providers, suck as record labels, when sold through the iTunes Store.',
                        help: 'Requires a supervised device in iOS 13 and later. Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        type: 'boolean',
                        default: true,
                    },
                    allowFileSharingModification: {
                        title: 'Allow File Sharing Modification',
                        description: 'If disabled, prevents modifying File Sharing setting in System Settings',
                        type: 'boolean',
                        default: true,
                    },
                    allowFilesNetworkDriveAccess: {
                        title: 'Allow Files Network Drive Access',
                        description: 'If disabled, prevents connecting to network drives in the Files app.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowFilesUSBDriveAccess: {
                        title: 'Allow Files USB Drive Access',
                        description: 'If disabled, prevents connecting to any connected USB devices in the Files app.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowFindMyDevice: {
                        title: 'Allow Find My Device',
                        description: 'Disables Find My Device in the Find My app.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowFindMyFriends: {
                        title: 'Allow Find My Friends',
                        description: 'Disables Find My Friends in the Find My app.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowFindMyFriendsModification: {
                        title: 'Allow Find My Friends Modification',
                        description: 'Disables changes to Find My Friends.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowFingerprintForUnlock: {
                        title: 'Allow Finger print For Unlock',
                        description: 'If disabled, prevents Touch ID or Face ID from unlocking a device.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowFingerprintModification: {
                        title: 'Allow Finger print Modification',
                        description: 'If disabled, prevents the user from modifying Touch ID or Face ID.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowGameCenter: {
                        title: 'Allow Game Center',
                        description: 'Disables Game Center, and its icon is removed from the Home Screen.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowGlobalBackgroundFetchWhenRoaming: {
                        title: 'Allow Global Background Fetch When Roaming',
                        description: 'Disables global background fetch activity when an iOS phone is roaming.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowHostPairing: {
                        title: 'Allow Host Pairing',
                        description: 'Disables host pairing with the exception of the supervision host.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowInAppPurchases: {
                        title: 'Allow In App Purchases',
                        description: 'Prohibits in-app purchasing.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowInternetSharingModification: {
                        title: 'Allow Internet Sharing Modification',
                        description: 'If disabled, prevents modifying Internet Sharing Setting in System Settings.',
                        type: 'boolean',
                        default: true,
                    },
                    allowiPhoneWidgetsOnMac: {
                        title: 'Allow iPhone Widgets On Mac',
                        description: 'Disallow iPhone widgets on a Mac that has signed in the same AppleID for iCloud.',
                        help: 'Supervised only.',
                        type: 'boolean',
                        default: true,
                    },
                    allowiTunes: {
                        title: 'Allow iTunes',
                        description:
                            'Disables the iTunes Music Store, and its icon is removed from the Home screen. Users cannot preview, purchase, or download content.',
                        help: 'As os iOS 13 requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowiTunesFileSharing: {
                        title: 'Allow iTunes File Sharing',
                        description: 'Disables iTunes file sharing services.',
                        type: 'boolean',
                        default: true,
                    },
                    allowKeyboardShortcuts: {
                        title: 'Allow Keyboard Shortcuts',
                        description: 'Disables keyboard shortcuts.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },

                    allowLockScreenControlCenter: {
                        title: 'Allow Lock Screen Control Center',
                        description: 'If disabled, prevents Control Center from appearing on the Lock screen.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowLockScreenNotificationsView: {
                        title: 'Allow Lock Screen Notifications View',
                        description:
                            'Disables the Notifications history view on the lock screen, so users can´t view past notifications. However, they can still see notifications when they arrive.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowLockScreenTodayView: {
                        title: 'Allow Lock Screen Today View',
                        description: 'Disables the Today view in Notification Center on the lock screen.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowMailPrivacyProtection: {
                        title: 'Allow Mail Privacy Protection',
                        description: 'Disables Mail Privacy Protection on the device.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowManagedAppsCloudSync: {
                        title: 'Allow Managed Apps Cloud Sync',
                        description: 'If disabled, prevents managed apps from using iCloud sync.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowManagedToWriteUnmanagedContacts: {
                        title: 'Allow Managed To Write Unmanaged Contacts',
                        description:
                            'Managed apps can write contacts to unmanaged contacts accounts.  If allowOpenFromManagedToUnmanaged is true, this restriction has no effect. If this restriction is set to true, you must install the payload through MDM.',
                        type: 'boolean',
                        default: true,
                    },
                    allowMultiplayerGaming: {
                        title: 'Allow Multiplayer Gaming',
                        description: 'Prohibits multiplayer gaming.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowMusicService: {
                        title: 'Allow Music Service',
                        description: 'Disables the Music service, and the Music app reverts to classic mode.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowNews: {
                        title: 'Allow News',
                        description: 'Disables News',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowNFC: {
                        title: 'Allow NFC',
                        description: 'Disables NFC',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowNotificationsModification: {
                        title: 'Allow Notifications Modification',
                        description: 'Disables modification of notification settings.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowOpenFromManagedToUnmanaged: {
                        title: 'Allow Open From Managed To Unmanaged',
                        description: 'Documents in managed apps and accounts only open in other managed apps and accounts.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowOpenFromUnmanagedToManaged: {
                        title: 'Allow Open From Unmanaged To Managed',
                        description: 'Documents in unmanaged apps and accounts only open in other unmanaged apps and accounts.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowOTAPKIUpdates: {
                        title: 'Allow OTAPKI Updates',
                        description: 'Disables over-the-air PKI updates. Setting this restriction to false doesn’t disable CRL and OCSP checks.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPairedWatch: {
                        title: 'Allow Paired Watch',
                        description: 'Disables paring with an Apple Watch. Any currently paired Apple Watch is unpaired and the watch´s content is erased.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPasscodeModification: {
                        title: 'Allow Passcode Modification',
                        description: 'If disabled, prevents the device passcode from being added changed, or removed.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPassbookWhileLocked: {
                        title: 'Allow Passbook While Locked',
                        description: 'Hides Passbook notifications from the lock screen.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPasswordAutoFill: {
                        title: 'Allow Password Auto Fill',
                        description:
                            'Disables the AutoFill Passwords feature in iOS (with Keychain and third-party password managers) and the user isn´t prompted to use a saved password in Safari or in apps. This restriction also disables Automatic Strong Passwords, and strong passwords are no longer suggested to users. It doesn’t prevent AutoFill for contact info and credit cards in Safari.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPasswordProximityRequests: {
                        title: 'Allow Password Proximity Requests',
                        description: 'Disables requesting passwords from nearby devices.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPasswordSharing: {
                        title: 'Allow Password Sharing',
                        description: 'Disables sharing passwords with the Airdrop Passwords feature.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPersonalHotspotModification: {
                        title: 'Allow Personal Hotspot Modification',
                        description: 'Disables modifications of the personal hotspot setting.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPodcasts: {
                        title: 'Allow Podcasts',
                        description: 'Disables podcasts.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPredictiveKeyboard: {
                        title: 'Allow Predictive Keyboard',
                        description: 'Disables predictive keyboards.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowProximitySetupToNewDevice: {
                        title: 'Allow Proximity Setup To New Device',
                        description: 'Disables the prompt to set up new devices that are nearby.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowRadioService: {
                        title: 'Allow Radio Service',
                        description: 'Disables Apple Music Radio',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowRapidSecurityResponseInstallation: {
                        title: 'Allow Rapid Security Response Installation',
                        description: 'Prohibits removal of rapid security responses.',
                        type: 'boolean',
                        default: true,
                    },
                    allowRapidSecurityResponseRemoval: {
                        title: 'Allow Rapid Security Response Removal',
                        description: 'Prohibits removal of rapid security responses.',
                        type: 'boolean',
                        default: true,
                    },
                    allowRemoteAppPairing: {
                        title: 'Allow Remote App Pairing',
                        description: 'Disables pairing Apple TV for use with the Remote app or Control Center widget.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowRemoteScreenObservation: {
                        title: 'Allow Remote Screen Observation',
                        description:
                            'Disables remote screen observation by the Classroom app. If "Allow Screen Shot" is disabled, the Classroom app doesn’t observe remote screens.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowSafari: {
                        title: 'Allow Safari',
                        description: 'Disables the Safari web browser app, and its icon is removed from the Home Screen.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowSharedStream: {
                        title: 'Allow Shared Stream',
                        description: 'Disables Shared Photo Stream.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowScreenShot: {
                        title: 'Allow Screenshot',
                        description:
                            'Disables saving screenshot of the display and capturing a screen recording. It also disables the Classroom app from observing remote screens.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: true,
                    },
                    allowSharedDeviceTemporarySession: {
                        title: 'Allow Shared Device Temporary Session',
                        description: 'Temporary sessions aren’t available on Shared iPad.',
                        type: 'boolean',
                        default: true,
                    },
                    allowSpellCheck: {
                        title: 'Allow Spell-Check',
                        description: 'Disables keyboard spell-check.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowSpotlightInternetResults: {
                        title: 'Allow Spotlight Internet Results',
                        description: 'Disables Spotlight Internet search results in Siri Suggestions.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    allowSystemAppRemoval: {
                        title: 'Allow System App Removal',
                        description: 'Disables the removal of system app from the device.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowUIAppInstallation: {
                        title: 'Allow UI App Installation',
                        description:
                            'Disables the App Store, and its icon is removed from the Home Screen. However, users may continue to use host apps (iTunes, Configurator) to install or update their apps.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowUIConfigurationProfileInstallation: {
                        title: 'Allow UI Configuration Profile Installation',
                        description: 'Prohibits the user from installing configuration profiles and certificates interactively.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowUniversalControl: {
                        title: 'Allow Universal Control',
                        description: 'Disables Universal Control.',
                        type: 'boolean',
                        default: true,
                    },
                    allowUnmanagedToReadManagedContacts: {
                        title: 'Allow Unmanaged To Read Managed Contacts',
                        description:
                            'Enables unmanaged apps can read from managed contacts accounts. If "Allow Open From Managed To Unmanaged" is enabled, this restriction has no effect. If this restriction is enabled you must install the payload through MDM.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    allowUnpairedExternalBootToRecovery: {
                        title: 'Allow Unpaired External Boot To Recovery',
                        description: 'Allows devices to be booted into recovery by an unpaired device.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    allowUntrustedTLSPrompt: {
                        title: 'Allow Untrusted TLS Prompt',
                        description: 'Disables automatically rejects untrusted HTTPS certificates without prompting the user.',
                        type: 'boolean',
                        default: true,
                    },
                    allowUSBRestrictedMode: {
                        title: 'Allow USB Restricted Mode',
                        description:
                            'Allows the device to always connect to USB accessories white locked. On macOS, allows new USB accessories to connect without authorization. If the system has Lockdown mode enabled, the system ignores this value.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowVideoConferencing: {
                        title: 'Allow Video Conferencing',
                        description: 'Hides the FaceTime app.',
                        help: 'As of iOS 13 requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowVPNCreation: {
                        title: 'Allow VPN Creation',
                        description: 'Disables the creation of VPN configurations.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowWallpaperModification: {
                        title: 'Allow Wallpaper Modification',
                        description: 'If disabled, prevents wallpaper from being changed.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    allowLocalUserCreation: {
                        title: 'Allow Local User Creation',
                        description: 'If disabled, prevents creating new users in System Settings.',
                        type: 'boolean',
                        default: true,
                    },
                    allowPrinterSharingModification: {
                        title: 'Allow Printer Sharing Modification',
                        description: 'If disabled, prevents modifying Printer Sharing setting in System Settings',
                        type: 'boolean',
                        default: true,
                    },
                    allowRemoteAppleEventsModification: {
                        title: 'Allow Remote Apple Events Modification',
                        description: 'If disabled, prevents modifying Remote Apple Events Sharing setting in System Settings.',
                        type: 'boolean',
                        default: true,
                    },
                    allowStartupDiskModification: {
                        title: 'Allow Startup Disk Modification',
                        description: 'If disabled, prevents modification of Startup Disk setting in System Settings.',
                        type: 'boolean',
                        default: true,
                    },
                    allowTimeMachineBackup: {
                        title: 'Allow Time Machine Backup',
                        description: 'If disabled, prevents modification of Time Machine settings in System Settings.',
                        type: 'boolean',
                        default: true,
                    },

                    forceAirDropUnmanaged: {
                        title: 'Force AirDrop Unmanaged',
                        description: 'Enables AirDrop to be considered an unmanaged drop target.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    forceAirPlayIncomingRequestsPairingPassword: {
                        title: 'Force AirPlay Incoming Requests Pairing Password',
                        description: 'If enabled, forces all devices sending AirPlay requests to this device to use a pairing password.',
                        help: 'This key isn’t supported in tvOS 10.2 and later. Use the AirPlay Security Payload instead.',
                        type: 'boolean',
                        default: false,
                    },
                    forceAirPlayOutgoingRequestsPairingPassword: {
                        title: 'Force AirPlay Outgoing Requests Pairing Password',
                        description: 'If enabled, forces all devices receiving AirPlay request from this device to use a pairing password.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    forceAirPrintTrustedTLSRequirement: {
                        title: 'Force AirPrint Trusted TLS Requirement',
                        description: 'If enabled, requires trusted certificates for TLS printing communication.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceClassroomUnpromptedAppAndDeviceLock: {
                        title: 'Force Classroom Unprompted App And Device Lock',
                        description: 'If enabled, allows the teacher to lock apps or the device without prompting the student.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceAssistantProfanityFilter: {
                        title: 'Force Assistant Profanity Filter',
                        description: 'If enabled, forces the use of the profanity filter assistant.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceAuthenticationBeforeAutoFill: {
                        title: 'Force Authentication Before AutoFill',
                        description: 'If enabled, the user must authenticate before passwords or credit card information can be autofilled in Safari and Apps.',
                        /* If this restriction isn’t enforced, the user can toggle this feature in Settings. Only supported on devices with Face ID or Touch ID. */
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceAutomaticDateAndTime: {
                        title: 'Force Automatic Date And Time',
                        description:
                            'If enabled, Set Automatically feature in Date & Time and can´t be disabled by the user. The device´s time zone is updated only when the device can determine its location services enabled.',
                        /* The device’s time zone is updated only when the device can determine its location using a cellular connection or Wi-Fi with location services enabled */
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceClassroomAutomaticallyJoinClasses: {
                        title: 'Force Classroom Automatically Join Classes',
                        description: 'If enabled, automatically gives permission to the teacher´s requests without prompting the student.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceClassroomRequestPermissionToLeaveClasses: {
                        title: 'Force Classroom Request Permission To Leave Classes',
                        description:
                            'If enabled, a student enrolled in an unmanaged course through Classroom requests permission from the teacher when attempting to leave the course.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceClassroomUnpromptedScreenObservation: {
                        title: 'Force Classroom Unprompted Screen Observation',
                        description:
                            'If enabled and "Screen Observation Permission Modification Allowed" is also enabled in the Education payload, a student enrolled in a managed course via the Classroom app automatically gives permission to that course teacher’s requests to observe the student’s screen without prompting the student.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceDelayedAppSoftwareUpdates: {
                        title: 'Force Delayed App Software Updates',
                        description: 'If enabled, delays user visibility of non-OS Software Updates.',
                        /* Visibility of Operating System updates is controlled through forceDelayedSoftwareUpdates.
                        The delay is 30 days unless enforcedSoftwareUpdateDelay is set to another value. */
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceDelayedMajorSoftwareUpdates: {
                        title: 'Force Delayed Major Software Updates',
                        description: 'If enabled, delays user visibility of major upgrades to OS Software.',
                        type: 'boolean',
                        default: false,
                    },
                    forceDelayedSoftwareUpdates: {
                        title: 'Force Delayed Software Updates',
                        description: 'If enabled, delays user visibility of software updates.',
                        /* In macOS, seed build updates are allowed, without delay. */
                        /* The delay is 30 days unless enforcedSoftwareUpdateDelay is set to another value. */
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceEncryptedBackup: {
                        title: 'Force Encrypted Backup',
                        description: 'If enabled, encrypts all backups.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    forceLimitAdTracking: {
                        title: 'Force Limit Ad Tracking',
                        description: 'If enabled, limits ad tracking. Additionally, it disables app tracking and the Allow Apps To Request To Track setting',
                        type: 'boolean',
                        default: false,
                    },
                    forceOnDeviceOnlyDictation: {
                        title: 'Force On Device Only Dictation',
                        description: 'If enabled, disables connections to Siri servers for the purposes of dictation.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    forceOnDeviceOnlyTranslation: {
                        title: 'Force On Device Only Translation',
                        description: 'If enabled, the device won´t connect to Siri servers for the purposes of translation.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    forceWatchWristDetection: {
                        title: 'Force Watch Wrist Detection',
                        description: 'If enabled, forces a paired Apple Watch to use Wrist Detection.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    forceWiFiToAllowedNetworksOnly: {
                        title: 'Force WiFi To Allowed Networks Only',
                        description: 'If enabled, limits device to only join Wi-Fi networks set up through a configuration profile.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },
                    forceWiFiPowerOn: {
                        title: 'Force WiFi PowerOn',
                        description:
                            'If enabled, prevents Wi-Fi from being turned off in Settings or Control Center, even by entering or leaving Airplane Mode. It doesn´t prevent selecting which Wi-Fi network to use.',
                        help: 'Requires a supervised device.',
                        type: 'boolean',
                        default: false,
                    },

                    requireManagedPasteboard: {
                        title: 'Require Managed Pasteboard',
                        description:
                            'If enabled, copy paste functionality respects the "Allow Open From Managed To Unmanaged" and "Allow Open From Unmanaged To Managed" restrictions.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    safariAllowAutoFill: {
                        title: 'Safari Allow Auto Fill',
                        description:
                            'Disables Safari AutoFill for passwords, contact info, and credit cards and also prevents the Keychain from being used for AutoFill. Though third-party password managers are allowed and apps can use AutoFill.',
                        help: 'As of iOS 13, requires a supervised device.',
                        type: 'boolean',
                        default: true,
                    },
                    safariAllowJavaScript: {
                        title: 'Safari Allow JavaScript',
                        description: 'If disabled, Safari doesn´t execute JavaScript.',
                        type: 'boolean',
                        default: true,
                    },
                    safariAllowPopups: {
                        title: 'Safari Allow Popups',
                        description: 'If disabled, Safari doesn´t allow pop-up windows.',
                        type: 'boolean',
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        default: true,
                    },
                    safariForceFraudWarning: {
                        title: 'Safari Force Fraud Warning',
                        description: 'Enables Safari fraud warning.',
                        help: 'Also available for user enrollment.',
                        type: 'boolean',
                        default: false,
                    },
                    allowListedAppBundleIDs: {
                        title: 'Allow Listed App Bundle IDs',
                        description: 'If present, allows only bundle IDs listed in the list to be shown or launchable.',
                        help: 'Requires a supervised device.', //Don't show when datatype is array
                        type: 'array',
                        items: {
                            type: 'appleapplicationsearchdialog',
                        },
                    },
                    blockedAppBundleIDs: {
                        title: 'Blocked App Bundle IDs',
                        description: 'If present, prevents bundle IDs listed in the array from being shown or launchable.',
                        /* Include the value com.apple.webapp to restrict all webclips. 
                        Note that denying system apps may disable other functionality.
                        For example, denying the App Store app may prevent users from accepting the terms and conditions for user-based VPP. */
                        help: 'Requires a supervised device.',
                        type: 'array', 
                        items: {
                            type: 'appleapplicationsearchdialog',
                        },
                    },
                    autonomousSingleAppModePermittedAppIDs: {
                        title: 'Autonomous Single App Mode Permitted App IDs',
                        description: 'Allows apps identified by the bundle IDs listed in the list to autonomously enter Single App Mode.',
                        help: 'Requires a supervised device.',
                        type: 'array',
                        items: {
                            type: 'appleapplicationsearchdialog',
                        },
                    },

                    enforcedFingerprintTimeout: {
                        title: 'Enforced Fingerprint Timeout',
                        description: 'The value in hours, after which the fingerprint unlock requires a password to authenticate.',
                        help: 'The default value is 48 hours.',
                        type: 'number', //TODO: integer in hours
                        hoursToSeconds: true,
                    },

                    enforcedSoftwareUpdateDelay: {
                        title: 'Enforced Software Update Delay',
                        description:
                            'Sets how many days to delay a software update on the device. With this restriction in place, the user doesn´t see a software update until the specified number of days after the software update release date.',
                        /* This value is used by forceDelayedAppSoftwareUpdates and forceDelayedSoftwareUpdates. */
                        help: 'Requires a supervised device.',
                        type: 'number', //TODO: Should be a integer with the values from 1 to 90
                    },
                    enforcedSoftwareUpdateMinorOSDeferredInstallDelay: {
                        title: 'Enforced Software Update Minor OS Deferred Install Delay',
                        description:
                            'This restriction allows the admin to set how many days to delay a minor OS software update on the device. When this restriction is in place the user see a software update only after the specified delay after release of the software update.',
                        /* This value controls the delay for forceDelayedSoftwareUpdates. */
                        help: '',
                        type: 'number', //TODO: Should be a integer with the values from 1 to 90
                    },
                    enforcedSoftwareUpdateMajorOSDeferredInstallDelay: {
                        title: 'Enforced Software Update Major OS Deferred Install Delay',
                        description:
                            'This restriction allows the admin to set how many days to delay a major software upgrade on the device. When this restriction is in place the user sees a software upgrade only after the specified delay after the release of the software upgrade.',
                        /* This value controls the delay for forceDelayedMajorSoftwareUpdates. */
                        type: 'number', //TODO: Should be a integer with the values from 1 to 90
                    },
                    enforcedSoftwareUpdateNonOSDeferredInstallDelay: {
                        title: 'Enforced Software Update Non OS Deferred Install Delay',
                        description:
                            'This restriction allows the admin to set how many days to delay an app software update on the device. When this restriction is in place the user sees a non-OS software update only after the specified delay after the release of the software.',
                        /* This value controls the delay for forceDelayedAppSoftwareUpdates. */
                        type: 'number', //TODO: Should be a integer with the values from 1 to 90
                    },
                    ratingApps: {
                        title: 'Rating Apps',
                        description: 'The maximum level of app content allowed on the device.',
                        help: 'Pre-installed apps ignore this restriction. Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        type: 'string', //Should be integer in documentation
                        options: [
                            {
                                id: 0,
                                name: 'None',
                            },
                            {
                                id: 100,
                                name: '4+',
                            },
                            {
                                id: 200,
                                name: '9+',
                            },
                            {
                                id: 300,
                                name: '12+',
                            },
                            {
                                id: 600,
                                name: '17+',
                            },
                            {
                                id: 1000,
                                name: 'All',
                            },
                        ],
                    },
                    ratingMovies: {
                        title: 'Rating Movies',
                        description: 'The maximum level of movie content allowed on the device.',
                        type: 'string', //Should be integer in documentation
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        options: [
                            {
                                id: 0,
                                name: 'None',
                            },
                            {
                                id: 100,
                                name: 'G',
                            },
                            {
                                id: 200,
                                name: 'PG',
                            },
                            {
                                id: 300,
                                name: 'PG-13',
                            },
                            {
                                id: 400,
                                name: 'R',
                            },
                            {
                                id: 500,
                                name: 'NC-17',
                            },
                            {
                                id: 1000,
                                name: 'All',
                            },
                        ],
                    },
                    ratingRegion: {
                        title: 'Rating Region',
                        description: 'Displayed by profile tools for proper ratings for the given region.',
                        /* This data isn’t recognized or reported by the client. */
                        type: 'string',
                        options: [
                            {
                                id: 'REGION_US',
                                name: 'us',
                            },
                            {
                                id: 'REGION_AU',
                                name: 'au',
                            },
                            {
                                id: 'REGION_CA',
                                name: 'ca',
                            },
                            {
                                id: 'REGION_DE',
                                name: 'de',
                            },
                            {
                                id: 'REGION_FR',
                                name: 'fr',
                            },
                            {
                                id: 'REGION_IE',
                                name: 'ie',
                            },
                            {
                                id: 'REGION_JP',
                                name: 'jp',
                            },
                            {
                                id: 'REGION_NZ',
                                name: 'nz',
                            },
                            {
                                id: 'REGION_GB',
                                name: 'gb',
                            },
                        ],
                    },
                    ratingTVShows: {
                        title: 'Rating TV Shows',
                        description: 'The maximum level of TV content allowed on the device.',
                        type: 'string', //Should be integer in documentation
                        help: 'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        options: [
                            {
                                id: 0,
                                name: 'None',
                            },
                            {
                                id: 100,
                                name: 'TV-Y',
                            },
                            {
                                id: 200,
                                name: 'TV-Y7',
                            },
                            {
                                id: 300,
                                name: 'TV-G',
                            },
                            {
                                id: 400,
                                name: 'TV-14',
                            },
                            {
                                id: 500,
                                name: 'TV-MA',
                            },
                            {
                                id: 1000,
                                name: 'All',
                            },
                        ],
                    },
                    safariAcceptCookies: {
                        title: 'Safari Accept Cookies',
                        description: 'This values defines the conditions under which the device accepts cookies.',
                        type: 'string',
                        default: '2',
                        help:'Support for this restriction on unsupervised devices is deprecated.',
                        setUrl: 'restrictions',
                        options: [
                            {
                                id: 0,
                                name: 'Prevent Cross-Site Tracking and Block All Cookies and the user canʼt disable either setting',
                            },
                            {
                                id: 1, // or 1.5 as in documentation
                                name: 'Prevent Cross-Site Tracking and the user canʼt disable it. Doesn’t enable Block All Cookies, but the user can enable it.',
                            },
                            {
                                id: 2,
                                name: 'Prevent Cross-Site Tracking but doesn’t enable Block All Cookies. The user can toggle either setting.',
                            },
                        ],
                        /* The user-facing settings changed in iOS 11, although the possible values remain the same. Available in iOS 4 and later. */
                    },
                },
            },
        },
    },
};
