import classNames from 'classnames';
import React from 'react';
import { LayoutCore, LayoutCoreProps } from '../layout-core/layout-core';
import './layout-center.scss';

export type LayoutCenterProps = LayoutCoreProps & { children: React.ReactNode };

export const LayoutCenter: React.FC<LayoutCenterProps> = (props) => (
    <LayoutCore
        fill
        scrollContent
        align="center"
        verticalAlign="center"
        {...props}
    />
);

export type LayoutCenteredProps = {
    direction?: 'column' | 'row';
} & React.HTMLAttributes<HTMLDivElement>;

export const LayoutCentered: React.FC<LayoutCenteredProps> = ({ className, direction = 'row', ...props }) => {
    return (
        <div
            className={classNames(
                {
                    'tw-flex tw-h-full tw-items-center tw-justify-center': true,
                    'tw-flex-col': direction === 'column',
                    'tw-flex-row': direction === 'row',
                },
                className
            )}
            {...props}
        />
    );
};

export default LayoutCenter;
