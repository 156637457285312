import { createTheme } from '@mui/material/styles';

type TCapaSystemSTheme = {
    background: {
        body: string;
        paper: string;
    };
    fontFamily: string;
    palette: {
        primary: {
            light: string;
            base: string;
            dark: string;
        };
        success: {
            light: string;
            base: string;
            dark: string;
        };
        info: {
            light: string;
            base: string;
            dark: string;
        };
        warn: {
            light: string;
            base: string;
            dark: string;
        };
        danger: {
            light: string;
            base: string;
            dark: string;
        };
        typography: {
            light: string;
            base: string;
            dark: string;
            white: string;
        };
        grey: {
            light: string;
            base: string;
            dark: string;
        };
    };
    spacing: number;
    borderRadius: number;
    fontSizeXS: number;
    fontSizeSmall: number;
    fontSize: number;
    fontSizeLarge: number;
    fontSizeXL: number;
    boxShadow: string;
    animationSpeed: {
        enter: number;
        exit: number;
    };
    icon: {
        fontSize: number;
    };
    borderColor: string;
    severity: {
        success: string;
        minor: string;
        major: string;
        critical: string;
        unknown: string;
        neutral: string;
    };
};

const palette = {
    primary: {
        light: 'rgb(163, 203, 237)',
        base: 'rgb(76, 144, 205)',
        dark: 'rgb(46, 120, 187)',
    },
    success: {
        light: 'rgb(158, 240, 208)',
        base: 'rgb(69, 209, 156)',
        dark: 'rgb(37, 192, 132)',
    },
    info: {
        light: 'rgb(255, 234, 168)',
        base: 'rgb(255, 226, 83)',
        dark: 'rgb(255, 221, 49)',
    },
    warn: {
        light: 'rgb(255, 219, 168)',
        base: 'rgb(255, 184, 84)',
        dark: 'rgb(255, 170, 48)',
    },
    danger: {
        light: 'rgb(255, 174, 167)',
        base: 'rgb(255, 96, 84)',
        dark: 'rgb(255, 64, 49)',
    },
    typography: {
        light: 'rgb(29, 48, 73)',
        base: 'rgb(31, 56, 86)',
        dark: 'rgb(14, 36, 62)',
        white: 'rgb(255,255,255)',
    },
    grey: {
        light: 'rgb(238, 238, 238)',
        base: 'rgb(189, 189, 189)',
        dark: 'rgb(117, 117, 117)',
    },
};

const background = {
    body: '#F6F8FB',
    paper: '#FFFFFF',
};

const capasystems: TCapaSystemSTheme = {
    background,
    fontFamily: '"Open Sans", sans-serif',
    palette: palette,
    spacing: 8, // Margin and padding.
    borderRadius: 4,
    fontSizeXS: 12,
    fontSizeSmall: 12,
    fontSize: 14,
    fontSizeLarge: 16,
    fontSizeXL: 18,
    boxShadow: '0 6px 8px rgba(102,119,136,.03), 0 1px 2px rgba(102,119,136,.3)',
    animationSpeed: {
        enter: 300,
        exit: 300,
    },
    icon: {
        fontSize: 24,
    },
    borderColor: palette.grey.light,
    severity: {
        success: palette.success.dark,
        minor: palette.info.base, // yellow
        major: palette.warn.dark, // orange
        critical: palette.danger.dark, // dark red
        unknown: palette.grey.light,
        neutral: background.paper,
    },
};

declare module '@mui/material/styles' {
    interface Theme {
        capasystems: typeof capasystems;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        capasystems?: typeof capasystems;
    }
}

const defaultTheme = createTheme({
    capasystems,
    palette: {
        tonalOffset: 0.15,
        background: {
            paper: capasystems.background.paper,
            default: capasystems.background.body,
        },
        primary: {
            light: capasystems.palette.primary.light,
            main: capasystems.palette.primary.base,
            dark: capasystems.palette.primary.dark,
        },
        secondary: {
            light: capasystems.palette.success.light,
            main: capasystems.palette.success.base,
            dark: capasystems.palette.success.dark,
        },
        error: {
            light: capasystems.palette.danger.light,
            main: capasystems.palette.danger.base,
            dark: capasystems.palette.danger.dark,
        },
    },
    spacing: capasystems.spacing,
    typography: {
        fontSize: 14,
        fontFamily: capasystems.fontFamily,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: capasystems.spacing * 6,
                },
                regular: {
                    minHeight: capasystems.spacing * 6,
                    paddingLeft: capasystems.spacing * 2,
                    paddingRight: capasystems.spacing * 2,
                },
                dense: {
                    minHeight: capasystems.spacing * 5,
                    paddingLeft: capasystems.spacing,
                    paddingRight: capasystems.spacing,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the component
                root: {
                    // Name of the rule
                    marginRight: capasystems.spacing,
                    textTransform: 'none',
                },
                contained: {
                    color: capasystems.palette.primary.base,
                    boxShadow: 'none',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    marginRight: capasystems.spacing,
                },
                sizeSmall: {
                    minHeight: capasystems.spacing * 4,
                    minWidth: capasystems.spacing * 4,
                    height: capasystems.spacing * 4,
                    width: capasystems.spacing * 4,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: capasystems.fontSizeSmall,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: capasystems.icon.fontSize,
                },
                fontSizeSmall: {
                    fontSize: capasystems.icon.fontSize - capasystems.spacing,
                },
                fontSizeLarge: {
                    fontSize: capasystems.icon.fontSize + capasystems.spacing,
                },
            },
        },
    },
});

defaultTheme.shadows[1] = capasystems.boxShadow; /** A better default shadow. */

export { defaultTheme };
