import { TooltipProps } from '@capasystems/types';

export const TOOLTIP = {
    POSITION: {
        TOP: 'top',
        TOP_START: 'top-start',
        TOP_END: 'top-end',
        LEFT: 'left',
        LEFT_START: 'left-start',
        LEFT_END: 'left-end',
        RIGHT: 'right',
        RIGHT_START: 'right-start',
        RIGHT_END: 'right-end',
        BOTTOM: 'bottom',
        BOTTOM_START: 'bottom-start',
        BOTTOM_END: 'bottom-end',
    },
} as { POSITION: { [key: string]: TooltipProps['position'] } };

export default TOOLTIP;
