export const AppleDnsProxyConfiguration = {
    schema: {
        schemaId: 'appleDnsProxyConfiguration',
        category: 'Proxies',
        title: 'DNS Proxy',
        type: 'object',
        properties: {
            'com.apple.dnsProxy.managed': {
                //Specify com.apple.dnsProxy.managed as the payload type.
                title: '',
                description: '',
                type: 'object',
                properties: {
                    AppBundleIdentifier: {
                        title: 'App Bundle Identifier',
                        description: 'The bundle identifier of the app containing the DNS proxy network extension.',
                        type: 'appleapplicationsearchdialog',
                        required: true,
                    },
                    ProviderBundleIdentifier: {
                        title: 'Provider Bundle ID',
                        description: 'The bundle identifier of the DNS proxy network extension to use.',
                        help: 'Declaring the bundle identifier is useful for apps that contain more than one DNS proxy extension.',
                        type: 'string',
                    },
                    DNSProxyUUID: {
                        title: 'DNS Proxy UUID',
                        description: 'A globally-unique identifier for this DNS proxy configuration.',
                        help: 'Managed apps with the same DNSProxyUUID in their app attributes have their DNS lookups traffic processed by the proxy.',
                        type: 'string',
                    },
                    // ProviderConfiguration: {
                    //     title: 'Provider Configuration',
                    //     description: 'The dictionary of vendor-specific configuration items.',
                    //     type: 'object',
                    //     properties: {
                    //         any: {
                    //             type: 'any', //TODO: Quarybuilder needs to support key/value pairs
                    //             help: 'Key/value pairs.',
                    //         },
                    //     },
                    // },
                },
            },
        },
    },
};
