/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { CoreContext } from '../../contexts/core-context/core.context';
import AndroidConnection from './android-connection';
import AppleConnection from './apple-connection';
import Connection from './connection';
import ManagementConnection from './management-connection';

const getConnection = () => new Connection();
const getManagementConnection = () => new ManagementConnection();
const getAndroidConnection = (enterpriseId: string) => new AndroidConnection(enterpriseId);
const getAppleConnection = () => new AppleConnection();

export const useApi = () => {
    const [api] = useState(getConnection);
    useEffect(() => api.abort, []);
    return api;
};

export const useAndroidApi = () => {
    const { androidEnterpriseAccount } = useContext(CoreContext);
    const [androidApi] = useState(getAndroidConnection(androidEnterpriseAccount.id));
    useEffect(() => androidApi.abort, []);
    return androidApi;
};

export const useAppleApi = () => {
    //const { appleEnterpriseAccount } = useContext(CoreContext);
    const [appleApi] = useState(getAppleConnection);
    useEffect(() => appleApi.abort, []);
    return appleApi;
};

export const useManagementApi = () => {
    const [api] = useState(getManagementConnection);
    useEffect(() => api.abort, []);
    return api;
};
