export const AndroidVpnConfiguration = {
    schema: {
        title: 'VPN',
        description: '',
        type: 'object',
        category: 'Networking',
        schemaId: 'vpnConfiguration',
        properties: {
            vpnConfigDisabled: {
                title: 'VPN Config Disabled',
                description: 'Whether configuring VPN is disabled.',
                type: 'boolean',
            },
            alwaysOnVpnPackage: {
                title: 'Always On VPN Package',
                description: 'Configuration for an always-on VPN connection.',
                help: "Note: Use with 'VPN Config Disabled' to prevent modification of this setting.",
                type: 'object',
                properties: {
                    packageName: {
                        title: 'Package Name',
                        description: 'The package name of the VPN app.',
                        type: 'string',
                        required: true,
                    },
                    lockdownEnabled: {
                        title: 'Lockdown Enabled',
                        description: 'Disallows networking when the VPN is not connected.',
                        type: 'boolean',
                    },
                },
            },
        },
    },
};
