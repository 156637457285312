export const AndroidPersistentPreferredActivitiesConfiguration = {
    schema: {
        title: 'Persistent Preferred Activities',
        category: 'Application',
        type: 'object',
        schemaId: 'persistentPreferredActivitiesConfiguration',
        properties: {
            persistentPreferredActivities: {
                title: 'Persistent Preferred Activities',
                description: 'Default intent handler activities.',
                type: 'object',
                properties: {
                    receiverActivity: {
                        title: 'Receiver Activity',
                        description:
                            'The activity that should be the default intent handler. This should be an Android component name, e.g. com.android.enterprise.app/.MainActivity. Alternatively, the value may be the package name of an app, which causes Android Device Policy to choose an appropriate activity from the app to handle the intent.',
                        type: 'string',
                        help: 'Choose a name containing only letters, underscores, digits, slashes, and periods',
                    },
                    actions: {
                        title: 'Actions',
                        description:
                            "The intent actions to match in the filter. If any actions are included in the filter, then an intent's action must be one of those values for it to match. If no actions are included, the intent action is ignored.",
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                        shouldRender: (siblings: any) => {
                            if (siblings.receiverActivity) {
                                return true;
                            }
                            delete siblings.actions;
                            return false;
                        },
                    },
                    categories: {
                        title: 'Categories',
                        description:
                            'The intent categories to match in the filter. An intent includes the categories that it requires, all of which must be included in the filter in order to match. Adding a category to the filter has no impact on matching unless that category is specified in the intent.',
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                        shouldRender: (siblings: any) => {
                            if (siblings.receiverActivity) {
                                return true;
                            }
                            delete siblings.categories;
                            return false;
                        },
                    },
                },
            },
        },
    },
};
