import { coreMessage, TOOLTIP } from '@capasystems/constants';
import { dispatchResizeEvent, getTheme, getUniqueId } from '@capasystems/utils';
import classNames from 'classnames';
import React, { useState } from 'react';
import Dialog from '../dialog/dialog';
import Ellipsis, { EllipsisProps } from '../ellipsis/ellipsis';
import IconButton from '../icon-button/icon-button';
import Icon from '../icon/icon';
import LayoutColumn, { LayoutColumnProps } from '../layout-column/layout-column';
import LayoutFill from '../layout-fill/layout-fill';
import Paper from '../paper/paper';
import Toolbar from '../toolbar/toolbar';
import Tooltip from '../tooltip/tooltip';
import './dashboard-grid-item-content.scss';

const { capasystems, palette } = getTheme();
const componentClass = 'cs-dashboard-grid-item-content';
const destinationStyles = { padding: 0, flex: '1 1 auto', overflowY: 'auto' } as React.CSSProperties;
const ELLIPSIS_TOOLTIP_PROPS = {
    position: 'top',
    interactive: true,
} as EllipsisProps['tooltipProps'];

export type DashboardGridItemContentProps = LayoutColumnProps & {
    title?: string;
    description?: string;
    isRemovable?: boolean;
    onRemove?: React.MouseEventHandler<HTMLButtonElement>;
    onConfigure?: React.MouseEventHandler<HTMLButtonElement>;
    onDuplication?: React.MouseEventHandler<HTMLButtonElement>;
    isConfigurable?: boolean;
    isDuplicable?: boolean;
    severity?: 'success' | 'minor' | 'major' | 'critical' | 'neutral';
    isFullscreenable?: boolean;
    square?: boolean;
    id?: string;
};

export const DashboardGridItemContent: React.FC<DashboardGridItemContentProps> = ({
    className,
    title = '',
    description = '',
    children,
    isRemovable = false,
    onRemove,
    onConfigure,
    onDuplication,
    isConfigurable = false,
    isDuplicable = false,
    severity = 'neutral',
    style,
    isFullscreenable = false,
    square = false,
    id = `${componentClass}-${getUniqueId()}`,
}) => {
    const [fullscreened, setFullscreened] = useState(false);
    const [enteredFullscreen, setEnteredFullscreen] = useState(false);

    const color = capasystems.severity[severity || /* istanbul ignore next */ 'neutral'] as string;
    const showToolbar = title !== '' || description !== '' || isRemovable || isConfigurable || isDuplicable || isFullscreenable;

    /* istanbul ignore next */
    const toggleFullscreen = () => setFullscreened(!fullscreened);

    /* istanbul ignore next */
    const onEntered = () => {
        dispatchResizeEvent();
        setEnteredFullscreen(true);
    };

    /* istanbul ignore next */
    const onExit = () => {
        dispatchResizeEvent();
        setEnteredFullscreen(false);
    };

    const paperStyles = {
        backgroundColor: color,
        color: palette.getContrastText(color),
        ...style,
    };

    return (
        <React.Fragment>
            <PaperComponent
                className={className}
                title={title}
                description={description}
                children={children}
                isRemovable={isRemovable}
                onRemove={onRemove}
                onConfigure={onConfigure}
                onDuplication={onDuplication}
                isConfigurable={isConfigurable}
                isDuplicable={isDuplicable}
                severity={severity}
                isFullscreenable={isFullscreenable}
                square={square}
                id={id}
                fullscreened={fullscreened}
                showToolbar={showToolbar}
                enteredFullscreen={enteredFullscreen}
                toggleFullscreen={toggleFullscreen}
                paperStyles={paperStyles}
            />
            <Dialog
                fullScreen
                open={fullscreened}
                onEntered={onEntered}
                onClose={toggleFullscreen}
                onExit={onExit} // Callback fired before the dialog exits.
            >
                <div style={destinationStyles}>
                    <PaperComponent
                        className={className}
                        title={title}
                        description={description}
                        children={children}
                        isRemovable={isRemovable}
                        onRemove={onRemove}
                        onConfigure={onConfigure}
                        onDuplication={onDuplication}
                        isConfigurable={isConfigurable}
                        isDuplicable={isDuplicable}
                        severity={severity}
                        isFullscreenable={isFullscreenable}
                        square={square}
                        id={id}
                        fullscreened={fullscreened}
                        showToolbar={showToolbar}
                        enteredFullscreen={enteredFullscreen}
                        toggleFullscreen={toggleFullscreen}
                        paperStyles={paperStyles}
                    />
                </div>
            </Dialog>
        </React.Fragment>
    );
};

type PaperComponentProps = {
    className?: string;
    title: string;
    description: string | React.ReactNode;
    children: React.ReactNode;
    isRemovable: boolean;
    onRemove?: React.MouseEventHandler<HTMLButtonElement>;
    onConfigure?: React.MouseEventHandler<HTMLButtonElement>;
    onDuplication?: React.MouseEventHandler<HTMLButtonElement>;
    isConfigurable: boolean;
    isDuplicable: boolean;
    severity: 'success' | 'minor' | 'major' | 'critical' | 'neutral';
    isFullscreenable: boolean;
    square: boolean;
    id: string;
    fullscreened: boolean;
    showToolbar: boolean;
    enteredFullscreen: boolean;
    toggleFullscreen?: React.MouseEventHandler<HTMLButtonElement>;
    paperStyles: React.CSSProperties;
    style?: React.CSSProperties;
    tooltipProps?: EllipsisProps['tooltipProps'];
};

const PaperComponent: React.FC<PaperComponentProps> = ({
    className,
    title,
    description,
    children,
    isRemovable,
    onRemove,
    onConfigure,
    onDuplication,
    isConfigurable,
    isDuplicable,
    severity,
    isFullscreenable,
    square,
    id = `${componentClass}-${getUniqueId()}`,
    fullscreened,
    showToolbar,
    enteredFullscreen,
    toggleFullscreen,
    paperStyles,
    ...otherProps
}) => {
    return (
        <Paper
            className={classNames(
                className,
                componentClass,
                `${componentClass}-severity-${severity}`,
                fullscreened ? /* istanbul ignore next */ `${componentClass}-fullscreen` : null
            )}
            square={fullscreened || square}
            style={paperStyles}
            id={id}
            {...otherProps}
        >
            <LayoutColumn fill>
                {showToolbar && (
                    <Toolbar
                        compact
                        className={`${componentClass}-toolbar`}
                    >
                        <LayoutFill>
                            <Ellipsis tooltipProps={ELLIPSIS_TOOLTIP_PROPS}>
                                <b>{title}</b>
                            </Ellipsis>
                        </LayoutFill>
                        {description !== '' && (
                            <Tooltip
                                content={description}
                                position="top"
                                interactive
                            >
                                <Icon
                                    type="description"
                                    size="small"
                                />
                            </Tooltip>
                        )}
                        {isDuplicable && !enteredFullscreen && (
                            /* istanbul ignore next */
                            <Tooltip
                                content={coreMessage.duplicate}
                                position={TOOLTIP.POSITION.TOP}
                            >
                                <IconButton
                                    color="inherit"
                                    onClick={onDuplication}
                                    noMargin
                                >
                                    <Icon
                                        type="clone"
                                        size="small"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isConfigurable && (
                            /* istanbul ignore next */
                            <Tooltip
                                content={coreMessage.configure}
                                position={TOOLTIP.POSITION.TOP}
                            >
                                <IconButton
                                    color="inherit"
                                    onClick={onConfigure}
                                    noMargin
                                    id={'configure'}
                                >
                                    <Icon
                                        type="configure"
                                        size="small"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isFullscreenable && (
                            /* istanbul ignore next */
                            <Tooltip
                                content={fullscreened ? coreMessage.exitFullscreen : coreMessage.fullscreen}
                                position={TOOLTIP.POSITION.TOP}
                            >
                                <IconButton
                                    color="inherit"
                                    onClick={toggleFullscreen}
                                    noMargin
                                    disableRipple
                                    disableFocusRipple
                                    id={'toggleFullscreen'}
                                >
                                    <Icon
                                        type={enteredFullscreen ? 'exitFullscreen' : 'fullscreen'}
                                        size={enteredFullscreen ? 'medium' : 'small'}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isRemovable && !enteredFullscreen && (
                            /* istanbul ignore next */
                            <Tooltip
                                content={coreMessage.remove}
                                position={TOOLTIP.POSITION.TOP}
                            >
                                <IconButton
                                    color="inherit"
                                    onClick={onRemove}
                                    noMargin
                                >
                                    <Icon
                                        type="remove"
                                        size="small"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Toolbar>
                )}
                <LayoutFill>{children}</LayoutFill>
            </LayoutColumn>
        </Paper>
    );
};

export default DashboardGridItemContent;
