import MUIListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const defaultClasses = {
    root: 'cs-list-item-text-container',
    primary: 'cs-list-item-text',
};

export const ListItemText: React.FC<ListItemTextProps & { classes?: any }> = ({ classes = {}, ...props }) => {
    const { root, primary, ...remainingClasses } = classes;
    return (
        <MUIListItemText
            classes={{
                root: classNames(defaultClasses.root, root),
                primary: classNames(defaultClasses.primary, primary),
                ...remainingClasses,
            }}
            {...props}
        />
    );
};

ListItemText.propTypes = {
    classes: PropTypes.shape({}),
};
