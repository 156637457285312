import classNames from 'classnames';
import React from 'react';

export type ErrorTextBlockProps = {
    error?: string;
    className?: string;
};

export const ErrorTextBlock: React.FC<ErrorTextBlockProps> = ({ error, className }) => {
    if (!error) return null;
    return <div className={classNames('tw-col-span-2 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700', className)}>{error}</div>;
};
