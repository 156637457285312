export const WindowsApplicationCapapacksConfigurationInstallationMode = {
    schema: {
        title: "CapaPacks",
        type: "object",
        schemaId: "installationMode",
        properties: {
            packageName: {
                title: "Application ID",
                description: `The CapaPacks application ID`,
                type: "string",
                disabled: true
            },
            language: {
                title: "Language",
                description: "The installation language",
                help: "",
                type: "string",
                required: true,
                options: [
                ],
            },
        }
    }
};

export const WindowsApplicationCapapacksConfigurationPlatform = {
    schema: {
        title: "",
        type: "object",
        schemaId: "platform",
        properties: {
            platform: {
                title: "Platform",
                description: "The installation platform",
                help: "",
                type: "string",
                required: true,
                options: [
                ],
            },
        }
    }
};