import { FormControlLabel } from '@mui/material';
import SwitchMUI, { SwitchProps as MUISwitchProps, switchClasses } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import React from 'react';

const defaultClasses = {
    root: 'cs-switch',
    checked: 'cs-switch-checked',
    disabled: 'cs-switch-disabled',
};

const IOSSwitch = styled((props: MUISwitchProps) => (
    <SwitchMUI
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        [`&.${switchClasses.checked}`]: {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            [`& + .${switchClasses.track}`]: {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 0,
            },
            [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
                opacity: 0.5,
            },
        },
        // [`&.${switchClasses.focusVisible} .${switchClasses.thumb}`]: {
        //     color: '#52d869',
        //     border: '6px solid #fff',
        // },
        [`&.${switchClasses.disabled} .${switchClasses.thumb}`]: {
            color: theme.palette.grey[100],
        },
        [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    [`& .${switchClasses.thumb}`]: {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    [`& .${switchClasses.track}`]: {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.grey[400],
        border: `1px solid ${theme.palette.grey[400]}`,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export type SwitchProps = MUISwitchProps & {
    /** The label of the switch */
    label?: string | React.ReactNode;
    /** The placement of the label */
    labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
    /** The classes of the label */
    labelClasses?: MUISwitchProps['classes'];
    /** If true, the switch will be checked */
    checked?: boolean;
};

export const Switch: React.FC<SwitchProps> = ({
    label = null,
    labelPlacement = 'end',
    labelClasses = {},
    onChange = () => null,
    checked = false,
    ...otherProps
}) => {
    if (label) {
        return (
            <FormControlLabel
                control={
                    <IOSSwitch
                        classes={defaultClasses}
                        onChange={onChange}
                        checked={checked}
                        {...otherProps}
                    />
                }
                label={label}
                classes={{
                    root: classNames('cs-switch-label-container', labelClasses.root),
                    disabled: classNames('cs-switch-label-disabled', labelClasses.disabled),
                    // label: classNames('cs-switch-label', labelClasses.label),
                }}
                labelPlacement={labelPlacement}
            />
        );
    }
    return (
        <IOSSwitch
            classes={defaultClasses}
            onChange={onChange}
            checked={checked}
            {...otherProps}
        />
    );
};

export default Switch;
