import { Grid, Page } from '@capasystems/ui';
import React, { useContext, useEffect, useState } from 'react';
import {
    RELIABILITY,
    ReliabilityAverageScoreWidget,
    ReliabilityContext,
    ReliabilityEventBarChartWidget,
    ReliabilityEventCountWidget,
    ReliabilityEventListWidget,
    ReliabilityEventSourcesWidget,
    ReliabilityLatestScoreWidget,
    ReliabilityMostEventsByDeviceWidget,
} from './../../index';
import { ReliabilityLatestScoreForDeviceWidget } from './reliability-latest-score-widget';
import ReliabilityToolbar from './reliability-toolbar';

const GRID_CLASSES = 'tw-max-w-screen-2xl tw-mx-auto tw-grid tw-grid-cols-1 tw-grid-rows-auto-1fr tw-h-full tw-p-4 tw-gap-4';

const ReliabilityDashboardPage = ({ withPageTitle = true }) => {
    const { severityId, inDetailedMode, deviceId } = useContext(ReliabilityContext);
    const [activeFilters, setActiveFilters] = useState(null);
    const [urlIsDirty, setUrlIsDirty] = useState(false);

    const setFilters = (activeLeafs, selectedFilters) => {
        setUrlIsDirty(false);
        setActiveFilters(activeLeafs);
    };

    useEffect(() => {
        setUrlIsDirty(true);
    }, [severityId]);

    if (severityId) {
        return (
            <Page>
                <div className={GRID_CLASSES}>
                    <div>
                        <ReliabilityToolbar
                            setFilters={setFilters}
                            urlIsDirty={urlIsDirty}
                        />
                    </div>
                    <div className="tw-pb-4">
                        <Grid
                            container
                            spacing={2}
                        >
                            {deviceId && (
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <ReliabilityAverageScoreWidget />
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                lg={6}
                            >
                                <ReliabilityEventBarChartWidget
                                    className="tw-h-96"
                                    activeFilters={activeFilters}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                            >
                                <ReliabilityEventSourcesWidget
                                    activeFilters={activeFilters}
                                    setUrlIsDirty={setUrlIsDirty}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <ReliabilityEventListWidget
                                    activeFilters={activeFilters}
                                    setUrlIsDirty={setUrlIsDirty}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Page>
        );
    }

    return (
        <Page>
            <div className={GRID_CLASSES}>
                <div>
                    <ReliabilityToolbar
                        setFilters={setFilters}
                        urlIsDirty={urlIsDirty}
                    />
                </div>
                <div className="tw-pb-4">
                    <div>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={deviceId ? 7 : 6}
                            >
                                <ReliabilityAverageScoreWidget
                                    activeFilters={activeFilters}
                                    setUrlIsDirty={setUrlIsDirty}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={deviceId ? 5 : 6}
                            >
                                {deviceId ? (
                                    <ReliabilityLatestScoreForDeviceWidget className="tw-h-80" />
                                ) : (
                                    <ReliabilityLatestScoreWidget activeFilters={activeFilters} />
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <ReliabilityEventBarChartWidget
                                    className="tw-h-80"
                                    activeFilters={activeFilters}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                            >
                                <ReliabilityEventCountWidget
                                    severity={RELIABILITY.SEVERITY.CRITICAL}
                                    activeFilters={activeFilters}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                            >
                                <ReliabilityEventCountWidget
                                    severity={RELIABILITY.SEVERITY.ERROR}
                                    activeFilters={activeFilters}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                            >
                                <ReliabilityEventCountWidget
                                    severity={RELIABILITY.SEVERITY.WARNING}
                                    activeFilters={activeFilters}
                                />
                            </Grid>
                            {deviceId ? (
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <ReliabilityEventListWidget
                                        activeFilters={activeFilters}
                                        setUrlIsDirty={setUrlIsDirty}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}
                                    >
                                        <ReliabilityMostEventsByDeviceWidget
                                            severity={RELIABILITY.SEVERITY.CRITICAL}
                                            activeFilters={activeFilters}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}
                                    >
                                        <ReliabilityMostEventsByDeviceWidget
                                            severity={RELIABILITY.SEVERITY.ERROR}
                                            activeFilters={activeFilters}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}
                                    >
                                        <ReliabilityMostEventsByDeviceWidget
                                            severity={RELIABILITY.SEVERITY.WARNING}
                                            activeFilters={activeFilters}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        </Page>
    );
};

ReliabilityDashboardPage.propTypes = {};

export { ReliabilityDashboardPage };
export default ReliabilityDashboardPage;
