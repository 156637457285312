import { BUTTON } from '@capasystems/constants';
import {
    AutoComplete,
    Button,
    Dialog,
    DialogContent,
    Divider,
    EmptyState,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    LayoutColumn,
    LayoutRow,
    List,
    Loading,
    Padding,
    Select,
    Switch,
    Tab,
    Tabs,
    Tooltip,
} from '@capasystems/ui';
import { isEqual, now } from '@capasystems/utils';
import { Editor } from '@monaco-editor/react';
import { ActionsDialog, TwoLineCellRenderer, useCoreContext } from '@thirdparty/ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const GridLayout = WidthProvider(ReactGridLayout);

export const workflowItemCategories = [
    {
        label: 'Favourites',
        type: 'favourites',
        icon: {
            type: 'star',
            className: 'tw-text-amber-500',
        },
    },
    {
        label: 'File and directory',
        type: 'fileAndDirectory',
        icon: {
            type: 'contract',
            className: 'tw-text-sky-600',
        },
    },
    {
        label: 'Installer',
        type: 'installer',
        icon: {
            type: 'installDesktop',
            className: 'tw-text-sky-600',
        },
    },
    {
        label: 'Windows Service',
        type: 'windowsService',
        icon: {
            type: 'windows',
            className: 'tw-text-sky-600',
        },
    },
    {
        label: 'Power Brick',
        type: 'powerBrick',
        icon: {
            type: 'widgets',
            className: 'tw-text-sky-600',
        },
    },
    {
        label: 'Registry',
        type: 'registry',
        icon: {
            type: 'capaPacks',
            className: 'tw-text-sky-600',
        },
    },
    {
        label: 'System',
        type: 'system',
        icon: {
            type: 'computer',
            className: 'tw-text-sky-600',
        },
    },
    {
        label: 'User Management',
        type: 'userManagement',
        icon: {
            type: 'userCircle',
            className: 'tw-text-sky-600',
        },
    },
];

const TAB_ID = {
    APPLICATION: 'application',
    POWERBRICKS: 'powerbricks',
    INSTALL: 'install',
    UNINSTALL: 'uninstall',
};

const viewAction = {
    id: 'view',
    name: 'View',
};

const editAction = {
    id: 'edit',
    name: 'Edit',
};

const removeAction = {
    id: 'remove',
    name: 'Remove',
};

const itemActions = [viewAction, editAction];

export const PowerBrickContent = ({
    powerBricks,
    install,
    uninstall,
    setInstall,
    setUninstall,
    installationFile = null,
    setInstallOptions = () => {
        /* Do nothing if not set */
    },
    setUninstallOptions = () => {
        /* Do nothing if not set */
    },
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [flowTabId, setFlowTabId] = useState(TAB_ID.INSTALL);
    const {
        userProfile: { powerBrickFavourites = [] },
        updateUserProfile,
    } = useCoreContext();
    const [dragItem, setDragItem] = useState(null);
    const [editBrickDialogOpen, setEditBrickDialogOpen] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [selectedBrick, setSelectedBrick] = useState(null);
    const [installBricks, setInstallBricks] = useState(
        install?.map((v, i) => {
            v.priority = v.priority || i;
            return v;
        }) || []
    );
    const [uninstallBricks, setUninstallBricks] = useState(
        uninstall?.map((v, i) => {
            v.priority = v.priority || i;
            return v;
        }) || []
    );
    const [newFlow, setNewFlow] = useState([]);
    const [displayName, setDisplayName] = useState('');
    const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [gridLoading, setGridLoading] = useState(false);
    const [powerBricksFiltered, setPowerBricksFiltered] = useState([]);
    const [editing, setEditing] = useState(false);
    const [newBrickAdded, setNewBrickAdded] = useState(false);

    useEffect(() => {
        setPowerBricksFiltered(
            powerBricks
                .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                })
                .sort((a, b) => {
                    const aIsFavourite = powerBrickFavourites.includes(a.id);
                    const bIsFavourite = powerBrickFavourites.includes(b.id);

                    if (aIsFavourite && !bIsFavourite) {
                        return -1;
                    }
                    if (!aIsFavourite && bIsFavourite) {
                        return 1;
                    }
                    return 0;
                })
                .filter((item) =>
                    showOnlyFavourites
                        ? powerBrickFavourites.includes(item.id) &&
                          (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase()))
                        : item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase())
                )
        );
    }, [powerBrickFavourites, showOnlyFavourites, searchTerm, powerBricks]);

    useEffect(() => {
        if (selectedBrick) {
            if (editing) {
                if (
                    displayName !== selectedBrick?.displayName ||
                    !selectedBrick?.parameters.find((parameter) => (parameter.value === '' || parameter.value === null) && parameter.required)
                ) {
                    setChangesMade(true);
                } else {
                    setChangesMade(false);
                }
            } else {
                if (!selectedBrick?.parameters.find((parameter) => (parameter.value === '' || parameter.value === null) && parameter.required)) {
                    setChangesMade(true);
                } else {
                    setChangesMade(false);
                }
            }
        }
    }, [displayName]);

    const onFlowTabChange = (e, newTabId) => {
        setFlowTabId(newTabId);
    };

    const adjustFavourites = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (powerBrickFavourites.includes(id)) {
            updateUserProfile({
                powerBrickFavourites: powerBrickFavourites.filter((favourite) => favourite !== id),
            });
        } else {
            updateUserProfile({
                powerBrickFavourites: powerBrickFavourites.concat([id]),
            });
        }
    };

    const applyChanges = () => {
        const newBricks = newFlow.length === 0 ? (flowTabId === TAB_ID.INSTALL ? installBricks : uninstallBricks) : newFlow;
        let newFlowWithName = [];
        if (selectedBrick?.displayName !== displayName) {
            newFlowWithName = newBricks.map((brick) => {
                if (brick.id === selectedBrick.id) {
                    return { ...brick, displayName };
                }
                return brick;
            });
        }
        if (selectedBrick.id === 'Install-Exe') {
            setInstallOptions(selectedBrick.parameters.find((parameter) => parameter.id === 'ProcessArguments').value);
        }
        if (selectedBrick.id === 'Install-MSI') {
            setInstallOptions(selectedBrick.parameters.find((parameter) => parameter.id === 'msiArgs').value);
        }
        if (selectedBrick.id === 'Uninstall-EXE') {
            setUninstallOptions(selectedBrick.parameters.find((parameter) => parameter.id === 'ProcessArguments').value);
        }
        if (flowTabId === TAB_ID.INSTALL) {
            setInstallBricks(newFlowWithName.length !== 0 ? newFlowWithName : newBricks);
            setInstall(newFlowWithName.length !== 0 ? newFlowWithName : newBricks);
        } else {
            setUninstallBricks(newFlowWithName.length !== 0 ? newFlowWithName : newBricks);
            setUninstall(newFlowWithName.length !== 0 ? newFlowWithName : newBricks);
        }
        setNewBrickAdded(true);
        setEditBrickDialogOpen(false);
        setDisplayName('');
        setChangesMade(false);
        setEditing(false);
    };

    const cancelChanges = () => {
        if (!newBrickAdded) {
            if (!editing && !viewMode) {
                if (flowTabId === TAB_ID.INSTALL) {
                    setInstallBricks(installBricks.filter((item) => item.id !== selectedBrick.id));
                    setInstall(installBricks.filter((item) => item.id !== selectedBrick.id));
                } else {
                    setUninstallBricks(uninstallBricks.filter((item) => item.id !== selectedBrick.id));
                    setUninstall(uninstallBricks.filter((item) => item.id !== selectedBrick.id));
                }
            }
            setChangesMade(false);
            setSelectedBrick(null);
            setDisplayName('');
            setNewFlow([]);
            setEditBrickDialogOpen(false);
            setViewMode(false);
            setGridLoading(false);
            setEditing(false);
        }
        setNewBrickAdded(false);
    };

    const changeInstallBricks = (newBricks) => {
        setInstallBricks(newBricks);
        setInstall(newBricks);
    };

    const changeUninstallBricks = (newBricks) => {
        setUninstallBricks(newBricks);
        setUninstall(newBricks);
    };

    const openBrickDialog = (editMode, brick) => {
        setEditBrickDialogOpen(true);
        setTimeout(() => {
            if (!editMode && !brick?.parameters?.some((parameter) => parameter.value === '' && parameter.required)) {
                setChangesMade(true);
            }
        }, 50);
    };

    return (
        <>
            <div className="tw-grid tw-h-[45rem] tw-grid-cols-5 tw-overflow-hidden">
                <div className="tw-col-span-3 tw-grid tw-h-[45rem] tw-grid-flow-row tw-grid-rows-auto-1fr">
                    <div className="tw-grid tw-grid-cols-auto-1fr tw-items-center tw-overflow-auto">
                        <div className="tw-justify-self-end tw-px-4">
                            <LayoutRow>
                                <Input
                                    value={searchTerm}
                                    placeholder="Search"
                                    fullWidth
                                    callToAction
                                    className="tw-my-4 tw-w-40 lg:tw-w-60"
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    containerClassName="tw-py-1 tw-text-sm tw-h-10"
                                    autoFocus
                                />
                                <LayoutRow className={classNames('tw-items-center tw-p-2 tw-pb-4')}>
                                    <div className="tw-ml-2">
                                        <Tooltip
                                            arrow
                                            dark
                                        >
                                            <Icon
                                                className="tw-cursor-pointer tw-text-amber-500"
                                                type={showOnlyFavourites ? 'star' : 'starOutlined'}
                                                onClick={() => setShowOnlyFavourites(!showOnlyFavourites)}
                                            ></Icon>
                                        </Tooltip>
                                    </div>
                                    <b className="tw-ml-2">Favorites</b>
                                </LayoutRow>
                            </LayoutRow>
                        </div>
                    </div>
                    {powerBricks.filter((item) =>
                        showOnlyFavourites
                            ? powerBrickFavourites.includes(item.id) &&
                              (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase()))
                            : item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase())
                    ).length === 0 ? (
                        <LayoutCentered>
                            <EmptyState
                                entity="powerbrick"
                                isSearching={searchTerm !== ''}
                            />
                        </LayoutCentered>
                    ) : (
                        <List className="tw-grid tw-h-fit tw-max-h-full tw-w-fit tw-grid-cols-1 tw-gap-4 tw-overflow-auto tw-overflow-auto tw-pl-2  lg:tw-grid-cols-2 2xl:tw-grid-cols-3">
                            {powerBricksFiltered.map((item) => {
                                return (
                                    <DraggableListItem
                                        item={item}
                                        powerBrickFavourites={powerBrickFavourites}
                                        adjustFavourites={adjustFavourites}
                                        setDragItem={setDragItem}
                                        setSelectedBrick={setSelectedBrick}
                                        numberOfItems={powerBricksFiltered.length}
                                    ></DraggableListItem>
                                );
                            })}
                        </List>
                    )}
                </div>
                <div className="tw-col-span-2 tw-ml-2 tw-mr-4">
                    <Tabs
                        value={flowTabId}
                        onChange={onFlowTabChange}
                        onRails
                    >
                        <Tab
                            value={TAB_ID.INSTALL}
                            label="Install"
                        />
                        <Tab
                            value={TAB_ID.UNINSTALL}
                            label="Uninstall"
                        />
                    </Tabs>
                    {/* TODO: Create different flows for install and uninstall */}
                    <div
                        className="tw-mb-4 tw-h-5/6 tw-w-full tw-bg-white"
                        style={{ minHeight: '600px' }}
                    >
                        <div className="tw-h-5/6">
                            <LayoutRow className={classNames('tw-items-center tw-bg-blue-100 tw-p-2 tw-pb-4')}>
                                <b>PowerBricks Flow</b>
                                <div className="tw-ml-2">
                                    <Tooltip
                                        arrow
                                        dark
                                        content="The PowerBricks are executed in the order they are listed. You can easily rearrange the order by dragging a PowerBrick to the desired location."
                                    >
                                        <Icon
                                            type="infoOutlined"
                                            className="tw-text-xl tw-text-gray-400"
                                        />
                                    </Tooltip>
                                </div>
                            </LayoutRow>
                            <DroppableGridLayout
                                dragItem={dragItem}
                                items={flowTabId === TAB_ID.INSTALL ? installBricks : uninstallBricks}
                                setItems={flowTabId === TAB_ID.INSTALL ? changeInstallBricks : changeUninstallBricks}
                                openBrickDialog={openBrickDialog}
                                setSelectedBrick={setSelectedBrick}
                                setViewMode={setViewMode}
                                loading={gridLoading}
                                setLoading={setGridLoading}
                                setDisplayName={setDisplayName}
                                setEditing={setEditing}
                                installationFile={installationFile}
                            ></DroppableGridLayout>
                        </div>
                    </div>
                </div>
            </div>
            {selectedBrick !== null && (
                <Dialog
                    confirm
                    open={editBrickDialogOpen}
                    onClose={() => {
                        if (editing) {
                            cancelChanges();
                        }
                        setViewMode(false);
                        setGridLoading(false);
                    }}
                    onExited={() => {
                        if (editing) {
                            cancelChanges();
                        }
                        setViewMode(false);
                        setGridLoading(false);
                    }}
                >
                    <DialogContent className="tw-p-0">
                        <ConfigureWorkflowItemInDialog
                            key={selectedBrick.data ? selectedBrick?.data?.id : selectedBrick.id}
                            id={selectedBrick.data ? selectedBrick?.data?.id : selectedBrick.id}
                            name={selectedBrick.data ? selectedBrick?.data?.name : selectedBrick.name}
                            description={selectedBrick.data ? selectedBrick?.data?.description : selectedBrick.description}
                            configuration={selectedBrick.data ? selectedBrick?.data?.configuration : selectedBrick.configuration}
                            category={selectedBrick.data ? selectedBrick?.data?.category : selectedBrick.category}
                            type={selectedBrick.data ? selectedBrick?.data?.type : selectedBrick.type}
                            workflowItems={powerBricks}
                            parameters={selectedBrick.data ? selectedBrick?.data?.parameters : selectedBrick.parameters}
                            changeDisplayName={setDisplayName}
                            displayName={selectedBrick.data ? selectedBrick?.data?.displayName : selectedBrick.displayName}
                            viewMode={viewMode}
                            setChangesMade={setChangesMade}
                            editing={editing}
                            onDataChange={(newData) => {
                                const initialBrick = selectedBrick;
                                const newBricks = (flowTabId === TAB_ID.INSTALL ? installBricks : uninstallBricks)?.map((currentBrick) => {
                                    if (currentBrick.id === selectedBrick.id) {
                                        return {
                                            ...currentBrick,
                                            parameters: currentBrick.parameters.map((parameter) => {
                                                if (parameter.id === newData.id) {
                                                    return newData;
                                                }
                                                return parameter;
                                            }),
                                        };
                                    }
                                    return currentBrick;
                                });
                                const changedBrick = {
                                    ...selectedBrick,
                                    parameters: selectedBrick.parameters.map((parameter) => {
                                        if (parameter.id === newData.id) {
                                            return newData;
                                        }
                                        return parameter;
                                    }),
                                };
                                setSelectedBrick(changedBrick);
                                setNewFlow(newBricks.map((brick) => (brick.id === changedBrick.id ? changedBrick : brick)));
                                if (
                                    isEqual(initialBrick, changedBrick) ||
                                    changedBrick.parameters.find((parameter) => (parameter.value === null || parameter.value === '') && parameter.required)
                                ) {
                                    setChangesMade(false);
                                } else {
                                    setChangesMade(true);
                                }
                            }}
                        />
                    </DialogContent>
                    {viewMode ? (
                        <div className="tw-flex tw-w-full">
                            <div className="tw-ml-auto">
                                <Button
                                    className="tw-m-4"
                                    onClick={() => {
                                        setEditBrickDialogOpen(false);
                                        setGridLoading(false);
                                        setViewMode(false);
                                    }}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <LayoutRow align="space-between">
                            <Button
                                className="tw-m-4"
                                variant={BUTTON.RAISED}
                                color={BUTTON.PRIMARY}
                                onClick={() => applyChanges()}
                                disabled={!changesMade}
                            >
                                Apply changes
                            </Button>
                            <Button
                                className="tw-m-4"
                                onClick={() => cancelChanges()}
                            >
                                Cancel
                            </Button>
                        </LayoutRow>
                    )}
                </Dialog>
            )}
        </>
    );
};

const ItemTypes = {
    LIST_ITEM: 'listItem',
};

const priorityIdPrefix = 'priority-id-';

const DroppableGridLayout = ({
    dragItem,
    openBrickDialog,
    setSelectedBrick,
    items,
    setItems,
    setViewMode,
    loading,
    setLoading,
    setDisplayName,
    setEditing,
    installationFile,
}) => {
    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        item: {},
        actions: [],
    });

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const [{ isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.LIST_ITEM,
        drop: (item, monitor) => {
            const offset = monitor.getClientOffset();
            const newItem = {
                ...item,
                priority: items.length + 1,
            };
            setItems((prevLayout) => [...prevLayout, newItem]);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const onDrop = (layout, layoutItem) => {
        const newItem = {
            ...dragItem,
            id: dragItem.id + `_${now()}`,
            priority: layoutItem.y + 1,
        };
        const newItems = items.map((item) => {
            if (item.priority > layoutItem.y) {
                return { ...item, priority: item.priority + 1 };
            }
            return item;
        });
        newItems.push(newItem);
        setItems(newItems);
        setSelectedBrick(newItem);
        setLoading(true);
        setDisplayName(newItem.displayName || '');
        openBrickDialog(false, newItem);
    };
    const onLayoutChange = (layout) => {
        setItems(
            items.map((item, index) => {
                return {
                    ...item,
                    priority: layout[index].y + 1,
                };
            })
        );
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case removeAction.id:
                setItems(items.filter((i) => i.id !== menuState.item.id));
                break;
            case editAction.id:
                setEditing(true);
                openBrickDialog(true, menuState.item);
                setDisplayName(menuState.item.displayName || '');
                setLoading(true);
                setSelectedBrick(menuState.item);
                break;
            case viewAction.id:
                setViewMode(true);
                openBrickDialog(true, menuState.item);
                setSelectedBrick(menuState.item);
                break;
        }
    };

    return (
        <div
            ref={drop}
            className="tw-h-full tw-overflow-auto tw-overflow-x-hidden tw-rounded-b-lg tw-border tw-border-gray-300"
        >
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Powerbrick"
                title={menuState.item.displayName || menuState.item.name || ''}
                pages={menuState.pages}
                actions={[
                    ...itemActions,
                    ...menuState.actions,
                    {
                        ...removeAction,
                        disabled:
                            installationFile !== null &&
                            (menuState.item.id === 'PowerPack-Download' ||
                                menuState.item.id === 'Install-Exe' ||
                                menuState.item.id === 'Install-MSI' ||
                                menuState.item.id === 'Uninstall-EXE' ||
                                menuState.item.id === 'MSI-Remove-By-Name'),
                    },
                ]}
                onActionClick={onActionClick}
            />
            {loading ? (
                <LayoutCentered>
                    <Loading></Loading>
                </LayoutCentered>
            ) : (
                <GridLayout
                    cols={1}
                    autoSize
                    isResizable={false}
                    rowHeight={48}
                    margin={[0, 0]}
                    containerPadding={[0, 0]}
                    layout={items}
                    isDroppable={true}
                    onDrop={onDrop}
                    className="tw-h-full"
                    onLayoutChange={onLayoutChange}
                    measureBeforeMount={true}
                >
                    {items.map((item, index) => {
                        return (
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                key={item.id}
                                data-grid={{
                                    x: 0,
                                    y: item.priority,
                                    i: item.id,
                                    w: 1,
                                    h: 1,
                                }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    className="tw-grid tw-h-full tw-cursor-grab tw-grid-cols-auto-1fr-auto  tw-items-center tw-gap-2 tw-px-2 hover:tw-bg-slate-50"
                                >
                                    <IconButton
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setMenuState({
                                                open: true,
                                                anchorEl: e.currentTarget,
                                                item,
                                                actions: [],
                                            });
                                        }}
                                        noMargin
                                    >
                                        <Icon type="moreVert" />
                                    </IconButton>
                                    <LayoutRow className="tw-h-full tw-items-center">
                                        {item.visuals && (
                                            <div className="tw-pr-2">
                                                <Icon
                                                    type={item.visuals.icon.type}
                                                    className={item.visuals.icon.className}
                                                ></Icon>
                                            </div>
                                        )}
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            id={priorityIdPrefix + item.id}
                                        >
                                            <TwoLineCellRenderer
                                                main={item.displayName || item.name}
                                                secondary={item.displayName ? `${item.name} • ${item.version}` : item.version}
                                            />
                                        </div>
                                    </LayoutRow>
                                </div>
                                <Divider />
                            </div>
                        );
                    })}
                </GridLayout>
            )}
        </div>
    );
};

const DraggableListItem = ({ item, powerBrickFavourites, adjustFavourites, setDragItem, setSelectedBrick, numberOfItems }) => {
    const [itemsPerRow, setItemsPerRow] = useState(3);

    useEffect(() => {
        const updateItemsPerRow = () => {
            if (window.matchMedia('(min-width: 1536px)').matches) {
                setItemsPerRow(3); // 3 items per row for 2xl and above
            } else if (window.matchMedia('(min-width: 1024px)').matches) {
                setItemsPerRow(2); // 2 items per row for lg and above
            } else {
                setItemsPerRow(1); // 1 item per row for anything smaller
            }
        };

        // Initial calculation
        updateItemsPerRow();

        // Add event listener to handle window resizing
        window.addEventListener('resize', updateItemsPerRow);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateItemsPerRow);
        };
    }, []);

    return (
        <React.Fragment key={item.name}>
            <div
                //Width set specifically to under 300px to prevent item from receiving default browser drag styling
                className={classNames(`tw-h-fit tw-min-h-[102px] tw-w-64 tw-cursor-grab tw-rounded tw-border-[1px] tw-shadow-sm active:tw-bg-green-200`, {
                    'tw-mb-8 ': numberOfItems <= itemsPerRow,
                })}
                onDragStart={(e) => {
                    e.dataTransfer.setData('text/plain', '');
                    setDragItem(item);
                    setSelectedBrick(item);
                }}
                onDragEnd={() => {
                    setDragItem(null);
                }}
                draggable={true}
            >
                <LayoutRow className="tw-h-[100px] tw-py-2 tw-pl-2 tw-pr-0">
                    <LayoutRow className="tw-items-center">
                        <Icon
                            style={{
                                minWidth: 32,
                            }}
                            type={item.visuals.icon.type}
                            className={item.visuals.icon.className}
                        ></Icon>
                        <p className="tw-w-32 tw-pl-2">{item.name}</p>
                    </LayoutRow>
                    <div className="tw-ml-auto tw-pr-2">
                        <Tooltip
                            PopperProps={{ disablePortal: true }}
                            dark
                            content={powerBrickFavourites.includes(item.id) ? 'Remove from favourites' : 'Add to favourites'}
                        >
                            <Icon
                                className="tw-text-amber-500"
                                type={powerBrickFavourites.includes(item.id) ? 'star' : 'starOutlined'}
                                onClick={adjustFavourites(item.id)}
                            ></Icon>
                        </Tooltip>
                        <Tooltip
                            PopperProps={{ disablePortal: true }}
                            dark
                            content={
                                <span>
                                    {item.description}
                                    <br />
                                    <br />
                                    Version: {item.version}
                                </span>
                            }
                        >
                            <Icon
                                type="infoOutlined"
                                className="tw-text-xl tw-text-gray-400 "
                            ></Icon>
                        </Tooltip>
                    </div>
                </LayoutRow>
            </div>
        </React.Fragment>
    );
};

const ConfigureWorkflowItemInDialog = ({
    configuration,
    type,
    category,
    onDataChange,
    name,
    description,
    workflowItems,
    id,
    parameters,
    displayName,
    changeDisplayName,
    viewMode,
    setChangesMade,
    editing,
}) => {
    if (!parameters.find((parameter) => parameter.required) && !editing) {
        setChangesMade(true);
    }
    if (type === 'powerbrick') {
        return (
            <>
                <Padding className="tw-bg-blue-100">
                    <LayoutRow
                        align="space-between"
                        verticalAlign="center"
                    >
                        <h2 className="tw-m-0">{name}</h2>
                    </LayoutRow>
                    <p className="tw-mb-4 tw-mt-1 tw-text-xs">{description}</p>
                </Padding>
                <Padding>
                    <LayoutColumn>
                        <b>PowerBrick Display Name (optional)</b>
                        <p className="tw-text-xs">The display name can help you recognize the PowerBrick</p>
                        <Input
                            className="tw-pb-4"
                            key={name}
                            value={displayName}
                            disabled={viewMode}
                            onChange={(e) => changeDisplayName(e.target.value)}
                        ></Input>
                    </LayoutColumn>
                    {parameters.map((parameter) => {
                        if (parameter.value === null) {
                            if (parameter.type === 'ComboBox') {
                                parameter.value = parameter.defaultValueObject || parameter.defaultValue;
                            } else {
                                parameter.value = parameter.defaultValue;
                            }
                        }
                        if (parameter.type === 'TextBox') {
                            return (
                                <LayoutColumn>
                                    <b>{parameter.name}</b>
                                    {parameter.description.split('\\n').map((splitDescription) => {
                                        return <p className="tw-text-xs">{splitDescription}</p>;
                                    })}
                                    <AutoComplete
                                        className="tw-pb-4"
                                        key={parameter.name}
                                        required={parameter.required}
                                        searchTerm={parameter.value}
                                        value={parameter.value}
                                        disabled={viewMode}
                                        error={parameter.required && parameter.value === ''}
                                        helperText={parameter.required && parameter.value === '' ? `${parameter.name} is required` : ''}
                                        suggestions={parameter.values
                                            .map((value) => {
                                                return { id: value, name: value };
                                            })
                                            .filter((value) => value.name.includes(parameter.value))}
                                        onSelect={(e) =>
                                            onDataChange({
                                                ...parameter,
                                                value: e.name,
                                            })
                                        }
                                        onSearch={(e) => {
                                            onDataChange({
                                                ...parameter,
                                                value: e,
                                            });
                                        }}
                                        autoComplete="off"
                                        type="text"
                                    />
                                </LayoutColumn>
                            );
                        }
                        if (parameter.type === 'Boolean') {
                            parameter.value = parameter.value === 'True' ? true : parameter.value === 'False' ? false : parameter.value;
                            return (
                                <LayoutColumn className="tw-mb-2">
                                    <b>{parameter.name}</b>
                                    {parameter.description.split('\\n').map((splitDescription) => {
                                        return <p className="tw-text-xs">{splitDescription}</p>;
                                    })}
                                    <Switch
                                        key={parameter.name}
                                        checked={parameter.value}
                                        disabled={viewMode}
                                        onChange={(e) => {
                                            onDataChange({
                                                ...parameter,
                                                value: e.target.checked,
                                            });
                                        }}
                                    ></Switch>
                                </LayoutColumn>
                            );
                        }
                        if (parameter.type === 'Integer') {
                            return (
                                <LayoutColumn>
                                    <b>{parameter.name}</b>
                                    {parameter.description.split('\\n').map((splitDescription) => {
                                        return <p className="tw-text-xs">{splitDescription}</p>;
                                    })}
                                    <Input
                                        className="tw-pb-4"
                                        key={parameter.name}
                                        value={parameter.value}
                                        type="number"
                                        required={parameter.required}
                                        disabled={viewMode}
                                        error={parameter.required && parameter.value === ''}
                                        helperText={parameter.required && parameter.value === '' ? `${parameter.name} is required` : ''}
                                        onChange={(e) =>
                                            onDataChange({
                                                ...parameter,
                                                value: parseInt(e.target.value),
                                            })
                                        }
                                    ></Input>
                                </LayoutColumn>
                            );
                        }
                        if (parameter.type === 'ComboBox') {
                            return (
                                <LayoutColumn className="tw-mb-4">
                                    <b>{parameter.name}</b>
                                    {parameter.description.split('\\n').map((splitDescription) => {
                                        return <p className="tw-text-xs">{splitDescription}</p>;
                                    })}
                                    <Select
                                        key={parameter.name}
                                        required={parameter.required}
                                        disabled={viewMode}
                                        options={
                                            parameter.valuesObject ||
                                            parameter.values.map((value) => {
                                                return { id: value, name: value };
                                            })
                                        }
                                        selectedOptions={
                                            typeof parameter.value === 'object' ? [parameter.value] : [{ id: parameter.value, name: parameter.value }]
                                        }
                                        onChange={(e) =>
                                            onDataChange({
                                                ...parameter,
                                                value: e[0] || '',
                                            })
                                        }
                                    ></Select>
                                </LayoutColumn>
                            );
                        }
                        if (parameter.type === 'Powershell') {
                            return (
                                <>
                                    <p
                                        key={parameter.name + '-label'}
                                        className="tw-pb-1"
                                    >
                                        Custom powershell
                                    </p>
                                    <Editor
                                        height="30vh"
                                        language="powershell"
                                        theme="vs-dark" // You can change the theme
                                        value={parameter.value}
                                        key={parameter.name}
                                        disabled={viewMode}
                                        onChange={(e) =>
                                            onDataChange({
                                                ...parameter,
                                                value: e,
                                            })
                                        }
                                    />
                                </>
                            );
                        }
                        return null;
                    })}
                </Padding>
            </>
        );
    }
};

export const CreateNewCategory = (brick) => {
    return {
        label: brick.category,
        type: brick.category,
        icon: {
            type: 'questionMark',
            className: 'tw-text-sky-600',
        },
    };
};
