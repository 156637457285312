import { inRange, isDefined } from '@capasystems/utils';

export const patchIsInProgress = (patch: any) => {
    return inRange(patch.status.code, 0, 200);
};

export const patchIsInstalled = (patch: any) => {
    return inRange(patch.status.code, 200, 300) && !patchIsInstalledButRestartRequired(patch);
};

export const patchIsInstalledButRestartRequired = (patch: any) => {
    return patch.status.code === 202 || patch.status.code === 207;
};

/**
 * It is not possible to Reinstall or Uninstall software that was patched outside of CapaOne.
 * */
export const patchIsInstalledManually = (patch: any) => {
    return patch.status.code === 204;
};

export const patchIsAvailable = (patch: any) => {
    return inRange(patch.status.code, 300, 400);
};

export const patchIsNotAvailable = (patch: any) => {
    return inRange(patch.status.code, 400, 500);
};

export const patchInstallOrUninstallFailed = (patch: any) => {
    return inRange(patch.status.code, 500, 700);
};

export const patchUninstallFailed = (patch: any) => {
    return patch.status.code === 505;
};

export const patchInstallFailed = (patch: any) => {
    return patchInstallOrUninstallFailed(patch) && !patchUninstallFailed(patch);
};

export const patchHasLogFiles = (patch: any) => {
    return patchIsInstalled(patch) || patchInstallOrUninstallFailed(patch) || patchIsInstalledButRestartRequired(patch);
};

export const patchInProgressCanBeCancelled = (patch: any) => {
    return patch.status.code === 1 || patch.status.code === 100;
};

export const getDerivedDriverStatus = (driverPatchStatusObject: any = {}) => {
    const derivedDriverStatus = {
        updateInProgress: false,
        restartRequired: false,
        outranked: false,
    };
    if (isDefined(driverPatchStatusObject?.code)) {
        derivedDriverStatus.restartRequired = driverPatchStatusObject.code === 207;
        derivedDriverStatus.updateInProgress = derivedDriverStatus.restartRequired || inRange(driverPatchStatusObject.code, 0, 200);
        derivedDriverStatus.outranked = driverPatchStatusObject.code === 205;
    }
    return derivedDriverStatus;
};
