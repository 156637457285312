import { Icon, LayoutCentered } from '@capasystems/ui';
import React from 'react';

export type DrawerStepRowProps = {
    badgeContent?: string | number;
    requirements?: boolean;
    children: React.ReactNode;
};

export const DrawerStepRow: React.FC<DrawerStepRowProps> = ({ badgeContent, requirements = false, children }) => {
    return (
        <div className="tw-mt-6 tw-grid tw-grid-cols-auto-1fr tw-gap-4">
            <div>
                <div className="tw-h-6 tw-w-6 tw-rounded-full tw-bg-slate-50 tw-text-tiny tw-text-slate-500">
                    <LayoutCentered>
                        {requirements ? (
                            <Icon
                                type="checkmark"
                                color="inherit"
                                size="small"
                                className="tw-text-xs"
                            />
                        ) : (
                            <b>{badgeContent}</b>
                        )}
                    </LayoutCentered>
                </div>
            </div>
            <div className="tw-w-full tw-font-semibold">{children}</div>
        </div>
    );
};
