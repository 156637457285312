export const ADMIN_ON_DEMAND = {
    APPLICATION: {
        INSTALL: {
            ID: 'Install',
            NAME: 'Installations',
            UNIT: 'Installation',
            DESCRIPTION: 'Number of installed applications',
            TITLE: 'Installed Applications',
            COLOR_REPRESENTATION: 'teal',
        },
        UNINSTALL: {
            ID: 'Uninstall',
            NAME: 'Uninstalls',
            UNIT: 'Uninstall',
            DESCRIPTION: 'Number of removed applications',
            TITLE: 'Removed Applications',
            COLOR_REPRESENTATION: 'red',
        },
        ELEVATION: {
            ID: 'Elevation',
            NAME: 'Times Elevated',
            UNIT: 'Elevation',
            DESCRIPTION: 'Number of elevated applications',
            TITLE: 'Elevated Applications',
            COLOR_REPRESENTATION: 'blue',
        },
    },
    USER: {
        ELEVATION: {
            ID: 'UserElevation',
            NAME: 'Times Elevated',
            UNIT: 'Elevation',
            DESCRIPTION: 'Number of elevated user accounts',
            TITLE: 'Elevated User Accounts',
            COLOR_REPRESENTATION: 'sky',
        },
        COMBINED: {
            ID: 'UserElevationCombined',
            NAME: 'Times Elevated',
            UNIT: 'Elevation',
            DESCRIPTION: 'Number of elevated user accounts',
            TITLE: 'Elevated User Accounts',
            COLOR_REPRESENTATION: 'sky',
        },
    },
    SESSION: {
        ELEVATION: {
            ID: 'SessionElevation',
            NAME: 'Times Elevated',
            UNIT: 'Elevation',
            DESCRIPTION: 'Number of elevated user accounts',
            TITLE: 'Elevated User Accounts',
            COLOR_REPRESENTATION: 'sky',
        },
    },
};
