/* istanbul ignore file */
import MuiLink, { LinkProps as MUILinkProps } from '@mui/material/Link';
import classnames from 'classnames';
import React from 'react';

export type LinkProps = MUILinkProps & {
    className?: string;
    underline?: 'none' | 'hover' | 'always';
    component?: React.ElementType;
};

/** Link component */
export const Link = React.forwardRef<any, LinkProps>(({ className = '', ...otherProps }, ref) => (
    <MuiLink
        className={classnames(className, 'cs-link')}
        ref={ref}
        underline="none"
        component="button"
        {...otherProps}
    />
));

export default Link;
