export const DEVICE_STATE = {
    READY: 'READY',
    BUSY: 'BUSY',
};

export const DEVICE_TYPE = {
    DESKTOP: 'Desktop',
    LAPTOP: 'Laptop',
    VIRTUAL: 'Virtual',
    SERVER: 'Server',
};
