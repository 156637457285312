//Documentation: Cellular
//https://developer.apple.com/documentation/devicemanagement/cellular
//https://support.apple.com/en-eg/guide/deployment/depa34b7357/1/web/1.0
export const AppleCellularConfiguration = {
    schema: {
        schemaId: 'appleCellularConfiguration',
        category: 'Networking',
        title: 'Cellular',
        skipIndent: true,
        type: 'object',
        properties: {
            'com.apple.cellular': {
                title: '',
                type: 'object',
                properties: {
                    APNs: {
                        title: 'APNs',
                        type: 'array',
                        description: 'Manage the default APN, the data APN, or both.',
                        items: {
                            type: 'object',
                            properties: {
                                Name: {
                                    title: 'Name',
                                    type: 'string',
                                    description: 'The name for this configuration.',
                                    required: true,
                                },
                                Username: {
                                    title: 'Username',
                                    type: 'string',
                                    description: 'The user name for the APN.',
                                },
                                Password: {
                                    title: 'Password',
                                    type: 'string',
                                    description: "The user's password for the APN.",
                                    isPassword: true,
                                },
                                AuthenticationType: {
                                    title: 'Authentication Type',
                                    type: 'string',
                                    description: 'The authentication type for logging in.',
                                    options: [
                                        {
                                            id: 'CHAP',
                                            name: 'CHAP',
                                        },
                                        {
                                            id: 'PAP',
                                            name: 'PAP',
                                        },
                                    ],
                                },
                                ProxyPort: {
                                    title: 'Proxy Port',
                                    type: 'number',
                                    description: "The proxy server's port number.",
                                },
                                ProxyServer: {
                                    title: 'Proxy Server',
                                    type: 'string',
                                    description: "The proxy server's address.",
                                },
                                AllowedProtocolMask: {
                                    title: 'Allowed Protocol Mask',
                                    type: 'string',
                                    description: 'The supported Internet Protocol versions.',
                                    options: [
                                        {
                                            id: 1,
                                            name: 'IPv4',
                                        },
                                        {
                                            id: 2,
                                            name: ' IPv6',
                                        },
                                        {
                                            id: 3,
                                            name: 'Both',
                                        },
                                    ],
                                },
                                AllowedProtocolMaskInRoaming: {
                                    title: 'Allowed Protocol Mask In Roaming',
                                    type: 'string',
                                    description: 'The supported Internet Protocol versions while roaming.',
                                    options: [
                                        {
                                            id: 1,
                                            name: 'IPv4',
                                        },
                                        {
                                            id: 2,
                                            name: 'IPv6',
                                        },
                                        {
                                            id: 3,
                                            name: 'Both',
                                        },
                                    ],
                                },
                                AllowedProtocolMaskInDomesticRoaming: {
                                    title: 'Allowed Protocol Mask In Domestic Roaming',
                                    type: 'string',
                                    description: 'The supported Internet Protocol versions while roaming domestically.',
                                    options: [
                                        {
                                            id: 1,
                                            name: 'IPv4',
                                        },
                                        {
                                            id: 2,
                                            name: 'IPv6',
                                        },
                                        {
                                            id: 3,
                                            name: 'Both',
                                        },
                                    ],
                                },
                                EnableXLAT464: {
                                    title: 'Enable XLAT464',
                                    type: 'boolean',
                                    description: 'Enables XLAT464.',
                                },
                            },
                        },
                    },
                    AttachAPN: {
                        title: 'Attach APN',
                        type: 'object',
                        description: 'The Internet Protocol versions that the system supports.',
                        properties: {
                            Name: {
                                title: 'Name',
                                description: 'The name for this configuration.',
                                type: 'string',
                                required: true,
                            },
                            Username: {
                                title: 'Username',
                                description: 'The user name for the APN.',
                                type: 'string',
                            },
                            Password: {
                                title: 'Password',
                                description: "The user's password for the APN.",
                                type: 'string',
                                isPassword: true,
                            },
                            AuthenticationType: {
                                title: 'Authentication Type',
                                description: 'The authentication type for logging in.',
                                type: 'string',
                                options: [
                                    {
                                        id: 'CHAP',
                                        name: 'CHAP',
                                    },
                                    {
                                        id: 'PAP',
                                        name: 'PAP',
                                    },
                                ],
                            },
                            AllowedProtocolMask: {
                                title: 'Allowed Protocol Mask',
                                description: 'The supported Internet Protocol versions.',
                                type: 'string',
                                options: [
                                    {
                                        id: 1,
                                        name: 'IPv4',
                                    },
                                    {
                                        id: 2,
                                        name: ' IPv6',
                                    },
                                    {
                                        id: 3,
                                        name: 'Both',
                                    },
                                ],
                            },
                        },
                    },
                },
            },
        },
    },
};
