const capaPacksSvg = 'assets/img/CapaPacks.svg';
const capaImagesSvg = 'assets/img/CapaImages.svg';
const capaInstallerSvg = 'assets/img/CapaInstaller.svg';
const performanceGuardSvg = 'assets/img/PerformanceGuard.svg';
const serviceDeskSvg = 'assets/img/ServiceDesk.svg';
const capaForumSVG = 'assets/img/CapaForum.svg';
const capaOneLogoSvg = 'assert/img/capaOneLogo.svg';
const knowledgeBaseSVG = 'assets/img/knowledgeBase.svg';
const capaDriversSVG = 'assets/img/capaDrivers.svg';
const mobileDeviceManagementSVG = 'assets/img/mobileDeviceManagement.svg';
const zeroTouchSVG = 'assets/img/zeroTouch.svg';
const office365SVG = 'assets/img/office365.svg';
const techBlogSVG = 'assets/img/techBlog.svg';
const portalSVG = 'assets/img/link.svg';
const capaReliability = 'assets/img/CapaOneReliability.svg';
const capaAdminOnDemand = 'assets/img/CapaOneLogoWhite.svg';

export type CapaOneIcons = {
    [key: string]: string;
};

export const capaOneIcons: CapaOneIcons = {
    service: serviceDeskSvg,
    capaPacks: capaPacksSvg,
    capaInstaller: capaInstallerSvg,
    performanceGuard: performanceGuardSvg,
    capaImage: capaImagesSvg,
    capaForum: capaForumSVG,
    capaOneLogo: capaOneLogoSvg,
    knowledgeBase: knowledgeBaseSVG,
    capaDrivers: capaDriversSVG,
    mobileDeviceManagement: mobileDeviceManagementSVG,
    zeroTouch: zeroTouchSVG,
    office365: office365SVG,
    techBlog: techBlogSVG,
    portal: portalSVG,
    capaReliability: capaReliability,
    capaAdminOnDemand: capaAdminOnDemand,
};

export default capaOneIcons;
