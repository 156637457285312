import { Button, Column, Dialog, DialogActions, DialogTitle, Ellipsis, Highcharts, Link, VirtualizedTable } from '@capasystems/ui';
import { inRange } from '@capasystems/utils';
import { LOCAL_STORAGE_ID } from '@thirdparty/constants';
import React, { useContext, useEffect, useRef, useState } from 'react';
import colors from 'tailwindcss/colors';
import { chartDefaults } from '../constants-with-dependencies/constants-with-dependencies';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { PieChart, RELIABILITY, ReliabilityContext, eSize, useApi, useNavigate } from './../../index';

const PAGE_SIZE = 50;

const ReliabilityLatestScoreWidget = ({ activeFilters }) => {
    const { lockedPeriod, timezone } = useContext(ReliabilityContext);
    const { appendBaseURL } = useNavigate();
    const [piePeriod, setPiePeriod] = useState({ start: 0, end: 0 });
    const chartDataRef = useRef({
        fairCount: 0,
        goodCount: 0,
        poorCount: 0,
        unacceptableCount: 0,
    });
    const [widgetPaperProps, setWidgetPaperProps] = useState({ loading: true });
    const [pieDialogOpen, setPieDialogOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [rowToScrollTo, setRowToScrollTo] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const selectedReliabilityScore = useRef(RELIABILITY.SCORE.GOOD);
    const api = useApi();

    const closePieDialog = () => {
        setPieDialogOpen(false);
        setRowToScrollTo(0);
        setPageNumber(1);
    };

    const openPieDialog = (point) => {
        setTableData([]);
        setTableLoading(true);
        selectedReliabilityScore.current = Object.values(RELIABILITY.SCORE).find((score) => score.NAME === point.name);
        if (selectedReliabilityScore.current) {
            selectedReliabilityScore.current.style = {
                background: selectedReliabilityScore.current.COLOR[100],
            };
            const tempPeriod = piePeriod.start + '|' + piePeriod.end;
            api.getEventOverviewReliabilityDeviceList(
                tempPeriod,
                timezone,
                'hour',
                selectedReliabilityScore.current.RANGE.FROM,
                selectedReliabilityScore.current.RANGE.TO,
                pageNumber,
                PAGE_SIZE
            )
                .then((deviceData) => {
                    setTableData(deviceData.data);
                    setRowCount(deviceData.rowCount);
                })
                .catch(closePieDialog)
                .finally(() => {
                    setTableLoading(false);
                });
            setPieDialogOpen(true);
        }
    };

    const prepareScrollTableData = (data) => {
        const combinedTableData = tableData.concat(data.data);
        setRowToScrollTo(50 * pageNumber);
        setRowCount(data.rowCount);
        setTableData(combinedTableData);
        setPageNumber(pageNumber + 1);
    };

    const scrolledToBottomHandler = () => {
        setTableLoading(true);
        const tempPeriod = piePeriod.start + '|' + piePeriod.end;
        api.getEventOverviewReliabilityDeviceList(
            tempPeriod,
            timezone,
            'hour',
            selectedReliabilityScore.current.RANGE.FROM,
            selectedReliabilityScore.current.RANGE.TO,
            pageNumber + 1,
            PAGE_SIZE
        )
            .then(prepareScrollTableData)
            .catch(console.log)
            .finally(() => {
                setTableLoading(false);
            });
    };

    const navigateTo = (deviceId) => () => {
        window.open(appendBaseURL(`windows/device/${deviceId}/reliability?${LOCAL_STORAGE_ID.SHOW_ACTIVITY_LOG}=false`));
    };

    useEffect(() => {
        setWidgetPaperProps({
            loading: true,
        });
        api.getEventOverviewReliabilityPieChart(timezone)
            .then((response) => {
                response.data.forEach((slice) => {
                    chartDataRef.current[slice.name] = slice.y;
                });
                setPiePeriod(response.period);
                setWidgetPaperProps({});
            })
            .catch((error) => {
                setWidgetPaperProps({
                    errorMessage: 'Could not load Reliability Score for the latest hour.',
                });
                console.log(error);
            });
    }, [lockedPeriod, activeFilters, api]);

    return (
        <WidgetPaper
            className="tw-h-80 tw-min-h-full"
            title="Reliability Score for latest hour"
            description="Current stability of your Windows endpoints"
            {...widgetPaperProps}
        >
            <PieChart
                data={[
                    {
                        name: RELIABILITY.SCORE.UNACCEPTABLE.NAME,
                        color: RELIABILITY.SCORE.UNACCEPTABLE.COLOR[400],
                        y: chartDataRef.current.unacceptableCount,
                    },
                    {
                        name: RELIABILITY.SCORE.POOR.NAME,
                        color: RELIABILITY.SCORE.POOR.COLOR[400],
                        y: chartDataRef.current.poorCount,
                    },
                    {
                        name: RELIABILITY.SCORE.FAIR.NAME,
                        color: RELIABILITY.SCORE.FAIR.COLOR[400],
                        y: chartDataRef.current.fairCount,
                    },
                    {
                        name: RELIABILITY.SCORE.GOOD.NAME,
                        y: chartDataRef.current.goodCount,
                        color: RELIABILITY.SCORE.GOOD.COLOR[400],
                    },
                ]}
                size={eSize.medium}
                onClick={(e) => openPieDialog(e)}
            />
            <Dialog
                open={pieDialogOpen}
                onClose={closePieDialog}
            >
                <DialogTitle
                    className="tw-pl-4"
                    style={selectedReliabilityScore.current.style}
                >
                    {selectedReliabilityScore.current.NAME} reliability score
                </DialogTitle>
                <div style={{ height: 500 }}>
                    <VirtualizedTable
                        items={tableData}
                        sortDirection="DESC"
                        totalRowCount={rowCount}
                        showRowCount
                        scrollToIndex={rowToScrollTo}
                        onScrollToBottom={scrolledToBottomHandler}
                        isLoading={tableLoading}
                        entity="endpoint"
                    >
                        <Column
                            minWidth={240}
                            dataKey="computerName"
                            label="Endpoint name"
                            type="string"
                            cellRenderer={({ rowData: { computerName, mongoId: deviceId } }) => (
                                <Link onClick={navigateTo(deviceId)}>
                                    <Ellipsis>{computerName}</Ellipsis>
                                </Link>
                            )}
                        />
                        <Column
                            minWidth={80}
                            maxWidth={80}
                            dataKey="latestScore"
                            label="Score"
                            type="number"
                        />
                    </VirtualizedTable>
                </div>
                <DialogActions>
                    <Button
                        onClick={closePieDialog}
                        noMargin
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </WidgetPaper>
    );
};

const ReliabilityLatestScoreForDeviceWidget = (props) => {
    const api = useApi();
    const { deviceId, timezone, sampleInterval, lockedPeriod } = useContext(ReliabilityContext);
    const [chartData, setChartData] = useState({
        deviceScore: 0,
        innerText: `<span style="font-size: 0.5em;">Unknown</span>`,
        scoreColorRepresentation: colors.neutral[400],
        period: { start: 0, end: 0 },
    });
    const [widgetPaperProps, setWidgetPaperProps] = useState({ loading: true });

    useEffect(() => {
        api.getEventReliabilityScoreDevice(deviceId, timezone)
            .then((response) => {
                if (response) {
                    const deviceScore = response.data[0]?.y || 0;
                    const foundReliabilityScore = Object.values(RELIABILITY.SCORE).find(({ RANGE }) => inRange(deviceScore, RANGE.FROM, RANGE.TO));
                    if (deviceScore > 0 && foundReliabilityScore) {
                        setChartData({
                            deviceScore,
                            innerText: `${deviceScore}<br/><span style="visibility: hidden; font-size: 4px;">.</span><br/><span style="font-size: 0.45em; font-weight: 600;">${foundReliabilityScore.NAME}</span>`,
                            scoreColorRepresentation: foundReliabilityScore.COLOR[400],
                            period: {
                                start: response.period.start,
                                end: response.period.end,
                            },
                        });
                    }
                }
                setWidgetPaperProps({});
            })
            .catch((error) => {
                setWidgetPaperProps({
                    errorMessage: 'Could not load Latest Reliability Score',
                });
                console.log(error);
            });
    }, []);

    return (
        <WidgetPaper
            title="Latest Reliability Score"
            description="Current stability of the device"
            {...props}
            {...widgetPaperProps}
        >
            <Highcharts
                allowChartUpdate={false}
                options={{
                    chart: chartDefaults.pie.chart,
                    plotOptions: {
                        pie: chartDefaults.pie.plotOptions,
                    },
                    title: {
                        text: null,
                    },
                    subtitle: {
                        text: chartData.innerText,
                        align: 'center',
                        verticalAlign: 'middle',
                        style: {
                            fontSize: '2.2rem',
                            fontWeight: 700,
                            color: chartData.scoreColorRepresentation,
                        },
                        y: chartData.deviceScore > 0 ? 36 : 24,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'Latest Reliability Score',
                            showInLegend: false,
                            events: {
                                click: function (e) {
                                    e.preventDefault();
                                },
                            },
                            data: [
                                {
                                    name: 'The Score',
                                    color: chartData.scoreColorRepresentation,
                                    y: chartData.deviceScore,
                                },
                                {
                                    name: 'Negative score',
                                    color: colors.slate[100],
                                    y: 10 - chartData.deviceScore,
                                },
                            ],
                        },
                    ],
                }}
            />
        </WidgetPaper>
    );
};

ReliabilityLatestScoreWidget.propTypes = {};

export { ReliabilityLatestScoreForDeviceWidget, ReliabilityLatestScoreWidget };
export default ReliabilityLatestScoreWidget;
