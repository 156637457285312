import FormLabel from '@mui/material/FormLabel';
import MUIRadioGroup, { RadioGroupProps as MUIRadioGroupProps } from '@mui/material/RadioGroup';
import React from 'react';

export type RadioGroupProps = MUIRadioGroupProps & {
    label: string;
};

/** RadioGroup component */
export const RadioGroup: React.FC<RadioGroupProps> = ({ label, ...otherProps }) => (
    <FormLabel>
        {label}
        <MUIRadioGroup {...otherProps} />
    </FormLabel>
);

export default RadioGroup;
